import React from 'react';
import debounce from '../utils/debounce';

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 50];

export default function usePagination(props) {
  const { onChangePage, onChangeRowPerPage, pending = false, page = 1, perPage = 10, count = 0 } = props;

  // Input
  const [inputPage, setInputPage] = React.useState(page);
  React.useEffect(() => setInputPage(String(page)), [page]);

  const numPage = Number(page) || 0;
  const numPerPage = Number(perPage) || 10;
  const numCount = Number(count) || 0;
  const countPages = Math.ceil((numCount || 0) / numPerPage);
  const isFirstPage = numPage <= 1;
  const isLastPage = numPage >= countPages;

  const debouncedOnChangePage = React.useMemo(
    () => (typeof onChangePage === 'function' ? debounce(onChangePage, 1100) : null),
    [onChangePage]
  );

  const handleInputPage = React.useCallback(
    ({ target }) => {
      const value = Number(target.value) || 0;
      const submitValue = value > countPages ? String(countPages) : String(value);
      setInputPage(submitValue);

      if (typeof debouncedOnChangePage !== 'function') return;
      if (value <= 0) return debouncedOnChangePage.abort();

      debouncedOnChangePage(submitValue);
    },
    [countPages, debouncedOnChangePage]
  );

  const handleNextPage = React.useCallback(
    ({ currentTarget } = {}) => {
      if (!(typeof onChangePage === 'function' && numPage < countPages)) return;
      const loadMore = currentTarget && currentTarget.getAttribute('data-load');

      onChangePage(numPage + 1, loadMore === 'more');
    },
    [numPage, onChangePage, countPages]
  );

  const handlePrevPage = React.useCallback(() => {
    if (!(typeof onChangePage === 'function' && numPage > 1)) return;
    onChangePage(numPage - 1);
  }, [onChangePage, numPage]);

  const handleChangeRowPerPage = React.useCallback(
    ({ target }) => {
      if (typeof onChangeRowPerPage !== 'function') return;
      onChangeRowPerPage((target && Number(target.value)) || 10);
    },
    [onChangeRowPerPage]
  );

  return {
    page: numPage,
    perPage: numPerPage,
    count: numCount,
    inputPage,
    countPages,
    isFirstPage,
    isLastPage,
    pending,
    handleInputPage,
    handleNextPage,
    handlePrevPage,
    handleChangeRowPerPage,
  };
}
