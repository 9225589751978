import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Link as RouterLink } from 'react-router-dom';
import { Button } from '../common/Buttons';
import useStyles from '../../containers/Auth/styles';
import Typography from '../common/Typography';
import { UserSuccess } from '../svg/Icons/Menu';

const InfoState = (props) => {
  const classes = useStyles();
  const { childSVG, title, subTitle, buttonText, to } = props;

  return (
    <Grid item xs={12}>
      {childSVG && <UserSuccess alt="expiration" className={classes.authStateImage} />}
      <Typography variant="h1Medium" fontFamily="lexend" weight="500" className={classes.verificationTitle}>
        {title}
      </Typography>
      <Typography component="p" weight="500" variant="body1" className={classes.email}>
        {subTitle}
      </Typography>
      <Grid item xs={4}>
        <Button
          id="sign-in-btn"
          color="primary"
          variant="outlined"
          size="large"
          fullWidth
          component={RouterLink}
          to={to}
        >
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default InfoState;

InfoState.defaultProps = {
  title: 'Example',
  subTitle: 'Example',
  buttonText: 'Example',
  to: '/expiration',
  childSVG: UserSuccess,
};
