import { EventEmitter } from 'events';
import { login } from '../../store/auth';
import { onboardingViews, setGlobalSettingsData } from '../../store/globalSettings';
import { orgActions } from '../../store/orgSettings';
import { setSubscriptionInfo } from '../../store/subscription';
import { AmplifyAuth, getAmplifyAuthData } from '../amplifyAuth';
import { getHttpClient, getWebSocketRpcClient } from '../api';
import { actions } from '../../store/billing';
import { setStage } from '../../store/stage';

/**
 * @class Application
 */
export default class Application extends EventEmitter {
  static EVENTS = {
    SERVER_EVENT: 'application.server-event',
    AUTHORIZE: 'application.authorize',
    LOGOUT: 'application.logout',
  };

  static AUTH_KEY = '25471d36-ae9e-4a25-8999-57c83e01df2b-AUTH';
  /**
   * @type {EnhancedStore}
   * @private
   */
  #storage;
  /**
   * @type {HttpClient}
   * @private
   */
  #httpClient;
  /**
   * @type {WebSocketRpcClient}
   * @private
   */
  #rpcClient;

  /**
   * @constructor
   * @param storage
   */
  constructor(storage) {
    super();
    this.#storage = storage;
    this.#httpClient = getHttpClient();
    this.#rpcClient = getWebSocketRpcClient();
    this.#rpcClient.on('event', (payload) => {
      console.debug('RPC event:', payload);
      if (payload && payload.event) return this.emit(payload.event, payload);
      if (payload) this.emit(Application.EVENTS.SERVER_EVENT, payload);
    });
    this.getCurrentSession();
  }

  get host() {
    return window.location.host;
  }

  get storage() {
    return this.#storage;
  }

  get httpClient() {
    return this.#httpClient;
  }

  get rpcClient() {
    return this.#rpcClient;
  }

  get auth() {
    const { auth } = this.storage.getState();
    return auth;
  }

  get authorized() {
    const auth = this.auth;
    return Boolean(auth && auth.authorized && auth.accessToken);
  }

  async onReady() {
    if (this._onReadyPromise) return this._onReadyPromise;

    return (this._onReadyPromise = new Promise(async (resolve) => {
      if (this.__ready) return resolve();
      this.once('$ready', resolve);
    }));
  }

  async getCurrentSession(getUser = true) {
    await AmplifyAuth.currentAuthenticatedUser()
      .then(async (user) => {
        const currentSession = await AmplifyAuth.currentSession();
        const userData = getAmplifyAuthData(user);
        userData.accessToken = currentSession.accessToken.jwtToken;
        return this.authorize(userData, getUser);
      })
      .catch((error) => {
        console.warn(error);
        this.emit(Application.EVENTS.LOGOUT);
      })
      .finally(() => this.emit('$ready', (this.__ready = true)));
  }

  async authorize(auth, getUser = true) {
    await this.rpcClient.auth(auth && auth.accessToken);
    this.httpClient.setAuthorization(auth && auth.accessToken);
    if (getUser) {
      const user = await this.rpcClient.call('auth.user').catch(() => ({}));
      if (Object.keys(user).length) {
        const [orgInfo, subscriptionInfo, plans, onboardingViewsData, globalSettingsData, stageData] =
          await Promise.all([
            this.httpClient.rpc('organization.getOrganizationInfo', {}).catch(() => ({})),
            this.httpClient.rpc('organization.subscriptionStatus', {}).catch(() => ({})),
            this.httpClient.rpc('billing.getPlans', {}).catch(() => ({})),
            this.httpClient.rpc('globalSettings.onboardingViews', {}).catch(() => ({})),
            this.httpClient.rpc('globalSettings.getGlobalSettings', {}).catch(() => ({})),
            this.httpClient.rpc('stage', {}).catch(() => ({})),
          ]);
        this.storage.dispatch(orgActions.setOrganizationInfo(orgInfo));
        this.storage.dispatch(setSubscriptionInfo(subscriptionInfo));
        this.storage.dispatch(actions.setGetPlans(plans));
        this.storage.dispatch(onboardingViews(onboardingViewsData));
        this.storage.dispatch(setGlobalSettingsData(globalSettingsData));
        this.storage.dispatch(setStage(stageData));
      }

      const userData = Object.assign(user, auth);
      this.storage.dispatch(login(userData));
    }
    this.emit(Application.EVENTS.AUTHORIZE, this.auth);
    return auth;
  }

  async apiHealthCheck() {
    return this.rpcClient.call('healthCheck');
  }

  async call(method, params, http = true) {
    await this.getCurrentSession(false);
    return this.onReady()
      .then(() => (http ? this.httpClient.rpc(method, params) : this.rpcClient.call(method, params)))
      .catch(async (error) => {
        return Promise.reject(error);
      });
  }
}
