import { Auth as AmplifyAuth } from '@aws-amplify/auth';
//import { isMFAEnabled } from '../../utils/mfa';

AmplifyAuth.configure({
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});

export { AmplifyAuth };

export function getAmplifyAuthData(result = {}) {
  const session = result.getSignInUserSession();
  //const { preferredMFA } = result;
  const { accessToken, idToken, refreshToken } = session;
  const { email, family_name: familyName, name } = idToken.payload;
  const { exp: accessExpires, auth_time: authTime, username } = accessToken.payload;
  //const mfaEnabled = isMFAEnabled(preferredMFA);

  return {
    accessToken: accessToken.jwtToken,
    refreshToken: refreshToken.token,
    accessExpires,
    authTime,
    //mfaEnabled,
    //preferredMFA,
    username,
    email,
    name,
    familyName,
  };
}
