import palette from '../pallete';
import typography from '../typography';

const MuiTabs = {
  MuiTabs: {
    root: {
      minHeight: 59,
      height: '100%',
      '&.indicator-filled $indicator': {
        height: '100%',
        opacity: 0.14,
      },

      // Custom Indicator Colors
      '&.indicator-inherit $indicator': {
        backgroundColor: 'currentColor',
      },
    },
    scroller: { height: '100%' },
    flexContainer: { height: '100%' },
    indicator: { height: 1 },
  },
  MuiTab: {
    root: {
      padding: '6px 20px',
      minHeight: 59,
      height: '100%',
      fontSize: typography.h6.fontSize,
      fontWeight: typography.fontWeightMedium,
      '@media (min-width: 0px)': { minWidth: 0 },
    },
    textColorSecondary: { color: palette.primary.main },
  },
};

export default MuiTabs;
