import React from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

const RecentNotificationItem = ({ index, item = {}, hideDescription = false }) => {
  return (
    <Grid item md={4} sm={12} xs={12}>
      <div className={styles.item}>
        <div className={styles.itemContent}>
          <div className={styles.image}>
            <img src="https://via.placeholder.com/276x156" alt="image" />
          </div>
          <h3>
            <Link className={styles.link} to="/video-detail/1">
              Jonh wayne live trining & Procurement Agen Jonh Conway
            </Link>
          </h3>
          {!hideDescription && (
            <p>
              It is a long established fact that a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
              letters, as opposed
            </p>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default RecentNotificationItem;
