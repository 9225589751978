import Grid from '@material-ui/core/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, RouterLink } from '../../../components/common/Buttons';
import { ModalDialog } from '../../../components/common/Dialogs';
import { TextField } from '../../../components/common/Inputs';
import Typography from '../../../components/common/Typography';
import { useForm } from '../../../hooks/useForm';
import { useTrans } from '../../../services/i18n';
import { getInitialValues, useScheme } from './helpers';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Paper, SpacingContainer } from '../../../components/common/Containers';
import InfoAboutNotifications from '../../../containers/Profile/EmailNotificationSettings/components/InfoAboutNotifications';
import InfoAboutNotificationsModal from '../../../containers/Profile/EmailNotificationSettings/components/InfoAboutNotificationsModal';
import { errorAlert, successAlert } from '../../../contexts/AlertContext';

import connect from './connect';
import { useApplication } from '../../../services/application';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useComponentPermission } from '../../../hooks/useComponentPermission';
import { COMPONENT_FEATURES } from '../../../utils/constants';
import { ROUTE_PROFILE } from '../../../constants';

const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: false };

const SaveSearchForm = ({
  item,
  open,
  onClose,
  setOpenModalPremium,
  onSaveSearch,
  authUser,
  updateOnViewHelpUseNotifications,
}) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const [step, setStep] = useState(1);
  const [notification, setNotification] = useState('GLOBAL');
  const [notificationFrequency, setNotificationFrequency] = useState('hour');
  const [notificationNumber, setNotificationNumber] = useState(1);
  const application = useApplication();
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [notificationsEnabled, globalNotification, globalFrequency, globalNotificationNumber] = React.useMemo(
    () => [
      authUser.savedSearchNotices,
      authUser.savedSearchTypeNotices,
      authUser.consolidateNotificationSelect,
      authUser.consolidateNotificationNumber,
    ],
    [authUser]
  );

  const { getPermissions } = useComponentPermission(COMPONENT_FEATURES.SAVED_SEARCHES);

  /***
   * When create or edit
   */
  const onSubmit = useCallback(
    (values, { resetForm }) => {
      if (typeof onSaveSearch !== 'function') return;
      Promise.resolve(
        onSaveSearch({
          id: values.id,
          title: values.title,
          notification: notification,
          notificationFrequency: notificationFrequency,
          notificationNumber: notificationNumber,
        })
      ).then((e) => {
        onClose();
        if (e?.error?.code === 'SubscribedContentOnlyError') {
          errorAlert('To continue to save searches get a premium account');
          setOpenModalPremium(true);
        } else {
          successAlert('Your search has been saved successfully');
        }
        getPermissions();
        resetForm();
      });
    },
    [onClose, onSaveSearch, setOpenModalPremium, notificationFrequency, notificationNumber, notification]
  );

  const { getFieldProps, handleSubmit, values, isSubmitting, isValid, setFieldValue, resetForm } = useForm(
    schema,
    getInitialValues(item),
    onSubmit,
    formOptions
  );

  const continueToNotifications = async () => {
    setStep(2);
    updateOnViewHelpUseNotifications({ onViewHelpUseNotifications: 1 });
    await application.call('user.markOnViewHelpUseNotifications', {});
  };

  /***
   * Set values for SS
   */
  useEffect(() => {
    setNotification('GLOBAL');
    setNotificationFrequency('hour');
    setNotificationNumber(1);
  }, [item, notificationsEnabled]);

  /**
   *
   */
  useEffect(() => {
    if (authUser?.onViewHelpUseNotifications) setStep(2);
  }, [authUser]);

  const handleClose = () => {
    onClose();
    resetForm();
  };

  return (
    <>
      <ModalDialog
        open={open}
        onClose={onClose}
        title={trans(values.id ? 'edit-saved-search' : 'create-saved-search')}
        actions={
          step === 1 ? (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="outlined" color="primary" onClick={handleClose}>
                  {trans('cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => continueToNotifications()}>
                  {trans('continue')}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="outlined" color="primary" disabled={isSubmitting} onClick={handleClose}>
                  {trans('cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                  pending={isSubmitting}
                  onClick={handleSubmit}
                >
                  {trans(values.id ? 'save' : 'create')}
                </Button>
              </Grid>
            </Grid>
          )
        }
      >
        {step === 1 ? (
          <InfoAboutNotifications />
        ) : (
          <>
            <Typography weight="600" title>
              {trans('name-this-saved-search')}
            </Typography>

            <TextField placeholder={trans('name')} required {...getFieldProps('title')} />

            <div style={{ width: '100%' }}>
              {!notificationsEnabled && (
                <Box mb={2.5} style={{ display: 'flex', marginTop: '25px' }}>
                  <Alert severity="warning" style={{ width: '100%' }}>
                    <Typography variant="body2">
                      Email notifications for Saved Searches are turned off in your{' '}
                      <Typography
                        variant="body2"
                        to={`${ROUTE_PROFILE.path}?gotoemailsettings=true`}
                        component={RouterLink}
                        style={{ textDecoration: 'underline' }}
                      >
                        profile.
                      </Typography>
                    </Typography>
                  </Alert>
                </Box>
              )}

              {!!notificationsEnabled && (
                <div>
                  <Paper
                    bgColor="paperSecondary"
                    component={SpacingContainer}
                    py={2}
                    px={2}
                    radius="4"
                    style={{ display: 'flex', marginTop: '25px' }}
                  >
                    <InfoOutlinedIcon color="primary" style={{ marginRight: '16px' }} />
                    <Typography variant="body2">
                      You can adjust your email frequency in your{' '}
                      <Typography
                        variant="body2"
                        to={`${ROUTE_PROFILE.path}?gotoemailsettings=true`}
                        component={RouterLink}
                        style={{ textDecoration: 'underline' }}
                      >
                        profile
                      </Typography>{' '}
                      settings. However, if you choose to disable email notifications in your{' '}
                      <Typography
                        variant="body2"
                        to={`${ROUTE_PROFILE.path}?gotoemailsettings=true`}
                        component={RouterLink}
                        style={{ textDecoration: 'underline' }}
                      >
                        profile
                      </Typography>
                      , it will stop all email notifications for Saved Searches.
                    </Typography>
                  </Paper>
                </div>
              )}
            </div>
          </>
        )}
      </ModalDialog>
      <InfoAboutNotificationsModal open={openInfoModal} onClose={() => setOpenInfoModal(false)} />
    </>
  );
};

export default connect(SaveSearchForm);
