import { SpacingContainer } from '../../../../components/common/Containers/Base';
import { Divider, Grid } from '@material-ui/core';
import Typography from '../../../../components/common/Typography';
import { useTrans } from '../../../../services/i18n';
import { Button } from '../../../../components/common/Buttons';
import { Paper } from '../../../../components/common/Containers';
import PremiumIcon from '../../../../components/assests/premium-icon.png';
import ModalSeats from './ModalSeats';
import connect from '../../connect';
import useSeats from './hooks/useSeats';

const Seats = ({ subscription, settings, users, setSeats }) => {
  const { trans } = useTrans();
  const [seatPrice, openDialog, onOpenDialog, onCloseDialog, classes] = useSeats();

  return (
    <>
      <Paper id="users" style={{ marginTop: '16px' }}>
        <SpacingContainer px={4} py={3}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <div className={classes.flex}>
                <img src={PremiumIcon} alt="" />
                <Typography variant="h6" weight="600" fontFamily="lexend">
                  {trans('current-user-seat-count')}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.flex}>
                <Typography variant="h6" fontFamily="lexend" color="placeholder">
                  {trans('user-seats')}
                </Typography>
                <Typography variant="h5" fontFamily="lexend" color="placeholder">
                  {settings?.seats}
                </Typography>
                <Divider orientation="vertical" flexItem style={{ backgroundColor: 'rgb(204 204 204)' }} />
                <Typography variant="h6" fontFamily="lexend" color="success">
                  {trans('available-seats')}
                </Typography>
                <Typography variant="h5" fontFamily="lexend" color="success">
                  {settings?.availableSeats}
                </Typography>
                <Button variant="contained" color="primary" onClick={/*subscribedOnly(onOpenDialog)*/ onOpenDialog}>
                  Manage seats
                </Button>
              </div>
            </Grid>
          </Grid>
        </SpacingContainer>
      </Paper>
      <ModalSeats
        open={openDialog}
        onClose={onCloseDialog}
        item={settings}
        users={users}
        onSaveSeats={setSeats}
        seatPrice={seatPrice}
        settings={settings}
      />
    </>
  );
};

export default connect(Seats);
