import { createReducer, createAction } from '@reduxjs/toolkit';
import { API, SCOPE_STATE_LOCAL_CONTRACT_OPPORTUNITIES } from '../../constants';
import { application } from '../../services/application';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  extendBuilderWithNotInterestedActions,
  extendListBuilder,
  generateListActions,
  generateNotInterestedActions,
  generateSearchActions,
  INITIAL_LIST_STATE_EXTENDED,
} from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['dueDate', 'asc'],
  isAllExpanded: false,
  isActiveOpportunitiesSelected: true,
};

const listActions = generateListActions({
  scope: SCOPE_STATE_LOCAL_CONTRACT_OPPORTUNITIES,
  apiMethod: {
    GET_LIST: API.STATE_LOCAL_CONTRACT_OPPORTUNITIES.GET_LIST,
    EXPORT_CSV: API.STATE_LOCAL_CONTRACT_OPPORTUNITIES.EXPORT_CSV,
  },
  getStore: (store) => store.stateLocalContractOpportunities,
});

const notInterestingActions = generateNotInterestedActions({
  scope: SCOPE_STATE_LOCAL_CONTRACT_OPPORTUNITIES,
  apiMethod: { ADD_NOT_INTERESTED: API.STATE_LOCAL_CONTRACT_OPPORTUNITIES.ADD_NOT_INTERESTED },
  getStore: (store) => store.stateLocalContractOpportunities,
});

const searchActions = generateSearchActions({
  scope: SCOPE_STATE_LOCAL_CONTRACT_OPPORTUNITIES,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.stateLocalContractOpportunities,
});

const getAllNaics = createAsyncThunk('slco/getAllNaics', (params) => {
  return application.call(API.STATE_LOCAL_CONTRACT_OPPORTUNITIES.GET_ALL_NAICS, params);
});

const createQuoteRequest = createAsyncThunk('slco/createQuoteRequest', (params) => {
  return application.call(API.STATE_LOCAL_CONTRACT_OPPORTUNITIES.CREATE_QUOTE_REQUEST, params);
});

const expandUnexpandStateLocalOpp = createAction('slco/expandUnexpandStateLocalOpp');
function onExpandUnexpandStateLocalOpp(state, action) {
  const copyItems = [...state.items];
  const index = copyItems.findIndex((i) => i.id === action.payload.id);
  copyItems[index] = { ...copyItems[index], expanded: !copyItems[index]?.expanded };

  return Object.assign(state, {
    items: copyItems,
  });
}

const expandUnexpandAll = createAction('slco/expandUnexpandAll');
function onExpandUnexpandAll(state, action) {
  const copyItems = [...state.items];
  for (let index = 0; index < copyItems.length; index++) {
    // The following logic (expandedResult) is to avoid changing the expanded value for old items
    let expandedResult = false;
    if (copyItems[index]?.expanded === null) {
      // It is null when the item comes the first time
      expandedResult = state.isAllExpanded;
    } else {
      expandedResult = action.payload?.overrideStatus ? state.isAllExpanded : copyItems[index]?.expanded;
    }
    copyItems[index] = {
      ...copyItems[index],
      expanded: expandedResult,
    };
  }

  return Object.assign(state, {
    items: copyItems,
  });
}

const handleIsAllExpanded = createAction('slco/handleIsAllExpanded');
function onHandleIsAllExpanded(state, action) {
  return Object.assign(state, {
    isAllExpanded: !state.isAllExpanded,
  });
}

const setIsActiveOpportunitiesSelected = createAction('slco/setIsActiveOpportunitiesSelected');

const onSetActiveOpportunitiesSelected = (state, action) => {
  return Object.assign(state, {
    isActiveOpportunitiesSelected: action.payload,
  });
};

export const actions = {
  ...listActions,
  ...searchActions,
  ...notInterestingActions,
  getAllNaics,
  createQuoteRequest,
  expandUnexpandStateLocalOpp,
  expandUnexpandAll,
  handleIsAllExpanded,
  setIsActiveOpportunitiesSelected,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendListBuilder(builder, actions)
    .addCase(expandUnexpandStateLocalOpp, onExpandUnexpandStateLocalOpp)
    .addCase(expandUnexpandAll, onExpandUnexpandAll)
    .addCase(handleIsAllExpanded, onHandleIsAllExpanded)
    .addCase(setIsActiveOpportunitiesSelected, onSetActiveOpportunitiesSelected);
  extendBuilderWithNotInterestedActions(builder, actions);

  return builder;
});
