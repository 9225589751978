import { Button } from '../../../components/common/Buttons';
import { useTrans } from '../../../services/i18n';
import SetPhone from './SetPhone';
import { ModalDialog } from '../../../components/common/Dialogs';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import { SpacingContainer } from '../../../components/common/Containers/Base';
import Typography from '../../../components/common/Typography';

const PhoneNumber = ({ auth, modalTitleFontSize = '1.313rem' }) => {
  const { trans } = useTrans();
  const [open, onOpenModal, onCloseModal] = useBooleanHandlers();

  return (
    <>
      <Button onClick={onOpenModal} id="personal-info-edit" variant="outlined" color="primary" size="small">
        {auth?.phoneVerified === 'true' || auth?.phoneVerified === true ? trans('edit') : trans('add')}
      </Button>

      <ModalDialog
        open={open}
        onClose={onCloseModal}
        title={
          <Typography weight={'500'} fontFamily="lexend" style={{ fontSize: modalTitleFontSize }}>
            {auth?.phoneVerified === 'true' || auth?.phoneVerified === true
              ? trans('change-phone')
              : trans('add-phone')}
          </Typography>
        }
      >
        <SpacingContainer py={0}>
          <SetPhone auth={auth} onClose={onCloseModal} />
        </SpacingContainer>
      </ModalDialog>
    </>
  );
};

export default PhoneNumber;
