import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_AGENCIES_OFFICES, ROUTE_AGENCIES_OFFICES_DETAILS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const FederalAgenciesAndOffices = React.lazy(() => import('./FederalAgenciesAndOffices'));
const FederalAgenciesAndOfficesDetails = React.lazy(() => import('./Details'));

export default function FederalAgenciesAndOfficesWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_AGENCIES_OFFICES_DETAILS.path}>
          <FederalAgenciesAndOfficesDetails />
        </Route>
        <Route exact path={ROUTE_AGENCIES_OFFICES.path}>
          <FederalAgenciesAndOffices />
        </Route>
        <Redirect exact path="/*" to={ROUTE_AGENCIES_OFFICES.path} />
      </Switch>
    </Suspense>
  );
}
