import {
  ROUTE_AGENCIES_OFFICES_DETAILS,
  ROUTE_CONTRACT_AWARDS_DETAILS,
  ROUTE_CONTRACT_OPPORTUNITIES_DETAILS,
  ROUTE_GRANT_OPPORTUNITIES_DETAILS,
  ROUTE_ORG_SETTINGS,
  ROUTE_TASKS,
  ROUTE_TEAMING_OPPORTUNITIES_DETAILS,
  ROUTE_VENDORS_DETAILS,
  ROUTE_PURSUIT_DETAILS,
} from '../../../../../../constants';
import { NOTIFICATIONS } from '../../../../../../constants/api';
import { intlDateWithTimeFormat } from '../../../../../../utils/dateUtils';
import { formatPrettyUSD } from '../../../../../../utils/numberUtils';
import OpportunityListActions from '../../../../../../components/generics/Opportunity/ListActions';
import ToggleCompleteButton from '../../../../../generics/Tasks/ToggleCompleteButton';
import TeamingEdit from '../../components/TeamingEdit';
import SaveSearchEdit from '../../components/SaveSearchEdit';
import TasksActions from '../../components/TasksActions';
import TeamingActions from '../../components/TeamingActions';
import TeamingView from '../../components/TeamingView';

const grantOpportunityStructure = {
  api: NOTIFICATIONS.GET_FGO_DETAILS,
  actions: [
    {
      label: () => 'View',
      linkTo: (item) => ROUTE_GRANT_OPPORTUNITIES_DETAILS.path.replace(':id', item.itemId),
      variant: 'outlined',
    },
    {
      label: () => 'quick-actions',
      component: (item) => <OpportunityListActions item={item} type="grant" variant="contained" hideRequestQuote />,
    },
    {
      label: () => 'edit',
      component: (item) => <SaveSearchEdit item={item} />,
    },
  ],
  fields: [
    {
      label: () => 'solicitation-id',
      getValue: (item) => item.opportunityNumber,
    },
    {
      label: () => 'federal-agency',
      getValue: (item) => item.agencyName,
      linkTo: (item) => ROUTE_AGENCIES_OFFICES_DETAILS.path.replace(':id', item.agencyId),
    },
    {
      label: () => 'posted-date',
      getValue: (item) => intlDateWithTimeFormat(item.postDate),
    },
    {
      label: () => 'due-date',
      getValue: (item) => intlDateWithTimeFormat(item.closeDate),
    },
    {
      label: () => 'award-ceiling',
      getValue: (item) => formatPrettyUSD(item.awardCeiling, 1, 1),
    },
    {
      label: () => 'award-floor',
      getValue: (item) => formatPrettyUSD(item.awardFloor, 1, 1),
    },
    {
      label: () => 'applicant-types',
      getValue: (item) => item.applicantTypes?.map((type) => type.title).join('\n'),
    },
  ],
};

const contractOpportunityStructure = {
  api: NOTIFICATIONS.GET_FCO_DETAILS,
  actions: [
    {
      label: () => 'View',
      linkTo: (item) => ROUTE_CONTRACT_OPPORTUNITIES_DETAILS.path.replace(':id', item.itemId),
      variant: 'outlined',
    },
    {
      label: () => 'quick-actions',
      component: (item) => <OpportunityListActions item={item} type="contract" variant="contained" hideRequestQuote />,
    },
    {
      label: () => 'edit',
      component: (item) => <SaveSearchEdit item={item} />,
    },
  ],
  fields: [
    {
      label: () => 'notice-type',
      getValue: (item) => item.noticeType,
    },
    {
      label: () => 'federal-agency',
      getValue: (item) => item.agency,
      linkTo: (item) => ROUTE_AGENCIES_OFFICES_DETAILS.path.replace(':id', item.agencyId),
    },
    {
      label: () => 'location',
      getValue: (item) => item.location,
    },
    {
      label: () => 'posted-date',
      getValue: (item) => intlDateWithTimeFormat(item.publishDate),
    },
    {
      label: () => 'solicitation-number',
      getValue: (item) => item.solicitationNumber,
    },
    {
      label: () => 'due-date',
      getValue: (item) => intlDateWithTimeFormat(item.responseDateActual),
    },
    {
      label: () => 'set-aside',
      getValue: (item) => item.setAside,
    },
    {
      label: () => 'primary-naics',
      getValue: (item) => (item?.naics ? `${item?.naics?.code} - ${item?.naics?.name}` : null),
    },
    {
      label: () => 'psc',
      getValue: (item) => (item?.psc ? `${item?.psc?.code} - ${item?.psc?.notes?.substring(0, 50)}` : null),
    },
    {
      label: () => 'award-date',
      getValue: (item) => intlDateWithTimeFormat(item.awardDate),
    },
    {
      label: () => 'award-number',
      getValue: (item) => item.awardNumber,
    },
  ],
};

const contractAwardStructure = {
  api: NOTIFICATIONS.GET_CA_DETAILS,
  actions: [
    {
      label: () => 'View',
      linkTo: (item) => ROUTE_CONTRACT_AWARDS_DETAILS.path.replace(':id', item.itemId),
      variant: 'outlined',
    },
    {
      label: () => 'quick-actions',
      component: (item) => (
        <OpportunityListActions item={item} type="award" variant="contained" hideRequestQuote hideNotInterested />
      ),
    },
    {
      label: () => 'edit',
      component: (item) => <SaveSearchEdit item={item} />,
    },
  ],
  fields: [
    {
      label: () => 'award-id',
      getValue: (item) => item.awardTitle,
      linkTo: (item) =>
        `${ROUTE_CONTRACT_AWARDS_DETAILS.path.replace(':id', item.awardId)}?type=${item.type}&i=${item.awardId}`,
    },
    {
      label: () => 'awarded-vendor',
      getValue: (item) => item.vendorName,
      linkTo: (item) => ROUTE_VENDORS_DETAILS.path.replace(':id', item.vendorId),
    },
    {
      label: () => 'funding-agency',
      getValue: (item) => item.fundingAgencyName,
      linkTo: (item) => ROUTE_AGENCIES_OFFICES_DETAILS.path.replace(':id', item.fundingAgencyId),
    },
    {
      label: () => 'naics-category',
      getValue: (item) => item.naicsName,
    },
    {
      label: () => 'award-date',
      getValue: (item) => intlDateWithTimeFormat(item.signedDate),
    },
    {
      label: () => 'completion-date',
      getValue: (item) => intlDateWithTimeFormat(item.ultimateCompletionDate),
    },
    {
      label: () => 'extent-competed',
      getValue: (item) => item.extentCompeted,
    },
  ],
};

const teamingOpportunitiesStructure = {
  api: NOTIFICATIONS.GET_TEAMING_DETAILS,
  actions: [
    {
      label: () => 'View',
      component: (item) => <TeamingView item={item} />,
    },
    {
      label: () => 'Edit',
      component: (item) => <TeamingEdit item={item} />,
    },
    {
      label: () => 'Actions',
      component: (item) => <TeamingActions item={item} />,
    },
  ],
  fields: [
    {
      label: () => 'naics-category',
      getValue: (item) => (item?.naics ? `${item.naics.code} - ${item.naics.name}` : null),
    },
    {
      label: () => 'budget',
      getValue: (item) => formatPrettyUSD(item.budget, 1, 1),
    },
    {
      label: () => 'team-info',
      getValue: (item) => item.teamInfo,
    },
  ],
};

const tasksStructure = {
  api: NOTIFICATIONS.GET_TASKS_DETAILS,
  actions: [
    {
      label: () => 'View',
      linkTo: () => ROUTE_TASKS.path,
      variant: 'outlined',
    },
    {
      label: (item) => (item.status === 'completed' ? 'mark-incomplete' : 'mark-complete'),
      component: (item) => <ToggleCompleteButton item={item} />,
    },
    {
      label: () => 'Edit',
      component: (item) => <TasksActions item={item} />,
    },
  ],
  fields: [
    {
      label: () => 'name',
      getValue: (item) => item.name,
    },
    {
      label: () => 'due-date',
      getValue: (item) => intlDateWithTimeFormat(item.dueDate),
    },
    {
      label: () => 'pipeline',
      getValue: (item) => item.pipelineName,
    },
    {
      label: () => 'pursuit',
      getValue: (item) => item.pursuitName,
    },
  ],
};

const organizationStructure = {
  api: NOTIFICATIONS.GET_ORG_DETAILS,
  actions: [
    {
      label: () => 'View',
      linkTo: () => ROUTE_ORG_SETTINGS.path,
      variant: 'outlined',
    },
  ],
  fields: [
    {
      label: () => 'owner',
      getValue: (item) => item.owner,
    },
  ],
};

const structure = {
  FGO: grantOpportunityStructure,
  FCO: contractOpportunityStructure,
  CA: contractAwardStructure,
  TEAMING: teamingOpportunitiesStructure,
  TASKS: tasksStructure,
  ORGANIZATION: organizationStructure,
};

export const getCardFields = (item) => {
  const itemStructure = structure[item.scope];
  if (itemStructure) {
    const { api, fields } = itemStructure;
    return { api, fields };
  }

  return null;
};

export const getCardActions = (item) => structure[item?.scope]?.actions || [];

export const containsDetails = (scope) => {
  return !!structure[scope];
};
