import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconNotify = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M13.211 18c.607 0 .986.579.682 1.04-.392.595-1.113.96-1.893.96s-1.501-.365-1.893-.96c-.287-.436.035-.976.583-1.035l.099-.005h2.422zM12 4c3.551 0 6.445 2.819 6.569 6.342l.004.236v3.037c0 .79-.398 1.438.37 1.511l.272.014c1.005.112 1.045 1.568.12 1.787l-.12.02-.125.008H4.91l-.125-.007C3.74 16.83 3.738 15.26 4.78 15.14l.276-.014c.721-.069.295-.643.364-1.365l.007-.146v-3.037C5.427 6.945 8.37 4 12 4zm0 1.821c-2.552 0-4.634 2.013-4.748 4.54l-.005.217v3.037c0 .424-.079.83-.223 1.203l-.094.22-.049.095h10.237l-.048-.096c-.142-.302-.24-.629-.287-.972l-.025-.26-.005-.19v-3.037c0-2.627-2.128-4.757-4.753-4.757z"
        transform="translate(-997.000000, -270.000000) translate(251.000000, 182.000000) translate(720.000000, 0.000000) translate(0.000000, 24.000000) translate(26.000000, 64.000000)"
      />
    </SvgIcon>
  );
};

IconNotify.propTypes = simpleIconPropsTypes;
