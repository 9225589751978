import { useCallback, useState } from 'react';

export default function useTabs(defaultValue = false) {
  const [tab, setTab] = useState(defaultValue);

  const onChangeTab = useCallback((event, tab) => {
    if (typeof tab !== 'undefined' && tab !== null) {
      return setTab(tab);
    }

    if (event && event.target && typeof event.target.value !== 'undefined' && event.target.value !== null) {
      return setTab(event.target.value);
    }

    // Here we can setTab based on other event.target || event.currentTarget props
  }, []);

  return [tab, onChangeTab];
}
