import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_FEDERAL_AGENTS, ROUTE_FEDERAL_AGENTS_DETAILS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const FederalAgents = React.lazy(() => import('./FederalAgents'));
const FederalAgentsDetails = React.lazy(() => import('./FederalAgentDetails'));

export default function FederalAgentsWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_FEDERAL_AGENTS_DETAILS.path}>
          <FederalAgentsDetails />
        </Route>
        <Route exact path={ROUTE_FEDERAL_AGENTS.path}>
          <FederalAgents />
        </Route>
        <Redirect exact path="/*" to={ROUTE_FEDERAL_AGENTS.path} />
      </Switch>
    </Suspense>
  );
}
