import { useCallback, useState, useEffect } from 'react';
import { scrollIntoViewById } from '../utils/scrollUtils';
import usePagination from './usePagination';

export const SELECTED_ID_PREFIX = 'id-qv-';

export default function useSelectedItem({
  showItem = false,
  selectedIndex = 0,
  maxIndex = 0,
  resetOnClose = false,
  scrollToSelectedItem = false,
  ...pagination
} = {}) {
  const [selectedItem, setSelectedItem] = useState({ showItem, selectedIndex });
  const { isFirstPage, isLastPage, handleNextPage, handlePrevPage, handleInputPage } = usePagination(pagination);

  const onSetSelectedItem = useCallback((selectedIndex) => {
    if (typeof selectedIndex === 'number') {
      return setSelectedItem({ showItem: true, selectedIndex });
    }

    if (selectedIndex && selectedIndex.currentTarget && selectedIndex.currentTarget.getAttribute) {
      const index = selectedIndex.currentTarget.getAttribute('data-index');
      return setSelectedItem({ showItem: true, selectedIndex: Number(index) || 0 });
    }
  }, []);

  const onCloseSelectedItem = useCallback(
    () =>
      setSelectedItem((item) =>
        resetOnClose ? { selectedIndex: null, showItem: false } : { ...item, showItem: false }
      ),
    [resetOnClose]
  );

  const onNextSelectedItem = useCallback(
    () =>
      setSelectedItem((item) => {
        if (item.selectedIndex >= maxIndex) {
          handleNextPage();
          return { ...item, selectedIndex: 0 };
        }

        const selectedIndex = item.selectedIndex + 1;
        scrollIntoViewById(`${SELECTED_ID_PREFIX}${selectedIndex}`);

        return { ...item, selectedIndex };
      }),
    [maxIndex, handleNextPage]
  );

  const onResetPagination = useCallback(() => {
    if (isFirstPage) return;
    handleInputPage({ target: { value: 1 } });
    setSelectedItem({ showItem: true, selectedIndex: 0 });
    scrollIntoViewById(`${SELECTED_ID_PREFIX}0`);
  }, [handleInputPage, isFirstPage]);

  const onPrevSelectedItem = useCallback(
    () =>
      setSelectedItem((item) => {
        if (item.selectedIndex <= 0) {
          handlePrevPage();
          return { ...item, selectedIndex: maxIndex };
        }

        const selectedIndex = item.selectedIndex - 1;
        scrollIntoViewById(`${SELECTED_ID_PREFIX}${selectedIndex}`);

        return { ...item, selectedIndex };
      }),
    [handlePrevPage, maxIndex]
  );

  /***
   * Fix behavior in first render and selecting fisrt item with right bottom pagination
   */
  useEffect(() => {
    if (scrollToSelectedItem) {
      scrollIntoViewById(`${SELECTED_ID_PREFIX}${selectedItem.selectedIndex}`);
    }
  }, [selectedItem]);

  return [
    { ...selectedItem, isFirstPage, isLastPage },
    onSetSelectedItem,
    onCloseSelectedItem,
    onNextSelectedItem,
    onPrevSelectedItem,
    onResetPagination,
  ];
}
