import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_QUOTE_REQUESTS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const QuoteRequests = React.lazy(() => import('./List'));

export default function QuoteRequestsWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_QUOTE_REQUESTS.path}>
          <QuoteRequests />
        </Route>
        <Redirect exact path="/*" to={ROUTE_QUOTE_REQUESTS.path} />
      </Switch>
    </Suspense>
  );
}
