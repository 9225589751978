export const JSONRPC_VERSION = '2.0';

/**
 * @param {string} method
 * @return {string}
 */
export function generateId(method) {
  return Buffer.from([method, Date.now()].join('-')).toString('hex');
}

export function rpcRequest(method, params, id) {
  return {
    jsonrpc: JSONRPC_VERSION,
    method,
    params,
    id,
  };
}

export function rpcRequestString(method, params, id) {
  return JSON.stringify(rpcRequest(method, params, id));
}
