import { useDispatch, useSelector } from 'react-redux';
import { orgActions } from '../store/orgSettings';

export const getComponentPermission = (componentFeature, components) => {
  const componentPermission = components?.find((c) => c?.key === componentFeature);
  return componentPermission;
};

export const useComponentPermission = (componentFeature) => {
  const components = useSelector((s) => s.orgSettings.components);
  const dispatch = useDispatch();
  const componentPermission = getComponentPermission(componentFeature, components);

  const getPermissions = () => dispatch(orgActions.getPermissions({}));

  let hasPermission = false;
  switch (componentPermission?.type) {
    case 'number':
      // Null means unlimited
      hasPermission = componentPermission?.value === null || componentPermission?.value > 0;
      break;
    case 'boolean':
      hasPermission = Boolean(componentPermission?.value);
      break;
    default:
      hasPermission = false;
  }

  return {
    hasPermission,
    getPermissions,
    componentPermission
  };
};
