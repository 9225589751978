import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';

export const INITIAL_STATE = {
  filterDefault: null,
  pending: false,
  items: [],
  count: 0,
  isSomeChecked: false,
  isAllChecked: false,
  filters: {
    text: '',
    order: ['alphabetical', 'asc'],
    filters: {},
    where: { archived: false, draft: false },
    pagination: { page: 1, perPage: 10 },
  },
  currentView: 'vendors',
  openAddVendorModal: false,
  dashboardStatistics: {
    states: [
      {
        code: 'AL',
        id: '01',
        name: 'Alabama',
        value: 0,
      },
      {
        code: 'AK',
        id: '02',
        name: 'Alaska',
        value: 0,
      },
      {
        code: 'AS',
        id: '60',
        name: 'American Samoa',
        value: 0,
      },
      {
        code: 'AZ',
        id: '04',
        name: 'Arizona',
        value: 0,
      },
      {
        code: 'AR',
        id: '05',
        name: 'Arkansas',
        value: 0,
      },
      {
        code: 'CA',
        id: '06',
        name: 'California',
        value: 0,
      },
      {
        code: 'CO',
        id: '08',
        name: 'Colorado',
        value: 0,
      },
      {
        code: 'CT',
        id: '09',
        name: 'Connecticut',
        value: 0,
      },
      {
        code: 'DE',
        id: '10',
        name: 'Delaware',
        value: 0,
      },
      {
        code: 'DC',
        id: '11',
        name: 'District Of Columbia',
        value: 0,
      },
      {
        code: 'FM',
        id: '64',
        name: 'Federated States Of Micronesia',
        value: 0,
      },
      {
        code: 'FL',
        id: '12',
        name: 'Florida',
        value: 0,
      },
      {
        code: 'GA',
        id: '13',
        name: 'Georgia',
        value: 0,
      },
      {
        code: 'GU',
        id: '66',
        name: 'Guam',
        value: 0,
      },
      {
        code: 'HI',
        id: '15',
        name: 'Hawaii',
        value: 0,
      },
      {
        code: 'ID',
        id: '16',
        name: 'Idaho',
        value: 0,
      },
      {
        code: 'IL',
        id: '17',
        name: 'Illinois',
        value: 0,
      },
      {
        code: 'IN',
        id: '18',
        name: 'Indiana',
        value: 0,
      },
      {
        code: 'IA',
        id: '19',
        name: 'Iowa',
        value: 0,
      },
      {
        code: 'KS',
        id: '20',
        name: 'Kansas',
        value: 0,
      },
      {
        code: 'KY',
        id: '21',
        name: 'Kentucky',
        value: 0,
      },
      {
        code: 'LA',
        id: '22',
        name: 'Louisiana',
        value: 0,
      },
      {
        code: 'ME',
        id: '23',
        name: 'Maine',
        value: 0,
      },
      {
        code: 'MH',
        id: '68',
        name: 'Marshall Islands',
        value: 0,
      },
      {
        code: 'MD',
        id: '24',
        name: 'Maryland',
        value: 0,
      },
      {
        code: 'MA',
        id: '25',
        name: 'Massachusetts',
        value: 0,
      },
      {
        code: 'MI',
        id: '26',
        name: 'Michigan',
        value: 0,
      },
      {
        code: 'MN',
        id: '27',
        name: 'Minnesota',
        value: 0,
      },
      {
        code: 'MS',
        id: '28',
        name: 'Mississippi',
        value: 0,
      },
      {
        code: 'MO',
        id: '29',
        name: 'Missouri',
        value: 0,
      },
      {
        code: 'MT',
        id: '30',
        name: 'Montana',
        value: 0,
      },
      {
        code: 'NE',
        id: '31',
        name: 'Nebraska',
        value: 0,
      },
      {
        code: 'NV',
        id: '32',
        name: 'Nevada',
        value: 0,
      },
      {
        code: 'NH',
        id: '33',
        name: 'New Hampshire',
        value: 0,
      },
      {
        code: 'NJ',
        id: '34',
        name: 'New Jersey',
        value: 0,
      },
      {
        code: 'NM',
        id: '35',
        name: 'New Mexico',
        value: 0,
      },
      {
        code: 'NY',
        id: '36',
        name: 'New York',
        value: 0,
      },
      {
        code: 'NC',
        id: '37',
        name: 'North Carolina',
        value: 0,
      },
      {
        code: 'ND',
        id: '38',
        name: 'North Dakota',
        value: 0,
      },
      {
        code: 'MP',
        id: '69',
        name: 'Northern Mariana Islands',
        value: 0,
      },
      {
        code: 'OH',
        id: '39',
        name: 'Ohio',
        value: 0,
      },
      {
        code: 'OK',
        id: '40',
        name: 'Oklahoma',
        value: 0,
      },
      {
        code: 'OR',
        id: '41',
        name: 'Oregon',
        value: 0,
      },
      {
        code: 'PW',
        id: '70',
        name: 'Palau',
        value: 0,
      },
      {
        code: 'PA',
        id: '42',
        name: 'Pennsylvania',
        value: 0,
      },
      {
        code: 'PR',
        id: '72',
        name: 'Puerto Rico',
        value: 0,
      },
      {
        code: 'RI',
        id: '44',
        name: 'Rhode Island',
        value: 0,
      },
      {
        code: 'SC',
        id: '45',
        name: 'South Carolina',
        value: 0,
      },
      {
        code: 'SD',
        id: '46',
        name: 'South Dakota',
        value: 0,
      },
      {
        code: 'TN',
        id: '47',
        name: 'Tennessee',
        value: 0,
      },
      {
        code: 'TX',
        id: '48',
        name: 'Texas',
        value: 0,
      },
      {
        code: 'UT',
        id: '49',
        name: 'Utah',
        value: 0,
      },
      {
        code: 'VT',
        id: '50',
        name: 'Vermont',
        value: 0,
      },
      {
        code: 'VI',
        id: '78',
        name: 'Virgin Islands',
        value: 0,
      },
      {
        code: 'VA',
        id: '51',
        name: 'Virginia',
        value: 0,
      },
      {
        code: 'WA',
        id: '53',
        name: 'Washington',
        value: 0,
      },
      {
        code: 'WV',
        id: '54',
        name: 'West Virginia',
        value: 0,
      },
      {
        code: 'WI',
        id: '55',
        name: 'Wisconsin',
        value: 0,
      },
      {
        code: 'WY',
        id: '56',
        name: 'Wyoming',
        value: 0,
      },
    ],
    numbers: [
      {
        value: 0,
        text: 'Active Vendors',
        link: '',
        color: 'success',
      },
      {
        value: 0,
        text: 'Expiring Soon',
        link: '',
        color: 'warning',
      },
      {
        value: 0,
        text: 'Expired Vendors',
        link: '',
        color: 'secondary',
      },
    ],
    activeAsides: [],
  },

  openAddTagsModal: false,
  openImportVendorsCsvModal: false,
  importingCsv: false,
  processCsv: false,
  vendorsTags: [],
  isAllColumnsChecked: false,
  isSomeColumnChecked: false,
  tableColumns: [
    {
      name: 'vendor-name',
      value: 'name',
      active: true,
    },
    {
      name: 'status',
      value: 'status',
      active: true,
    },
    {
      name: 'expiration-date',
      value: 'expirationDate',
      active: true,
    },
    {
      name: 'cage-code',
      value: 'cageCode',
      active: true,
    },
    {
      name: 'uei-code',
      value: 'ueiCode',
      active: true,
    },
    {
      name: 'business-start-date',
      value: 'bussinessStartDate',
      active: true,
    },
    {
      name: 'SetAsides',
      value: 'setAsides',
      active: true,
    },
    {
      name: 'state',
      value: 'state',
      active: true,
    },
    {
      name: 'Naics',
      value: 'naics',
      active: true,
    },
    {
      name: 'Psc',
      value: 'psc',
      active: true,
    },
    {
      name: 'Tags',
      value: 'tags',
      active: true,
    },
  ],
  processValuesCsv: {
    total: 0,
    duplicates: 0,
    issues: 0,
    newsRegistered: [],
    notNewRegistered: [],
  },
  editVendorManagment: null,
  textSearch: '',
};

const getList = createAsyncThunk('vendorsManagement/getList', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.GET_LIST, params);
});
function onGetList(state, action) {
  const copyColumns = [...state.tableColumns];
  if (action.payload.columnsPreference.length) {
    copyColumns.forEach((i) => {
      i.active = action.payload.columnsPreference.includes(i.value);
    });
  }
  return Object.assign(state, {
    items: action.payload.data.items.map((i) => ({ ...i, checked: false })),
    count: action.payload.data.count,
    tableColumns: [...copyColumns],
    pending: false,
  });
}

const addVendor = createAsyncThunk('vendorsManagement/addVendor', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.ADD_VENDOR, params);
});

const checkAll = createAction('vendorsManagement/checkAll');
function onCheckAll(state, action) {
  return Object.assign(state, {
    isAllChecked: true,
    isSomeChecked: true,
    items: state.items.map((i) => ({ ...i, checked: true })),
  });
}

const uncheckAll = createAction('vendorsManagement/uncheckAll');
function onUnCheckAll(state, action) {
  return Object.assign(state, {
    isAllChecked: false,
    isSomeChecked: false,
    items: state.items.map((i) => ({ ...i, checked: false })),
  });
}

const itemCheck = createAction('vendorsManagement/itemCheck');
function onItemCheck(state, action) {
  const copyItems = [...state.items];
  const index = copyItems.findIndex((i) => i.id === action.payload.id);
  copyItems[index] = { ...copyItems[index], checked: action.payload.checked };

  const hasUnChecked = copyItems.find((i) => i.checked === false);
  const hasChecked = copyItems.find((i) => i.checked === true);

  return Object.assign(state, {
    isAllChecked: hasUnChecked ? false : true,
    isSomeChecked: hasChecked ? true : false,
    items: copyItems,
  });
}

const archiveSelected = createAsyncThunk('vendorsManagement/archiveSelected', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.ARCHIVE_SELECTED, params);
});
function onArchiveSelected(state, action) {
  const ids = action.payload.ids;
  const copyItems = state.items.filter((i) => !ids.includes(i.id));
  return Object.assign(state, {
    isAllChecked: false,
    isSomeChecked: false,
    items: copyItems,
  });
}

const deleteVendorManagement = createAsyncThunk('vendorsManagement/deleteVendorManagement', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.DELETE_SELECTED, params);
});
function onDeleteVendorManagement(state, action) {
  const ids = action.payload.ids;
  const copyItems = state.items.filter((i) => !ids.includes(i.id));
  return Object.assign(state, {
    isAllChecked: false,
    isSomeChecked: false,
    items: copyItems,
  });
}

const checkVendor = createAsyncThunk('vendorsManagement/checkVendor', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.CHECK_VENDOR, params);
});

const changeFilters = createAction('vendorsManagement/changeFilters');
function onChangeFilters(state, action) {
  return Object.assign(state, {
    filters: action.payload,
  });
}

const changeCurrentView = createAction('vendorsManagement/changeCurrentView');
function onChangeCurrentView(state, action) {
  return Object.assign(state, {
    currentView: action.payload,
  });
}

const openAddVendor = createAction('vendorsManagement/openAddVendor');
function onOpenAddVendor(state, action) {
  return Object.assign(state, {
    openAddVendorModal: action.payload,
  });
}

const openAddVendorManually = createAction('vendorsManagement/openAddVendorManually');
function onOpenAddVendorManually(state, action) {
  return Object.assign(state, {
    openAddVendorManuallyModal: action.payload || false,
  });
}

const getDashboard = createAsyncThunk('vendorsManagement/getDashboard', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.GET_DASHBOARD, params);
});
function onGetDashboard(state, action) {
  return Object.assign(state, {
    dashboardStatistics: action.payload.statistics,
  });
}

const addVendorTags = createAsyncThunk('vendorsManagement/addVendorTags', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.ADD_VENDOR_TAGS, params);
});
function onAddVendorTags(state, action) {
  return Object.assign(state, {
    isAllChecked: false,
    isSomeChecked: false,
    items: state.items.map((i) => ({ ...i, checked: false })),
    vendorsTags: action.payload.tags,
  });
}

const getVendorTags = createAsyncThunk('vendorsManagement/getVendorTags', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.GET_VENDOR_TAGS, params);
});
function onGetVendorTags(state, action) {
  return Object.assign(state, {
    vendorsTags: action.payload.tags,
  });
}

const openAddTags = createAction('vendorsManagement/openAddTags');
function onOpenAddTags(state, action) {
  return Object.assign(state, {
    openAddTagsModal: action.payload,
  });
}

const openImportVendors = createAction('vendorsManagement/openImportVendors');
function onOpenImportVendors(state, action) {
  return Object.assign(state, {
    openImportVendorsCsvModal: action.payload,
  });
}

const changeImportingCsv = createAsyncThunk('vendorsManagement/changeImportingCsv', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.IMPORT_CSV, params);
});
function onSuccessImportingCsv(state, action) {
  return Object.assign(state, {
    processValuesCsv: action.payload,
  });
}

const addVendorsFromImport = createAsyncThunk('vendorsManagement/addVendorsFromImport', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.ADD_VENDORS_FROM_IMPORT, params);
});

const changeProcessCsv = createAction('vendorsManagement/changeProcessCsv');
function onChangeProcessCsv(state, action) {
  return Object.assign(state, {
    processCsv: action.payload,
  });
}

const changeProcessValuesCsv = createAction('vendorsManagement/changeProcessValuesCsv');
function onChangeProcessValuesCsv(state, action) {
  return Object.assign(state, {
    processCsv: action.payload,
  });
}

const checkAllColumns = createAction('vendorsManagement/checkAllColumns');
function onCheckAllColumns(state, action) {
  return Object.assign(state, {
    isAllColumnsChecked: true,
    isSomeColumnChecked: true,
    tableColumns: state.tableColumns.map((i) => ({ ...i, active: true })),
  });
}

const uncheckAllColumns = createAction('vendorsManagement/uncheckAllColumns');
function onUnCheckAllColumns(state, action) {
  return Object.assign(state, {
    isAllColumnsChecked: false,
    isSomeColumnChecked: false,
    tableColumns: state.tableColumns.map((i) => ({ ...i, active: false })),
  });
}

const apiColumnCheck = createAsyncThunk('vendorsManagement/apiColumnCheck', (params, { getState }) => {
  const columns = getState().vendorsManagement.tableColumns;
  return application.call(API.VENDORS_MANAGEMENT.CHECK_COLUMN_PREFERENCE, {
    columns: columns.filter((i) => i.active).map((item) => item.value),
  });
});
const columnCheck = createAction('vendorsManagement/columnCheck');
function onColumnCheck(state, action) {
  const copyColumns = [...state.tableColumns];
  const index = copyColumns.findIndex((i) => i.name === action.payload.name);
  copyColumns[index] = { ...copyColumns[index], active: action.payload.active };

  const hasUnChecked = copyColumns.find((i) => i.active === false);
  const hasChecked = copyColumns.find((i) => i.active === true);

  return Object.assign(state, {
    isAllColumnsChecked: hasUnChecked ? false : true,
    isSomeColumnChecked: hasChecked ? true : false,
    tableColumns: copyColumns,
  });
}

function onChangePending(state) {
  return Object.assign(state, {
    pending: true,
  });
}

const changeFilterDefault = createAction('vendorsManagement/changeFilterDefault');
function onChangeFilterDefault(state, action) {
  return Object.assign(state, {
    filterDefault: action.payload,
  });
}
const changeEditVendorManagement = createAction('vendorsManagement/changeEditVendorManagement');
function onChangeEditVendorManagement(state, action) {
  let copy = action.payload;
  if (action.payload) {
    copy = { ...action.payload };
    copy.setAsides = copy.setAsides.map((i) => ({ id: i.code, value: i.description }));
    copy.naicsCode = copy.naics.map((i) => ({ id: i.code, value: i.description }));
    copy = { ...copy };
  }
  return Object.assign(state, {
    editVendorManagement: copy,
  });
}

const onClearSearch = createAction('vendorsManagement/onClearSearch');

export const actions = {
  onClearSearch,
  getList,
  checkAll,
  uncheckAll,
  itemCheck,
  archiveSelected,
  changeFilters,
  changeCurrentView,
  openAddVendor,
  openAddVendorManually,
  checkVendor,
  addVendor,
  getDashboard,
  openAddTags,
  openImportVendors,
  addVendorTags,
  changeImportingCsv,
  changeProcessCsv,
  changeProcessValuesCsv,
  checkAllColumns,
  uncheckAllColumns,
  apiColumnCheck,
  columnCheck,
  getVendorTags,
  addVendorTags,
  changeFilterDefault,
  addVendorsFromImport,
  changeEditVendorManagement,
  deleteVendorManagement,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return builder
    .addCase(onClearSearch, (state, action) => {
      Object.assign(state, {
        textSearch: action.payload,
      });
    })
    .addCase(getList.pending, onChangePending)
    .addCase(getList.fulfilled, onGetList)
    .addCase(checkAll, onCheckAll)
    .addCase(uncheckAll, onUnCheckAll)
    .addCase(itemCheck, onItemCheck)
    .addCase(changeFilters, onChangeFilters)
    .addCase(changeCurrentView, onChangeCurrentView)
    .addCase(openAddVendor, onOpenAddVendor)
    .addCase(openAddVendorManually, onOpenAddVendorManually)
    .addCase(openAddTags, onOpenAddTags)
    .addCase(openImportVendors, onOpenImportVendors)
    .addCase(changeProcessCsv, onChangeProcessCsv)
    .addCase(changeImportingCsv.fulfilled, onSuccessImportingCsv)
    .addCase(changeProcessValuesCsv, onChangeProcessValuesCsv)
    .addCase(checkAllColumns, onCheckAllColumns)
    .addCase(uncheckAllColumns, onUnCheckAllColumns)
    .addCase(columnCheck, onColumnCheck)
    .addCase(changeFilterDefault, onChangeFilterDefault)
    .addCase(getDashboard.fulfilled, onGetDashboard)
    .addCase(archiveSelected.fulfilled, onArchiveSelected)
    .addCase(addVendorTags.fulfilled, onAddVendorTags)
    .addCase(getVendorTags.fulfilled, onGetVendorTags)
    .addCase(changeEditVendorManagement, onChangeEditVendorManagement)
    .addCase(deleteVendorManagement.fulfilled, onDeleteVendorManagement);
});
