import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconUser = (props) => {
  const { width, height, multiple = false, rounded = false } = props;

  return rounded ? (
    <SvgIcon width={width || 15} height={height || 15} viewBox="0 0 15 15">
      <path
        d="M7.5 2C3.36 2 0 5.36 0 9.5 0 13.64 3.36 17 7.5 17c4.14 0 7.5-3.36 7.5-7.5C15 5.36 11.64 2 7.5 2zM3.803 14.21c.322-.675 2.287-1.335 3.697-1.335 1.41 0 3.383.66 3.697 1.335-1.02.81-2.302 1.29-3.697 1.29s-2.678-.48-3.697-1.29zm8.467-1.088c-1.072-1.304-3.675-1.747-4.77-1.747-1.095 0-3.697.443-4.77 1.748C1.965 12.117 1.5 10.864 1.5 9.5c0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 1.365-.465 2.617-1.23 3.623zM7.5 5C6.045 5 4.875 6.17 4.875 7.625S6.045 10.25 7.5 10.25s2.625-1.17 2.625-2.625S8.955 5 7.5 5zm0 3.75c-.622 0-1.125-.502-1.125-1.125 0-.622.503-1.125 1.125-1.125.623 0 1.125.503 1.125 1.125 0 .623-.502 1.125-1.125 1.125z"
        transform="translate(-400 -151) translate(0 80) translate(393 65) translate(7 4)"
      />
    </SvgIcon>
  ) : multiple ? (
    <SvgIcon width={width || 68} height={height || 62} viewBox="0 0 68 62">
      <path
        d="M122 36c7.17 0 13 5.596 13 12.532l-.004.32C134.819 56.156 128.841 62 121.52 62H98.48C91.035 62 85 55.965 85 48.52 85 41.596 90.83 36 98 36zm21.25.422c5.634 1.454 9.602 6.47 9.746 12.261l.004.34C153 56.19 147.19 62 140.024 62c-.553 0-1-.448-1-1s.447-1 1-1c5.965 0 10.82-4.76 10.972-10.689l.004-.31c-.004-5.013-3.396-9.39-8.25-10.643-.535-.138-.856-.683-.718-1.218.138-.535.683-.856 1.218-.718zM122 38H98c-6.084 0-11 4.72-11 10.52C87 54.86 92.14 60 98.48 60h23.04c6.235 0 11.326-4.977 11.476-11.184l.004-.296c0-5.8-4.916-10.52-11-10.52zM110 0c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13zm21.248.421c5.752 1.473 9.776 6.656 9.776 12.594s-4.024 11.12-9.776 12.594c-.535.137-1.08-.186-1.217-.721-.137-.535.186-1.08.721-1.217 4.867-1.246 8.272-5.632 8.272-10.656 0-5.024-3.405-9.41-8.272-10.656-.535-.137-.858-.682-.72-1.217.136-.535.681-.858 1.216-.72zM110 2c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11z"
        transform="translate(-836 -745) translate(380 148) translate(0 68) translate(371 529)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width || 24} height={height || 24} viewBox="0 0 24 24">
      <path
        d="M15.5 13c2.517 0 4.5 2.266 4.5 5.037l-.008.186C19.877 19.784 18.574 21 17 21H7c-1.657 0-3-1.343-3-3 0-2.734 1.983-5 4.5-5zm0 2h-7C7.151 15 6 16.316 6 18c0 .552.448 1 1 1h10c.524 0 .959-.405.995-.887L18 18c0-1.684-1.151-3-2.5-3zM12 3c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
        transform="translate(-890 -294) translate(720) translate(150 194) translate(0 80) translate(20 20)"
      />
    </SvgIcon>
  );
};

IconUser.propTypes = simpleIconPropsTypes;
