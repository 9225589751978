import { useEffect, useState, useMemo } from 'react';
import Typography from '../../../../../components/common/Typography';
import { useTrans } from '../../../../../services/i18n';
import CreditCardSharpIcon from '@material-ui/icons/CreditCardSharp';
import { Button } from '../../../../../components/common/Buttons';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import FormCard from '../FormCard';
import RemoveCard from '../RemoveCard';
import { formatToTitleCase } from '../../../../../utils/helpers';
import { LoaderSmall } from '../../../../../components/common/LoaderSmall';
import connect from '../../../connect';
import BillingInfo from '../BillingInfo';
import MediumBanner from '../../../../../components/common/Subscription/BecomePremiumTopBar/MidBanner';
import { makeStyles } from '@material-ui/core';
import Contact from '../../../PlansTab/components/Plan/Contact';

const useStyles = makeStyles((theme) => ({
  centerTitle: {
    width: '100%',
    textAlign: 'center',
  },
}));
const KEY_PERSON_TYPE = 'registration-contracting-specialist';

const BillingAndPayment = ({
  subscription,
  subscriptionStatus,
  addCard,
  removePaymentMethod,
  getCustomerBilling,
  updateBilling,
  setDefaultPaymentMethodAction,
  updateCurrentProduct,
  businessTeam,
  fetchBusinessTeam,
}) => {
  const { trans } = useTrans();
  const styles = useStyles();
  const [openForm, onOpenForm, onCloseForm] = useBooleanHandlers();
  const contactPerson = useMemo(() => businessTeam?.find((p) => p.type === KEY_PERSON_TYPE), [businessTeam]);
  //const [billing, setBilling] = useState(null);
  const [billingInformation, setBillingInformation] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [pending, setPending] = useState(false);

  const onInit = async () => {
    setPending(!pending);
    const { payload } = await getCustomerBilling().finally(() => setPending(false));
    if (payload?.success) {
      //setBilling(payload.data.customer);
      if (payload.data.paymentMethod) {
        const { billingDetails, ...rest } = payload.data.paymentMethod;
        setPaymentMethod(rest);
        setBillingInformation(billingDetails);
        setDefaultPaymentMethodAction(payload.data.paymentMethod.id);
      }
      updateCurrentProduct(payload.data.subscription);
    }
    fetchBusinessTeam();
  };

  const onAddCard = async (params) => {
    const res = await addCard(params);
    onInit();
    return res;
  };

  const onRemovePaymentMethod = async (params) => {
    await removePaymentMethod(params);
    setPaymentMethod(null);
    setDefaultPaymentMethodAction(null);
  };

  const onUpdateBilling = async (params) => {
    const { payload } = await updateBilling({ address: params, id: paymentMethod.id });
    if (payload.success) {
      setBillingInformation(payload.data);
    }
    return payload;
  };

  const getSpecialSubscriptionDetails = () => {
    switch (subscriptionStatus?.currentPlan) {
      case 'limited':
        return <MediumBanner customTitle={trans('usfcr-subscription-plans')} customSmallText={trans('learn-more')} />;
      default:
        return <Contact contactPerson={contactPerson} />;
    }
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <>
      <Typography variant="h4" weight="600" fontFamily="lexend" gutter="label" className={styles.centerTitle}>
        {trans('billing-and-payment')}
      </Typography>
      {!pending ? (
        <>
          <div style={{ marginBottom: '1rem' }}>
            {paymentMethod ? (
              <>
                <Typography variant="body1" style={{ color: '#888FA9' }} gutter="label">
                  <CreditCardSharpIcon style={{ marginRight: '8px' }} />
                  {formatToTitleCase(paymentMethod.brand)} ending in **** {paymentMethod.last4}
                </Typography>
                <RemoveCard paymentMethod={paymentMethod} removePaymentMethod={onRemovePaymentMethod} />
              </>
            ) : (
              <>
                {!['sapAdvantage', 'procurementPro'].includes(subscriptionStatus?.currentPlan) && (
                  <Typography variant="body1" color="placeholder" gutter="label" className={styles.centerTitle}>
                    <CreditCardSharpIcon style={{ marginRight: '8px' }} />
                    {trans('no-payment-method-added')}
                  </Typography>
                )}

                {subscription && (
                  <>
                    <Button onClick={onOpenForm} variant="outlined" color="primary">
                      {trans('add-payment-method')}
                    </Button>
                    <FormCard open={openForm} onClose={onCloseForm} addCard={onAddCard} />
                  </>
                )}
                {getSpecialSubscriptionDetails()}
              </>
            )}
          </div>
          {paymentMethod && <BillingInfo billingInfo={billingInformation} onUpdateBilling={onUpdateBilling} />}
        </>
      ) : (
        <LoaderSmall color="inherit" size="1.35em" />
      )}
    </>
  );
};

export default connect(BillingAndPayment);
