import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common';
import {
  extendBuilderWithListActions,
  extendBuilderWithSearchActions,
  generateListActions,
  generateSearchActions,
} from '../helpers/listActionsHelpers';
import { API, SCOPE_MY_PURSUITS } from '../../constants';
import { application } from '../../services/application';

const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['pursuitName', 'asc'],
};

const listActions = generateListActions({
  scope: SCOPE_MY_PURSUITS,
  apiMethod: {
    GET_LIST: API.PURSUITS.GET_PURSUITS,
  },
  getStore: (store) => store.myPursuits,
});

const searchActions = generateSearchActions({
  scope: SCOPE_MY_PURSUITS,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.myPursuits,
});

const deletePursuit = createAsyncThunk(`${SCOPE_MY_PURSUITS}/deletePursuit`, (params) => {
  return application.call(API.PURSUITS.DELETE_PURSUIT, params);
});

const editPursuit = createAsyncThunk(`${SCOPE_MY_PURSUITS}/editPursuit`, (params) => {
  return application.call(API.PURSUITS.EDIT_PURSUIT, params);
});

const changePursuitStatus = createAsyncThunk(`${SCOPE_MY_PURSUITS}/changePursuitStatus`, (params) => {
  return application.call(API.PURSUITS.CHANGE_PURSUIT_STATUS, params).then((pursuits) => {
    return pursuits?.items?.find((item) => params.id === item.id);
  });
});

const onPursuitChange = (state, { payload }) => {
  if (!payload) return;
  const index = state.items.findIndex((x) => x.id === payload.id);
  if (index > -1) {
    state.items[index] = payload;
  }
};

const myPursuitsSlice = createSlice({
  name: SCOPE_MY_PURSUITS,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    extendBuilderWithListActions(builder, { ...listActions });
    extendBuilderWithSearchActions(builder, { ...searchActions });
    builder.addCase(changePursuitStatus.fulfilled, onPursuitChange).addCase(editPursuit.fulfilled, onPursuitChange);
  },
});

export const actions = {
  ...searchActions,
  ...listActions,
  ...myPursuitsSlice.actions,
  deletePursuit,
  changePursuitStatus,
  editPursuit,
};

export default myPursuitsSlice.reducer;
