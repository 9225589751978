import Drawer from '@material-ui/core/Drawer';
import React from 'react';

const preparePaperProps = (maxWidth) => ({ style: { width: maxWidth } });
const disableOtherCloseProps = { disableEscapeKeyDown: true, disableBackdropClick: true };

export const DrawerDialog = (props) => {
  const { children, disableOtherClose = false, anchor = 'right', maxWidth = 760, ...rest } = props;

  return (
    <Drawer
      PaperProps={preparePaperProps(maxWidth)}
      ModalProps={disableOtherClose ? disableOtherCloseProps : undefined}
      anchor={anchor}
      {...rest}
    >
      {children}
    </Drawer>
  );
};
