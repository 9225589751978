import { IconButton, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import Search from './components/Search';
import ActionsMenu from './components/ActionsMenu';
import connect from '../../../connect';
import { useTrans } from '../../../../../../services/i18n';

const AppHeader = ({ getDashboard, closeDrawer }) => {
  const { trans } = useTrans();
  const styles = useStyles();

  const closeModal = () => {
    getDashboard({});
    closeDrawer();
  };

  return (
    <>
      <div className={styles.appHeader}>
        <div className={styles.leftMenu} onClick={closeModal} style={{ cursor: 'pointer' }}>
          <ArrowBackIcon />
        </div>
        <div className={styles.title}>
          <Typography style={{ fontWeight: 'bold' }} variant="h3">
            {trans('manage-vendors')}
          </Typography>
        </div>
        {/* <div className={styles.mobileAppLogo}>fdfd</div> */}
        <div className={styles.searchDiv}>
          <Search />
        </div>
        <div className={styles.rightMenu}>
          <ActionsMenu />
        </div>
      </div>
    </>
  );
};

export default connect(AppHeader);
