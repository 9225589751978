import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {};

export const setExceptionsAction = createAction('exceptions/setExceptions');
export const cleanExceptionsAction = createAction('exceptions/cleanExceptions');

const setExceptions = (state, action) => {
  const code = action.payload.code;
  const payload = action.payload;
  return { ...state, [code]: { ...payload } };
};
const cleanExceptions = () => {
  return INITIAL_STATE;
};
export const actions = {
  setExceptions,
  cleanExceptions,
};
export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(setExceptionsAction, setExceptions);
  builder.addCase(cleanExceptionsAction, cleanExceptions);
});
