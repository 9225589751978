import { connect } from 'react-redux';
import { actions as pipelinesDetailsActions } from '../../../store/pipelinesDetails';
import { actions as pipelinesActions } from '../../../store/pipelines';

function mapStateToProps(state) {
  return {
    pipeline: state.pipelineDetails.item,
    loading: state.pipelineDetails.pending,
    pursuits: state.pipelineDetails.pursuitsByStage,
    settings: state.orgSettings,
    statistics: state.pipelineDetails.statistics,
  };
}

function mapDispatchToProps(dispatch) {
  const onInit = async (params) => {
    await dispatch(pipelinesDetailsActions.getPipeline(params));
  };
  const editPipeline = async (params) => {
    await dispatch(pipelinesDetailsActions.editPipeline(params));
  };

  const deletePipeline = async (params) => {
    await dispatch(pipelinesDetailsActions.deletePipeline(params));
    await dispatch(pipelinesActions.removePipelineList(params));
  };

  const getPursuits = async (params) => {
    await dispatch(pipelinesDetailsActions.getPursuits(params));
  };

  const searchTagsByUser = (params) => dispatch(pipelinesActions.getTagsByUser(params));

  const toggleIncludeInStatistics = async (params) => {
    await dispatch(pipelinesActions.toggleIncludeInStatistics(params));
  };

  const exportPursuits = async (params) => {
    await dispatch(pipelinesDetailsActions.exportPursuits(params));
  };

  const getStatistics = async (params) => {
    await dispatch(pipelinesDetailsActions.getStatistics(params));
  };

  return {
    onInit,
    editPipeline,
    deletePipeline,
    getPursuits,
    searchTagsByUser,
    toggleIncludeInStatistics,
    exportPursuits,
    getStatistics,
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
