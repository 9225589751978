import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_SUGGESTED_SEARCHES } from '../../constants';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE } from '../helpers/common';
import { extendListBuilder, generateListActions, generateSearchActions } from '../helpers/listActionsHelpers';
import { extendBuilderWithAsyncAction } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE,
  pagination: { page: 1, perPage: 6 },
  order: ['name', 'asc'],
};

const listActions = generateListActions({
  scope: SCOPE_SUGGESTED_SEARCHES,
  apiMethod: {
    GET_LIST: API.SUGGESTED_SEARCHES.GET_LIST,
  },
  getStore: (store) => store.suggestedSearches,
});

const searchActions = generateSearchActions({
  scope: SCOPE_SUGGESTED_SEARCHES,
  apiMethod: {},
  getStore: (store) => store.suggestedSearches,
});

const addToSavedAction = createAsyncThunk(`${SCOPE_SUGGESTED_SEARCHES}/addToSaved`, (data) => {
  return application.call(API.SUGGESTED_SEARCHES.ADD_TO_SAVED, data);
});

const addAllToListAction = createAsyncThunk(`${SCOPE_SUGGESTED_SEARCHES}/addAllToList`, (data) => {
  return application.call(API.SUGGESTED_SEARCHES.ADD_ALL_TO_LIST, data);
});

const addToNotInterestedAction = createAsyncThunk(`${SCOPE_SUGGESTED_SEARCHES}/addToNotInterested`, (data) => {
  return application.call(API.SUGGESTED_SEARCHES.ADD_TO_NOT_INTERESTED, data);
});

export const actions = {
  ...listActions,
  ...searchActions,
  addToSavedAction,
  addAllToListAction,
  addToNotInterestedAction,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendListBuilder(builder, actions);

  extendBuilderWithAsyncAction(builder, addToSavedAction);
  extendBuilderWithAsyncAction(builder, addAllToListAction);
  extendBuilderWithAsyncAction(builder, addToNotInterestedAction);
});
