import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { RouterLink } from '../../../../components/common/Buttons';
import FieldValue from '../../../../components/generics/Helpers/FieldValue';
import { ROUTE_CODE_CATEGORIES_DETAILS } from '../../../../constants';
import { useTrans } from '../../../../services/i18n';
import { emptyArray } from '../../../../utils/arrayUtils';
import { generateLocation } from '../../helpers';
import AvatarTitle from './Avatar';
import { ROUTE_VENDORS_DETAILS } from '../../../../constants';

const VendorFound = ({ item, md = 6, isSmScreen = false }) => {
  const { trans } = useTrans();
  const [image, setImage] = useState(null);
  const detailLink = item ? ROUTE_VENDORS_DETAILS.path.replace(':id', item.id) : '';

  return (
    item && (
      <div style={{ padding: '30px 16px' }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <AvatarTitle
              src={image ? image : ''}
              title={item.name}
              description={generateLocation(item)}
              detailLink={detailLink}
              small={isSmScreen}
            />
          </Grid>

          <Grid container style={{ paddingLeft: '90px' }} spacing={3}>
            <Grid item md={md} lg={6} xs={12}>
              <FieldValue value={item.ueiCode} label={trans('uei-code')} />
            </Grid>

            <Grid item md={md} sm={6} xs={6}>
              <FieldValue value={item.cageCode} label={trans('CAGECode')} />
            </Grid>

            <Grid item md={md} lg={6} xs={12}>
              <FieldValue value={item.entityStructureCode} label={trans('EntityStructure')} />
            </Grid>

            <Grid item md={md} xs={12}>
              <FieldValue value={generateLocation(item)} label={trans('location')} noWrap={false} />
            </Grid>

            <Grid item md={md} sm={6} xs={6}>
              <FieldValue label={trans('NAICS-category')}>
                {!emptyArray(item.naics) &&
                  item.naics.map((item) => (
                    <Typography component="p" key={item.code}>
                      <Typography
                        variant="h6"
                        component={RouterLink}
                        to={ROUTE_CODE_CATEGORIES_DETAILS.path.replace(':id', `NAICS-${item.code}`)}
                      >
                        {item.code} - {item.name}
                      </Typography>
                    </Typography>
                  ))}
              </FieldValue>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  );
};

export default VendorFound;
