import React, { useEffect, useState } from 'react';
import { useTrans } from '../../../../services/i18n';
import { ModalDialog } from '../../../../components/common/Dialogs';
import { MenuItem } from '@material-ui/core';
import Loader from '../../../../components/common/Loader';
import Button from '../../../../components/common/Buttons/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useForm } from '../../../../hooks/useForm';
import { CustomStatesAutocomplete } from '../CustomStatesAutocomplete';
import { SelectField } from '../../../../components/common/Inputs';
import Typography from '../../../../components/common/Typography';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import * as Yup from 'yup';
import { schemaToInitialState } from '../../../../utils/validationUtils';

const useStyles = makeStyles((theme) => ({
  darkGrayLabel: {
    color: theme.palette.gray.main,
    fontFamily: 'lexend',
    marginBottom: '0.4rem',
  },
}));

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    name: Yup.string().required(trans('required')),
    states: Yup.array().of(Yup.object()).min(1, 'At least one state is required').required('States are required'),
  })
);

export default function OfficialCertifications({ open, onClose, states, certifications, setCertifications }) {
  const styles = useStyles();
  const schema = useScheme();
  const { trans } = useTrans();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      ...values,
      states: [],
    });
  }, [setValues]);

  const certificationsList = [
    {
      name: trans('official-certification-wosb'),
      value: 'WOSB/WBE',
    },
    {
      name: trans('official-certification-edwosb'),
      value: 'EDWOSB',
    },
    {
      name: trans('official-certification-vosb'),
      value: 'VOSB/VBE',
    },
    {
      name: trans('official-certification-sdvosb'),
      value: 'SDVOSB',
    },
    {
      name: trans('official-certification-mbe'),
      value: 'a/MBE',
    },
  ];

  function getRenderValue(list, value) {
    const d = list.find((i) => i.value === value);
    return d && d.name;
  }

  const onAddCertification = () => {
    const certification = { name: values.name, states: values.states.map((i) => i.id) };
    // //Look for existing certificate and include in in the certifications array
    const existingIndex = certifications.findIndex((item) => item.name === certification.name);

    if (existingIndex !== -1) {
      const updatedList = [...certifications];
      updatedList[existingIndex] = certification;
      setCertifications(updatedList);
    } else {
      setCertifications([...certifications, certification]);
    }
    onClose();
    resetForm();
  };

  const handleValueChange = (v) => {
    setFieldValue('name', v.target.value);
    const certValue = v.target.value;

    const obj = certifications.find((i) => i.name === certValue);
    if (obj) {
      setFieldValue(
        'states',
        obj.states.map((state) => ({ id: state, value: state }))
      );
    } else {
      setFieldValue('states', []);
    }
  };

  const { getFieldProps, handleSubmit, values, setFieldValue, resetForm, setValues } = useForm(
    schema,
    schemaToInitialState(schema),
    onAddCertification,
    {
      validateOnChange: false,
      validateOnBlur: false,
    }
  );

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('add-official-certification')}
      actions={
        <>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {trans('cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {trans('add')}
          </Button>
        </>
      }
    >
      <Loader open={loading} />
      <Typography weight="500" variant="body2" className={styles.darkGrayLabel}>
        {trans('what-official-certifications-do-you-hold')}
      </Typography>

      <SelectField
        type="select"
        getRenderValue={getRenderValue.bind(null, certificationsList, values?.name)}
        {...getFieldProps('name')}
        onChange={handleValueChange}
      >
        {certificationsList &&
          certificationsList.map((fd, index) => (
            <MenuItem key={`oc_${index}`} value={fd.value}>
              {fd.name}
            </MenuItem>
          ))}
      </SelectField>

      <CustomStatesAutocomplete
        label={trans('state-area')}
        placeholder={trans('please-select')}
        multi
        options={states}
        {...getFieldProps('states')}
      />
    </ModalDialog>
  );
}
