import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '../../../../../components/common/Typography';
import { useTrans } from '../../../../../services/i18n';
import { SpacingContainer, Paper } from '../../../../../components/common/Containers';
import { Avatar } from '../../../../../components/common/Cards';
import connect from '../connect';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Chip } from '../../../../../components/common/Chip';

const useStyles = makeStyles((theme) => ({
  avatarMobile: {
    height: '4.25rem',
    width: '4.25rem',
  },
  avatarWeb: {
    height: '6.25rem',
    width: '6.25rem',
  },
  noTopPadding: {
    paddingTop: 'unset !important',
  },
  noBottomPadding: {
    paddingBottom: 'unset !important',
  },
}));

const VendorHeader = ({ item, orgAvatarUrl }) => {
  const { trans } = useTrans();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles();

  return (
    <Paper bgColor="primary" square component={SpacingContainer} px="5sm3" py={4}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs>
          <Grid container spacing={3}>
            {isMobile ? (
              <>
                <Grid item md={12} sm={12} xs={12} className={styles.noBottomPadding}>
                  <Typography variant="h2" weight="600" color="white" gutter="title" fontFamily="lexend">
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item md={9} sm={9} xs={9} className={styles.noTopPadding}>
                  <Chip
                    label={trans('personal-vendor')}
                    color={'success' || 'secondary' || 'primary'}
                    style={{ backgroundColor: '#11CA7B' }}
                  />
                </Grid>
                <Grid item md={3} sm={3} xs={3} align="right">
                  {orgAvatarUrl && (
                    <Avatar src={orgAvatarUrl} alt="-" variantAvatar="rounded" className={styles.avatarMobile} />
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={9} sm={9} xs={9}>
                  <Typography variant="h2" weight="500" color="white" gutter="title" fontFamily="lexend">
                    {item.name}
                  </Typography>
                  <Chip
                    label={trans('personal-vendor')}
                    color={'success' || 'secondary' || 'primary'}
                    style={{ backgroundColor: '#11CA7B' }}
                  />
                </Grid>
                <Grid item md={3} sm={3} xs={3} align="right">
                  {orgAvatarUrl && (
                    <Avatar src={orgAvatarUrl} alt="-" variantAvatar="rounded" className={styles.avatarWeb} />
                  )}
                </Grid>
              </>
            )}

            {/*
              <Grid item md={6} sm={12} xs={12}>
                <Typography variant="button" weight="600" color="active">
                  {trans('AverageAnnualRevenue')}
                </Typography>
                <Typography variant="body1" weight="bold" color="white">
                  $150,000.00
                </Typography>
                <Typography variant="button" color="white">
                  {trans('based-off-contract-details')}
                </Typography>
              </Grid>
              */}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default connect(VendorHeader);
