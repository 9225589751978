import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import React from 'react';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconForms = (props) => {
  const { width = 25, height = 25 } = props;

  return (
    <AssignmentIcon
      width={width}
      height={height}
      style={{
        fontSize: `${width}px`,
      }}
    />
  );
};

IconForms.propTypes = simpleIconPropsTypes;
