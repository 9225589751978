import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    height: '100%',
    width: '100%',
    flex: 1,
    minWidth: 0,
  },
  appHeader: {
    backgroundColor: theme.palette.common.white,
    height: 80,
    padding: '0',
    boxShadow: '0 10px 20px 0 rgba(78, 89, 120, 0.06)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    paddingLeft: 12,
    zIndex: theme.zIndex.appBar,
    color: theme.palette.gray.main,
  },
  btnToggle: {
    borderRight: `1px solid ${theme.palette.gray.divider}`,
    borderLeft: `1px solid ${theme.palette.gray.divider}`,
    borderRadius: 0,
    height: '100%',
    width: 80,
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileAppLogo: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  rightMenu: {
    paddingLeft: 25,
    paddingRight: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leftMenu: {
    paddingLeft: 25,
    paddingRight: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 80,
    padding: '0 1em',
    borderLeft: `1px solid ${theme.palette.gray.divider}`,
    borderRight: `1px solid ${theme.palette.gray.divider}`,
    color: theme.palette.gray.main,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchDiv: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: 80,
    padding: '0 1em',
    borderRight: `1px solid ${theme.palette.gray.divider}`,
    color: theme.palette.gray.main,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  recentSearches: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.speedDial,
    minHeight: 80,
    borderTop: `1px solid ${theme.palette.gray.divider}`,
    boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.12)',
  },
  searchText: {
    marginLeft: 20,
  },
  avatarRoot: {
    padding: `0 30px 0 12px`,
  },
  avatarTitle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileToggle: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
