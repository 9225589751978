import { connect } from 'react-redux';
import { actions } from '../../../../../store/pipelinesDetails';

function mapStateToProps(state, { item, index }) {
  return {
    settings: state.orgSettings,
  };
}

function mapDispatchToProps(dispatch) {
  const getOriginalOpportunity = async (params) => {
    await dispatch(actions.fetchOpportunitiesByPursuitId(params));
  };

  return { getOriginalOpportunity };
}

export default connect(mapStateToProps, mapDispatchToProps);
