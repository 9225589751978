import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';
import { config } from '../../services/api';
import { download } from '../../utils/filesUtils';
import PlanImg from '../../containers/Billing/BillingTab/images/plan.png';

export const INITIAL_STATE = {
  items: [],
  pending: false,
  count: 0,
  order: ['CreatedDate', 'desc'],
  currentProduct: null,
  defaultPaymentMethod: null,
  pendingCreate: false,
  currentModalityPlan: null,
  promoCode: { code: '' },
  plans: [],
  products: null,
  selectedPlan: null,
  selectedPlanPrice: null,
  seatPrice: null,
};

function onUpdateCurrentProduct(state, action) {
  return Object.assign(state, {
    currentProduct: action.payload,
  });
}

const updateCurrentProduct = createAction('billing/updateCurrentProduct');

const getCustomerBilling = createAsyncThunk('billing/getCustomerBilling', async () => {
  return application.call(API.BILLING.GET_CUSTOMER_BILLING, {});
});

const addPaymentMethod = createAsyncThunk('billing/addPaymentMethod', (params) => {
  return application.call(API.BILLING.ADD_PAYMENT_METHOD, params);
});

const updateBilling = createAsyncThunk('billing/updateBilling', (params) => {
  return application.call(API.BILLING.UPDATE_BILLING, params);
});

const removePaymentMethod = createAsyncThunk('billing/removePaymentMethod', (params) => {
  return application.call(API.BILLING.REMOVE_PAYMENT_METHOD, params);
});

const getPlans = createAsyncThunk('billing/getPlans', (params) => {
  return application.call(API.BILLING.GET_PLANS, params);
});
const setGetPlans = createAction('billing/setGetPlans');
function onGetPlans(state, action) {
  return Object.assign(state, {
    plans: action?.payload?.product ? [{ ...action.payload.product, image: PlanImg }] : [],
  });
}

const getProducts = createAsyncThunk('billing/getProducts', (params) => {
  return application.call(API.BILLING.GET_PRODUCTS, params);
});

function onGetProducts(state, action) {
  return Object.assign(state, {
    products: action?.payload ?? null,
    pending: false,
  });
}

const createSubscription = createAsyncThunk('billing/createSubscription', (params) => {
  return application.call(API.BILLING.CREATE_SUBSCRIPTION, params);
});

const getSubscription = createAsyncThunk('billing/getSubscription', (params) => {
  return application.call(API.BILLING.GET_SUBSCRIPTION_ID, params);
});

const getInvoices = createAsyncThunk('billing/getInvoices', async (params) => {
  return application.call(API.BILLING.GET_INVOICES, params);
});

const getInvoiceById = createAsyncThunk('billing/getInvoiceById', async (params) => {
  return application.call(API.BILLING.GET_INVOICE_BY_ID, params);
});

const getSeatPrice = createAsyncThunk('billing/getSeatPrice', async (params) => {
  return application.call(API.BILLING.GET_SEAT_PRICE, params);
});

const downloadInvoice = createAsyncThunk('billing/downloadInvoice', (params) => {
  return fetch(`${config.apiHost}/download-invoice?invoiceId=${params.id}`)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      download(blob, params.fileName);
    });
});

const cancelSubscription = createAsyncThunk('billing/cancelSubscription', (params) => {
  return application.call(API.BILLING.CANCEL_SUBSCRIPTION, params);
});

const setDefaultPaymentMethodAction = createAction('billing/setDefaultPaymentMethod');
function onSetDefaultPaymentMethod(state, action) {
  return Object.assign(state, {
    defaultPaymentMethod: action.payload,
  });
}

const setCurrentModalityPlan = createAction('billing/setCurrentModalityPlan');
function onSetCurrentModalityPlan(state, action) {
  return Object.assign(state, {
    currentModalityPlan: action.payload,
  });
}

const applyPromoCode = createAsyncThunk('billing/applyPromoCode', (params) => {
  return application.call(API.BILLING.APPLY_PROMO_CODE, params);
});

const setPromoCode = createAction('billing/setPromoCode');
function onSetPromoCode(state, action) {
  return Object.assign(state, {
    promoCode: action.payload,
  });
}

const shareInvoce = createAsyncThunk('billing/shareInvoice', (params) => {
  return application.call(API.BILLING.SHARE_INVOICE, params);
});

const upgradeDowngradeSubscription = createAsyncThunk('billing/upgradeDowngradeSubscription', (params) => {
  return application.call(API.BILLING.UPGRADE_DOWNGRADE_SUBSCRIPTION, params);
});

const updateSubscription = createAsyncThunk('billing/updateSubscription', (params) => {
  return application.call(API.BILLING.UPDATE_SUBSCRIPTION, params);
});

const setSelectedPlan = createAction('billing/setSelectedPlan');
function onSetSelectedPlan(state, action) {
  return Object.assign(state, {
    selectedPlan: action.payload,
  });
}

const setSelectedPlanPrice = createAction('billing/setSelectedPlanPrice');
function onSetSelectedPlanPrice(state, action) {
  return Object.assign(state, {
    selectedPlanPrice: action.payload,
  });
}

export const actions = {
  getCustomerBilling,
  addPaymentMethod,
  removePaymentMethod,
  updateBilling,
  getPlans,
  createSubscription,
  cancelSubscription,
  updateCurrentProduct,
  getInvoices,
  setDefaultPaymentMethodAction,
  getInvoiceById,
  getSeatPrice,
  downloadInvoice,
  setCurrentModalityPlan,
  getSubscription,
  applyPromoCode,
  setPromoCode,
  shareInvoce,
  upgradeDowngradeSubscription,
  updateSubscription,
  setGetPlans,
  getProducts,
  setSelectedPlan,
  setSelectedPlanPrice,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(updateCurrentProduct, onUpdateCurrentProduct);
  builder.addCase(setDefaultPaymentMethodAction, onSetDefaultPaymentMethod);
  builder.addCase(createSubscription.pending, (state, action) => {
    Object.assign(state, { pendingCreate: true });
  });
  builder.addCase(createSubscription.fulfilled, (state, action) => {
    Object.assign(state, { pendingCreate: false });
  });
  builder.addCase(getPlans.fulfilled, onGetPlans);
  builder.addCase(setCurrentModalityPlan, onSetCurrentModalityPlan);
  builder.addCase(setPromoCode, onSetPromoCode);
  builder.addCase(setGetPlans, onGetPlans);
  builder.addCase(getProducts.pending, (state) => {
    Object.assign(state, { pending: true });
  });
  builder.addCase(getProducts.fulfilled, onGetProducts);
  builder.addCase(setSelectedPlan, onSetSelectedPlan);
  builder.addCase(setSelectedPlanPrice, onSetSelectedPlanPrice);
});
