import React from 'react';
import Typography from '../common/Typography';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import { useTrans } from '../../services/i18n';
import { useIsMobile } from '../../hooks/useIsMobile';
import TermsAndConditions from '../../containers/Auth/SignIn/components/Termsandcond';
import poweredByUsfcr from '../assests/powered-by-usfcr.svg';

const FullPageLayout = ({ actions, children }) => {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const { trans } = useTrans();
  const now = new Date();

  return (
    <Box className={classes.flatContainer}>
      <Box className={classes.flatHeader}>
        <img src={poweredByUsfcr} alt="Powered By USFCR" />
        {actions && <div>{actions}</div>}
      </Box>
      <Box className={classes.flatContent}>{children}</Box>
      <div className={classes.flatFooter}>
        <Typography
          variant="body2"
          component="p"
          weight="500"
          style={{ textAlign: 'center', marginBottom: isMobile ? '.5rem' : 0 }}
        >
          {trans('copyright')} {now.getFullYear()} {trans('all-rights-reserved')}
        </Typography>

        <Box display="flex">
          <TermsAndConditions style={{ margin: 0 }} />
        </Box>
      </div>
    </Box>
  );
};

export default FullPageLayout;
