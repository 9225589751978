import { useState, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTrans } from '../../../services/i18n';
import { useAlertContext } from '../../../contexts/AlertContext';
import { TextField } from '../../../components/common/Inputs';
import { useForm } from '../../../hooks/useForm';
import { getInitialValues, useScheme } from './formik';
import { useApplication } from '../../../services/application';
import connect from '../connect';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Divider } from '@material-ui/core';
import SaveDialogAction from '../../../components/common/Dialogs/SaveDialogAction';

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
};

const useStyles = makeStyles((theme) => ({
  containerCard: {
    padding: '2.125rem 1.5rem',
  },
  containerFooter: {
    padding: '1.87rem 2.3rem',
    marginTop: 'unset',
  },
}));

const SamApiKey = ({ updateSamApiKey, auth }) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert } = useAlertContext();
  const [isSend, setIsSend] = useState(false);
  const application = useApplication();
  const [open, onOpen, onClose] = useBooleanHandlers();
  const [apiKey, setApiKey] = useState('');
  const styles = useStyles();

  const [currentApiKey, setCurrentApiKey] = useState('');

  const changeInput = (ev) => {
    setApiKey(ev.target.value);
  };

  useEffect(() => {
    setCurrentApiKey(auth?.samApiKey ? auth.samApiKey : '');
    setApiKey(' ');
    setApiKey('');
  }, [auth]);

  const onSubmit = useCallback(
    async (values) => {
      setIsSend(true);
      application
        .call('user.updateUserSamApiKey', { samApiKey: values.samApiKey })
        .then(async (res) => {
          if (res) {
            successAlert('Sam api key updated successfully');
            updateSamApiKey({ samApiKey: values.samApiKey });
            closeModal();
          }
        })
        .catch((e) => errorAlert(e.message))
        .finally(() => {
          setIsSend(false);
        });
    },
    [open, errorAlert, successAlert]
  );

  const { getFieldProps, handleSubmit, resetForm } = useForm(
    schema,
    getInitialValues({
      samApiKey: auth.samApiKey,
    }),
    onSubmit,
    formOptions
  );

  const changeApiKey = async () => {
    try {
      if (!apiKey) {
        errorAlert('The api key field cannot be empty');
        return;
      }
      setIsSend(true);
      application
        .call('user.updateUserSamApiKey', { samApiKey: apiKey })
        .then(async (res) => {
          if (res) {
            successAlert('Sam api key updated successfully');
            updateSamApiKey({ samApiKey: res.samApiKey });
            closeModal();
          }
        })
        .catch((e) => errorAlert(e.message))
        .finally(() => {
          setIsSend(false);
        });
    } catch (error) {
      setIsSend(false);
    }
  };

  const closeModal = () => {
    resetForm();
    onClose();
  };

  return (
    <>
      <Grid container spacing={3} className={styles.containerCard}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            label={trans('sam-api-key')}
            placeholder={currentApiKey ? currentApiKey : 'Paste your API key'}
            required
            maxLength={255}
            onChange={changeInput}
            value={apiKey}
          />
        </Grid>
      </Grid>
      <Divider light spacing={1} />
      <Grid container className={styles.containerFooter}>
        <SaveDialogAction formId="update-sam-api-key-edit" onSaveClick={changeApiKey} pending={isSend} />
      </Grid>
    </>
  );
};

export default connect(SamApiKey);
