import LazyLoad from '../../services/lazyLoad';
import { useMediaQuery } from '@material-ui/core';

const LazyComponent = LazyLoad({ loader: () => import('./Dashboard') });
const LazyComponentXs = LazyLoad({ loader: () => import('./DashboardMobile') });

export default function Dashboard() {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return isMobile ? <LazyComponentXs /> : <LazyComponent />;
}
