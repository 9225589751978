import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/listActionsHelpers';
import { onPending, onPendingDone } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  userFiles: {
    ...INITIAL_LIST_STATE_EXTENDED,
    order: ['alphabetical', 'asc'],
  },
  gvfiles: {
    ...INITIAL_LIST_STATE_EXTENDED,
    order: ['alphabetical', 'asc'],
  },
  firstLoading: false,
  firstLoadingGF: false,
};

//Actions
const getUserFiles = createAsyncThunk('pursuits/getUserFilesByPursuit', (params) => {
  const { filters, order, pagination } = INITIAL_STATE;
  return application.call(API.PURSUITS.GET_USER_FILES, {
    filters,
    order,
    pagination,
    pursuitId: params.pursuitId,
    text: params.text,
  });
});

const getGovernmentFiles = createAsyncThunk('pursuits/getGovernmentFilesByPursuit', (params) => {
  const { filters, order, pagination } = INITIAL_STATE;
  return application.call(API.PURSUITS.GET_GOVERNMENT_FILES, {
    filters,
    order,
    pagination,
    pursuitId: params.pursuitId,
    text: params.text,
  });
});

const getUrlFileUpload = createAsyncThunk('pursuits/getUrlFileUpload', (params) => {
  return application.call(API.PURSUITS.GET_URL_UPLOAD_FILE, params);
});

const uploadFiles = createAsyncThunk('pursuits/uploadFiles', (params) => {
  return application.call(API.PURSUITS.UPLOAD_FILES, params);
});

const editFile = createAsyncThunk('pursuits/editFile', (params) => {
  return application.call(API.PURSUITS.EDIT_FILE, params);
});

const uploadVersion = createAsyncThunk('pursuits/uploadVersion', (params) => {
  return application.call(API.PURSUITS.UPLOAD_FILE_VERSION, params);
});

const deleteFile = createAsyncThunk('pursuits/deleteFile', (params) => {
  return application.call(API.PURSUITS.DELETE_FILE, params);
});

//Reducers
function onFetchList(state, action) {
  Object.assign(state.userFiles, {
    items: action.payload.items,
    pending: false,
    count: action.payload.count,
  });
  state.firstLoading = true;
}

function onFetchListGF(state, action) {
  Object.assign(state.gvfiles, {
    items: action.payload.items,
    pending: false,
    count: action.payload.count,
  });
  state.firstLoadingGF = true;
}

function onPushFiles(state, action) {
  if (action.payload.success) {
    Object.assign(state.userFiles, {
      items: [...state.userFiles.items, action.payload.file],
      pending: false,
      count: state.userFiles.count + 1,
    });
  }
}

function onEditFile(state, action) {
  const index = state.userFiles.items.findIndex((i) => i.id === action.payload.file.id);
  const cloneState = [...state.userFiles.items];
  cloneState[index].shortName = action.payload.file.shortName;
  Object.assign(state.userFiles, {
    items: cloneState,
    pending: false,
  });
}

function onDeleteFile(state, action) {
  const index = state.userFiles.items.findIndex((i) => i.id === action.payload.id);
  const cloneState = [...state.userFiles.items];
  cloneState.splice(index, 1);
  Object.assign(state.userFiles, {
    items: JSON.parse(JSON.stringify(cloneState)),
    pending: false,
    count: state.userFiles.count - 1,
  });
}

export const actions = {
  getUserFiles,
  getGovernmentFiles,
  uploadFiles,
  getUrlFileUpload,
  editFile,
  deleteFile,
  uploadVersion,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return builder
    .addCase(getUserFiles.pending, onPending)
    .addCase(getUserFiles.rejected, onPendingDone)
    .addCase(getUserFiles.fulfilled, onFetchList)
    .addCase(getGovernmentFiles.pending, onPending)
    .addCase(getGovernmentFiles.rejected, onPendingDone)
    .addCase(getGovernmentFiles.fulfilled, onFetchListGF)
    .addCase(uploadFiles.pending, onPending)
    .addCase(uploadFiles.rejected, onPendingDone)
    .addCase(uploadFiles.fulfilled, onPushFiles)
    .addCase(getUrlFileUpload.pending, onPending)
    .addCase(getUrlFileUpload.rejected, onPendingDone)
    .addCase(getUrlFileUpload.fulfilled, onPushFiles)
    .addCase(deleteFile.pending, onPending)
    .addCase(deleteFile.rejected, onPendingDone)
    .addCase(deleteFile.fulfilled, onDeleteFile)
    .addCase(editFile.fulfilled, onEditFile);
});
