import React from 'react';
import { Grid } from '@material-ui/core';

import connect from '../connect';
import Members from './Members';
import Note from './Note';
import Naics from './Naics';

const Notes = ({
  vendorInfo,
  vendorNoteMemberListActions,
  vendorNoteNotesListActions,
  vendorNoteNaicsListActions,
  members,
  notes,
  naics,
  removeVendorMember,
  removeVendorNote,
  removeVendorNaics,
  currentUserId,
  currentUserName,
}) => {
  return (
    <Grid container justifyContent="space-between" spacing={2} alignItems="flex-start">
      <Grid container spacing={3}>
        <Grid item id="members" xs={12}>
          <Members
            xs={12}
            item={vendorInfo}
            members={members}
            actions={vendorNoteMemberListActions}
            removeVendorMember={removeVendorMember}
          />
        </Grid>
        <Grid item id="note" xs={12}>
          <Note
            item={vendorInfo}
            notes={notes}
            actions={vendorNoteNotesListActions}
            removeVendorNote={removeVendorNote}
            currentUserId={currentUserId}
            currentUserName={currentUserName}
          />
        </Grid>
        <Grid item id="naics" xs={12}>
          <Naics
            item={vendorInfo}
            naics={naics}
            actions={vendorNoteNaicsListActions}
            removeVendorNaics={removeVendorNaics}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(Notes);
