import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Button } from '../../../../../components/common/Buttons';
import { CloseTitle, InfoContent, ModalDialog } from '../../../../../components/common/Dialogs';
import Typography from '../../../../../components/common/Typography';
import { useTrans } from '../../../../../services/i18n';
import useStyles from '../../../styles';

export const NeedHelpDialog = ({ open, onClose, onResetClick }) => {
  const { trans } = useTrans();
  const classes = useStyles();

  return (
    <ModalDialog open={open} onClose={onClose} maxWidth="sm" DialogTitle={<CloseTitle onClose={onClose} />}>
      <InfoContent>
        <Grid container spacing={3} justifyContent="center" className={classes.content}>
          <Grid item xs={12}>
            <Typography variant="h2" fontFamily="lexend" weight="600">
              {trans('having-trouble-logging-in')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography weight="500" variant="body1">
              {trans('having-trouble-logging-in-text')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontFamily="lexend" weight="600" variant="h4" gutter="title">
              {trans('still-not-working')}
            </Typography>
            <Typography weight="500" variant="body1" gutter="title" className={classes.modalText}>
              {trans('it-might-be-time-to-reset')}
            </Typography>
            <Button id="reset-password-ok" variant="outlined" color="primary" onClick={onResetClick}>
              {trans('reset-password')}
            </Button>
          </Grid>
        </Grid>
      </InfoContent>
    </ModalDialog>
  );
};
