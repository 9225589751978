import { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField } from '../../../../../components/common/Inputs';
import { useTrans } from '../../../../../services/i18n';
import { Button } from '../../../../../components/common/Buttons';
import { getInitialValues, useScheme } from './formik';
import { useForm } from '../../../../../hooks/useForm';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import connect from '../../../connect';

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  validateOnSubmit: true,
};

const PromoCode = ({ step = null, promoCode, onApplyPromoCode, setPromoCode, onRenderDiscount }) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const { errorAlert } = useAlertContext();
  const [pending, setPending] = useState(false);
  const [validCode, setValidCode] = useState(null);

  const apply = useCallback(
    (values) => {
      if (onApplyPromoCode && typeof onApplyPromoCode !== 'function') return;
      setPending(true);
      Promise.resolve(onApplyPromoCode(values))
        .then(({ payload: { success, message, data } }) => {
          if (!success) errorAlert(message);
          setPromoCode({ code: success ? data.code : '', id: data?.id });
          setValidCode(success);
          onRenderDiscount(success ? data.coupon : null);
        })
        .finally(() => setPending(false));
    },
    [onApplyPromoCode, onRenderDiscount, errorAlert, setPromoCode]
  );

  const { getFieldProps, handleSubmit, setFieldValue, values } = useForm(
    schema,
    getInitialValues(promoCode),
    apply,
    formOptions
  );

  const clearCode = () => {
    setFieldValue('code', '');
    setPromoCode({ code: '' });
    setValidCode(null);
    onRenderDiscount(null);
  };

  const InputProps = {
    startAdornment: (
      <InputAdornment position="start" style={{ marginTop: 0 }}>
        {validCode === true ? (
          <CheckCircleOutlineIcon style={{ color: '#17b471' }} />
        ) : (
          <ErrorOutlineIcon style={{ color: '#ff5225' }} />
        )}
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
        <IconButton disabled={step === 'information'} onClick={clearCode} size="small">
          <HighlightOffIcon />
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item lg={9} md={9} xs={12}>
        <TextField
          label={trans('promo-code')}
          placeholder={trans('enter-promo-code')}
          InputProps={validCode !== null && values.code ? InputProps : undefined}
          required
          {...getFieldProps('code')}
        />
      </Grid>
      <Grid item lg={3} md={3} xs={12}>
        <Button
          variant="outlined"
          color="primary"
          pending={pending}
          onClick={handleSubmit}
          fullWidth
          size="large"
          style={{ marginTop: '1.8rem' }}
          // disabled={step === 'information'}
        >
          {trans('apply')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default connect(PromoCode);
