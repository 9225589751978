import { useCallback, useEffect, useState } from 'react';
import { API } from '../../../../../constants';
import { application } from '../../../../../services/application';
import useActions from '../../../../../hooks/dispatchers/useActions';
import { actions } from '../../../../../store/tasks';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';

export const useTasksActions = (item) => {
  const [onEditTask] = useActions([actions.editTask]);
  const [searchTags] = useActions([actions.searchTagsForTasks]);

  const [open, onOpen, onClose] = useBooleanHandlers(false);
  const [itemTask, setItemTask] = useState(null);

  const fetchTaskList = useCallback(async () => {
    const params = {
      pursuitId: item?.pursuitId ?? 0,
      taskId: item?.itemId ?? 0,
    };
    const tasksList = (await application.call(API.TASKS.GET_LIST, params))?.items;
    setItemTask(tasksList.length > 0 ? tasksList[0] : null);
  }, [item]);

  useEffect(() => {
    fetchTaskList();
  }, [fetchTaskList]);

  const handleEdit = useCallback(
    async (params) => {
      const result = await onEditTask(params);
      await fetchTaskList();
      return result;
    },
    [onEditTask, fetchTaskList]
  );

  return {
    itemTask,
    open,
    onOpen,
    onClose,
    onEditTask: handleEdit,
    searchTags,
  };
};
