import React, { useEffect, useState } from 'react';
import { ModalDialog } from '../../../components/common/Dialogs';
import { useTrans } from '../../../services/i18n';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import { Button } from '../../../components/common/Buttons';
import Typography from '../../../components/common/Typography';
import { AmplifyAuth } from '../../../services/amplifyAuth';
import { AUTH_NOMFA } from '../../../constants';
import { SpacingContainer } from '../../../components/common/Containers/Base';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { Divider } from '../../../components/common/Divider';
import MessageIcon from '@material-ui/icons/Message';
import { IconQrCodeParams } from '../../../components/svg/Icons';
import SmsFa from './SmsFa';
import AuthenticatorAppFa from './AuthenticatorAppFa';
import SuccessMfa from './SuccessMfa';
import { CodeInput } from '../../../components/common/Inputs';
import { Grid } from '@material-ui/core';
import { useAlertContext } from '../../../contexts/AlertContext';

const useStyles = makeStyles((theme) => ({
  containerCard: {
    padding: '1rem 1.5rem 0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  iconColor: {
    fill: theme.palette.primary.main,
  },
  marginLeft: {
    marginLeft: '0.5rem',
  },
}));

const MFA_METHOD = {
  SMS: 'SMS_MFA',
  APP: ['SOFTWARE_TOKEN_MFA', 'TOTP'],
};

export default function TwoFactorAuthentication({ auth, onUpdate, updatePhone }) {
  const { trans } = useTrans();
  const { mfaEnabled, phone } = auth;
  const [open, onOpenModal, onCloseModal] = useBooleanHandlers();
  const [qrCode, setQrCode] = React.useState(undefined);
  const [twoFaType, setTwoFaType] = React.useState(null);
  const [pendingDisabled, setPendingDisabled] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [openTwoFactorModal, setOpenTwoFactorModal] = useState(false);
  const [code, setCode] = useState('');
  const [pending, setPending] = useState(false);
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const [pendingSendCode, setPendingSendCode] = useState(false);
  const styles = useStyles();

  const onOpen = React.useCallback(() => {
    if (!mfaEnabled) {
      AmplifyAuth.currentAuthenticatedUser()
        .then((user) => {
          const { preferred_username: username } = user.attributes;
          const issuer = trans('my-app-short');
          return AmplifyAuth.setupTOTP(user).then((code) =>
            setQrCode('otpauth://totp/' + username + '?secret=' + code + '&issuer=' + issuer)
          );
        })
        .catch(console.warn);
    }
    return onOpenModal();
  }, [mfaEnabled, onOpenModal, trans]);

  const onDisable = () => {
    setPendingDisabled(true);

    AmplifyAuth.currentAuthenticatedUser()
      .then((user) => AmplifyAuth.setPreferredMFA(user, AUTH_NOMFA))
      .then(() => {
        onUpdate(AUTH_NOMFA);
        setOpenTwoFactorModal(false);
        setPending(false);
        setCode('');
        successAlert(trans('two-factor-disabled-successfully'));
      })
      .finally(() => {
        setPendingDisabled(false);
        setPending(false);
      })
      .catch(console.warn);
  };

  const onMfaSubmit = () => {
    setPending(true);

    if (auth.preferredMFA == MFA_METHOD.SMS) {
      AmplifyAuth.verifyCurrentUserAttributeSubmit('phone_number', code)
        .then(() => {
          onDisable();
        })
        .catch(() => {
          setPending(false);
          errorAlert(trans('incorrect-authentication-code'));
        }); // Should it be translated here?
    } else {
      AmplifyAuth.currentAuthenticatedUser()
        .then((user) => {
          return AmplifyAuth.verifyTotpToken(user, code)
            .then(() => {
              onDisable();
            })
            .catch(() => {
              setPending(false);
              errorAlert(trans('incorrect-authentication-code'));
            }); // Should it be translated here?
        })
        .catch(console.warn);
    }
  };

  const showModalDisabled = () => {
    setOpenTwoFactorModal(true);
    resendCode();
  };

  const resendCode = () => {
    if (auth.preferredMFA == MFA_METHOD.SMS) {
      setPendingSendCode(true);
      AmplifyAuth.verifyCurrentUserAttribute('phone_number')
        .finally(() => {
          setPendingSendCode(false);
          successAlert(trans('we-sent-you-a-code'));
        })
        .catch((e) => console.log(e.message));
    }
  };

  const hadleCancel = () => {
    onCloseModal();
    setTwoFaType(null);
    setSuccess(false);
  };

  const onSuccess = () => {
    setTwoFaType(null);
    setSuccess(true);
  };

  const onOpenEnable = (method) => {
    //Validate id another method is enabled
    if (auth.mfaEnabled) {
      if (method === 'app' && !MFA_METHOD.APP.includes(auth.preferredMFA)) {
        infoAlert(trans('disable-sms'));
        return;
      }
      if (method === 'sms' && auth.preferredMFA !== MFA_METHOD.SMS) {
        infoAlert(trans('disable-app'));
        return;
      }
    }
    setTwoFaType(method);
    onOpen();
  };

  const onOpenDisable = (method) => {
    showModalDisabled();
  };

  useEffect(() => {
    if (twoFaType === 'app') onOpen();
  }, [twoFaType]);

  return (
    <React.Fragment>
      <Grid container spacing={3} className={styles.containerCard}>
        <Grid item lg={12} md={12} xs={12}>
          <List>
            <ListItem disableGutters>
              <MessageIcon color="primary" />
              <ListItemText className={styles.marginLeft}>
                <Typography variant="h6" component="p" weight="600">
                  {trans('sms')}
                </Typography>
              </ListItemText>
              {auth.mfaEnabled && auth.preferredMFA === MFA_METHOD.SMS ? (
                <Button color="error" variant="outlined" onClick={() => onOpenDisable('sms')}>
                  {trans('disable')}
                </Button>
              ) : (
                <Button color="success" variant="outlined" onClick={() => onOpenEnable('sms')}>
                  {trans('enable')}
                </Button>
              )}
            </ListItem>
            <Divider light spacing={1} />
            <ListItem disableGutters>
              <IconQrCodeParams className={styles.iconColor} />
              <ListItemText className={styles.marginLeft}>
                <Typography variant="h6" component="p" weight="600">
                  {trans('authenticator-app')}
                </Typography>
              </ListItemText>
              {auth.mfaEnabled && MFA_METHOD.APP.includes(auth.preferredMFA) ? (
                <Button color="error" variant="outlined" onClick={() => onOpenDisable('app')}>
                  {trans('disable')}
                </Button>
              ) : (
                <Button color="success" variant="outlined" onClick={() => onOpenEnable('app')}>
                  {trans('enable')}
                </Button>
              )}
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <ModalDialog open={open} onClose={hadleCancel} title={trans('two-factor-authentication')}>
        <SpacingContainer py={0}>
          {twoFaType === 'sms' && !success && (
            <SmsFa userPhone={phone} updateMFA={onUpdate} updatePhone={updatePhone} setSuccess={onSuccess} />
          )}
          {twoFaType === 'app' && !success && (
            <AuthenticatorAppFa qrCode={qrCode} updateMFA={onUpdate} setSuccess={onSuccess} />
          )}
          {success && <SuccessMfa type={twoFaType} />}
        </SpacingContainer>
      </ModalDialog>

      <ModalDialog
        open={openTwoFactorModal}
        onClose={() => setOpenTwoFactorModal(false)}
        title={trans('two-factor-authentication')}
      >
        <Grid container direction="column" alignItems="center">
          <CodeInput type="number" onChange={setCode} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography color="hint" align="center" weight="600" variant="body2" gutter="verticalSpaceBig">
              {auth.preferredMFA == 'SMS_MFA'
                ? trans('enter-code-displayed-in-sms')
                : trans('enter-code-displayed-in-application')}
            </Typography>

            {auth.preferredMFA === MFA_METHOD.SMS && (
              <Button
                style={{ marginLeft: '16px' }}
                variant="outlined"
                color="primary"
                size="small"
                pending={pendingSendCode}
                onClick={resendCode}
              >
                {trans('resend-code')}
              </Button>
            )}
          </div>
          <Button
            id="code-confirm"
            variant="contained"
            color="primary"
            size="large"
            onClick={onMfaSubmit}
            disabled={code.length !== 6}
            pending={pending}
          >
            {trans('confirm')}
          </Button>
        </Grid>
      </ModalDialog>
    </React.Fragment>
  );
}
