import { useEffect, useState } from 'react';
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase';
import { SpacingContainer } from '../../../components/common/Containers';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import { ColoredLogoSvg } from '../../../components/svg/logos';
import { Card } from '@material-ui/core';
import FormCheckout from './components/FormCheckout';
import styles from '../styles.module.css';
import { useTrans } from '../../../services/i18n';
import connect from '../connect';
import { Divider } from '../../../components/common/Divider';
import { useParams } from 'react-router-dom';
import { useApplication } from '../../../services/application';

const breadcrumbs = ['checkout', 'information', 'payments'];

const Checkout = (props) => {
  const { trans } = useTrans();
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState('checkout');
  const handleNextStep = (step) => setCurrentBreadcrumb(step);
  const application = useApplication();
  const [quote, setQuote] = useState(null);

  const { type, id } = useParams();

  const getQuote = async () => {
    const res = await application.call('quoteRequests.findOne', { id });
    setQuote(res.quoteRequest);
  };

  useEffect(() => {
    if (type === 'quote') {
      getQuote();
    }
  }, [type]);

  return (
    <SpacingContainerBase>
      <SpacingContainer py={2}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item>
            <Typography weight="600" variant="h2" fontFamily="lexend" title>
              {trans('checkout')}
            </Typography>
          </Grid>
          <Grid item>
            <ColoredLogoSvg />
          </Grid>
        </Grid>

        <Card elevation={0} className={styles.containerCardCheckout}>
          <div className={styles.containerBreadcrumbs}>
            <SpacingContainer px={5} py={4}>
              <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
                {breadcrumbs.map((b, index) => (
                  <Typography
                    key={index}
                    color={currentBreadcrumb === b ? 'primary' : 'inherit'}
                    variant="h4"
                    fontFamily="lexend"
                    weight="500"
                  >
                    {trans(b)}
                  </Typography>
                ))}
              </Breadcrumbs>
            </SpacingContainer>
          </div>

          <Divider light />

          <SpacingContainer px={5} py={5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormCheckout
                  quote={quote}
                  type={type}
                  id={id}
                  breadcrumb={currentBreadcrumb}
                  onNextStep={handleNextStep}
                />
              </Grid>
            </Grid>
          </SpacingContainer>
        </Card>
      </SpacingContainer>
    </SpacingContainerBase>
  );
};

export default connect(Checkout);
