import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { BackDrop as Blur, Paper, SpacingContainer } from '../../../../../components/common/Containers';
import SectionScrollable from '../../../../../components/common/Containers/SectionScrollable';
import Typography from '../../../../../components/common/Typography';
import { IconDrag, IconTriage } from '../../../../../components/svg/Icons';
import { DRAG_STAGE } from '../../../../../constants/other';
import useDragAndDropTarget from '../../../../../hooks/dnd/useDragAndDropTarget';
import { useTrans } from '../../../../../services/i18n';
import StageItemMenu from './Menu';
import PursuitList from './PursuitList/PursuitList';
import connect from './connect';
import { formatNumberToUsd } from '../../../../../utils/helpers';
import { Button } from '../../../../../components/common/Buttons';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import StorePursuitForm from '../../../../../components/forms/StorePursuitForm';

function StageItem({
  item,
  onMoveCard,
  onDropCard,
  index,
  deleteStage,
  editStage,
  pursuits,
  addStages,
  deletePursuit,
  changePursuitStatus,
  settings,
}) {
  const { trans } = useTrans();
  const styles = useStyles();
  const isDraggable = typeof onMoveCard === 'function'; // && settings.userRole === 'Admin'

  const title = item?.type ? trans(item.name) : item.name || trans(`Unnamed`);
  const estimatedValue = React.useMemo(
    () =>
      pursuits
        .map((pursuit) => (pursuit?.estimatedValue ? parseFloat(pursuit?.estimatedValue) : 0))
        .reduce((acc, curr) => acc + curr, 0),
    [pursuits]
  );
  const probableValue = React.useMemo(() => {
    return (
      pursuits
        .map((pursuit) => ({
          wp: pursuit?.winProbability ? parseFloat(pursuit?.winProbability) : 0,
          ev: pursuit?.estimatedValue ? parseFloat(pursuit?.estimatedValue) : 0,
        }))
        .reduce((acc, curr) => acc + curr.wp * curr.ev, 0) / 100
    );
  }, [pursuits]);
  const { dragAndDropRef, dragOver } = useDragAndDropTarget(
    { type: DRAG_STAGE, item },
    {
      accept: DRAG_STAGE,
      drop: onDropCard,
      hover: ({ item: dragItem }) => isDraggable && onMoveCard({ dragItem, hoverItem: item }),
    }
  );

  const [openForm, onOpenAddForm, onCloseForm] = useBooleanHandlers();
  const conditionWhenPursuitAdd = {
    pipelineId: item.pipelineId,
  };

  return (
    <SpacingContainer px={1} py={1}>
      <Paper ref={isDraggable ? dragAndDropRef : undefined} className={styles.card} hidden radius={8}>
        {dragOver && <Blur open={dragOver} absolute />}
        <SectionScrollable
          pending={false}
          bgColor="pipeline"
          TopFixed={
            <Paper
              bgColor="pipelineLight"
              square
              component={SpacingContainer}
              px={2}
              py={2}
              className={isDraggable ? 'draggable' : undefined}
            >
              <Grid container alignItems="center" spacing={1} justifyContent="space-between">
                <Grid item>
                  <Grid container alignItems="center" spacing={1} wrap={'nowrap'}>
                    <Grid item>{item.type === 'triage' ? <IconTriage /> : <IconDrag />}</Grid>
                    <Grid item>
                      <Typography variant="h5" weight="500" fontFamily="lexend" style={{ wordBreak: 'break-word' }}>
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    {settings && ['Admin', 'Owner'].includes(settings.userRole) && (
                      <Grid item>
                        <Button onClick={onOpenAddForm} variant="outlined" size="small">
                          <Typography variant="button" color="white">
                            {trans('Pursuit')}
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <StageItemMenu
                        item={item}
                        title={title}
                        pending={false}
                        onDelete={deleteStage}
                        onEdit={editStage}
                        addStages={addStages}
                        index={index}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          }
          BottomFixed={
            <Paper bgColor="pipelineLight" square component={SpacingContainer} px={2} py={2}>
              <Typography variant="button" component="p">
                <Typography variant="button" component="span" weight="bold">
                  {formatNumberToUsd(estimatedValue)}
                </Typography>
                {` ${trans('EstValue')} • `}
                <Typography variant="button" component="span" weight="bold">
                  {formatNumberToUsd(probableValue)}
                </Typography>
                {` ${trans('ProbableValue')}`}
              </Typography>
              <Typography variant="button" component="p" color="hint" style={{ wordBreak: 'break-word' }}>
                {item?.type === 'triage' ? trans('ThisStageForPursuitsYouTriaged') : item?.description}
              </Typography>
            </Paper>
          }
        >
          <PursuitList stageId={item.id} deletePursuit={deletePursuit} changePursuitStatus={changePursuitStatus} />
        </SectionScrollable>
      </Paper>
      <StorePursuitForm
        stage={item}
        open={openForm}
        onClose={onCloseForm}
        conditionFreshSuccess={conditionWhenPursuitAdd}
      />
    </SpacingContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    width: 400,
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: 320,
    },

    ...theme.mixins.scrollBarColor(theme.palette.pipeline.dark, theme.palette.pipeline.main),
  },
}));

export default connect(StageItem);
