import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const ColoredLogoSvg = (props) => {
  const { width, height = 21, short = false } = props;

  return short ? (
    <SvgIcon width={width || 23} height={height} viewBox="0 0 23 21">
      <path
        d="M10.483,0 C10.195,0.981 9.814,1.862 9.441,2.546 L9.573,2.861 C10.771,5.667 13.013,9.754 16.982,13.711 C17.586,12.744 18.124,11.791 18.602,10.865 C15.079,7.086 13.018,2.689 11.869,0 L10.483,0 Z M13.387,0 C14.474,2.432 16.298,6.284 19.247,9.562 C19.836,8.314 20.317,7.136 20.705,6.068 C19.066,4.178 17.782,1.708 16.783,0 L13.387,0 L13.387,0 Z M5.527,8.31 C4.881,9.09 4.174,9.875 3.405,10.656 C5.019,13.824 7.328,17.338 10.605,20.566 C10.849,20.806 11.265,20.806 11.508,20.566 C12.087,19.996 12.635,19.418 13.154,18.834 C9.382,15.164 6.998,11.347 5.527,8.31 L5.527,8.31 Z M21.5,0 L18.39,0 C19.164,1.263 20.098,3.206 21.22,4.569 C21.691,3.094 21.964,1.338 22.104,0.663 C22.175,0.32 21.884,0 21.499,0 L21.5,0 Z M8.576,4.044 C7.978,5.024 7.286,6.05 6.491,7.096 C7.855,10.083 10.183,14.018 14.034,17.807 C14.841,16.83 15.571,15.845 16.232,14.865 C12.272,11.003 9.918,6.989 8.576,4.044 Z"
        id="Shape"
        fill="#E2222E"
      />
      <path
        d="M5.331,4.803 L4.043,3.867 L2.754,4.803 L3.246,3.289 L1.957,2.352 L3.551,2.352 L4.043,0.837 L4.535,2.352 L6.128,2.352 L4.839,3.289 L5.331,4.803 Z M0.615,0 C0.23,0 -0.061,0.319 0.011,0.664 C0.111,1.146 0.279,1.957 0.539,2.977 L0.559,3.055 C0.812,4.035 1.149,5.201 1.59,6.45 L1.592,6.455 C1.924,7.393 2.315,8.378 2.775,9.365 C6.153,6.103 8.058,2.394 8.78,0.8 L8.78,0.8 C8.788,0.786 8.806,0.742 8.831,0.683 C9.025,0.25 9.118,0 9.118,0 L0.615,0 Z"
        id="Shape"
        fill="#112159"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width || 122} height={height} viewBox="0 0 122 21">
      <path
        fill="#E2222E"
        d="M33.261 20.485c-1.521 0-2.85-.173-3.985-.519-1.136-.345-2.08-.83-2.83-1.451-.75-.623-1.314-1.363-1.689-2.223-.375-.859-.563-1.812-.563-2.859 0-.158.015-.375.045-.652.03-.276.064-.493.104-.652L26.95.367h5.185L29.557 12.01c-.02.079-.04.192-.059.34-.02.149-.03.312-.03.49 0 .414.084.814.252 1.2.168.385.405.726.711 1.022.306.296.697.533 1.17.71.475.178 1.028.268 1.66.268 1.363 0 2.41-.38 3.14-1.141.731-.76 1.225-1.723 1.482-2.89L40.461.368h5.156L43.039 12.1c-.277 1.205-.652 2.316-1.126 3.333-.474 1.018-1.096 1.902-1.867 2.652-.77.75-1.714 1.338-2.83 1.763-1.116.424-2.434.637-3.955.637M54.061 20.485c-1.008 0-1.98-.089-2.919-.267-.938-.177-1.812-.434-2.622-.77-.81-.336-1.54-.73-2.192-1.185-.652-.454-1.205-.968-1.66-1.541l3.082-3.793c.691.91 1.644 1.645 2.86 2.208 1.214.563 2.503.844 3.866.844.83 0 1.417-.167 1.763-.503.345-.336.518-.672.518-1.008 0-.355-.237-.662-.71-.918-.475-.257-1.063-.504-1.764-.741-.701-.237-1.466-.499-2.296-.785-.83-.286-1.596-.652-2.296-1.097-.702-.444-1.29-.992-1.763-1.644-.474-.652-.712-1.471-.712-2.46 0-.829.178-1.648.534-2.459.355-.81.874-1.535 1.555-2.177.682-.642 1.526-1.156 2.534-1.541C52.846.263 54.002.07 55.305.07c1.6 0 3.126.277 4.578.83 1.452.553 2.682 1.353 3.689 2.4l-3.11 3.703c-.396-.414-.836-.78-1.32-1.096-.484-.316-.988-.578-1.51-.785-.524-.207-1.053-.36-1.586-.46-.533-.098-1.047-.147-1.54-.147-.574 0-1.038.128-1.393.385-.356.257-.534.583-.534.977 0 .337.238.623.712.86s1.066.479 1.778.726l2.296.8c.819.286 1.585.657 2.296 1.11.711.456 1.304 1.008 1.778 1.66.474.652.711 1.452.711 2.4 0 .968-.188 1.877-.563 2.726-.376.85-.91 1.596-1.6 2.237-.692.642-1.54 1.15-2.548 1.526-1.008.375-2.134.563-3.378.563"
        transform="translate(-30 -40) translate(30 40)"
      />
      <path
        fill="#112159"
        d="M63.364 20.129L67.72.367 82.209.367 81.231 4.811 71.838 4.811 71.186 7.892 80.342 7.892 79.364 12.337 70.179 12.337 68.461 20.129zM91.808 20.485c-1.402 0-2.71-.208-3.926-.622-1.214-.415-2.271-1.012-3.17-1.793-.899-.78-1.61-1.723-2.133-2.83-.524-1.105-.786-2.35-.786-3.733 0-1.718.311-3.284.934-4.696.622-1.412 1.467-2.622 2.533-3.63C86.327 2.174 87.566 1.4 88.98.855 90.39.312 91.897.04 93.497.04c1.323 0 2.494.174 3.512.519 1.016.346 1.9.8 2.651 1.363.75.563 1.368 1.21 1.852 1.94.484.732.854 1.482 1.111 2.253l-4.918 1.6c-.277-1.008-.815-1.788-1.615-2.341-.8-.553-1.744-.83-2.83-.83-.87 0-1.679.173-2.43.519-.75.345-1.402.82-1.955 1.422-.553.603-.988 1.309-1.304 2.118-.316.81-.474 1.67-.474 2.578 0 .672.119 1.3.356 1.882.237.583.568 1.091.992 1.526.425.434.949.775 1.57 1.022.623.247 1.32.37 2.09.37.394 0 .8-.054 1.215-.163.414-.108.814-.266 1.2-.474.385-.207.74-.449 1.066-.726.326-.276.607-.582.844-.918l3.941 2.607c-.592.81-1.244 1.482-1.955 2.015-.711.533-1.447.963-2.208 1.289-.76.326-1.521.553-2.281.682-.76.128-1.467.192-2.119.192M113.29 9.048c.889 0 1.59-.212 2.103-.637.514-.425.77-1.002.77-1.734 0-.552-.207-1.002-.622-1.348-.414-.345-.899-.518-1.452-.518h-3.91l-.919 4.237h4.03zm-.356 11.082l-1.837-6.638h-2.844L106.8 20.13h-5.096L106.06.366h9.007c.85 0 1.66.134 2.43.4.77.267 1.452.652 2.045 1.156.592.504 1.061 1.111 1.407 1.822.345.711.518 1.511.518 2.4s-.138 1.714-.414 2.474c-.277.76-.652 1.447-1.126 2.06-.474.612-1.033 1.125-1.674 1.54-.643.415-1.329.722-2.06.919l2.37 6.993h-5.629z"
        transform="translate(-30 -40) translate(30 40)"
      />
      <path
        fill="#E2222E"
        d="M10.483 0c-.288.981-.669 1.862-1.042 2.546l.132.315c1.198 2.806 3.44 6.893 7.409 10.85.604-.967 1.142-1.92 1.62-2.846C15.079 7.086 13.018 2.689 11.869 0h-1.386zm2.904 0c1.087 2.432 2.911 6.284 5.86 9.562.589-1.248 1.07-2.426 1.458-3.494-1.639-1.89-2.923-4.36-3.922-6.068h-3.396zm-7.86 8.31c-.646.78-1.353 1.565-2.122 2.346 1.614 3.168 3.923 6.682 7.2 9.91.244.24.66.24.903 0 .579-.57 1.127-1.148 1.646-1.732-3.772-3.67-6.156-7.487-7.627-10.524zM21.5 0h-3.11c.774 1.263 1.708 3.206 2.83 4.569.471-1.475.744-3.231.884-3.906C22.175.32 21.884 0 21.499 0zM8.576 4.044c-.598.98-1.29 2.006-2.085 3.052 1.364 2.987 3.692 6.922 7.543 10.711.807-.977 1.537-1.962 2.198-2.942-3.96-3.862-6.314-7.876-7.656-10.821z"
        transform="translate(-30 -40) translate(30 40)"
      />
      <path
        fill="#112159"
        d="M5.331 4.803l-1.288-.936-1.289.936.492-1.514-1.289-.937h1.594L4.043.837l.492 1.515h1.593l-1.289.937.492 1.514zM.615 0C.23 0-.061.319.011.664c.1.482.268 1.293.528 2.313l.02.078c.253.98.59 2.146 1.031 3.395l.002.005c.332.938.723 1.923 1.183 2.91C6.153 6.103 8.058 2.394 8.78.8V.8c.008-.014.026-.058.051-.117C9.025.25 9.118 0 9.118 0H.615z"
        transform="translate(-30 -40) translate(30 40)"
      />
    </SvgIcon>
  );
};

ColoredLogoSvg.propTypes = simpleIconPropsTypes;
