import React from 'react';
import { useSubscriptionStatus } from '../../../../hooks/useSubscriptionStatus';
import { SUBSCRIPTION_STATUS } from '../../../../utils/constants';
//import { SubscriptionContext } from '../SubscribedOnly/context';

export const useSubscribedOnly = (subscription, setOpenModalPremium = () => {}) => {
  //const { open } = React.useContext(SubscriptionContext);
  const { pending, data } = useSubscriptionStatus(subscription);
  return {
    pending,
    data,
    subscribed: [SUBSCRIPTION_STATUS.SUBSCRIBED, SUBSCRIPTION_STATUS.ACTIVE].includes(data?.status),
    // Wrap a function that's going to be called only when the user is subscribed
    // And if not, a modal is open (SubscribedOnly component needs to be a parent component)
    subscribedOnly:
      (fn, notSubscribedCb) =>
      (...args) => {
        if (!pending && [SUBSCRIPTION_STATUS.SUBSCRIBED, SUBSCRIPTION_STATUS.ACTIVE].includes(data?.status)) {
          return fn?.(...args);
        }
        if (pending) return;
        notSubscribedCb?.();
        setOpenModalPremium(true);
      },
  };
};
