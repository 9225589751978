import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import React, { useState } from 'react';
import { useMediaQueryDown } from '../../../../../../hooks/useMediaQuery';
import { InlineContainer, SpacingContainer } from '../../../../../../components/common/Containers';
import { SelectAllMenu } from '../../../../../../components/common/Inputs/CheckBox';

//Icons
import CreateIcon from '@material-ui/icons/Create';
import { ReactComponent as ArchiveIcon } from '../../../icons/ico-remove.svg';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { IconInbox } from '../../../../../../components/svg/Icons';
import styles from './styles.module.css';
import connect from '../../../connect';
import ShowColumns from './components/ShowColumns';
import Filters from './components/Filters';
import PaginationSmall from '../../../../../../components/common/Paginations/PaginationSmall';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import Tooltip from '../../../../../../components/common/Tooltip';
import Typography from '../../../../../../components/common/Typography';
import BorderColorIcon from '@material-ui/icons/BorderColor';

const ActionsPanel = ({
  items,
  checkAll,
  uncheckAll,
  isAllChecked,
  isSomeChecked,
  filters,
  changeFilters,
  getList,
  changeCurrentView,
  archiveSelected,
  currentView,
  openAddTags,
  count,
  changeEditVendorManagement,
  openAddVendorManually,
  deleteVendorManagement,
}) => {
  const isSmScreen = useMediaQueryDown('sm');

  const onCheckAll = () => {
    checkAll();
  };
  const onRemoveAllChecked = () => {
    uncheckAll();
  };
  const showVendors = () => {
    setPage(1);
    const copyFilters = { ...filters };
    copyFilters.where = { archived: false, draft: false };
    copyFilters.pagination = { ...copyFilters.pagination, page: 1 };
    changeFilters(copyFilters);
    getList(copyFilters);
    changeCurrentView('vendors');
  };
  const showArchivedVendors = () => {
    setPage(1);
    const copyFilters = { ...filters };
    copyFilters.where = { archived: true, draft: false };
    copyFilters.pagination = { ...copyFilters.pagination, page: 1 };
    changeFilters(copyFilters);
    getList(copyFilters);
    changeCurrentView('vendorsArchived');
  };
  const showDraft = () => {
    setPage(1);
    const copyFilters = { ...filters };
    copyFilters.where = { archived: false, draft: true };
    copyFilters.pagination = { ...copyFilters.pagination, page: 1 };
    changeFilters(copyFilters);
    getList(copyFilters);
    changeCurrentView('draft');
  };
  const onArchiveSelected = () => {
    const vendorsChecked = items.filter((i) => i.checked === true);
    const ids = vendorsChecked.map((i) => i.id);
    archiveSelected({ ids: ids, view: currentView });
    changePage(1);
  };
  const onDeleteSelected = () => {
    const vendorsChecked = items.filter((i) => i.checked === true);
    const ids = vendorsChecked.map((i) => i.id);
    deleteVendorManagement({ ids: ids, view: currentView });
    changePage(1);
  };
  const onEditSelected = () => {
    const vendorsChecked = items.find((i) => i.checked === true);
    changeEditVendorManagement(vendorsChecked);
    openAddVendorManually(true);
  };

  const showEditButtom = () => {
    const vendorsChecked = items.filter((i) => i.checked === true);
    return vendorsChecked.length === 1 && vendorsChecked?.[0].status !== 'Registered' ? true : false;
  };
  const onAddTag = () => {
    openAddTags(true);
  };

  const [page, setPage] = useState(1);
  const changePage = (p) => {
    const copyFilters = { ...filters };
    copyFilters.pagination = { ...copyFilters.pagination, page: p };
    changeFilters(copyFilters);
    getList(copyFilters);
    setPage(p);
  };

  return (
    <SpacingContainer px={3} py={2} style={{ background: 'white' }}>
      <Grid container alignItems="center" wrap="nowrap" justifyContent="space-between">
        <Grid item>
          <InlineContainer spacing={isSmScreen ? 2 : 1}>
            <SelectAllMenu
              isSomeChecked={isSomeChecked}
              isAllChecked={isAllChecked}
              onCheckAll={onCheckAll}
              onRemoveAllChecked={onRemoveAllChecked}
            />

            {isSomeChecked && (
              <>
                <IconButton onClick={() => onAddTag()}>
                  <Tooltip
                    placement="top"
                    RefComponent="span"
                    title={
                      <Typography variant="body2" weight="400">
                        Add tag
                      </Typography>
                    }
                  >
                    <LocalOfferOutlinedIcon />
                  </Tooltip>
                </IconButton>
                {showEditButtom() && (
                  <IconButton onClick={() => onEditSelected()}>
                    <Tooltip
                      placement="top"
                      RefComponent="span"
                      title={
                        <Typography variant="body2" weight="400">
                          Edit
                        </Typography>
                      }
                    >
                      <CreateIcon />
                    </Tooltip>
                  </IconButton>
                )}
                {currentView !== 'draft' && (
                  <IconButton onClick={() => onArchiveSelected()}>
                    <Tooltip
                      placement="top"
                      RefComponent="span"
                      title={
                        <Typography variant="body2" weight="400">
                          {currentView === 'vendorsArchived' ? 'Unarchive' : 'Archive'}
                        </Typography>
                      }
                    >
                      {currentView === 'vendorsArchived' ? <RestoreFromTrashIcon /> : <ArchiveIcon />}
                    </Tooltip>
                  </IconButton>
                )}
                {currentView === 'vendorsArchived' && (
                  <IconButton onClick={() => onDeleteSelected()}>
                    <Tooltip
                      placement="top"
                      RefComponent="span"
                      title={
                        <Typography variant="body2" weight="400">
                          Delete
                        </Typography>
                      }
                    >
                      <ArchiveIcon />
                    </Tooltip>
                  </IconButton>
                )}
                {currentView === 'draft' && (
                  <Tooltip
                    placement="top"
                    RefComponent="span"
                    title={
                      <Typography variant="body2" weight="400">
                        Delete
                      </Typography>
                    }
                  >
                    <IconButton onClick={() => onDeleteSelected()}>
                      {' '}
                      <ArchiveIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {/* <ActionsMenu
                  isSomeChecked={isSomeChecked}
                  onMarkAllAsRead={undefined}
                  onMarkAsRead={undefined}
                  onAddToTrash={undefined}
                  onRemove={onDelete}
                  isSmScreen={isSmScreen}
                /> */}
              </>
            )}
          </InlineContainer>
        </Grid>
        <Grid item>
          <InlineContainer spacing={3}>
            <InlineContainer>
              <PaginationSmall
                count={count}
                page={filters.pagination.page}
                perPage={filters.pagination.perPage}
                onChangePage={changePage}
                onChangeRowPerPage={undefined}
                pending={false}
                px={5}
                py={1}
              />

              <Tooltip
                placement="top"
                RefComponent="span"
                title={
                  <Typography variant="body2" weight="400">
                    Active vendors
                  </Typography>
                }
              >
                <IconButton
                  color={!filters.where.archived && !filters.where.draft ? 'secondary' : 'default'}
                  onClick={() => showVendors()}
                >
                  <IconInbox />
                </IconButton>
              </Tooltip>

              <Tooltip
                placement="top"
                RefComponent="span"
                title={
                  <Typography variant="body2" weight="400">
                    Archived vendors
                  </Typography>
                }
              >
                <IconButton
                  color={filters.where.archived ? 'secondary' : 'default'}
                  onClick={() => showArchivedVendors()}
                >
                  <IconInbox archive />
                </IconButton>
              </Tooltip>

              <Tooltip
                placement="top"
                RefComponent="span"
                title={
                  <Typography variant="body2" weight="400">
                    Draft vendors
                  </Typography>
                }
              >
                <IconButton color={filters.where.draft ? 'secondary' : 'default'} onClick={() => showDraft()}>
                  <BorderColorIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                placement="top"
                RefComponent="span"
                title={
                  <Typography variant="body2" weight="400">
                    Visible columns
                  </Typography>
                }
              >
                <ShowColumns />
              </Tooltip>

              <Tooltip
                placement="top"
                RefComponent="span"
                title={
                  <Typography variant="body2" weight="400">
                    Filters
                  </Typography>
                }
              >
                <Filters />
              </Tooltip>
            </InlineContainer>
          </InlineContainer>
        </Grid>
      </Grid>
    </SpacingContainer>
  );
};

export default connect(ActionsPanel);
