import { Container, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Avatar } from '../../components/common/Cards';
import { Paper } from '../../components/common/Containers';
import { SpacingContainer } from '../../components/common/Containers/Base';
import Typography from '../../components/common/Typography';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';
import useUserFullName from '../../hooks/useUserFullName';
import { useTrans } from '../../services/i18n';
import { formatPrettyUnixDate } from '../../utils/dateUtils';
import connect from './connect';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from './components/Card';
import PersonalInformation from './PersonalInformation';
import {
  IconArchiveSlash,
  IconCourthouse,
  IconKey,
  IconReceipt,
  IconShieldSecurity,
  IconShieldTick,
  IconSMSNotification,
  IconUser,
} from '../../components/svg';
import SamApiKey from './SamApiKey';
import ChangePassword from './ChangePassword';
import EmailNotificationSettingsProfile from './EmailNotificationSettingsProfile';
import BillingInformation from './BillingInformation';
import ListOfNotInterested from './ListOfNotInterested';
import MyOrganizations from './MyOrganizations';
import TwoFactorAuthentication from './TwoFactorAuthentication';

const useStyles = makeStyles((theme) => ({
  gridsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '2.5rem',
  },
  singleCard: {
    display: 'flex',
    padding: '2.5rem 0rem',
  },
  singleCardContent: {
    gap: '0.4375rem',
  },
}));

function Profile({ authUser, updateMFA, updatePhone, updatePersonalInformation, updateEmailNotificationSettings }) {
  const { trans } = useTrans();
  const fullName = useUserFullName(authUser);
  const styles = useStyles();

  const loginDate = React.useMemo(() => {
    return formatPrettyUnixDate(authUser.lastLogin || authUser.authTime, 'MM/DD/YYYY, h:mm A');
  }, [authUser.authTime, authUser.lastLogin]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (!query.get('gotoemailsettings')) return;

    const element = document.querySelector('#email-notification-settings');
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }, []);

  return (
    <SpacingContainerBase>
      <Container className={styles.gridsContainer}>
        <Typography weight="600" variant="h2" fontFamily="lexend">
          {trans('my-profile')}
        </Typography>
        <Paper component={SpacingContainer} px={2} py={3} className={styles.singleCard}>
          <Grid
            container
            direction="column"
            alignItems="center"
            component={SpacingContainer}
            className={styles.singleCardContent}
          >
            <Avatar value={fullName} variant="h1Medium" />
            <Typography weight="700" variant="h1Medium">
              {fullName}
            </Typography>
            <Typography weight="500" variant="body1" color="label">
              {authUser.email}
            </Typography>
            <Grid container direction="column" alignItems="center">
              <Typography color="hint" variant="caption">
                {`${trans('lastLogin')}:`} {loginDate}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <Card title={trans('personal-information')} icon={<IconUser />}>
                <PersonalInformation user={authUser} onUpdate={updatePersonalInformation} />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card title={trans('list-of-not-interested')} icon={<IconArchiveSlash />}>
                <ListOfNotInterested />
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card title={trans('update-SAM-API-key')} icon={<IconShieldSecurity />}>
                <SamApiKey auth={authUser} />
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card title={trans('change-password')} icon={<IconKey />}>
                <ChangePassword />
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <Card title={trans('my-organizations')} icon={<IconCourthouse />}>
                <MyOrganizations />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card title={trans('billing-information')} icon={<IconReceipt />}>
                <BillingInformation user={authUser} />
              </Card>
            </Grid>
            <Grid item xs={12} id="email-notification-settings" style={{ scrollMargin: '-25vh' }}>
              <Card title={trans('email-notification-settings')} icon={<IconSMSNotification />}>
                <EmailNotificationSettingsProfile user={authUser} onUpdate={updateEmailNotificationSettings} />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card title={trans('two-factor-authentication')} icon={<IconShieldTick />}>
                <TwoFactorAuthentication auth={authUser} onUpdate={updateMFA} updatePhone={updatePhone} />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </SpacingContainerBase>
  );
}

export default connect(Profile);
