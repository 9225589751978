import MUITooltip from '@material-ui/core/Tooltip';
import React from 'react';

const PopperProps = { disablePortal: true };
const classes = { small: { tooltip: 'small' } };

export default React.forwardRef(({ children, size = 'normal', RefComponent = null, ...tooltipProps }, ref) => {
  return (
    <MUITooltip ref={ref} classes={classes[size]} PopperProps={PopperProps} {...tooltipProps}>
      {RefComponent ? <RefComponent>{children}</RefComponent> : children}
    </MUITooltip>
  );
});
