import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconVendors = (props) => {
  const { width = 64, height = 64 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 64 64">
      <path
        d="M60 20a4 4 0 0 1 4 4v36a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h24a4 4 0 0 1 4 4v16h28zM30 44H2v16a2 2 0 0 0 1.85 1.995L4 62h26V44zm16-22H32v40h14V22zm14 0H48v40h12a2 2 0 0 0 1.995-1.85L62 60V24a2 2 0 0 0-1.85-1.995L60 22zm-30 0H2v20h28V22zM28 2H4a2 2 0 0 0-1.995 1.85L2 4v16h28V4a2 2 0 0 0-1.85-1.995L28 2z"
        fill="#B2B8CF"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

IconVendors.propTypes = simpleIconPropsTypes;
