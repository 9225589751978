import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_CODE_CATEGORIES, ROUTE_CODE_CATEGORIES_DETAILS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const CodeCategories = React.lazy(() => import('./CodeCategories'));
const CodeCategoriesDetails = React.lazy(() => import('./Details'));

export default function CodeCategoriesWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_CODE_CATEGORIES_DETAILS.path}>
          <CodeCategoriesDetails />
        </Route>
        <Route exact path={ROUTE_CODE_CATEGORIES.path}>
          <CodeCategories />
        </Route>
        <Redirect exact path="/*" to={ROUTE_CODE_CATEGORIES.path} />
      </Switch>
    </Suspense>
  );
}
