import { spacing } from '../common';

const safariFixMargin = 0.5;

const MuiGrid = {
  root: {
    '&[class*="grid-sm-auto"]': { width: 'auto' },
    '&[class*="grid-md-auto"]': { width: 'auto' },
    '&[class*="grid-lg-auto"]': { width: 'auto' },
    '&[class*="grid-xl-auto"]': { width: 'auto' },
  },
  'grid-xs-auto': { width: 'auto' },

  // Fix Safari
  'spacing-xs-1': { margin: -(spacing / 2 - safariFixMargin) },
  'spacing-xs-2': { margin: -(spacing - safariFixMargin) },
  'spacing-xs-3': { margin: -((spacing * 3) / 2 - safariFixMargin) },
  'spacing-xs-4': { margin: -((spacing * 4) / 2 - safariFixMargin) },
};

export default MuiGrid;
