import { Button, Grid, InputAdornment } from '@material-ui/core';
import React from 'react';
import { useTrans } from '../../../../services/i18n';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Typography from '../../../../components/common/Typography';
import { TextField } from '../../../../components/common/Inputs';

function PasswordFields({ formikProps, classes, onSubmit }) {
  const { trans } = useTrans();
  const [inputTypePassword, setInputTypePassword] = React.useState('password');
  const [inputTypeConfirmPassword, setInputTypeConfirmPassword] = React.useState('password');

  const handleVisibilityPassword = () => {
    setInputTypePassword(inputTypePassword === 'password' ? 'text' : 'password');
  };

  const handleVisibilityConfirmPassword = () => {
    setInputTypeConfirmPassword(inputTypeConfirmPassword === 'password' ? 'text' : 'password');
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h1Medium" fontFamily="lexend" weight="500">
          {trans('set-new-password')}
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={trans('password')}
          type={inputTypePassword}
          placeholder={trans('password')}
          {...formikProps.getFieldProps('password')}
          required
          name="password"
          InputProps={{
            endAdornment: (
              <InputAdornment onClick={handleVisibilityPassword} position="end" className={classes.cursorPointer}>
                {inputTypePassword === 'password' ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          type={inputTypeConfirmPassword}
          label={trans('confirm-new-password')}
          placeholder={trans('confirm-new-password')}
          {...formikProps.getFieldProps('confirmPassword')}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment
                onClick={handleVisibilityConfirmPassword}
                position="end"
                className={classes.cursorPointer}
              >
                {inputTypeConfirmPassword === 'password' ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button id="change-password-btn" variant="contained" size="large" color="primary" onClick={onSubmit}>
          {trans('change-password')}
        </Button>
      </Grid>
    </>
  );
}

export default PasswordFields;
