import { APP_MENU } from '../../../../../constants';
import { matchPath, useHistory } from 'react-router-dom';
import React from 'react';
import { TIERS } from '../../../../../utils/subscription';
import { useSelector } from 'react-redux';
import {
  LIMITED_VALID_ROUTES,
  shouldDisableFeaturesForLimitedUsers,
} from '../../../../../hooks/useShouldRedirectLimitedUsers';

const disableMenuOption = ({ menuKey, currentPlan, limitedDeletionPatchDate, daysToDisable }) => {
  if (menuKey === 'workplace') {
    return false;
  }

  const disableFeatures = shouldDisableFeaturesForLimitedUsers(limitedDeletionPatchDate, daysToDisable);
  if (currentPlan === TIERS.LIMITED && disableFeatures) {
    return true;
  }
};

export const useAppMenu = (sub) => {
  const history = useHistory();
  const limitedDeletionPatchDate = useSelector((state) => state.globalSettings.limitedAccountsDeletionDate);
  const daysToDisable = useSelector((state) => state.globalSettings.limitedAccountsDaysToDisable);
  const disableFeatures = shouldDisableFeaturesForLimitedUsers(limitedDeletionPatchDate, daysToDisable);

  const [activeGroup, setActiveGroup] = React.useState(-1);

  React.useLayoutEffect(() => {
    setActiveGroup(-1);
  }, [history.location.pathname]);

  const oneMatches = (group) => {
    return group.items.some((item) => {
      return matchPath(history.location.pathname, {
        path: item.path,
      });
    });
  };

  return {
    menu: React.useMemo(() => {
      return APP_MENU.map((group, idx) => {
        const groupIsActive = activeGroup > -1 ? activeGroup === idx : oneMatches(group);

        return {
          ...group,
          items: group.items.map((item) => ({
            ...item,
            disabled:
              disableFeatures && sub?.currentPlan === TIERS.LIMITED && !LIMITED_VALID_ROUTES.includes(item.path),
          })),
          active: groupIsActive,
          disabled: disableMenuOption({
            menuKey: group.key,
            currentPlan: sub?.currentPlan,
            limitedDeletionPatchDate,
            daysToDisable: daysToDisable,
          }),
        };
      });
    }, [activeGroup, history.location.pathname, sub, limitedDeletionPatchDate]),
    setActive(idx) {
      setActiveGroup(idx);
    },
  };
};
