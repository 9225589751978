import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Paper } from '../../../common/Containers';
import { SpacingContainer } from '../../../common/Containers/Base';
import Typography from '../../../common/Typography';

export default function QickViewCard({ title, leftField, rightField, chosen, onClick, ...rest }) {
  return !title ? null : (
    <Paper
      className="pointer"
      component={SpacingContainer}
      px={3}
      py={3}
      bgColor={chosen ? 'active' : 'default'}
      bgColorHover="active"
      onClick={onClick}
      {...rest}
    >
      <Grid container justifyContent="space-between" spacing={1}>
        {leftField && <Grid item>{leftField}</Grid>}
        {rightField && <Grid item>{rightField}</Grid>}

        <Grid item xs={12} zeroMinWidth>
          <Typography variant="body1" color="primary" weight="600" noWrap={false}>
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
