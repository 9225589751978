import { connect } from 'react-redux';
import { actions } from '../../../../store/notifications';

function mapStateToProps(state) {
  return {
    banner: state.notifications.banner,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBanner: () => dispatch(actions.getBanner()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
