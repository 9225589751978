import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_PERSONAL_TASKS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const PersonalTasks = React.lazy(() => import('./PersonalTasks'));

export default function PersonalTasksWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_PERSONAL_TASKS.path}>
          <PersonalTasks />
        </Route>
        <Redirect exact path="/*" to={ROUTE_PERSONAL_TASKS.path} />
      </Switch>
    </Suspense>
  );
}
