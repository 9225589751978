import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { application } from '../../services/application';
import { CONTRACT_AWARDS } from '../../constants/api';
import { onPending, onReject } from '../helpers/sharedCases';
import { INITIAL_LIST_STATE } from '../helpers/common';
import { extendBuilderWithListActions, generateListActions } from '../helpers/listActionsHelpers';
import { SCOPE_CONTRACT_AWARDS } from '../../constants';

export const INITIAL_STATE = {
  item: null,
  pending: true,
  timeline: [],
  subawardsCount: 0,
  // Totals for sub-awards table
  totalSubawards: 0,
  totalAmount: 0,
  percentageObligatedAmount: 0,
  // Federal count
  federalAccountFundingCount: 0,

  transactions: INITIAL_LIST_STATE,
  subawards: INITIAL_LIST_STATE,
  contractOpportunities: INITIAL_LIST_STATE,
  federalAccountFunding: INITIAL_LIST_STATE,
};

const fetchContractAward = createAsyncThunk('contractAwards/findOne', async (params) => {
  return application.call(CONTRACT_AWARDS.FIND_ONE, params);
});

const transactionsListAtions = generateListActions({
  scope: `${SCOPE_CONTRACT_AWARDS}/transactions`,
  apiMethod: {
    GET_LIST: CONTRACT_AWARDS.GET_TRANSACTIONS,
    EXPORT_CSV: CONTRACT_AWARDS.EXPORT_TRANSACTIONS_CSV,
  },
  getStore: (store) => store.contractAwardDetails.transactions,
});

const subawardsListAtions = generateListActions({
  scope: `${SCOPE_CONTRACT_AWARDS}/subawards`,
  apiMethod: {
    GET_LIST: CONTRACT_AWARDS.FETCH_SUBAWARDS,
    EXPORT_CSV: CONTRACT_AWARDS.EXPORT_SUBAWARDS_TO_CSV,
  },
  getStore: (store) => store.contractAwardDetails.subawards,
});

const fcoListAtions = generateListActions({
  scope: `${SCOPE_CONTRACT_AWARDS}/contractOpportunities`,
  apiMethod: {
    GET_LIST: CONTRACT_AWARDS.GET_RELATED_FCO,
    EXPORT_CSV: CONTRACT_AWARDS.EXPORT_FEDERAL_CONTRACT_OPPORTUNITIES,
  },
  getStore: (store) => store.contractAwardDetails.contractOpportunities,
});

const federalAccountFundingListActions = generateListActions({
  scope: `${SCOPE_CONTRACT_AWARDS}/federalAccountFunding`,
  apiMethod: {
    GET_LIST: CONTRACT_AWARDS.GET_FEDERAL_ACCOUNT_FUNDING,
    EXPORT_CSV: CONTRACT_AWARDS.EXPORT_FEDERAL_ACCOUNT_FUNDING_TO_CSV,
  },
  getStore: (store) => store.contractAwardDetails.federalAccountFunding,
});

const cleanStore = createAction('contractAwards/cleanStore');

function onFetchContractAward(state, action) {
  const latestIndex = action.payload.latestIndex || 0;
  const item = Object.assign({}, action.payload.timeline && action.payload.timeline[latestIndex], action.payload.award);
  item.inNotInteresting = action.payload.inNotInteresting;
  item.relatedFundingAgency = action.payload.relatedFundingAgency;
  item.relatedAwardingAgency = action.payload.relatedAwardingAgency;

  return Object.assign(state, action.payload, {
    item,
    pending: false,
    subawards: state.subawards,
    transactions: state.transactions,
  });
}

const getSubawardsCount = createAsyncThunk('contractAwards/getSubawardsCount', async (params) => {
  return application.call(CONTRACT_AWARDS.GET_SUBAWARDS_COUNT, params);
});

function onGetSubawardsCount(state, action) {
  return Object.assign(state, action.payload, {
    subawardsCount: action.payload?.count,
  });
}

const getFederalAccountFundingCount = createAsyncThunk(
  'contractAwards/getFederalAccountFundingCount',
  async (params) => {
    return application.call(CONTRACT_AWARDS.GET_FEDERAL_ACCOUNT_FUNDING_COUNT, params);
  }
);

function onGetFederalAccountFundingCount(state, action) {
  return Object.assign(state, action.payload, {
    federalAccountFundingCount: action.payload?.count,
  });
}

const getSubawardsTotals = createAsyncThunk('contractAwards/getSubawardsTotals', async (params) => {
  return application.call(CONTRACT_AWARDS.GET_SUBAWARDS_TOTALS, params);
});

function onGetSubawardsTotals(state, action) {
  return Object.assign(state, action.payload, {
    totalSubawards: action.payload?.totalSubawards,
    totalAmount: action.payload?.totalAmount,
    percentageObligatedAmount: action.payload?.percentageObligatedAmount,
  });
}

export const actions = {
  fetchContractAward,
  transactionsListAtions,
  subawardsListAtions,
  fcoListAtions,
  federalAccountFundingListActions,
  cleanStore,
  getSubawardsCount,
  getSubawardsTotals,
  getFederalAccountFundingCount,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, transactionsListAtions, (store) => store.transactions);
  extendBuilderWithListActions(builder, subawardsListAtions, (store) => store.subawards);
  extendBuilderWithListActions(builder, fcoListAtions, (store) => store.contractOpportunities);
  extendBuilderWithListActions(builder, federalAccountFundingListActions, (store) => store.federalAccountFunding);

  builder
    .addCase(fetchContractAward.fulfilled, onFetchContractAward)
    .addCase(fetchContractAward.pending, onPending)
    .addCase(fetchContractAward.rejected, onReject)
    .addCase(getSubawardsCount.fulfilled, onGetSubawardsCount)
    .addCase(getSubawardsTotals.fulfilled, onGetSubawardsTotals)
    .addCase(getFederalAccountFundingCount.fulfilled, onGetFederalAccountFundingCount)

    .addCase(cleanStore, () => INITIAL_STATE);
});
