import React from 'react';
import Typography from '../../../../../components/common/Typography';
import { Paper, SpacingContainer } from '../../../../../components/common/Containers';
import { Grid } from '@material-ui/core';
import { ReactComponent as IconNoInvoices } from './Line.svg';

const Plan = () => {
  return (
    <Paper component={SpacingContainer} py={4} px="4sm3" radius={4}>
      <Grid container spacing={2}>
        <Grid lg={12} item alignItems="center">
          <div style={{ display: 'flex', alignItems: 'center', padding: '2rem', background: '#F9F9F9' }}>
            <IconNoInvoices style={{ marginRight: '20px' }} />
            <Typography variant="h5" weight="600" style={{ fontSize: '16px' }}>
              No invoices available to preview yet
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Plan;
