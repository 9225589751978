import React, { useEffect } from 'react';
import { PROD } from './constants';
import { AwsRum } from 'aws-rum-web';

function AWSRumScript({ stage }) {
  useEffect(() => {
    if (stage !== PROD) return null;

    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.onload = () => {
      try {
        const config = {
          sessionSampleRate: 1,
          guestRoleArn: 'arn:aws:iam::274449070938:role/RUM-Monitor-us-east-1-274449070938-8738789638761-Unauth',
          identityPoolId: 'us-east-1:a9b00905-d10d-4064-bd06-f1e764c0daf6',
          endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
          telemetries: ['performance', 'errors', 'http'],
          allowCookies: true,
          enableXRay: false,
        };

        const APPLICATION_ID = 'f4273fa5-8cb2-4e54-9ed0-363ca7cf75fc';
        const APPLICATION_VERSION = '1.0.0';
        const APPLICATION_REGION = 'us-east-1';

        const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
      } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
      }
    };

    document.head.insertBefore(script, document.getElementsByTagName('script')[0]);
  }, [stage]);

  return <></>;
}

export default AWSRumScript;
