import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { API, SCOPE_VENDORS } from '../../constants';
import { application } from '../../services/application';
import { onItemDetails, onItemDetailsReject } from '../helpers/sharedCases';
import {
  extendBuilderWithListActions,
  generateListActions,
  INITIAL_LIST_STATE,
  INITIAL_LIST_STATE_EXTENDED,
} from '../helpers/listActionsHelpers';

const INITIAL_STATE = {
  item: {},
  asideList: [],
  pending: false,
  notes: { ...INITIAL_LIST_STATE_EXTENDED, pagination: { page: 1, perPage: 2 } },
  members: INITIAL_LIST_STATE_EXTENDED,
  naics: INITIAL_LIST_STATE,
};

const fetchVendor = createAsyncThunk('vendorsManagement/findOne', (params) => {
  return application.call(API.VENDORS_MANAGEMENT.FIND_ONE, params);
});

const cleanStore = createAction('vendors/cleanStore');

const fetchAsideList = createAsyncThunk('vendors/getSetAsideList', () => {
  return application.call(API.VENDORS.GET_VENDORS_ASIDE);
});
function onFetchAsideList(state, action) {
  return Object.assign(state, {
    asideList: action.payload,
  });
}

const vendorNoteMemberListActions = generateListActions({
  scope: `${SCOPE_VENDORS}/vendorNoteMembers`,
  apiMethod: {
    GET_LIST: API.VENDORS.GET_ALL_VENDOR_NOTE_MEMBERS,
  },
  getStore: (store) => store.vendorsManagementDetails.members,
});
const vendorNoteNotesListActions = generateListActions({
  scope: `${SCOPE_VENDORS}/vendorNoteNotes`,
  apiMethod: {
    GET_LIST: API.VENDORS.GET_ALL_VENDOR_NOTE,
  },
  getStore: (store) => store.vendorsManagementDetails.notes,
});

const vendorNoteNaicsListActions = generateListActions({
  scope: `${SCOPE_VENDORS}/vendorNoteNaics`,
  apiMethod: {
    GET_LIST: API.VENDORS.GET_ALL_VENDOR_NOTE_NAICS,
  },
  getStore: (store) => store.vendorsManagementDetails.naics,
});

const addVendorMember = createAsyncThunk('vendors/addVendorMember', async (params) => {
  return application.call(API.VENDORS.ADD_VENDOR_NOTE_MEMBER, params);
});
const removeVendorMember = createAsyncThunk('vendors/removeVendorMember', async (params) => {
  return application.call(API.VENDORS.REMOVE_VENDOR_NOTE_MEMBER, params);
});
const updateVendorMember = createAsyncThunk('vendors/updateVendorMember', (params) => {
  return application.call(API.VENDORS.UPDATE_VENDOR_NOTE_MEMBER, params);
});

const addVendorNote = createAsyncThunk('vendors/addVendorNote', async (params) => {
  return application.call(API.VENDORS.ADD_VENDOR_NOTE, params);
});
const removeVendorNote = createAsyncThunk('vendors/removeVendorNote', async (params) => {
  return application.call(API.VENDORS.REMOVE_VENDOR_NOTE, params);
});
const updateVendorNote = createAsyncThunk('vendors/updateVendorNote', (params) => {
  return application.call(API.VENDORS.UPDATE_VENDOR_NOTE, params);
});

const addVendorNaics = createAsyncThunk('vendors/addVendorNaics', async (params) => {
  return application.call(API.VENDORS.ADD_VENDOR_NOTE_NAICS, params);
});
const removeVendorNaics = createAsyncThunk('vendors/removeVendorNaics', async (params) => {
  return application.call(API.VENDORS.REMOVE_VENDOR_NOTE_NAICS, params);
});
const updateVendorNaics = createAsyncThunk('vendors/updateVendorNaics', (params) => {
  return application.call(API.VENDORS.UPDATE_VENDOR_NOTE_NAICS, params);
});

function onAddVendorMember(state, action) {
  Object.assign(state, { memberPending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [];
  copy.push(action.payload);
  Object.assign(state.members.items, [...state.members.items, action.payload]);
}
function onUpdateVendorMember(state, action) {
  Object.assign(state, { memberPending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.members.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    Object.assign(copy[index], action.payload);

    Object.assign(state.members, {
      items: [...copy],
    });
  }
}
function onRemoveVendorMember(state, action) {
  Object.assign(state, { memberPending: false });

  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.members.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    copy.splice(index, 1);
    Object.assign(state.members, {
      items: [...copy],
      count: state.count - 1,
    });
  }
}

function onAddVendorNote(state, action) {
  Object.assign(state, { notePending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [];
  copy.push(action.payload);
  Object.assign(state.notes.items, [...state.notes.items, action.payload]);
}
function onUpdateVendorNote(state, action) {
  Object.assign(state, { notePending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.notes.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    Object.assign(copy[index], action.payload);

    Object.assign(state.notes, {
      items: [...copy],
    });
  }
}
function onRemoveVendorNote(state, action) {
  Object.assign(state, { notePending: false });

  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.notes.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    copy.splice(index, 1);
    Object.assign(state.notes, {
      items: [...copy],
      count: state.count - 1,
    });
  }
}

function onAddVendorNaics(state, action) {
  Object.assign(state, { naicsPending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [];
  copy.push(action.payload);
  Object.assign(state.naics.items, [...state.naics.items, action.payload]);
}
function onUpdateVendorNaics(state, action) {
  Object.assign(state, { naicsPending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.naics.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    Object.assign(copy[index], action.payload);

    Object.assign(state.naics, {
      items: [...copy],
    });
  }
}
function onRemoveVendorNaics(state, action) {
  Object.assign(state, { naicsPending: false });

  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.naics.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    copy.splice(index, 1);
    Object.assign(state.naics, {
      items: [...copy],
      count: state.count - 1,
    });
  }
}

export const actions = {
  fetchVendor,
  cleanStore,
  fetchAsideList,
  vendorNoteMemberListActions,
  vendorNoteNotesListActions,
  vendorNoteNaicsListActions,
  addVendorMember,
  removeVendorMember,
  updateVendorMember,
  addVendorNote,
  removeVendorNote,
  updateVendorNote,
  addVendorNaics,
  removeVendorNaics,
  updateVendorNaics,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, vendorNoteMemberListActions, (store) => store.members);
  extendBuilderWithListActions(builder, vendorNoteNotesListActions, (store) => store.notes);
  extendBuilderWithListActions(builder, vendorNoteNaicsListActions, (store) => store.naics);

  return builder
    .addCase(fetchVendor.fulfilled, onItemDetails)
    .addCase(fetchVendor.pending, (state) =>
      Object.assign(state, INITIAL_STATE, { pending: true, teamingPending: true, orgAvatarUrl: null })
    )
    .addCase(fetchVendor.rejected, onItemDetailsReject)
    .addCase(fetchAsideList.fulfilled, onFetchAsideList)

    .addCase(addVendorMember.fulfilled, onAddVendorMember)
    .addCase(removeVendorMember.fulfilled, onRemoveVendorMember)
    .addCase(updateVendorMember.fulfilled, onUpdateVendorMember)
    .addCase(updateVendorMember.pending, (state) => Object.assign(state, { memberPending: true }))
    .addCase(removeVendorMember.pending, (state) => Object.assign(state, { memberPending: true }))
    .addCase(addVendorMember.pending, (state) => Object.assign(state, { memberPending: true }))

    .addCase(addVendorNote.fulfilled, onAddVendorNote)
    .addCase(removeVendorNote.fulfilled, onRemoveVendorNote)
    .addCase(updateVendorNote.fulfilled, onUpdateVendorNote)
    .addCase(updateVendorNote.pending, (state) => Object.assign(state, { notePending: true }))
    .addCase(removeVendorNote.pending, (state) => Object.assign(state, { notePending: true }))
    .addCase(addVendorNote.pending, (state) => Object.assign(state, { notePending: true }))

    .addCase(addVendorNaics.fulfilled, onAddVendorNaics)
    .addCase(removeVendorNaics.fulfilled, onRemoveVendorNaics)
    .addCase(updateVendorNaics.fulfilled, onUpdateVendorNaics)
    .addCase(updateVendorNaics.pending, (state) => Object.assign(state, { naicsPending: true }))
    .addCase(removeVendorNaics.pending, (state) => Object.assign(state, { naicsPending: true }))
    .addCase(addVendorNaics.pending, (state) => Object.assign(state, { naicsPending: true }));
});
