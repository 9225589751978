import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_TASKS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const Tasks = React.lazy(() => import('./Tasks'));

export default function TasksWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_TASKS.path}>
          <Tasks />
        </Route>
        <Redirect exact path="/*" to={ROUTE_TASKS.path} />
      </Switch>
    </Suspense>
  );
}
