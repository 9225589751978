import { addMethod, mixed, string } from 'yup';

const INVALID_PARAMETER_CODE = 'InvalidParamsError';
const VALIDATION_ERROR = 'ValidationError';

function passwordValidation(message) {
  return mixed().test('password', message, function () {
    const { originalValue } = this;
    return !(
      !RegExp('[a-z]').test(originalValue) ||
      !RegExp('[A-Z]').test(originalValue) ||
      !RegExp('[0-9]').test(originalValue) ||
      !RegExp('[!@#$%^&*(),.?":{}|<>]').test(originalValue) ||
      originalValue.length < 8
    );
  });
}

export const registerValidationSchema = () => {
  addMethod(string, 'password', passwordValidation);
};

export const schemaToInitialState = (schema, defaultValues = undefined) =>
  Object.keys(schema.fields).reduce((acc, curr) => {
    acc[curr] = (defaultValues && defaultValues[curr]) || '';
    return acc;
  }, {});

export const catchYupErrors = (err, yupHandler, defaultHandler) => {
  if (err.data && Array.isArray(err.data) && err.code === INVALID_PARAMETER_CODE) {
    const errors = err.data.filter((e) => e.name === VALIDATION_ERROR);
    return yupHandler(
      errors.reduce((acc, curr) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {})
    );
  }
  return defaultHandler ? defaultHandler(err) : err;
};
