import React from 'react';
import { simpleIconPropsTypes } from '../../propTypes';
import ContactSupportIcon from '@material-ui/icons/ContactSupportOutlined';

export const IconSupport = (props) => {
  const { width = 25, height = 25 } = props;

  return <ContactSupportIcon width={width} height={height} />;
};

IconSupport.propTypes = simpleIconPropsTypes;
