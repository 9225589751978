import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconContacted = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M18 4c1.105 0 2 .895 2 2v9c0 1.105-.895 2-2 2h-4v3l-5-3-3.039-.059c-1.089-.02-1.961-.91-1.961-2V6c0-1.105.895-2 2-2h12zm0 1.8H6c-.092 0-.17.062-.193.147L5.8 6v8.942c0 .09.06.167.144.192l.052.008 3.039.058c.251.005.498.063.725.168l.166.089 2.282 1.368.016-.117c.131-.808.8-1.435 1.628-1.502L14 15.2h4c.092 0 .17-.062.193-.147L18.2 15V6c0-.092-.062-.17-.147-.193L18 5.8z"
        transform="translate(-997.000000, -357.000000) translate(251.000000, 182.000000) translate(720.000000, 0.000000) translate(0.000000, 24.000000) translate(26.000000, 151.000000)"
      />
    </SvgIcon>
  );
};

IconContacted.propTypes = simpleIconPropsTypes;
