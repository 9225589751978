import Grid from '@material-ui/core/Grid';
import React, { useEffect, useCallback } from 'react';
import { useForm } from '../../../../../../../hooks/useForm';
import { useTrans } from '../../../../../../../services/i18n';
import { Button } from '../../../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../../../components/common/Dialogs';
import { TextField, DatePicker } from '../../../../../../../components/common/Inputs';
import { getInitialValues, useScheme } from './helpers';
import { useAlertContext } from '../../../../../../../contexts/AlertContext';
import Typography from '../../../../../../../components/common/Typography';
import { Divider } from '../../../../../../../components/common/Divider';
import connect from '../../connect';

const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: true };

const AddInternalOpportunityForm = ({
  open,
  onClose,
  getInternalOpportunities,
  addOpportunity,
  pursuit,
  currentShowOpportunity,
}) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert } = useAlertContext();

  const onSubmit = useCallback(
    (values) => {
      saveOpportunity(values);
    },
    [onClose, addOpportunity]
  );

  const { getFieldProps, handleSubmit, values, isSubmitting, resetForm } = useForm(
    schema,
    getInitialValues(currentShowOpportunity),
    onSubmit,
    formOptions
  );

  useEffect(() => {}, []);

  const closeAddInternalOpportunityModal = () => {
    resetForm();
    onClose();
  };

  const saveOpportunity = (values) => {
    try {
      const data = { ...values };
      addOpportunity({ ...data, type: 'internal', pursuitId: pursuit.id }).then((res) => {
        closeAddInternalOpportunityModal();
        successAlert('Internal opportunity added!');
        getInternalOpportunities({ innerType: 'internal', pursuitId: pursuit.id });
      });
    } catch (error) {
      errorAlert('Failed to add internal opportunity');
    }
  };

  return (
    <ModalDialog
      open={open}
      onClose={() => closeAddInternalOpportunityModal()}
      title={values.id ? trans('edit-internal-teaming-opportunity') : trans('create-internal-teaming-opportunity')}
      actions={
        <div>
          <Button variant="outlined" color="primary" onClick={() => closeAddInternalOpportunityModal()}>
            {trans('cancel')}
          </Button>
          <Button
            pending={isSubmitting}
            disabled={isSubmitting}
            style={{ marginLeft: '16px' }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {isSubmitting ? trans(values.id ? 'Saving' : 'Creating') : trans(values.id ? 'save' : 'create')}
          </Button>
        </div>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" weight="bold">
            Any USFCR member can see in a brief view and apply.
          </Typography>
          <Typography variant="h6">
            Accepted members will get access. You will be able to manage roles and permissions.
          </Typography>
          <Divider style={{ marginTop: '30px' }} light />
        </Grid>

        <Grid container style={{ padding: '0  12px 16px 12px' }}>
          <Grid container style={{ marginTop: '15px' }}>
            <Grid item xs={6} style={{ paddingRight: '16px' }}>
              <Typography variant="h6" weight="bold" style={{ marginBottom: '16px' }}>
                {trans('teaming-information')}
              </Typography>
              <DatePicker
                label={trans('response-by')}
                placeholder={trans('date')}
                maxLength={80}
                disablePast
                disableToolbar
                {...getFieldProps('responseBy')}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '16px' }}>
            <Grid item xs={12}>
              <TextField
                label={trans('Title')}
                placeholder={trans('required')}
                required
                maxLength={80}
                {...getFieldProps('title')}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '16px' }}>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={5}
                tabIndex="2"
                label={trans('description')}
                placeholder={trans('required')}
                required
                maxLength={500}
                {...getFieldProps('description')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

export default connect(AddInternalOpportunityForm);
