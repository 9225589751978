import makeStyles from '@material-ui/core/styles/makeStyles';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import clsx from 'clsx';
import React from 'react';

const TableContext = React.createContext({ responsive: 'sm' });
export const useTableContext = () => React.useContext(TableContext);

export default function Table({ children, head, className, fixedLayout = false, responsive = 'sm', ...rest }) {
  const styles = useStyles();
  const value = { responsive };

  return (
    <TableContext.Provider value={value}>
      <MUITable className={clsx(className, styles[responsive], fixedLayout && styles.fixedLayout)} {...rest}>
        {head}
        <TableBody>{children}</TableBody>
      </MUITable>
    </TableContext.Provider>
  );
}

const responsiveStyles = {
  display: 'block',
  margin: '0 -8px',
  '& > thead': { display: 'none' },
  '& > tbody': {
    display: 'block',
    '& > tr': {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '8px 0',
      '&:not(:last-child)': { borderBottomWidth: 1, borderBottomStyle: 'solid' },
      '& > td': { borderBottom: 'none', padding: 8 },
    },
  },
};

const useStyles = makeStyles((theme) => ({
  fixedLayout: { tableLayout: 'fixed' },

  xs: { [theme.breakpoints.down('xs')]: responsiveStyles },
  sm: { [theme.breakpoints.down('sm')]: responsiveStyles },
  md: { [theme.breakpoints.down('md')]: responsiveStyles },
  lg: { [theme.breakpoints.down('lg')]: responsiveStyles },
  xl: { [theme.breakpoints.down('xl')]: responsiveStyles },
}));
