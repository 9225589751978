import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import { Button } from '../../common/Buttons';
import { ModalDialog } from '../../common/Dialogs';
import { TextField } from '../../common/Inputs';
import { useForm } from '../../../hooks/useForm';
import { useTrans } from '../../../services/i18n';
import { getInitialValues, useScheme } from './helpers';
import { useAlertContext } from '../../../contexts/AlertContext';

const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: true };

export default function SavePipelineStageForm({ item, open, onClose, onEdit }) {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const [isSend, setIsSend] = React.useState(false);

  const onSubmit = useCallback(
    (values) => {
      Promise.resolve(onEdit(values))
        .then(() => {
          successAlert('Stage saved!');
          onClose();
          resetForm();
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false));
    },
    [onClose, infoAlert, errorAlert, successAlert, onEdit]
  );

  const { getFieldProps, handleSubmit, values, isSubmitting, isValid, resetForm } = useForm(
    schema,
    getInitialValues(item),
    onSubmit,
    formOptions
  );

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('EditStage')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" disabled={isSend} onClick={onClose}>
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={!isValid} pending={isSend} onClick={handleSubmit}>
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label={trans('name')}
            placeholder={trans('required')}
            required
            maxLength={80}
            {...getFieldProps('name')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={trans('description')}
            placeholder={trans('Description')}
            multiline
            rows={4}
            rowsMax={10}
            maxLength={255}
            {...getFieldProps('description')}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
}
