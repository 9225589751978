import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconBack = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M20 11L7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13z"
        transform="translate(-768 -48) translate(720) translate(48 48)"
      />
    </SvgIcon>
  );
};

IconBack.propTypes = simpleIconPropsTypes;
