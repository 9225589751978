import { Box, Grid, makeStyles } from '@material-ui/core';
import CheckIcon from './CheckIcon';
import Typography from '../../../components/common/Typography';
import { useTrans } from '../../../services/i18n';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    whiteSpace: 'nowrap',
    marginRight: '1.5rem',
  },
  marquee: {
    width: '100%',
    whiteSpace: 'nowrap',
    animation: '$marquee 60s linear infinite',
  },
  benefitMobile: {
    backgroundColor: theme.palette.success.fade,
    padding: '.75rem .5rem',
    borderRadius: '.5rem',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  '@keyframes marquee': {
    '0%': {
      transform: 'translateX(100vw)',
    },
    '100%': {
      transform: 'translateX(-500vw)',
    },
  },
}));

const DESKTOP_BENEFITS = [
  'full-searching-capabilities',
  '2-user-access',
  'unlimited-saved-searches',
  'online-learning-videos',
  '3000-exports-per-month',
  'access-to-thousands-of-contract-and-grant-opportunities',
  'teaming',
  'vendor-management',
];

const MOBILE_BENEFITS = [
  'full-searching-capabilities',
  'unlimited-saved-searches',
  'online-learning-videos',
  'vendor-management',
  'access-to-thousands-of-contract-and-grant-opportunities',
  '3000-exports-per-month',
  'teaming',
  '2-user-access',
];

function Benefits({ isMobile }) {
  const classes = useStyles();
  const { trans } = useTrans();
  const theme = useTheme();
  const rowLimit = isMobile ? 1 : 4;
  const benefits = isMobile ? MOBILE_BENEFITS : DESKTOP_BENEFITS;
  const rowCountArray = [...Array(benefits.length / rowLimit).keys()];

  const getBenefitsSlice = (index) => benefits.slice(index * rowLimit, (index + 1) * rowLimit);

  const shouldAddBr = (index) => !isMobile && (index + 1) % rowLimit === 0;

  return isMobile ? (
    <Grid container spacing={2}>
      {benefits.map((benefit, index) => (
        <Grid key={index} item xs={6}>
          <Box className={classes.benefitMobile}>
            <CheckIcon style={{ marginRight: '.5rem', minWidth: '20px', maxWidth: '20px' }} />

            <Box>
              <Typography variant={'h6'} style={{ lineHeight: '1.3rem', fontSize: '14px' }}>
                {trans(benefit)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Box width="100%" overflow="hidden">
      <Box display="flex" flexDirection="column" alignItems="center">
        {rowCountArray.map((_, index) => (
          <Box display="flex" key={`row-${index}`}>
            {getBenefitsSlice(index).map((benefit, index) => (
              <>
                {shouldAddBr(index) ? <br /> : null}
                <Box mb=".5rem" display="flex" width="fit-content">
                  <CheckIcon style={{ marginRight: '.5rem' }} />

                  <Box flex={1}>
                    <Typography key={index} variant={'h6'} className={classes.title} style={{ marginRight: '1.5rem' }}>
                      {trans(benefit)}
                    </Typography>
                  </Box>
                </Box>
              </>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
export default Benefits;
