import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconFilters = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M16 15c1.306 0 2.418.835 2.83 2H20c.552 0 1 .448 1 1s-.448 1-1 1h-1.171c-.412 1.166-1.523 2-2.829 2-1.306 0-2.417-.834-2.829-2H4c-.552 0-1-.448-1-1s.448-1 1-1h9.17c.412-1.165 1.524-2 2.83-2zM8 3c1.306 0 2.418.835 2.83 2H20c.552 0 1 .448 1 1s-.448 1-1 1h-9.171C10.417 8.167 9.306 9 8 9c-1.306 0-2.417-.834-2.829-2H4c-.552 0-1-.448-1-1s.448-1 1-1h1.17C5.583 3.835 6.695 3 8 3z"
        transform="translate(-1132 -234) translate(380 146) translate(0 68) translate(752 20)"
      />
    </SvgIcon>
  );
};

IconFilters.propTypes = simpleIconPropsTypes;
