import React from 'react';
import { Paper } from '@material-ui/core';
import styles from './styles.module.css';
import SelectedVideoItem from '../SelectedVideoItem';
import { SpacingContainer } from '../../../components/common/Containers/Base';
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase';
import FeatureVideo from '../FeatureVideo';
import Typography from '../../../components/common/Typography';
import { Grid } from '@material-ui/core';

const VideoLibrary = ({ faq = [1, 2, 3, 4, 5, 6, 7, 8], training = [1, 2, 3, 4, 5, 6], items = [] }) => (
  <SpacingContainerBase>
    <SpacingContainer py={2} px={0}>
      <Typography weight="600" variant="h2" fontFamily="lexend" title>
        Video Library
      </Typography>
    </SpacingContainer>

    <Paper elevation={0} className={styles.marginTop}>
      <div className={styles.list}>
        <div className={styles.titleWithButton}>
          <h2>Featured video</h2>{' '}
        </div>
        <SpacingContainer py={0} px={1}>
          <FeatureVideo />
        </SpacingContainer>
      </div>
    </Paper>

    <Paper elevation={0} className={styles.marginTop}>
      <div className={styles.list}>
        <div className={styles.titleWithButton}>
          <h2>USFCR Common FAQs with Bid Trainer John Wayne</h2>{' '}
        </div>

        {faq.length > 0 ? (
          <SpacingContainer py={0} px={3}>
            <Grid container>
              {faq.map((item, index) => (
                <SelectedVideoItem key={index} index={index} hideDescription={true} />
              ))}
            </Grid>
          </SpacingContainer>
        ) : (
          <p className={styles.noRegisters}>No videos</p>
        )}
      </div>
    </Paper>

    <Paper elevation={0} className={styles.marginTop}>
      <div className={styles.list}>
        <div className={styles.titleWithButton}>
          <h2>Training Milestone</h2>{' '}
        </div>

        {training.length > 0 ? (
          <SpacingContainer py={0} px={3}>
            <Grid container>
              {training.map((item, index) => (
                <SelectedVideoItem key={index} index={index} />
              ))}
            </Grid>
          </SpacingContainer>
        ) : (
          <p className={styles.noRegisters}>No videos</p>
        )}
      </div>
    </Paper>
  </SpacingContainerBase>
);

export default VideoLibrary;
