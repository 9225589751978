import React, { useMemo, useState } from 'react';
import { Button } from '../../../common/Buttons';
import { InlineContainer, SpacingContainer } from '../../../common/Containers/Base';
import { ModalDialog } from '../../../common/Dialogs';
import Typography from '../../../common/Typography';
import { useTrans } from '../../../../services/i18n';
import connect from './connect';
import { useAlertContext } from '../../../../contexts/AlertContext';
import Grid from '@material-ui/core/Grid';
import { getInitialValues, useScheme } from './helpers';
import { useForm } from '../../../../hooks/useForm';
import { TextField } from '../../../../components/common/Inputs';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { ROUTE_QUOTE_REQUESTS } from '../../../../constants';
import { ChevronRightOutlined } from '@material-ui/icons';
import { isTodayOrAfter } from '../../../../utils/dateUtils';
import { COMPONENT_FEATURES } from '../../../../utils/constants';
import { useComponentPermission } from '../../../../hooks/useComponentPermission';
import premiumIcon from '../../../../components/assests/premium-icon.png';
import Alert from './components/Alert';
import { useIsMobile } from '../../../../hooks/useIsMobile';

// Form options for validation behavior
const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: true };

// Main component for creating a quote request
const CreateQuoteRequest = ({
  opportunity,
  type,
  size = 'small',
  createQuoteRequest,
  label = 'request-a-quote',
  withEndIcon = true,
  modalTitle = 'request-for-quote-modal-title',
}) => {
  const { trans } = useTrans();
  const { errorAlert } = useAlertContext();
  const schema = useScheme();
  const [success, setSuccess] = useState(false);
  const [pendingButton, setPendingButton] = useState(false);
  const [open, onOpen, onClose] = useBooleanHandlers(); // Handles modal open/close state
  const isMobile = useIsMobile('md');
  const [disabledSaved, setDisabledSaved] = useState(false);

  // Permission check for the component
  const { hasPermission } = useComponentPermission(COMPONENT_FEATURES.REQUEST_FOR_QUOTE);

  // Memoized calculation of whether the opportunity's due date is today or in the future
  const isDueDate = useMemo(() => {
    const dueDate = opportunity?.responseDateActual || opportunity?.closeDate;
    if (!dueDate) return false;
    return isTodayOrAfter(dueDate);
  }, [opportunity]);

  // Handle button click to open modal
  const handleClick = (event) => {
    onOpen();
  };

  // Function to handle form submission
  const onSubmit = (values) => {
    setPendingButton(true);
    createQuoteRequest({
      opportunityType: type,
      opportunityId: opportunity.id,
      message: values.message,
    })
      .then((res) => {
        if (res.payload.success) {
          setSuccess(true); // Show success message
          setDisabledSaved(true); // Disable button after submission
          resetForm(); // Reset form after success
        }
      })
      .catch(errorAlert) // Show alert on error
      .finally(() => {
        setPendingButton(false); // Reset button loading state
      });
  };

  // Form configuration, uses custom hook for form management
  const { getFieldProps, handleSubmit, resetForm } = useForm(
    schema,
    getInitialValues(opportunity),
    onSubmit,
    formOptions
  );

  // Close the modal and reset the form state
  const closeModal = () => {
    setSuccess(false);
    resetForm();
    onClose();
  };

  return (
    <>
      {/* Display the button if the due date has not passed */}
      {!isDueDate && (
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={hasPermission && handleClick}
          size={size}
          disabled={opportunity?.hasQuoteRequest || disabledSaved}
          endIcon={
            withEndIcon && (
              <>
                <ChevronRightOutlined />
                {!hasPermission && (
                  <img src={premiumIcon} alt="" style={{ width: 18, height: 18, marginLeft: '.4rem' }} />
                )}
              </>
            )
          }
          style={{
            marginRight: '1rem',
            backgroundColor: !hasPermission && 'rgba(78, 89, 120, 0.1)',
            color: !hasPermission && '#b2b8cf',
          }}
        >
          {trans(label)}
        </Button>
      )}

      {/* Modal for creating a quote request */}
      <ModalDialog
        open={open}
        onClose={closeModal}
        disableContentSpacing
        title={success ? `${trans('request-submitted')}` : trans(modalTitle)}
        direction={isMobile ? 'up' : 'down'}
        noWrap={false}
        titleHeight={96}
        actions={
          !success && (
            <InlineContainer>
              <Button
                style={{ height: '3.25rem', fontSize: '0.875rem', fontWeight: 700 }}
                pending={pendingButton}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                {trans('send-request-quote')}
              </Button>
            </InlineContainer>
          )
        }
        pending={pendingButton}
      >
        {success ? (
          // Success message after request submission
          <SpacingContainer px={4} py={4} style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                alignItems: 'center',
                background: '#17B471',
                color: 'white',
                borderRadius: '5px',
                marginBottom: '18px',
              }}
            >
              <CheckIcon style={{ marginRight: '10px' }} />
              <Typography variant="body2" weight={500} fontFamily="lexend">
                {trans('request-submitted')}
              </Typography>
            </div>

            <Typography variant="h5" weight={500} fontFamily="lexend">
              {trans('request-successfully-submitted')}
            </Typography>
            <Typography style={{ margin: '18px 0' }} variant="h6" component="p" weight={500} color="hint">
              {trans('your-req-submitted-receive-response')}
            </Typography>

            <Button to={ROUTE_QUOTE_REQUESTS.path} variant="contained" color="primary">
              {trans('click-here-to-view-all-requests')}
            </Button>
          </SpacingContainer>
        ) : (
          // Form content for quote request submission
          <div>
            <SpacingContainer px={4} py={4}>
              {/* Phone number is removed due to client request, because some users are not receiving the SMS and
              USFCR can get the contact data from Sales Force, so it is not necessary - APP-5785 */}
              <Alert />
              <Typography style={{ fontSize: '1.125rem' }} weight={500} fontFamily="lexend">
                {trans('add-comment')}
              </Typography>
              <Typography style={{ margin: '16px 0' }} variant="h6" component="p" weight={600} color="hint">
                {trans('comment-concern-question-quote')}
              </Typography>

              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    label={trans('message-180-characters-maximum')}
                    placeholder={trans('write-your-message')}
                    multiline
                    minRows={4}
                    maxRows={10}
                    maxLength={180}
                    {...getFieldProps('message')}
                  />
                </Grid>
              </Grid>
            </SpacingContainer>
          </div>
        )}
      </ModalDialog>
    </>
  );
};

export default connect(CreateQuoteRequest);
