import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { Button } from '../../../components/common/Buttons';
import TextField from '../../../components/common/Inputs/TextField';
import Typography from '../../../components/common/Typography';
import { IconBack } from '../../../components/svg';
import { ROUTE_PROFILE, ROUTE_SIGNIN } from '../../../constants';
import { useForm } from '../../../hooks/useForm';
import { useApplication } from '../../../services/application';
import { useTrans } from '../../../services/i18n';
import { catchYupErrors, schemaToInitialState } from '../../../utils/validationUtils';
import useStyles from '../../Auth/styles';
import AuthLayout from '../../../components/layouts/AuthLayout';
import { Link as RouterLink } from 'react-router-dom';
import connect from '../connect';

const Migrate = (props) => {
  const { updatePersonalInformation } = props;
  const classes = useStyles();
  const { trans } = useTrans();

  const application = useApplication();

  const history = useHistory();

  const schema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(trans('empty-not-allowed', { field: trans('first-name') })),
        familyName: Yup.string().required(trans('empty-not-allowed', { field: trans('last-name') })),
      }),
    [trans]
  );

  const handleSubmit = async (values, { setStatus, setErrors }) => {
    return application
      .call('user.migrateUser', values)
      .then((user) => {
        updatePersonalInformation(user);
        history.push(ROUTE_PROFILE.path);
      })
      .catch((e) => catchYupErrors(e, setErrors, (err) => setStatus({ message: err.message })));
  };

  const formik = useForm(schema, schemaToInitialState(schema), handleSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <AuthLayout>
      <div className={classes.formBack}>
        <Grid container spacing={0} justifyContent="center" className={classes.content}>
          <Grid item xs={12} className={classes.topHeader}>
            <IconButton edge="start" color="inherit" size="small" component={RouterLink} to={ROUTE_SIGNIN.path}>
              <IconBack />
            </IconButton>
          </Grid>
          <Grid item xs={12} className={classes.signForm}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h1" fontFamily="lexend" weight="500">
                  {trans('migrate-info')}
                </Typography>
              </Grid>
              {formik.status?.message && (
                <Grid item xs={12}>
                  <Typography color="error">{formik.status?.message}</Typography>
                </Grid>
              )}
              <Grid item md={6} xs={12}>
                <TextField
                  label={trans('first-name')}
                  placeholder={trans('name')}
                  required
                  {...formik.getFieldProps('firstName')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label={trans('last-name')}
                  placeholder={trans('name')}
                  {...formik.getFieldProps('familyName')}
                  required
                />
              </Grid>
              <Grid item md={6} xs={12} />
              <Grid item md={6} xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  fullWidth
                  onClick={formik.handleSubmit}
                  pending={formik.isSubmitting}
                >
                  {trans('continue')}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.flexHelper} />
          </Grid>
          <Grid item xs={12} className={classes.flexHelper} />
        </Grid>
      </div>
    </AuthLayout>
  );
};

export default connect(Migrate);
