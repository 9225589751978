import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import useDropTarget from '../../../../hooks/dnd/useDropTarget';
import { useTrans } from '../../../../services/i18n';
import { openFiles } from '../../../../utils/filesUtils';
import { IconUpload } from '../../../svg/Icons/IconUpload';
import { Button } from '../../Buttons';
import { Paper } from '../../Containers';
import Typography from '../../Typography';
import InputFile from './InputFile';
import useUploadFiles from './useUploadFiles';
import Grid from '@material-ui/core/Grid';
import { useIsMobile } from '../../../../hooks/useIsMobile';

export default function DnDField({
  onChange,
  value,
  name = 'files',
  multiple = false,
  labelFile = false,
  subLabelFile = false,
  subSubLabelFile = false,
  subSubSubLabelFile = false,
  bgColor = 'paperSecondary',
}) {
  const { trans } = useTrans();
  const styles = useStyles();
  const { handleChange, handleInputChange } = useUploadFiles({ value, name, onChange, multiple });
  const isMobile = useIsMobile();

  const { dragOver, dropRef } = useDropTarget({
    accept: NativeTypes.FILE,
    drop: (data) => {
      const files = openFiles(data.files);
      handleChange(files);
    },
  });

  return (
    <Paper radius="8" ref={dropRef} bgColor={bgColor} className={clsx(styles.paper, dragOver && styles.dragOver)}>
      <Typography color="primary" align="center" gutter="title" weight="bold">
        <IconUpload />
      </Typography>
      <Button color="primary" fullWidth component="label">
        <Typography weight="bold">
          {labelFile ? labelFile : trans(isMobile ? 'click-to-browse' : 'DragFilesOrClickToBrowse')}
        </Typography>
        <InputFile multiple={multiple} onChange={handleInputChange} name={name} />
      </Button>
      {subLabelFile && (
        <Grid item xs={12}>
          <div className={styles.subLabelContainer}>
            <Typography className={styles.subLabel}>{subLabelFile}</Typography>
          </div>
        </Grid>
      )}
      {subSubLabelFile && (
        <div className={styles.subSubLabelContainer}>
          <Typography className={styles.subLabel}>{subSubLabelFile}</Typography>
        </div>
      )}
      {subSubSubLabelFile && (
        <div className={styles.subSubSubLabelContainer}>
          <Typography className={styles.subLabel}>{subSubSubLabelFile}</Typography>
        </div>
      )}
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 155,
    padding: '20px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px dashed #ccc',
  },
  dragOver: {
    border: `2px dashed ${theme.palette.primary.main}`,
  },
  subLabelContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  subSubLabelContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  subSubSubLabelContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  subLabel: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
  },
}));
