import Backdrop from '@material-ui/core/Backdrop';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  mobileStepper: { zIndex: theme.zIndex.mobileStepper },
  speedDial: { zIndex: theme.zIndex.speedDial },
  appBar: { zIndex: theme.zIndex.appBar },
  drawer: { zIndex: theme.zIndex.drawer },
  modal: { zIndex: theme.zIndex.modal },
  snackbar: { zIndex: theme.zIndex.snackbar },
  tooltip: { zIndex: theme.zIndex.tooltip },

  absolute: { position: 'absolute' },
}));

export default function BackDrop({
  children,
  className,
  zIndex = 'mobileStepper',
  open = false,
  absolute = false,
  top = 80,
  ...rest
}) {
  const styles = useStyles();

  return (
    <Backdrop
      style={{ top }}
      open={open}
      className={clsx(styles[zIndex], className, absolute && styles.absolute, styles.top)}
      {...rest}
    >
      {children}
    </Backdrop>
  );
}
