import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100vh',
    position: 'relative',
    borderRadius: 0,
    backgroundSize: 'cover',
    padding: '2.5rem',
    width: '100%',
    justifyContent: 'space-between',
    color: '#fff',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  height: {
    height: '100vh',
  },
  authHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logos: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  logoApp: {
    width: 150,
    height: 70,
  },
  imageBlock: {
    maxHeight: '100vh',
  },
  mobileHeader: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  contentContainer: {
    maxHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    backgroundColor: 'white',
    height: 'fit-content',
    width: '100%',
    borderRadius: '2rem',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photo: {
    width: 130,
    height: 50,
  },
  flatContainer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  flatHeader: {
    padding: (props) => (props.isMobile ? '2rem 1rem' : '0 1.875rem 0rem'),
    zIndex: 999,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  flatContent: {
    flex: 1,
    overflowY: (props) => (props.isMobile ? 'visible' : 'auto'),
  },
  flatFooter: {
    padding: (props) => (props.isMobile ? '2rem 3rem' : '2rem 1.875rem'),
    color: theme.palette.gray.light,
    zIndex: 999,
    width: '100%',
    display: 'flex',
    flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
    alignItems: 'center',
    justifyContent: (props) => (props.isMobile ? 'center' : 'space-between'),
    '& p': {
      marginTop: 13,
    },
  },
  authTitle: {
    textShadow: '0 .25rem 1rem rgba(0, 0, 0, 0.16) !important',
    whiteSpace: 'pre-wrap',
    fontSize: '2.125rem',
  },
  cornerLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  footer: {
    background: 'linear-gradient(to top, rgba(0 0 0 / 60%), transparent)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      textAlign: 'center',
      padding: '1.125rem 3rem',
    },
  },
  mobileFooter: {
    background: 'linear-gradient(to top, rgba(0 0 0 / 60%), transparent)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '1.125rem 3rem',
  },
}));
