import React, { useEffect, useState } from 'react';
import { useTrans } from '../../../services/i18n';
import { SelectField, TextField } from '../../../components/common/Inputs';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { useForm } from '../../../hooks/useForm';
import { useApplication } from '../../../services/application';
import { catchYupErrors, schemaToInitialState } from '../../../utils/validationUtils';
import MenuItem from '@material-ui/core/MenuItem';
import { AmplifyAuth } from '../../../services/amplifyAuth';
import makeValidationSchema from '../../../hooks/makeValidationSchema';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAlertContext } from '../../../contexts/AlertContext';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PhoneNumber from '../PhoneNumber';
import { Divider } from '../../../components/common/Divider';
import SaveDialogAction from '../../../components/common/Dialogs/SaveDialogAction';
import { LINKEDIN_URL_FORMAT } from '../../../constants/regex';

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    email: Yup.string()
      .email(trans('incorrect-email'))
      .required(trans('empty-not-allowed', { field: trans('email') })),
    firstName: Yup.string().required(trans('empty-not-allowed', { field: trans('first-name') })),
    lastName: Yup.string().required(trans('empty-not-allowed', { field: trans('last-name') })),
    phone: Yup.string(),
    timezone: Yup.string(),
    dateFormat: Yup.string(),
    jobTitle: Yup.string(),
    linkedInUrl: Yup.string().matches(LINKEDIN_URL_FORMAT, trans('linkedin-error-msg')),
  })
);

function getRenderValue(timezone, value) {
  const item = timezone.find((tz) => tz.abbr === value);
  return item && item.text;
}

function getDateRenderValue(dfs, value) {
  const d = dfs.find((i) => i.value === value);
  return d && d.name;
}

const useStyles = makeStyles((theme) => ({
  labelDropdowns: {
    fontFamily: 'Lexend',
    marginBottom: '8px',
    color: '#4e5978',
    fontWeight: 500,
  },
  containerCard: {
    padding: '2.125rem 1.5rem 1rem',
  },
  containerFooter: {
    padding: '1.3rem 2.3rem 1.87rem 2.3rem',
    marginTop: 'unset',
  },
}));

const formatDates = [
  {
    name: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY',
  },
  {
    name: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY',
  },
];

export default function PersonalInformation({ user, onUpdate }) {
  const { trans } = useTrans();
  const application = useApplication();
  const schema = useScheme();
  const [pending, setPending] = useState(false);
  const [timezone, setTimezone] = React.useState([]);
  const styles = useStyles();
  const { successAlert, errorAlert } = useAlertContext();

  useEffect(() => {
    application.call('utilities.timezones').then((val) => setTimezone(val));
  }, [application]);

  const onSubmit = React.useCallback(
    async (values, { setStatus, setErrors }) => {
      try {
        setPending(true);
        Promise.resolve(application.call('user.setPersonalInformation', values))
          .then(async (result) => {
            successAlert(trans('personal-info-updated-successfully'));
            localStorage.setItem('authFormatDate', result.formatDate ? result.formatDate : 'MM/DD/YYYY');
            localStorage.setItem('authTimezone', result.authTimezone);
            onUpdate(result);
            await AmplifyAuth.currentAuthenticatedUser({
              bypassCache: true,
            });
          })
          .catch((e) => {
            errorAlert(e.message);
            catchYupErrors(e, setErrors, (err) => setStatus({ message: err.message }));
          })
          .finally(() => {
            setPending(false);
          });
      } catch (e) {
      } finally {
      }
    },
    [application, onUpdate]
  );

  const { setValues, getFieldProps, handleSubmit, values } = useForm(schema, schemaToInitialState(schema), onSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    setValues({
      firstName: user?.name || '',
      lastName: user?.familyName || '',
      email: user?.email || '',
      timezone: user?.timezone || '',
      formatDate: user?.formatDate || 'MM/DD/YYYY',
      phone: (user?.phoneVerified === 'true' || user?.phoneVerified === true) ? user?.phone : '',
      jobTitle: user?.jobTitle || '',
      linkedInUrl: user?.linkedInUrl || '',
    });
  }, [user, setValues]);

  return (
    <React.Fragment>
      <Grid container spacing={3} className={styles.containerCard}>
        <Grid item sm={6} xs={12} px={0}>
          <TextField type="text" label={trans('first-name')} {...getFieldProps('firstName')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField type="text" label={trans('last-name')} {...getFieldProps('lastName')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField type="text" label={trans('email')} {...getFieldProps('email')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className={styles.labelDropdowns}>{trans('phone')}</div>
          <div style={{ position: 'relative' }}>
            <PhoneInput
              international
              {...getFieldProps('phone')}
              style={{
                background: '#f1f3f9',
                padding: '12px 2px',
                borderRadius: '3px',
                paddingRight: '60px',
              }}
              disabled
            />
            <div style={{ position: 'absolute', right: '10px', top: '11px' }}>
              <PhoneNumber auth={user} />
            </div>
          </div>
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            type="text"
            label={trans('job-title')}
            {...getFieldProps('jobTitle')}
            placeholder={trans('optional')}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            type="text"
            label={trans('linkedin-profile')}
            {...getFieldProps('linkedInUrl')}
            placeholder={trans('optional')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className={styles.labelDropdowns}>{trans('timezone')}</div>
          <SelectField
            placeholder={trans('select-timezone')}
            type="select"
            getRenderValue={getRenderValue.bind(null, timezone)}
            {...getFieldProps('timezone')}
          >
            {timezone &&
              timezone.map((tz, index) => (
                <MenuItem key={`tz_${index}`} value={tz.abbr}>
                  {tz.text}
                </MenuItem>
              ))}
          </SelectField>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className={styles.labelDropdowns}>Date Format</div>
          <SelectField
            type="select"
            getRenderValue={getDateRenderValue.bind(null, formatDates, values.formatDate)}
            {...getFieldProps('formatDate')}
          >
            {formatDates &&
              formatDates.map((fd, index) => (
                <MenuItem key={`fd_${index}`} value={fd.value}>
                  {fd.name}
                </MenuItem>
              ))}
          </SelectField>
        </Grid>
      </Grid>
      <Divider light spacing={1} />
      <Grid container className={styles.containerFooter}>
        <SaveDialogAction formId="personal-info-edit" onSaveClick={handleSubmit} pending={pending} />
      </Grid>
    </React.Fragment>
  );
}
