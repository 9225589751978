import React from 'react';
import { useTrans } from '../../../../../services/i18n';
import { Grid } from '@material-ui/core';
import useStyles from '../../../styles';
import { ModalDialog } from '../../../../../components/common/Dialogs';
import Typography from '../../../../../components/common/Typography';

export const TermsModal = ({ open, onClose }) => {
  const { trans } = useTrans();
  const classes = useStyles();

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      bgColor="default"
      title={trans('terms-of-service')}
      position="fullHeight"
    >
      <div className={classes.signModalsCover}>
        <Grid container spacing={4} justifyContent="center" className={classes.content}>
          <Grid item xs={12}>
            <Typography weight="500" variant="h6" component="p" className={classes.modalText}>
              {`${trans('last-updated')}:`} 08/12/2020
            </Typography>
            <Typography weight="500" variant="h6" component="p" className={classes.modalText}>
              {trans('terms-text-1')}
            </Typography>
            <Typography weight="500" variant="h6" component="p" className={classes.modalText}>
              {trans('terms-text-2')}
            </Typography>
            <Typography weight="500" variant="h6" component="p" className={classes.modalText}>
              {trans('terms-text-3')}
            </Typography>
            <Typography weight="500" variant="h6" component="p" className={classes.modalText}>
              {trans('terms-text-4')}
            </Typography>
            <Typography weight="500" variant="h6" component="p" className={classes.modalText}>
              {trans('terms-text-5')}
            </Typography>
            <Typography weight="500" variant="h6" component="p" className={classes.modalText}>
              {trans('terms-text-6')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </ModalDialog>
  );
};
