import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import IconError from '@material-ui/icons/ErrorOutline';
import IconInfo from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { IconCheck } from '../../svg/Icons/IconCheck';
import { IconClose } from '../../svg/Icons/IconClose';
import Typography from '../Typography';
import Snackbar from './index';

export default function AlertSnackbar({
  children,
  onClose,
  title = '',
  message = '',
  variant = 'default',
  ...snackbarProps
}) {
  return (
    <Snackbar bgColor={variant} onClose={onClose} {...snackbarProps}>
      <Grid container alignItems="flex-start" spacing={2} wrap="nowrap">
        <Grid item>
          {variant === 'error' ? (
            <IconError className="autoSize" width={26} height={26} />
          ) : variant === 'success' ? (
            <IconCheck width={26} height={26} />
          ) : (
            <IconInfo className="autoSize" width={26} height={26} />
          )}
        </Grid>
        <Grid item>
          {!!title && (
            <Typography variant="h6" weight={600}>
              {title}
            </Typography>
          )}
          {!!message && <Typography variant="body2">{message}</Typography>}

          {children}
        </Grid>
        <Grid item>
          <IconButton onClick={onClose} color="inherit" size="small" edge="end">
            <IconClose />
          </IconButton>
        </Grid>
      </Grid>
    </Snackbar>
  );
}
