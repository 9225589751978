import React from 'react';
import { useApiAutocomplete } from '../../hooks/useApiAutocomplete';
// import { application } from '../../services/application';
import Autocomplete from '../common/Inputs/Autocomplete';

const getOptionLabel = (option) => (option ? option.value : '');

export const TagsAutocomplete = (props) => {
  const {
    getTags,
    onChange,
    value,
    multi,
    labelKey = 'value',
    label,
    name = 'tags',
    maxTagTextLength = null,
    validateOnEnterPress = true,
    ...rest
  } = props;

  const { options, loading, handleValueChange, filterOptions, handleInputChange } = useApiAutocomplete({
    labelKey,
    onChange: React.useCallback((val) => onChange && onChange(val), [onChange]),
    getOptionList: getTags ? getTags : (params) => true, // TODO - get list api
    createOption: (params) => true, // TODO - create api
  });

  return (
    <Autocomplete
      name={name}
      options={options}
      label={label}
      labelKey={labelKey}
      loading={loading}
      value={multi ? value ?? [] : value}
      filterOptions={filterOptions}
      onChange={handleValueChange}
      multiple={multi}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      maxTagTextLength={maxTagTextLength}
      validateOnEnterPress={validateOnEnterPress}
      {...rest}
    />
  );
};
