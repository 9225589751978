import { useEffect, useState } from 'react';
import useActions from '../../../../../hooks/dispatchers/useActions';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import { actions } from '../../../../../store/billing';
import useStyles from '../styles';

export default function useSeats() {
  const [getSeatPrice] = useActions([actions.getSeatPrice]);
  const [seatPrice, setSeatPrice] = useState();

  useEffect(() => {
    getSeatPrice().then((res) => {
      if (res.payload?.success) {
        setSeatPrice(res.payload.seat);
      }
    });
  }, [getSeatPrice]);

  const [openDialog, onOpenDialog, onCloseDialog] = useBooleanHandlers();
  const classes = useStyles();

  return [seatPrice, openDialog, onOpenDialog, onCloseDialog, classes];
}
