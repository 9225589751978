import React, { useEffect } from 'react';
import { CloseTitle, ModalDialog } from '../../../common/Dialogs/ModalDialog';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { Box, Grid } from '@material-ui/core';
import Typography from '../../../common/Typography';
import { Button, RouterLink } from '../../../common/Buttons';
import { useTrans } from '../../../../services/i18n';
import hourglassImg from '../../../assests/hourglass.svg';
import { ROUTE_PLANS_PRIVATE } from '../../../../constants';
import ExpiredIcon from './components/ExpiredIcon';
import NearExpiredIcon from './components/NearExpiredIcon';
import PhoneIcon from './components/PhoneIcon';
import VideoIcon from './components/VideoIcon';
import { useIsMobile } from '../../../../hooks/useIsMobile';

const ExpiredMessage = () => (
  <Typography weight={600} lineHeight="1.54em" style={{ fontSize: '1.285em' }}>
    Your subscription to the Advanced Procurement Portal has
    <span style={{ color: '#f32445' }}> expired</span>, limiting your access to key features like contract notifications
    and saved searches.
  </Typography>
);

const NearExpirationMessage = () => (
  <Typography weight={600} lineHeight="1.54em" style={{ fontSize: '1.285em' }}>
    Your subscription to the Advanced Procurement Portal
    <span style={{ color: '#ff7825' }}> is about to expire</span>, limiting your access to key features like contract
    notifications and saved searches.
  </Typography>
);

function NearExpirationModal({ subscription, user }) {
  const [open, onOpen, onClose] = useBooleanHandlers(false);
  const [title, setTitle] = React.useState(null);
  const [icon, setIcon] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const { trans } = useTrans();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (subscription?.isNearExpiration) {
      onOpen();
      setTitle(trans('subscription-is-about-to-expire'));
      setIcon(<NearExpiredIcon />);
      setMessage(<NearExpirationMessage />);
    }

    if (subscription?.currentPlan === 'expired') {
      onOpen();
      setTitle(trans('subscription-has-expired'));
      setIcon(<ExpiredIcon />);
      setMessage(<ExpiredMessage />);
    }
  }, [subscription, onOpen, trans]);

  return (
    <ModalDialog open={open} onClose={onClose} title={title}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" gridGap="1rem">
            {icon}
            {message}
          </Box>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '.75em' }}>
          <Box display="flex" gridGap="1rem">
            <PhoneIcon />
            <Typography variant="h6" weight={400}>
              If you need help reactivating your account, contact a specialist at{' '}
              <a
                href="tel:+18772522700"
                style={{
                  color: '#2445a7',
                  textDecoration: 'underline',
                }}
              >
                877 252 2700
              </a>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '.75em' }}>
          <Box display="flex" gridGap="1rem">
            <VideoIcon />
            <Typography variant="h6" weight={400}>
              To learn more about how APP can support your success, watch the video below:
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '1rem', padding: isMobile ? '0' : '0 4rem' }}>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/993094653?h=d0a2a28e73&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              title="APP_RENEWAL"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </Grid>
      </Grid>
    </ModalDialog>
  );
}

export default NearExpirationModal;
