import React, { useEffect } from 'react';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';
import connect from './connect';
import Loader from '../../components/common/Loader';
import Plans from './components/Plans';
import FullPageLayout from '../../components/layouts/FullPageLayout';
import { useTrans } from '../../services/i18n';
import Typography from '../../components/common/Typography';
import { Button, RouterLink } from '../../components/common/Buttons';
import { ROUTE_SIGNIN } from '../../constants';
import { Grid, Box } from '@material-ui/core';
import { useIsMobile } from '../../hooks/useIsMobile';

function PublicPlans({ getProducts, pending }) {
  const isMobile = useIsMobile();
  const { trans } = useTrans();
  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const Actions = () =>
    isMobile ? (
      <Box display="flex" alignItems="center" maxWidth="50vw">
        <Box textAlign={'end'} mr={1}>
          <Typography component="span" variant="body2" weight="500" color="hint">
            {trans('already-have-an-account')}
          </Typography>
        </Box>
        <Box flex={1}>
          <Button
            id="sign-in-sign-up-btn"
            color="primary"
            variant="outlined"
            component={RouterLink}
            to={ROUTE_SIGNIN.path}
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {trans('sign-in')}
          </Button>
        </Box>
      </Box>
    ) : (
      <Grid item container spacing={3} justifyContent="flex-end" alignItems="baseline">
        <Grid item>
          <Typography component="span" variant="body2" weight="500" color="hint">
            {trans('already-have-an-account')}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            id="sign-in-sign-up-btn"
            color="primary"
            variant="outlined"
            component={RouterLink}
            to={ROUTE_SIGNIN.path}
          >
            {trans('sign-in')}
          </Button>
        </Grid>
      </Grid>
    );

  return (
    <FullPageLayout actions={<Actions />}>
      <SpacingContainerBase py={0} px={0}>
        {pending && <Loader open />}
        <Plans />
      </SpacingContainerBase>
    </FullPageLayout>
  );
}

export default connect(PublicPlans);
