import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { CODE_CATEGORIES, FEDERAL_AGENCIES } from '../../constants/api';
import { application } from '../../services/application';
import {
  extendBuilderWithListActions,
  extendBuilderWithReportsActions,
  generateListActions,
  generateReportsActions,
  INITIAL_LIST_STATE,
} from '../helpers/listActionsHelpers';
import { onItemDetails, onReject, onPending, onPendingDone } from '../helpers/sharedCases';
import { API, SCOPE_CODE_CATEGORIES } from '../../constants';
import { INITIAL_AWARDS_COUNT_STATE, INITIAL_FUNDING_STATE } from '../helpers/common';

export const INITIAL_STATE = {
  item: null,
  pending: true,
  fundingAnalysis: INITIAL_LIST_STATE,
  popularOpportunities: INITIAL_LIST_STATE,
  federalContractAwards: INITIAL_LIST_STATE,
  funding: INITIAL_FUNDING_STATE,
  awards: INITIAL_AWARDS_COUNT_STATE,
  opportunities: INITIAL_LIST_STATE,
};

export const fetchCodeCategory = createAsyncThunk('codeCategories/findOne', async (params) => {
  return application.call(CODE_CATEGORIES.FIND_ONE, params);
});

const fundingAnalysisListActions = generateListActions({
  scope: `${SCOPE_CODE_CATEGORIES}/fundingAnalysis`,
  apiMethod: {
    GET_LIST: FEDERAL_AGENCIES.FETCH_FUNDING_ANALYSIS,
    EXPORT_CSV: FEDERAL_AGENCIES.EXPORT_FUNDING_ANALYSIS,
  },
  getStore: (store) => store.codeCategoryDetails.fundingAnalysis,
});

const popularOpportunitiesListActions = generateListActions({
  scope: `${SCOPE_CODE_CATEGORIES}/popularOpportunities`,
  apiMethod: {
    GET_LIST: CODE_CATEGORIES.FETCH_POPULAR_OPPORTUNITIES,
  },
  getStore: (store) => store.codeCategoryDetails.popularOpportunities,
});

const agencyReportsActions = generateReportsActions({
  scope: `${SCOPE_CODE_CATEGORIES}/reports`,
  apiMethod: {
    GET_FUNDING: API.CODE_CATEGORIES.GET_FUNDING,
    GET_AWARDS_COUNT: API.CODE_CATEGORIES.GET_AWARDS_COUNT,
  },
  getStore: (store) => store.codeCategoryDetails,
});

const federalContractAwardsListActions = generateListActions({
  scope: `${SCOPE_CODE_CATEGORIES}/federalContractAwards`,
  apiMethod: {
    GET_LIST: CODE_CATEGORIES.FETCH_FEDERAL_CONTRACT_AWARDS,
    EXPORT_CSV: FEDERAL_AGENCIES.EXPORT_FEDERAL_CONTRACT_AWARDS,
  },
  getStore: (store) => store.codeCategoryDetails.federalContractAwards,
});

const opportunitiesListActions = generateListActions({
  scope: `${SCOPE_CODE_CATEGORIES}/opportunities`,
  apiMethod: {
    GET_LIST: CODE_CATEGORIES.CONTRACT_OPPORTUNITIES,
    EXPORT_CSV: CODE_CATEGORIES.CONTRACT_OPPORTUNITIES_EXPORT_CSV,
  },
  getStore: (store) => store.codeCategoryDetails.opportunities,
});

const addToFavorite = createAsyncThunk('federalAgents/toggleFavorite', (params) => {
  return application.call(API.CODE_CATEGORIES.TOGGLE_FAVORITE, params);
});

function onAddFavorite(state, action) {
  if (state.item) {
    state.item.isFavorite = action.payload.isFavorite;
  }
  return Object.assign(state, { pending: false });
}

const cleanStore = createAction('codeCategories/cleanStore');

export const actions = {
  fetchCodeCategory,
  cleanStore,
  fundingAnalysisListActions,
  popularOpportunitiesListActions,
  ...agencyReportsActions,
  federalContractAwardsListActions,
  opportunitiesListActions,
  addToFavorite,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, fundingAnalysisListActions, (store) => store.fundingAnalysis);
  extendBuilderWithListActions(builder, popularOpportunitiesListActions, (store) => store.popularOpportunities);
  extendBuilderWithListActions(builder, federalContractAwardsListActions, (store) => store.federalContractAwards);
  extendBuilderWithReportsActions(builder, agencyReportsActions, (store) => store);
  extendBuilderWithListActions(builder, opportunitiesListActions, (store) => store.opportunities);

  return builder
    .addCase(addToFavorite.fulfilled, onAddFavorite)
    .addCase(addToFavorite.pending, onPending)
    .addCase(addToFavorite.rejected, onPendingDone)
    .addCase(fetchCodeCategory.fulfilled, onItemDetails)
    .addCase(fetchCodeCategory.rejected, onReject)
    .addCase(fetchCodeCategory.pending, (state) => Object.assign(state, INITIAL_STATE, { pending: true }));
});
