import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_NSN_OPPORTUNITIES } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const NsnOpportunities = React.lazy(() => import('./nsnOpportunities'));
// const FederalAgentsDetails = React.lazy(() => import('./FederalAgentDetails'));

export default function NsnOpportunitiesWrapper() {
  return (
    <Suspense>
      <Switch>
        {/* <Route exact path={ROUTE_FEDERAL_AGENTS_DETAILS.path}>
          <FederalAgentsDetails />
        </Route>
        */}
        <Route exact path={ROUTE_NSN_OPPORTUNITIES.path}>
          <NsnOpportunities />
        </Route>
        <Redirect exact path="/*" to={ROUTE_NSN_OPPORTUNITIES.path} />
      </Switch>
    </Suspense>
  );
}
