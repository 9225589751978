import React from 'react';
import Suspense from '../../services/lazyLoad/Suspense';

const SavedSearches = React.lazy(() => import('./SavedSearches'));

export default function SavedSearchesWrapper() {
  return (
    <Suspense>
      <SavedSearches />
    </Suspense>
  );
}
