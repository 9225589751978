import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconArrowForward from '@material-ui/icons/ArrowForward';
import React from 'react';
import { useTrans } from '../../../../services/i18n';
import { InputPropsSearch, InputPropsSearchSmall } from '../../../common/Inputs';
import TextField from '../../../common/Inputs/TextField';
import { IconClose } from '../../../svg';
import useSearchField from './useSearchField';

export default function SimpleSearchField({
  placeholder,
  onFocus,
  onBlur,
  name = 'searchText',
  size = 'small',
  bgColor = 'secondary',
  ...props
}) {
  const { trans } = useTrans();
  const { text, changed, onSubmit, onClearSearch, onChange } = useSearchField(props);

  const InputPropsSearchFull = {
    startAdornment: size === 'small' ? InputPropsSearchSmall.startAdornment : InputPropsSearch.startAdornment,
    endAdornment: (
      <InputAdornment position="end">
        {!!text ? (
          changed ? (
            <IconButton className="primary" size="small" type="submit">
              <IconArrowForward fontSize={size === 'small' ? 'small' : 'medium'} />
            </IconButton>
          ) : (
            <IconButton onClick={onClearSearch} size="small">
              <IconClose />
            </IconButton>
          )
        ) : (
          false
        )}
      </InputAdornment>
    ),
  };

  return (
    <form action="#" onSubmit={onSubmit}>
      <TextField
        size={size}
        bgColor={bgColor}
        value={text}
        onChange={onChange}
        InputProps={InputPropsSearchFull}
        placeholder={placeholder || trans('search')}
        name={name}
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </form>
  );
}
