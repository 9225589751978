import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconNear = (props) => {
  const { width = 18, height = 18 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 18 18">
      <path
        d="M10.3914 15.3334H10.3414C10.158 15.3224 9.98342 15.251 9.84476 15.1304C9.70611 15.0099 9.61119 14.8469 9.57477 14.6668L8.16644 7.83342L1.33311 6.42509C1.15267 6.38925 0.989156 6.29472 0.86807 6.15622C0.746985 6.01772 0.675132 5.84305 0.663716 5.65944C0.6523 5.47582 0.701962 5.2936 0.804958 5.14117C0.907955 4.98874 1.0585 4.87467 1.23311 4.81676L14.5664 0.375091C14.7161 0.313293 14.8805 0.296436 15.0396 0.32658C15.1987 0.356723 15.3456 0.432562 15.4623 0.544826C15.5789 0.65709 15.6604 0.800916 15.6967 0.958724C15.7329 1.11653 15.7224 1.28149 15.6664 1.43342L11.2248 14.7668C11.1668 14.9392 11.0539 15.088 10.9035 15.1903C10.753 15.2927 10.5732 15.3429 10.3914 15.3334ZM4.72478 5.41676L9.04978 6.30009C9.21145 6.33128 9.36026 6.40966 9.47743 6.52534C9.59459 6.64103 9.67487 6.78883 9.70811 6.95009L10.5831 11.2751L13.5164 2.48342L4.72478 5.41676Z"
        fill="#888FA9"
      />
    </SvgIcon>
  );
};

IconNear.propTypes = simpleIconPropsTypes;
