import { createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_AGENCIES_OFFICES } from '../../constants';
import {
  extendListBuilder,
  generateListActions,
  generateSearchActions,
  INITIAL_LIST_STATE_EXTENDED,
} from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['relevance', 'desc'],
  type: 'department',
};

const listActions = generateListActions({
  scope: SCOPE_AGENCIES_OFFICES,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.GET_LIST,
    EXPORT_CSV: API.FEDERAL_AGENCIES.EXPORT_CSV,
  },
  getStore: (store) => store.federalAgenciesAndOffices,
});

const searchActions = generateSearchActions({
  scope: SCOPE_AGENCIES_OFFICES,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.federalAgenciesAndOffices,
});

export const actions = {
  ...listActions,
  ...searchActions,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, { ...listActions, ...searchActions });
});
