import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const initialValues = {
  name: '',
  description: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    name: Yup.string().required(trans('required-field')),
    description: Yup.string().optional().nullable().default(undefined),
  })
);
