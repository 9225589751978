import { Grid, Box, Link, InputAdornment } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '../../../../../components/common/Buttons';
import { InputPropsLock, InputPropsUser, TextField } from '../../../../../components/common/Inputs';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import SimpleForm from '../../../../../components/common/SimpleForm';
import Typography from '../../../../../components/common/Typography';
import { IconHelp } from '../../../../../components/svg';
import { ROUTE_PLANS_PUBLIC } from '../../../../../constants';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import { useTrans } from '../../../../../services/i18n';
import useStyles from '../../../styles';
import { ForgotPassword } from '../ForgotPassword';
import { NeedHelpDialog } from '../NeedHelpDialog';
import { temporaryPasswordExpired, userNotConfirmed } from '../../constants';
import ResendConfirmationLink from '../../../ResendConfirmationLink';
import ResendTemporaryPassword from '../../../ResendTemporaryPassword';
import TermsAndConditions from '../Termsandcond';
import poweredByUsfcr from '../../../../../components/assests/powered-by-usfcr.svg';

function SignInForm({ pending = false, onSubmit, error, onFlushError, openForgotPasswordModal }) {
  const classes = useStyles();
  const { trans } = useTrans();
  const [openForgotPassword, onOpenForgotPassword, onCloseForgotPassword] = useBooleanHandlers();
  const [openNeedHelpDialog, onOpenNeedHelpDialog, onCloseNeedHelpDialog] = useBooleanHandlers();
  const [email, setEmail] = React.useState('');
  const [inputType, setInputType] = React.useState('password');
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  useEffect(() => {
    if (openForgotPasswordModal) {
      onOpenForgotPassword();
    }
  }, [openForgotPasswordModal, onOpenForgotPassword]);

  // This function is triggered on the keyup event
  const checkCapsLock = (event) => {
    if (event.getModifierState('CapsLock')) {
      if (event.keyCode === 20)
        if (isCapsLockOn) setIsCapsLockOn(false);
        else setIsCapsLockOn(true);
      else setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const getErrorMessage = () => {
    switch (error) {
      case userNotConfirmed:
        return (
          <React.Fragment>
            <Typography variant={'span'} color="error">
              {trans('looks-like-not-confirmed-user')}{' '}
            </Typography>
            <ResendConfirmationLink email={email} onSent={onFlushError} />
          </React.Fragment>
        );
      case temporaryPasswordExpired:
        return (
          <React.Fragment>
            <Typography variant={'span'} color="error">
              {trans('temporary-password-expired')}{' '}
            </Typography>
            <ResendTemporaryPassword email={email} onSent={onFlushError} />
          </React.Fragment>
        );
      default:
        return <Typography color="error">{error}</Typography>;
    }
  };

  const handleHelpResetPress = useCallback(() => {
    onCloseNeedHelpDialog();
    onOpenForgotPassword();
  }, [onOpenForgotPassword, onCloseNeedHelpDialog]);

  const handleVisibilityPassword = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  return (
    <>
      <ForgotPassword open={openForgotPassword} onClose={onCloseForgotPassword} />
      <NeedHelpDialog open={openNeedHelpDialog} onClose={onCloseNeedHelpDialog} onResetClick={handleHelpResetPress} />

      <div className={classes.formBack}>
        <Grid container spacing={0} justifyContent="center" className={classes.content}>
          <Grid item xs={12} className={classes.signHeader} justifyContent="space-between" alignItems="baseline">
            <Box className={classes.signHeaderElementContainer}>
              <img src={poweredByUsfcr} alt="powered-by-usfcr" />

              <Box display="flex" alignItems="center" className={classes.authStateMessage}>
                <Typography component="span" variant="body2" weight="400">
                  {trans('dont-have-an-account')}
                </Typography>
                <Button
                  id="sign-in-sign-up-btn"
                  color="primary"
                  variant="outlined"
                  component={RouterLink}
                  to={ROUTE_PLANS_PUBLIC.path}
                >
                  {trans('sign-up')}
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs className={classes.signForm}>
            <Grid container spacing={2} component={SimpleForm} name="signInForm" onSubmit={onSubmit}>
              <Grid item xs={12}>
                <Typography variant="h1Medium" fontFamily="lexend" weight="500" gutter="title">
                  {trans('sign-in')}
                </Typography>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  {getErrorMessage()}
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="login"
                  placeholder={trans('email')}
                  InputProps={InputPropsUser}
                  big
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onKeyUp={checkCapsLock}
                  onKeyDown={checkCapsLock}
                  name="password"
                  type={inputType}
                  placeholder={trans('password')}
                  InputProps={{
                    ...InputPropsLock,
                    endAdornment: (
                      <InputAdornment
                        onClick={handleVisibilityPassword}
                        position="end"
                        className={classes.cursorPointer}
                      >
                        {inputType === 'password' ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </InputAdornment>
                    ),
                  }}
                  disabled={pending}
                  big
                />
                {isCapsLockOn && <small style={{ color: '#cb4747' }}>Capslock is on</small>}
              </Grid>
              <Grid item md={12} xs={12} container alignItems="center" className={classes.mainActions}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Button
                    id="login-btn"
                    className={classes.loginButton}
                    type="submit"
                    variant="contained"
                    size="large"
                    color="secondary"
                    pending={pending}
                  >
                    {trans('login')}
                  </Button>
                  <Link onClick={onOpenForgotPassword}>
                    <Typography variant="body2" weight="400">
                      {trans('forgot-your-password')}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center" className={classes.helpRow}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TermsAndConditions
                textProps={{
                  color: 'label',
                  weight: 500,
                  variant: 'body2',
                }}
              />
              <Box>
                <Link onClick={onOpenNeedHelpDialog}>
                  <Typography color="label" variant="body2" component="span" weight="700">
                    {trans('need-help')}
                  </Typography>
                  <Typography color="label" component="span" style={{ marginLeft: 5 }}>
                    <IconHelp />
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default SignInForm;
