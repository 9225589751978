import Grid from '@material-ui/core/Grid';
import React from 'react';
import Loader from '../../../components/common/Loader';
import ProgressesLine from '../../../components/common/ProgressBars/ProgressesLine';
import NothingFoundCardList from '../../../components/generics/Cards/NothingFoundCard/NothingFoundCardList';
import QuickViewLayout from '../../../components/layouts/QuickViewLayout';
import { QUERY_PIPELINE } from '../../../constants/queries';
import useQueryCallbacks from '../../../hooks/query/useQueryCallbacks';
import connect from './connect';
import PipelineInfo from './Info';
import PipelineMenu from './Menu';
import Stages from './Stages';
import styles from './styles.module.css';

import { getOpportunityRoute } from '../../../utils/opportunitiesRoutes';
import { useSelector, useDispatch } from 'react-redux';
import { actions as pipelinesDetailsActions } from '../../../store/pipelinesDetails';
import { useHistory } from 'react-router-dom';
import { useTrans } from '../../../services/i18n';
import IncludeStatisticsToggle from './components/IncludeStatisticsToggle';
import ExportButton from './components/ExportButton';
import ShowStatsButton from './components/ShowStatsButton';
import HeaderDivider from './components/HeaderDivider';
import { useMediaQueryDown } from '../../../hooks/useMediaQuery';

function PipelineDetails({
  pipeline,
  pursuits,
  onInit,
  onChangeFilters,
  pipelineId,
  editPipeline,
  deletePipeline,
  getPursuits,
  loading,
  searchTagsByUser,
  breakPoint = 'sm',
}) {
  const { deleteQuery } = useQueryCallbacks();
  const { trans } = useTrans();
  const isSmallScreen = useMediaQueryDown(breakPoint);
  const history = useHistory();

  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    if (!pipelineId) return;
    onInit?.({ id: pipelineId });
    getPursuits?.({ pipelineId });
  }, [onInit, getPursuits, pipelineId]);
  const targetStages = React.useMemo(() => {
    if (!Array.isArray(pipeline?.stagesDetails)) return {};
    return {
      won: pipeline.stagesDetails.find((x) => x.type === 'won')?.id,
      lost: pipeline.stagesDetails.find((x) => x.type === 'lost')?.id,
      abandoned: pipeline.stagesDetails.find((x) => x.type === 'abandoned')?.id,
    };
  }, [pipeline?.stagesDetails]);
  const amount = React.useMemo(() => {
    const count = (type) => {
      return pursuits?.[targetStages[type]]?.length ?? 0;
    };
    return {
      won: count('won'),
      lost: count('lost'),
      abandoned: count('abandoned'),
    };
  }, [pursuits, targetStages]);

  const originalOpportunityObject = useSelector((state) => state.pipelineDetails.originalViewOpportunity);
  React.useEffect(() => {
    if (originalOpportunityObject.id) {
      let pathOpportunity;
      if (originalOpportunityObject.type === 'award') {
        pathOpportunity = getOpportunityRoute(originalOpportunityObject.opportunityId, originalOpportunityObject.type);
      } else {
        pathOpportunity = getOpportunityRoute(originalOpportunityObject.opportunity.id, originalOpportunityObject.type);
      }

      dispatch(pipelinesDetailsActions.clearOriginalViewOpportunity());
      history.push(pathOpportunity);
    }
  }, [originalOpportunityObject]);

  return (
    <QuickViewLayout
      title={trans('pipeline-details')}
      onBackClick={deleteQuery(QUERY_PIPELINE)}
      responsiveSecondButton={
        <div className={styles.containerButtonMenu}>
          <HeaderDivider>
            <ShowStatsButton item={pipeline} />
          </HeaderDivider>
          <HeaderDivider>
            <PipelineMenu
              item={pipeline}
              pending={false}
              onDelete={deletePipeline}
              onSave={undefined}
              editPipeline={editPipeline}
              pipelineId={pipelineId}
              getTagsByUser={searchTagsByUser}
            />
          </HeaderDivider>
        </div>
      }
      search={
        pipeline && (
          <div className={styles.containerButtonMenu}>
            <HeaderDivider>
              <IncludeStatisticsToggle item={pipeline} />
            </HeaderDivider>
            <HeaderDivider>
              <ExportButton item={pipeline} />
            </HeaderDivider>
            <HeaderDivider>
              <ShowStatsButton item={pipeline} />
            </HeaderDivider>
            <HeaderDivider>
              <PipelineMenu
                item={pipeline}
                pending={false}
                onDelete={deletePipeline}
                onSave={undefined}
                editPipeline={editPipeline}
                pipelineId={pipelineId}
                getTagsByUser={searchTagsByUser}
              />
            </HeaderDivider>
          </div>
        )
        /*<SearchTopPanel
          filters={filters}
          filterOptions={filterOptions}
          onChangeFilters={onChangeFilters}
          quickView
          action={
            
          }
        />*/
      }
    >
      <Loader open={loading} />

      {pipeline && !loading ? (
        <Grid container direction="column" wrap="nowrap" className="forwardHeight">
          {isSmallScreen && (
            <Grid item>
              <Grid container direction="row" wrap="nowrap" className={styles.subHeader}>
                <Grid item xs={8}>
                  <HeaderDivider>
                    <IncludeStatisticsToggle item={pipeline} />
                  </HeaderDivider>
                </Grid>
                <Grid item xs={4}>
                  <ExportButton item={pipeline} />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <PipelineInfo item={pipeline} />
          </Grid>
          <Grid item>
            <ProgressesLine values={[amount.won, amount.lost, amount.abandoned]} />
          </Grid>
          <Grid item xs className={isSmallScreen ? 'forwardScrollLandscape' : 'forwardScroll'}>
            {Array.isArray(pipeline?.stagesDetails) && <Stages stages={pipeline?.stagesDetails} />}
          </Grid>
        </Grid>
      ) : !pipeline && !loading ? (
        <NothingFoundCardList />
      ) : null}
    </QuickViewLayout>
  );
}

export default connect(PipelineDetails);
