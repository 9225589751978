import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const selectexceptions = (state) => state.exceptions;

const useExceptionsSelector = () => {
  const exceptions = useSelector(selectexceptions);
  return useMemo(
    () => ({
      exceptions,
    }),
    [exceptions]
  );
};

export default useExceptionsSelector;
