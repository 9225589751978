import palette from '../pallete';

const MuiTable = {
  MuiTable: {
    root: { backgroundColor: palette.common.white },
  },
  MuiTableCell: {
    root: {
      borderBottom: `1px solid ${palette.gray.divider}`,
      padding: '23px 1vh',
    },
    head: {
      padding: '13px 16px',
      color: palette.gray.light,
      fontWeight: 'normal',
    },
  },
  MuiTableRow: {
    root: {
      borderColor: palette.gray.divider,
      '&:last-child td': { borderBottom: 'none' },
    },
  },
  MuiTableSortLabel: {
    root: {
      '&:hover': { color: palette.gray.main },
      '&:focus': { color: undefined },
      '&$active': { color: palette.gray.main },
      '& > svg': { color: palette.gray.active },
    },
  },
};

export default MuiTable;
