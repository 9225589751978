import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const selectEmailExpired = (state) => state.globalSettings.emailExpired;

const useGlobalSettingsSelector = () => {
  const emailExpired = useSelector(selectEmailExpired);
  return useMemo(
    () => ({
      emailExpired,
    }),
    [emailExpired]
  );
};

export default useGlobalSettingsSelector;
