import { Grid, Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { CenterGrid } from '../styled';
import Typography from '../../../components/common/Typography';
import { useTrans } from '../../../services/i18n';
import ConfirmDialog from '../../../components/generics/Confirm/Dialog';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import connect from '../connect';
import useChangePlanActions from '../hooks/useChangePlanActions';
import { useIsMobile } from '../../../hooks/useIsMobile';
import AppMaxPlans, { getPlanProperty } from './AppMaxPlans';
import logoMobile from '../../../components/assests/logo-mobile.png';
import Benefits from './Benefits';

function Plans({
  products,
  setSelectedPlan,
  selectedPlan,
  subscription,
  updateSubscription,
  getOrganizationInfo,
  getSubscriptionStatus,
  defaultPaymentMethod,
  getCustomerBilling,
  auth,
  org,
}) {
  const isMobile = useIsMobile();
  const { trans } = useTrans();
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useBooleanHandlers(false);
  const isAuthorized = !!auth?.userName;
  const { onSubscribeClick, onUpdateSubscription, updatePending } = useChangePlanActions({
    products,
    setSelectedPlan,
    subscription,
    onOpenConfirm,
    onCloseConfirm,
    updateSubscription,
    getOrganizationInfo,
    getSubscriptionStatus,
    isAuthorized,
    hasPaymentMethod: !!defaultPaymentMethod,
  });

  const getSelectedPlanDisplayName = () => {
    if (!selectedPlan || !selectedPlan.recurring) return '';
    return `APP MAX ${trans(getPlanProperty(selectedPlan?.recurring).name)} ${trans('subscription')}`;
  };

  useEffect(() => {
    getCustomerBilling();
  }, [getCustomerBilling]);

  return (
    <Grid container spacing={2}>
      <CenterGrid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {!isAuthorized && <img src={logoMobile} alt="mobile-logo" style={{ width: '5rem', aspectRatio: '1:1' }} />}

          <Typography
            fontFamily="lexend"
            variant={isMobile ? 'h4' : 'h1'}
            weight={800}
            color="primary"
            component="span"
            style={{ fontSize: '3rem', color: '#206bae' }}
          >
            APP
          </Typography>
          <Typography
            fontFamily="lexend"
            variant={isMobile ? 'h4' : 'h1'}
            weight={800}
            color="secondary"
            component="span"
            style={{ marginLeft: '0.5rem', fontSize: '3rem' }}
          >
            MAX
          </Typography>
        </Box>
      </CenterGrid>

      <CenterGrid item xs={12} style={{ paddingTop: 0 }}>
        <Typography variant={isMobile ? 'h4' : 'h1'} weight={800} style={{ textAlign: 'center' }}>
          {trans('launch-your-path-to-government-contracting-success')}
        </Typography>
      </CenterGrid>
      <CenterGrid item xs={12} style={{ marginBottom: '1rem' }}>
        <Benefits isMobile={isMobile} />
      </CenterGrid>

      <CenterGrid item xs={12}>
        <AppMaxPlans onSubscribeClick={onSubscribeClick} onOpenConfirm={onOpenConfirm} isAuthorized={isAuthorized} />
      </CenterGrid>

      <CenterGrid item xs={12} style={{ padding: isMobile ? '0 2rem' : '1rem 0 0 0' }}>
        <Typography
          variant={isMobile ? 'body2' : 'body1'}
          weight={400}
          style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
          color="label"
        >
          {trans('subscription-prices-disclaimer')}
        </Typography>
      </CenterGrid>

      <ConfirmDialog
        open={openConfirm}
        onClose={onCloseConfirm}
        onConfirm={onUpdateSubscription}
        pending={updatePending}
        title={trans('update-subscription')}
        text={trans('confirm-subscription-change')}
        buttonColor="primary"
        noIcon
      >
        {selectedPlan && <Typography color="hint">{getSelectedPlanDisplayName()}</Typography>}
      </ConfirmDialog>
    </Grid>
  );
}

export default connect(Plans);
