import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';
import { onItemDetails, onItemDetailsReject, onPendingDone } from '../helpers/sharedCases';

const INITIAL_STATE = {
  item: {},
  pending: false,
  sort: {
    pursuits: {},
  },
  show: false,
  pursuitsByStage: {},
  originalViewOpportunity: {},
  statistics: {},
};

const fetchOpportunitiesByPursuitId = createAsyncThunk('pipelineDetails/fetchOpportunitiesByPursuitId', (params) => {
  return application.call(API.PURSUITS.GET_OPPORTUNITIES_BY_PURSUIT_ID, params);
});

const getPipeline = createAsyncThunk('pipelineDetails/getPipeline', (params) => {
  return application.call(API.PIPELINES.GET_PIPELINE, params).then(cleanPipelineDetails);
});

const editPipeline = createAsyncThunk('pipelineDetails/editPipeline', (params) => {
  return application.call(API.PIPELINES.EDIT_PIPELINE, params).then(cleanPipelineDetails);
});

const deletePipeline = createAsyncThunk('pipelineDetails/deletePipeline', (params) => {
  return application.call(API.PIPELINES.DELETE_PIPELINE, params);
});

const deleteStage = createAsyncThunk('pipelineDetails/deletePipelineStage', (params, { getState }) => {
  const item = getState().pipelineDetails.item;
  const pipelineId = item?.id;
  return application.call(API.PIPELINES.DELETE_PIPELINE_STAGE, { pipelineId, ...params }).then(cleanStagesDetails);
});

const editStage = createAsyncThunk('pipelineDetails/editPipelineStages', (params, { getState }) => {
  const item = getState().pipelineDetails.item;
  const pipelineId = item?.id;
  return application.call(API.PIPELINES.EDIT_PIPELINE_STAGE, { pipelineId, ...params }).then(cleanStagesDetails);
});

const deletePursuit = createAsyncThunk('pipelineDetails/deletePursuit', (params) => {
  return application.call(API.PURSUITS.DELETE_PURSUIT, params);
});

const changePursuitStatus = createAsyncThunk('pipelineDetails/changePursuitStatus', (params) => {
  return application.call(API.PURSUITS.CHANGE_PURSUIT_STATUS, params);
});

const getPursuits = createAsyncThunk('pipelineDetails/getPipelinePursuits', (params) => {
  if (!params) return Promise.reject(null);
  return application.call(API.PIPELINES.GET_PURSUITS, params);
});

const addPipelineStage = createAsyncThunk('pipelineDetails/addPipelineStage', (params, { getState }) => {
  const item = getState().pipelineDetails.item;
  const stages = []
    .concat(item?.stagesDetails)
    .sort((x, y) => x.order - y.order)
    .map((x) => x.id);
  stages.splice(params.index, 0, null);
  const pipelineId = item?.id;
  return application.call(API.PIPELINES.ADD_PIPELINE_STAGE, { pipelineId, ids: stages }).then(cleanStagesDetails);
});

const updatePursuitsOrder = createAsyncThunk(
  'pipelineDetails/updatePursuitsOrder',
  (params, { getState, dispatch }) => {
    const pursuitsByStage = getState().pipelineDetails.pursuitsByStage;
    const pursuitsToUpdate = Object.values(pursuitsByStage)
      .map((values) => values.map((pursuit) => ({ id: pursuit.id, stageId: pursuit.stageId })))
      .flat();
    const req = application.call(API.PURSUITS.UPDATE_PURSUITS_ORDER, { items: pursuitsToUpdate }).then((x) => {
      if (!x?.success) {
        throw new Error('Invalid update');
      }
    });
    req.catch((e) => {
      console.error(e);
      const pipelineId = getState().pipelineDetails.item?.id;
      // Update pursuits shown in case of failure
      dispatch(actions.getPursuits({ pipelineId }));
    });
    return req;
  }
);

const updateStagesOrder = createAsyncThunk('pipelineDetails/updateStagesOrder', ({ ids }, { getState }) => {
  const pipelineId = getState().pipelineDetails.item?.id;
  return application.call(API.PIPELINES.ADD_PIPELINE_STAGE, { ids, pipelineId }).then(cleanStagesDetails);
});

const editPursuit = createAsyncThunk('pipelineDetails/editPursuit', (params, { getState }) => {
  return application.call(API.PURSUITS.EDIT_PURSUIT, params);
});

const clearOriginalViewOpportunity = createAsyncThunk('pipelineDetails/cleanOriginalViewOpportunity', () => {
  return {};
});

const getStatistics = createAsyncThunk('pipelineDetails/getStatistics', (params) => {
  return application.call(API.PIPELINES.GET_STATISTICS, params);
});
const onGetStatistics = (state, { payload }) => {
  if (!payload.success) return;
  state.statistics = payload.statistics;
};

const exportPursuits = createAsyncThunk('pipelines/exportPursuits', (params) => {
  return application.call(API.PIPELINES.EXPORT_PURSUITS, params);
});

const cleanPipelineDetails = (data) => {
  return {
    ...data,
    stagesDetails: cleanStagesDetails(data.stagesDetails),
    tags: data.tags?.map((x) => ({ create: x, value: x })) ?? [],
  };
};

const cleanStagesDetails = (data) => {
  const stages = Array.isArray(data) ? data : [];
  return stages.sort((a, b) => a.order - b.order);
};

const cleanPursuits = (data) => {
  const pursuits = Array.isArray(data?.items) ? data.items : [];
  return { ...data, items: pursuits.sort((a, b) => a.order - b.order) };
};

function getPursuitsByStage(pursuits) {
  return pursuits.reduce((acc, x) => {
    if (!(x.stageId in acc)) {
      acc[x.stageId] = [x];
    } else {
      acc[x.stageId].push(x);
    }
    return acc;
  }, {});
}

function onFetchPursuits(state, action) {
  state.pursuitsByStage = getPursuitsByStage(cleanPursuits(action.payload).items);
}

function onStartPending(state) {
  return Object.assign(state, { pending: true });
}

function onStageDetailChange(state, { payload }) {
  if (state.item) state.item.stagesDetails = payload;
}

function getOriginalOpportunity(state, { payload }) {
  if (payload.items) {
    state.originalViewOpportunity = payload.items[0];
  }
  Object.assign(state, { pending: false });
}

function getOriginalOpportunityPending(state) {
  return Object.assign(state, { pending: true });
}

const pipelineDetailsSlice = createSlice({
  name: 'pipelineDetails',
  initialState: INITIAL_STATE,
  reducers: {
    show(state, { payload }) {
      state.show = Boolean(payload);
    },
    sortPursuitsBy(state, { payload }) {
      const pursuitsSort = state;
      pursuitsSort[payload.stageId] = {
        field: payload.sortBy,
        direction: -1 * (pursuitsSort[payload.stageId]?.direction ?? -1),
      };
      updatePursuitsOrder();
    },

    onDropPursuit(state, { payload: { source, destination } }) {
      if (!destination) return;
      if (source.droppableId === destination.droppableId) {
        const stageId = source.droppableId;
        const pursuits = state.pursuitsByStage[stageId];
        const [item] = pursuits.splice(source.index, 1);
        pursuits.splice(destination.index, 0, item);
      } else {
        const pursuitsFrom = state.pursuitsByStage[source.droppableId];
        if (!Array.isArray(state.pursuitsByStage[destination.droppableId]))
          state.pursuitsByStage[destination.droppableId] = [];
        const pursuitsTo = state.pursuitsByStage[destination.droppableId];
        const [item] = pursuitsFrom.splice(source.index, 1);
        pursuitsTo.splice(destination.index, 0, { ...item, stageId: destination.droppableId });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPursuit.fulfilled, (state, { payload }) => {
        const index = state.pursuitsByStage[payload?.stageId]?.findIndex((x) => x.id === payload.id) ?? -1;
        if (index > -1) {
          state.pursuitsByStage[payload.stageId][index] = payload;
        }
      })
      .addCase(changePursuitStatus.fulfilled, onFetchPursuits)
      .addCase(deletePursuit.fulfilled, (state, { payload: { success, id, stageId } }) => {
        if (success) {
          const index = state.pursuitsByStage[stageId].findIndex((x) => x.id === id);
          if (index > -1) {
            state.pursuitsByStage[stageId].splice(index, 1);
          }
        }
      })
      .addCase(updateStagesOrder.fulfilled, onStageDetailChange)
      .addCase(editStage.fulfilled, onStageDetailChange)
      .addCase(addPipelineStage.fulfilled, onStageDetailChange)
      .addCase(deleteStage.fulfilled, onStageDetailChange)
      .addCase(getPursuits.fulfilled, onFetchPursuits)
      .addCase(getPipeline.fulfilled, onItemDetails)
      .addCase(getPipeline.pending, onStartPending)
      .addCase(getPipeline.rejected, onItemDetailsReject)
      .addCase(editPipeline.fulfilled, (state, { payload }) => {
        state.item = payload;
      })
      .addCase(fetchOpportunitiesByPursuitId.fulfilled, getOriginalOpportunity)
      .addCase(fetchOpportunitiesByPursuitId.pending, getOriginalOpportunityPending)
      .addCase(clearOriginalViewOpportunity.fulfilled, (state) => {
        state.originalViewOpportunity = {};
      })
      .addCase(getStatistics.fulfilled, onGetStatistics);
  },
});

export const actions = {
  editPipeline,
  getPipeline,
  deletePipeline,
  deleteStage,
  editStage,
  getPursuits,
  addPipelineStage,
  deletePursuit,
  changePursuitStatus,
  updateStagesOrder,
  updatePursuitsOrder,
  editPursuit,
  fetchOpportunitiesByPursuitId,
  clearOriginalViewOpportunity,
  exportPursuits,
  getStatistics,
  ...pipelineDetailsSlice.actions,
};

export default pipelineDetailsSlice.reducer;
