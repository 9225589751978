import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  buttonCounter: {
    background: theme.palette.primary.main,
    color: 'white',
    padding: 0,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  marginTop: {
    marginTop: '15px',
  },
  accent: {
    color: theme.palette.primary.main,
    fontWeight: 800,
  },
}));

export default useStyles;
