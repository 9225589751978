import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_MY_PURSUITS, ROUTE_PURSUIT_DETAILS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const MyPursuits = React.lazy(() => import('./MyPursuits'));
const PursuitDetails = React.lazy(() => import('../Pipelines/PursuitDetails'));

export default function PursuitsWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_MY_PURSUITS.path}>
          <MyPursuits />
        </Route>
        <Route exact path={ROUTE_PURSUIT_DETAILS.path}>
          <PursuitDetails />
        </Route>
        <Redirect exact path="/*" to={ROUTE_MY_PURSUITS.path} />
      </Switch>
    </Suspense>
  );
}
