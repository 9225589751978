import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconKey = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g id="vuesax/linear/key">
        <g id="vuesax/linear/key_2">
          <g id="key">
            <path
              id="Vector"
              d="M19.7899 14.93C17.7299 16.98 14.7799 17.61 12.1899 16.8L7.4799 21.5C7.1399 21.85 6.4699 22.06 5.9899 21.99L3.8099 21.69C3.0899 21.59 2.4199 20.91 2.3099 20.19L2.0099 18.01C1.9399 17.53 2.1699 16.86 2.4999 16.52L7.1999 11.82C6.3999 9.22001 7.0199 6.27001 9.0799 4.22001C12.0299 1.27001 16.8199 1.27001 19.7799 4.22001C22.7399 7.17001 22.7399 11.98 19.7899 14.93Z"
              stroke="black"
              strokeWidth={1.5}
              strokeMiterlimit={10}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M6.88989 17.49L9.18989 19.79"
              stroke="black"
              strokeWidth={1.5}
              strokeMiterlimit={10}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
              stroke="black"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconKey.propTypes = simpleIconPropsTypes;
