import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { Equalizer } from '@material-ui/icons';
import React from 'react';
import { ModalDialog } from '../../../../components/common/Dialogs';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { useTrans } from '../../../../services/i18n';
import { SpacingContainer } from '../../../../components/common/Containers';
import Typography from '../../../../components/common/Typography';
import { formatPrettyUSD } from '../../../../utils/numberUtils';
import NaicsTable from './NaicsTable';
import AgenciesTable from './AgenciesTable';
import connect from '../connect';
import Loader from '../../../../components/common/Loader';
import { LoaderSmall } from '../../../../components/common/LoaderSmall';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    maxHeight: '50vh',
    padding: '0 15px',
  },
}));

function ShowStatsButton({ item, statistics, getStatistics }) {
  const { trans } = useTrans();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [open, onOpen, onClose] = useBooleanHandlers(false);

  const onClick = React.useCallback(async () => {
    onOpen();
    setLoading(true);
    await getStatistics({ id: item.id });
    setLoading(false);
  }, [getStatistics, item.id, onOpen]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <IconButton style={{ transform: 'rotateY(180deg)' }} onClick={onClick}>
          <Equalizer />
        </IconButton>
      </Box>

      <ModalDialog
        open={open}
        onClose={onClose}
        title={trans('statistics')}
        className={classes.modal}
        disableContentSpacing
      >
        <Box className={classes.table}>
          {loading ? (
            <LoaderSmall open />
          ) : (
            <Table size="medium">
              <NaicsTable statistics={statistics} />
              <AgenciesTable statistics={statistics} />
            </Table>
          )}
        </Box>
      </ModalDialog>
    </>
  );
}

export default connect(ShowStatsButton);
