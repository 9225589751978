import React from 'react';
import Typography from '../../../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from '../../../styles';
import { useTrans } from '../../../../../services/i18n';
import CodeInput from '../../../../../components/common/Inputs/CodeInput';
import { IconBack, IconHelp } from '../../../../../components/svg';
import IconButton from '@material-ui/core/IconButton';
import { AUTH_SMS_MFA } from '../../../../../constants';

const Authentication = ({ user, challenge, onSubmit, onCancel }) => {
  const classes = useStyles();
  const { trans } = useTrans();

  const onChangeHandle = React.useCallback(
    (value) => {
      if (value.length === 6) onSubmit && onSubmit(value);
    },
    [onSubmit]
  );

  return (
    <div className={classes.formBack}>
      <Grid container spacing={0} justifyContent="center" className={classes.content}>
        <Grid item xs={12} className={classes.topHeader}>
          <IconButton edge="start" color="inherit" size="small" onClick={onCancel}>
            <IconBack />
          </IconButton>
        </Grid>
        <Grid item xs={12} className={classes.signForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h1Medium" fontFamily="lexend" weight="500">
                {trans('login-to-USFCR')}
              </Typography>
              <Typography weight="500" className={classes.codeHelperText}>
                {trans('an-authentication-code-is-required')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutter="label" variant="h6" fontFamily="lexend" weight="600" color="label">
                {trans('verification-code')}
              </Typography>
              {challenge === AUTH_SMS_MFA && (
                <Typography weight="500" color="label" variant="h6">
                  A code has been sent to the phone number registered in the application ending in...
                </Typography>
              )}
              <Typography variant="h6">{user?.challengeParam?.CODE_DELIVERY_DESTINATION}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.codeRow}>
              <CodeInput type="number" onChange={onChangeHandle} />
            </Grid>
            <Grid item xs={12} className={classes.helpRow}>
              <IconHelp />
              <Typography color="hint" component="p" style={{ marginLeft: 10 }}>
                {trans('enter-the-code-from-your-authentication-app')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.flexHelper} />
      </Grid>
    </div>
  );
};

export default Authentication;
