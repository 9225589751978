export function emptyArray(array) {
  return !(Array.isArray(array) && array.length > 0);
}

export const uniqBy = (myArr, prop = 'id') => {
  if (!Array.isArray(myArr)) return myArr;

  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export function arrayChunk(arr, chunkSize) {
  const res = [];
  const numChunkSize = Number(chunkSize) || 0;
  if (!(!emptyArray(arr) && numChunkSize > 0)) return res;

  for (let i = 0; i < arr.length; i += chunkSize) {
    res.push(arr.slice(i, i + chunkSize));
  }

  return res;
}

export function alphabeticOrder(array) {
  if (!Array.isArray(array)) return [];

  return array.sort((a, b) => {
    const nameA = a.value.toUpperCase();
    const nameB = b.value.toUpperCase();

    return nameA < nameB ? -1 : 1;
  });
}
