import Grid from '@material-ui/core/Grid';
import { Button } from '../../../../../../components/common/Buttons';
import React from 'react';
import * as Yup from 'yup';
import ModalDialog from '../../../../../../components/common/Dialogs/ModalDialog';
import { NaicsCodeAutocomplete } from '../../../../../../components/autocompletes/NaicsCodeAutocomplete';
import { TextField } from '../../../../../../components/common/Inputs';
import makeValidationSchema from '../../../../../../hooks/makeValidationSchema';
import { useForm } from '../../../../../../hooks/useForm';
import { useTrans } from '../../../../../../services/i18n';
import { schemaToInitialState } from '../../../../../../utils/validationUtils';
import connect from '../../connect';
import { useAlertContext } from '../../../../../../contexts/AlertContext';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useScheme = makeValidationSchema((trans) => {
  return Yup.object().shape({
    naics: Yup.object().required(trans('empty-not-allowed', { field: trans('naic') })),
    note: Yup.string(),
  });
});

const useStyles = makeStyles((theme) => ({
  labelDropdowns: {
    fontFamily: 'Lexend',
    marginBottom: '8px',
    color: '#4e5978',
    fontWeight: 500,
  },
  phoneInput: {
    fontWeight: 'normal',
    background: theme.palette.background.input,
    color: theme.palette.common.black,
    borderRadius: '3px',
    height: '1.4em',
    padding: '1.7rem',
    border: 'none',
    width: '100%',
    '&::placeholder': { color: theme.palette.gray.active, opacity: 1 },
  },
}));
function NaicsModal({ addVendorNaics, updateVendorNaics, naic, onClose, open = false, vendorInfo, naicsPending }) {
  const { trans } = useTrans();
  const schema = useScheme();
  const [loading, setLoading] = React.useState(false);
  const { successAlert, errorAlert } = useAlertContext();

  /***
   *
   */
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const resp = await addVendorNaics({
        ...values,
        naics: JSON.stringify(values.naics),
        vendorManagementId: vendorInfo.id,
      });
      if (resp?.error) {
        errorAlert('Error creating Naic');
      } else {
        resetForm();
        successAlert('Success!');
        typeof onClose === 'function' && onClose();
      }
      setLoading(false);
    } catch (error) {
      errorAlert('Error creating Naic');
      setLoading(false);
      console.error(error);
    }
  };

  /***
   *
   */
  const onEdit = React.useCallback(
    async (values) => {
      try {
        setLoading(true);
        const resp = await updateVendorNaics({
          ...values,
          vendorManagementId: vendorInfo.id,
          naics: JSON.stringify(values.naics),
          id: naic.id,
        });
        if (resp?.error) {
          errorAlert('Error updating Naic');
        } else {
          successAlert('Success!');
          typeof onClose === 'function' && onClose();
        }
        setLoading(false);
      } catch (error) {
        errorAlert('Error updating Member');
        setLoading(false);
        console.error(error);
      }
    },
    [updateVendorNaics, onClose, naic, successAlert]
  );

  /***
   *
   */
  const { handleSubmit, resetForm, getFieldProps, setFieldValue, isValid, setValues, values } = useForm(
    schema,
    naic || schemaToInitialState(schema),
    naic ? onEdit : onSubmit,
    {
      validateOnChange: true,
      validateOnBlur: true,
    }
  );

  /***
   *
   */
  const handleCancel = React.useCallback(() => {
    resetForm();
    typeof onClose === 'function' && onClose();
  }, [onClose, resetForm]);

  /***
   *
   */
  React.useEffect(() => {
    if (naic) {
      const { id, created_at, updated_at, ...data } = naic;
      setValues({
        ...data,
        naics: data.naics,
      });
    }
    return () => {
      resetForm();
    };
  }, [naic, resetForm]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans(naic ? 'edit-org-naics' : 'add-org-naics')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" disabled={naicsPending} onClick={handleCancel} pending={loading}>
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" pending={loading} onClick={handleSubmit} disabled={!isValid}>
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <NaicsCodeAutocomplete
            label={trans('organization-form-primary-naics')}
            placeholder={trans('typeToSearchNaics')}
            multi={false}
            {...getFieldProps('naics')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={trans('Note')}
            placeholder={trans('note-placeholder')}
            multiline
            minRows={4}
            maxRows={10}
            maxLength={2000}
            {...getFieldProps('note')}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
}

export default connect(NaicsModal);
