import LazyLoad from '../../services/lazyLoad';
import { TIERS } from '../../utils/subscription';
import connect from './connect';

const DemoDashboard = LazyLoad({ loader: () => import('./Dashboard') });
const LimitedDashboard = LazyLoad({ loader: () => import('./LimitedDashboard') });

function Dashboard({ subscription }) {
  if (subscription?.data?.currentPlan === TIERS.LIMITED) {
    return <LimitedDashboard />;
  }

  return <DemoDashboard />;
}

export default connect(Dashboard);
