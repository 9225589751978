import React from 'react';
import Loader from '../../components/common/Loader';

const DefaultLoaderComponent = () => <Loader open />;

export default function Suspense({ children, loaderComponent = null }) {
  const LoadingComponent = loaderComponent || DefaultLoaderComponent;

  return <React.Suspense fallback={<LoadingComponent />}>{children}</React.Suspense>;
}
