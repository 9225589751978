import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';
import { onPending, onReject } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  text: '',
  pending: false,
  results: {},
  recentSearches: [],
  filters: {
    order: ['createdAt', 'desc'],
    pagination: { page: 1, perPage: 5 },
  },
};

const changeTextAction = createAction(`EZSearch/changeValue`);

const fetchRecentSearchs = createAsyncThunk(`EZSearch/getSavedSearchs`, async (_, { getState }) => {
  const { filters } = getState().ezSearch;
  return application.call(API.EZ_SEARCH.GET_SAVED_SEARCHS, filters);
});

const fetchListAction = createAsyncThunk(`EZSearch/fetchList`, async (_, { getState }) => {
  const { text } = getState().ezSearch;
  return !text ? {} : application.call(API.EZ_SEARCH.GET_LIST, { text });
});

export const actions = {
  changeTextAction,
  fetchListAction,
  fetchRecentSearchs,
};

const onFetchListFulfilled = (state, action) => {
  Object.assign(state, { results: action.payload, pending: false });
};

const onFetchSavedSearch = (state, action) => {
  Object.assign(state, { recentSearches: action.payload });
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(changeTextAction, (state, action) => {
      state.text = action.payload || '';
    })
    .addCase(fetchRecentSearchs.fulfilled, onFetchSavedSearch)
    .addCase(fetchListAction.fulfilled, onFetchListFulfilled)
    .addCase(fetchListAction.pending, onPending)
    .addCase(fetchListAction.rejected, onReject);
});
