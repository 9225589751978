import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import Typography from '../../../common/Typography';
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';
import useStyles from '../styles';

const AppHeader = ({ onBackClick, search, title, actions, responsiveSecondButton, breakPoint = 'sm' }) => {
  const styles = useStyles();
  const isSmallScreen = useMediaQueryDown(breakPoint);

  return (
    <Grid
      container
      justifyContent="space-between"
      className={isSmallScreen ? styles.appHeaderSingle : styles.appHeader}
    >
      <Grid container item md={4} xs={12} alignItems="center" wrap="nowrap">
        <IconButton
          onClick={onBackClick}
          disableFocusRipple
          disableRipple
          className={isSmallScreen ? styles.headerBtnMobile : styles.headerBtn}
          color="inherit"
        >
          <ChevronLeft />
        </IconButton>
        <Grid item xs={10}>
          <Typography align="center" fontFamily="lexend" variant={isSmallScreen ? 'h4' : 'h3'} weight="500">
            {title}
          </Typography>
        </Grid>

        {responsiveSecondButton && isSmallScreen ? (
          <Box disableFocusRipple disableRipple className={styles.header100Btn} color="inherit">
            {responsiveSecondButton}
          </Box>
        ) : undefined}
      </Grid>
      {!isSmallScreen ? (
        <Grid item md={8} xs={12} className={styles.searchDiv}>
          {search}
        </Grid>
      ) : undefined}
      {actions && (
        <Grid item xs={12} className={styles.actions}>
          {actions}
        </Grid>
      )}
    </Grid>
  );
};

export default AppHeader;
