import { createReducer, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { application } from '../../services/application';
import { onPending, onReject } from '../helpers/sharedCases';

const INITIAL_STATE = { pending: false };

export const addUserAction = createAsyncThunk('orgUsers/addOrgUser', async (params) => {
  return application.call('organization.inviteUser', params);
});

function onAddUser(state, action) {
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state];
  copy.push(action.payload);
  Object.assign(state, [...copy]);
}

export const deleteUserAction = createAsyncThunk('orgUsers/deleteOrgUser', async (params) => {
  return await application.call('organization.deleteOrgUser', params);
});

function onDeleteUser(state, action) {
  const index = state.findIndex((i) => i.id === action.payload);
  const cloneState = [...state];
  cloneState.splice(index, 1);
  return cloneState;
}

export const editUserAction = createAsyncThunk('orgUsers/editOrgUser', async (params) => {
  return application.call('organization.editOrgUser', params);
});

function onEditUser(state, action) {
  const index = state.findIndex((i) => i.id === action.payload.id && i.userId === action.payload.userId);
  const cloneState = [...state];
  cloneState[index] = action.payload;
  return cloneState;
}

export const reSendInviteAction = createAsyncThunk('orgUsers/reSendInvite', async (params) => {
  return application.call('organization.reSendInvite', params);
});

export const getUsersAction = createAction('orgUsers/getOrganizationInfo');

function onGetUsers(state, action) {
  return [...action.payload.orgInfo.users];
}

export const getVendor = createAsyncThunk('orgUsers/getVendor', async (params) => {
  return application.call('vendors.findOne', params);
});

function onVendor(state, action) {
  return action.payload;
}

function onChangeActiveOrganization(state, action) {
  return Object.assign(state, {
    pending: false,
  });
}
export const changeActiveOrganization = createAsyncThunk('orgUsers/changeActiveOrganization', async (params) => {
  return application.call('user.changeActiveOrganization', params);
});

export const enableDisableUser = createAsyncThunk('orgUsers/enableDisableUser', async (params) => {
  return application.call('organization.enableDisableUser', params);
});

export const usersActions = {
  addUserAction,
  deleteUserAction,
  editUserAction,
  getUsersAction,
  reSendInviteAction,
  changeActiveOrganization,
  enableDisableUser,
};

export const orgUsersReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getVendor.fulfilled, onVendor)
    .addCase(addUserAction.fulfilled, onAddUser)
    .addCase(deleteUserAction.fulfilled, onDeleteUser)
    .addCase(editUserAction.fulfilled, onEditUser)
    //.addCase(changeActiveOrganization.fulfilled, onGetUsers)
    .addCase(enableDisableUser.fulfilled, onEditUser)
    .addCase(getUsersAction, onGetUsers)
    .addCase(changeActiveOrganization.fulfilled, onChangeActiveOrganization)
    .addCase(changeActiveOrganization.pending, onPending)
    .addCase(changeActiveOrganization.rejected, onReject);
});
