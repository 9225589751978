import React, { useEffect, useState } from 'react';
import { useTrans } from '../../../../services/i18n';
import { TextField } from '../../../../components/common/Inputs';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { useForm } from '../../../../hooks/useForm';
import { useApplication } from '../../../../services/application';
import { schemaToInitialState } from '../../../../utils/validationUtils';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import makeStyles from '@material-ui/core/styles/makeStyles';
import 'react-phone-number-input/style.css';
import { Divider } from '../../../../components/common/Divider';
import { Button } from '../../../../components/common/Buttons';
import { ReactComponent as ArrowBackIcon } from '../../icons/arrow-left.svg';
import Typography from '../../../../components/common/Typography';
import Loader from '../../../../components/common/Loader';

const useScheme = makeValidationSchema((trans) => Yup.object().shape({}));

const useStyles = makeStyles((theme) => ({
  labelDropdowns: {
    fontFamily: 'Lexend',
    marginBottom: '8px',
    color: '#4e5978',
    fontWeight: 500,
  },
  containerCard: {
    display: 'flex',
    padding: '1.5rem 2.5rem',
    gap: '1.5rem',
  },
  centerItems: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginX: {
    marginLeft: '2.5rem',
    marginRight: '2.5rem',
  },
  itemBlock: {
    padding: '0 !important',
  },
  containerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.3rem 2.3rem 1.87rem 2.3rem',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  backButton: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontFamily: 'Manrope',
    fontWeight: '500',
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cancelNextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textFieldFont: {
    '& .MuiFilledInput-input-1786': {
      fontSize: '0.875rem',
    },
  },
}));

export default function TeamingQuestions({
  nextStep,
  previousStep,
  onCompletionChange,
  onCancel,
  data,
  setData,
  isEditMode,
}) {
  const { trans } = useTrans();
  const application = useApplication();
  const schema = useScheme();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = React.useState([]);
  const styles = useStyles();

  // Form set up
  const { setValues, getFieldProps, values } = useForm(schema, schemaToInitialState(schema), {
    validateOnChange: false,
    validateOnBlur: false,
  });

  /***
   * Get questions from db
   */
  useEffect(() => {
    setLoading(true);
    application.call('organization.getOrgTeamingQuestions').then((resp) => {
      setQuestions(resp.questions);
      setLoading(false);
    });
  }, []);

  /***
   * Set values in formik data
   */
  useEffect(() => {
    setValues({
      answers: questions.map((q) => ({
        questionId: q.id,
        answer: data?.teamingAnswers?.find((answer) => answer?.questionId === q?.id)?.answer,
      })),
    });
  }, [questions]);

  /***
   * Set values in state
   */
  useEffect(() => {
    if (questions?.length > 0) {
      //Save on state
      storeInState();
    }
  }, [setValues, values]);

  /***
   * Go to the next page
   */
  const handleNextClick = () => {
    onCompletionChange(true);
    nextStep();
  };

  /***
   * Go back to the previous page
   */
  const handlePreviousClick = () => {
    previousStep();
  };

  /***
   * In charge of store data on state
   */
  const storeInState = () => {
    setData((prev) => ({
      ...prev,
      teamingAnswers: values.answers,
    }));
  };

  return (
    <React.Fragment>
      <Loader open={loading} />
      <Grid container spacing={3} className={`${styles.containerCard} ${styles.centerItems}`}>
        <Typography weight="700" variant="h4">
          {trans('teaming-organization-form-step-2')}
        </Typography>
      </Grid>
      <Divider light spacing={1} className={styles.marginX} />
      <Grid container spacing={3} className={styles.containerCard}>
        {questions?.map((question, index) => (
          <Grid key={question.id} item sm={12} xs={12} px={0} className={styles.itemBlock}>
            <TextField
              className={styles.textFieldFont}
              type="text"
              label={question.question}
              placeholder={question.question}
              multiline
              minRows={4}
              maxRows={10}
              maxLength={2000}
              {...getFieldProps(`answers[${index}].answer`)}
            />
          </Grid>
        ))}
      </Grid>
      <Divider light spacing={1} />
      <Grid container className={styles.containerFooter}>
        <div className={styles.backButtonContainer}>
          <ArrowBackIcon />
          <div className={styles.backButton} onClick={handlePreviousClick}>
            {trans('back')}
          </div>
        </div>
        <div className={styles.cancelNextButtonContainer}>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {trans('cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleNextClick} style={{ marginLeft: '10px' }}>
            {trans('next')}
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  );
}
