import { useCallback, useEffect, useState } from 'react';
import { ROUTE_EXPIRATION } from '../../../constants';
import { useAlertContext } from '../../../contexts/AlertContext';
import useExceptionsAction from '../../../hooks/dispatchers/useExceptionsAction';
import useGlobalSettings from '../../../hooks/dispatchers/useGlobalSettings';
import useQueryHistory from '../../../hooks/query/useQueryHistory';

import { useApplication } from '../../../services/application';
import { useTrans } from '../../../services/i18n';
import { EXPIRED_CODE_EXCEPTION, USER_ALRADY_CONFIRMED_CODE } from '../../../utils/exceptions';
import useStyles from '../styles';

export function useResendCode() {
  const [pending, setPending] = useState();
  const { successAlert, errorAlert } = useAlertContext();
  const application = useApplication();

  const resendCode = useCallback(
    async (email) => {
      setPending(true);

      try {
        await application.call('user.resendConfirmationCode', { email });
        successAlert('Confirmation link was re-sent successfully', 'Please check your email');
        setTimeout(() => setPending(false), 10000);
      } catch {
        errorAlert('Could not connect to the server, try again in a few minutes.');
        setPending(false);
      }
    },
    [application, successAlert, errorAlert]
  );

  return { pending, resendCode };
}

export default function useVerification() {
  const { pending: pendingReCode, resendCode } = useResendCode();
  const { setEmailExpiredD } = useGlobalSettings();
  const { setExceptionsD } = useExceptionsAction();
  const application = useApplication();
  const classes = useStyles();
  const { trans } = useTrans();
  const { history, query } = useQueryHistory();
  const { state = {} } = history.location;
  const [pending, setPending] = useState();

  const verifyAccount = useCallback(() => {
    setPending(true);
    return application
      .call('user.confirmEmail', {
        email: query.email,
        code: query.code,
      })
      .catch((e) => {
        if (e.code === USER_ALRADY_CONFIRMED_CODE) return;
        setExceptionsD({
          ...e,
          code: EXPIRED_CODE_EXCEPTION,
        });
        setEmailExpiredD(query.email);
        history.push(ROUTE_EXPIRATION.path);
      })
      .finally(() => setPending(false));
  }, [application, history, query.code, query.email, setExceptionsD, setEmailExpiredD]);

  const resendVerification = useCallback(
    () => resendCode(query.email || state.email),
    [query.email, state.email, resendCode]
  );
  useEffect(() => {
    if (query.code) {
      verifyAccount();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    classes,
    query,
    trans,
    state,
    pending: pending || pendingReCode,
    resendVerification,
  };
}
