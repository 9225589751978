import React from 'react';
import connect from '../../../connect';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { InlineContainer } from '../../../../../components/common/Containers';
import IconButton from '@material-ui/core/IconButton';
import dayjs from 'dayjs';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '@material-ui/icons/CloudDownloadSharp';
import ShareIcon from '@material-ui/icons/Share';
import { formatNumber } from '../../../../../utils/numberUtils';
import ShareInvoiceModal from '../ShareInvoiceModal';
import { useItem } from './useItem';

const Item = ({ item, downloadInvoice }) => {
  const { shareInvoiceOpen, openShareInvoice, closeShareInvoice, show, download, interval } = useItem({
    item,
    downloadInvoice,
  });

  return (
    <>
      <TableRow>
        <TableCell align="left">{dayjs.unix(item.created).format('DD MMM YYYY')}</TableCell>

        <TableCell align="left" style={{ textTransform: 'capitalize' }}>
          {item.total < 0 ? 'Credit' : item.object}
        </TableCell>

        <TableCell align="left">{item.number}</TableCell>

        <TableCell align="left">
          {item.lines.data.map((i) => {
            return <div>{i.description}</div>;
          })}
        </TableCell>

        <TableCell align="left">
          {formatNumber(item.total / 100)}/{interval}
        </TableCell>

        <TableCell align="right">
          {item.total >= 0 && (
            <InlineContainer spacing={2}>
              <IconButton onClick={() => show(item.id)} color="primary" variant="outlined" size="small">
                <VisibilityIcon />
              </IconButton>

              <IconButton onClick={() => download(item.id)} color="primary" variant="outlined" size="small">
                <DownloadIcon />
              </IconButton>

              <IconButton onClick={() => openShareInvoice()} color="primary" variant="outlined" size="small">
                <ShareIcon />
              </IconButton>
            </InlineContainer>
          )}
        </TableCell>
      </TableRow>

      <ShareInvoiceModal open={shareInvoiceOpen} onClose={() => closeShareInvoice()} invoiceId={item.id} />
    </>
  );
};

export default connect(Item);
