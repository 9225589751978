import * as Yup from 'yup';
import makeValidationSchema from '../../../../../hooks/makeValidationSchema';

export const initialValues = {
  country: {
    id: '',
    name: '',
  },
  state: {
    id: '',
    name: '',
  },
  city: '',
  line1: '',
  postal_code: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    country: Yup.object()
      .shape({
        id: Yup.string().required(trans('required-field')),
        name: Yup.string().required(trans('required-field')),
      })
      .required(trans('required-field')),
    state: Yup.object()
      .shape({
        id: Yup.string().required(trans('required-field')),
        name: Yup.string().required(trans('required-field')),
      })
      .required(trans('required-field')),
    city: Yup.string().required(trans('required-field')),
    line1: Yup.string().required(trans('required-field')),
    postal_code: Yup.object()
      .shape({
        id: Yup.string().required(trans('required-field')),
        value: Yup.string().required(trans('required-field')),
      })
      .required(trans('required-field')),
  })
);
