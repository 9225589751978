import { useMemo } from 'react';
import { useTrans } from '../services/i18n';

/**
 * makeValidationSchema
 * @param {function(trans: function(string): string): Schema | Schema} factory
 * @return {function(): Schema}
 */
export default function makeValidationSchema(factory) {
  factory = typeof factory === 'function' ? factory : () => factory;
  return () => {
    const { trans } = useTrans();
    return useMemo(() => factory(trans), [trans]);
  };
}
