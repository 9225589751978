import IconButton from '@material-ui/core/IconButton';
import IconMore from '@material-ui/icons/MoreHoriz';
import React from 'react';
import { CSVLink } from 'react-csv';
import usePopover from '../../../../../../../../hooks/usePopover';
import { useTrans } from '../../../../../../../../services/i18n';
import {
  BottomLeftPosition,
  ContextMenuListItem,
  Popover,
} from '../../../../../../../../components/common/Dialogs/Popover';
import Typography from '../../../../../../../../components/common/Typography';
import connect from '../../../../../connect';

const ActionsMenu = ({
  openAddVendor,
  openAddVendorManually,
  openImportVendors,
  changeImportingCsv,
  changeProcessCsv,
  getList,
  getVendorTags,
  items,
  tableColumns,
  changeEditVendorManagement,
}) => {
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const { trans } = useTrans();
  const data = [];
  const headers = [];

  const headersFilter = tableColumns.filter((i) => i.active === true);

  headersFilter.forEach((filter) => headers.push({ label: filter.name, key: filter.name }));

  items.forEach((item) =>
    data.push({
      'vendor-name': item.name,
      status: item.status,
      'expiration-date': item.expirationDate,
      'cage-code': item.cageCode,
      'duns-number': item.dunsCode,
      'uei-code': item.ueiCode,
      'business-start-date': item.bussinessStartDate,
      SetAsides: item.setAsides?.map((setAsides) => setAsides.code),
      Naics: item.naics?.map((naic) => naic.code),
      Psc: item.psc?.map((psc) => psc.code),
      Tags: item.tags,
    })
  );

  const importVendorsCsv = () => {
    openImportVendors(true);
    //changeImportingCsv(false);
    changeProcessCsv(false);
  };

  const addNewVendor = () => {
    openAddVendorManually(true);
    changeEditVendorManagement(null);
  };

  const searchNewVendor = () => {
    openAddVendor(true);
    changeEditVendorManagement(null);
  };

  return (
    <>
      <IconButton size="small" onClick={onOpenPopover}>
        <IconMore />
      </IconButton>

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover} {...BottomLeftPosition}>
        <>
          <ContextMenuListItem button onClick={() => searchNewVendor()} onClickCB={onClosePopover} size="normal">
            <Typography variant="body2" color="primary" weight="500" noWrap>
              {trans('search-vendor')}
            </Typography>
          </ContextMenuListItem>
          <ContextMenuListItem button onClick={() => addNewVendor()} onClickCB={onClosePopover} size="normal">
            <Typography variant="body2" color="primary" weight="500" noWrap>
              {trans('add-new-vendor-manually')}
            </Typography>
          </ContextMenuListItem>

          <ContextMenuListItem button onClick={() => importVendorsCsv()} onClickCB={onClosePopover} size="normal">
            <Typography variant="body2" color="primary" weight="500" noWrap>
              {trans('import-vendors-from-csv')}
            </Typography>
          </ContextMenuListItem>
          <ContextMenuListItem button onClick={undefined} onClickCB={onClosePopover} size="normal">
            <CSVLink data={data} headers={headers} filename={'vendors data.csv'} style={{ textDecoration: 'none' }}>
              <Typography variant="body2" color="primary" weight="500" noWrap>
                {trans('export-vendors')}
              </Typography>
            </CSVLink>
          </ContextMenuListItem>
        </>
      </Popover>
    </>
  );
};

export default connect(ActionsMenu);
