import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import styles from './styles.module.css';
import SelectedVideoItem from '../SelectedVideoItem';
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase';
import SectionScrollable from '../../../components/common/Containers/SectionScrollable';
import DetailsAnchorsHeader from '../../../components/generics/DetailsAnchorsHeader';

const VideoDetail = ({ maybeInterested = [1, 2, 3, 4, 5, 6] }) => (
  <SectionScrollable
    TopFixed={
      <DetailsAnchorsHeader
        anchors={[
          { id: 'videoDetail', i18nKey: 'Video Details' },
          { id: 'interestedVideos', i18nKey: 'You may be interested' },
        ]}
        backLink="/dashboard"
        onPrint={undefined}
      />
    }
  >
    <SpacingContainerBase>
      <Paper id="videoDetail" elevation={0} className={styles.containerMainVideo}>
        <h2>John Wayne Live Training & Procurement Agent John Conway (retired)</h2>
        <video
          controls
          width="100%"
          height="425px"
          src="https://temp.media/video/?height=400&width=500&length=10&text="
        ></video>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione eligendi ullam quod sint fugit? Quod autem
          quae adipisci, dicta totam laboriosam porro, vero est, illo aliquid obcaecati repudiandae necessitatibus
          temporibus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, libero laudantium est molestias
          commodi repudiandae atque quis officia ducimus earum dolor in, sapiente repellat laborum nisi vel sint et
          totam! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugiat molestiae ipsam hic. Numquam, nisi
          animi vero tempora vel minima! Fugiat quod dolore nemo. Consequatur ratione, suscipit ipsa fugiat itaque
          asperiores?
        </p>
      </Paper>

      <Paper id="interestedVideos" elevation={0} className={styles.marginTop}>
        <div className={styles.list}>
          <div className={styles.titleWithButton}>
            <h2>You may be interested</h2>{' '}
          </div>

          {maybeInterested.length > 0 ? (
            <>
              {maybeInterested.map((item, index) => (
                <SelectedVideoItem key={index} index={index} hideDescription={true} />
              ))}
            </>
          ) : (
            <p className={styles.noRegisters}>No videos</p>
          )}
        </div>
      </Paper>
    </SpacingContainerBase>
  </SectionScrollable>
);

export default VideoDetail;
