import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { application } from '../../../services/application';
import { filterEmptyValues, getFilterParams } from '../../../utils/filterUtils';
import { isLoadMoreAction } from '../common';
import { onReject, onRejectAlert } from '../sharedCases';

export function generateListActions({ scope, apiMethod, getStore }) {
  if (!(scope && apiMethod && typeof getStore === 'function')) return {};

  return {
    setFilters: createAction(`${scope}/setFilters`),
    setOrder: createAction(`${scope}/setOrder`),
    setPagination: createAction(`${scope}/setPagination`),
    setPending: createAction(`${scope}/setPending`),
    setParams: createAction(`${scope}/setParams`),

    fetchList: createAsyncThunk(`${scope}/fetchList`, async (_, { getState }) => {
      if (!apiMethod.GET_LIST) return;

      const { filters: storeFilters, pagination, order, params } = getStore(getState());
      const { text, fields } = storeFilters;
      const filters = getFilterParams(fields);

      return application.call(apiMethod.GET_LIST, Object.assign({ text, order, filters, pagination }, params));
    }),

    exportToCsv: createAsyncThunk(`${scope}/exportToCsv`, async (_, { getState }) => {
      if (!apiMethod.EXPORT_CSV) return;

      const { filters: storeFilters, order, params } = getStore(getState());
      const { text, fields } = storeFilters;
      const filters = getFilterParams(fields);

      return application.call(apiMethod.EXPORT_CSV, Object.assign({ text, order, filters }, params));
    }),
  };
}

export function extendBuilderWithListActions(builder, actions, getStore, keepItems = true) {
  const { fetchList, setPagination, setFilters, setOrder, setPending, setParams, exportToCsv } = actions;
  const getStoreValues = getStore ? getStore : (state) => state;

  if (fetchList) {
    builder
      .addCase(fetchList.fulfilled, (state, action) => {
        const store = getStoreValues(state);

        if (isLoadMoreAction(action)) {
          const items = [...store.items, ...action.payload.items];
          Object.assign(store, action.payload, { items, pending: false });
        } else {
          Object.assign(store, action.payload, { pending: false });
        }
      })
      .addCase(fetchList.pending, (state, action) => {
        const store = getStoreValues(state);
        const newStore = { pending: true };
        if (!isLoadMoreAction(action) && !keepItems) newStore.items = [];

        Object.assign(store, newStore);
      })
      .addCase(fetchList.rejected, (state, action) => {
        const store = getStoreValues(state);
        onReject(store, action);
      });
  }

  if (setPagination) {
    builder.addCase(setPagination, (state, action) => {
      const store = getStoreValues(state);
      store.pagination = { ...store.pagination, ...action.payload };
    });
  }

  if (setFilters) {
    builder.addCase(setFilters, (state, action) => {
      const store = getStoreValues(state);
      const { text: newText = '', reset = false, ...newFilters } = action.payload;
      const text = typeof newText !== undefined ? newText : store.filters.text;
      const fields = reset ? {} : filterEmptyValues(Object.assign({}, store.filters.fields, newFilters));

      if (text) {
        store.filters = { text, fields };
      } else {
        if (text === '') {
          store.filters = { text, fields };
        } else {
          store.filters = { ...store.filters, fields };
        }
      }
    });
  }

  if (setParams) {
    builder.addCase(setParams, (state, action) => {
      const store = getStoreValues(state);
      store.params = action.payload;
    });
  }

  if (setOrder) {
    builder.addCase(setOrder, (state, action) => {
      const store = getStoreValues(state);
      store.order = action.payload;
    });
  }

  if (setPending) {
    builder.addCase(setPending, (state, action) => {
      const store = getStoreValues(state);
      store.pending = action.payload;
    });
  }

  if (exportToCsv) {
    // builder.addCase(exportToCsv.fulfilled, (state, action) => console.log('fulfilled', action.payload));
    // builder.addCase(exportToCsv.pending, (state, action) => console.log('pending', action.payload));
    builder.addCase(exportToCsv.rejected, onRejectAlert);
  }

  return builder;
}

export function generateListCallbacks(dispatch, actions) {
  const { fetchList, setPagination, setFilters, setOrder, setParams, exportToCsv } = actions || {};
  if (!(fetchList && setPagination && setFilters && setOrder && exportToCsv && dispatch)) return {};

  const onInit = async ({ text, ...params } = {}) =>
    batch(() => {
      if (text) dispatch(setFilters({ text }));
      if (params) dispatch(setParams(params));
      dispatch(fetchList());
    });

  const onChangeFilters = async (filters, fetch = true) => {
    batch(() => {
      dispatch(setFilters(filters));
      dispatch(setPagination({ page: 1 }));
    });
    if (fetch) await dispatch(fetchList());
  };
  const onChangeSearchText = (text) => onChangeFilters({ text });

  const onChangeOrder = async (order) => {
    dispatch(setOrder(order));
    await dispatch(fetchList());
  };

  const onChangePage = async (page, isLoadMore) => {
    dispatch(setPagination({ page }));
    await dispatch(fetchList({ isLoadMore }));
  };

  const onChangeRowPerPage = async (perPage) => {
    dispatch(setPagination({ perPage, page: 1 }));
    await dispatch(fetchList());
  };

  const onExportToCsv = () => dispatch(exportToCsv());

  return {
    onInit,
    onChangeFilters,
    onChangeOrder,
    onChangePage,
    onChangeRowPerPage,
    onExportToCsv,
    onChangeSearchText,
  };
}
