import DayjsUtils from '@date-io/dayjs';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import React from 'react';
import SectionScrollable from '../../common/Containers/SectionScrollable';
import AppHeader from './AppHeader';
import useStyles from './styles';

const QuickViewLayout = ({ onBackClick, children, search, actions, title = '', responsiveSecondButton }) => {
  const styles = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <div className={styles.root}>
        <section className={styles.content}>
          <SectionScrollable
            bgColor="default"
            TopFixed={
              <AppHeader
                onBackClick={onBackClick}
                search={search}
                actions={actions}
                title={title}
                responsiveSecondButton={responsiveSecondButton}
              />
            }
          >
            {children}
          </SectionScrollable>
        </section>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default QuickViewLayout;
