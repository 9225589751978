import Grid from '@material-ui/core/Grid';
import React from 'react';
import { RouterLink } from '../../../../../components/common/Buttons';
import { Paper, SpacingContainer } from '../../../../../components/common/Containers';
import { Divider } from '../../../../../components/common/Divider';
import Typography from '../../../../../components/common/Typography';
import Bull from '../../../../../components/common/Typography/Bull';
import { IconDrag } from '../../../../../components/svg/Icons/IconDrag';
import { ROUTE_PURSUIT_DETAILS } from '../../../../../constants';
import { useTrans } from '../../../../../services/i18n';
import { formatDate, intlDateWithTimeFormat } from '../../../../../utils/dateUtils';
import PursuitItemMenu from './Menu';
import connect from './connect';
import { Draggable } from 'react-beautiful-dnd';

export const PursuitItem = ({
  item,
  settings,
  changePursuitStatus,
  deletePursuit,
  getOriginalOpportunity,
  gridProps = {},
}) => {
  const { trans } = useTrans();

  const detailLink = ROUTE_PURSUIT_DETAILS.path.replace(':id', item.id);

  return (
    <Grid item xs {...gridProps}>
      <Paper radius={4} hidden className="draggable relative">
        {/*{isDragging && <Blur open={isDragging} absolute />}*/}
        <SpacingContainer px={1} py={1}>
          <Grid container alignItems="center" spacing={1} wrap={'nowrap'}>
            <Grid item>
              <IconDrag />
            </Grid>
            <Grid item xs>
              <Typography
                variant="button"
                component={RouterLink}
                to={detailLink}
                weight="600"
                style={{ wordBreak: 'break-word' }}
              >
                {item.pursuitName}
              </Typography>
            </Grid>
            <Grid item>
              <PursuitItemMenu
                item={item}
                onDelete={deletePursuit}
                changePursuitStatus={changePursuitStatus}
                getOriginalOpportunity={getOriginalOpportunity}
                settings={settings}
              />
            </Grid>
          </Grid>
        </SpacingContainer>

        <Divider light />

        <Paper bgColor="paperSecondary" square component={SpacingContainer} px={3} py={2}>
          <Typography variant="caption" component="p" weight="500" gutter="label" lineHeight="1.6">
            <Typography variant="caption" weight="500" color="hint">
              {`${trans('Owner')} `}
            </Typography>
            {item.owner ? `${item.owner.name} ${item.owner.familyName}` : trans('Unknown')}
            <Bull variant="caption" size="small" />
            <Typography variant="caption" weight="500" color="hint">
              {`${trans('updated')} `}
            </Typography>
            {intlDateWithTimeFormat(item.updatedAt)}
            <Bull variant="caption" size="small" />
            <Typography variant="caption" weight="500" color="hint">
              {`${trans('due')} `}
            </Typography>
            {intlDateWithTimeFormat(item.dueDate)}
            <Bull variant="caption" size="small" />
            <Typography variant="caption" weight="500" color="hint">
              {`${trans('AddedToStage')} `}
            </Typography>
            {formatDate(item.createdAt, 'MMM D, YYYY')}
            <Bull variant="caption" size="small" />
            <Typography variant="caption" weight="500" color="hint">
              {`${trans('agency')} `}
            </Typography>
            <Typography variant="caption" weight="500" component={RouterLink} to={'#'}>
              {item.agencyName}
            </Typography>
          </Typography>
        </Paper>
      </Paper>
    </Grid>
  );
};

function getStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return {
      ...style,
      ...(snapshot.isDragging && { transform: [style.transform, `rotate(-5deg)`].filter(Boolean).join(' ') }),
    };
  }
  const { moveTo, curve } = snapshot.dropAnimation;
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
  // patching the existing style
  return {
    ...style,
    transform: `${translate}`,
    // slowing down the drop because we can
    transition: `all ${curve} ${200}ms`,
  };
}

function DraggablePursuitItem({ item, changePursuitStatus, deletePursuit, index, getOriginalOpportunity, settings }) {
  return (
    <Draggable draggableId={item.id} index={index} isDragDisabled={false}>
      {(provided, snapshot) => {
        return (
          <PursuitItem
            item={item}
            changePursuitStatus={changePursuitStatus}
            deletePursuit={deletePursuit}
            getOriginalOpportunity={getOriginalOpportunity}
            gridProps={{
              ref: provided.innerRef,
              ...provided.draggableProps,
              ...provided.dragHandleProps,
              style: getStyle(provided.draggableProps.style, snapshot),
            }}
          />
        );
      }}
    </Draggable>
  );
}

export default connect(React.memo(DraggablePursuitItem));
