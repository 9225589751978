import { connect } from 'react-redux';
import { orgActions } from '../../../../store/orgSettings';
import { changeCurrentOnboardingStep, update } from '../../../../store/auth';
import { changeSkipState } from '../../../../store/globalSettings';

function mapStateToProps(state) {
  return {
    authUser: state.auth || {},
    settings: state.orgSettings,
    onboardingViews: state.globalSettings.onboardingViews,
    currentOnboardingStep: state.auth.currentOnboardingStep,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    get: () => dispatch(orgActions.getOrganizationInfoAction({})),
    changeCurrentOnboardingStep: (params) => dispatch(changeCurrentOnboardingStep(params)),
    updateOnViewTour: (params) => dispatch(update(params)),
    changeSkipState: (params) => dispatch(changeSkipState(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
