import React from 'react';
import Loader from '../../Loader/Loader';
import FeaturesModal from '../FeaturesModal';
import { SUBSCRIPTION_STATUS } from '../../../../utils/constants';
import { useSubscriptionStatus } from '../../../../hooks/useSubscriptionStatus';
import { SubscriptionContext } from './context';
import connect from '../connect';

const SubscribedOnly = React.memo(({ children, handled, fixed, subscription }) => {
  const { pending, data } = useSubscriptionStatus(subscription);
  const [open, setOpen] = React.useState(false);
  const close = React.useCallback(() => {
    if (!handled) return;
    setOpen(false);
  }, [handled]);

  const isOpen = handled ? open : !pending && data?.status !== SUBSCRIPTION_STATUS.SUBSCRIBED;
  return (
    <SubscriptionContext.Provider
      value={React.useMemo(() => {
        return {
          open() {
            if (!handled) return;
            setOpen(true);
          },
          close,
        };
      }, [handled])}
    >
      {children}
      <Loader open={pending} />
      {/* //comentar esto */}
      {/* <FeaturesModal open={isOpen} fixed={fixed} onClose={handled ? close : undefined} /> */}
    </SubscriptionContext.Provider>
  );

  return (
    <React.Fragment>
      <Loader open={pending} />
      {children}
    </React.Fragment>
  );
});

export default connect(SubscribedOnly);
