import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../Typography';
import { IconClose } from '../../../svg/Icons';
import SpacingContainerBase from '../../../layouts/SpacingContainerBase';
import useStyles from './styles';

function Header({ onClose }) {
  const { trans } = useTrans();
  const classes = useStyles();

  return (
    <div className={classes.bottomShadow}>
      <SpacingContainerBase py={1} px={2}>
        <Box className={classes.header}>
          <Typography variant="h4" weight="700">
            {trans('free-trial-ended')}
          </Typography>
          <IconButton onClick={onClose}>
            <IconClose />
          </IconButton>
        </Box>
      </SpacingContainerBase>
    </div>
  );
}

export default Header;
