import { useMemo, useCallback } from 'react';
import useStyles from '../styles';
import useExceptionsSelector from '../../../hooks/selectors/useExceptionsSelector';
import useGlobalSettingsSelector from '../../../hooks/selectors/useGlobalSettingsSelector';
import { useTrans } from '../../../services/i18n';
import { useResendCode } from '../SignUp/useVerification';
import useResendEmailForgetPasswordCode from '../ChangePassword/useResendEmailForgetPasswordCode';
import { EXPIRED_CODE_EXCEPTION, EXPIRED_CODE_EXCEPTION_FORGET_PASSWORD } from '../../../utils/exceptions';
import useGlobalSettings from '../../../hooks/dispatchers/useGlobalSettings';
import useExceptionsActions from '../../../hooks/dispatchers/useExceptionsAction';
import { useEffect } from 'react';

const setPropsComponent = (params) => {
  const { trans, exceptions, functions, emailExpired } = params;
  if (exceptions[EXPIRED_CODE_EXCEPTION]?.code) {
    return {
      title: trans('sorry-email-link-expired'),
      contact: [trans('please-contact'), trans('futher-questions-or-concerns')],
      textButton: trans('resend'),
      onClick: () => functions.resendCode(emailExpired),
    };
  } else if (exceptions[EXPIRED_CODE_EXCEPTION_FORGET_PASSWORD]?.code) {
    return {
      title: trans('sorry-reset-password-has-expired'),
      contact: [trans('please-contact'), trans('futher-questions-or-concerns')],
      textButton: trans('resend-link'),
      onClick: () => functions.resendCodeForgetPassword(emailExpired),
    };
  } else {
    return {
      title: trans('sorry-your-invitation-has-expired'),
      contact: [trans('please-contact'), ''],
    };
  }
};

export const useExpiration = () => {
  const classes = useStyles();
  const { trans } = useTrans();
  const { exceptions } = useExceptionsSelector();
  const { emailExpired } = useGlobalSettingsSelector();
  const { pending, resendCode } = useResendCode();
  const { pending: pendiForgetPassword, resendCodeForgetPassword } = useResendEmailForgetPasswordCode();
  const { cleanEmailExpiredD } = useGlobalSettings();
  const { cleanExceptionsD } = useExceptionsActions();

  useEffect(() => {
    return () => {
      cleanEmailExpiredD();
      cleanExceptionsD();
    };
  }, [cleanEmailExpiredD, cleanExceptionsD]);
  const functions = useMemo(
    () => ({
      resendCode,
      resendCodeForgetPassword,
    }),
    [resendCode, resendCodeForgetPassword]
  );
  const propsComponent = useCallback(
    () => setPropsComponent({ trans, exceptions, functions, emailExpired }),
    [exceptions, functions, trans, emailExpired]
  )();
  return { classes, pending: pending || pendiForgetPassword, propsComponent };
};
