import React from 'react';
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import Typography from '../../../components/common/Typography';
import { useTrans } from '../../../services/i18n';
import connect from '../connect';
import { useEffect } from 'react';
import { LoaderSmall } from '../../../components/common/LoaderSmall';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Paper, SpacingContainer } from '../../../components/common/Containers';
import { Divider } from '../../../components/common/Divider';
import Item from './components/Item';
import Autocomplete from '../../../components/common/Inputs/Autocomplete';
import dayjs from 'dayjs';
import NoInvoices from './components/NoInvoices';
import { emptyArray } from '../../../utils/arrayUtils';

const InvoicesTab = ({ getInvoices }) => {
  const [modality, setModality] = useState(false);
  const { trans } = useTrans();
  const currentYear = dayjs().format('YYYY');

  const changeModality = (ev) => {
    setModality(ev.target.checked);
  };

  const [invoices, setInvoices] = useState([]);
  const [pending, setPending] = useState(false);

  const [years, setYears] = useState([
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
  ]);
  const [months, setMonths] = useState([
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ]);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');

  const changeYear = (ev) => {
    setYear(ev.target.value);
    get({ month: month?.value ?? '', year: ev.target.value ? ev.target.value.value : currentYear });
  };

  const changeMonth = (ev) => {
    setMonth(ev.target.value);
    get({ year: year?.value ?? currentYear, month: ev.target.value ? ev.target.value.value : '' });
  };

  const get = ({ year: y, month: m }) => {
    setPending(true);
    getInvoices({ month: m, year: y ? y : dayjs().format('YYYY') })
      .then((res) => {
        setInvoices(res.payload.invoices ?? []);
        setPending(false);
      })
      .finally(() => {
        setPending(false);
      });
  };

  useEffect(() => {
    get({ month: month, year: year ? year : dayjs().format('YYYY') });
  }, []);

  return (
    <>
      <Card elevation={0}>
        <Grid
          style={{ padding: '40px 30px' }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Typography variant="h4" weight="bold">
              {trans('invoice-and-billing-history')}
            </Typography>
            <Typography variant="body1" gutter="topSpace">
              {trans('search-by-months')}
            </Typography>
          </Grid>

          <Grid item sm={6} xs={6}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={6}>
                <Autocomplete
                  style={{ width: '100%' }}
                  name="years"
                  options={years}
                  label={'Year'}
                  labelKey={'label'}
                  value={year}
                  onChange={changeYear}
                  multiple={false}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  freeSolo
                  placeholder="Select year"
                  size="medium"
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <Autocomplete
                  size="medium"
                  style={{ width: '100%' }}
                  name="months"
                  options={months}
                  label={'Month'}
                  labelKey={'label'}
                  value={month}
                  onChange={changeMonth}
                  multiple={false}
                  handleHomeEndKeys
                  freeSolo
                  placeholder="Select month"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider light />

        {!pending && emptyArray(invoices) && <NoInvoices />}

        {!pending && !emptyArray(invoices) && (
          <TableContainer component={Paper} square radius={8} style={{ padding: '0px 16px' }}>
            <SpacingContainer px="0" py={0}>
              <Table size="medium">
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="body2" weight="800">
                      DATE
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" weight="800">
                      TYPE
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" weight="800">
                      ORDER NUMBER
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" weight="800">
                      PLAN
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" weight="800">
                      QUANTITY
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" weight="800">
                      ACTION
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableBody>
                  {invoices.map((item, index) => (
                    <Item key={index} item={item} />
                  ))}
                </TableBody>
              </Table>
            </SpacingContainer>
          </TableContainer>
        )}

        {pending && <LoaderSmall color="inherit" size="1.35em" />}
      </Card>
    </>
  );
};

export default connect(InvoicesTab);
