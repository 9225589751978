import React from 'react';
import { ModalDialog, DialogTitle } from '../../../../components/common/Dialogs';
import { useTrans } from '../../../../services/i18n';

import InfoAboutNotifications from './InfoAboutNotifications';

export default function InfoAboutNotificationsModal({ open, onClose }) {
  const { trans } = useTrans();
  return (
    <React.Fragment>
      <ModalDialog
        open={open}
        onClose={onClose}
        DialogTitle={<DialogTitle title={trans('email-notification-settings')} onClose={onClose}></DialogTitle>}
      >
        <InfoAboutNotifications />
      </ModalDialog>
    </React.Fragment>
  );
}
