import { useEffect, useCallback, useState } from 'react';
import { application } from '../../../../../services/application';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import { API } from '../../../../../constants';
import { useForm } from '../../../../../hooks/useForm';
import { getInitialValues, useScheme } from './helpers';
import { TextField, Autocomplete, Signature } from '../../../../../components/common/Inputs';
import Grid from '@material-ui/core/Grid';
import { useTrans } from '../../../../../services/i18n';
import Typography from '../../../../../components/common/Typography';
import { Button } from '../../../../../components/common/Buttons';
import { ROUTE_BILLING, ROUTE_SUCCESS_PAYMENT } from '../../../../../constants';
import styles from './styles.module.css';
import { Divider } from '../../../../../components/common/Divider';
import { ZipcodeAutocomplete } from '../../../../../components/autocompletes';
import FieldValue from '../../../../../components/generics/Helpers/FieldValue';
import CurrentPlanSelect from '../CurrentPlanSelect';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import ModalProcessed from './ModalProcessed';
import { useHistory, useLocation } from 'react-router-dom';
import connect from '../../../connect';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { formatNumber } from '../../../../../utils/numberUtils';

const elementInputStyle = {
  width: '100%',
  backgroundColor: '#f1f3f9',
  color: '#000000',
  fontSize: '16px',
  '::placeholder': {
    color: '#b2b8cf',
    fontSize: '14px',
  },
};

const optionsElementsStripe = {
  fonts: [
    {
      src: 'https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap',
      family: 'Lexend',
    },
  ],
};

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  validateOnSubmit: true,
  validateOnBlurAndChange: false,
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Wrapper = (props) => (
  <Elements stripe={stripePromise} options={optionsElementsStripe}>
    <FormCheckout {...props} />
  </Elements>
);

const LabelElementStripe = ({ label, ...props }) => {
  return (
    <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
      {label}
    </Typography>
  );
};

const FormCheckout = (props) => {
  const {
    contactEmail,
    breadcrumb,
    onNextStep,
    addCard,
    createSubscription,
    setCreateSubscription,
    promoCode,
    setPromoCode,
    setSubscriptionInfo,
    selectedPlan,
    setSelectedPlan,
    selectedPlanPrice,
    products,
    getProducts,
    type,
    id,
    quote,
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  const schema = useScheme();
  const { trans } = useTrans();
  const history = useHistory();
  const location = useLocation();

  const { errorAlert, successAlert } = useAlertContext();

  const [isSend, setIsSend] = useState(false);
  const [isSubmittingCard, setSubmittingCard] = useState(false);
  const [fullBillingAddress, setFullBillingAddress] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [cardComplete, setCardComplete] = useState({
    cardName: false,
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [isMonthly, setIsMontly] = useState(false);

  const submitBilling = useCallback(
    (values) => {
      setFullBillingAddress(
        `${values.country.name}, ${values.city.name}, ${values.state.name} - ${values.line1} - ${values.postal_code.id}`
      );
      onNextStep('information');
    },
    [onNextStep]
  );

  const {
    getFieldProps,
    handleSubmit: handleSubmitFormBilling,
    values,
    setFieldValue,
  } = useForm(schema, getInitialValues(), submitBilling, formOptions);

  const handleCardElementOnChange = (e) => setCardComplete({ ...cardComplete, [e.elementType]: e.complete });

  const handleCardHolderOnChange = (e) => {
    const value = e.target.value;
    setCardComplete({ ...cardComplete, cardName: value.length > 5 ? value : false });
  };

  const handleSignatureOnChange = (e) => {
    const value = e.target.value;
    setCardComplete({ ...cardComplete, signature: value });
  };

  const handleSubmitCard = async () => {
    if (!stripe || !elements) return;
    const cardNumberElement = elements?.getElement(CardNumberElement);
    if (cardNumberElement) {
      if (
        cardComplete.cardNumber &&
        cardComplete.cardExpiry &&
        cardComplete.cardCvc &&
        cardComplete.cardName &&
        cardComplete.signature
      ) {
        setIsSend(true);

        if (type === 'quote') {
          const res = await stripe.createToken(cardNumberElement);

          if (res?.token?.id) {
            const data = {
              paymentMethodId: res.token.id, //token card
              quoteRequestId: id,
              solicitationNumber: quote?.opportunity?.solicitationNumber,
              signature: cardComplete.signature,
            };

            const ress = await application.call(API.QUOTE_REQUESTS.PAYMENT, data);

            if (ress?.success) {
              successAlert(ress.message);
              setIsSend(false);
              history.push('/quote-requests');
            }
          } else {
            errorAlert('Error processing the payment check your payment method');
          }
          setIsSend(false);
          return;
        } else {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            billing_details: {
              address: {
                ...values,
                country: values.country.id,
                state: values.state.id,
                city: values.city.name,
                postal_code: values.postal_code.id,
              },
              name: cardComplete.cardName,
            },
            card: cardNumberElement,
          });

          if (error) {
            setSubmittingCard(false);
            setIsSend(false);
            errorAlert(error.message);
            return false;
          }
          const { id } = paymentMethod;
          if (id) {
            try {
              const { payload: payloadAddCard } = await addCard({ paymentMethodId: id });

              if (payloadAddCard.success && selectedPlanPrice) {
                const { payload } = await createSubscription({
                  priceId: selectedPlanPrice,
                  paymentMethodId: id,
                  promoCode: promoCode?.id ? promoCode.id : null,
                  signature: cardComplete.signature,
                });
                if (payload.success) {
                  setSubscriptionInfo(payload.subscription);
                  setCreateSubscription(payload.subscription);
                  history.push(ROUTE_SUCCESS_PAYMENT.path.replace(':subscriptionId', payload.subscription.id));
                } else {
                  errorAlert(payload.message);
                }
              } else {
                errorAlert(payloadAddCard.message);
              }
            } catch (error) {
              errorAlert(error.message);
            }
            setIsSend(false);
          }
        }
      } else {
        setSubmittingCard(true);
      }
    }
  };

  const getCountries = useCallback(async () => {
    const payload = await application.call(API.UTILITIES.GET_COUNTRIES, {});
    setCountries(payload.map(({ name, isoCode }) => ({ id: isoCode, name })));
  }, []);

  const getStates = async () => {
    const { id } = values.country;
    const payload = await application.call(API.UTILITIES.GET_STATES_BY_COUNTRY, { country: id });
    setStates(payload.map(({ name, isoCode }) => ({ id: isoCode, name })));
  };

  const getCities = async () => {
    const { id: stateId } = values.state;
    const { id: countryId } = values.country;
    const payload = await application.call(API.UTILITIES.GET_CITIES_BY_STATE, {
      state: stateId,
      country: countryId,
    });
    setCities(payload.map(({ name, stateCode }) => ({ id: stateCode, name })));
  };

  const returnToBillingView = () => {
    setPromoCode({ code: '' });
    history.push(ROUTE_BILLING.path);
  };

  useEffect(() => {
    const init = async () => {
      const planId = new URLSearchParams(location.search).get('plan');
      let newIsMonthly = false;
      if (products) {
        setSelectedPlan(products.appMax);
        newIsMonthly = products.appMax.prices.find((p) => p.recurring === 'month').id === planId;
      } else {
        const res = await getProducts();
        const products = Object.values(res.payload);
        const selectedProduct = products.find((product) => product.prices.map((p) => p.id).includes(planId));
        setSelectedPlan(selectedProduct);
        newIsMonthly = selectedProduct.prices.find((p) => p.recurring === 'month').id === planId;
      }
      setIsMontly(newIsMonthly);
    };

    init();
  }, [products, getProducts, location.search, setSelectedPlan]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (values.country) {
      setFieldValue('state', '');
      getStates();
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state) {
      setFieldValue('city', '');
      getCities();
    }
  }, [values.state]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={6} md={12}>
          {breadcrumb === 'checkout' && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" fontFamily="lexend" weight="500">
                  {trans('billing-address')}
                </Typography>
              </Grid>
              <Grid item lg={12} md={6} xs={12}>
                <Autocomplete
                  options={countries}
                  required
                  label={trans('country')}
                  placeholder={trans('required')}
                  disableClearable
                  {...getFieldProps('country')}
                />
              </Grid>
              <Grid item lg={12} md={6} xs={12}>
                <Autocomplete
                  options={states}
                  required
                  label={trans('state')}
                  placeholder={trans('required')}
                  disableClearable
                  {...getFieldProps('state')}
                  disabled={!values.country}
                />
              </Grid>
              <Grid item lg={12} md={6} xs={12}>
                <Autocomplete
                  options={cities}
                  required
                  label={trans('city')}
                  placeholder={trans('required')}
                  disableClearable
                  {...getFieldProps('city')}
                  disabled={!values.state}
                />
              </Grid>
              <Grid item lg={8} md={8} xs={12}>
                <TextField
                  label={trans('address')}
                  placeholder={trans('required')}
                  required
                  {...getFieldProps('line1')}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <ZipcodeAutocomplete
                  label={trans('zip-code')}
                  onCustomChange={(value) => {
                    setFieldValue('postal_code', { id: value, value: value });
                  }}
                  required
                  country={values.country.id}
                  state={values.state.name}
                  placeholder={trans('required')}
                  {...getFieldProps('postal_code')}
                />
              </Grid>
            </Grid>
          )}

          {breadcrumb === 'information' && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h6" fontFamily="lexend" weight="500">
                    {trans('contact-information')}
                  </Typography>
                  <Divider light spacing="2" />
                  <FieldValue
                    value={contactEmail}
                    label={trans('contact')}
                    colorLabel="label"
                    color="hint"
                    fontFamily="lexend"
                  />
                  <Divider light spacing="2" />
                  <Grid container alignItems="flex-end" justifyContent="space-between" spacing={3}>
                    <Grid item zeroMinWidth>
                      <FieldValue
                        value={fullBillingAddress}
                        label={trans('billing')}
                        colorLabel="label"
                        color="hint"
                        fontFamily="lexend"
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Button variant="outlined" color="primary" onClick={() => onNextStep('billing')} size="small">
                        {trans('change')}
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider light spacing="2" />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" fontFamily="lexend" weight="500">
                    {trans('payment')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label={trans('full-name')}
                    placeholder={trans('required')}
                    onChange={handleCardHolderOnChange}
                    onBlur={handleCardHolderOnChange}
                  />
                  {!cardComplete.cardName && !!isSubmittingCard && (
                    <p className={styles.errorText}>{trans('required-field')}</p>
                  )}
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <LabelElementStripe label={trans('card-details')} />
                  <div className={styles.cardInputStripe}>
                    <CardNumberElement
                      onChange={handleCardElementOnChange}
                      options={{
                        style: {
                          base: elementInputStyle,
                        },
                      }}
                    />
                  </div>
                  {!cardComplete.cardNumber && !!isSubmittingCard && (
                    <p className={styles.errorText}>{trans('required-field')}</p>
                  )}
                </Grid>
                <Grid item lg={3} md={3} xs={12}>
                  <LabelElementStripe label={trans('mm-yy')} />
                  <div className={styles.cardInputStripe}>
                    <CardExpiryElement
                      onChange={handleCardElementOnChange}
                      options={{
                        style: {
                          base: elementInputStyle,
                        },
                      }}
                    />
                  </div>
                  {!cardComplete.cardExpiry && !!isSubmittingCard && (
                    <p className={styles.errorText}>{trans('required-field')}</p>
                  )}
                </Grid>
                <Grid item lg={3} md={3} xs={12}>
                  <LabelElementStripe label={trans('cvc')} />
                  <div className={styles.cardInputStripe}>
                    <CardCvcElement
                      onChange={handleCardElementOnChange}
                      options={{
                        style: {
                          base: elementInputStyle,
                        },
                      }}
                    />
                  </div>
                  {!cardComplete.cardCvc && !!isSubmittingCard && (
                    <p className={styles.errorText}>{trans('required-field')}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Signature
                    label={trans('signature')}
                    placeholder={trans('required')}
                    onChange={handleSignatureOnChange}
                    name={'signature'}
                  />
                  {!cardComplete.signature && !!isSubmittingCard && (
                    <p className={styles.errorText}>{trans('required-field')}</p>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Grid item lg={6} md={12}>
          {!type && <CurrentPlanSelect step={breadcrumb} defaultValue={isMonthly} />}

          {type === 'quote' && (
            <>
              <Grid container alignItems="flex-end" direction="row" spacing={2} justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6" fontFamily="lexend" gutter="label">
                    {quote?.opportunity?.solicitationNumber} - {quote?.opportunity?.title}
                  </Typography>
                  <Typography variant="h6" color="label" fontFamily="lexend">
                    <AttachMoneyIcon /> USD {formatNumber(quote?.amount)} / payment for quote request
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Divider light spacing="2" />

          <div className="containerActionButtons">
            {breadcrumb === 'checkout' && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={returnToBillingView}
                  size="large"
                  className={styles.separateButtons}
                >
                  {trans('return-to-billing')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmitFormBilling} size="large">
                  {trans('continue-to-payment')}
                </Button>
              </>
            )}

            {breadcrumb === 'information' && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onNextStep('checkout')}
                  size="large"
                  className={styles.separateButtons}
                >
                  {trans('return-to-billing')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmitCard} size="large">
                  {trans('complete-order')}
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <ModalProcessed open={isSend} />
    </>
  );
};

export default connect(Wrapper);
