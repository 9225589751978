import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconShieldTick = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g id="vuesax/linear/shield-tick">
        <g id="vuesax/linear/shield-tick_2">
          <g id="shield-tick">
            <path
              id="Vector"
              d="M10.4899 2.23006L5.49991 4.11006C4.34991 4.54006 3.40991 5.90006 3.40991 7.12006V14.5501C3.40991 15.7301 4.18991 17.2801 5.13991 17.9901L9.43991 21.2001C10.8499 22.2601 13.1699 22.2601 14.5799 21.2001L18.8799 17.9901C19.8299 17.2801 20.6099 15.7301 20.6099 14.5501V7.12006C20.6099 5.89006 19.6699 4.53006 18.5199 4.10006L13.5299 2.23006C12.6799 1.92006 11.3199 1.92006 10.4899 2.23006Z"
              stroke="black"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M9.05005 11.8701L10.66 13.4801L14.96 9.18005"
              stroke="black"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconShieldTick.propTypes = simpleIconPropsTypes;
