import IconButton from '@material-ui/core/IconButton';
import IconMore from '@material-ui/icons/MoreHoriz';
import React, { useCallback, useEffect, useState } from 'react';
import { ListItemTextBase } from '../../../../../components/common/Containers';
import { ContextMenuListItem, Popover } from '../../../../../components/common/Dialogs/Popover';
import Typography from '../../../../../components/common/Typography';
import ConfirmDialog from '../../../../../components/generics/Confirm/Dialog';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import usePopover from '../../../../../hooks/usePopover';
import { useTrans } from '../../../../../services/i18n';
import SavePursuitForm from '../../../../../components/forms/SavePursuitForm';

export default React.memo(function PursuitItemMenu({
  item,
  settings,
  title,
  pending,
  onDelete,
  getOriginalOpportunity,
  changePursuitStatus,
  onEdit,
}) {
  const { trans } = useTrans();

  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useBooleanHandlers();
  const [openEdit, onOpenEdit, onCloseEdit] = useBooleanHandlers();

  function goToOriginalView() {
    if (item) {
      getOriginalOpportunity({ pursuitId: item.id });
    }
  }

  return !item ? null : (
    <>
      <IconButton onClick={onOpenPopover} size="small">
        <IconMore />
      </IconButton>
      <SavePursuitForm item={item} open={openEdit} onClose={onCloseEdit} onEdit={onEdit} />
      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        <ContextMenuListItem size="normal">
          <Typography variant="caption" color="hint">
            {trans('Actions')}
          </Typography>
        </ContextMenuListItem>

        <ContextMenuListItem button onClick={onOpenEdit} onClickCB={onClosePopover} size="normal">
          <ListItemTextBase>{trans('EditPursuit')}</ListItemTextBase>
        </ContextMenuListItem>

        {/* {settings && ['Owner', 'Admin'].includes(settings.userRole) && ( */}
        <ContextMenuListItem button onClick={onOpenConfirm} onClickCB={onClosePopover} size="normal">
          <ListItemTextBase>{trans('DeletePursuit')}</ListItemTextBase>
        </ContextMenuListItem>
        {/* )} */}

        <ContextMenuListItem button onClick={goToOriginalView} onClickCB={onClosePopover} size="normal">
          <ListItemTextBase>{trans('ViewOriginal')}</ListItemTextBase>
        </ContextMenuListItem>

        <ContextMenuListItem size="normal">
          <Typography variant="caption" color="hint">
            {trans('ChangePursuitStatus')}
          </Typography>
        </ContextMenuListItem>
        <ContextMenuListItem
          button
          onClick={() => {
            changePursuitStatus?.({ status: 'Won', stageId: item.stageId, id: item.id });
          }}
          onClickCB={onClosePopover}
          size="normal"
        >
          <ListItemTextBase>{trans('Won')}</ListItemTextBase>
        </ContextMenuListItem>
        <ContextMenuListItem
          button
          onClick={() => {
            changePursuitStatus?.({ status: 'Lost', stageId: item.stageId, id: item.id });
          }}
          onClickCB={onClosePopover}
          size="normal"
        >
          <ListItemTextBase>{trans('Lost')}</ListItemTextBase>
        </ContextMenuListItem>
        <ContextMenuListItem
          button
          onClick={() => {
            changePursuitStatus?.({ status: 'Abandoned', stageId: item.stageId, id: item.id });
          }}
          onClickCB={onClosePopover}
          size="normal"
        >
          <ListItemTextBase>{trans('Abandoned')}</ListItemTextBase>
        </ContextMenuListItem>
      </Popover>

      <ConfirmDialog
        open={openConfirm}
        onClose={onCloseConfirm}
        onConfirm={() => {
          onDelete?.({ id: item.id, stageId: item.stageId }).then(onCloseConfirm);
        }}
        pending={pending}
        title={trans('DeletePursuit')}
        text={trans('ConfirmActionPursuit')}
      >
        <Typography color="hint">{item.pursuitName}</Typography>
      </ConfirmDialog>
    </>
  );
});
