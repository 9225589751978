import * as Yup from 'yup';
import makeValidationSchema from '../../../../../hooks/makeValidationSchema';

export const orderOptions = [
  { name: 'updated', value: 'lastModifiedDate', asc: 'oldest-first', desc: 'newest-first' },
  { name: 'alphabetical', value: 'alphabetical', asc: 'A-Z', desc: 'Z-A' },
];

export const useSettingsScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    isSendEmails: Yup.boolean().default(false),
    range: Yup.string()
      .default('')
      .when('isSendEmails', (val, schema) => (val ? schema.required(trans('required-field')) : schema.optional())),
    from: Yup.string()
      .default('')
      .when('isSendEmails', (val, schema) =>
        val
          ? schema.when('range', (val, schema) =>
              val === 'Day' ? schema.required(trans('required-field')) : schema.optional()
            )
          : schema.optional()
      ),
    to: Yup.string()
      .default('')
      .when('isSendEmails', (val, schema) =>
        val
          ? schema.when('range', (val, schema) =>
              val === 'Day' ? schema.required(trans('required-field')) : schema.optional()
            )
          : schema.optional()
      ),
  })
);

export const TIME_RANGE_OPTIONS = [
  { value: '15minutes', i18nKey: 'num-minutes', i18nValues: { num: 15 } },
  { value: '30minutes', i18nKey: 'num-minutes', i18nValues: { num: 30 } },
  { value: '1hour', i18nKey: 'num-hours', i18nValues: { num: 1 } },
  { value: 'Day', i18nKey: 'Day' },
];

export function getChipColorByScope(scope) {
  switch (scope) {
    case 'PURSUIT':
    case 'TASKS':
    case 'ORGANIZATION':
    case 'TEAMING':
      return 'primary';
    case 'CA':
      return 'warning';
    case 'FGO':
    case 'FCO':
      return 'success';
    case 'VENDOR':
    case 'AGENT':
      return 'error';
    default:
      return 'secondary';
  }
}

export function getButtonTextByItemType(itemType) {
  switch (itemType) {
    case 'Saved Searches':
      return 'modify-saved-search';
    case 'Task Reminders':
      return 'view-task';
    case 'Pursuit Update':
      return 'modify-pursuit';
    case 'Team Updates':
      return 'view-team';
    default:
      return '';
  }
}

export function getActionByItem(item) {
  switch (item?.itemType) {
    case 'Saved Searches':
    case 'Task Reminders':
      return item?.details?.linkTo;
    case 'Pursuit Update':
      return `/pursuits/${item?.itemId}`;
    case 'Team Updates':
      return `/pursuits/${item?.itemId}?tab=teaming`;
    default:
      return '';
  }
}

export function getChipBGByItemType(itemType) {
  switch (itemType) {
    case 'Saved Searches':
      return 'rgba(149, 118, 255, 0.1)';
    case 'Task Reminders':
      return 'rgba(255, 120, 37, 0.1)';
    case 'Pursuit Update':
      return 'rgba(23, 180, 113, 0.1)';
    case 'Team Updates':
      return 'rgba(78, 89, 120, 0.1)';
    default:
      return 'rgba(78, 89, 120, 0.1)';
  }
}

export function getChipColorByItemType(itemType) {
  switch (itemType) {
    case 'Saved Searches':
      return '#9576FF';
    case 'Task Reminders':
      return '#FF7825';
    case 'Pursuit Update':
      return '#17B471';
    case 'Team Updates':
      return '#4E5978';
    default:
      return '#4E5978';
  }
}

/***
 * The folowing code is an example of how the color should be returned,
 * the 'interested' and 'not interesed' logic is missing right now
 */
export function getChipBGByNotInterested(item) {
  if (item) {
    return '#FFFFFF';
  } else {
    return 'rgba(243, 36, 69, 0.1)';
  }
}

/***
 * The folowing code is an example of how the color should be returned,
 * the 'interested' and 'not interesed' logic is missing right now
 */
export function getChipColorByNotInterested(item) {
  if (item) {
    return '#2445A7';
  } else {
    return '#F32445';
  }
}

/***
 * The folowing code is an example of how the color should be returned,
 * the 'interested' and 'not interesed' logic is missing right now
 */
export function getChipBorderByNotInterested(item) {
  if (item) {
    return '1px solid #2445A733';
  } else {
    return 'none';
  }
}

export function getDescriptionByItem(item) {
  const itemDetails = item?.details;
  switch (item?.itemType) {
    case 'Saved Searches':
      return `+${itemDetails.count} opportunities`;
    case 'Task Reminders':
      return `Due ${itemDetails.dueDateTz}`;
    case 'Pursuit Update':
      return '';
    case 'Team Updates':
      return `${itemDetails.type}`;
    default:
      return '';
  }
}

export function getDescriptionColorByItemType(itemType) {
  switch (itemType) {
    case 'Saved Searches':
      return '#888FA9';
    case 'Task Reminders':
      return '#FF7825';
    case 'Pursuit Update':
      return '#FFFFFF';
    case 'Team Updates':
      return '#888FA9';
    default:
      return '#FFFFFF';
  }
}

export function getTitleByItem(item) {
  const { itemType, title, details } = item;
  switch (itemType) {
    case 'Saved Searches':
      return title;
    case 'Task Reminders':
      return title;
    case 'Pursuit Update':
      return title;
    case 'Team Updates':
      return details.scope;
    default:
      return title;
  }
}

export function hasSubtitleByItemType(itemType) {
  switch (itemType) {
    case 'Saved Searches':
    case 'Pursuit Update':
      return false;
    case 'Task Reminders':
    case 'Team Updates':
      return true;
    default:
      return false;
  }
}

export function getSubtitleByItem(item) {
  const { itemType, title, scope } = item;
  switch (itemType) {
    case 'Saved Searches':
      return '';
    case 'Task Reminders':
      return scope;
    case 'Pursuit Update':
      return '';
    case 'Team Updates':
      return title;
    default:
      return '';
  }
}
