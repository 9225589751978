import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useState } from 'react';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../../../components/common/Typography';
import { makeStyles } from '@material-ui/core/styles';
import usePopover from '../../../../hooks/usePopover';
import Popover from '../Popover';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles({
  label: {
    fontSize: '0.938rem',
  },
  formControl: {
    width: '100%',
  },
  container: {
    backgroundColor: '#F1F3F9', // Gray background
    padding: '6px 10px 6px 10px', // Padding around the RadioGroup
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginTop: '0.1rem',
  },
  customRadio: {
    transform: 'scale(1.1)',
  },
});

export default function RadioFormTextHelp({ options, onChange, name = 'radio', value, disabled, error, touched }) {
  const { trans } = useTrans();
  const classes = useStyles();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const [helpTooltipOption, setHelpTooltipOption] = useState('');

  return (
    <>
      <FormControl component="fieldset" disabled={disabled} className={classes.formControl}>
        <div className={classes.container} style={{ border: error && '1px solid #f32445' }}>
          <RadioGroup row name={name} value={value} onChange={!disabled ? onChange : undefined}>
            {options.map((option, ind) => (
              <FormControlLabel
                key={ind}
                value={option.value}
                control={
                  <Radio color="secondary" id={`radio-${name}-${option.value}`} className={classes.customRadio} />
                }
                label={
                  <>
                    <span>{trans(option.name)}</span>
                    {option.description && (
                      <span
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                        }}
                      >
                        <InfoOutlined
                          onClick={(ev) => {
                            setHelpTooltipOption(option.description);
                            onOpenPopover(ev);
                          }}
                          color="primary"
                          className={classes.infoIcon}
                        />
                      </span>
                    )}
                  </>
                }
                classes={{ label: classes.label }} // Apply custom styles here
              />
            ))}
          </RadioGroup>
        </div>
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </FormControl>
      {/* Help popover */}
      <Popover
        helpTooltip={helpTooltipOption}
        anchorEl={anchorEl}
        onOpenPopover={onOpenPopover}
        onClosePopover={onClosePopover}
      />
    </>
  );
}
