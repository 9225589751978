import { connect } from 'react-redux';
import { actions } from '../../../store/pipelinesDetails';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch, { onEdit }) {
  return {
    onEdit:
      onEdit ??
      (async (params) => {
        await dispatch(actions.editPursuit(params));
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
