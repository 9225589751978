import React from 'react';
import debounce from '../../utils/debounce'; // Import your custom debounce function
import { useApiAutocomplete } from '../../hooks/useApiAutocomplete';
import { useApplication } from '../../services/application';
import Autocomplete from '../common/Inputs/Autocomplete';
import { BILLING } from '../../constants/api';
import { ANY_OF_THOSE } from '../../constants/filter';
import Popper from '@material-ui/core/Popper';

const getOptionLabel = (option) => {
  if (option && option.value) {
    const [zipcode] = option.value.split(':');
    return zipcode.trim();
  }
  return '';
};

function CustomPopper(props) {
  return <Popper {...props} placement="top" />;
}
export const ZipcodeAutocomplete = (props) => {
  const {
    onChange,
    onCustomChange,
    value,
    multi,
    labelKey = 'id',
    apiMethod = BILLING.GET_ZIP_CODES,
    label,
    country,
    state,
    filterValues,
    ...rest
  } = props;
  const application = useApplication();
  let mappedState = state;
  const filterData = filterValues && filterValues();
  if (!state && filterData && filterData.state_data && filterData.state_type === ANY_OF_THOSE) {
    mappedState = filterData.state_data.map((s) => s.id).join(',');
  }

  const { options, loading, handleValueChange, filterOptions, handleInputChange } = useApiAutocomplete({
    labelKey,
    onChange: React.useCallback((val) => onChange && onChange(val), [onChange]),
    getOptionList: (params) =>
      application.call(apiMethod, { ...params, filter: { ...params.filter, country, state: mappedState } }),
    listLimit: 25,
  });

  // Use your custom debounce function to debounce onCustomChange
  const debouncedOnCustomChange = React.useMemo(
    () => (typeof onCustomChange === 'function' ? debounce(onCustomChange, 300) : null),
    [onCustomChange]
  );

  // Clean up the debounced function on component unmount
  React.useEffect(() => {
    return () => {
      if (debouncedOnCustomChange && typeof debouncedOnCustomChange.abort === 'function') {
        debouncedOnCustomChange.abort();
      }
    };
  }, [debouncedOnCustomChange]);

  return (
    <Autocomplete
      name="zip-code"
      options={options}
      label={label}
      labelKey={labelKey}
      loading={loading}
      value={multi ? value ?? [] : value}
      filterOptions={filterOptions}
      onChange={handleValueChange}
      multiple={multi}
      renderOption={(option) => option.value}
      selectOnFocus
      handleHomeEndKeys
      freeSolo
      getOptionLabel={getOptionLabel}
      onInputChange={(e, value) => {
        handleInputChange(e, value);
        if (debouncedOnCustomChange) {
          debouncedOnCustomChange(value);
        }
      }}
      PopperComponent={CustomPopper}
      {...rest}
    />
  );
};
