import MUIPopover from '@material-ui/core/Popover';
import clsx from 'clsx';
import React from 'react';

const defaultProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
};

export const BottomLeftPosition = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
};

export const BottomCenterPosition = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'center' },
};

export const TopLeftPosition = {
  anchorOrigin: { vertical: 'top', horizontal: 'left' },
  transformOrigin: { vertical: 'center', horizontal: 'left' },
};

export const TopTopPosition = {
  anchorOrigin: { vertical: 'top', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
};

export const Popover = ({
  children,
  anchorOrigin = defaultProps.anchorOrigin,
  transformOrigin = defaultProps.transformOrigin,
  className,
  dense = false,
  ...rest
}) => {
  return (
    <MUIPopover
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      className={clsx(className, dense && 'densePopover')}
      {...rest}
    >
      {children}
    </MUIPopover>
  );
};
