import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_FEDERAL_AGENTS } from '../../constants';
import { FEDERAL_AGENTS } from '../../constants/api';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE } from '../helpers/common';
import { extendBuilderWithListActions, generateListActions } from '../helpers/listActionsHelpers';
import { onItemDetails, onReject } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  item: null,
  pending: true,

  naicsCodes: INITIAL_LIST_STATE,
  colleagues: { ...INITIAL_LIST_STATE, pagination: { page: 1, perPage: 3 } },
  opportunities: INITIAL_LIST_STATE,
};

const naicsCodesListAtions = generateListActions({
  scope: `${SCOPE_FEDERAL_AGENTS}/naicsCodeList`,
  apiMethod: { GET_LIST: API.FEDERAL_AGENTS.GET_NAICS_CODES },
  getStore: (store) => store.federalAgentDetails.naicsCodes,
});

const colleaguesListAtions = generateListActions({
  scope: `${SCOPE_FEDERAL_AGENTS}/colleagues`,
  apiMethod: { GET_LIST: API.FEDERAL_AGENTS.GET_COLLEAGUES },
  getStore: (store) => store.federalAgentDetails.colleagues,
});

const opportunitiesListAtions = generateListActions({
  scope: `${SCOPE_FEDERAL_AGENTS}/opportunities`,
  apiMethod: { GET_LIST: API.FEDERAL_AGENTS.GET_POSTED_OPPORTUNITIES },
  getStore: (store) => store.federalAgentDetails.opportunities,
});

export const fetchFederalAgent = createAsyncThunk('federalAgents/findOne', async (params) => {
  return application.call(FEDERAL_AGENTS.FIND_ONE, params);
});

const toggleUserOption = createAsyncThunk('federalAgents/toggleUserOption', (params) => {
  return application.call(API.FEDERAL_AGENTS.TOGGLE_USER_OPTION, params);
});

const onUpdateNote = (params) => application.call('federalAgents.updateNotes', params);

export const actions = {
  fetchFederalAgent,
  toggleUserOption,
  onUpdateNote,
  naicsCodesListAtions,
  colleaguesListAtions,
  opportunitiesListAtions,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, naicsCodesListAtions, (store) => store.naicsCodes);
  extendBuilderWithListActions(builder, colleaguesListAtions, (store) => store.colleagues);
  extendBuilderWithListActions(builder, opportunitiesListAtions, (store) => store.opportunities);

  return builder
    .addCase(fetchFederalAgent.fulfilled, onItemDetails)
    .addCase(fetchFederalAgent.rejected, onReject)
    .addCase(fetchFederalAgent.pending, (state) => Object.assign(state, INITIAL_STATE, { pending: true }));
});
