import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconTaskCompletePending = (props) => {
  const { width = 24, height = 24, className, type = 'completed' } = props;

  return type === 'completed' ? (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM16.6757 8.26285C16.2686 7.88965 15.636 7.91716 15.2628 8.32428L10.499 13.519L8.73715 11.597L8.64665 11.5098C8.26798 11.188 7.70008 11.1911 7.32428 11.5356C6.91716 11.9088 6.88965 12.5413 7.26285 12.9485L9.76285 15.6757L9.85193 15.7618C10.2551 16.1059 10.8691 16.0772 11.2372 15.6757L16.7372 9.67572L16.8162 9.57799C17.1039 9.17282 17.0515 8.60733 16.6757 8.26285Z"
        fill="#17B471"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V8ZM11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16Z"
        fill="#F32445"
      />
    </SvgIcon>
  );
};

IconTaskCompletePending.propTypes = simpleIconPropsTypes;
