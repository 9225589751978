import { createAsyncThunk, createAction, createSlice } from '@reduxjs/toolkit';
import { application } from '../../services/application';
import { API } from '../../constants';

const INITIAL_STATE = {
  pending: false,
  data: {
    status: '',
    currentPlan: '',
  },
  openModalPremium: false,
};

const fetch = createAsyncThunk('subscription/fetch', () => {
  return application.call(API.ORGANIZATION.SUBSCRIPTION_STATUS);
});

export const setOpenModalPremium = createAction('subscription/setOpenModal');
function onOpenModalPremium(state, action) {
  return Object.assign(state, {
    openModalPremium: action.payload,
  });
}

export const setSubscriptionInfo = createAction('subscription/setInfo');
function onSetSubsInfo(state, action) {
  return Object.assign(state, {
    data: action.payload,
  });
}

const dismissUpgradingSubscription = createAsyncThunk('subscription/dismissUpgradingSubscription', () => {
  return application.call(API.ORGANIZATION.DISMISS_UPGRADING_SUBSCRIPTION);
});

const slice = createSlice({
  initialState: INITIAL_STATE,
  name: 'subscription',
  extraReducers: (builder) => {
    builder
      .addCase(fetch.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.data = payload;
      })
      .addCase(fetch.rejected, (state) => {
        state.pending = false;
      })
      .addCase(fetch.pending, (state) => {
        state.pending = true;
      })
      .addCase(setSubscriptionInfo, onSetSubsInfo)
      .addCase(setOpenModalPremium, onOpenModalPremium);
  },
});

export const subscriptionActions = {
  ...slice.actions,
  fetch,
  dismissUpgradingSubscription,
  setOpenModalPremium,
};

export default slice.reducer;
