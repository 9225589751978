import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCopy = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M20 8c1.657 0 3 1.343 3 3v9c0 1.657-1.343 3-3 3h-9c-1.657 0-3-1.343-3-3v-9c0-1.657 1.343-3 3-3zm0 2h-9c-.552 0-1 .448-1 1v9c0 .552.448 1 1 1h9c.552 0 1-.448 1-1v-9c0-.552-.448-1-1-1zm-7-9c1.598 0 2.904 1.249 2.995 2.824L16 4v1c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L14 5V4c0-.513-.386-.936-.883-.993L13 3H4c-.513 0-.936.386-.993.883L3 4v9c0 .513.386.936.883.993L4 14h1c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L5 16H4c-1.598 0-2.904-1.249-2.995-2.824L1 13V4c0-1.598 1.249-2.904 2.824-2.995L4 1h9z"
        transform="translate(-991 -423) translate(380 356) translate(596 52) translate(15 15)"
      />
    </SvgIcon>
  );
};

IconCopy.propTypes = simpleIconPropsTypes;
