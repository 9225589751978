import React from 'react';
import { Button, RouterLink } from '../../../common/Buttons';
import { InlineContainer, SpacingContainer } from '../../../common/Containers/Base';
import { ModalDialog } from '../../../common/Dialogs';
import Typography from '../../../common/Typography';
import { useTrans } from '../../../../services/i18n';

export default function AddToPipelineModal({ pipeline, pending, pursuitRegister, open, onCloseDialog }) {
  const { trans } = useTrans();

  return (
    <ModalDialog
      open={open}
      onClose={onCloseDialog}
      disableContentSpacing
      title={`${trans('Success')}!`}
      actions={
        pipeline && (
          <InlineContainer>
            <RouterLink to={'/pipelines?pipeline=' + pipeline.id}>
              <Button variant="outlined" color="primary">
                {trans('ViewPipeline')}
              </Button>
            </RouterLink>
            <RouterLink to={'/pursuits/' + pursuitRegister}>
              <Button variant="contained" color="primary">
                {trans('ViewPursuit')}
              </Button>
            </RouterLink>
          </InlineContainer>
        )
      }
      pending={pending}
    >
      <SpacingContainer px={4} py={4}>
        <Typography variant="h5" weight={500} fontFamily="lexend">
          The Pursuit was added to the pipeline
        </Typography>
        <Typography variant="h6" component="p" weight={500} color="hint">
          {`${trans('Pipeline')} `}
          <RouterLink to={`/pipelines?pipeline=${pipeline?.id}`}>«{pipeline ? pipeline.name : ''}»</RouterLink>
        </Typography>
      </SpacingContainer>
    </ModalDialog>
  );
}
