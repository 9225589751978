import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../../../components/common/Typography';
import { makeStyles } from '@material-ui/core/styles';
import usePopover from '../../../../hooks/usePopover';
import Popover from '../Popover';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { useState } from 'react';

const useStyles = makeStyles({
  label: {
    fontSize: '0.938rem',
  },
  formControl: {
    width: '100%',
  },
  container: {
    backgroundColor: '#F1F3F9', // Gray background
    padding: '6px 10px 6px 10px', // Padding around the RadioGroup
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginTop: '0.1rem',
  },
  customCheckbox: {
    transform: 'scale(1.1)',
  },
});

export default function CheckboxFormTextHelp({
  options,
  onChange,
  name = 'checkbox',
  value = [],
  disabled,
  error,
  row = false,
}) {
  const { trans } = useTrans();
  const classes = useStyles();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const [helpTooltip, setHelpTooltip] = useState('');

  const handleCheckboxChange = (event) => {
    if (disabled) return;

    const newValue = Array.isArray(value) ? [...value] : [];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      const index = newValue.indexOf(event.target.value);
      if (index > -1) {
        newValue.splice(index, 1);
      }
    }
    onChange(event, newValue);
  };

  function renderCheckboxes() {
    return options.map((option, ind) => (
      <FormControlLabel
        key={ind}
        name={name}
        control={
          <Checkbox
            color="secondary"
            checked={value && value?.includes(option?.value)}
            onChange={handleCheckboxChange}
            value={option.value}
            id={`checkbox-${name}-${option.value}`}
            className={classes.customCheckbox}
          />
        }
        label={
          <>
            <span style={{ fontSize: '0.938rem' }}>{trans(option.name)}</span>
            {option.description && (
              <span
                style={{
                  marginLeft: '0.3rem',
                  cursor: 'pointer',
                }}
              >
                <InfoOutlined
                  onClick={(ev) => {
                    setHelpTooltip(option.description);
                    onOpenPopover(ev);
                  }}
                  color="primary"
                  className={classes.infoIcon}
                />
              </span>
            )}
          </>
        }
      />
    ));
  }

  return (
    <>
      <FormControl component="fieldset" disabled={disabled} className={classes.formControl}>
        <div className={classes.container} style={{ border: error && '1px solid #f32445' }}>
          {row ? <div style={{ display: 'flex', flexDirection: 'row' }}>{renderCheckboxes()}</div> : renderCheckboxes()}
          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
        </div>
      </FormControl>
      {/* Help popover */}
      <Popover
        helpTooltip={helpTooltip}
        anchorEl={anchorEl}
        onOpenPopover={onOpenPopover}
        onClosePopover={onClosePopover}
      />
    </>
  );
}
