import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconPrint = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M18 1c.513 0 .936.386.993.883L19 2v6h1c1.598 0 2.904 1.249 2.995 2.824L23 11v5c0 1.598-1.249 2.904-2.824 2.995L20 19h-1v3c0 .513-.386.936-.883.993L18 23H6c-.552 0-1-.448-1-1v-3H4c-1.598 0-2.904-1.249-2.995-2.824L1 16v-5c0-1.598 1.249-2.904 2.824-2.995L4 8h1V2c0-.513.386-.936.883-.993L6 1h12zm-1 14H7v6h10v-6zm3-5H4c-.513 0-.936.386-.993.883L3 11v5c0 .513.386.936.883.993L4 17h1v-3c0-.513.386-.936.883-.993L6 13h12c.552 0 1 .448 1 1v3h1c.513 0 .936-.386.993-.883L21 16v-5c0-.513-.386-.936-.883-.993L20 10zm-3-7H7v5h10V3z"
        transform="translate(-1386 -99) translate(82 81) translate(1304 18)"
      />
    </SvgIcon>
  );
};

IconPrint.propTypes = simpleIconPropsTypes;
