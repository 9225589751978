import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTrans } from '../../../../services/i18n';
import { ModalDialog } from '../../../../components/common/Dialogs';
import Loader from '../../../../components/common/Loader';
import { useAlertContext } from '../../../../contexts/AlertContext';
import Button from '../../../../components/common/Buttons/Button';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import DnDField from '../../../../components/common/Inputs/File/DnDField';
import Typography from '../../../../components/common/Typography';
import _ from 'lodash';

const TWENTY_MB = 20971520;
export default function FileUploadModal({ open, onClose, multiple = false, file, setFile }) {
  const { trans } = useTrans();
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const acceptableTypesMessage = React.useRef('');
  const [acceptableExtensions, setAcceptableExtensions] = React.useState([]);
  const { infoAlert } = useAlertContext();
  const [fileSelected, setFileSelected] = React.useState();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  /***
   * In charge of set the type of files acceptable
   */
  React.useEffect(() => {
    setAcceptableExtensions([]);
    // acceptableTypesMessage.current = item.acceptableFileTypes.split(',');
    acceptableTypesMessage.current = ['PDF'];
    for (let index = 0; index < acceptableTypesMessage.current.length; index++) {
      const element = acceptableTypesMessage.current[index];
      let extensions = [];

      extensions.push(`.${element.toLowerCase()}`);
      setAcceptableExtensions((prevExtensions) => [...prevExtensions, ...extensions]);
    }
    // Add spaces to extensions in order to fix styles when there are many of them
    let extensions = 'PDF';
    acceptableTypesMessage.current = extensions;
  }, [open]);

  /***
   * When user upload one or more files
   */
  const handleChange = React.useCallback(
    (v) => {
      if (!v.target.value) return;

      // Only add files with acceptable extensions and less than 20mb, extract only the first one in case multiple
      // files were attached using drag & drop
      let val = v.target.value;
      val = _.isArray(val) ? val[0] : val;

      setFileSelected(null);

      if (!acceptableExtensions.includes(val?.ext?.toLowerCase() || '')) infoAlert(trans('file-type-not-allowed'));
      else if (val.originFile.size > TWENTY_MB) infoAlert(trans('file-size-exceeded'));
      else setFileSelected(val);
    },
    [fileSelected, acceptableExtensions]
  );

  /***
   * Build request and send it to backend
   */
  const sendFiles = async () => {
    setFile(fileSelected);
    setFileSelected(null);
    onClose();
  };

  /***
   * Action when modal is closed
   */
  const onCloseModal = () => {
    setFileSelected(null);
    onClose();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ModalDialog
        open={open}
        onClose={onCloseModal}
        title={trans('upload-capabilities')}
        actions={
          fileSelected && (
            <>
              <Button variant="outlined" color="primary" onClick={onCloseModal}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={sendFiles}>
                Add File
              </Button>
            </>
          )
        }
      >
        <Loader open={loading} />
        <DnDField
          bgColor="transparent"
          multiple={multiple}
          onChange={handleChange}
          labelFile={isMobile ? trans('ClickToBrowse') : trans('DragDropFilesOrClickToBrowse')}
          subLabelFile={trans('AcceptableFiles') + acceptableTypesMessage.current}
          subSubLabelFile={trans('MaximumFileSize')}
        />

        <Typography align="center" varian="h5" style={{ marginTop: '1rem' }}>
          {fileSelected?.name}
          {fileSelected?.ext}
        </Typography>
      </ModalDialog>
    </DndProvider>
  );
}
