import colors from '../pallete';
import typography from '../typography';

const MuiButton = {
  root: {
    color: colors.gray.main,
    minWidth: 0,
    borderRadius: 4,
    fontWeight: 'bold',
    boxShadow: 'none',
    padding: '7.5px 20px 8px',
    '&$disabled': { color: undefined },
  },

  startIcon: {
    marginLeft: -11,
    marginRight: 5,
    '&$iconSizeSmall': { marginLeft: -7, marginRight: 3 },
  },
  endIcon: {
    marginRight: -10,
    marginLeft: 5,
  },

  text: {
    padding: undefined,
    height: 40,
    fontSize: typography.body2.fontSize,
  },
  contained: {
    height: 40,
    fontSize: typography.button.fontSize,
    '&$disabled': { color: undefined, backgroundColor: undefined },
  },
  outlined: {
    height: 40,
    padding: '6.5px 20px 7px',
    fontSize: typography.button.fontSize,
    border: '1px solid currentColor',
  },

  textSizeLarge: {
    fontSize: '1rem',
    height: 52,
  },
  containedSizeLarge: {
    padding: '11px 30px 13px',
    fontSize: typography.body2.fontSize,
    height: 52,
  },
  outlinedSizeLarge: {
    padding: '10px 30px 12px',
    fontSize: typography.body2.fontSize,
    height: 52,
  },

  textSizeSmall: {
    padding: '2px 10px',
    height: 28,
  },
  containedSizeSmall: {
    padding: '3px 10px',
    height: 28,
    borderRadius: 4,
  },
  outlinedSizeSmall: {
    padding: '2px 10px',
    height: 28,
    borderRadius: 4,
    fontSize: typography.caption.fontSize,
  },
};

export default MuiButton;
