import * as Yup from 'yup';
import makeValidationSchema from '../../../../../hooks/makeValidationSchema';

export const initialValues = {
  name: '',
  lastName: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    name: Yup.string().required(trans('empty-not-allowed', { field: trans('name') })),
    lastName: Yup.string().required(trans('empty-not-allowed', { field: trans('lastName') })),
  })
);
