import Grid from '@material-ui/core/Grid';
import React from 'react';
import { RouterLink } from '../../../../../components/common/Buttons';
import Avatar from '../../../../../components/common/Cards/Avatar';
import Typography from '../../../../../components/common/Typography';

export default function AvatarTitle({ src, title, description, descriptionNode, detailLink, small = false }) {
  return (
    <Grid container alignItems={small ? 'flex-start' : 'center'} spacing={2} wrap="nowrap">
      <Grid item>
        <Avatar size={small ? 40 : 60} variant={small ? 'body1' : 'h6'} value={title} src={src} alt={title} />
      </Grid>
      <Grid item zeroMinWidth>
        {title && detailLink && (
          <Typography variant="h4" weight="600" color="primary" component={RouterLink} to={detailLink} noWrap>
            {title}
          </Typography>
        )}
        {descriptionNode ? (
          descriptionNode
        ) : description ? (
          <Typography variant="h6" weight="500">
            {description}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}
