function getName(item) {
  if (item && item.name) return item.name;
  if (item && item.code) return item.code;
  if (item && typeof item === 'string') return item;
  return '';
}

function findOpportunityLocation(item) {
  let loc = (item && item.placeOfPerformance && item.placeOfPerformance[0]) || (item && item.placeOfPerformance);
  if (!loc && Array.isArray(item.organizationHierarchy)) {
    const organization = Array.from(item.organizationHierarchy)
      .reverse()
      .find((o) => Boolean(o.address && o.address.zip));
    loc = organization?.address;
  }
  return loc;
}

export function getLocationObject(item) {
  const loc = findOpportunityLocation(item);
  if (!loc) return null;
  return {
    streetAddress: getName(loc.streetAddress),
    streetAddress2: getName(loc.streetAddress2),
    city: getName(loc.city),
    state: getName(loc.state),
    zip: getName(loc.zip),
    country: getName(loc.country),
  };
}

export function getLocation(loc) {
  return [
    getName(loc.streetAddress),
    getName(loc.streetAddress2),
    getName(loc.city),
    getName(loc.state),
    getName(loc.zip),
    getName(loc.country),
  ]
    .filter((f) => !!f)
    .join(', ');
}

export function getOpportunityLocation(item) {
  /*const loc = findOpportunityLocation(item);
  if (!loc) return null;
  return getLocation({  });*/
  if (!item) return null;
  return [item.streetAddress, item.streetAddress2, item.city, item.state, item.zip, item.country]
    .filter((f) => !!f && f !== ' ')
    .join(', ');
}

export function getOpportunityAgency(item) {
  if (item.agencyCode) {
    return {
      id: item.agencyCode,
      name: item.agencyName,
    };
  } else if (item.departmentCode)
    return {
      id: item.departmentCode,
      name: item.departmentName,
    };
  else {
    return {
      id: item.agencyId,
    };
  }
}

export function getContacts(item) {
  return (
    item &&
    item.pointOfContacts &&
    item.pointOfContacts.length > 0 &&
    item.pointOfContacts.map((contact) => ({
      id: contact.id,
      name: contact.fullName || contact.name,
      title: contact.title,
      email: contact.email && contact.email.toLowerCase(),
      phone: contact.phone,
    }))
  );
}

export function getOpportunityDates(item) {
  const deadline = item && item.responseDateActual;
  const publishDate = item && item.publishDate;
  const awardDate = item && item.awardDate;
  return {
    deadline,
    publishDate,
    awardDate,
  };
}

export function getDescriptionWordsCount(string) {
  return string
    .replace(/(\s|,)/gm, ' ')
    .split(' ')
    .filter((el) => el !== '').length;
}

export function getOpportunityData(item) {
  return {
    dates: getOpportunityDates(item),
    location: getOpportunityLocation(item?.placeOfPerformance),
    agency: getOpportunityAgency(item),
    setAside: item && item.setAside,
    setAsideDescription: item && item.setAsideDescription && `- ${item.setAsideDescription}`,
    naicsCode: item && item.naics && Array.isArray(item.naics) ? item.naics[0] : item.naics,
    psc: item && item.psc && Array.isArray(item.psc) ? item.psc[0] : item.psc,
  };
}

export function getShortDescription(description, length = 1000) {
  return `${description.substr(0, length)}${description.length > length ? '...' : ''}`;
}
