import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconFederalAgents = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="M17 12c2.21 0 4 1.79 4 4v2c0 2.21-1.79 4-4 4H5c-2.21 0-4-1.79-4-4v-2c0-2.21 1.79-4 4-4zm0 2H5c-1.105 0-2 .895-2 2v2c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-2c0-1.105-.895-2-2-2zM11 0c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm0 2C9.343 2 8 3.343 8 5s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z" />
    </SvgIcon>
  );
};

IconFederalAgents.propTypes = simpleIconPropsTypes;
