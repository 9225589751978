import { connect } from 'react-redux';
import { toggleTutorials, update, updatePhone, setMFADb, updateSamApiKey, updatePhoneVerified } from '../../store/auth';
import { actions as billingActions } from '../../store/billing';
import { isMFAEnabled } from '../../utils/mfa';

function mapStateToProps(state) {
  return {
    authUser: state.auth || {},
    subscription: state.orgSettings.subscription,
    currentProduct: state.billing.currentProduct,
    subscriptionStatus: state.subscription.data,
    settings: state.orgSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomerBilling: () => {
      return dispatch(billingActions.getCustomerBilling());
    },
    updateMFA: (preferredMFA) => {
      dispatch(setMFADb({ mfa: preferredMFA }));
      return dispatch(update({ mfaEnabled: isMFAEnabled(preferredMFA), preferredMFA }));
    },
    updatePhone: (phone) => {
      return dispatch(updatePhone(phone));
    },
    updatePhoneVerified: (isVerified) => {
      return dispatch(updatePhoneVerified(isVerified));
    },
    updateSamApiKey: (samApiKey) => {
      return dispatch(updateSamApiKey(samApiKey));
    },
    updatePersonalInformation: (data) => {
      const { email, name, familyName, timezone, formatDate, jobTitle, linkedInUrl } = data;
      return dispatch(update({ email, name, familyName, timezone, formatDate, jobTitle, linkedInUrl }));
    },
    updateEmailNotificationSettings: (data) => {
      const {
        consolidateNotificationNumber,
        consolidateNotificationSelect,
        pursuitNotices,
        savedSearchNotices,
        teamUpdates,
        taskReminders,
        pursuitDailyRecap,
        savedSearchDailyRecap,
        teamUpdatesDailyRecap,
        taskRemindersDailyRecap,
        personalTaskRemindersDailyRecap,
      } = data;
      return dispatch(
        update({
          consolidateNotificationNumber,
          consolidateNotificationSelect,
          pursuitNotices,
          savedSearchNotices,
          teamUpdates,
          taskReminders,
          pursuitDailyRecap,
          savedSearchDailyRecap,
          teamUpdatesDailyRecap,
          taskRemindersDailyRecap,
          personalTaskRemindersDailyRecap,
        })
      );
    },
    toggleTutorialsStatus: (data) => {
      const { disableTutorials } = data;
      return dispatch(toggleTutorials({ disableTutorials }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
