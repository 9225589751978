import React from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../components/common/Typography';
import { TextField } from '../../../components/common/Inputs';
import { Button } from '../../../components/common/Buttons';
import useStyles from '../styles';
import { useForm } from '../../../hooks/useForm';
import { schemaToInitialState } from '../../../utils/validationUtils';
import makeValidationSchema from '../../../hooks/makeValidationSchema';
import { useTrans } from '../../../services/i18n';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    password: Yup.string()
      .password(trans('incorrect-password'))
      .required(trans('empty-not-allowed', { field: trans('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], trans('passwords-match'))
      .required(trans('empty-not-allowed', { field: trans('confirm-new-password') })),
  })
);

function SetPasswordForm({ onSubmit, loading }) {
  const { trans } = useTrans();
  const classes = useStyles();
  const schema = useScheme();
  const [inputTypePassword, setInputTypePassword] = React.useState('password');
  const [inputTypeConfirmPassword, setInputTypeConfirmPassword] = React.useState('password');

  const formik = useForm(schema, schemaToInitialState(schema), onSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleVisibilityPassword = () => {
    setInputTypePassword(inputTypePassword === 'password' ? 'text' : 'password');
  };

  const handleVisibilityConfirmPassword = () => {
    setInputTypeConfirmPassword(inputTypeConfirmPassword === 'password' ? 'text' : 'password');
  };

  return (
    <div className={classes.formBack}>
      <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
        <Grid item xs={12} className={classes.signForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h1Medium" fontFamily="lexend" weight="500">
                {trans('set-password')}
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label={trans('password')}
                type={inputTypePassword}
                placeholder={trans('password')}
                {...formik.getFieldProps('password')}
                required
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={handleVisibilityPassword} position="end" className={classes.cursorPointer}>
                      {inputTypePassword === 'password' ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                type={inputTypeConfirmPassword}
                label={trans('confirm-new-password')}
                placeholder={trans('confirm-new-password')}
                {...formik.getFieldProps('confirmPassword')}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={handleVisibilityConfirmPassword}
                      position="end"
                      className={classes.cursorPointer}
                    >
                      {inputTypeConfirmPassword === 'password' ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                id="change-password-btn"
                variant="contained"
                size="large"
                pending={loading}
                color="primary"
                onClick={formik.handleSubmit}
              >
                {trans('set-password')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default SetPasswordForm;
