import React from 'react';
import { Grid, Button, IconButton } from '@material-ui/core';

import MoreHoriz from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import { SpacingContainer, Paper } from '../../../../../../components/common/Containers';
import DetailsContentBlock from '../../../../../../components/generics/Helpers/DetailsContentBlock';
import ListItemTextBase from '../../../../../../components/common/Containers/Base/ListItemTextBase';
import { ContextMenuListItem, Popover } from '../../../../../../components/common/Dialogs/Popover';
import { useTrans } from '../../../../../../services/i18n';
import { Table, TableCell, TableHead, TableRow } from '../../../../../../components/common/ResponsiveTable';
import Typography from '../../../../../../components/common/Typography';
import usePopover from '../../../../../../hooks/usePopover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { IconDelete, IconPencil } from '../../../../../../components/svg';
import NaicsModal from './NaicsModal';
import ConfirmDialog from '../../../../../../components/generics/Confirm/Dialog';
import PaginationSmall from '../../../../../../components/common/Paginations/PaginationSmall';
import { useAlertContext } from '../../../../../../contexts/AlertContext';

const useStyles = makeStyles((theme) => ({
  cell: {
    position: 'absolute',
    right: 0,
  },
}));

const Naics = ({ item, actions, naics, removeVendorNaics }) => {
  const { trans } = useTrans();
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpenNaicModal, setIsOpenNaicModal] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [currentNaic, setCurrentNaic] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { successAlert, errorAlert } = useAlertContext();

  const [anchorEl, onOpen, onClosePopover] = usePopover();

  const { items, pending, pagination, count } = naics;
  const { onInit, onChangePage, onChangeRowPerPage } = actions;

  /***
   *
   */
  const openMemberModal = (naics) => {
    return () => {
      setIsOpenNaicModal(true);
      naics && setCurrentNaic(naics);
    };
  };

  /***
   *
   */
  const onOpenPopover = (naics) => {
    return (e) => {
      setCurrentNaic(naics);
      onOpen(e);
    };
  };

  /***
   *
   */
  const closeNaicsModal = () => {
    setCurrentNaic(null);
    setIsOpenNaicModal(false);
  };

  /***
   *
   */
  const onCloseConfirm = () => {
    setCurrentNaic(null);
    setOpenConfirm(false);
  };

  /***
   *
   */
  const onOpenConfirm = (naics) => {
    return () => {
      setOpenConfirm(true);
      naics && setCurrentNaic(naics);
    };
  };

  /***
   *
   */
  React.useEffect(() => {
    if (!item.id) return;
    onInit({ vendorManagementId: item.id });
  }, [onInit, item.id]);

  /***
   * Fix error when the last item in a page different from 1 is deleted
   */
  React.useEffect(() => {
    if (count > 0 && count <= (pagination.page - 1) * pagination.perPage) {
      onChangePage(pagination.page - 1);
    }
  }, [count]);

  /***
   *
   */
  const vendorNaics = item?.naics?.reduce((acc, value) => {
    acc[value.code] = value.name;
    return acc;
  }, {});

  return (
    <Paper hidden>
      <NaicsModal open={isOpenNaicModal} onClose={closeNaicsModal} naic={currentNaic} />
      <Popover id={'id-naics-actions'} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        <ContextMenuListItem button onClick={openMemberModal()}>
          <ListItemTextBase>{trans('edit')}</ListItemTextBase>
        </ContextMenuListItem>

        <ContextMenuListItem button onClick={onOpenConfirm()} onClickCB={onClosePopover}>
          <ListItemTextBase>{trans('delete')}</ListItemTextBase>
        </ContextMenuListItem>
      </Popover>

      <ConfirmDialog
        open={openConfirm}
        onClose={onCloseConfirm}
        onConfirm={async () => {
          try {
            setLoading(true);
            if (currentNaic.id) {
              const resp = await removeVendorNaics({ id: currentNaic.id });
              if (resp?.error) {
                errorAlert('Error deleting Naic');
              } else {
                successAlert('Success!');
                onCloseConfirm();
                // Get all items in order to change the current page if applies
                onInit({ vendorManagementId: item.id });
              }
            }
            setLoading(false);
          } catch (error) {
            errorAlert('Error deleting Naic');
            setLoading(false);
          }
        }}
        noIcon
        pending={loading}
        title={trans('delete-org-naics')}
        noText
        withOutBackground
      >
        <Typography variant="h2">{trans('confirm-delete-org-naics')}</Typography>
        <Typography variant="body1" color="secondary" weight="500">
          {currentNaic?.naics?.value}
        </Typography>
      </ConfirmDialog>
      <SpacingContainer>
        <DetailsContentBlock
          title={trans('org-naics')}
          pending={pending}
          actions={
            isMobile ? (
              <Button variant="contained" color="primary" onClick={openMemberModal()} size="md">
                <AddIcon />
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={openMemberModal()}>
                {trans('add-naics')}
              </Button>
            )
          }
        >
          <Grid container alignItems="center" spacing={2} xs={12}>
            <Grid item xs={12}>
              {items?.length > 0 && (
                <SpacingContainer px="5sm3" py={0}>
                  <Table
                    stickyHeader
                    head={
                      <TableHead>
                        <TableCell>{trans('naics-code')}</TableCell>
                        <TableCell>{trans('naics-desc')}</TableCell>
                        <TableCell width={0}>{trans('in-sam')}</TableCell>
                        <TableCell width={0}>{trans('naics-note')}</TableCell>
                        <TableCell width={0}>{trans('Actions')}</TableCell>
                      </TableHead>
                    }
                  >
                    {items.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell label={trans('naics-code')}>
                          <Typography variant="body2">{row.naics.id}</Typography>
                        </TableCell>
                        <TableCell label={trans('naics-desc')}>
                          <Typography variant="body2">{row.naics?.value?.split('-')?.pop()}</Typography>
                        </TableCell>
                        {isMobile && (
                          <TableCell className={styles.cell} align="right">
                            <IconButton onClick={onOpenPopover(row)} size="small">
                              <MoreHoriz />
                            </IconButton>
                          </TableCell>
                        )}

                        <TableCell label={trans('in-sam')}>
                          <Typography
                            variant="body2"
                            style={{ color: vendorNaics[row.naics.id] ? '#17b471' : '#f32445' }}
                          >
                            {vendorNaics[row.naics.id] ? 'Registered' : 'Not registered'}
                          </Typography>
                        </TableCell>

                        <TableCell label={trans('naics-note')}>
                          <Typography variant="body2">{row.note}</Typography>
                        </TableCell>

                        {!isMobile && (
                          <TableCell style={{ display: 'flex' }}>
                            <IconButton onClick={openMemberModal(row)}>
                              <IconPencil />
                            </IconButton>
                            <IconButton onClick={onOpenConfirm(row)}>
                              <IconDelete />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </Table>
                </SpacingContainer>
              )}
              <PaginationSmall
                {...pagination}
                count={count}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                pending={pending}
                px="5sm3"
                py={2}
              />
            </Grid>
          </Grid>
        </DetailsContentBlock>
      </SpacingContainer>
    </Paper>
  );
};

export default Naics;
