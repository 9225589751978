import { cleanExceptionsAction, setExceptionsAction } from '../../store/exceptions';
import useActions from './useActions';

const useExceptionsActions = () => {
  const [setExceptionsD, cleanExceptionsD] = useActions([setExceptionsAction, cleanExceptionsAction]);

  return {
    setExceptionsD,
    cleanExceptionsD,
  };
};

export default useExceptionsActions;
