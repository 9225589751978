import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconSavedSearches = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="M10 0c5.523 0 10 4.477 10 10 0 2.4-.846 4.604-2.256 6.328l3.963 3.965c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-3.965-3.963C14.604 19.154 12.4 20 10 20 4.477 20 0 15.523 0 10S4.477 0 10 0zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm3.707 5.293c.36.36.388.928.083 1.32l-.083.094-4 4c-.36.36-.928.388-1.32.083l-.094-.083-2-2c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083l.094.083L9 10.585l3.293-3.292c.39-.39 1.024-.39 1.414 0z" />
    </SvgIcon>
  );
};

IconSavedSearches.propTypes = simpleIconPropsTypes;
