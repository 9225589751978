import Grid from '@material-ui/core/Grid';
import React from 'react';
import Skeleton from '../../../common/Skeleton';

export default function DetailsPageSceleton({ oneColumn = false }) {
  return oneColumn ? (
    <Grid container spacing={3} direction="column">
      <Grid item xs>
        <Skeleton height={550} variant="rect" />
      </Grid>
      <Grid item xs>
        <Skeleton height={300} variant="rect" />
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyContent="space-between" spacing={3} alignItems="flex-start">
      <Grid item md={8} sm={12} xs={12}>
        <Grid container spacing={3} direction="column">
          <Grid item xs>
            <Skeleton height={550} variant="rect" />
          </Grid>
          <Grid item xs>
            <Skeleton height={300} variant="rect" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <Grid container spacing={3} direction="column">
          <Grid item xs>
            <Skeleton height={350} variant="rect" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
