import { Grid, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { RouterLink } from '../../../components/common/Buttons';
import { ModalDialog } from '../../../components/common/Dialogs';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import { useTrans } from '../../../services/i18n';
import NotInterestedList from './List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Divider } from '../../../components/common/Divider';
import { emptyArray } from '../../../utils/arrayUtils';
import connect from './connect';
import { useEffect } from 'react';
import { SpacingContainer } from '../../../components/common/Containers';
import Typography from '../../../components/common/Typography';
import { correctNotInterestedType } from './helpers';
import NothingFoundCard from '../../../components/generics/Cards/NothingFoundCard';
import {
  ROUTE_CONTRACT_AWARDS_DETAILS,
  ROUTE_CONTRACT_OPPORTUNITIES_DETAILS,
  ROUTE_GRANT_OPPORTUNITIES_DETAILS,
} from '../../../constants';
import {
  IconContractAwards,
  IconContractOpportunities,
  IconGrantOpportunities,
  IconNsnOpportunities,
} from '../../../components/svg/Icons/Menu';

const sections = {
  FGO: ROUTE_GRANT_OPPORTUNITIES_DETAILS.path,
  FCO: ROUTE_CONTRACT_OPPORTUNITIES_DETAILS.path,
  NSN: null,
  CA: ROUTE_CONTRACT_AWARDS_DETAILS.path,
};

const useStyles = makeStyles((theme) => ({
  containerCard: {
    padding: '1rem 1.5rem 0.2rem',
  },
  containerFooter: {
    padding: '1.3rem 2.3rem 1.87rem',
    marginTop: 'unset',
    display: 'flex',
    justifyContent: 'center',
  },
  blackLabel: {
    color: theme.palette.text.primary,
  },
  iconContainer: {
    display: 'flex',
    width: '2.5rem',
    height: '2.5rem',
    padding: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    background: 'rgba(178, 184, 207, 0.16)',
    marginRight: '0.5rem',
  },
}));

const ListOfNotInterested = ({ listOfNotInterested, onInit }) => {
  const { items, filters } = listOfNotInterested;
  const hasItems = !emptyArray(items);
  const { trans } = useTrans();
  const [open, onOpen, onClose] = useBooleanHandlers();
  const styles = useStyles();

  useEffect(() => {
    onInit();
  }, [onInit]);

  const getDetailLink = (item) => {
    let id = item.typeId;

    if (item.type === 'CA') id = `${id}?i=${id}`;

    const link = sections[item.type] ? `${sections[item.type].replace(':id', id)}` : null;
    return link;
  };

  const getItemIcon = (item) => {
    switch (item.type) {
      case 'CA':
        return (
          <div className={styles.iconContainer}>
            <IconContractAwards width={24} height={24} />
          </div>
        );
      case 'FCO':
        return (
          <div className={styles.iconContainer}>
            <IconContractOpportunities width={24} height={24} />
          </div>
        );
      case 'FGO':
        return (
          <div className={styles.iconContainer}>
            <IconGrantOpportunities width={24} height={24} />
          </div>
        );
      case 'NSN':
        return (
          <div className={styles.iconContainer}>
            <IconNsnOpportunities width={24} height={24} />
          </div>
        );
      default:
        return (
          <div className={styles.iconContainer}>
            <IconContractOpportunities width={24} height={24} />
          </div>
        );
    }
  };

  return (
    <>
      <Grid container spacing={3} className={styles.containerCard}>
        <Grid item lg={12} md={12} xs={12}>
          {hasItems ? (
            <SpacingContainer component="ul">
              {items.map((item, index) => (
                <React.Fragment key={item.id}>
                  {index > 0 && index < 3 && <Divider light spacing={1} component="li" />}

                  {index < 3 && (
                    <ListItem disableGutters>
                      {getItemIcon(item)}
                      <ListItemText>
                        <Typography
                          fontFamily="lexend"
                          weight="500"
                          variant="body1"
                          component={RouterLink}
                          to={() => getDetailLink(item)}
                        >
                          {item.title}
                        </Typography>
                        <Grid container>
                          <Grid item>
                            <Typography color="hint" weight="500" variant="body2">
                              {trans('type')}{' '}
                              <Typography variant="inherit" className={styles.blackLabel}>
                                {correctNotInterestedType(item.type)}
                              </Typography>
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  )}
                </React.Fragment>
              ))}
            </SpacingContainer>
          ) : (
            <NothingFoundCard
              title={trans(!!filters.text ? 'NothingFound' : 'NothingHasBeenAddedYet')}
              description={trans(!!filters.text ? 'PleaseTrySomethingElse' : 'AllOpportunitiesMarkedAppearHere')}
              size="small"
              py={10}
            />
          )}
        </Grid>
      </Grid>
      <Divider light spacing={1} />
      <Grid container className={styles.containerFooter}>
        <a
          onClick={onOpen}
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            fontSize: '1rem',
            color: '#2445A7',
          }}
        >
          {trans('ViewAll')}
        </a>
      </Grid>
      <ModalDialog open={open} onClose={onClose} title={trans('list-of-not-interested')} disableContentSpacing>
        <NotInterestedList />
      </ModalDialog>
    </>
  );
};

export default connect(ListOfNotInterested);
