import { useHistory } from 'react-router-dom';
import { TIERS } from '../../../utils/subscription';
import { useState } from 'react';

const useChangePlanActions = ({
  products,
  setSelectedPlan,
  subscription,
  onOpenConfirm,
  onCloseConfirm,
  updateSubscription,
  getOrganizationInfo,
  getSubscriptionStatus,
  isAuthorized,
  hasPaymentMethod,
}) => {
  const history = useHistory();
  const [tier, setTier] = useState(null);
  const [updatePending, setUpdatePending] = useState(false);

  const { currentPlan, sfSubscription } = subscription?.data ?? {
    currentPlan: TIERS.LIMITED,
    sfSubscription: false,
  };

  const createNewSubscription = (selectedProduct) => {
    history.push(`/checkout?plan=${selectedProduct.id}`);
  };

  const onUpdateSubscription = () => {
    const selectedPrice = products?.appMax?.prices?.find((p) => tier.includes(p.recurring));
    setUpdatePending(true);
    updateSubscription({ priceId: selectedPrice.id })
      .then(async (res) => {
        Promise.all([getOrganizationInfo({}), getSubscriptionStatus()]).then(() => {
          setUpdatePending(false);
          onCloseConfirm();
          history.push('/dashboard');
        });
      })
      .catch((err) => {
        setUpdatePending(false);
        onCloseConfirm();
      });
  };

  const onNotAuthSubscribeClick = (targetPlan) => {
    const signUpPath = `${process.env.REACT_APP_SIGN_UP_PAGE}/signup/?tier=${targetPlan}`;
    window.location.href = signUpPath;
  };

  const onSubscribeClick = (targetPlan) => {
    setTier(targetPlan);

    if (!isAuthorized) {
      onNotAuthSubscribeClick(targetPlan);
      return;
    }

    if (sfSubscription || targetPlan === TIERS.LIMITED) {
      history.push('/billing?tab=plan');
      return;
    }

    const selectedPlan = products?.appMax?.prices.find((p) => targetPlan.includes(p.recurring));
    setSelectedPlan(selectedPlan);

    if (currentPlan === TIERS.LIMITED || currentPlan === TIERS.FREE_TRIAL || !hasPaymentMethod) {
      createNewSubscription(selectedPlan);
    } else {
      // If user has subscription or a payment method, show confirmation modal to upgrade/downgrade subscription
      onOpenConfirm();
    }
  };

  return {
    onSubscribeClick,
    onUpdateSubscription,
    updatePending,
  };
};

export default useChangePlanActions;
