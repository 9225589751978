import { extendBuilderWithListActions, generateListActions, generateListCallbacks } from './listActions';
import { extendBuilderWithSearchActions, generateSearchActions, generateSearchCallbacks } from './searchActions';

function extendListBuilder(builder, actions) {
  if (!builder) return;
  extendBuilderWithListActions(builder, actions, undefined, false);
  extendBuilderWithSearchActions(builder, actions);
  return builder;
}

export { INITIAL_LIST_STATE, INITIAL_LIST_STATE_EXTENDED, isLoadMoreAction } from '../common';
export * from './notInterestingActions';
export * from './reportsActions';
export * from './selectItemsActions';
export {
  extendBuilderWithListActions,
  generateListActions,
  generateListCallbacks,
  extendListBuilder,
  extendBuilderWithSearchActions,
  generateSearchActions,
  generateSearchCallbacks,
};
