import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';

export const INITIAL_STATE = {
  items: [],
  pending: false,
  pagination: {
    page: 1,
    perPage: 10,
  },
  count: 0,
  order: ['CreatedDate', 'desc'],
  filter: { i18nKey: 'Only Open', value: 'Open' },
  categories: [],
};

const getTickets = createAsyncThunk('business/getTickets', (params, { getState }) => {
  const { pagination, order, filter } = getState().businessTickets;
  return application.call(API.BUSINESS.GET_TICKETS, {
    order,
    filter: filter.value,
    pagination: { ...pagination },
  });
});
function onFetchTickets(state, action) {
  Object.assign(state, {
    items: action.payload.items,
    count: action.payload.count,
    pending: false,
  });
}

const addTicket = createAsyncThunk('business/addTicket', (params) => {
  return application.call(API.BUSINESS.ADD_TICKET, params);
});
function onFullAddTicket(state, action) {
  Object.assign(state, { pending: true });
  const copy = [...state.items];
  copy.unshift(action.payload);
  Object.assign(state, { items: [...copy], pending: false });
}

const editTicket = createAsyncThunk('business/editTicket', (params) => {
  return application.call(API.BUSINESS.EDIT_TICKET, params);
});
function onFullEditTicket(state, action) {
  const copy = [...state.items];
  const indexItem = copy.findIndex((i) => i.id === action.payload.id);
  copy[indexItem] = action.payload;
  Object.assign(state, { items: [...copy] });
}

const deleteTicket = createAsyncThunk('business/deleteTicket', (params) => {
  return application.call(API.BUSINESS.DELETE_TICKET, params);
});
function onDeleteTicket(state, action) {
  const copy = [...state.items];
  const indexItem = copy.findIndex((i) => i.id === action.payload.id);
  copy.splice(indexItem, 1);
  Object.assign(state, { items: [...copy] });
}

const closeTicket = createAsyncThunk('business/closeTicket', (params) => {
  return application.call(API.BUSINESS.CLOSE_TICKET, params);
});
function onFullCloseTicket(state, action) {
  const copy = [...state.items];
  const indexItem = copy.findIndex((i) => i.id === action.payload.id);
  copy[indexItem].status = action.payload.status;
  copy[indexItem].isclosed = true;
  copy[indexItem].closeddate = action.payload.closeddate;
  copy[indexItem].reason = action.payload.reason;
  Object.assign(state, { items: [...copy] });
}

const getResponsesByTickets = createAsyncThunk('business/responsesByTickets', (params) => {
  return application.call(API.BUSINESS.GET_RESPONSES_BY_TICKETS, params);
});

const getTicketCategories = createAsyncThunk('business/getTicketCategories', (params) => {
  return application.call(API.BUSINESS.GET_TICKET_CATEGORIES, params);
});
const onGetTicketCategories = (state, action) => {
  Object.assign(state, { categories: action.payload, pending: false });
};

const onChangePage = createAction('business/changePageTickets');
const onChangeRowPerPage = createAction('business/changeRowPerPageTickets');
const onChangeSort = createAction('business/changeSortTickets');
const onChangeFilter = createAction('business/changeFilterTickets');

export const actions = {
  getTickets,
  addTicket,
  editTicket,
  deleteTicket,
  closeTicket,
  onChangePage,
  onChangeRowPerPage,
  onChangeSort,
  onChangeFilter,
  getResponsesByTickets,
  getTicketCategories,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getTickets.fulfilled, onFetchTickets)
    .addCase(getTickets.pending, (state) => {
      Object.assign(state, { pending: true });
    })
    .addCase(getTickets.rejected, (state) => {
      Object.assign(state, { pending: false });
    })
    .addCase(getTicketCategories.fulfilled, onGetTicketCategories)
    .addCase(getTicketCategories.pending, (state) => {
      Object.assign(state, { pending: true });
    })
    .addCase(getTicketCategories.rejected, (state) => {
      Object.assign(state, { pending: false });
    })
    .addCase(addTicket.fulfilled, onFullAddTicket)
    .addCase(editTicket.fulfilled, onFullEditTicket)
    .addCase(deleteTicket.fulfilled, onDeleteTicket)
    .addCase(closeTicket.fulfilled, onFullCloseTicket)
    .addCase(onChangePage, (state, action) => {
      Object.assign(state.pagination, { page: action.payload });
    })
    .addCase(onChangeRowPerPage, (state, action) => Object.assign(state.pagination, { perPage: action.payload }))
    .addCase(onChangeSort, (state, action) => Object.assign(state, { order: action.payload }))
    .addCase(onChangeFilter, (state, action) => Object.assign(state, { filter: action.payload }));
});
