import { useTrans } from '../../../../services/i18n';
import Typography from '../../../../components/common/Typography';

export default function AvailableSeats({ settings }) {
  const { trans } = useTrans();

  return (
    <>
      <Typography variant="body1" fontFamily="lexend" color="placeholder">
        {trans('additional-seats')}
      </Typography>
      <Typography variant="body1" weight="500" fontFamily="lexend" color="placeholder">
        {settings?.seats}
      </Typography>
      <div style={{ borderRight: '1px solid #ccc', height: 30 }}></div>
      <Typography variant="body1" fontFamily="lexend" color="success">
        {trans('available-seats')}
      </Typography>
      <Typography variant="body1" weight="bold" fontFamily="lexend" color="success">
        {settings?.availableSeats}
      </Typography>
    </>
  );
}
