import Grid from '@material-ui/core/Grid';
import YourPlan from './components/YourPlan';
import PlanBenefits from './components/PlanBenefits';
import BillingAndPayment from './components/BillingAndPayment';
import connect from '../connect';
import { Paper, SpacingContainer } from '../../../components/common/Containers';
import styles from '../styles.module.css';
import Typography from '../../../components/common/Typography';
import { useTrans } from '../../../services/i18n';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  centerTitle: {
    width: '100%',
    textAlign: 'center',
  },
}));

const BillingTab = ({ navigateManagePlan }) => {
  const componentStyles = useStyles();
  const { trans } = useTrans();
  return (
    <Paper component={SpacingContainer} py={5} px="5sm3">
      <Grid container spacing={8}>
        <Grid item sm={4} className={styles.borderRight}>
          <YourPlan navigateManagePlan={navigateManagePlan} />
        </Grid>
        <Grid item sm={4} className={styles.borderRight}>
          <Typography
            variant="h4"
            weight="600"
            fontFamily="lexend"
            gutter="label"
            className={componentStyles.centerTitle}
          >
            {trans('plan-benefits')}
          </Typography>
          <PlanBenefits />
        </Grid>
        <Grid item sm={4}>
          <BillingAndPayment />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default connect(BillingTab);
