import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconCodeCategories = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="M8.202 1v2h-3c-.513 0-.936.386-.993.883L4.202 4v4.303L2.404 11l1.798 2.697V18c0 .513.386.936.883.993l.117.007h3v2h-3c-1.598 0-2.904-1.249-2.995-2.824L2.202 18v-3.696L0 11l2.202-3.304V4c0-1.598 1.249-2.904 2.824-2.995L5.202 1h3zm9 0c1.598 0 2.904 1.249 2.995 2.824l.005.176v3.697L22.404 11l-2.202 3.302V18c0 1.598-1.25 2.904-2.824 2.995l-.176.005h-3v-2h3c.513 0 .935-.386.993-.883l.007-.117v-4.303L19.999 11l-1.797-2.697V4c0-.513-.386-.936-.884-.993L17.202 3h-3V1h3zm-2 13v2h-8v-2h8zm0-4v2h-8v-2h8zm0-4v2h-8V6h8z" />
    </SvgIcon>
  );
};

IconCodeCategories.propTypes = simpleIconPropsTypes;
