import React from 'react';
import { Grid, Link, Paper } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Loader from '../../../components/common/Loader/Loader';
import Typography from '../../../components/common/Typography';
import AuthLayout from '../../../components/layouts/AuthLayout';
import { ROUTE_SIGNIN, ROUTE_SIGNUP } from '../../../constants';
import useVerification from './useVerification';
import InfoState from '../../../components/organims/InfoState';

const Verification = (props) => {
  const { classes, query, trans, state, pending, resendVerification } = useVerification();

  return !query.code && !state.email ? (
    <Redirect to={ROUTE_SIGNUP.path} />
  ) : (
    <AuthLayout>
      <Paper className={classes.formBack}>
        <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center">
          {pending ? (
            <div className={classes.centeredBlock}>
              <Loader open={true} />
            </div>
          ) : !query.code ? (
            <div className={classes.centeredBlock}>
              <Typography variant="h2" component="p">
                {trans('hey')},{' '}
                <Typography variant="h2" component="span" color="secondary">
                  {state.firstName}!
                </Typography>
              </Typography>
              <Typography variant="h1Medium" fontFamily="lexend" weight="500" className={classes.verificationTitle}>
                {trans('please-verify-your-email')}
              </Typography>
              <Typography component="p" weight="500" variant="body1" className={classes.email}>
                {trans('we-sent-verification-email')}
                <Typography component="span" color="secondary" weight="500" variant="body1">
                  {state.email}
                </Typography>
              </Typography>
              <Typography color="hint" variant="body2">
                {trans('did-not-get-the-letter')}
                <Link className={classes.link} onClick={pending ? null : resendVerification}>
                  <Typography component="span" color={pending ? 'disabled' : 'textPrimary'}>
                    {trans('re-send')}
                  </Typography>
                </Link>
              </Typography>
            </div>
          ) : (
            <div className={classes.centeredBlockInfoState}>
              <InfoState
                title={trans('your-account-successfully-created-please-log-in')}
                subTitle={trans('no-further-action-is-needed')}
                buttonText={trans('log-in')}
                to={ROUTE_SIGNIN.path}
              />
            </div>
          )}
        </Grid>
      </Paper>
    </AuthLayout>
  );
};

export default Verification;
