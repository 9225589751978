import React, { useState } from 'react';
import { SpacingContainer } from '../../../../components/common/Containers/Base';
import { Divider } from '../../../../components/common/Divider';
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard';
import { IconOrganizationTeaming } from '../../../../components/svg';
import Typography from '../../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import { useTrans } from '../../../../services/i18n';
import connect from '../../connect';
import { Button } from '../../../../components/common/Buttons';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';
import CompanyInformation from './companyInformation';
import TeamingQuestions from './teamingQuestions';
import KeyPersonnel from './keyPersonnel';

const useStyles = makeStyles((theme) => ({
  topButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
  },
  topButtons: {
    display: 'inline-flex',
    gap: '16px',
  },
  publicProfileContainer: {
    width: '100%',
    height: '100%',
    paddingTop: 5,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 10,
    background: 'rgba(16.76, 202.50, 123.03, 0.12)',
    borderRadius: 14,
    display: 'inline-flex',
    gap: 10,
  },
  publicProfileText: {
    color: '#17B471',
    fontSize: 12,
    fontFamily: 'Manrope',
    fontWeight: 700,
    wordWrap: 'break-word',
    paddingTop: 5,
    paddingBottom: 7,
  },
  publicStatus: {
    display: 'flex',
    padding: '5px 10px 7px 10px',
    alignItems: 'center',
    gap: 10,
    borderRadius: 14,
    background: 'rgba(17, 202, 123, 0.12)',
    color: 'var(--Green, #17B471)',
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
}));

const OrganizationTeaming = ({ loading, settings, openAddFeatureInfoOrg, openAddUpdateOrg }) => {
  console.log('🚀 ~ file: index.js:66 ~ OrganizationTeaming ~ settings:', settings);
  const { trans } = useTrans();
  const [active, setActive] = useState(1);
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState('companyInfo');

  const history = useHistory();

  const teamingOrganizationHandler = (editMode) => {
    history.push(`/teaming-organization`, { orgTeamingRecord: settings.orgTeamingRecord, isEditMode: editMode });
  };

  return (
    <>
      {settings.orgTeamingRecord ? (
        <>
          <SpacingContainer px={5} py={5}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item md={6} sm={12} xs={12}>
                <Typography variant="h3" style={{ fontFamily: 'Lexend', fontWeight: 500, wordWrap: 'break-word' }}>
                  {trans('Teaming')}
                </Typography>
              </Grid>
              <Grid className={styles.topButtonsContainer} item md={6} sm={12} xs={12}>
                <div className={styles.topButtons}>
                  {settings.orgTeamingRecord.publicVisibility === 1 && (
                    <div className={styles.publicStatus}>{'Public Profile'}</div>
                  )}

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      teamingOrganizationHandler(true);
                    }}
                  >
                    {trans('edit')}
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Box mt={4}>
              <Divider />
              <Grid
                container
                wrap="nowrap"
                direction="row"
                style={{ paddingLeft: 40, paddingRight: 40, alignItems: 'center' }}
              >
                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                  onClick={() => setActiveTab('companyInfo')}
                >
                  <Typography
                    style={{
                      color: activeTab === 'companyInfo' ? '#F32445' : '#2445A7',
                      fontSize: 15,
                      fontFamily: 'Manrope',
                      fontWeight: '600',
                      wordWrap: 'break-word',
                      paddingBottom: '10px',
                      paddingtop: '10px',
                    }}
                  >
                    {trans('teaming-organization-form-step-1')}
                  </Typography>
                </Grid>

                <Divider orientation="vertical" flexItem />

                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                  onClick={() => setActiveTab('teamingQuestions')}
                >
                  <Typography
                    style={{
                      color: activeTab === 'teamingQuestions' ? '#F32445' : '#2445A7',
                      fontSize: 15,
                      fontFamily: 'Manrope',
                      fontWeight: '600',
                      wordWrap: 'break-word',
                      paddingBottom: '10px',
                      paddingtop: '10px',
                    }}
                  >
                    {trans('teaming-organization-form-step-2')}
                  </Typography>
                </Grid>

                <Divider orientation="vertical" flexItem />

                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                  }}
                  onClick={() => setActiveTab('keyPersonnel')}
                >
                  <Typography
                    style={{
                      color: activeTab === 'keyPersonnel' ? '#F32445' : '#2445A7',
                      fontSize: 15,
                      fontFamily: 'Manrope',
                      fontWeight: '600',
                      wordWrap: 'break-word',
                      paddingBottom: '10px',
                      paddingtop: '10px',
                    }}
                  >
                    {trans('teaming-organization-form-step-3')}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <Grid container direction="row" style={{ padding: 40 }}>
              {activeTab === 'companyInfo' && <CompanyInformation />}
              {activeTab === 'teamingQuestions' && <TeamingQuestions />}
              {activeTab === 'keyPersonnel' && <KeyPersonnel />}
            </Grid>
          </SpacingContainer>
        </>
      ) : (
        <NothingFoundCard
          title={trans('teaming')}
          description={trans('teaming-description')}
          Icon={<IconOrganizationTeaming />}
          py={7}
          size="small"
        >
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  teamingOrganizationHandler(false);
                }}
              >
                {trans('add-teamning-information')}
              </Button>
            </Grid>
          </Grid>
        </NothingFoundCard>
      )}
    </>
  );
};

export default connect(OrganizationTeaming);
