import { connect } from 'react-redux';

function mapStateToProps(state, { stageId }) {
  return {
    items: state.pipelineDetails.pursuitsByStage[stageId],
    sortBy: state.pipelineDetails.sort.pursuits[stageId],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps);
