import React from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '../../../components/common/Buttons';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import { useTrans } from '../../../services/i18n';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Divider } from '../../../components/common/Divider';
import { Paper } from '../../../components/common/Containers';
import Typography from '../../../components/common/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import connect from '../connect';
import { IconVendors } from '../../../components/svg';
import { Chip } from '../../../components/common/Chip';
import { ROUTE_ORG_SETTINGS } from '../../../constants';
import { useHistory } from 'react-router-dom';
import ModalSwitchOrganizations from './ModalSwitchOrganizations';

const useStyles = makeStyles((theme) => ({
  containerCard: {
    padding: '2rem 1.5rem 1.5rem',
  },
  containerFooter: {
    padding: '1.3rem 2.3rem 1.87rem',
    marginTop: 'unset',
    display: 'flex',
    justifyContent: 'center',
  },
  blackLabel: {
    color: theme.palette.text.primary,
  },
  iconContainer: {
    display: 'flex',
    width: '2.5rem',
    height: '2.5rem',
    padding: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    background: 'rgba(178, 184, 207, 0.16)',
    marginRight: '0.5rem',
  },
  columnLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    alignItems: 'center',
  },
  buttonWidth: {
    width: '8.3rem',
  },
  blackLabel: {
    color: theme.palette.text.primary,
  },
  greenLabel: {
    color: theme.palette.success.dark,
  },
  cells: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.4rem',
  },
  verticalDivider: {
    borderRight: '1px solid  #eaebef',
  },
  bottomSpace: {
    margin: '1rem 0rem',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1.25rem',
  },
  textEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chipsContainer: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'center',
  },
}));

const MyOrganizations = ({ settings }) => {
  const { trans } = useTrans();
  const history = useHistory();
  const [open, onOpen, onClose] = useBooleanHandlers();
  const styles = useStyles();

  return (
    <>
      <Grid container spacing={3} className={styles.containerCard}>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container alignItems="center" className={styles.section}>
            {/* {settings.orgName && settings.avatarId && settings.avatarUrl ? (
              <Avatar src={settings.avatarId && settings.avatarUrl} alt="-" />
            ) : (
              // 
              !settings.avatarId && ( */}
            <Paper style={{ background: '#f1f3f9', padding: '1rem' }}>
              <IconVendors />
            </Paper>
            {/* )
            )} */}
          </Grid>
          <Typography variant="h1Medium" weight="600" align="center">
            {settings.orgName}
          </Typography>
          <div container className={styles.chipsContainer}>
            <Chip
              color="primary"
              style={settings.parentOrganizationId ? { backgroundColor: '#4E5978' } : { backgroundColor: '#2445A7' }}
              label={settings.parentOrganizationId ? trans('sub-organization') : trans('main-organization')}
            />

            {settings.vendorClaimed ? (
              <Chip
                color="success"
                label={
                  <>
                    <CheckCircleOutlineIcon style={{ fontSize: '16px', marginRight: '5px' }} />{' '}
                    {trans('registered-organization')}{' '}
                  </>
                }
              />
            ) : (
              <Chip label={trans('unregistered')} />
            )}
          </div>

          <Grid container className={styles.bottomSpace}>
            <Grid item xs={6} className={styles.verticalDivider}>
              <Typography variant="body2" color="hint" align="center" weight={600}>
                {trans('additional-seats')}:{settings?.seats}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="success" align="center" weight={600} className={styles.greenLabel}>
                {trans('available-seats')}:{settings?.availableSeats}
              </Typography>
            </Grid>
          </Grid>
          <Divider light spacing={1} />
          <Grid container className={styles.bottomSpace}>
            <Grid item xs={6}>
              <Typography variant="body2" color="hint" align="left" weight={400}>
                {trans('contact-person')}:
              </Typography>
              <Typography
                variant="h6"
                color="hint"
                align="left"
                weight={400}
                className={`${styles.blackLabel} ${styles.textEllipsis}`}
              >
                {settings?.contactPerson ? settings?.contactPerson : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="hint" align="left" weight={400}>
                {trans('contact-email')}:
              </Typography>
              <Typography
                variant="h6"
                align="left"
                weight={400}
                className={`${styles.blackLabel} ${styles.textEllipsis}`}
              >
                {settings?.contactEmail ? settings?.contactEmail : '-'}
              </Typography>
            </Grid>
          </Grid>
          <div className={styles.buttonsContainer}>
            <Button variant="contained" color="primary" onClick={() => history.push(ROUTE_ORG_SETTINGS.path)}>
              {trans('manage-organization')}
            </Button>
            <Button onClick={onOpen} variant="outlined" color="primary">
              {trans('switch-organization')}
            </Button>
          </div>
        </Grid>
      </Grid>

      <ModalSwitchOrganizations open={open} onClose={onClose} />
    </>
  );
};

export default connect(MyOrganizations);
