import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import connect from '../../Subscription/connect';
import useStyles from './styles';

function Button({
  isIcon,
  className,
  variant,
  pending,
  disabled,
  children,
  color,
  view,
  component,
  settings,
  ...props
}) {
  const styles = useStyles();
  const isCustomColor =
    color === 'error' || color === 'success' || color === 'warning' || color === 'initial' || color === 'info';
  const classNames = {
    [styles.isIconRoot]: isIcon && variant !== 'outlined',
    [styles.isIconOutlined]: isIcon && variant === 'outlined',
    [styles.disabledStyles]: disabled,
    [styles.pendingStyles]: pending,
    [styles[color]]: isCustomColor && !disabled,
    [styles.view]: view,
  };

  const classes = {
    contained: styles.contained,
    outlined: styles.outlined,
    text: styles.text,
    label: styles.label,
    sizeSmall: styles.sizeSmall,
    sizeLarge: styles.sizeLarge,
  };
  return (
    <MUIButton
      color={isCustomColor ? 'default' : color}
      variant={variant}
      className={clsx(className, classNames)}
      classes={classes}
      disabled={pending || disabled}
      component={!!props.href ? 'a' : !!props.to ? Link : component}
      target={!!props.href ? '_blank' : undefined}
      {...props}
      // style={custom}
    >
      {children}
      {pending && (
        <span className={styles.loader}>
          <CircularProgress color="inherit" size="1.29em" />
        </span>
      )}
    </MUIButton>
  );
}
export default connect(Button);
