import { Paper, SpacingContainer } from '../../../../components/common/Containers';
import Typography from '../../../../components/common/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { formatNumber } from '../../../../utils/numberUtils';
import PromoCode from './PromoCode';
import { Divider } from '../../../../components/common/Divider';
import Grid from '@material-ui/core/Grid';
import { useTrans } from '../../../../services/i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoaderSmall } from '../../../../components/common/LoaderSmall';
import connect from '../../connect';
import { Switch } from '../../../../components/common/Inputs';

const CurrentPlanSelect = ({
  selectedPlan,
  setSelectedPlanPrice,
  pending: pendingPlan,
  step = false,
  defaultValue = false,
}) => {
  const { trans } = useTrans();
  const [amountDiscount, setAmountDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  let [modality, setModality] = useState(defaultValue);
  const productPrice = useMemo(() => {
    if (!selectedPlan) return 0;

    if (selectedPlan.prices?.length) {
      const interval = modality ? 'month' : 'year';
      const price = selectedPlan.prices.find((p) => p.recurring === interval);
      setSelectedPlanPrice(price.id);
      return price.amount;
    }

    setSelectedPlanPrice(selectedPlan?.priceId);
    return selectedPlan?.amount;
  }, [selectedPlan, modality, setSelectedPlanPrice]);

  const onRenderDiscount = (coupon) => {
    if (!coupon) {
      setAmountDiscount(0);
      setTotal(productPrice);
    } else {
      if (coupon.amountOff) {
        setAmountDiscount(coupon.amountOff);
      }
      if (coupon.percentOff) {
        const amountDiscount = parseFloat((productPrice * coupon.percentOff) / 100);
        setAmountDiscount(amountDiscount);
      }
    }
  };

  const calculatePrice = useCallback(() => {
    if (selectedPlan && amountDiscount) {
      setTotal(productPrice - amountDiscount);
    } else {
      return setTotal(productPrice);
    }
  }, [selectedPlan, productPrice, amountDiscount]);

  useEffect(() => {
    calculatePrice();
  }, [selectedPlan, amountDiscount, calculatePrice]);

  useEffect(() => {
    if (defaultValue) {
      setModality(defaultValue);
    }
  }, [defaultValue]);

  const changeModality = (ev) => {
    const val = ev;
    setModality(val);
  };

  return (
    <>
      <Paper bgColor="paperSecondary" component={SpacingContainer} py={4} px={4}>
        {pendingPlan && <LoaderSmall size="1.5rem" />}
        {!pendingPlan && (
          <Grid container alignItems="flex-end" direction="row" spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" fontFamily="lexend" gutter="label">
                {selectedPlan?.name}
              </Typography>
              <Typography variant="h6" color="label" fontFamily="lexend">
                <AttachMoneyIcon />
                USD {formatNumber(productPrice)}/{modality ? 'monthly' : 'yearly'} payment plan
              </Typography>
            </Grid>
            {selectedPlan?.prices?.length && (
              <Grid item>
                <Switch
                  color="primary"
                  size="medium"
                  onChange={(ev) => changeModality(ev.target.checked)}
                  label={modality ? 'Monthly' : 'Yearly'}
                  checked={modality}
                  disabled={step === 'information'}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Paper>

      <Divider light spacing="3" />
      <PromoCode onRenderDiscount={onRenderDiscount} step={step} />
      <Divider light spacing="3" />

      {amountDiscount > 0 && (
        <>
          <Grid spacing={2} container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography weight="600" variant="body1" fontFamily="lexend">
                {trans('subtotal')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography weight="600" variant="h6" fontFamily="lexend">
                ${formatNumber(productPrice)}
              </Typography>
            </Grid>
          </Grid>
          <Grid spacing={2} container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography weight="600" variant="body1" fontFamily="lexend">
                {trans('discount')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography weight="600" variant="h6" fontFamily="lexend" color="secondary">
                - ${formatNumber(amountDiscount)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid spacing={2} container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography weight="600" variant="body1" fontFamily="lexend">
            {trans('total')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography weight="600" variant="h2" fontFamily="lexend">
            ${formatNumber(total)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(CurrentPlanSelect);
