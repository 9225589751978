import React, { useEffect, useRef } from 'react';
import Typography from '../../../../components/common/Typography';
import SignatureCanvas from 'react-signature-canvas';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function Signature(props) {
  const { error, name, value, label = null, className, onChange, ...rest } = props;

  const signatureRef = useRef(null);

  const clear = () => {
    signatureRef.current.clear();
    const event = {
      target: {
        name,
        value: null,
        type: 'signature',
      },
    };
    onChange(event);
  };

  useEffect(() => {
    if (value) {
      signatureRef.current.fromDataURL(`data:image/png;base64,${value}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <SignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{ width: 500, height: 175, style: { border: `solid 1px ${error ? 'red' : 'black'}` } }}
          onEnd={() => {
            const event = {
              target: {
                name,
                value: signatureRef.current
                  .getTrimmedCanvas()
                  .toDataURL('image/png')
                  .replace('data:image/png;base64,', ''),
                type: 'signature',
              },
            };
            onChange(event);
          }}
          {...rest}
        />
        {/* Changed button to anchor */}
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
        <a href="javascript:void(0)" onClick={clear} style={{ cursor: 'pointer', color: '#007BFF' }}>
          Clear
        </a>
      </div>
    </>
  );
}
