import * as Yup from 'yup';
import makeValidationSchema from '../../../../../hooks/makeValidationSchema';

export const initialValues = {
  tags: [],
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    tags: Yup.array(
      Yup.object().shape({
        id: Yup.string(),
        value: Yup.string(),
      })
    ).required(),
  })
);
