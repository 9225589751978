import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconLocation = (props) => {
  const { width = 22, height = 22, type = 'mui' } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      {type === 'mui' ? (
        <path d="M11 0c4.976 0 9 4.116 9 9.182 0 3.087-1.611 6.109-4.266 8.975-.91.981-1.88 1.875-2.853 2.667-.34.277-.657.522-.943.734l-.1.072-.275.196c-.34.232-.787.232-1.126 0l-.276-.196-.55-.414c-.158-.123-.322-.254-.492-.392-.972-.792-1.943-1.686-2.853-2.667C3.611 15.29 2 12.269 2 9.182 2 4.116 6.024 0 11 0zm0 2C7.14 2 4 5.21 4 9.182c0 2.48 1.389 5.084 3.734 7.616.84.907 1.744 1.74 2.647 2.475.158.128.31.25.455.362l.164.125.164-.125.455-.362c.903-.736 1.807-1.568 2.647-2.475C16.611 14.266 18 11.663 18 9.182 18 5.21 14.86 2 11 2zm0 4c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 2c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" />
      ) : (
        <>
          <path
            d="M9.99967 1.6665C8.24305 1.66642 6.55739 2.35964 5.30904 3.5955C4.0607 4.83136 3.35057 6.50997 3.33301 8.2665C3.33301 12.8332 9.20801 17.9165 9.45801 18.1332C9.60895 18.2623 9.80105 18.3332 9.99967 18.3332C10.1983 18.3332 10.3904 18.2623 10.5413 18.1332C10.833 17.9165 16.6663 12.8332 16.6663 8.2665C16.6488 6.50997 15.9386 4.83136 14.6903 3.5955C13.442 2.35964 11.7563 1.66642 9.99967 1.6665ZM9.99967 16.3748C8.60801 15.0498 4.99967 11.3748 4.99967 8.2665C4.99967 6.94042 5.52646 5.66865 6.46414 4.73097C7.40182 3.79329 8.67359 3.2665 9.99967 3.2665C11.3258 3.2665 12.5975 3.79329 13.5352 4.73097C14.4729 5.66865 14.9997 6.94042 14.9997 8.2665C14.9997 11.3498 11.3913 15.0498 9.99967 16.3748Z"
            fill="#888FA9"
          />
          <path
            d="M9.99968 5C9.42281 5 8.85891 5.17106 8.37926 5.49155C7.89962 5.81203 7.52578 6.26756 7.30503 6.80051C7.08427 7.33346 7.02651 7.9199 7.13905 8.48568C7.25159 9.05146 7.52938 9.57116 7.93728 9.97906C8.34518 10.387 8.86488 10.6648 9.43066 10.7773C9.99644 10.8898 10.5829 10.8321 11.1158 10.6113C11.6488 10.3906 12.1043 10.0167 12.4248 9.53708C12.7453 9.05744 12.9163 8.49353 12.9163 7.91667C12.9163 7.14312 12.6091 6.40125 12.0621 5.85427C11.5151 5.30729 10.7732 5 9.99968 5V5ZM9.99968 9.16667C9.75245 9.16667 9.51077 9.09336 9.30521 8.956C9.09965 8.81865 8.93944 8.62343 8.84483 8.39502C8.75022 8.16661 8.72546 7.91528 8.77369 7.6728C8.82193 7.43033 8.94098 7.2076 9.11579 7.03278C9.29061 6.85797 9.51334 6.73892 9.75581 6.69068C9.99829 6.64245 10.2496 6.66721 10.478 6.76182C10.7064 6.85643 10.9017 7.01664 11.039 7.2222C11.1764 7.42777 11.2497 7.66944 11.2497 7.91667C11.2497 8.24819 11.118 8.56613 10.8836 8.80055C10.6491 9.03497 10.3312 9.16667 9.99968 9.16667Z"
            fill="#888FA9"
          />
        </>
      )}
    </SvgIcon>
  );
};

IconLocation.propTypes = simpleIconPropsTypes;
