import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../../../../components/common/Typography';
import { Grid } from '@material-ui/core';
import {
  IconCheckList,
  IconCircleGray,
  IconCircleError,
  IconCircleCheck,
  IconRightArrowWhite,
  IconLeftArrowBlue,
} from '../../../../components/svg';
import { useTrans } from '../../../../services/i18n';
import { Button } from '../../../../components/common/Buttons';
import { capitalizeFirstLetterFirstWord } from '../../helpers';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.2rem',
    borderBottom: '1px solid #EAEBEF', // Adding bottom border
  },
  headerStep: {
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
  },
  stepText: {
    marginLeft: '0.3rem',
  },
  pill: {
    maxWidth: '2.8rem',
    height: '1.5rem',
    padding: '0.125rem 0.375rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6.25rem',
    background: 'rgba(149, 118, 255, 0.1)',
    opacity: '0px',
    fontFamily: 'Manrope',
    fontSize: '0.8125rem',
    fontWeight: 600,
    lineHeight: '1.11rem',
    textAlign: 'center',
    color: '#9576FF',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.8rem 0.8rem 0.5rem 0.8rem',
    borderTop: '1px solid #EAEBEF', // Adding top border
  },
  body: {
    marginTop: '0.5rem',
    maxHeight: '14rem',
    overflowY: 'scroll',
  },
  bodyContainer: {
    display: 'flex',
    padding: '0.2rem 0.5rem 0.3rem 1rem',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  statusBar: {
    width: '0.125rem',
    minWidth: '0.125rem',
    height: '2.25rem',
    gap: '0rem',
    borderRadius: '0.0625rem',
    opacity: '0rem',
    marginRight: '0.5rem',
  },
  bodyText: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: '2rem',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    maxHeight: '26rem',
    overflowY: 'scroll',
  },
});

export default function ProgressForm(props) {
  const {
    step,
    currentSection,
    currentSectionIndex,
    fields,
    showField,
    validateField,
    changeSection,
    saveAndResumeLater,
    onSubmit,
    partialSubmitting,
    sections,
    submitting,
    handleFieldClick,
    inputRefs,
  } = props;
  const { trans } = useTrans();
  const classes = useStyles();

  // States
  const [sectionFields, setSectionFields] = useState([]);
  const [percentage, setPercentage] = useState(0);
  // Store the refs of current page inputs to scroll to them when user clicks on "next step" and tehre is an error
  const currentInputFormRefs = useRef({});
  // Controls if go to error field
  const [isGoToErrorField, setIsGoToErrorField] = useState(false);

  /***
   * Set the fields that are shown in the current section
   */
  useEffect(() => {
    // Filter fields based on the current section and the showField condition
    const fieldsToShow = fields.filter((f) => f.section === currentSection && showField(f));
    // Initialize an array to hold fields with subfields or standalone fields
    let fieldsWithSubsection = [];
    // Iterate through the filtered fields
    fieldsToShow.forEach((element) => {
      // Skip elements of type 'section' or 'richtext'
      if (element.type !== 'section' && element.type !== 'richtext') {
        // If the element has subfields, add them to the array
        if (element.subfields.length > 0) {
          // fieldsWithSubsection.push(...element.subfields);
          element.subfields.forEach((subfield) => {
            // Change the label of the subfield
            // subfield.label = subfield.labelToShow;
            fieldsWithSubsection.push(subfield);
          });
        } else {
          // Otherwise, add the element itself
          fieldsWithSubsection.push(element);
        }
      }
    });
    // Set the % of progress
    getPercentageValue(fieldsWithSubsection);
    createRefs(fieldsWithSubsection);
    // Update the state with the processed fields
    setSectionFields(fieldsWithSubsection);
  }, [fields, currentSection, changeSection]); // Dependencies: runs when fields or currentSection changes

  /***
   * Create ref for each field that is in the current section in the form
   */
  useEffect(() => {
    inputRefs.current = fields.reduce((acc, field) => {
      acc[field.id] = React.createRef();
      // Check if the field has subfields
      if (field.subfields && field.subfields.length > 0) {
        // Create refs for each subfield
        field.subfields.forEach((subfield) => {
          acc[subfield.id] = React.createRef();
        });
      }
      return acc;
    }, {});
  }, []);

  // Function to introduce a delay
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  /***
   * Set the fields that are shown in the current section
   */
  useEffect(() => {
    if (isGoToErrorField === true) {
      setTimeout(async () => {
        const withError = sectionFields.find((sf) => sf.error);
        if (withError) {
          const element = currentInputFormRefs.current[withError.id + 'pf'];
          if (element && element.current) {
            element.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start',
            });
            console.log('before');
            await delay(1000);
            element.current?.click();
            console.log('after');
            // setTimeout(() => {
            //   element.current?.click();
            // }, 500);
          }
        }
        setIsGoToErrorField(false);
      }, 500);
    }
  }, [sectionFields, isGoToErrorField]); // Dependencies: runs when fields or currentSection changes

  /***
   * Create ref for each field in the current component
   */
  const createRefs = (fieldsWithSubsection) => {
    const arrayToReduce = [...fieldsWithSubsection];
    currentInputFormRefs.current = arrayToReduce.reduce((acc, field) => {
      acc[field.id + 'pf'] = React.createRef();
      return acc;
    }, {}); // Provide an empty object as the initial value
  };

  /***
   * Scroll to specific name input in the current component
   */
  const goToErrorField = useCallback(
    (action) => {
      action === 'next' && changeSection('next');
      action === 'prev' && changeSection('prev');
      action === 'onSubmit' && onSubmit();

      setTimeout(() => {
        setIsGoToErrorField(true);
      }, 500);
    },
    [sectionFields, currentInputFormRefs, changeSection, onSubmit]
  );

  /**
   * Calculates the percentage of fields without errors.
   * @param {Array} fieldsWithSubsection - Array of field objects.
   */
  const getPercentageValue = (fieldsWithSubsection) => {
    // Filter out fields that do not have errors
    const fieldsWithoutError = fieldsWithSubsection.filter((field) => {
      const { error } = field;
      return error === null || error === '';
    });

    // Calculate the percentage as an integer
    const percentage = Math.floor((fieldsWithoutError.length * 100) / fieldsWithSubsection.length);

    // Set the calculated percentage
    setPercentage(percentage);
  };

  /**
   * Sets the icon that matches the field error status.
   * @param {Object} field - The field object containing the error status.
   * @returns {JSX.Element} - The icon component corresponding to the error status.
   */
  const setIconField = (field) => {
    const { error } = field;

    if (error) {
      return <IconCircleError />;
    }

    if (error === null || error === '') {
      return <IconCircleCheck />;
    }

    return <IconCircleGray />;
  };

  /**
   * Sets the background color for the pill based on the field error status.
   * @param {Object} field - The field object containing the error status.
   * @returns {string} - The background color corresponding to the error status.
   */
  const setBackgroundColorPill = (field) => {
    const { error } = field;

    if (error) {
      return '#F32445'; // Error color
    }

    if (error === null || error === '') {
      return '#17B471'; // Success color
    }

    return '#B2B8CF'; // Default color
  };

  return (
    <>
      <Grid container className={classes.main}>
        {/* Init Header */}
        <Grid item xs={12} className={classes.headerContainer}>
          <div className={classes.headerStep}>
            <IconCheckList />
            <Typography weight="500" fontFamily="lexend" variant="body2" className={classes.stepText}>
              {`${trans('progress-of-step')} ${step}`}
            </Typography>
          </div>
          <div
            className={classes.pill}
            style={{ color: percentage === 100 && '#FFFFFF', backgroundColor: percentage === 100 && '#54B177' }}
          >
            {`${percentage}%`}
          </div>
        </Grid>
        {/* End Header */}
        {/* Init Body */}
        <Grid item xs={12} className={classes.body}>
          {sectionFields.map((sf) => {
            return (
              <div
                className={classes.bodyContainer}
                key={sf.id + 'pf'}
                id={sf.id + 'pf'}
                onClick={() => handleFieldClick(sf.id)}
                ref={currentInputFormRefs.current[sf.id + 'pf']}
              >
                <div className={classes.flexCenter}>
                  <div className={classes.statusBar} style={{ backgroundColor: setBackgroundColorPill(sf) }}></div>
                  <div className={classes.bodyText}>{sf.label}</div>
                </div>
                <div className={classes.flexCenter}>{setIconField(sf)}</div>
              </div>
            );
          })}
        </Grid>
        {/* End Body */}
        {/* Init Footer */}
        <Grid item xs={12} className={classes.footerContainer}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {/* It appears in all steps except in the first one */}
            {currentSectionIndex !== 0 && (
              <Button
                disabled={currentSectionIndex === 0 || partialSubmitting || submitting}
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => goToErrorField('prev')}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '15%',
                  borderRadius: '0.5rem',
                  marginRight: '0.5rem',
                  backgroundColor: 'rgba(36, 69, 167, 0.1)',
                }}
              >
                <IconLeftArrowBlue />
              </Button>
            )}
            {currentSectionIndex + 1 !== sections.length ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => {
                  //     changeSection('next');
                  goToErrorField('next');
                }}
                pending={partialSubmitting}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '0.5rem',
                  width: '100%',
                }}
              >
                {trans('next-step')} <IconRightArrowWhite />
              </Button>
            ) : (
              <Button
                // disabled={submitting}
                variant="contained"
                size="medium"
                color="success"
                onClick={() => {
                  // onSubmit();
                  goToErrorField('onSubmit');
                }}
                pending={submitting}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '0.5rem',
                  width: '100%',
                }}
              >
                {`${trans('complete')} & Submit`}
              </Button>
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              disabled={partialSubmitting}
              variant="text"
              size="medium"
              color="success"
              onClick={() => saveAndResumeLater()}
              pending={partialSubmitting}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '0.5rem',
                width: '100%',
                marginTop: '0.5rem',
              }}
            >
              <Typography style={{ color: '#4E5978' }} variant="subtitle1" weight="500">
                {trans('save-and-resume-later')}
              </Typography>
            </Button>
          </div>
          {/* </div> */}
        </Grid>
        {/* End Footer */}
      </Grid>
    </>
  );
}
