import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_EZ_SEARCH } from '../../../../constants/routes';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { useTrans } from '../../../../services/i18n';
import { emptyArray } from '../../../../utils/arrayUtils';
import { BackDrop, Paper, SpacingContainer } from '../../../common/Containers';
import Loader from '../../../common/Loader';
import Typography from '../../../common/Typography';
import SimpleSearchField from '../../../generics/SearchTopPanel/SearchField/SimpleSearchField';
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';
import useStyles from '../styles';
import connect from './connect';

function EZSearch({ text, pending, recentSearches, onChangeText, fetchRecentSearchs, setDrawerClose = () => {} }) {
  const styles = useStyles();
  const { trans } = useTrans();
  const { push, location } = useHistory();
  const [focused, onFocus, onBlur] = useBooleanHandlers();
  const hasRecentSearches = !emptyArray(recentSearches);
  const isSmallScreen = useMediaQueryDown('sm');

  const handleChange = React.useCallback(
    (value) => {
      if (isSmallScreen && value) setDrawerClose();
      onChangeText && onChangeText(value);
      onBlur();
      if (value && location.pathname !== ROUTE_EZ_SEARCH.path) {
        push(ROUTE_EZ_SEARCH.path);
      }
    },
    [location.pathname, onChangeText, push, onBlur]
  );

  useEffect(() => {
    fetchRecentSearchs();
  }, []);

  return (
    <>
      <SimpleSearchField
        value={text}
        onValueChange={handleChange}
        name="ezSearch"
        size="medium"
        bgColor="default"
        placeholder={trans('ez-search')}
        freeSolo
        onFocus={onFocus}
      />

      <BackDrop invisible open={focused} onClick={onBlur} />

      {focused && hasRecentSearches && !pending && (
        <Paper
          style={{ zIndex: 1001 }}
          className={styles.recentSearches}
          component={SpacingContainer}
          px={1}
          py={1}
          square
        >
          <Loader open={pending} size={30} />

          {hasRecentSearches && (
            <List>
              <ListItem>
                <Typography variant="body2" weight={600}>
                  {trans('RecentSearches')}
                </Typography>
              </ListItem>

              {recentSearches.map((item, ind) => (
                <ListItem key={ind}>
                  <Typography
                    onClick={onChangeText ? handleChange.bind(null, item.search) : undefined}
                    variant="body2"
                    color="primary"
                    className="pointer"
                  >
                    {item.search}
                  </Typography>
                </ListItem>
              ))}
            </List>
          )}
        </Paper>
      )}
    </>
  );
}

export default connect(EZSearch);
