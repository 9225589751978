import { useEffect, useState } from 'react';
import { useTrans } from '../../../../services/i18n';
import connect from '../../connect';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router-dom';
import { ROUTE_PLANS_PRIVATE } from '../../../../constants';

const ButtonUpdateSubscription = (props) => {
  const { subscription } = props;

  const { trans } = useTrans();
  const history = useHistory();

  const navigateToPlans = () => {
    history.push(ROUTE_PLANS_PRIVATE.path);
  };

  return (
    <>
      {subscription && subscription.status === 'active' && !subscription.cancel && (
        <>
          <div
            style={{
              cursor: 'pointer',
              color: '#2445A7',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '13px',
              fontWeight: 'bold',
            }}
            onClick={navigateToPlans}
            variant="flat"
            color="primary"
            fullWidth
          >
            <span>{trans('update-subscription')}</span>

            <ArrowForwardIosIcon style={{ fontSize: '13px' }} />
          </div>
        </>
      )}
    </>
  );
};

export default connect(ButtonUpdateSubscription);
