import React from 'react';

function SimpleForm({ children, onSubmit, ...props }) {
  const onSubmitHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);

      return onSubmit && onSubmit(Object.fromEntries(formData), formData);
    },
    [onSubmit]
  );

  return (
    <form {...props} action="#" onSubmit={onSubmitHandler}>
      {children}
    </form>
  );
}

export default SimpleForm;
