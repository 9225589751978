import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

export default function InfoContent({ children }) {
  return <div className={useStyles().content}>{children}</div>;
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 50,
    paddingTop: 35,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10,
    },
  },
}));
