import { connect } from 'react-redux';
import { actions } from '../../../../store/pursuits';
import { subscriptionActions } from '../../../../store/subscription';

function mapStateToProps(state) {
  return {
    userPursuits: state.pursuits ? state.pursuits.userPursuits : [],
    subscription: state.subscription,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPursuits: (params) => dispatch(actions.getPursuits(params)),
    getPointOfContactsFCO: (params) => dispatch(actions.getPointOfContactsFCO(params)),
    setOpenModalPremium: (payload) => dispatch(subscriptionActions.setOpenModalPremium(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
