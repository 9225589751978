import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_OPEN_PURSUITS, ROUTE_TEAMING_OPPORTUNITIES_DETAILS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const OpenPursuits = React.lazy(() => import('./List'));
const TeamingOpportunitiesDetail = React.lazy(() => import('../TeamingOpportunities/Detail'));

export default function OpenPursuitsWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_OPEN_PURSUITS.path}>
          <OpenPursuits />
        </Route>
        <Route exact path={ROUTE_TEAMING_OPPORTUNITIES_DETAILS.path}>
          <TeamingOpportunitiesDetail />
        </Route>
        <Redirect exact path="/*" to={ROUTE_OPEN_PURSUITS.path} />
      </Switch>
    </Suspense>
  );
}
