export const SUBSCRIPTION_STATUS = {
  SUBSCRIBED: 'subscribed',
  EXPIRED: 'expired',
  NOT_SUBSCRIBED: 'not-subscribed',
  ACTIVE: 'active',
};

export const PIPELINE_DETAILS_FIXED_STAGES = ['triage'];

export const USER_SETTINGS_FEDERAL_AGENT_WATCH = 'FEDERAL_AGENT_WATCH';
export const USER_SETTINGS_FEDERAL_AGENT_CONTACTED = 'FEDERAL_AGENT_CONTACTED';

export const FEDERAL_GRANT_OPPORTUNITY = 'fgo';
export const FEDERAL_CONTRACT_OPPORTUNITY = 'fco';

export const USER_SETTINGS_TYPE = [USER_SETTINGS_FEDERAL_AGENT_WATCH, USER_SETTINGS_FEDERAL_AGENT_CONTACTED];

export const DATE_FILTER_OPTIONS_REPORTS = [
  {
    i18nKey: 'last-month',
    i18nValues: { num: 6 },
    value: '6:m',
  },
  {
    i18nKey: 'last-year',
    value: '12:m',
  },
  {
    i18nKey: 'last-years',
    i18nValues: { num: 3 },
    value: 3,
  },
  {
    i18nKey: 'last-years',
    i18nValues: { num: 5 },
    value: 5,
  },
  {
    i18nKey: 'last-years',
    i18nValues: { num: 10 },
    value: 10,
  },
];

export const ROUTE_SAM_OPP_SOLICITATION_NUMBER =
  'https://sam.gov/search/?index=opp&sort=-modifiedDate&page=1&pageSize=25&sfm%5Bstatus%5D%5Bis_active%5D=true&sfm%5BsimpleSearch%5D%5BkeywordRadio%5D=ALL&sfm%5BsimpleSearch%5D%5BkeywordTags%5D%5B0%5D%5Bkey%5D=7fcil3030084b&sfm%5BsimpleSearch%5D%5BkeywordTags%5D%5B0%5D%5Bvalue%5D=';

export const CONTRACT_OPPORTUNITIES_TYPES_FOR_QUOTES_REQUESTS = [
  'Combined Synopsis/Solicitation',
  'Modification/Amendment',
  'Presolicitation',
  'Solicitation',
];
export const TEMPORAL_PASSWORD = '12TemporalPassword,.T';

export const COMPONENT_FEATURES = {
  SAVED_SEARCHES: 'savedSearches',
  USERS: 'users',
  SAM_MAINTENANCE_UPDATE: 'samMaintenanceUpdate',
  COURSES: 'courses',
  SAP_PROGRAM: 'sapProgram',
  SET_ASIDE_QUALIFICATION: 'setAsideQualification',
  ASSIGNED_CASE_MANAGER: 'assignedCaseManager',
  EXPORTING: 'exporting',
  PURSUITS_PIPELINES_TASKS: 'pursuitsPipelinesTasks',
  AGENT_FINDER: 'agentFinder',
  CONTRACTS_WON_VS_LOST: 'contractsWonVsLost',
  OPPORTUNITIES_AND_ATTACHMENTS: 'opportunitiesAndAttachments',
  REQUEST_FOR_QUOTE: 'requestForQuote',
  CALENDAR_SYNC: 'calendarSync',
  ORGANIZATION_SAVED_SEARCHES: 'organizationSavedSearches',
  PERSONAL_TASKS: 'personalTasks',
};

export const OFFICIAL_CERT_LIST = [
  {
    name: 'Women-Owned Small Business (WOSB)/Women Business Enterprise (WBE)',
    value: 'WOSB/WBE',
  },
  {
    name: 'Economical Disadvantaged Women-Owned Small Business (EDWOSB)',
    value: 'EDWOSB',
  },
  {
    name: 'Veteran Owned Small Business (VOSB)/Veteran Business Enterprise (VBE)',
    value: 'VOSB/VBE',
  },
  {
    name: 'Service Disabled Veteran Owned Small Business (SDVOSB)',
    value: 'SDVOSB',
  },
  {
    name: '8(a)/Minority Business Enterprise (MBE)',
    value: 'a/MBE',
  },
];
