import LazyLoad from '../../services/lazyLoad';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';

const LazyComponent = LazyLoad({ loader: () => import('./Dashboard') });

export default function Dashboard() {
  return (
    <SpacingContainerBase>
      <LazyComponent />
    </SpacingContainerBase>
  );
}
