import { connect } from 'react-redux';
import { actions } from '../../../store/vendorsManagement';

function mapStateToProps(state) {
  return {
    filters: state.vendorsManagement.filters,
    items: state.vendorsManagement.items,
    count: state.vendorsManagement.count,
    isAllChecked: state.vendorsManagement.isAllChecked,
    isSomeChecked: state.vendorsManagement.isSomeChecked,
    currentView: state.vendorsManagement.currentView,
    openAddVendorModal: state.vendorsManagement.openAddVendorModal,
    openAddVendorManuallyModal: state.vendorsManagement.openAddVendorManuallyModal,
    dashboardStatistics: state.vendorsManagement.dashboardStatistics,
    openAddTagsModal: state.vendorsManagement.openAddTagsModal,
    openImportVendorsCsvModal: state.vendorsManagement.openImportVendorsCsvModal,
    importingCsv: state.vendorsManagement.importingCsv,
    processCsv: state.vendorsManagement.processCsv,
    processValuesCsv: state.vendorsManagement.processValuesCsv,
    tableColumns: state.vendorsManagement.tableColumns,
    isAllColumnsChecked: state.vendorsManagement.isAllColumnsChecked,
    isSomeColumnChecked: state.vendorsManagement.isSomeColumnChecked,
    vendorsTags: state.vendorsManagement.vendorsTags,
    pending: state.vendorsManagement.pending,
    filterDefault: state.vendorsManagement.filterDefault,
    editVendorManagement: state.vendorsManagement.editVendorManagement,
    textSearch: state.vendorsManagement.textSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeFilters: (params) => dispatch(actions.changeFilters(params)),
    getList: (params) => dispatch(actions.getList(params)),
    checkAll: (params) => dispatch(actions.checkAll(params)),
    uncheckAll: (params) => dispatch(actions.uncheckAll(params)),
    itemCheck: (params) => dispatch(actions.itemCheck(params)),
    archiveSelected: (params) => dispatch(actions.archiveSelected(params)),
    changeCurrentView: (params) => dispatch(actions.changeCurrentView(params)),
    openAddVendor: (params) => dispatch(actions.openAddVendor(params)),
    openAddVendorManually: (params) => dispatch(actions.openAddVendorManually(params)),
    checkVendor: (params) => dispatch(actions.checkVendor(params)),
    addVendor: (params) => dispatch(actions.addVendor(params)),
    getDashboard: (params) => dispatch(actions.getDashboard(params)),
    openAddTags: (params) => dispatch(actions.openAddTags(params)),
    openImportVendors: (params) => dispatch(actions.openImportVendors(params)),
    addVendorTags: (params) => dispatch(actions.addVendorTags(params)),
    getVendorTags: (params) => dispatch(actions.getVendorTags(params)),
    changeImportingCsv: (params) => dispatch(actions.changeImportingCsv(params)),
    changeProcessCsv: (params) => dispatch(actions.changeProcessCsv(params)),
    changeProcessValuesCsv: (params) => dispatch(actions.changeProcessValuesCsv(params)),
    checkAllColumns: (params) => dispatch(actions.checkAllColumns(params)),
    uncheckAllColumns: (params) => dispatch(actions.uncheckAllColumns(params)),
    columnCheck: (params) => dispatch(actions.columnCheck(params)),
    apiColumnCheck: (params) => dispatch(actions.apiColumnCheck(params)),
    changeFilterDefault: (params) => dispatch(actions.changeFilterDefault(params)),
    changeEditVendorManagement: (params) => dispatch(actions.changeEditVendorManagement(params)),
    addVendorsFromImport: (params) => {
      return dispatch(actions.addVendorsFromImport(params)).then(() => {
        dispatch(actions.getList(params));
      });
    },
    onClearSearch: (params) => dispatch(actions.onClearSearch(params)),
    deleteVendorManagement: (params) => dispatch(actions.deleteVendorManagement(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
