import { useEffect, useState } from 'react';
import { formatNumber } from '../../../utils/numberUtils';

const SelectedPlan = ({ plan, select }) => {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setPrice(plan[0].prices.find((p) => p.recurring.interval === select));
  }, [select, plan]);

  return <>{'$' + formatNumber(price?.unit_amount) + '.00'}</>;
};

export default SelectedPlan;
