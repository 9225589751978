import { useDrop } from 'react-dnd';

const collect = (monitor) => ({ dragOver: monitor.isOver() && monitor.canDrop() });

export default function useDropTarget(params, deps) {
  const [collectedProps, dropRef] = useDrop(
    {
      collect,
      ...params,
    },
    deps
  );

  return { ...collectedProps, dropRef };
}
