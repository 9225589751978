import dayjs from 'dayjs';
import {
  IS_AFTER,
  IS_BEFORE,
  IS_BETWEEN,
  IS_GREATER,
  IS_LESS,
  WITHIN_THE_LAST,
  WITHIN_THE_NEXT,
} from '../constants/filter';
import { isNull } from './helpers';

export const isFiltersSet = (filters) => filters && Object.keys(filters).length > 0;

export const filterEmptyEntries = (obj) =>
  !obj ? [] : Object.entries(obj).filter(([_key, value]) => (Array.isArray(value) ? value.length > 0 : !!value));

export const filterEmptyValues = (obj) => Object.fromEntries(filterEmptyEntries(obj));

export function getFilterParams(filters = {}) {
  return filterEmptyEntries(filters).reduce((acc, [key, item]) => {
    const { type, value, unitCount, dateUnit } = formatFilter(item);
    const filter = { value, type };
    if (type === WITHIN_THE_LAST || type === WITHIN_THE_NEXT) {
      filter.unitCount = unitCount;
      filter.dateUnit = dateUnit;
    }
    return { ...acc, [key]: filter };
  }, {});
}

export function formatFilter(item) {
  let value;
  const { type, from, to, data, unitCount, dateUnit, value: formatedValue } = item;

  if (type === IS_BETWEEN && !isNull(to) && !isNull(from)) value = [from, to];
  if (type === IS_AFTER && !isNull(from)) value = from;
  if ((type === IS_GREATER || type === IS_LESS) && !isNull(data)) value = data;
  if (type === IS_BEFORE && !isNull(to)) value = to;

  if (type === WITHIN_THE_LAST || type === WITHIN_THE_NEXT) {
    const startDate =
      type === WITHIN_THE_NEXT ? dayjs.utc().toISOString() : dayjs.utc().subtract(unitCount, dateUnit).toISOString();
    const endDate =
      type === WITHIN_THE_NEXT ? dayjs.utc().add(unitCount, dateUnit).toISOString() : dayjs.utc().toISOString();

    value = [startDate, endDate];
  }

  if (!value && data) value = data;
  else if (!value && formatedValue) value = formatedValue;

  return { ...item, value };
}
