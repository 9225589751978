import useDragTarget from './useDragTarget';
import useDropTarget from './useDropTarget';

export default function useDragAndDropTarget(dragParams, dropParams) {
  const { dragRef, ...restDrag } = useDragTarget(dragParams);
  const { dropRef, ...restDrop } = useDropTarget(dropParams);

  return {
    dragAndDropRef: (ref) => dragRef(dropRef(ref)),
    ...restDrag,
    ...restDrop,
  };
}
