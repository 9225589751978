const ROUTES = {
  contract: (id) => `/contract-opportunities/${id}`,
  grant: (id) => `/grant-opportunities/${id}`,
  award: (id) => `/contract-awards/${id}?i=${id}`,
  nsn: (id) => `/nsn-opportunities/${id}`,
};

/**
 *
 * @param {opportunityId} id
 * @param {ROUTES} opportunityType
 */
export function getOpportunityRoute(id, opportunityType) {
  const route = ROUTES[opportunityType];
  if (!route) return null;
  return route(id);
}
