import colors from '../pallete';

const MuiTypography = {
  colorSecondary: {
    color: colors.secondary.dark,
  },
  noWrap: { display: 'block' },
};

export default MuiTypography;
