const typography = {
  htmlFontSize: 16,
  fontSize: 14,
  fontWeightExtraLight: 200,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  fontFamily: [
    '"Manrope"',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1Big: {
    fontSize: '5rem',
  },
  h1Medium: {
    fontSize: '2.5rem',
  },
  h1: {
    fontSize: '2rem',
  },
  h2: {
    fontSize: '1.75rem',
  },
  h3: {
    fontSize: '1.5rem',
  },
  h4: {
    fontSize: '1.3125rem',
  },
  h5: {
    fontSize: '1.1875rem',
  },
  h6: {
    fontSize: '0.9375rem',
  },
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.875rem',
  },
  subtitle1: {
    fontSize: '0.813rem',
  },
  button: {
    fontSize: '0.8125rem',
    textTransform: 'none',
    lineHeight: 'normal',
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: 'normal',
  },
  overline: {
    fontSize: '0.68rem',
    letterSpacing: 1,
  },
  keyword: {
    fontSize: '0.75rem',
    lineHeight: 'normal',
    fontWeight: 600,
  },
};

export default typography;
