import React from 'react';
import { SvgIcon } from '../../../../common/SvgIcon';

function ExpiredIcon({ width = 48, height = 48 }) {
  return (
    <SvgIcon width={width} height={height}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4Z"
          stroke="#F32445"
          stroke-width="2"
          stroke-linecap="round"
          stroke-dasharray="0.5 3.5"
        />
        <path d="M18 24L26 24L26 16" stroke="#F32445" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </SvgIcon>
  );
}

export default ExpiredIcon;
