import { connect } from 'react-redux';
import { actions } from '../../../../../store/pipelinesDetails';

function mapStateToProps(state, { item }) {
  return {
    pursuits: state.pipelineDetails.pursuitsByStage[item?.id] ?? [],
    settings: state.orgSettings,
  };
}

function mapDispatchToProps(dispatch) {
  const editStage = async (params) => {
    await dispatch(actions.editStage(params));
  };

  const addStages = async (params) => {
    await dispatch(actions.addPipelineStage(params));
  };

  const deletePursuit = async (params) => {
    await dispatch(actions.deletePursuit(params));
  };

  const changePursuitStatus = async (params) => {
    await dispatch(actions.changePursuitStatus(params));
  };

  const deleteStage = async (params) => {
    await dispatch(actions.deleteStage(params));
  };

  const editPursuit = async (params) => {
    await dispatch(actions.editPursuit(params));
  };

  return {
    editStage,
    addStages,
    deletePursuit,
    changePursuitStatus,
    deleteStage,
    editPursuit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
