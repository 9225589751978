import Grid from '@material-ui/core/Grid';
import React from 'react';
import { emptyArray } from '../../../../utils/arrayUtils';
import ProgressesLineItem from './Item';

const colorsMap = ['success', 'error', 'primary'];

export default function ProgressesLine({ values, height = 30 }) {
  const summ = !emptyArray(values) ? values.reduce((acc, value) => acc + (Number(value) || 0), 0) : 0;

  return summ <= 0 ? null : (
    <Grid container wrap="wrap" alignItems="center">
      {values?.map((value, ind) =>
        value > 0 ? (
          <ProgressesLineItem
            key={ind}
            value={((Number(value) || 0) * 100) / summ}
            color={colorsMap[ind % colorsMap.length]}
            height={height}
          />
        ) : null
      )}
    </Grid>
  );
}
