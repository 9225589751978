import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { SCOPE_NOTIFICATIONS } from '../../../../constants';
import { AddToPipelineConsumer, AddToPipelineProvider } from '../../../../contexts/AddToPipelineContext';
import useQueryCallbacks from '../../../../hooks/query/useQueryCallbacks';
import SpacingContainer from '../../../common/Containers/Base/SpacingContainer';
import { DrawerDialog } from '../../../common/Dialogs/DrawerDialog';
import { IconNotifications } from '../../../svg';
import connect from './connect';
import Notifications from './Notifications';
import SubscribedOnly from '../../../common/Subscription/SubscribedOnly';
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';

const QUERIES = ['ntf', 'arch'];

const NotificationsInfo = ({ onInit, notifications, getUnread, color }) => {
  const isExtraSmallScreen = useMediaQueryDown('xs');
  const { query, setQueryFromHref, deleteQuery } = useQueryCallbacks();
  const open = query.ntf === 'y';
  const isArchive = query.arch === 'Y';

  React.useEffect(() => {
    if (!query.ntf) return;

    onInit && onInit({ isArchive });
  }, [onInit, query.ntf, getUnread, isArchive]);

  React.useEffect(() => {
    getUnread();
  }, []);

  return (
    <SubscribedOnly handled={true} fixed={true}>
      <SpacingContainer px={isExtraSmallScreen ? 0 : 1} py={0}>
        <IconButton
          aria-label="notifications"
          disableRipple
          color="inherit"
          onClick={setQueryFromHref}
          data-href="ntf=y"
          style={{ padding: isExtraSmallScreen ? 6 : 12 }}
        >
          <Badge badgeContent={notifications.unReadCount} color="secondary">
            <IconNotifications />
          </Badge>
        </IconButton>

        <DrawerDialog open={open} maxWidth="100%" transitionDuration={400} anchor="right">
          <AddToPipelineProvider scope={SCOPE_NOTIFICATIONS}>
            <Notifications onClose={deleteQuery(QUERIES)} scope={SCOPE_NOTIFICATIONS} isArchive={isArchive} />
            <AddToPipelineConsumer />
          </AddToPipelineProvider>
        </DrawerDialog>
      </SpacingContainer>
    </SubscribedOnly>
  );
};

export default connect(NotificationsInfo);
