import React from 'react';
import Typography from '../../../../../components/common/Typography';
import styles from './styles.module.css';
import connect from '../../connect';
import { useTrans } from '../../../../../services/i18n';

const CardProcessCsv = ({ processValuesCsv }) => {
  const { trans } = useTrans();

  return (
    <div className={styles.card}>
      <Typography className={styles.text} variant="h6" gutter="label">
        {trans('total-results')} <span>{processValuesCsv.total}</span>
      </Typography>
      <div className={styles.line}>
        <Typography className={styles.lineText}>{trans('new-registered-vendors')}</Typography>
        <Typography className={styles.lineNumber} color="success">
          {processValuesCsv.newsRegistered.length}
        </Typography>
      </div>
      <div className={styles.line}>
        <Typography className={styles.lineText}>{trans('new-not-registered-vendors')}</Typography>
        <Typography className={styles.lineNumber} color="success">
          {processValuesCsv.notNewRegistered.length}
        </Typography>
      </div>
      <div className={styles.line}>
        <Typography className={styles.lineText}>
          {trans('duplicates-found')} <span className={styles.textMuted}>({trans('same-duns-code')})</span>
        </Typography>
        <Typography className={styles.lineNumber} color="warning">
          {processValuesCsv.duplicates}
        </Typography>
      </div>
      <div className={styles.line}>
        <Typography className={styles.lineText}>
          {trans('issues')} <span className={styles.textMuted}>({trans('vendors-csv-issues')})</span>
        </Typography>
        <Typography className={styles.lineNumber} color="secondary">
          {processValuesCsv.issues}
        </Typography>
      </div>
    </div>
  );
};

export default connect(CardProcessCsv);
