import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCircleError = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" noFill={true}>
      <rect width="24" height="24" rx="12" fill="#F32445" fill-opacity="0.1" />
      <path
        d="M15.295 8.705C14.9056 8.31564 14.2744 8.31564 13.885 8.705L12 10.59L10.115 8.705C9.72564 8.31564 9.09436 8.31564 8.705 8.705C8.31564 9.09436 8.31564 9.72564 8.705 10.115L10.59 12L8.705 13.885C8.31564 14.2744 8.31564 14.9056 8.705 15.295C9.09436 15.6844 9.72564 15.6844 10.115 15.295L12 13.41L13.885 15.295C14.2744 15.6844 14.9056 15.6844 15.295 15.295C15.6844 14.9056 15.6844 14.2744 15.295 13.885L13.41 12L15.295 10.115C15.6844 9.72564 15.6844 9.09436 15.295 8.705Z"
        fill="#F32445"
      />
    </SvgIcon>
  );
};

IconCircleError.propTypes = simpleIconPropsTypes;
