import React, { useEffect, useState } from 'react';
import { useTrans } from '../../../../services/i18n';
import { CheckBox, Switch, TextField } from '../../../../components/common/Inputs';
import { Grid, InputAdornment } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import { useApplication } from '../../../../services/application';
import { schemaToInitialState } from '../../../../utils/validationUtils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import 'react-phone-number-input/style.css';
import { Divider } from '../../../../components/common/Divider';
import { Button } from '../../../../components/common/Buttons';
import { ReactComponent as ArrowBackIcon } from '../../icons/arrow-left.svg';
import Typography from '../../../../components/common/Typography';
import { TagsAutocomplete } from '../../../../components/autocompletes/TagsAutocomplete';
import FileUploadModal from '../FileUploadModal';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import OfficialCertifications from '../CertificationsModal/OfficialCertifications';
import { API } from '../../../../constants';
import { Chip } from '../../../../components/common/Chip';
import QualityStandards from '../CertificationsModal/QualityStandards';
import GeneralCertifications from '../CertificationsModal/GeneralCertifications';
import { useScheme } from './formScheme';

const useStyles = makeStyles((theme) => ({
  containerCard: {
    display: 'flex',
    padding: '1.5rem 2.5rem',
    gap: '1.5rem',
  },
  centerItems: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButton: {
    color: theme.palette.gray.disabled,
    fontSize: 18,
    fontFamily: 'Manrope',
    fontWeight: '500',
  },
  marginX: {
    marginLeft: '2.5rem',
    marginRight: '2.5rem',
  },
  darkGrayLabel: {
    color: theme.palette.gray.main,
    fontFamily: 'lexend',
  },
  itemBlock: {
    padding: '0 !important',
  },
  spacing: {
    gap: '0.7rem',
  },
  fontInputs: {
    fontSize: '10px !important',
  },
  currencyInput: {
    marginRight: '0.7rem',
    color: theme.palette.gray.disabled,
    fontSize: 18,
    fontWeight: 600,
  },
  marginBottom: {
    marginBottom: '0.3rem',
  },
  tag: {
    height: '1.8rem',
    gap: '.1rem',
    backgroundColor: '#FFFFFF',
    marginBottom: '0.25rem',
    marginTop: '0.25rem',
    '& > [class*="label"]': {
      fontWeight: 'normal',
      fontSize: '13px',
      backgroundColor: '#F1F3F9',
      borderRadius: '0.125rem',
      padding: '0rem 0.75rem',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    '& > [class*="deleteIcon"]': {
      margin: '0 10px 0 0',
      backgroundColor: '#F1F3F9',
      borderRadius: '0.125rem',
      height: '100%',
      fill: '#222',
      cursor: 'pointer',
      width: '1rem',
    },
  },
  containerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.3rem 2.3rem 1.87rem 2.3rem',
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cancelNextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function CompanyInformation({ nextStep, onCompletionChange, onCancel, data, setData, isEditMode }) {
  const { trans } = useTrans();
  const [openFileModal, onOpenFileModal, onCloseFileModal] = useBooleanHandlers();
  const [openCertModal, onOpenCertModal, onCloseCertModal] = useBooleanHandlers();
  const [openQualityModal, onOpenQualityModal, onCloseQualityModal] = useBooleanHandlers();
  const [openGeneralCertModal, onOpenGeneralCertModal, onCloseGeneralCertModal] = useBooleanHandlers();
  const schema = useScheme();
  const [statementFile, setStatementFile] = useState();
  const [states, setStates] = useState([]);
  const styles = useStyles();
  const [officialCert, setOfficialCert] = useState([]);
  const [qualityCert, setQualityCert] = useState([]);
  const [generalCert, setGeneralCert] = useState([]);
  const application = useApplication();

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    const payload = await application.call(API.FEDERAL_AGENTS.GET_STATES);
    setStates([{ id: 'Federal', value: 'Federal' }, ...payload]);
  };

  useEffect(() => {
    setValues({
      ...values,
      keywords: [],
    });
  }, [setValues]);

  /***
   * Go to the next page
   */
  const handleNextClick = () => {
    onCompletionChange(true);
    nextStep();
  };

  // Form set up
  const { setValues, getFieldProps, handleSubmit, values, setFieldValue, errors } = useForm(
    schema,
    schemaToInitialState(schema),
    handleNextClick,
    {
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
    }
  );

  /***
   * Store in state when formik values change
   */
  useEffect(() => {
    storeInState();
  }, [values, setValues, officialCert, qualityCert, generalCert, statementFile, setStatementFile]);

  /***
   * Set values in formik data
   */
  useEffect(() => {
    const {
      budgetFrom,
      budgetTo,
      websiteUrl,
      linkedInUrl,
      consPerContract,
      consAggregate,
      servPerContract,
      servAggregate,
      intPrimeContractor,
      intSubContractor,
      publicVisibility,
      keywords,
      capabilityNarrative,
      specialEquipment,
      capabilityStatements,
      officialCertifications,
      generalCertifications,
      qualityAssurance,
    } = data || {};

    setValues({
      budget: {
        from: budgetFrom,
        to: budgetTo,
      },
      websiteUrl,
      linkedInUrl,
      consPerContract,
      consAggregate,
      servPerContract,
      servAggregate,
      intPrimeContractor,
      intSubContractor,
      publicVisibility,
      keywords: keywords ? keywords : [],
      capabilityNarrative,
      specialEquipment,
    });
    setStatementFile(capabilityStatements && capabilityStatements.length > 0 ? capabilityStatements : null);
    setOfficialCert(officialCertifications || []);
    setGeneralCert(generalCertifications || []);
    setQualityCert(qualityAssurance ? qualityAssurance : []);
  }, []);

  const getFileName = (file) => {
    if (Array.isArray(file) && typeof file[0] === 'string') {
      return file[0].split('/').pop();
    }
    if (typeof file === 'object' && file !== null) {
      return `${file.name}${file.ext}`;
    }
    return null;
  };

  /***
   * In charge of store data on state
   */
  const storeInState = () => {
    setData((prev) => ({
      ...prev,
      budgetFrom: values.budget?.from,
      budgetTo: values.budget?.to,
      websiteUrl: values.websiteUrl,
      linkedInUrl: values.linkedInUrl,
      consPerContract: values.consPerContract,
      consAggregate: values.consAggregate,
      servPerContract: values.servPerContract,
      servAggregate: values.servAggregate,
      intPrimeContractor: values.intPrimeContractor,
      intSubContractor: values.intSubContractor,
      publicVisibility: values.publicVisibility,
      keywords: values.keywords,
      capabilityNarrative: values.capabilityNarrative,
      specialEquipment: values.specialEquipment,
      capabilityStatements: statementFile,
      officialCertifications: officialCert,
      generalCertifications: generalCert,
      qualityAssurance: qualityCert,
    }));
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} className={`${styles.containerCard} ${styles.centerItems}`}>
        <Typography weight="700" variant="h4">
          {trans('teaming-organization-form-step-1')}
        </Typography>
      </Grid>
      <Divider light spacing={1} className={styles.marginX} />
      <Grid container spacing={3} className={styles.containerCard}>
        <Grid item xs={12} className={styles.itemBlock}>
          <Typography weight="500" variant="body2" className={styles.darkGrayLabel}>
            {trans('company-interests')}
          </Typography>
          <CheckBox
            color="secondary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            {...getFieldProps('intPrimeContractor')}
            onChange={(e) => {
              setFieldValue('intPrimeContractor', !values.intPrimeContractor);
            }}
            checked={!!values.intPrimeContractor}
          />
          {trans('prime-contractor')}
          <CheckBox
            color="secondary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            style={{ marginLeft: '1.3rem' }}
            {...getFieldProps('intSubContractor')}
            onChange={(e) => {
              setFieldValue('intSubContractor', !values.intSubContractor);
            }}
            checked={!!values.intSubContractor}
          />
          {trans('subcontractor')}
        </Grid>
        <Grid item xs={12} className={styles.itemBlock}>
          <Typography weight="500" variant="body2" className={styles.darkGrayLabel}>
            {trans('public-profile-visibility')}
          </Typography>
          <Switch
            checked={!!values.publicVisibility}
            {...getFieldProps('publicVisibility')}
            onChange={(e) => {
              setFieldValue('publicVisibility', !values.publicVisibility);
            }}
          />
        </Grid>
        <Grid item xs={12} className={styles.itemBlock}>
          <TagsAutocomplete
            label={trans('company-keywords')}
            placeholder={trans('company-expertise')}
            multi
            {...getFieldProps('keywords')}
            getTags={true}
            maxTagTextLength={values?.keywords?.length === 20 ? 0 : 25}
          />
        </Grid>

        <Grid item xs={12} className={styles.itemBlock}>
          <Typography weight="500" variant="body2" className={styles.darkGrayLabel}>
            {trans('org-capability')}
          </Typography>
          {statementFile && (
            <Chip className={styles.tag} label={getFileName(statementFile)} onDelete={() => setStatementFile(null)} />
          )}
          <Button
            onClick={onOpenFileModal}
            color="primary"
            variant="contained"
            size="small"
            style={{ marginTop: '0.3rem' }}
          >
            <Typography weight="700" variant="caption">
              {statementFile ? trans('re-upload-capability-statement') : trans('upload-capability-statement')}
            </Typography>
          </Button>
          <FileUploadModal
            open={openFileModal}
            onClose={onCloseFileModal}
            multiple={false}
            file={statementFile}
            setFile={setStatementFile}
          />
        </Grid>
        <Grid item xs={12} className={styles.itemBlock}>
          <TextField
            label={trans('capabilities-narrative')}
            placeholder={trans('capabilities-narrative-ph')}
            multiline
            minRows={4}
            maxRows={10}
            maxLength={2000}
            {...getFieldProps('capabilityNarrative')}
            inputProps={{ fontSize: '10px' }}
          />
        </Grid>
        <Grid container className={styles.itemBlock} spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={trans('company-linkedin')}
              placeholder={trans('optional')}
              maxLength={255}
              {...getFieldProps('linkedInUrl')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={trans('company-website')}
              placeholder={trans('optional')}
              maxLength={255}
              {...getFieldProps('websiteUrl')}
            />
          </Grid>
        </Grid>
        <Grid container className={`${styles.itemBlock} ${styles.spacing}`}>
          <Typography xs={12} weight="600" variant="h6" fontFamily="lexend">
            {trans('interested-budget-range')}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={trans('budget-from')}
                placeholder={0}
                maxLength={10}
                {...getFieldProps('budget.from')}
                error={errors?.budget?.from ? errors?.budget?.from : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end" className={styles.currencyInput}>
                      ${' '}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ inputMode: 'numeric' }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label={trans('budget-to')}
                placeholder={0}
                maxLength={255}
                {...getFieldProps('budget.to')}
                error={errors?.budget?.to ? errors?.budget?.to : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end" className={styles.currencyInput}>
                      ${' '}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ inputMode: 'numeric' }}
              />
            </Grid>
          </Grid>
          <div>
            <Typography style={{ fontSize: '0.75rem' }} variant="body2" color="error">
              {errors?.budget?.to || errors?.budget?.from ? '' : errors?.budget}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} className={styles.itemBlock}>
          <Typography weight="600" variant="h6" fontFamily="lexend" className={styles.marginBottom}>
            {trans('official-certifications')}
          </Typography>
          {officialCert?.map((c) => (
            <Chip
              className={styles.tag}
              label={`${c?.states?.map((s) => ` ${s}`)} - ${c.name}`}
              onDelete={() => setOfficialCert(officialCert.filter((ci) => ci.name !== c.name))}
            />
          ))}

          <Button onClick={onOpenCertModal} color="primary" variant="contained" size="small">
            <Typography weight="700" variant="caption">
              {trans('add')}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} className={styles.itemBlock}>
          <Typography weight="600" variant="h6" fontFamily="lexend" className={styles.marginBottom}>
            {trans('quality-assurance-standards')}
          </Typography>
          {qualityCert.map((c, i) => (
            <Chip
              className={styles.tag}
              label={`${c.assuranceType}-${c.standardNumber}`}
              onDelete={() => setQualityCert(qualityCert.filter((ci, j) => i !== j))}
            />
          ))}
          {qualityCert.length < 30 && (
            <Button onClick={onOpenQualityModal} color="primary" variant="contained" size="small">
              <Typography weight="700" variant="caption">
                {trans('add')}
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid item xs={12} className={styles.itemBlock}>
          <Typography weight="600" variant="h6" fontFamily="lexend" className={styles.marginBottom}>
            {trans('general-certifications')}
          </Typography>
          {generalCert?.map((c, i) => (
            <Chip
              className={styles.tag}
              label={`${c?.states?.map((s) => ` ${s}`)} - ${c.name} ${c.license}`}
              onDelete={() => setGeneralCert(generalCert.filter((ci, j) => i !== j))}
            />
          ))}
          {generalCert?.length < 30 && (
            <Button onClick={onOpenGeneralCertModal} color="primary" variant="contained" size="small">
              <Typography weight="700" variant="caption">
                {trans('add')}
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid item xs={12} className={styles.itemBlock}>
          <Typography weight="600" variant="h6" fontFamily="lexend" className={styles.marginBottom}>
            {trans('special-equipment-materials')}
          </Typography>
          <TextField
            placeholder={trans('special-equipment-materials-ph')}
            multiline
            minRows={4}
            maxRows={10}
            maxLength={1000}
            {...getFieldProps('specialEquipment')}
            inputProps={{ fontSize: '10px' }}
          />
        </Grid>
        <Grid container className={`${styles.itemBlock} ${styles.spacing}`}>
          <Typography xs={12} weight="600" variant="h6" fontFamily="lexend">
            {trans('bonding-levels')}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={trans('construction-per-contract')}
                placeholder={0}
                maxLength={10}
                {...getFieldProps('consPerContract')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end" className={styles.currencyInput}>
                      ${' '}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ inputMode: 'numeric' }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label={trans('construction-aggregate')}
                placeholder={0}
                maxLength={255}
                {...getFieldProps('consAggregate')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end" className={styles.currencyInput}>
                      ${' '}
                    </InputAdornment>
                  ),
                }}
                inputProps={{ inputMode: 'numeric' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={trans('service-per-contract')}
              placeholder={0}
              maxLength={10}
              {...getFieldProps('servPerContract')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" className={styles.currencyInput}>
                    ${' '}
                  </InputAdornment>
                ),
              }}
              inputProps={{ inputMode: 'numeric' }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label={trans('service-aggregate')}
              placeholder={0}
              maxLength={255}
              {...getFieldProps('servAggregate')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" className={styles.currencyInput}>
                    ${' '}
                  </InputAdornment>
                ),
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider light spacing={1} />
      <Grid container className={styles.containerFooter}>
        <div className={styles.backButtonContainer}>
          <ArrowBackIcon />
          <div className={styles.backButton}>{trans('back')}</div>
        </div>
        <div className={styles.cancelNextButtonContainer}>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {trans('cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginLeft: '10px' }}>
            {trans('next')}
          </Button>
        </div>
      </Grid>
      <OfficialCertifications
        open={openCertModal}
        onClose={onCloseCertModal}
        states={states}
        certifications={officialCert}
        setCertifications={setOfficialCert}
      />
      <QualityStandards
        open={openQualityModal}
        onClose={onCloseQualityModal}
        certifications={qualityCert}
        setCertifications={setQualityCert}
      />
      <GeneralCertifications
        open={openGeneralCertModal}
        onClose={onCloseGeneralCertModal}
        states={states}
        certifications={generalCert}
        setCertifications={setGeneralCert}
      />
    </React.Fragment>
  );
}
