import React, { useEffect, useState } from 'react';
import { useTrans } from '../../../../services/i18n';
import { ModalDialog } from '../../../../components/common/Dialogs';
import { Grid, MenuItem } from '@material-ui/core';
import Loader from '../../../../components/common/Loader';
import Button from '../../../../components/common/Buttons/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from '../../../../hooks/useForm';
import { SelectField, TextField } from '../../../../components/common/Inputs';
import Typography from '../../../../components/common/Typography';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import * as Yup from 'yup';
import { schemaToInitialState } from '../../../../utils/validationUtils';

const useStyles = makeStyles((theme) => ({
  darkGrayLabel: {
    color: theme.palette.gray.main,
    fontFamily: 'lexend',
    marginBottom: '0.4rem',
  },
}));

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    assuranceType: Yup.string().required(trans('required')),
    standardNumber: Yup.string()
      .required(trans('required'))
      .max(50, 'The Standard Number/Info can be 50 characters max'),
  })
);

export default function QualityStandards({ open, onClose, certifications, setCertifications }) {
  const styles = useStyles();
  const schema = useScheme();
  const { trans } = useTrans();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('🍉 ~ file: QualityStandards.js:43 ~ QualityStandards ~ UE');
    setValues({ assuranceType: '', standardNumber: '' });
  }, []);

  const certificationsList = [
    {
      name: trans('iso'),
      value: trans('iso'),
    },
    {
      name: trans('ansi'),
      value: trans('ansi'),
    },
  ];

  function getRenderValue(list, value) {
    const d = list.find((i) => i.value === value);
    return d && d.name;
  }

  const onAddCertification = () => {
    setCertifications([...certifications, values]);
    onClose();
    resetForm();
  };

  const { setValues, getFieldProps, handleSubmit, values, resetForm } = useForm(
    schema,
    schemaToInitialState(schema),
    onAddCertification,
    {
      validateOnChange: false,
      validateOnBlur: false,
    }
  );

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('add-quality-assurance-standard')}
      actions={
        <>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {trans('cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {trans('add')}
          </Button>
        </>
      }
    >
      <Loader open={loading} />
      <Grid container spacing={3} style={{ width: 'unset' }}>
        <Grid item xs={6} className={styles.itemBlock}>
          <Typography weight="500" variant="body2" className={styles.darkGrayLabel}>
            {trans('quality-assurance-type')}
          </Typography>

          <SelectField
            type="select"
            getRenderValue={getRenderValue.bind(null, certificationsList, values?.assuranceType)}
            {...getFieldProps('assuranceType')}
          >
            {certificationsList &&
              certificationsList.map((fd, index) => (
                <MenuItem key={`qa_${index}`} value={fd.value}>
                  {fd.name}
                </MenuItem>
              ))}
          </SelectField>
        </Grid>
        <Grid item xs={6} className={styles.itemBlock}>
          <TextField
            label={trans('standard-number-info')}
            placeholder={trans('standard-number-info')}
            maxLength={50}
            {...getFieldProps('standardNumber')}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
}
