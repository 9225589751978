import MUITableHead from '@material-ui/core/TableHead';
import TableRow from './TableRow';

export default function TableHead({ children }) {
  return (
    <MUITableHead>
      <TableRow>{children}</TableRow>
    </MUITableHead>
  );
}
