import { connect } from 'react-redux';
import { actions } from '../../../../store/ezSearch';

function mapStateToProps(state) {
  return state.ezSearch;
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeText: (value) => dispatch(actions.changeTextAction(value)),
    fetchRecentSearchs: () => dispatch(actions.fetchRecentSearchs()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
