import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconRemove = (props) => {
  const { width, height, thin = false } = props;

  return thin ? (
    <SvgIcon width={width || 56} height={height || 62} viewBox="0 0 56 62">
      <path
        d="M316 0c3.785 0 6.87 3.005 6.996 6.76L323 7v5h14c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L337 14h-5v41c0 3.785-3.005 6.87-6.76 6.996L325 62h-30c-3.785 0-6.87-3.005-6.996-6.76L288 55V14h-5c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L283 12h14V7c0-3.785 3.005-6.87 6.76-6.996L304 0h12zm14 14h-40v41c0 2.689 2.122 4.882 4.783 4.995L295 60h30c2.689 0 4.882-2.122 4.995-4.783L330 55V14zm-26 13c.513 0 .936.386.993.883L305 28v18c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L303 46V28c0-.552.448-1 1-1zm12 0c.513 0 .936.386.993.883L317 28v18c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L315 46V28c0-.552.448-1 1-1zm0-25h-12c-2.689 0-4.882 2.122-4.995 4.783L299 7v5h22V7c0-2.689-2.122-4.882-4.783-4.995L316 2z"
        transform="translate(-692.000000, -155.000000) translate(380.000000, 20.000000) translate(30.000000, 135.000000)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width || 24} height={height || 24} viewBox="0 0 24 24">
      <path
        d="M13 2c1.598 0 2.904 1.249 2.995 2.824L16 5v1h4c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L20 8h-1v11c0 1.598-1.249 2.904-2.824 2.995L16 22H8c-1.598 0-2.904-1.249-2.995-2.824L5 19V8H4c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L4 6h4V5c0-1.598 1.249-2.904 2.824-2.995L11 2h2zm4 6H7v11c0 .513.386.936.883.993L8 20h8c.513 0 .936-.386.993-.883L17 19V8zm-7 3c.513 0 .936.386.993.883L11 12v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L9 16v-4c0-.552.448-1 1-1zm4 0c.513 0 .936.386.993.883L15 12v4c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L13 16v-4c0-.552.448-1 1-1zm-1-7h-2c-.513 0-.936.386-.993.883L10 5v1h4V5c0-.513-.386-.936-.883-.993L13 4z"
        transform="translate(-1006 -170) translate(380) translate(30 148) translate(596 22)"
      />
    </SvgIcon>
  );
};

IconRemove.propTypes = simpleIconPropsTypes;
