import { Grid } from '@material-ui/core';
import React from 'react';
import GeneralInfo from './GeneralInfo';
import connect from '../connect';

const Summary = ({ vendorInfo }) => {
  return (
    <>
      {!!vendorInfo && (
        <Grid container justifyContent="space-between" spacing={2} alignItems="flex-start">
          <Grid container spacing={3}>
            <Grid item id="summary" xs={12}>
              <GeneralInfo xs={12} item={vendorInfo} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default connect(Summary);
