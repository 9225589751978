import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import React from 'react';
import * as Yup from 'yup';
import { Button, RouterLink } from '../../../../../components/common/Buttons';
import { CloseTitle, InfoContent, ModalDialog } from '../../../../../components/common/Dialogs';
import TextField from '../../../../../components/common/Inputs/TextField';
import Typography from '../../../../../components/common/Typography';
import { useForm } from '../../../../../hooks/useForm';
import { useTrans } from '../../../../../services/i18n';
import { schemaToInitialState } from '../../../../../utils/validationUtils';
import useStyles from '../../../styles';
import { application } from '../../../../../services/application';
import ResendConfirmationLink from '../../../ResendConfirmationLink';

export const ForgotPassword = ({ open, onClose }) => {
  const { trans } = useTrans();
  const [isSent, setSent] = React.useState(true);
  const classes = useStyles();
  const [pending, setPending] = React.useState(false);
  const [mustConfirm, setMustConfirm] = React.useState(false);
  const [userNotExists, setUserNotExists] = React.useState(false);

  React.useLayoutEffect(() => {
    setMustConfirm(false);
  }, [isSent]);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(trans('incorrect-email'))
      .required(trans('empty-not-allowed', { field: trans('email') })),
  });

  const handleSubmit = React.useCallback(
    (values, { setStatus, setErrors }) => {
      setPending(true);
      return application
        .call('user.forgotPassword', { email: values.email })
        .then(({ success, reason }) => {
          if (!success) {
            if (reason === 'not-confirmed') {
              setMustConfirm(true);
              setUserNotExists(false);
            }
            if (reason === 'user-does-not-exist') {
              setMustConfirm(false);
              setUserNotExists(true);
            }
            return;
          }
          setSent(false);
        })
        .catch((err) => setStatus({ message: err.message }))
        .finally(() => setPending(false));
    },
    [setSent]
  );

  const formik = useForm(schema, schemaToInitialState(schema), handleSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleOnClose = () => {
    setMustConfirm(false);
    setUserNotExists(false);
    formik.resetForm();
    onClose();
  };

  return (
    <ModalDialog open={open} onClose={onClose} maxWidth="sm" DialogTitle={<CloseTitle onClose={onClose} />}>
      <InfoContent>
        {isSent ? (
          <Grid container spacing={4} justifyContent="center" className={classes.content}>
            <Grid item xs={12}>
              <Typography variant="h2" fontFamily="lexend" weight="600">
                {trans('forgot-password')}
              </Typography>
            </Grid>

            {/* <Grid item xs={12}></Grid> */}

            {mustConfirm && (
              <Grid item xs={12}>
                <Typography color={'error'}>{trans('confirm-account-first-for-password-reset')} </Typography>
                <Typography>
                  <ResendConfirmationLink email={formik.values.email} onSent={handleOnClose} />
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography fontFamily="lexend" weight="400" style={{ marginBottom: '10px' }}>
                {trans('message-send-email-forgot-password')}
              </Typography>
              <TextField placeholder={trans('email')} {...formik.getFieldProps('email')} required />
            </Grid>
            {userNotExists && (
              <Grid item xs={12} style={{ padding: '0 16px', marginBottom: '16px' }}>
                <Typography color={'error'}>
                  {trans('user-does-not-exist')}{' '}
                  <Typography variant={'span'} to={'/sign-up'} component={RouterLink}>
                    {trans('sign-up')}
                  </Typography>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} spacing={4} container alignItems="center">
              <Button
                id="send-mail-btn"
                pending={pending}
                variant="contained"
                size="large"
                color="primary"
                onClick={formik.handleSubmit}
              >
                {trans('send-mail')}
              </Button>
              <Grid item>
                <Button id="forgot-password-cancel" size="large" onClick={handleOnClose}>
                  {trans('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4} justifyContent="center" className={classes.content}>
            <Grid item xs={12}>
              <Typography variant="h2" fontFamily="lexend" weight="600">
                {trans('check-your-email')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography weight="800" variant="body1" className={classes.modalText}>
                {trans('we-have-sent-instructions-to')}
                <Typography component="span" weight="700" variant="body1" color="secondary">
                  {formik.values?.email}
                </Typography>
              </Typography>
              <Typography weight="500" variant="body1" className={classes.modalText}>
                {trans('if-an-account-with-this-email-exists')}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} spacing={4} container alignItems="center">
              <Grid>
                <Button
                  id="forgot-password-ok"
                  variant="contained"
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={onClose}
                >
                  {trans('ok')}
                </Button>
              </Grid>
              <Grid item>
                <Typography color="hint" variant="body1" weight="600">
                  {trans('did-not-get-the-letter')}
                  <Link className={classes.link} onClick={() => setSent(true)}>
                    <Typography component="span">{trans('re-send')}</Typography>
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </InfoContent>
    </ModalDialog>
  );
};
