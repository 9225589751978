import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconSuitcase = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 16H9.5V16.5H10V16ZM10 15H10.5V14.5H10V15ZM3.01 15V14.5H2.51125L2.51 14.9987L3.01 15ZM3 19L2.5 18.9987V19H3ZM21 15H21.5V14.5H21V15ZM14 15V14.5H13.5V15H14ZM14 16V16.5H14.5V16H14ZM15.99 7H15.49V7.5H15.99V7ZM15.99 5H16.49V4.79289L16.3436 4.64645L15.99 5ZM13.99 3L14.3436 2.64645L14.1971 2.5H13.99V3ZM9.99 3V2.5H9.78289L9.63645 2.64645L9.99 3ZM7.99 5L7.63645 4.64645L7.49 4.79289V5H7.99ZM7.99 7V7.5H8.49V7H7.99ZM10 14V14.5H10.5V14H10ZM10 12V11.5H9.5V12H10ZM14 12H14.5V11.5H14V12ZM14 14H13.5V14.5H14V14ZM14 7V7.5H14.5V7H14ZM10 7H9.5V7.5H10V7ZM10 5V4.5H9.5V5H10ZM14 5H14.5V4.5H14V5ZM10.5 16V15H9.5V16H10.5ZM10 14.5H3.01V15.5H10V14.5ZM2.51 14.9987L2.5 18.9987L3.5 19.0012L3.51 15.0013L2.51 14.9987ZM2.5 19C2.5 20.3861 3.61386 21.5 5 21.5V20.5C4.16614 20.5 3.5 19.8339 3.5 19H2.5ZM5 21.5H19V20.5H5V21.5ZM19 21.5C20.3861 21.5 21.5 20.3861 21.5 19H20.5C20.5 19.8339 19.8339 20.5 19 20.5V21.5ZM21.5 19V15H20.5V19H21.5ZM21 14.5H14V15.5H21V14.5ZM13.5 15V16H14.5V15H13.5ZM14 15.5H10V16.5H14V15.5ZM20 6.5H15.99V7.5H20V6.5ZM16.49 7V5H15.49V7H16.49ZM16.3436 4.64645L14.3436 2.64645L13.6364 3.35355L15.6364 5.35355L16.3436 4.64645ZM13.99 2.5H9.99V3.5H13.99V2.5ZM9.63645 2.64645L7.63645 4.64645L8.34355 5.35355L10.3436 3.35355L9.63645 2.64645ZM7.49 5V7H8.49V5H7.49ZM7.99 6.5H4V7.5H7.99V6.5ZM4 6.5C2.62386 6.5 1.5 7.62386 1.5 9H2.5C2.5 8.17614 3.17614 7.5 4 7.5V6.5ZM1.5 9V12H2.5V9H1.5ZM1.5 12C1.5 13.3861 2.61386 14.5 4 14.5V13.5C3.16614 13.5 2.5 12.8339 2.5 12H1.5ZM4 14.5H10V13.5H4V14.5ZM10.5 14V12H9.5V14H10.5ZM10 12.5H14V11.5H10V12.5ZM13.5 12V14H14.5V12H13.5ZM14 14.5H20V13.5H14V14.5ZM20 14.5C21.3761 14.5 22.5 13.3761 22.5 12H21.5C21.5 12.8239 20.8239 13.5 20 13.5V14.5ZM22.5 12V9H21.5V12H22.5ZM22.5 9C22.5 7.62386 21.3761 6.5 20 6.5V7.5C20.8239 7.5 21.5 8.17614 21.5 9H22.5ZM14 6.5H10V7.5H14V6.5ZM10.5 7V5H9.5V7H10.5ZM10 5.5H14V4.5H10V5.5ZM13.5 5V7H14.5V5H13.5Z"
        fill="#636363"
      />
    </svg>
  );
};

IconSuitcase.propTypes = simpleIconPropsTypes;
