import React from 'react';
import Typography from '../../../../../components/common/Typography';
import { useTrans } from '../../../../../services/i18n';
import connect from '../../../connect';
import { makeStyles } from '@material-ui/core';
import { IconMoney } from '../../../../../components/svg/Icons';
import BillingInformation from '../../../../Profile/BillingInformation';
import SubscribeIllustration from '../images/Subscribe-Illustration.png';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerTitle: {
    width: '100%',
    textAlign: 'center',
  },
}));

const YourPlan = ({ authUser, subscriptionStatus }) => {
  const { trans } = useTrans();
  const styles = useStyles();

  return (
    <>
      <Typography variant="h4" weight="600" fontFamily="lexend" gutter="bottomSpace" className={styles.centerTitle}>
        {trans('your-plan')}
      </Typography>
      <BillingInformation
        user={authUser}
        hideButtons={true}
        overridePlanImg={SubscribeIllustration}
        customDetails={
          subscriptionStatus.currentPlan === 'limited' ? (
            <div className={styles.detailsWrapper}>
              <IconMoney />
              <Typography variant="body2" color="hint" align="center" weight={400}>
                {trans('freePlan')}
              </Typography>
            </div>
          ) : null
        }
      />
    </>
  );
};

export default connect(YourPlan);
