import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../../../components/common/Typography';
import { ModalDialog } from '../../../../components/common/Dialogs';
import { Paper, SpacingContainer } from '../../../../components/common/Containers';
import Counter from './Counter';
import { Divider } from '../../../../components/common/Divider';
import { Button } from '../../../../components/common/Buttons';
import { formatNumberToUsd } from '../../../../utils/helpers';
import useModalSeats from './hooks/useModalSeats';
import useStyles from './styles';

const ModalSeats = React.memo(({ item, users, open, onClose, onSaveSeats, seatPrice, settings }) => {
  const styles = useStyles();
  const { trans } = useTrans();
  const {
    value,
    increment,
    decrement,
    saveSeats,
    pending,
    openConfirm,
    onOpenConfirm,
    onCloseConfirm,
    classes,
    seatsTotal,
    formattedInterval,
    usedSeats,
  } = useModalSeats({ item, users, open, onClose, onSaveSeats, seatPrice });

  return (
    <>
      <ModalDialog
        title={
          <Box pb={1}>
            {trans('manage-additional-seats')} {item?.orgName}
          </Box>
        }
        open={open}
        onClose={onClose}
        actions={
          <Grid container>
            <Grid item sm={12} xs={12} className={styles.right}>
              <Button
                pending={pending}
                disabled={value === item.seats}
                variant="contained"
                color="primary"
                onClick={onOpenConfirm}
              >
                {trans('update-plan')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Paper bgColor="paperSecondary" component={SpacingContainer} px={2} py={2} className={styles.wrapper}>
          <SpacingContainer>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
              <Grid item sm={12} xs={12}>
                <Typography variant="body1" weight="600" color="hint">
                  {item?.orgName} has <strong>{settings?.seats}</strong> seats right now, with{' '}
                  <strong>{usedSeats > 0 ? 'nobody' : usedSeats}</strong> on the team filling these seats. Adjust the
                  number below to add or remove the current seats.
                </Typography>
              </Grid>

              <Grid item sm={4} xs={4}>
                <Counter number={value} onIncrement={increment} onDecrement={decrement} />
              </Grid>
              <Grid item sm={12} xs={12} className={styles.center}>
                <Typography variant="body1" weight="600" color="hint">
                  This organization currently has {usedSeats > 0 ? usedSeats : ''}{' '}
                  {usedSeats === 0 ? 'no seats' : usedSeats > 1 ? 'seats' : 'seat'} in use.
                </Typography>
              </Grid>
            </Grid>
          </SpacingContainer>
        </Paper>
      </ModalDialog>

      <ModalDialog title={trans('confirm-payment')} open={openConfirm} onClose={onCloseConfirm}>
        <SpacingContainer>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={12} className={classes.center}>
              <Typography variant="body1" weight="600" color="label">
                You will be charged{' '}
                <span className={classes.accent}>
                  {formatNumberToUsd(seatsTotal)}
                  {seatPrice?.interval ? `/${trans(seatPrice?.interval).toLowerCase()}` : ''}
                </span>{' '}
                for {value} additional seats, this will be your {formattedInterval} subscription up to{' '}
                <span className={classes.accent}>{formatNumberToUsd(seatPrice?.subscriptionAmount + seatsTotal)}</span>.
              </Typography>
              <Typography variant="body1" weight="600" color="label">
                {trans('confirm-continue-action')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider light spacing={3} />

              <div className={classes.right}>
                <Button variant="outlined" color="primary" onClick={onCloseConfirm} style={{ marginRight: '1rem' }}>
                  {trans('cancel')}
                </Button>

                <Button variant="contained" color="primary" onClick={saveSeats} pending={pending}>
                  {trans('confirm')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </SpacingContainer>
      </ModalDialog>
    </>
  );
});

export default ModalSeats;
