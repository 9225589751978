import { EventEmitter } from 'events';
import { ApiError, RpcError } from './errors';
import { generateId, rpcRequest } from './helpers';

/**
 * @typedef {*&{}} HttpObject
 * @typedef {HttpObject} HttpQueryRecord
 * @typedef {HttpObject} HttpBodyData
 * @typedef {HttpObject} HttpResponseJson
 */

/**
 * @class HttpClient
 */
export default class HttpClient extends EventEmitter {
  /**
   * @readonly
   * @type {string}
   */
  baseUrl;

  /**
   * @private
   * @type {Object}
   */
  #headers = {};

  /**
   * @constructor
   * @param {string} baseUrl
   */
  constructor(baseUrl) {
    super();
    this.baseUrl = baseUrl;
  }

  setAuthorization(value) {
    if (!value) {
      delete this.#headers['Authorization'];
    } else {
      this.#headers['Authorization'] = value;
    }
    return this;
  }

  /**
   * @protected
   * @param {string} path
   * @param {HttpQueryRecord} [query]
   * @return {string}
   */
  getUrl(path, query) {
    const url = new URL(query ? [path, HttpClient.formatSearchParams(query).toString()].join('?') : path, this.baseUrl);
    return url.toString();
  }

  /**
   * @public
   * @param {string} path
   * @param {HttpQueryRecord} [query]
   * @return {Promise<HttpResponseJson | Blob>}
   */
  async get(path, query) {
    const response = await fetch(this.getUrl(path, query), {
      method: 'GET',
      headers: {
        ...this.#headers,
      },
      referrerPolicy: 'no-referrer',
    });
    if (response.ok) {
      return HttpClient.isJson(response) ? response.json() : response.blob();
    }

    return Promise.reject((await response.text()) || response.statusText);
  }

  /**
   * @public
   * @param {string} path
   * @param {HttpBodyData | FormData} body
   * @param {HttpQueryRecord} [query]
   * @return {Promise<HttpResponseJson>}
   */
  async post(path, body, query) {
    const response = await fetch(this.getUrl(path, query), {
      method: 'POST',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        ...this.#headers,
        'Content-Type': body instanceof FormData ? 'application/x-www-form-urlencoded' : 'application/json',
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: body instanceof FormData ? body : JSON.stringify(body), // body data type must match "Content-Type" header
    });
    // Retrieve the 'X-App-Version' header
    const appVersion = response.headers.get('x-app-version');
    const storedAppVersion = localStorage.getItem('appVersion');
    if (storedAppVersion !== appVersion || !storedAppVersion) {
      localStorage.setItem('appVersion', appVersion);
      window.location.reload();
    }
    const data = await (HttpClient.isJson(response) ? response.json() : response.text());

    if (response.ok) {
      return data;
    }

    return Promise.reject(new ApiError(data?.message || response.statusText, data?.data, response.url));
  }

  /**
   * @public
   * @param {string} method
   * @param {HttpBodyData} [params]
   * @return {Promise<HttpResponseJson>}
   */
  async rpc(method, params) {
    const value = await this.post('/rpc', rpcRequest(method, params, generateId(method)));

    if (value.error) {
      return Promise.reject(new RpcError(value.error, method));
    }

    return value.result;
  }

  /**
   * @public
   * @param {HttpQueryRecord} query
   * @return {URLSearchParams}
   */
  static formatSearchParams(query) {
    const searchParams = new URLSearchParams();
    Object.entries(query).forEach(([name, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => searchParams.append(name, encodeURI(String(v))));
      } else {
        searchParams.set(name, encodeURI(String(value)));
      }
    });
    return searchParams;
  }

  /**
   * @public
   * @param {Response} response
   * @return {boolean}
   */
  static isJson(response) {
    return String(response.headers.get('Content-Type')).includes('application/json');
  }
}
