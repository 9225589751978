export function correctNotInterestedType(type) {
  const types = {
    CA: 'Contract Award',
    FGO: 'Federal Grant Opportunity',
    FCO: 'Federal Contract Opportunity',
    NSN: 'NSN Opportunity',
  };

  return types[type];
}
