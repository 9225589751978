import { connect } from 'react-redux';
import { actions } from '../../../store/billing';
import { subscriptionActions } from '../../../store/subscription';

function mapStateToProps(state) {
  return {
    subscription: state.subscription,
    currentProduct: state.billing.currentProduct,
    openModalPremium: state.subscription.openModalPremium,
    plans: state.billing.plans,
    settings: state.orgSettings,
    orgSettings: state.orgSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOpenModalPremium: (payload) => dispatch(subscriptionActions.setOpenModalPremium(payload)),
    dismissUpgradingSubscription: () => dispatch(subscriptionActions.dismissUpgradingSubscription()),
    getPlans: (params) => dispatch(actions.getPlans(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
