import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionActions } from '../store/subscription';

export const useSubscriptionStatus = (subscription) => {
  return {
    pending: subscription?.pending || false,
    data: subscription?.data,
  };
};

export const useFetchSubscriptionStatus = () => {
  const dispatch = useDispatch();
  const activeOrg = useSelector((state) => state.auth?.organizations?.find((x) => !!x.active)?.organizationId);
  React.useLayoutEffect(() => {
    if (activeOrg) dispatch(subscriptionActions.fetch());
  }, [activeOrg]);
  return useSubscriptionStatus();
};
