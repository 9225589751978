import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  cont: {
    minWidth: 300,
    paddingLeft: 5,
    paddingRight: 5,
  },
});

export default function ContextInput({ children, className, ...rest }) {
  const styles = useStyles();

  return (
    <div className={clsx(styles.cont, className)} {...rest}>
      {children}
    </div>
  );
}
