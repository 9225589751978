import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCheck = (props) => {
  const { width = 24, height = 24, ...rest } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 26 26" {...rest}>
      <path
        d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm4.676 5.263c.376.344.428.91.14 1.315l-.079.098-5.5 6c-.368.401-.982.43-1.385.086l-.09-.086-2.5-2.728c-.372-.407-.345-1.04.062-1.412.376-.345.944-.348 1.323-.026l.09.087 1.762 1.922 4.764-5.195c.373-.407 1.006-.434 1.413-.061z"
        transform="translate(1 1)"
      />
    </SvgIcon>
  );
};

IconCheck.propTypes = simpleIconPropsTypes;
