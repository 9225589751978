import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonResend: {
    margin: '32px 0px 32px 0px',
    weight: 108,
  },
  formBack: {
    padding: '3rem',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      padding: '1.875rem',
      boxShadow: 'none',
      height: 'auto',
    },
  },
  topHeader: {
    color: theme.palette.gray.main,
    width: '100%',
    marginBottom: 16,
  },
  signHeader: {
    color: theme.palette.gray.light,
    width: '100%',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  signHeaderElementContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  signForm: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  flexHelper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    height: '3rem',
  },
  centeredBlock: {
    maxWidth: 420,
  },
  centeredBlockInfoState: {
    maxWidth: 440,
  },
  verificationTitle: {
    marginTop: 24,
    lineHeight: 1.3,
    marginBottom: 12,
  },
  email: {
    marginBottom: 38,
  },
  link: {
    marginLeft: 10,
  },
  codeRow: {
    marginBottom: 30,
  },
  helpRow: {
    color: theme.palette.gray.light,
    display: 'flex',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      // justifyContent: 'flex-start',
      marginTop: '.5rem',
    },
  },
  codeHelperText: {
    marginTop: 10,
    marginBottom: 30,
  },
  hintIcon: {
    marginRight: 10,
    marginLeft: 10,
  },
  authStateImage: {
    width: 140,
    height: 140,
    marginBottom: -24,
    marginLeft: -12,
  },
  authStateMessage: {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      gap: '0.5rem',
      '& span': {
        textAlign: 'end',
      },
      '& a': {
        whiteSpace: 'nowrap',
      },
    },
  },
  modalText: {
    marginBottom: 15,
  },
  loginButton: {
    height: 64,
    borderRadius: 8,
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      height: 48,
    },
  },
  mainActions: {
    margin: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0',
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}));
