import { connect } from 'react-redux';
import { actions as pipelinesActions } from '../../../store/pipelinesDetails';
import { actions as pursuitsActions } from '../../../store/pursuits';

function mapStateToProps(state, { itemId }) {
  return {
    sortBy: state.pipelineDetails.sort.pursuits[itemId],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    async onSave(params) {
      return dispatch(pursuitsActions.savePursuit(params));
    },
    getPursuits: (params) => dispatch(pipelinesActions.getPursuits(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
