import { createReducer, createAction } from '@reduxjs/toolkit';
import { API, SCOPE_CONTRACT_AWARDS } from '../../constants';
import {
  extendBuilderWithNotInterestedActions,
  extendListBuilder,
  generateListActions,
  generateNotInterestedActions,
  generateSearchActions,
  INITIAL_LIST_STATE_EXTENDED,
} from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['awardDate', 'desc'],
  isAllExpanded: false,
  isListView: true,
};

const listActions = generateListActions({
  scope: SCOPE_CONTRACT_AWARDS,
  apiMethod: {
    GET_LIST: API.CONTRACT_AWARDS.GET_LIST,
    EXPORT_CSV: API.CONTRACT_AWARDS.EXPORT_CSV,
  },
  getStore: (store) => store.contractAwards,
});

const notInterestingActions = generateNotInterestedActions({
  scope: SCOPE_CONTRACT_AWARDS,
  apiMethod: { ADD_NOT_INTERESTED: API.CONTRACT_AWARDS.ADD_NOT_INTERESTED },
  getStore: (store) => store.contractAwards,
});

const searchActions = generateSearchActions({
  scope: SCOPE_CONTRACT_AWARDS,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.contractAwards,
});

const expandUnexpandContractAward = createAction('contractAwards/expandUnexpandContractAward');
function onExpandUnexpandContractAward(state, action) {
  const copyItems = [...state.items];
  const index = copyItems.findIndex((i) => i.id === action.payload.id);
  copyItems[index] = { ...copyItems[index], expanded: !copyItems[index]?.expanded };

  return Object.assign(state, {
    items: copyItems,
  });
}

const expandUnexpandAll = createAction('contractAwards/expandUnexpandAll');
function onExpandUnexpandAll(state, action) {
  const copyItems = [...state.items];
  for (let index = 0; index < copyItems.length; index++) {
    // The following logic (expandedResult) is to avoid changing the expanded value for old items
    let expandedResult = false;
    if (copyItems[index]?.expanded === null) {
      // It is null when the item comes the first time
      expandedResult = state.isAllExpanded;
    } else {
      expandedResult = action.payload?.overrideStatus ? state.isAllExpanded : copyItems[index]?.expanded;
    }
    copyItems[index] = {
      ...copyItems[index],
      expanded: expandedResult,
    };
  }

  return Object.assign(state, {
    items: copyItems,
  });
}

const handleIsAllExpanded = createAction('contractAwards/handleIsAllExpanded');
function onHandleIsAllExpanded(state, action) {
  return Object.assign(state, {
    isAllExpanded: !state.isAllExpanded,
  });
}

const handleIsListView = createAction('contractAwards/handleIsListView');
function onHandleIsListView(state, action) {
  return Object.assign(state, {
    isListView: !state.isListView,
  });
}

export const actions = {
  ...listActions,
  ...searchActions,
  ...notInterestingActions,
  expandUnexpandContractAward,
  expandUnexpandAll,
  handleIsAllExpanded,
  handleIsListView,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendListBuilder(builder, actions)
    .addCase(expandUnexpandContractAward, onExpandUnexpandContractAward)
    .addCase(expandUnexpandAll, onExpandUnexpandAll)
    .addCase(handleIsAllExpanded, onHandleIsAllExpanded)
    .addCase(handleIsListView, onHandleIsListView);
  extendBuilderWithNotInterestedActions(builder, actions);

  return builder;
});
