import { connect } from 'react-redux';
import { actions } from '../../store/billing';
import { orgActions } from '../../store/orgSettings';
import { setSubscriptionInfo, subscriptionActions } from '../../store/subscription';

function mapStateToProps(state) {
  return {
    contactEmail: state?.auth?.email,
    userFullName: `${state?.auth?.name} ${state?.auth?.familyName}`,
    subscription: state.orgSettings.subscription,
    currentProduct: state.billing.currentProduct,
    pendingCreate: state.billing.pendingCreate,
    defaultPaymentMethod: state.billing.defaultPaymentMethod,
    currentModalityPlan: state.billing.currentModalityPlan,
    selectedPlan: state.billing.selectedPlan,
    selectedPlanPrice: state.billing.selectedPlanPrice,
    promoCode: state.billing.promoCode,
    products: state.billing.products,
    pending: state.billing.pending,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSubscriptionInfo: (subscription) => dispatch(setSubscriptionInfo(subscription)),
    createSubscription: (params) => dispatch(actions.createSubscription(params)),
    setCreateSubscription: (params) => dispatch(orgActions.setCreateSubscriptionAction(params)),
    setDefaultPaymentMethodAction: (params) => dispatch(actions.setDefaultPaymentMethodAction(params)),
    addCard: (params) => dispatch(actions.addPaymentMethod(params)),
    getSubscription: (params) => dispatch(actions.getSubscription(params)),
    downloadInvoice: (params) => dispatch(actions.downloadInvoice(params)),
    onApplyPromoCode: (params) => dispatch(actions.applyPromoCode(params)),
    setPromoCode: (params) => dispatch(actions.setPromoCode(params)),
    setCurrentModalityPlan: (params) => dispatch(actions.setCurrentModalityPlan(params)),
    getPlans: (params) => dispatch(actions.getPlans(params)),
    setSelectedPlan: (params) => dispatch(actions.setSelectedPlan(params)),
    setSelectedPlanPrice: (params) => dispatch(actions.setSelectedPlanPrice(params)),
    getProducts: (params) => dispatch(actions.getProducts(params)),
    getOrganizationInfo: (params) => dispatch(orgActions.getOrganizationInfoAction(params)),
    getSubscriptionStatus: (params) => dispatch(subscriptionActions.fetch(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
