import TextField from '../TextField';

import React from 'react';
import { DATE_FORMAT } from '../../../../constants';
import Typography from '../../Typography';

import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  width: 100%;
  & input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(70%) sepia(5%) saturate(150%) hue-rotate(220deg) brightness(110%) contrast(85%);
  }
`;

export default function TimePicker({
  label,
  value,
  onChange,
  onChangeValue,
  name = 'date',
  format = DATE_FORMAT,
  error,
  ...rest
}) {
  const handleChange = React.useCallback(
    (idx) => {
      return (date) => {
        if (typeof onChange === 'function') return onChange(`${name}.${idx}`)(date);
        if (typeof onChangeValue === 'function') return onChangeValue(date, `${name}.${idx}`, null);
      };
    },
    [name, onChange, onChangeValue]
  );
  return (
    <>
      {label && (
        <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
          {label}
        </Typography>
      )}
      <Box>
        <TextField value={value?.[0] || null} name={name} onChange={handleChange(0)} type="time" {...rest} />
        <TextField value={value?.[1] || null} name={name} onChange={handleChange(1)} type="time" {...rest} />
      </Box>
    </>
  );
}
