import Grid from '@material-ui/core/Grid';
import React from 'react';
import Avatar from '../Avatar';
import Typography from '../../Typography';
import { Link } from '@material-ui/core';

export default function AvatarTitle({ item, small = false, makeContactLink = false }) {
  return (
    <Grid container alignItems={small ? 'flex-start' : 'center'} spacing={2} wrap="nowrap">
      <Grid item>
        <Avatar
          size={40}
          variant={small ? 'body1' : 'h6'}
          value={item?.person ? item.person[0] : ''}
          alt={item?.person ? item.person[0] : ''}
          bgColor="success"
          color="white"
        />
      </Grid>
      <Grid item zeroMinWidth>
        {item && item.person && item.person.length && (
          <>
            <Typography variant="h6" weight="600" color="success" noWrap>
              {item.person[0]}
            </Typography>

            {makeContactLink ? (
              <Link href={`mailto:${item.person[1]}?subject=Upgrade USFCR Subscription`}>
                <Typography variant="subtitle2" noWrap>
                  {item.person[1] || ''}
                </Typography>
              </Link>
            ) : (
              <Typography variant="subtitle2" noWrap>
                {item.person[1] || ''}
              </Typography>
            )}
            <Typography variant="subtitle2" noWrap>
              {item.person[2] || ''}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}
