import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCircleGray = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" noFill={true}>
      <rect width="24" height="24" rx="12" fill="#B2B8CF" fill-opacity="0.2" />
    </SvgIcon>
  );
};

IconCircleGray.propTypes = simpleIconPropsTypes;
