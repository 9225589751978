import React from 'react';
import { useHistory } from 'react-router-dom';
import { useApplication } from '../services/application';
import { AmplifyAuth } from '../services/amplifyAuth';
import { ROUTE_SIGNIN } from '../constants';

export default function useLogout() {
  const history = useHistory();
  const application = useApplication();

  return React.useCallback(
    async (forceRedirect) => {
      try {
        await AmplifyAuth.signOut();
        await application.authorize();

        if (forceRedirect) {
          history.push(ROUTE_SIGNIN.path);
        }
        const script = document.createElement('script');
        script.src = window.Beacon('destroy');
        script.async = true;
        document.body.appendChild(script);
      } catch (error) {
        console.warn(error);
        localStorage.clear();
        window.location.reload();
      }
    },
    [history, application]
  );
}
