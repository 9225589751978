import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconBank = (props) => {
  const { width = 24, height = 24, filled = false, fullSize = false } = props;

  return fullSize ? (
    <SvgIcon width={width} height={height} viewBox="0 0 15 15">
      <path
        d="M9.588 8.879l-3.98.564-.09.018c-.516.138-.702.793-.3 1.174l2.874 2.715-.677 3.833-.01.084c-.03.525.539.905 1.033.653L12 16.103l3.562 1.817.079.035c.5.187 1.04-.233.944-.772l-.678-3.833 2.875-2.715.061-.066c.339-.409.102-1.047-.45-1.126L14.41 8.88l-1.778-3.494c-.261-.513-1.005-.513-1.266 0L9.588 8.879z"
        transform="translate(-5, -4)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      {filled ? (
        <g>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            d="M22 19.5v3H2v-3c0-.55.45-1 1-1h18c.55 0 1 .45 1 1Z"
          />
          <path d="M7 11.5H5v7h2v-7ZM11 11.5H9v7h2v-7ZM15 11.5h-2v7h2v-7ZM19 11.5h-2v7h2v-7ZM23 23.25H1c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h22c.41 0 .75.34.75.75s-.34.75-.75.75ZM21.37 6.25l-9-3.6c-.2-.08-.54-.08-.74 0l-9 3.6c-.35.14-.63.55-.63.93v3.32c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V7.18c0-.38-.28-.79-.63-.93ZM12 9c-.83 0-1.5-.67-1.5-1.5S11.17 6 12 6s1.5.67 1.5 1.5S12.83 9 12 9Z" />
        </g>
      ) : (
        <g>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            d="M22 19.5v3H2v-3c0-.55.45-1 1-1h18c.55 0 1 .45 1 1Z"
          />
          <path d="M7 11.5H5v7h2v-7ZM11 11.5H9v7h2v-7ZM15 11.5h-2v7h2v-7ZM19 11.5h-2v7h2v-7ZM23 23.25H1c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h22c.41 0 .75.34.75.75s-.34.75-.75.75ZM21.37 6.25l-9-3.6c-.2-.08-.54-.08-.74 0l-9 3.6c-.35.14-.63.55-.63.93v3.32c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V7.18c0-.38-.28-.79-.63-.93ZM12 9c-.83 0-1.5-.67-1.5-1.5S11.17 6 12 6s1.5.67 1.5 1.5S12.83 9 12 9Z" />
        </g>
      )}
    </SvgIcon>
  );
};

IconBank.propTypes = simpleIconPropsTypes;
