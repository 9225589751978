import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '../../Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.success.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.success.main,
      border: '6px solid #fff',
    },
  },
  switchBase2: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&:not($checked)': {
      '& + $track': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.success.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.gray.active,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

const IOSSwitch = (props) => {
  const classes = useStyles();
  const { color } = props;

  return (
    <Switch
      color={color}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: color ? classes.switchBase2 : classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export default function BasicSwitch(props) {
  const { label, labelPlacement, labelColor = 'inherit', ...switchProps } = props;

  return !!label ? (
    <FormControlLabel
      control={<IOSSwitch {...switchProps} />}
      label={
        <Typography variant="body2" component="span" color={labelColor}>
          {label}
        </Typography>
      }
      labelPlacement={labelPlacement || 'start'}
    />
  ) : (
    <IOSSwitch {...switchProps} />
  );
}
