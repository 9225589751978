import React from 'react';
import { useTrans } from '../../../../../services/i18n';
import { Button } from '@material-ui/core';
import { RouterLink } from '../../../../common/Buttons';
import { ROUTE_TEAMING_OPPORTUNITIES_DETAILS, ROUTE_PURSUIT_DETAILS } from '../../../../../constants';

function TeamingView({ item }) {
  const { trans } = useTrans();

  let action = {
    label: () => 'View',
    linkTo: (item) =>
      item?.isOwner
        ? `${ROUTE_PURSUIT_DETAILS.path.replace(':id', item?.pursuit?.id)}?tab=teaming`
        : ROUTE_TEAMING_OPPORTUNITIES_DETAILS.path.replace(':id', item.itemId),
    variant: 'outlined',
  };

  return (
    <>
      <Button
        color="primary"
        variant={action?.variant || 'contained'}
        {...(action?.linkTo && { to: action.linkTo(item), component: RouterLink })}
        {...(action?.onClick && { onClick: async () => await action.onClick({ ...item }) })}
      >
        {trans(action.label(item))}
      </Button>
    </>
  );
}

export default TeamingView;
