import React from 'react';
import { Paper, SpacingContainer } from '../../../../common/Containers';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Typography from '../../../../common/Typography';
import { useTrans } from '../../../../../services/i18n';

function Alert() {
  const { trans } = useTrans();
  return (
    <Paper
      bgColor="paperSecondary"
      component={SpacingContainer}
      py={2}
      px={2}
      radius="4"
      style={{ display: 'flex', marginBottom: '1rem' }}
    >
      <InfoOutlined color="primary" style={{ marginRight: '16px' }} />
      <Typography variant="body2">{trans('request-message')}</Typography>
    </Paper>
  );
}

export default Alert;
