import { Box, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { Button } from '../../components/common/Buttons';

export const CenterBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CenterGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`;

export const AlertButton = styled(Button)`
  pointer-events: none;
  background-color: ${(props) => (props.color ? props.theme.palette[props.color].fade : '#17b47122')};
`;
