import makeStyles from '@material-ui/core/styles/makeStyles';
import MUITextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import React from 'react';
import Typography from '../../../../components/common/Typography';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import usePopover from '../../../../hooks/usePopover';
import Popover from '../Popover';

const useStyles = makeStyles({
  bigInput: {
    '& input': { paddingTop: 21, paddingBottom: 22 },
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginTop: '0.1rem',
  },
  textFieldInputError: {
    border: '1px solid #f32445', // Red border for error state
  },
});

export default function Signature(props) {
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();

  const {
    InputProps: _InputProps,
    inputProps: _inputProps,
    error,
    maxLength,
    label = null,
    fullWidth = true,
    disableBrowserAutoComplete = true,
    big = false,
    className,
    id = '',
    name = '',
    bgColor = '',
    align = '',
    borderRadius1rem = '',
    labelWeight = '400',
    labelFontFamily = 'manrope',
    labelColor = 'default',
    labelGutter = 'label',
    labelVariant = 'h6',
    isRequired = false,
    helpTooltip = false,
    ...rest
  } = props;
  let InputProps = _InputProps || {};
  const inputProps = _inputProps || {};
  const classes = useStyles();

  InputProps.disableUnderline = true;
  if (disableBrowserAutoComplete) inputProps.autoComplete = 'off';
  if (typeof maxLength === 'number') inputProps.maxLength = maxLength;
  if (error) {
    InputProps = { ...InputProps, className: error && classes.textFieldInputError };
  }

  return (
    <>
      {label && (
        <Typography
          weight={labelWeight}
          fontFamily={labelFontFamily}
          color={labelColor}
          gutter={labelGutter}
          variant={labelVariant}
        >
          {label}
          {isRequired && <span style={{ color: 'red', marginLeft: '0.5rem' }}>*</span>}
          {helpTooltip && (
            <span
              style={{
                marginLeft: '0.3rem',
                cursor: 'pointer',
              }}
            >
              <InfoOutlined onClick={onOpenPopover} color="primary" className={classes.infoIcon} />
            </span>
          )}
        </Typography>
      )}
      <MUITextField
        id={id || `input-${name}`}
        name={name}
        InputProps={InputProps}
        inputProps={inputProps}
        variant="filled"
        helperText={error}
        error={Boolean(error)}
        hiddenLabel={!(error || label)}
        fullWidth={fullWidth}
        autoComplete={'off'}
        className={clsx(
          className,
          big && classes.bigInput,
          bgColor && `textfield-${bgColor}`,
          align && `textfield-${align}`,
          borderRadius1rem && `textfield-${borderRadius1rem}`,
          error && classes.textFieldError
        )}
        {...rest}
      />
      {/* Help popover */}
      <Popover
        helpTooltip={helpTooltip}
        anchorEl={anchorEl}
        onOpenPopover={onOpenPopover}
        onClosePopover={onClosePopover}
      />
    </>
  );
}
