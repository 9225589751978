import React, { useMemo } from 'react';
import Typography from '../../../components/common/Typography';
import { useTrans } from '../../../services/i18n';
import { Chip } from '../../../components/common/Chip';
import PlanImg from '../components/images/plan.png';
import { formatDatePretty } from '../../../utils/dateUtils';
import connect from '../connect';
import { Button } from '../../../components/common/Buttons';
import { Grid, makeStyles } from '@material-ui/core';
import { DATE_FORMAT, ROUTE_BILLING, ROUTE_PLANS_PRIVATE } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { Divider } from '../../../components/common/Divider';
import { useState } from 'react';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import ButtonCancelSubscription from '../../Billing/PlansTab/components/ButtonCancelSubscription';

const useStyles = makeStyles((theme) => ({
  containerCard: {
    padding: '2.125rem 1.5rem',
  },
  containerFooter: {
    padding: '1.87rem 2.3rem',
    marginTop: 'unset',
  },
  columnLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    alignItems: 'center',
  },
  buttonWidth: {
    width: '8.3rem',
  },
  blackLabel: {
    color: theme.palette.text.primary,
  },
  greenLabel: {
    color: theme.palette.success.dark,
  },
  cells: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.4rem',
  },
  verticalDivider: {
    borderRight: '1px solid  #eaebef',
  },
  bottomSpace: {
    marginBottom: '1rem',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1.25rem',
  },
  planWrapper: { display: 'flex', flexDirection: 'row', gap: 8, textAlign: 'center' },
}));

const colorMap = {
  limited: '#526FFF',
  pro: '#11CA7B',
  marketing: '#9576FF',
};

const BillingInformation = ({
  currentProduct,
  subscriptionStatus,
  subscription,
  navigateManagePlan,
  user,
  getCustomerBilling,
  settings,
  hideButtons,
  customDetails,
  overridePlanImg,
}) => {
  console.log('🚀 ~   subscriptionStatus:', { subscriptionStatus, subscription });
  const styles = useStyles();
  const history = useHistory();
  const { trans } = useTrans();
  const [pending, setPending] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState();

  const planName = useMemo(() => {
    let name = '';
    if (subscription?.status === 'trialing') {
      name += 'Free Trial - ';
    }

    name +=
      currentProduct?.product?.name ?? subscriptionStatus?.currentPlan
        ? trans(subscriptionStatus.currentPlan)
        : 'USFCR Plan';

    return name;
  }, [currentProduct, subscriptionStatus, trans]);

  const chipColor = useMemo(() => {
    if (subscription?.status === 'trialing') return colorMap.pro;

    switch (subscriptionStatus.currentPlan) {
      case 'limited':
        return colorMap.limited;
      case 'pro':
      case 'advantage':
      case 'freeTrial':
      case 'freeTrial-14-day':
        return colorMap.pro;
      case 'sapAdvantage':
      case 'appMax':
      case 'procurementPro':
        return colorMap.marketing;
      default:
        return colorMap.limited;
    }
  }, [subscriptionStatus]);

  const canCancelSubscription = useMemo(() => {
    return subscription && ['active', 'trialing'].includes(subscription.status) && !subscription.cancel;
  }, [subscription]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getCustomerBilling().finally(() => setPending(false));
      setSubscriptionDetails(result.payload?.data);
    };

    fetchData();
  }, [subscription, subscriptionStatus, getCustomerBilling]);

  const planRow = useMemo(() => {
    return (
      <Typography variant="body1" weight={600} className={styles.planWrapper}>
        {'USFCR Plan'} <Chip label={planName} style={{ backgroundColor: chipColor }} color="secondary" />
      </Typography>
    );
  }, [subscription, subscriptionStatus]);

  const manageBillingButton = (
    <>
      {!hideButtons && (
        <div className={styles.buttonsContainer}>
          <Button
            onClick={() => history.push(ROUTE_BILLING.path)}
            variant="contained"
            color="primary"
            className={styles.buttonWidth}
          >
            {trans('manage-billing')}
          </Button>

          {!canCancelSubscription && (
            <Button onClick={() => history.push(ROUTE_PLANS_PRIVATE.path)} variant="contained" color="success">
              {trans('renew-subscription')}
            </Button>
          )}
        </div>
      )}
      {canCancelSubscription && (
        <div className={styles.buttonsContainer}>
          <ButtonCancelSubscription
            returnButton={true}
            subscriptionEndDate={subscriptionDetails?.subscription?.plan?.subscriptionEnd}
          />
        </div>
      )}
    </>
  );

  const actions = useMemo(() => {
    switch (subscriptionStatus.currentPlan) {
      case 'limited':
      case 'expired':
        return (
          <>
            <div className={styles.section}>
              {planRow}
              {customDetails ?? (
                <Typography variant="body2" color="hint" align="center" weight={600} style={{ textAlign: 'center' }}>
                  {trans('member-since')}
                  {': '}
                  <Typography variant="inherit" weight={600} className={styles.blackLabel}>
                    {formatDatePretty(user?.createdAt, DATE_FORMAT)}
                  </Typography>
                </Typography>
              )}
            </div>
            {manageBillingButton}
          </>
        );
      case 'advantage':
        return (
          <div className={styles.cells}>
            <div className={styles.section}>{planRow}</div>
            <Divider light spacing={1} />
            <Grid container>
              <Grid item xs={6} className={styles.verticalDivider}>
                <Typography variant="body2" color="hint" align="center" weight={600} style={{ textAlign: 'center' }}>
                  {trans('member-since')}
                  {': '}
                  <Typography variant="inherit" weight={600} className={styles.blackLabel}>
                    {formatDatePretty(user?.createdAt, DATE_FORMAT)}
                    {/* {subscriptionDetails?.subscription?.plan?.subscriptionStart} */}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="hint" align="center" weight={600}>
                  {trans('next-charge')}
                  {': '}
                  <Typography variant="inherit" weight={600} className={styles.blackLabel}>
                    {subscriptionDetails?.subscription?.plan?.nextCharge}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Divider light spacing={1} />
            <Grid container className={styles.bottomSpace}>
              <Grid item xs={6} className={styles.verticalDivider}>
                <Typography variant="body2" color="hint" align="center" weight={600}>
                  {trans('additional-seats')}
                  {': '}
                  {settings?.seats}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="success" align="center" weight={600} className={styles.greenLabel}>
                  {trans('available-seats')}
                  {': '}
                  {settings?.availableSeats}
                </Typography>
              </Grid>
            </Grid>

            {manageBillingButton}
          </div>
        );
      case 'pro':
      case 'sapAdvantage':
      case 'appMax':
      case 'procurementPro':
      case 'freeTrial':
      case 'freeTrial-14-day':
        return (
          <div className={styles.cells}>
            <div className={styles.section}>{planRow}</div>
            {!!subscription?.canceledAt && (
              <Typography variant="body2" color="hint" align="center" weight={600}>
                {trans('subscription-ends')}
                {': '}
                <Typography variant="inherit" weight={600} className={styles.blackLabel}>
                  {subscriptionStatus.currentPlan === 'freeTrial' ||
                  subscriptionStatus.currentPlan === 'freeTrial-14-day'
                    ? formatDatePretty(dayjs(user?.createdAt).add(14, 'day').toISOString(), DATE_FORMAT)
                    : subscriptionStatus.currentPlan === 'sapAdvantage' ||
                      subscriptionStatus.currentPlan === 'procurementPro'
                    ? formatDatePretty(subscriptionDetails?.subscription?.sam_expiration__c, DATE_FORMAT)
                    : subscriptionDetails?.subscription?.plan?.subscriptionEnd}
                </Typography>
              </Typography>
            )}
            <Divider light spacing={1} />
            <Grid container>
              <Grid item xs={6} className={styles.verticalDivider}>
                <Typography variant="body2" color="hint" align="center" weight={600} style={{ textAlign: 'center' }}>
                  {trans('member-since')}
                  {': '}
                  <Typography variant="inherit" weight={600} className={styles.blackLabel}>
                    {formatDatePretty(user?.createdAt, DATE_FORMAT)}
                    {/* {subscriptionDetails?.subscription?.plan?.subscriptionStart} */}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {subscription?.canceledAt ? (
                  <Typography variant="body2" color="secondary" align="center" weight={600}>
                    {trans('subscription-canceled')}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="hint" align="center" weight={600}>
                    {trans('next-charge')}
                    {': '}
                    <Typography variant="inherit" weight={600} className={styles.blackLabel}>
                      {subscriptionStatus.currentPlan === 'freeTrial' ||
                      subscriptionStatus.currentPlan === 'freeTrial-14-day'
                        ? 'N/A'
                        : subscriptionStatus.currentPlan === 'sapAdvantage' ||
                          subscriptionStatus.currentPlan === 'procurementPro'
                        ? formatDatePretty(subscriptionDetails?.subscription?.sam_expiration__c, DATE_FORMAT)
                        : subscriptionDetails?.subscription?.plan?.nextCharge}
                    </Typography>
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Divider light spacing={1} />
            <Grid container className={styles.bottomSpace}>
              <Grid item xs={6} className={styles.verticalDivider}>
                <Typography variant="body2" color="hint" align="center" weight={600}>
                  {trans('additional-seats')}
                  {': '}
                  {settings?.seats}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="success" align="center" weight={600} className={styles.greenLabel}>
                  {trans('available-seats')}
                  {': '}
                  {settings?.availableSeats}
                </Typography>
              </Grid>
            </Grid>

            {manageBillingButton}
          </div>
        );
      default:
        break;
    }
  }, [subscription, subscriptionStatus, trans, subscriptionDetails]);

  return (
    <Grid container spacing={3} className={styles.containerCard}>
      <Grid item lg={12} md={12} xs={12} className={styles.columnLayout} style={{ paddingBottom: 0 }}>
        <div>
          <img src={overridePlanImg ?? PlanImg} alt="billing-banner" style={{ width: '-webkit-fill-available' }} />
        </div>
        {actions}
      </Grid>
    </Grid>
  );
};

export default connect(BillingInformation);
