import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_GRANT_OPPORTUNITIES } from '../../constants';
import { application } from '../../services/application';
import {
  extendBuilderWithNotInterestedActions,
  extendListBuilder,
  generateListActions,
  generateNotInterestedActions,
  generateSearchActions,
  INITIAL_LIST_STATE_EXTENDED,
} from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['postedDate', 'desc'],
  dictionary: {
    eligibleApplicantType: {},
    fundingActivityCategory: {},
    fundingInstrument: {},
    opportunityCategory: {},
  },
};

const listActions = generateListActions({
  scope: SCOPE_GRANT_OPPORTUNITIES,
  apiMethod: {
    GET_LIST: API.GRANT_OPPORTUNITIES.GET_LIST,
    EXPORT_CSV: API.GRANT_OPPORTUNITIES.EXPORT_CSV,
  },
  getStore: (store) => store.grantOpportunities,
});

const notInterestingActions = generateNotInterestedActions({
  scope: SCOPE_GRANT_OPPORTUNITIES,
  apiMethod: { ADD_NOT_INTERESTED: API.GRANT_OPPORTUNITIES.ADD_NOT_INTERESTED },
  getStore: (store) => store.grantOpportunities,
});

const searchActions = generateSearchActions({
  scope: SCOPE_GRANT_OPPORTUNITIES,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.grantOpportunities,
});

const getDictionary = createAsyncThunk('grantOpportunities/getDictionary', async (params, thunkApi) => {
  return application.call(API.GRANT_OPPORTUNITIES.GET_DICTIONARY, { params });
});

export const actions = {
  ...listActions,
  ...searchActions,
  ...notInterestingActions,
  getDictionary,
};

const onGetDictionary = (state, action) => Object.assign(state, { dictionary: { ...action.payload } });

export default createReducer(INITIAL_STATE, (builder) => {
  extendListBuilder(builder, actions);
  extendBuilderWithNotInterestedActions(builder, actions);

  return builder.addCase(getDictionary.fulfilled, onGetDictionary);
});
