import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common';
import {
  extendBuilderWithListActions,
  extendBuilderWithSearchActions,
  generateListActions,
  generateSearchActions,
} from '../helpers/listActionsHelpers';
import { API, SCOPE_TASKS } from '../../constants';
import { application } from '../../services/application';
import { getFilterParams } from '../../utils/filterUtils';

const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  selectedTasks: [],
  inferredStatus: 'completed',
  filterStatus: '',
  pursuitId: '',
  order: ['updatedAt', 'desc'],
  filterOptionsInDetailPursuit: [
    { i18nKey: 'all-tasks', value: 'all', isMy: false },
    { i18nKey: 'OnlyMyTasks', value: 'isMy', isMy: true },
  ],
  filterSelect: { i18nKey: 'all-tasks', value: 'all', isMy: false },
  firstLoading: false,
};

const listActions = generateListActions({
  scope: SCOPE_TASKS,
  apiMethod: {
    GET_LIST: API.TASKS.GET_LIST,
  },
  getStore: (store) => store.tasks,
});

const searchActions = generateSearchActions({
  scope: SCOPE_TASKS,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.tasks,
});

const addTask = createAsyncThunk(`${SCOPE_TASKS}/addTask`, (params) => {
  return application.call(API.PURSUITS.STORE_TASK_ON_PURSUIT, params);
});
const deleteTask = createAsyncThunk(`${SCOPE_TASKS}/deleteTask`, (params) => {
  return application.call(API.PURSUITS.DELETE_TASK_BY_ID, params);
});
const editTask = createAsyncThunk(`${SCOPE_TASKS}/editTask`, (params) => {
  return application.call(API.PURSUITS.UPDATE_TASK_ON_PURSUIT, params);
});
const deleteSelectedTasks = createAsyncThunk(`${SCOPE_TASKS}/deleteSelectedTasks`, (params, { getState }) => {
  const ids = getState().tasks.selectedTasks;
  return application.call(API.PURSUITS.DELETE_TASK_BY_ID, { id: ids });
});

const changeTaskStatus = createAsyncThunk(`${SCOPE_TASKS}/changeTaskStatus`, (params) => {
  return application.call(API.PURSUITS.CHANGE_STATUS_TASK_BY_ID, params);
});

const changeTasksStatus = createAsyncThunk(`${SCOPE_TASKS}/changeTasksStatus`, (params, { getState }) => {
  const ids = getState().tasks.selectedTasks;
  return application.call(API.PURSUITS.CHANGE_STATUS_TASK_BY_ID, { ...params, id: ids });
});

const fetchList = createAsyncThunk(`${SCOPE_TASKS}/fetchList`, async (params, { getState }) => {
  const { filters: storeFilters, pagination, order, owned, filterStatus, pursuitId } = getState().tasks;
  const { text, fields } = storeFilters;
  const filters = getFilterParams(fields);

  return application.call(
    API.TASKS.GET_LIST,
    Object.assign({ text, order, filters, pagination, owned, filterStatus, pursuitId }, params)
  );
});

const searchTagsForTasks = createAsyncThunk('tagsByUser/getTagsByUser', (params) => {
  if (params.filter && params.filter.text) {
    return application.call(API.TAGS_USER.GET_TAGS_BY_USER, {
      text: params.filter.text,
      type: 'tasks',
    });
  }
});

const updateInferredStatus = (state) => {
  const targetItems = state.items.filter((x) => state.selectedTasks.includes(x.id));
  if (targetItems.every((x) => x.status === 'completed')) {
    state.inferredStatus = 'incomplete';
  } else {
    state.inferredStatus = 'completed';
  }
};

const tasksSlice = createSlice({
  name: SCOPE_TASKS,
  initialState: INITIAL_STATE,
  reducers: {
    reset() {
      return INITIAL_STATE;
    },
    refreshSelectedTasks(state) {
      const newSet = new Set();
      const selected = new Set(state.selectedTasks);
      for (const item of state.items) {
        if (selected.has(item.id)) {
          newSet.add(item.id);
        }
      }
      state.selectedTasks = [...newSet];
      updateInferredStatus(state);
    },
    cleanSelectedTasks(state) {
      state.selectedTasks = [];
    },
    toggleTask(state, { payload }) {
      const index = state.selectedTasks.indexOf(payload);
      if (index < 0) {
        state.selectedTasks.push(payload);
      } else {
        state.selectedTasks.splice(index, 1);
      }
      updateInferredStatus(state);
    },
    toggleAllTasks(state) {
      if (state.selectedTasks.length !== state.items.length) {
        const selected = new Set();
        state.items.forEach((x) => selected.add(x.id));
        state.selectedTasks = [...selected];
      } else {
        state.selectedTasks = [];
      }
      updateInferredStatus(state);
    },
    filterByOwnedTask(state, { payload }) {
      state.owned = payload;
    },
    filterByOptionTask(state, { payload }) {
      state.firstLoading = false;
      state.owned = payload.isMy;
      state.filterSelect = payload;
    },
    searchByStatus(state, { payload }) {
      state.filterStatus = payload;
    },
    searchByPursuit(state, { payload }) {
      state.pursuitId = payload;
    },
    setTextSearch(state, { payload }) {
      state.firstLoading = false;
      state.filters.text = payload;
    },
    spliceDeleteTask(state, { payload }) {
      const index = state.items.findIndex((i) => i.id == payload);
      state.items.splice(index, 1);
    },
    setFirstLoading(state, { payload }) {
      state.firstLoading = payload;
    },
  },
  extraReducers: (builder) => {
    extendBuilderWithListActions(builder, { ...listActions });
    extendBuilderWithSearchActions(builder, { ...searchActions });
  },
});

export const actions = {
  addTask,
  deleteTask,
  editTask,
  deleteSelectedTasks,
  changeTaskStatus,
  changeTasksStatus,
  ...searchActions,
  ...listActions,
  ...tasksSlice.actions,
  fetchList,
  searchTagsForTasks,
};

export default tasksSlice.reducer;
