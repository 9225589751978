import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import SectionScrollable from '../../../../components/common/Containers/SectionScrollable';
import DetailsPageSceleton from '../../../../components/generics/Cards/DetailsPageSceleton';
import NothingFoundCardList from '../../../../components/generics/Cards/NothingFoundCard/NothingFoundCardList';
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase';
import Loader from '../../../../components/common/Loader';
import { ROUTE_VENDORS_MANAGEMENT } from '../../../../constants';
import connect from './connect';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTabs from '../../../../hooks/useTabs';
import { useHistory } from 'react-router-dom';
import { useTrans } from '../../../../services/i18n';
import { Tabs, Tab, TabPanel } from '../../../../components/common/Tabs';
import clsx from 'clsx';
import { IconBack } from '../../../../components/svg';
import { RouterLink } from '../../../../components/common/Buttons';
import Summary from './Summary';
import { V_MANAGEMENT_DETAILS_TABS } from '../../helpers';
import Notes from './Notes';

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: theme.zIndex.appBar + 1,
    position: 'relative',
    borderTop: `1px solid ${theme.palette.gray.divider}`,
    borderLeft: `1px solid ${theme.palette.gray.divider}`,
    height: 60,
    boxShadow: '0 10px 20px 0 rgba(78, 89, 120, 0.1)',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  btnHeader: {
    borderRadius: 0,
    height: '100%',
    width: 80,
    [theme.breakpoints.down('sm')]: { width: 60 },
  },
  content: { height: '100%' },
}));

const VendorManagementDetails = ({ onInit, onDestroy, vendorInfo = {}, pending }) => {
  const params = useParams();
  const styles = useStyles();
  const [tab, onChangeTab] = useTabs(1);
  const history = useHistory;
  const { trans } = useTrans();

  useEffect(() => {
    onInit({ id: params.id });
    return () => {
      vendorInfo = {};
      onDestroy();
    };
  }, [params.id, onInit, onDestroy]);

  return (
    <SectionScrollable
      TopFixed={
        <Grid container className={styles.header} alignItems="center" wrap="nowrap" justifyContent="space-between">
          {history.length > 0 ? (
            <IconButton onClick={() => history.goBack} className={styles.btnHeader} color="inherit">
              <IconBack />
            </IconButton>
          ) : (
            <IconButton
              component={RouterLink}
              to={ROUTE_VENDORS_MANAGEMENT.path}
              className={styles.btnHeader}
              color="inherit"
            >
              <IconBack />
            </IconButton>
          )}

          <Grid item className={styles.content} zeroMinWidth>
            <Tabs scrollButtons="on" className={styles.tabsContainer} value={tab} onChange={onChangeTab}>
              {V_MANAGEMENT_DETAILS_TABS.map((item, i) => (
                <Tab
                  key={i + 1}
                  value={i + 1}
                  label={trans(item.i18nKey)}
                  className={clsx(styles.tab, tab === i + 1 && styles.activeTab)}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item className={styles.btnHeader} />
        </Grid>
      }
    >
      <Loader open={pending} />
      <SpacingContainerBase>
        <TabPanel value={tab} tab={1} index={1}>
          {!!vendorInfo ? <Summary /> : <NothingFoundCardList />}
        </TabPanel>
        <TabPanel value={tab} tab={2} index={2}>
          {!!vendorInfo ? <Notes /> : <NothingFoundCardList />}
        </TabPanel>
      </SpacingContainerBase>
    </SectionScrollable>
  );
};

export default connect(VendorManagementDetails);
