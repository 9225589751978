import React from 'react';
import ContactModal from '../ContactModal';
import { useSubscriptionStatus } from '../../../../hooks/useSubscriptionStatus';
import { SUBSCRIPTION_STATUS } from '../../../../utils/constants';
//import makeStyles from '@material-ui/core/styles/makeStyles';
//import { SpacingContainer } from '../../Containers';
//import Grid from '@material-ui/core/Grid';
//import { Button } from '../../Buttons';
//import { useTrans } from '../../../../services/i18n';
//import { ReactComponent as IconPremium } from '../../../assests/ico-premium.svg';
//import Typography from '../../Typography';
import connect from '../connect';
/*
const useStyles = makeStyles({
  root: {
    backgroundColor: '#9576FF',
    color: 'white',
    position: 'relative',
  },
  button: {
    color: 'white',
    border: '1px solid white',
    textTransform: 'uppercase',
    marginLeft: 12,
  },
});*/

const BecomePremiumTopBar = React.memo(({ subscription }) => {
  const { pending, data } = useSubscriptionStatus(subscription);
  //const { trans } = useTrans();
  //const classes = useStyles();
  const [contactOpen, setContactOpen] = React.useState(false);
  React.useLayoutEffect(() => {
    if (contactOpen && (pending || data?.status === SUBSCRIPTION_STATUS.SUBSCRIBED)) {
      setContactOpen(false);
    }
  }, [pending, data?.status, contactOpen]);
  if (pending || data?.status === SUBSCRIPTION_STATUS.SUBSCRIBED) {
    return null;
  }
  return (
    <React.Fragment>
      <ContactModal open={contactOpen} onClose={() => setContactOpen(false)} />
      {/*<SpacingContainer className={classes.root} px={2} py={1}>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <IconPremium />
          </Grid>
          <Grid item>
            <Typography variant={'h5'} weight={'600'}>
              {trans('become-premium-get-full-access')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              onClick={() => {
                setContactOpen(true);
              }}
            >
              {trans('get-premium')}
            </Button>
          </Grid>
        </Grid>
      </SpacingContainer>*/}
    </React.Fragment>
  );
});

export default connect(BecomePremiumTopBar);
