import React, { useEffect, useState } from 'react';
import { useTrans } from '../../../../services/i18n';
import { ModalDialog } from '../../../../components/common/Dialogs';
import { Grid } from '@material-ui/core';
import Loader from '../../../../components/common/Loader';
import Button from '../../../../components/common/Buttons/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from '../../../../hooks/useForm';
import { CustomStatesAutocomplete } from '../CustomStatesAutocomplete';
import { TextField } from '../../../../components/common/Inputs';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import * as Yup from 'yup';
import { schemaToInitialState } from '../../../../utils/validationUtils';

const useStyles = makeStyles((theme) => ({
  darkGrayLabel: {
    color: theme.palette.gray.main,
    fontFamily: 'lexend',
    marginBottom: '0.4rem',
  },
}));

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    name: Yup.string().required(trans('required')),
    license: Yup.string().required(trans('required')),
    states: Yup.array().of(Yup.object()).min(1, 'At least one state is required').required('States are required'),
  })
);

export default function GeneralCertifications({ open, onClose, states, certifications, setCertifications }) {
  const styles = useStyles();
  const schema = useScheme();
  const { trans } = useTrans();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      ...values,
      states: [],
    });
  }, [setValues]);

  const onAddCertification = () => {
    const certification = { name: values.name, license: values.license, states: values.states.map((i) => i.id) };
    setCertifications([...certifications, certification]);
    onClose();
    resetForm();
    values.states = [];
  };

  const { getFieldProps, handleSubmit, values, resetForm, setValues } = useForm(
    schema,
    { states: [] },
    onAddCertification,
    {
      validateOnChange: false,
      validateOnBlur: false,
    }
  );

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('add-general-certification')}
      actions={
        <>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {trans('cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {trans('add')}
          </Button>
        </>
      }
    >
      <Loader open={loading} />
      <Grid container spacing={3} style={{ width: 'unset' }}>
        <Grid item xs={6} className={styles.itemBlock}>
          <TextField
            label={trans('general-certification-name')}
            placeholder={trans('general-certification-name')}
            maxLength={50}
            {...getFieldProps('name')}
          />
        </Grid>
        <Grid item xs={6} className={styles.itemBlock}>
          <TextField
            label={trans('license-number')}
            placeholder={trans('license-number')}
            maxLength={20}
            {...getFieldProps('license')}
          />
        </Grid>
        <Grid item xs={12} className={styles.itemBlock}>
          <CustomStatesAutocomplete
            label={trans('state-area')}
            placeholder={trans('please-select')}
            multi
            options={states}
            {...getFieldProps('states')}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
}
