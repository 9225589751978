import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconSchedule = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 3H8.5H6C4.34315 3 3 4.34315 3 6V9V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V9V6C21 4.34315 19.6569 3 18 3H15.5ZM19 8V6C19 5.44772 18.5523 5 18 5H16.5V6C16.5 6.55228 16.0523 7 15.5 7C14.9872 7 14.5645 6.61396 14.5067 6.11662L14.5 6V5H9.5V6C9.5 6.55228 9.05228 7 8.5 7C7.98716 7 7.56449 6.61396 7.50673 6.11662L7.5 6V5H6C5.44772 5 5 5.44772 5 6V8H19ZM5 10H19V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V10Z"
        fill="black"
      />
    </svg>
  );
};

IconSchedule.propTypes = simpleIconPropsTypes;
