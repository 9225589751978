import { useEffect, useState, useMemo, useCallback } from 'react';
import useActions from '../../../../../hooks/dispatchers/useActions';
import { actions } from '../../../../../store/savedSearches';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';

export const useSavedSearch = (item) => {
  const [saveSearchAction] = useActions([actions.saveSearchAction]);
  const [findOneSearchAction] = useActions([actions.findOneSearchAction]);
  const [open, onOpen, onClose] = useBooleanHandlers(false);
  const [itemSearch, setitemSearch] = useState(null);

  const params = useMemo(() => {
    if (!item?.details) return {};
    return {
      id: item.details?.savedSearchId ?? 0,
      scope: item.details?.scope,
    };
  }, [item]);

  const fetchSaveSearchList = useCallback(async () => {
    if (!item?.details) return;

    if (typeof findOneSearchAction !== 'function') return;
    let test = await findOneSearchAction(params);
    if (test && test.payload) setitemSearch(test.payload);
  }, [item, params, findOneSearchAction]);

  useEffect(() => {
    fetchSaveSearchList();
  }, [fetchSaveSearchList]);

  const handleSave = useCallback(
    async (saveSearchParams) => {
      await saveSearchAction(saveSearchParams);
      await fetchSaveSearchList();
    },
    [saveSearchAction, fetchSaveSearchList]
  );

  return {
    itemSearch,
    open,
    onOpen,
    onClose,
    handleSave,
  };
};
