import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconZoomIn = (props) => {
  const { width, height } = props;

  return (
    <SvgIcon width={width || 24} height={height || 24} viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2ZM19 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4ZM16 11C16.5523 11 17 11.4477 17 12C17 12.5128 16.614 12.9355 16.1166 12.9933L16 13H13V16C13 16.5523 12.5523 17 12 17C11.4872 17 11.0645 16.614 11.0067 16.1166L11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4872 7.38604 11.0645 7.88338 11.0067L8 11H11V8C11 7.44772 11.4477 7 12 7C12.5128 7 12.9355 7.38604 12.9933 7.88338L13 8V11H16Z"
        fill="#B2B8CF"
      />
    </SvgIcon>
  );
};
export const IconZoomOut = (props) => {
  const { width, height } = props;

  return (
    <SvgIcon width={width || 24} height={height || 24} viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 2H5C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2ZM5 4H19C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5C4 4.44772 4.44772 4 5 4ZM17 12C17 11.4477 16.5523 11 16 11H8L7.88338 11.0067C7.38604 11.0645 7 11.4872 7 12C7 12.5523 7.44772 13 8 13H16L16.1166 12.9933C16.614 12.9355 17 12.5128 17 12Z"
        fill="#B2B8CF"
      />
    </SvgIcon>
  );
};

IconZoomIn.propTypes = simpleIconPropsTypes;
