import { connect } from 'react-redux';
import { actions } from '../../store/releases';
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers';

function mapStateToProps(state) {
  return { releases: state.releases, currentRelease: state.releases.currentRelease };
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    changeCurrentRelease: (params) => dispatch(actions.changeCurrentRelease(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
