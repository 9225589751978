import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { application } from '../../services/application';
import { API } from '../../constants';

const INITIAL_STATE = {
  item: {},
  pending: false,
  notifications: [],
};

const getPursuits = createAsyncThunk('dashboard/getPursuits', (params) => {
  return application.call(API.PIPELINES.GET_PURSUITS, params);
});

const getNotifications = createAsyncThunk('dashboard/geNotifications', (params) => {
  return application.call(API.NOTIFICATIONS.GET_LIST, params);
});

function onFetchPursuits(state, action) {
  Object.assign(state, {
    pursuits: action.payload ? [...action.payload.items] : [],
  });
}

const getOpportunities = createAsyncThunk('dashboard/getOpportunities', (params) => {
  return application.call(API.CONTRACT_OPPORTUNITIES.GET_CONTRACT_BY_NAICS, params);
});

export const actions = {
  getPursuits,
  getNotifications,
  getOpportunities,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return builder
    .addCase(getPursuits.fulfilled, onFetchPursuits)
    .addCase(getNotifications.fulfilled, (state, { payload }) => {
      Object.assign(state, {
        notifications: payload ? [...payload.items] : [],
      });
    })
    .addCase(getOpportunities.fulfilled, (state, { payload }) => {
      state.opportunities = Array.isArray(payload?.items) ? payload.items : [];
    });
});
