const mixins = {
  unselectable: {
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  scrollBarColor: (color, background) => ({
    '& *': {
      'scrollbar-color': `${color} ${background}`,
    },
    '& *::-webkit-scrollbar-track': {
      background: background,
    },
    '& *::-webkit-scrollbar-thumb': {
      background: color,
      '&:hover': {
        background: color,
      },
    },
  }),
};

export default mixins;
