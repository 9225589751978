import React, { useMemo } from 'react';
import Typography from '../../../../../components/common/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useState } from 'react';
import { useEffect } from 'react';
import { formatNumber } from '../../../../../utils/numberUtils';
import ButtonCancelSubscription from '../ButtonCancelSubscription';
import { Paper, SpacingContainer } from '../../../../../components/common/Containers';
import { Grid } from '@material-ui/core';
import UpdatePlanButton from '../../../UpdatePlanButton';
import { Chip } from '../../../../../components/common/Chip';
import styles from '../../styles.module.css';
import { useTrans } from '../../../../../services/i18n';
import { Divider } from '../../../../../components/common/Divider';
import ButtonUpdateSubscription from '../ButtonUpdateSubscription';
import { getBenefitsByTier } from '../../../../../utils/subscription';
import Contact from './Contact';

const KEY_PERSON_TYPE = 'registration-contracting-specialist';

const Plan = ({
  plan: premiumPlan,
  currentProduct,
  modality,
  subscription,
  setCurrentModalityPlan,
  businessTeam,
  subscriptionStatus,
}) => {
  const { trans } = useTrans();
  const [price, setPrice] = useState(0);
  const [plan, setPlan] = useState({});

  const contactPerson = useMemo(() => businessTeam?.find((p) => p.type === KEY_PERSON_TYPE), [businessTeam]);

  useEffect(() => {
    let benefits = [];
    let productProperties = {};

    if (currentProduct) {
      const { plan, product } = currentProduct;
      const tier = product ? product?.tier : subscriptionStatus?.currentPlan;
      benefits = getBenefitsByTier(tier);
      productProperties = { ...product, ...plan };
    } else {
      benefits = getBenefitsByTier(subscriptionStatus.currentPlan);
      productProperties = {
        name: trans(subscriptionStatus.currentPlan),
      };
    }

    setPlan({
      ...premiumPlan,
      ...productProperties,
      benefits: benefits.slice(0, 10).map((b) => b.name),
    });
  }, [currentProduct, premiumPlan, subscriptionStatus, trans]);

  const getPrice = () => {
    let mod = 'year';
    if (modality) mod = 'month';

    const price = plan?.prices?.find((p) => p.recurring.interval === mod);
    if (price) setPrice(price);
    setCurrentModalityPlan({
      modality: modality ? 'monthly' : 'yearly',
      plan: plan?.name,
      price,
    });
  };

  useEffect(() => {
    getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modality]);

  return (
    <Paper component={SpacingContainer} py={4} px="4sm3" radius={4}>
      <Grid container spacing={2}>
        <Grid lg={4} xs={12} item className={styles.containerAvailablePlan}>
          <div>
            {subscription && subscription.status === 'active' && (
              <Chip label={trans('current-plan')} color={'secondary'} className={styles.chipCurrentPlan} />
            )}
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
              <img src={plan?.image} alt="plan" />
            </div>
            <Typography variant="body1" weight="bold" gutter="label">
              {plan.name}
            </Typography>
            {plan?.amount && (
              <Typography variant="h6" weight={600} color="label" gutter="bottom2">
                <AttachMoneyIcon /> {`USD ${formatNumber(plan.amount)}/${subscription?.interval ?? ''} payment plan`}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid lg={4} xs={12} item className={styles.containerBenefits}>
          <div>
            {plan?.benefits?.map((b, index) => (
              <Typography variant="body1" gutter="bottom2" key={index} weight="500">
                <CheckCircleOutlineIcon className={styles.checkIcon} />
                {b}
              </Typography>
            ))}
          </div>
        </Grid>
        <Grid lg={4} xs={12} item>
          <div className={styles.containerSubscriptionButton}>
            {' '}
            <Typography style={{ marginTop: '16px' }} variant="h5" gutter="bottom2" weight="bold">
              {trans('manage-your-plan')}
            </Typography>
            <Divider light />
            {businessTeam ? (
              <Contact contactPerson={contactPerson} />
            ) : (
              <>
                <div style={{ padding: '20px 0' }}>
                  <ButtonUpdateSubscription />
                </div>
                <Divider light />
                <div style={{ padding: '20px 0' }}>
                  <ButtonCancelSubscription />
                </div>
                <Divider light />
                <UpdatePlanButton price={price} />
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Plan;
