import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import { Button } from '../../common/Buttons';
import { ModalDialog } from '../../common/Dialogs';
import { DatePicker, TextField } from '../../common/Inputs';
import { useForm } from '../../../hooks/useForm';
import { useTrans } from '../../../services/i18n';
import { getInitialValues, useScheme } from './helpers';
import { useAlertContext } from '../../../contexts/AlertContext';
import connect from './connect';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ModalActions } from '../../common/Dialogs/ModalDialog/ModalDialog';

const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: true };

function SavePursuitFromContent({ item, onClose, onEdit }) {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert } = useAlertContext();
  const [isSend, setIsSend] = React.useState(false);

  const onSubmit = useCallback(
    (values) => {
      setIsSend(true);
      Promise.resolve(onEdit({ ...values, estimatedValue: values.estimatedValue || null, stageId: item.stageId }))
        .then(() => {
          successAlert('Pursuit updated');
          onClose?.();
          resetForm();
        })
        .catch(() => {
          errorAlert('Error saving pursuit');
        })
        .finally(() => setIsSend(false));
    },
    [onClose, errorAlert, successAlert, onEdit]
  );

  const { getFieldProps, handleSubmit, isValid, resetForm } = useForm(
    schema,
    getInitialValues(item),
    onSubmit,
    formOptions
  );

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          <TextField
            label={trans('name')}
            placeholder={trans('required')}
            required
            maxLength={80}
            {...getFieldProps('pursuitName')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={trans('description')}
            placeholder={trans('required')}
            multiline
            rows={4}
            maxRows={10}
            {...getFieldProps('description')}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            label={trans('Win Probability')}
            placeholder={trans('required')}
            maxLength={3}
            align="center"
            {...getFieldProps('winProbability')}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            label={trans('Estimated Value')}
            placeholder={trans('required')}
            InputProps={{
              startAdornment: <InputAdornment position="end">$</InputAdornment>,
            }}
            inputProps={{ inputMode: 'numeric' }}
            align="center"
            {...getFieldProps('estimatedValue')}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <DatePicker
            label={trans('Due Date')}
            placeholder={trans('required')}
            maxLength={5}
            align="center"
            disablePast
            disableToolbar
            orientation="landscape"
            {...getFieldProps('dueDate')}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <DatePicker
            label={trans('Est. Award Date')}
            placeholder={trans('required')}
            maxLength={5}
            align="center"
            {...getFieldProps('estimatedAwardDate')}
          />
        </Grid>
      </Grid>
      <ModalActions>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" disabled={isSend} onClick={onClose}>
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={!isValid} pending={isSend} onClick={handleSubmit}>
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      </ModalActions>
    </React.Fragment>
  );
}

function SavePursuitForm({ item, open, onClose, onEdit }) {
  const { trans } = useTrans();

  return (
    <ModalDialog open={open} onClose={onClose} title={trans('EditPursuit')}>
      <SavePursuitFromContent item={item} onClose={onClose} onEdit={onEdit} />
    </ModalDialog>
  );
}

export default connect(SavePursuitForm);
