import { useTrans } from '../../../services/i18n';
import Typography from '../../../components/common/Typography';
import { Grid } from '@material-ui/core';
import { Button } from '../../../components/common/Buttons';
import CodeInput from '../../../components/common/Inputs/CodeInput';
import { Divider } from '../../../components/common/Divider';
import { useState, useEffect } from 'react';
import { useApplication } from '../../../services/application';
import { useAlertContext } from '../../../contexts/AlertContext';
import { AmplifyAuth } from '../../../services/amplifyAuth';
import { AUTH_SMS_MFA } from '../../../constants';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const SmsFa = ({ userPhone = '', updateMFA, updatePhone, setSuccess }) => {
  const application = useApplication();
  const { trans } = useTrans();
  const [phone, setPhone] = useState('');
  const [pendingSendCode, setSendCodePending] = useState(false);
  const [pendingVerifyCode, setPendingVerifyCode] = useState(false);
  const { errorAlert } = useAlertContext();
  const [code, setCode] = useState('');
  const [sentCode, setSentCode] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
    updateMFA(AUTH_SMS_MFA);
  };

  const handleSetPhone = async () => {
    setSendCodePending(true);

    if (!sentCode) {
      return application
        .call('user.setPhone', { phone })
        .then((res) => {
          if (res) {
            updatePhone(phone);
            if (res.verified === 'false') {
              setSentCode(true);
              setSendCodePending(false);
            } else {
              AmplifyAuth.currentAuthenticatedUser()
                .then((user) => AmplifyAuth.setPreferredMFA(user, 'SMS'))
                .then(() => {
                  handleSuccess();
                })
                .finally(() => setSendCodePending(false));
            }
          }
        })
        .catch((e) => errorAlert(e.message));
    } else {
      return AmplifyAuth.verifyCurrentUserAttribute('phone_number')
        .then(async () => {
          await application.call('user.updatePhone', { phone });
          updatePhone(phone);
        })
        .finally(() => setSendCodePending(false))
        .catch((e) => {
          errorAlert(e.message);
        });
    }
  };

  const setMfa = async () => {
    setPendingVerifyCode(true);
    AmplifyAuth.verifyCurrentUserAttributeSubmit('phone_number', code)
      .then(() => {
        AmplifyAuth.currentAuthenticatedUser()
          .then((user) => AmplifyAuth.setPreferredMFA(user, 'SMS'))
          .then(() => {
            handleSuccess();
          })
          .finally(() => setPendingVerifyCode(false));
      })
      .catch((e) => {
        setPendingVerifyCode(false);
        errorAlert(e.message);
      });
  };

  useEffect(() => {
    if (userPhone) {
      setPhone(userPhone);
    }
  }, [userPhone]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutter="label" variant="h6" component="p" weight="bold" color="label">
            {trans('confirm-your-phone')}
          </Typography>
          <div style={{ position: 'relative' }}>
            <PhoneInput
              defaultCountry="US"
              international
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
              style={{ background: '#f1f3f9', padding: '10px', borderRadius: '3px', fontFamily: 'Lexend' }}
            />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ position: 'absolute', right: '10px', top: '11px' }}
              pending={pendingSendCode}
              onClick={handleSetPhone}
            >
              {!sentCode ? trans('send-code') : trans('resend-code')}
            </Button>
          </div>
        </Grid>
        {sentCode && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" component="p" weight="bold" color="label">
                {trans('step-verification')}
              </Typography>
              <Typography variant="h6" component="p" color="label">
                Please verify the CODE sent to your phone registered and enter below
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutter="label" variant="h6" component="p" weight="bold" color="label">
                {trans('enter-code')}
              </Typography>
              <CodeInput fields={6} type="number" onChange={(e) => setCode(e)} />
            </Grid>
          </>
        )}
      </Grid>
      {sentCode && (
        <>
          <Divider light spacing={4} />
          <div style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={setMfa}
              disabled={!phone || code.length < 6}
              pending={pendingVerifyCode}
            >
              {trans('verify')}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default SmsFa;
