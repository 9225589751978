import React, { useState, useEffect } from 'react';
import { SpacingContainer } from '../../../../components/common/Containers/Base';
import { Divider } from '../../../../components/common/Divider';
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard';
import { IconOrganizationDetails } from '../../../../components/svg';
import Typography from '../../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import { useTrans } from '../../../../services/i18n';
import FieldValue from '../../../../components/generics/Helpers/FieldValue';
import { formatDate } from '../../../../utils/dateUtils';
import connect from '../../connect';
import { Button } from '../../../../components/common/Buttons';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
  },
  buttons: {
    display: 'inline-flex',
    gap: '16px',
  },
  viewFullProfileButton: {
    background: '#2445A7',
    borderRadius: 4,
    border: '0.50px rgba(36, 69, 167, 0.40) solid',
    color: 'white',
    fontSize: 12,
    fontFamily: 'Manrope',
    fontWeight: 700,
  },
  linkAccountButton: {
    borderRadius: 4,
    border: '0.50px rgba(36, 69, 167, 0.40) solid',
    color: '#2445A7',
    fontSize: 12,
    fontFamily: 'Manrope',
    fontWeight: 700,
  },
}));

const OrganizationDetails = ({ loading, settings, openRegisterOrg }) => {
  const { trans } = useTrans();
  const history = useHistory();
  const styles = useStyles();
  const [showMore, setShowMore] = useState(false);

  const navegateVendorDetail = () => {
    if (settings.vendor?.id) {
      history.push(`/vendors/${settings.vendor.id}`);
      return;
    }
    const isPublic = settings.orgTeamingRecord?.publicVisibility === 1;
    const hasCertifications = settings.orgTeamingRecord?.officialCertifications?.length > 0;
    if (isPublic && hasCertifications) {
      history.push(`/vendors/local-vendors/${settings.id}`);
      return;
    }
  };

  const manualRegistrationRecordStyles = {
    color: 'black',
    weight: '400',
    weightLabel: '500',
    fontFamily: 'Manrope',
  };

  function formatPhoneNumber(number) {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(+' + match[1] + ') (' + match[2] + ') ' + match[3] + '-' + match[4];
    }
    return number;
  }

  const navegateOrganizationDetails = (id) => {};

  const registerAccountHandler = (editMode) => {
    history.push(`/register-organization`, {
      manualRegistrationRecord: settings.manualRegistrationRecord,
      isEditMode: editMode,
    });
  };

  const linkAccountHandler = () => {
    openRegisterOrg(true);
  };

  return (
    <>
      {Boolean(settings.vendorClaimed) && Boolean(settings.vendor) ? (
        <>
          <SpacingContainer px={5} py={5}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item md={6} sm={12} xs={12}>
                <Typography variant="h3" style={{ fontFamily: 'Lexend', fontWeight: 500, wordWrap: 'break-word' }}>
                  {trans('organization-details')}
                </Typography>
              </Grid>
              <Grid className={styles.buttonsContainer} item md={6} sm={12} xs={12}>
                <div className={styles.buttons}>
                  <Button variant="contained" color="primary" onClick={() => navegateVendorDetail()}>
                    {trans('view-full-profile')}
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue value={formatDate(settings.vendor.entityStartDate)} label={trans('business-start-date')} />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue
                  value={formatDate(settings.vendor.fiscalYearEndCloseDate)}
                  label={trans('fiscal-year-close')}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue value={formatDate(settings.vendor.lastUpdatedDate)} label={trans('last-updated')} />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue value={formatDate(settings.vendor.expirationDate)} label={trans('expiration-date')} />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue value={settings.vendor.ueiCode} label={trans('uei-code')} />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue value={settings.vendor.cageCode} label={trans('cage-code')} />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue value={settings.vendor.doingAsBusiness} label={trans('doing-business-as')} />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue value={''} label={trans('employees')} />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue value={settings.vendor.sbaQualifications} label={trans('sba-qualifications')} />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue label={trans('corporate-url')}>
                  <a
                    style={{ textDecoration: 'none', color: '#2445a7' }}
                    href={settings.vendor.corpUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {settings.vendor.corpUrl}
                  </a>
                </FieldValue>
              </Grid>
            </Grid>
          </SpacingContainer>
          <div
            style={{
              padding: '16px 0',
              borderTop: '1px solid rgba(78, 89, 120, 0.12)',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button onClick={() => navegateVendorDetail(settings.vendor.id)}>{trans('view-full-profile')}</Button>
          </div>
        </>
      ) : settings.manualRegistrationRecord ? (
        <>
          <SpacingContainer px={5} py={5}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item md={6} sm={12} xs={12}>
                <Typography variant="h3" style={{ fontFamily: 'Lexend', fontWeight: 500, wordWrap: 'break-word' }}>
                  {trans('organization-details')}
                </Typography>
              </Grid>
              <Grid className={styles.buttonsContainer} item md={6} sm={12} xs={12}>
                <div className={styles.buttons}>
                  <Button variant="contained" color="primary" onClick={() => navegateVendorDetail()}>
                    {trans('view-full-profile')}
                  </Button>
                  <Button variant="outlined" color="primary" onClick={linkAccountHandler}>
                    {trans('link-existing-account')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      registerAccountHandler(true);
                    }}
                  >
                    {trans('edit')}
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: '24px' }}
            >
              <Grid item md={6} sm={12} xs={12}>
                <Typography variant="h5" style={{ fontFamily: 'Lexend', fontWeight: 500, wordWrap: 'break-word' }}>
                  {trans('register-organization-form-step-1')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '24px', marginBottom: showMore ? '0' : '24px' }}>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item md={6} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                  <FieldValue
                    value={formatDate(settings.manualRegistrationRecord.businessStartDate)}
                    label={trans('business-start-date')}
                    {...manualRegistrationRecordStyles}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <FieldValue
                    value={formatDate(settings.manualRegistrationRecord.updatedAt)}
                    label={trans('last-updated')}
                    {...manualRegistrationRecordStyles}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item md={6} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                  <FieldValue
                    value={settings.manualRegistrationRecord.ueiCode}
                    label={trans('uei-code')}
                    noWrap={false}
                    {...manualRegistrationRecordStyles}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <FieldValue
                    value={settings.manualRegistrationRecord.cageCode}
                    label={trans('cage-code')}
                    noWrap={false}
                    {...manualRegistrationRecordStyles}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item md={6} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                  <FieldValue
                    value={settings.manualRegistrationRecord.dba}
                    label={trans('organization-form-dba')}
                    noWrap={false}
                    {...manualRegistrationRecordStyles}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <FieldValue
                    value={settings.manualRegistrationRecord.companyName}
                    label={trans('organization-form-company-name')}
                    noWrap={false}
                    {...manualRegistrationRecordStyles}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item md={6} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                  <FieldValue
                    value={settings.manualRegistrationRecord.corporateUrl}
                    label={trans('organization-form-corporate-url')}
                    noWrap={false}
                    {...manualRegistrationRecordStyles}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <FieldValue
                    value={settings.manualRegistrationRecord.stateOfIncorporation}
                    label={trans('organization-form-state-of-incorporation')}
                    noWrap={false}
                    {...manualRegistrationRecordStyles}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!showMore && (
              <div
                style={{
                  padding: '16px 24px',
                  borderTop: '1px solid rgba(78, 89, 120, 0.12)',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button onClick={() => setShowMore(!showMore)}>{trans('show-more')}</Button>
              </div>
            )}
            {showMore && (
              <>
                <Grid container style={{ marginBottom: '24px' }}>
                  <Grid container style={{ marginTop: '16px' }}>
                    <Grid item md={6} sm={12} xs={12}>
                      <FieldValue
                        value={formatPhoneNumber(settings.manualRegistrationRecord.phone)}
                        label={trans('organization-form-phone')}
                        noWrap={false}
                        {...manualRegistrationRecordStyles}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <FieldValue
                        value={settings.manualRegistrationRecord.businessHours}
                        label={trans('organization-form-business-hours')}
                        noWrap={false}
                        {...manualRegistrationRecordStyles}
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: '16px' }}>
                    <Grid item md={6} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                      {settings.manualRegistrationRecord &&
                      !!settings.manualRegistrationRecord?.socioEconomicCategories?.length ? (
                        <FieldValue
                          label={trans('organization-form-socioeconomic-categories')}
                          noWrap={false}
                          {...manualRegistrationRecordStyles}
                        >
                          <ul
                            style={{
                              width: '100%',
                              color: 'black',
                              fontSize: 15,
                              fontFamily: 'Manrope',
                              fontWeight: '400',
                              wordWrap: 'break-word',
                              listStyleType: 'disc',
                              paddingLeft: '20px',
                            }}
                          >
                            {settings.manualRegistrationRecord.socioEconomicCategories.map((category, index) => (
                              <li key={index}>{category}</li>
                            ))}
                          </ul>
                        </FieldValue>
                      ) : (
                        <FieldValue
                          label={trans('organization-form-socioeconomic-categories')}
                          value=""
                          noWrap={false}
                          {...manualRegistrationRecordStyles}
                        />
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      {settings.manualRegistrationRecord && settings.manualRegistrationRecord?.corporateStructure ? (
                        <FieldValue
                          label={trans('organization-form-corporate-structure')}
                          noWrap={false}
                          {...manualRegistrationRecordStyles}
                        >
                          <ul
                            style={{
                              width: '100%',
                              color: 'black',
                              fontSize: 15,
                              fontFamily: 'Manrope',
                              fontWeight: '400',
                              wordWrap: 'break-word',
                              listStyleType: 'disc',
                              paddingLeft: '20px',
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                color: 'black',
                                fontSize: 15,
                                fontFamily: 'Manrope',
                                fontWeight: '400',
                                wordWrap: 'break-word',
                              }}
                            >
                              <li>{settings.manualRegistrationRecord.corporateStructure}</li>
                            </div>
                          </ul>
                        </FieldValue>
                      ) : (
                        <FieldValue
                          label={trans('organization-form-corporate-structure')}
                          value=""
                          noWrap={false}
                          {...manualRegistrationRecordStyles}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: '16px' }}>
                    {settings.manualRegistrationRecord &&
                    (!!settings.manualRegistrationRecord?.primaryNaics?.length ||
                      settings.manualRegistrationRecord?.secondaryNaics?.length) ? (
                      <FieldValue label={trans('NAICS-codes')} noWrap={false} {...manualRegistrationRecordStyles}>
                        {settings.manualRegistrationRecord.primaryNaics?.map((i) => (
                          <Typography component="p" key={i}>
                            <Typography variant="h6" weight="500" style={{ color: '#2445A7' }}>
                              {`Primary - ${i}`}
                            </Typography>
                          </Typography>
                        ))}

                        {settings.manualRegistrationRecord.secondaryNaics?.map((i) => (
                          <Typography component="p" key={i}>
                            <Typography variant="h6" weight="500" style={{ color: '#2445A7' }}>
                              {`${i}`}
                            </Typography>
                          </Typography>
                        ))}
                      </FieldValue>
                    ) : (
                      <FieldValue
                        label={trans('NAICS-codes')}
                        value=""
                        noWrap={false}
                        {...manualRegistrationRecordStyles}
                      />
                    )}
                  </Grid>
                  <Grid container style={{ marginTop: '16px' }}>
                    {settings.manualRegistrationRecord && !!settings.manualRegistrationRecord?.pscCodes?.length ? (
                      <FieldValue label={trans('PSC-Codes')} noWrap={false} {...manualRegistrationRecordStyles}>
                        {settings.manualRegistrationRecord.pscCodes?.map((i) => (
                          <Typography component="p" key={i.code}>
                            <Typography variant="h6" weight="500" style={{ color: '#2445A7' }}>
                              {`${i}`}
                            </Typography>
                          </Typography>
                        ))}
                      </FieldValue>
                    ) : (
                      <FieldValue
                        label={trans('PSC-Codes')}
                        value=""
                        noWrap={false}
                        {...manualRegistrationRecordStyles}
                      />
                    )}
                  </Grid>
                </Grid>
                <div
                  style={{
                    padding: '16px 24px',
                    borderTop: '1px solid rgba(78, 89, 120, 0.12)',
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button onClick={() => setShowMore(!showMore)}>{trans('show-less')}</Button>
                </div>
              </>
            )}
          </SpacingContainer>
        </>
      ) : (
        <NothingFoundCard
          title={trans('register-your-organization')}
          description={trans('register-your-organization-description')}
          Icon={<IconOrganizationDetails />}
          py={10}
          size="small"
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  registerAccountHandler(true);
                }}
              >
                {trans('register-account')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={linkAccountHandler}>
                {trans('link-existing-account')}
              </Button>
            </Grid>
          </Grid>
        </NothingFoundCard>
      )}
    </>
  );
};

export default connect(OrganizationDetails);
