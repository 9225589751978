import React, { useEffect, useState } from 'react';
import ActionsPanel from './components/ActionsPanel';
import Header from './components/Header';
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard';
import { Table, TableCell, TableHead, TableRow } from '../../../../components/common/ResponsiveTable';
import { useTrans } from '../../../../services/i18n';
import { Grid, Button, TableContainer, List, ListItem, ListItemText } from '@material-ui/core';
import styles from './styles.module.css';
import connect from '../connect';
import { emptyArray } from '../../../../utils/arrayUtils';
import { ReactComponent as VendorsIcon } from '../icons/ico-vendor-management-empty.svg';
import { ReactComponent as NoArchivedIcon } from '../icons/ico-no-archived.svg';
import AddVendorManuallyForm from '../components/AddVendorManuallyForm';
import AddVendorForm from '../components/AddVendorForm';
import AddVendorTagsForm from '../components/AddVendorTagsForm';
import AddVendorsCsvForm from '../components/AddVendorsCsvForm';
import { CheckBox } from '../../../../components/common/Inputs/CheckBox';
import { formatDatePretty } from '../../../../utils/dateUtils';
import Loader from '../../../..//components/common/Loader';
import { makeStyles } from '@material-ui/core/styles';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import { ModalDialog } from '../../../../components/common/Dialogs';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { SpacingContainer } from '../../../../components/common/Containers/Base';
import SectionScrollable from '../../../../components/common/Containers/SectionScrollable';
import Typography from '../../../../components/common/Typography';
import { Divider } from '../../../../components/common/Divider';
import CodeDescription from './components/CodeDescription';
import { useHistory } from 'react-router-dom';
import { ROUTE_VENDORS_MANAGEMENT_DETAILS, ROUTE_VENDORS_DETAILS } from '../../../../constants/routes';
import { RouterLink } from '../../../../components/common/Buttons';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  linkStyle: {
    color: '#2445A7',
    cursor: 'pointer',
  },
});

const modalNames = {
  naics: 'NAICS Codes',
  setAsides: 'Set asides codes',
  psc: 'PSC codes',
  tags: 'Tags',
};

const VendorsManagementList = ({
  currentView,
  items,
  count,
  getList,
  itemCheck,
  filters,
  openAddVendorModal,
  openAddVendor,
  openAddVendorManuallyModal = false,
  openAddVendorManually,
  closeDrawer,
  openAddTagsModal,
  openAddTags,
  openImportVendorsCsvModal,
  openImportVendors,
  tableColumns,
  getVendorTags,
  pending,
  filterDefault,
}) => {
  const { trans } = useTrans();
  const history = useHistory();
  const classes = useStyles();
  const [open, onOpen, onClose] = useBooleanHandlers();

  const [modalName, setModalName] = useState([]);
  const [codes, setCodes] = useState([]);

  const showCodes = (column, codes) => {
    setModalName(column);
    setCodes(codes);
    onOpen();
  };

  const getStatusStyleCell = (status, archived) => {
    if (archived) return styles.archived;
    if (status === 'Registered') return styles.registered;
    if (status === 'Unregistered') return styles.unregistered;
  };

  const onItemCheck = (ev, item) => {
    itemCheck({ id: item.id, checked: ev.target.checked });
  };

  const getTableCell = (c, item) => {
    const redirect = () => {
      if (!item) return '';
      if (item.status === 'Unregistered') {
        return ROUTE_VENDORS_MANAGEMENT_DETAILS.path.replace(':id', item.id);
      }
      if (item.status === 'Registered') {
        return ROUTE_VENDORS_DETAILS.path.replace(':id', item.documentId);
      }
    };

    if (c.value === 'name') {
      return (
        <TableCell className={classes.linkStyle}>
          <Typography color="primary" component={RouterLink} to={redirect()} noWrap={false}>
            {item[c.value]}
          </Typography>
        </TableCell>
      );
    }
    if (c.value === 'status')
      return (
        <TableCell className={getStatusStyleCell(item.status, item.archived)}>
          <p>{item.archived ? 'Archived' : item.status}</p>
        </TableCell>
      );

    if (c.value === 'expirationDate') return <TableCell>{formatDatePretty(item[c.value])}</TableCell>;
    if (c.value === 'bussinessStartDate') return <TableCell>{formatDatePretty(item[c.value])}</TableCell>;

    if (['naics', 'setAsides', 'psc', 'tags'].includes(c.value)) {
      return (
        <TableCell>
          {!emptyArray(item[c.value]) ? (
            <Button variant="contained" size="small" color="primary" onClick={() => showCodes(c.value, item[c.value])}>
              View All
            </Button>
          ) : (
            'Not provided'
          )}
        </TableCell>
      );
    }

    return <TableCell>{item[c.value]}</TableCell>;
  };

  useEffect(() => {
    getVendorTags();
  }, [getList]);

  return (
    <>
      <div style={{ padding: '10px' }}>
        <Loader open={pending} />
        <AddVendorManuallyForm open={openAddVendorManuallyModal} onClose={() => openAddVendorManually(false)} />
        <AddVendorForm open={openAddVendorModal} onClose={() => openAddVendor(false)} />
        <AddVendorTagsForm open={openAddTagsModal} onClose={() => openAddTags(false)} />
        <AddVendorsCsvForm open={openImportVendorsCsvModal} onClose={() => openImportVendors(false)} />
        <Header closeDrawer={closeDrawer} />
        <ActionsPanel />
        {!emptyArray(items) ? (
          <TableContainer style={{ width: 'auto' }}>
            <Table
              className={classes.table}
              size="small"
              stickyHeader
              fixedLayout
              head={
                <TableHead>
                  <TableCell className={styles.tableHeadBg} width={60} noWrap></TableCell>
                  {tableColumns
                    .filter((i) => i.active)
                    .map((c) => (
                      <TableCell width={200} className={styles.tableHeadBg} key={c.id} noWrap>
                        {trans(c.name)}
                      </TableCell>
                    ))}
                </TableHead>
              }
            >
              {items.map(
                (item, index) =>
                  item && (
                    <TableRow key={index}>
                      <TableCell>
                        <CheckBox
                          color="secondary"
                          onChange={(ev) => onItemCheck(ev, item)}
                          checked={item.checked}
                          md={{ '& .MuiSvgIcon-root': { fontSize: 50 } }}
                        />
                      </TableCell>
                      {tableColumns.filter((i) => i.active).map((c) => getTableCell(c, item))}
                    </TableRow>
                  )
              )}
            </Table>
          </TableContainer>
        ) : currentView === 'vendors' && !pending ? (
          <NothingFoundCard
            title={trans('you-add-no-vendors-yet')}
            description={trans('all-manually-added')}
            size="large"
            Icon={<VendorsIcon />}
          >
            <br />
            <br />
            <Button color="primary" onClick={() => openAddVendor(true)}>
              {trans('search-vendor')}
            </Button>
          </NothingFoundCard>
        ) : currentView === 'vendorsArchived' && !pending ? (
          <NothingFoundCard
            title={trans('your-archive-is-empty')}
            description={trans('all-archived-vendors')}
            size="large"
            Icon={<NoArchivedIcon />}
          ></NothingFoundCard>
        ) : (
          !pending && (
            <NothingFoundCard
              title={trans('your-draft-is-empty')}
              description={trans('all-draft-vendors')}
              size="large"
              Icon={<BorderColorOutlinedIcon style={{ width: '64px', height: '64px' }} />}
            ></NothingFoundCard>
          )
        )}
      </div>
      <ModalDialog open={open} onClose={onClose} disableContentSpacing title={modalNames[modalName]}>
        <SectionScrollable dividedFixed>
          <SpacingContainer px={4} py={1}>
            <List>
              {codes.map((item, index) => (
                <React.Fragment key={item.code}>
                  <ListItem disableGutters>
                    <ListItemText>
                      <Typography fontFamily="lexend" color="primary" variant="body1" weight="bold">
                        {item.code || item}
                      </Typography>
                      {item.description && (
                        <Grid container>
                          <Grid item>
                            <CodeDescription item={item} />
                          </Grid>
                        </Grid>
                      )}
                    </ListItemText>
                  </ListItem>

                  {index + 1 < codes.length && <Divider light spacing={0} />}
                </React.Fragment>
              ))}
            </List>
          </SpacingContainer>
        </SectionScrollable>
      </ModalDialog>
    </>
  );
};

export default connect(VendorsManagementList);
