import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconAttachment = (props) => {
  const { width = 17, height = 17 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 16 17">
      <path
        d="M17.317 10.29c1.438-1.453 3.775-1.453 5.213 0 1.385 1.4 1.433 3.635.143 5.092l-.143.153-6.626 6.688c-.876.886-2.3.886-3.175 0-.83-.838-.87-2.17-.118-3.056l.118-.129 6.115-6.172c.31-.313.817-.316 1.131-.005.286.283.313.727.082 1.041l-.077.09-6.114 6.172c-.254.257-.254.677 0 .934.225.227.573.25.822.068l.08-.068 6.625-6.688c.817-.826.817-2.168 0-2.994-.772-.78-1.996-.82-2.814-.117l-.125.117-6.619 6.688c-1.38 1.395-1.38 3.66 0 5.054 1.326 1.34 3.444 1.388 4.827.143l.15-.143 6.62-6.689c.31-.314.817-.316 1.13-.006.286.283.314.727.084 1.042l-.077.09-6.62 6.688c-2 2.023-5.25 2.023-7.251 0-1.939-1.959-1.996-5.095-.171-7.123l.17-.181 6.62-6.689z"
        transform="translate(-303 -1659) translate(251 182) translate(0 1356) translate(43 107) translate(0 5)"
      />
    </SvgIcon>
  );
};

IconAttachment.propTypes = simpleIconPropsTypes;
