import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconLearningCenter = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 190 190" noFill={true}>
      <path d="M24 48V108" stroke="#4E5978" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M40.65 162C46.6535 152.792 54.8594 145.227 64.5243 139.991C74.1892 134.755 85.0078 132.012 96 132.012C106.992 132.012 117.811 134.755 127.476 139.991C137.141 145.227 145.347 152.792 151.35 162"
        stroke="#4E5978"
        stroke-width="17"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M168 48L96 72L24 48L96 24L168 48Z"
        stroke="#4E5978"
        stroke-width="17"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.975 61.65C132.479 67.6604 136.115 75.1421 137.441 83.1832C138.766 91.2243 137.724 99.4774 134.44 106.936C131.157 114.395 125.774 120.738 118.949 125.191C112.123 129.644 104.15 132.015 96 132.015C87.8504 132.015 79.8768 129.644 73.0513 125.191C66.2258 120.738 60.8432 114.395 57.5597 106.936C54.2763 99.4774 53.2338 91.2243 54.5593 83.1832C55.8849 75.1421 59.5212 67.6604 65.025 61.65"
        stroke="#4E5978"
        stroke-width="17"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

IconLearningCenter.propTypes = simpleIconPropsTypes;
