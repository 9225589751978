import React from 'react';
import { Paper, SpacingContainer } from '../../../../components/common/Containers';

import Typography from '../../../../components/common/Typography';
import { useTrans } from '../../../../services/i18n';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  gridsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '2.5rem',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5rem',
    gap: '0.5rem',
    boxShadow: '0px 10px 20px 0px rgba(78, 89, 120, 0.06)',
  },
  dividerShadow: {
    boxShadow: '0px 10px 20px 0px rgba(78, 89, 120, 0.06)',
  },
  cardContainer: {
    margin: '0 0 2.5rem 0',
  },
}));

const Card = ({ children, title, icon, titleChild }) => {
  const { trans } = useTrans();
  const styles = useStyles();

  return (
    <Paper className={styles.cardContainer}>
      <SpacingContainer className={styles.title}>
        {icon}
        <Typography fontFamily="lexend" variant="h5" weight="500">
          {title}
        </Typography>
        {titleChild || titleChild}
      </SpacingContainer>

      {children ? (
        children
      ) : (
        <SpacingContainer px="5sm3" py={3}>
          <Typography color="hint">{trans('not-listed')}</Typography>
        </SpacingContainer>
      )}
    </Paper>
  );
};

export default Card;
