import React, { useEffect, useState } from 'react';
import { useFormData } from '../../index';
import { useApplication } from '../../../../services/application';
import { API } from '../../../../constants';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '../../../../components/common/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTrans } from '../../../../services/i18n';
import { SelectField, TextField, Autocomplete } from '../../../../components/common/Inputs';
import { Grid } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAlertContext } from '../../../../contexts/AlertContext';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Divider } from '../../../../components/common/Divider';
import { Button } from '../../../../components/common/Buttons';
import { ReactComponent as ArrowBackIcon } from '../../icons/arrow-left.svg';
import * as yup from 'yup';
import { DATE_FORMAT_MM_DD_YYYY } from '../../../../constants/regex';

const useStyles = makeStyles((theme) => ({
  labelDropdowns: {
    fontFamily: 'Lexend',
    marginBottom: '8px',
    color: '#4e5978',
    fontWeight: 500,
  },
  containerCard: {
    padding: '2.125rem 1.5rem 1rem',
  },
  containerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.3rem 2.3rem 1.87rem 2.3rem',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  backButton: {
    color: '#999999',
    fontSize: 18,
    fontFamily: 'Manrope',
    fontWeight: '500',
    wordWrap: 'break-word',
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cancelNextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const generalInformationSchema = yup.object().shape({
  companyName: yup.string().required('Company name is required'),
  businessStartDate: yup
    .string()
    .nullable(true)
    .when([], {
      is: (value) => !value,
      then: yup.string().notRequired(),
      otherwise: yup.string().matches(DATE_FORMAT_MM_DD_YYYY, 'Date format must be MM/DD/YYYY'),
    }),
});

export default function GeneralInformation({
  nextStep,
  previousStep,
  cancelButtonHandler,
  isEdit,
  onCompletionChange,
  initialValues,
}) {
  const { generalInformationData, setGeneralInformationData } = useFormData();
  const application = useApplication();
  const [states, setStates] = useState([]);
  const [errors, setErrors] = useState({});
  const [profitType, setProfitType] = useState(initialValues.profitType || null);
  const { trans } = useTrans();
  const [phone, setPhone] = useState(generalInformationData?.phone || null);
  const styles = useStyles();
  const { successAlert, errorAlert } = useAlertContext();
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    const payload = await application.call(API.UTILITIES.GET_STATES_BY_COUNTRY, { country: 'US' });
    setStates(payload.map(({ name, isoCode }) => ({ id: isoCode, name })));
  };

  const handleChangePhone = (value) => {
    setPhone(value);
    setGeneralInformationData({
      ...generalInformationData,
      phone: value,
    });
  };

  useEffect(() => {
    if (onCompletionChange) {
      onCompletionChange(isComplete);
    }
  }, [isComplete, onCompletionChange]);

  const handleProfitChange = (event) => {
    setProfitType(event.target.value);
    setGeneralInformationData({
      ...generalInformationData,
      profitType: event.target.value,
    });
  };

  const handleNextClick = () => {
    try {
      generalInformationSchema.validateSync(generalInformationData, { abortEarly: false });
      onCompletionChange(true);
      nextStep();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });
        setErrors(errorMessages);
      }
      onCompletionChange(false);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} className={styles.containerCard}>
        <div
          style={{
            width: '100%',
            height: '100%',
            paddingLeft: 40,
            paddingRight: 40,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 24,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: 'black',
              fontSize: 21,
              fontFamily: 'Manrope',
              fontWeight: '700',
              wordWrap: 'break-word',
            }}
          >
            General Information
          </div>
          <div style={{ alignSelf: 'stretch', height: 1, background: '#D9D9D9' }} />
        </div>
        <Grid item sm={6} xs={12} px={0}>
          <TextField
            type="text"
            label={trans('organization-form-company-name')}
            value={generalInformationData.companyName || ''}
            onChange={(e) => {
              setGeneralInformationData({
                ...generalInformationData,
                companyName: e.target.value,
              });
            }}
            error={!!errors.companyName}
            helperText={errors.companyName}
            placeholder={trans('organization-form-company-name')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="text"
            label={trans('organization-form-business-start-date')}
            value={generalInformationData.businessStartDate || ''}
            onChange={(e) => {
              setGeneralInformationData({
                ...generalInformationData,
                businessStartDate: e.target.value,
              });
            }}
            error={!!errors.businessStartDate}
            helperText={errors.businessStartDate}
            placeholder={trans('organization-form-business-start-date')}
          />
        </Grid>
        <Grid item sm={6} xs={12} px={0}>
          <TextField
            type="text"
            label={trans('organization-form-uei-code')}
            value={generalInformationData.ueiCode || ''}
            onChange={(e) => {
              setGeneralInformationData({
                ...generalInformationData,
                ueiCode: e.target.value,
              });
            }}
            placeholder={trans('uei-mayus')}
          />
        </Grid>
        <Grid item sm={6} xs={12} px={0}>
          <TextField
            type="text"
            label={trans('organization-form-cage-code')}
            value={generalInformationData.cageCode || ''}
            onChange={(e) => {
              setGeneralInformationData({
                ...generalInformationData,
                cageCode: e.target.value,
              });
            }}
            placeholder={trans('cage-mayus')}
          />
        </Grid>
        <Grid item sm={6} xs={12} px={0}>
          <TextField
            type="text"
            label={trans('organization-form-dba')}
            value={generalInformationData.dba || ''}
            onChange={(e) => {
              setGeneralInformationData({
                ...generalInformationData,
                dba: e.target.value,
              });
            }}
            placeholder={trans('dba')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Autocomplete
            options={states}
            value={states.find((state) => state.name === generalInformationData.stateOfIncorporation) || null}
            label={trans('organization-form-state-of-incorporation')}
            placeholder={trans('organization-form-state-of-incorporation')}
            disableClearable
            onChange={(event) => {
              const selectedOption = event.target.value;
              setGeneralInformationData((prev) => ({
                ...prev,
                stateOfIncorporation: selectedOption ? selectedOption.name : null,
              }));
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12} px={0}>
          <TextField
            type="text"
            label={trans('organization-form-corporate-url')}
            placeholder={trans('organization-form-corporate-url')}
            value={generalInformationData.corporateUrl || ''}
            onChange={(e) => {
              setGeneralInformationData({
                ...generalInformationData,
                corporateUrl: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
            {trans('organization-form-phone')}
          </Typography>
          <PhoneInput
            defaultCountry="US"
            placeholder="Enter phone number"
            value={phone}
            onChange={handleChangePhone}
            style={{ background: '#f1f3f9', padding: '10px', borderRadius: '3px', fontFamily: 'Lexend' }}
          />
        </Grid>
        <Grid item sm={6} xs={12} px={0}>
          <TextField
            type="text"
            label={trans('organization-form-business-hours')}
            placeholder={trans('organization-form-business-hours')}
            value={generalInformationData.businessHours || ''}
            onChange={(e) => {
              setGeneralInformationData({
                ...generalInformationData,
                businessHours: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
            {trans('organization-form-profit-structure')}
          </Typography>
          <div
            style={{
              width: '100%',
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 0,
              paddingBottom: 0,
              background: 'white',
              borderRadius: 4,
              border: '0.50px rgba(0, 0, 0, 0.08) solid',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'inline-flex',
            }}
          >
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={profitType}
              onChange={handleProfitChange}
              row
              style={{ width: '50%', verticalAlign: 'center' }}
            >
              <FormControlLabel
                color="primary"
                style={{
                  color: `#2445A7`,
                  width: '100%',
                  marginLeft: '1px',
                }}
                value="profit"
                control={<Radio color="secondary" />}
                label="For Profit"
              />
            </RadioGroup>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={profitType}
              onChange={handleProfitChange}
              row
              style={{ width: '50%', verticalAlign: 'center' }}
            >
              <FormControlLabel
                color="primary"
                style={{
                  color: `#2445A7`,
                  width: '100%',
                  marginLeft: '1px',
                }}
                value="nonprofit"
                control={<Radio color="secondary" />}
                label="Nonprofit"
              />
            </RadioGroup>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={profitType}
              onChange={handleProfitChange}
              row
              style={{ width: '50%', verticalAlign: 'center' }}
            >
              <FormControlLabel
                color="#F32445"
                style={{
                  color: `#2445A7`,
                  width: '100%',
                  marginLeft: '1px',
                }}
                value="other"
                control={<Radio color="secondary" />}
                label="Other"
              />
            </RadioGroup>
          </div>
        </Grid>
      </Grid>
      <Divider light spacing={1} />
      <Grid container className={styles.containerFooter}>
        <div className={styles.backButtonContainer}>
          <ArrowBackIcon />
          <div className={styles.backButton} onClick={previousStep}>
            {trans('organization-form-back-button')}
          </div>
        </div>
        <div className={styles.cancelNextButtonContainer}>
          <Button variant="contained" color="primary" onClick={cancelButtonHandler}>
            {trans('organization-form-cancel-button')}
          </Button>
          <Button variant="outlined" color="primary" onClick={handleNextClick} style={{ marginLeft: '10px' }}>
            {trans('organization-form-next-button')}
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  );
}
