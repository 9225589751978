import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { PROD, TEST } from './constants';

function UserPilotScripts({ user, orgSettings, stage: { env } }) {
  const location = useLocation();
  const isPublic = orgSettings.orgTeamingRecord?.publicVisibility === 1;
  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  const createdAt = useMemo(() => {
    const organization = user?.organizations?.find((o) => o.organizationId === orgSettings.id);
    return organization?.createdAt;
  }, [user, orgSettings]);

  const userRole = useMemo(() => {
    const orgUser = orgSettings?.users?.find((u) => u.email === user.email);
    return orgUser?.role;
  }, [user, orgSettings]);

  const identifyData = {
    name: `${user.name} ${user.familyName}`,
    email: user.email,
    first_name: user.name,
    last_name: user.familyName,
    created_at: dayjs(user.createdAt).unix().toString(),
    role: userRole,
    login_count: user.loginCount,
    project_Id: '',
    app_language: 'en',
    plan_tier: orgSettings.planTier,
    company: {
      id: orgSettings.id,
      name: orgSettings.orgName,
      created_at: dayjs(createdAt).unix().toString(),
      company_country: 'US',
      trial_ends: '',
    },
    account_claimed: !!orgSettings.vendorClaimed || false,
    profile_filled: (!!orgSettings.onPublicProfile && !!orgSettings.publicProfile) || false,
    saved_search: user.hasSavedSearches || false,
    org_filled: Boolean(orgSettings?.manualRegistrationRecord) || false,
    teaming_filled: Boolean(orgSettings?.orgTeamingRecord) || false,
    pending_form: orgSettings?.hasPendingForms || false,
    pending_document: orgSettings?.hasPendingDocuments || false,
    num_of_orgs: user.organizations?.length || 0,
    public_profile: isPublic,
  };

  if (!user || ![TEST, PROD].includes(env)) return null;

  Userpilot.identify(user.id, identifyData);

  return null;
}

export default UserPilotScripts;
