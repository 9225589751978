import React from 'react';
import { useApiAutocomplete } from '../../hooks/useApiAutocomplete';
import { useApplication } from '../../services/application';
import { getLocation } from '../../utils/opportunityUtils';
import Autocomplete from '../common/Inputs/Autocomplete';

const getOptionLabel = (option) => (!!option ? getLocation(option) : '');

const setLocationValue = (event) => {
  const { value } = event.target;
  return { target: { ...event.target, value: value.map((v) => ({ ...v, value: getLocation(v) })) } };
};

export const PlaceOfPerformanceAutocomplete = (props) => {
  const { onChange, value, multi, labelKey = 'value', label, ...rest } = props;
  const application = useApplication();
  const { options, loading, handleValueChange, filterOptions, handleInputChange } = useApiAutocomplete({
    labelKey,
    onChange: React.useCallback((val) => onChange && onChange(setLocationValue(val)), [onChange]),
    getOptionList: (params) => application.call('contractOpportunities.getPlaceOfPerformanceList', params),
  });

  const validateValueArray = () => {
    if (Array.isArray(value)) {
      return value;
    } else {
      return [];
    }
  }

  return (
    <Autocomplete
      name="place-of-performance"
      options={options}
      label={label}
      labelKey={labelKey}
      loading={loading}
      value={validateValueArray()}
      filterOptions={filterOptions}
      onChange={handleValueChange}
      multiple={multi}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};
