import React from 'react';
import { Grid, Button, IconButton } from '@material-ui/core';

import MoreHoriz from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import VendorHeader from '../../VendorHeader';
import { SpacingContainer, Paper } from '../../../../../../components/common/Containers';
import DetailsContentBlock from '../../../../../../components/generics/Helpers/DetailsContentBlock';
import ListItemTextBase from '../../../../../../components/common/Containers/Base/ListItemTextBase';
import { ContextMenuListItem, Popover } from '../../../../../../components/common/Dialogs/Popover';
import { useTrans } from '../../../../../../services/i18n';
import { Table, TableCell, TableHead, TableRow } from '../../../../../../components/common/ResponsiveTable';
import Typography from '../../../../../../components/common/Typography';
import usePopover from '../../../../../../hooks/usePopover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { IconDelete, IconPencil } from '../../../../../../components/svg';
import MemberModal from './MemberModal';
import ConfirmDialog from '../../../../../../components/generics/Confirm/Dialog';
import PaginationSmall from '../../../../../../components/common/Paginations/PaginationSmall';
import { useAlertContext } from '../../../../../../contexts/AlertContext';

const useStyles = makeStyles((theme) => ({
  cell: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

const Members = ({ item, actions, members, removeVendorMember }) => {
  const { trans } = useTrans();
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpenMemberModal, setIsOpenMemberModal] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [currentMember, setCurrentMember] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { successAlert, errorAlert } = useAlertContext();

  const [anchorEl, onOpen, onClosePopover] = usePopover();

  const { items, pending, pagination, count } = members;
  const { onInit, onChangePage, onChangeRowPerPage } = actions;

  /***
   *
   */
  const onOpenPopover = (member) => {
    return (e) => {
      setCurrentMember(member);
      onOpen(e);
    };
  };

  /***
   *
   */
  const openMemberModal = (member) => {
    return () => {
      setIsOpenMemberModal(true);
      member && setCurrentMember(member);
    };
  };

  /***
   *
   */
  const closeMemberModal = () => {
    setCurrentMember(null);
    setIsOpenMemberModal(false);
  };

  /***
   *
   */
  const onCloseConfirm = () => {
    setCurrentMember(null);
    setOpenConfirm(false);
  };

  /***
   *
   */
  const onOpenConfirm = (member) => {
    return () => {
      setOpenConfirm(true);
      member && setCurrentMember(member);
    };
  };

  /***
   *
   */
  React.useEffect(() => {
    if (!item.id) return;
    onInit({ vendorManagementId: item.id });
  }, [onInit, item]);

  /***
   * Fix error when the last item in a page different from 1 is deleted
   */
  React.useEffect(() => {
    if (count > 0 && count <= (pagination.page - 1) * pagination.perPage) {
      onChangePage(pagination.page - 1);
    }
  }, [count]);

  return (
    <Paper hidden>
      <MemberModal open={isOpenMemberModal} onClose={closeMemberModal} member={currentMember} />
      <Popover id={'id-users-actions'} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        <ContextMenuListItem button onClick={openMemberModal()}>
          <ListItemTextBase>{trans('edit')}</ListItemTextBase>
        </ContextMenuListItem>

        <ContextMenuListItem button onClick={onOpenConfirm()} onClickCB={onClosePopover}>
          <ListItemTextBase>{trans('delete')}</ListItemTextBase>
        </ContextMenuListItem>
      </Popover>

      <ConfirmDialog
        open={openConfirm}
        onClose={onCloseConfirm}
        onConfirm={async () => {
          try {
            setLoading(true);
            if (currentMember.id) {
              const resp = await removeVendorMember({ id: currentMember.id });
              if (resp?.error) {
                errorAlert('Error deleting Member');
              } else {
                successAlert('Success!');
                onCloseConfirm();
                // Get all items in order to change the current page if applies
                onInit({ vendorManagementId: item.id });
              }
            }
            setLoading(false);
          } catch (error) {
            errorAlert('Error deleting Member');
            setLoading(false);
          }
        }}
        noIcon
        pending={loading}
        title={trans('delete-org-member')}
        noText
        withOutBackground
      >
        <Typography variant="h2">{trans('confirm-delete-org-member')}</Typography>
        <Typography
          variant="body1"
          color="secondary"
          weight="500"
        >{`${currentMember?.name} ${currentMember?.familyName}`}</Typography>
      </ConfirmDialog>
      <VendorHeader item={item} />
      <SpacingContainer>
        <DetailsContentBlock
          title={trans('org-members')}
          pending={pending}
          actions={
            isMobile ? (
              <Button variant="contained" color="primary" onClick={openMemberModal()} size="md">
                <AddIcon />
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={openMemberModal()}>
                {trans('add-member')}
              </Button>
            )
          }
        >
          <Grid container alignItems="center" spacing={2} xs={12}>
            <Grid item xs={12}>
              {items?.length > 0 && (
                <>
                  <SpacingContainer px="4sm3" py={0}>
                    <Table
                      stickyHeader
                      head={
                        <TableHead>
                          <TableCell>{trans('full-Name')}</TableCell>
                          <TableCell>{trans('job-title')}</TableCell>
                          <TableCell width={0}>{trans('role')}</TableCell>
                          <TableCell width={0}>{trans('phone')}</TableCell>
                          <TableCell width={0}>{trans('email')}</TableCell>
                          <TableCell width={0}>{trans('Actions')}</TableCell>
                        </TableHead>
                      }
                    >
                      {items.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell label={trans('full-Name')}>
                            <Typography variant="body2">{`${row.name} ${row.familyName}`}</Typography>
                          </TableCell>
                          {isMobile && (
                            <TableCell className={styles.cell} align="right">
                              <IconButton onClick={onOpenPopover(row)} size="small">
                                <MoreHoriz />
                              </IconButton>
                            </TableCell>
                          )}
                          {isMobile ? (
                            <>
                              <Grid item xs={6}>
                                <TableCell label={trans('job-title')} style={{ borderBottomStyle: 'none' }}>
                                  <Typography variant="body2">{row.jobTitle || trans('not-provided')}</Typography>
                                </TableCell>
                              </Grid>
                              <Grid item xs={6}>
                                <TableCell label={trans('role')} style={{ borderBottomStyle: 'none' }}>
                                  <Typography variant="body2">{row.role || trans('not-provided')}</Typography>
                                </TableCell>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <TableCell label={trans('job-title')}>
                                <Typography variant="body2">{row.jobTitle || trans('not-provided')}</Typography>
                              </TableCell>
                              <TableCell label={trans('role')}>
                                <Typography variant="body2">{row.role || trans('not-provided')}</Typography>
                              </TableCell>
                            </>
                          )}
                          {isMobile ? (
                            <>
                              <Grid item xs={6}>
                                <TableCell
                                  label={trans('phone')}
                                  respWidth="auto"
                                  style={{ borderBottomStyle: 'none' }}
                                >
                                  {row.phone || trans('not-provided')}
                                </TableCell>
                              </Grid>
                              <Grid item xs={6}>
                                <TableCell
                                  label={trans('email')}
                                  respWidth="auto"
                                  style={{ borderBottomStyle: 'none' }}
                                >
                                  {row.email || trans('not-provided')}
                                </TableCell>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <TableCell label={trans('phone')} respWidth="auto">
                                {row.phone || trans('not-provided')}
                              </TableCell>
                              <TableCell label={trans('email')} respWidth="auto">
                                {row.email || trans('not-provided')}
                              </TableCell>
                            </>
                          )}
                          {!isMobile && (
                            <Grid item xs={12}>
                              <TableCell>
                                <IconButton onClick={openMemberModal(row)}>
                                  <IconPencil />
                                </IconButton>
                                <IconButton onClick={onOpenConfirm(row)}>
                                  <IconDelete />
                                </IconButton>
                              </TableCell>
                            </Grid>
                          )}
                        </TableRow>
                      ))}
                    </Table>
                  </SpacingContainer>
                  <PaginationSmall
                    {...pagination}
                    count={count}
                    onChangePage={onChangePage}
                    pending={pending}
                    onChangeRowPerPage={onChangeRowPerPage}
                    px="5sm3"
                    py={2}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </DetailsContentBlock>
      </SpacingContainer>
    </Paper>
  );
};

export default Members;
