import * as Yup from 'yup';
import makeValidationSchema from '../../../../../hooks/makeValidationSchema';

export const initialValues = {
  name: '',
  cageCode: '',
  //dunsCode: '',
  ueiCode: '',
  naicsCode: [],
  setAsides: [],
  expirationDate: '',
  bussinessStartDate: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    name: Yup.string().required(trans('required-field')),
    cageCode: Yup.string(),
    //dunsCode: Yup.string(),
    ueiCode: Yup.string(),
  })
);
