import { createAsyncThunk } from '@reduxjs/toolkit';
import { application } from '../../../services/application';
import { INITIAL_FUNDING_STATE } from '../common';

export function generateReportsActions({ scope, apiMethod, getStore }) {
  if (!(scope && apiMethod && typeof getStore === 'function')) return {};
  return {
    fetchFunding: createAsyncThunk(`${scope}/getFunding`, async (params) => {
      return application.call(apiMethod.GET_FUNDING, params);
    }),
    fetchAwardsCount: createAsyncThunk(`${scope}/getAwardsCount`, async (params) => {
      return application.call(apiMethod.GET_AWARDS_COUNT, params);
    }),
  };
}

export function extendBuilderWithReportsActions(builder, actions, getStore) {
  const { fetchFunding, fetchAwardsCount } = actions;
  const getStoreValues = getStore ? getStore : (state) => state;
  if (fetchFunding) {
    builder
      .addCase(fetchFunding.fulfilled, (state, action) => {
        const store = getStoreValues(state);
        if (!action.payload.itemsYear) return Object.assign(state, { funding: INITIAL_FUNDING_STATE });
        return Object.assign(store, {
          funding: { itemsYear: action.payload.itemsYear, itemsMonth: action.payload.itemsMonth, pending: false },
        });
      })
      .addCase(fetchFunding.pending, (state) => {
        const store = getStoreValues(state);
        return Object.assign(store, { funding: { ...store.funding, pending: true } });
      });
  }

  if (fetchAwardsCount) {
    builder
      .addCase(fetchAwardsCount.fulfilled, (state, action) => {
        const store = getStoreValues(state);
        return Object.assign(store, {
          awards: { itemsYear: action.payload.itemsYear, itemsMonth: action.payload.itemsMonth, pending: false },
        });
      })
      .addCase(fetchAwardsCount.pending, (state) => {
        const store = getStoreValues(state);
        return Object.assign(store, { awards: { ...store.awards, pending: true } });
      });
  }
}
