import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const initialValues = {
  type: '',
  subject: '',
  description: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    type: Yup.string().required(trans('required-field')),
    subject: Yup.string().required(trans('required-field')),
    description: Yup.string().required(trans('required-field')).nullable(),
  })
);

const SAM_REGISTRATION_TYPE = 'SAM Registration';
const SPECIAL_SERVICES_TYPE = 'Special Services';
const SIMPLIFIED_ACQUISITION_TYPE = 'Simplified Acquisitions Program';

export const categoryTypeInfoCaption = {
  [SAM_REGISTRATION_TYPE]:
    'When creating this ticket, you are reaching out to your Case Manager for questions or concerns regarding your SAM Registration and aspects concerning this. This can be about your UEI, CAGE, timeframe, or documents that are being requested.',
  [SPECIAL_SERVICES_TYPE]:
    'When creating this ticket, you are reaching out to your Special Services Case Manager in regards to questions or concerns regarding these services: WAWF, ED/WOSB, SD/VOSB, HUBZONE, 8a, GSA, or DAPA. This can be about document requests, the timeframe, or general information that falls under this category.',
  [SIMPLIFIED_ACQUISITION_TYPE]:
    'When creating this ticket, you are reaching out to the SAP Specialist in regards to questions or changes concerning your Online Capabilities Statement website',
};
