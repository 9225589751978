import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';

const INITIAL_STATE = {
  pending: false,
  usersData: {},
  usersToInvite: [],
  usersWhoShared: [],
  usersSharedTo: [],
};

export const syncCalendar = createAsyncThunk('calendar/syncCalendar', (params = {}) => {
  return application.call(API.CALENDAR.SYNC_CALENDAR, params);
});

export const enableSyncCalendarReminder = createAsyncThunk('calendar/enableSyncCalendarReminder', (params = {}) => {
  return application.call(API.CALENDAR.SYNC_CALENDAR_REMINDER, params);
});

export const getUsersEvents = createAsyncThunk('calendar/getUsersEvents', (params = {}) => {
  return application.call(API.CALENDAR.GET_USERS_EVENTS, params);
});

export const getUsersToInvite = createAsyncThunk('calendar/getUsersToInvite', (params = {}) => {
  return application.call(API.CALENDAR.GET_USERS_TO_INVITE, params);
});

export const getUsersWhoShared = createAsyncThunk('calendar/getUsersWhoShared', (params = {}) => {
  return application.call(API.CALENDAR.GET_USERS_WHO_SHARED, params);
});

export const getUsersSharedTo = createAsyncThunk('calendar/getUsersSharedTo', (params = {}) => {
  return application.call(API.CALENDAR.GET_USERS_SHARED_TO, params);
});

export const shareCalendar = createAsyncThunk('calendar/shareCalendar', (params = {}) => {
  return application.call(API.CALENDAR.SHARE_CALENDAR, params);
});

export const deleteFromSharedTo = createAsyncThunk('calendar/deleteFromSharedTo', (params = {}) => {
  return application.call(API.CALENDAR.DELETE_FROM_SHARED_TO, params);
});

export const deleteFromWhoShared = createAsyncThunk('calendar/deleteFromWhoShared', (params = {}) => {
  return application.call(API.CALENDAR.DELETE_FROM_WHO_SHARED, params);
});

function onChangePending(state, action) {
  return Object.assign(state, {
    pending: true,
  });
}

function onGetList(state, action) {
  if (action.payload.error) {
    // Handle error
    return Object.assign(state, {
      error: action.payload.error,
      pending: false,
    });
  }
  // No errors
  return Object.assign(state, {
    usersData: action.payload,
    pending: false,
  });
}

function onGetUsers(state, action) {
  if (action.payload.error) {
    // Handle error
    return Object.assign(state, {
      error: action.payload.error,
      pending: false,
    });
  }
  // No errors
  return Object.assign(state, {
    usersToInvite: action.payload,
    pending: false,
  });
}

function onGetUsersWhoShared(state, action) {
  if (action.payload.error) {
    // Handle error
    return Object.assign(state, {
      error: action.payload.error,
      pending: false,
    });
  }
  // No errors
  return Object.assign(state, {
    usersWhoShared: action.payload,
    pending: false,
  });
}

function onGetUsersSharedTo(state, action) {
  if (action.payload.error) {
    // Handle error
    return Object.assign(state, {
      error: action.payload.error,
      pending: false,
    });
  }
  // No errors
  return Object.assign(state, {
    usersSharedTo: action.payload,
    pending: false,
  });
}

function onShareCalendar(state, action) {
  if (action.payload.error) {
    // Handle error
    return Object.assign(state, {
      error: action.payload.error,
      pending: false,
    });
  }
  // No errors
  return Object.assign(state, {
    usersSharedTo: action.payload,
    pending: false,
  });
}

function onDeleteFromSharedTo(state, action) {
  if (action.payload.error) {
    // Handle error
    return Object.assign(state, {
      error: action.payload.error,
      pending: false,
    });
  }
  // No errors
  return Object.assign(state, {
    usersSharedTo: action.payload,
    pending: false,
  });
}

function onDeleteFromWhoShared(state, action) {
  if (action.payload.error) {
    // Handle error
    return Object.assign(state, {
      error: action.payload.error,
      pending: false,
    });
  }
  // No errors
  return Object.assign(state, {
    usersWhoShared: action.payload,
    pending: false,
  });
}

export const actions = {
  getUsersEvents,
  getUsersToInvite,
  getUsersWhoShared,
  getUsersSharedTo,
  shareCalendar,
  deleteFromSharedTo,
  deleteFromWhoShared,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(getUsersEvents.pending, onChangePending).addCase(getUsersEvents.fulfilled, onGetList);
  builder.addCase(getUsersToInvite.pending, onChangePending).addCase(getUsersToInvite.fulfilled, onGetUsers);
  builder.addCase(getUsersWhoShared.pending, onChangePending).addCase(getUsersWhoShared.fulfilled, onGetUsersWhoShared);
  builder.addCase(getUsersSharedTo.pending, onChangePending).addCase(getUsersSharedTo.fulfilled, onGetUsersSharedTo);
  builder.addCase(shareCalendar.pending, onChangePending).addCase(shareCalendar.fulfilled, onShareCalendar);
  builder
    .addCase(deleteFromSharedTo.pending, onChangePending)
    .addCase(deleteFromSharedTo.fulfilled, onDeleteFromSharedTo);
  builder
    .addCase(deleteFromWhoShared.pending, onChangePending)
    .addCase(deleteFromWhoShared.fulfilled, onDeleteFromWhoShared);
});
