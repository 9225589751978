import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { API, SCOPE_TEAMING_OPPORTUNITIES } from '../../constants';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common';
import { extendListBuilder, generateListActions, generateSearchActions } from '../helpers/listActionsHelpers';
import { onPending } from '../helpers/sharedCases';
import { onItemDetails, onItemDetailsReject } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  dashboard: {
    items: [],
    savedItems: [],
    pending: false,
    statistics: [
      {
        value: 0,
        text: "Organization's Pursuits",
        link: '/open-pursuits?type=internal',
        icon: 'IconOrganizations',
        colorText: 'initial',
        colorButton: 'default',
      },
      {
        value: 0,
        text: 'Public Pursuits',
        link: '/open-pursuits?type=external',
        icon: 'IconPublicPursuits',
        colorText: 'success',
        colorButton: 'success',
      },
      {
        value: 0,
        text: 'Subcontractors',
        link: '/vendors?collaborate=y',
        icon: 'IconSubcontractors',
        colorText: 'primary',
        colorButton: 'primary',
      },
    ],
  },
  order: ['createdAt', 'desc'],
  pendingButtonAction: false,
  pendingSaved: false,
  item: {},
  internalOpportunities: [],
  publicOpportunities: [],
  openAddPublicOpportunityModal: false,
  openAddInternalOpportunityModal: false,
  openPublicOpportunityDetail: false,
  openInternalOpportunityDetail: false,
  openContactVendorModal: false,
  currentShowOpportunity: null,
  currentEditOpportunity: null,
  interestedUsersInternal: {
    items: [],
    count: 0,
    pending: false,
    filters: {
      order: ['createdAt', 'desc'],
      pagination: { page: 1, perPage: 10 },
    },
  },
  interestedContractorsExternal: {
    items: [],
    count: 0,
    pending: false,
    filters: {
      order: ['createdAt', 'desc'],
      pagination: { page: 1, perPage: 10 },
    },
  },
  recommendedContractors: {
    items: [],
    pending: false,
  },
  pursuits: [],
  selectedOpportunity: null,
  textSearch: '',
};

const listActions = generateListActions({
  scope: SCOPE_TEAMING_OPPORTUNITIES,
  apiMethod: {
    GET_LIST: API.TEAMING_OPPORTUNITIES.GET_LIST,
  },
  getStore: (store) => store.teamingOpportunities,
});

const searchActions = generateSearchActions({
  scope: SCOPE_TEAMING_OPPORTUNITIES,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.teamingOpportunities,
});

const getDashboard = createAsyncThunk('teamingOpportunities/getDashboard', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.GET_DASHBOARD, params);
});
function onPendingDashboard(state, action) {
  Object.assign(state.dashboard, {
    pending: action,
  });
}
function onGetDashboard(state, action) {
  const copyStatistics = [...state.dashboard.statistics];
  copyStatistics[0].value = action.payload.orgPursuits;
  copyStatistics[1].value = action.payload.publicPursuits;
  copyStatistics[2].value = action.payload.collaborators;

  Object.assign(state.dashboard, {
    items: action.payload ? [...action.payload.items] : [],
    savedItems: action.payload ? [...action.payload.savedItems] : [],
    jobsCreatedByMe: action.payload?.jobsCreatedByMe,
    statistics: [...copyStatistics],
    pending: false,
  });
}

const teamingOpportunityDetail = createAsyncThunk('teamingOpportunities/findOne', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.FIND_ONE, params);
});

const markInterested = createAsyncThunk('teamingOpportunities/markInterested', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.MARK_INTERESTED, params);
});

function onPendingMarkInterested(state, action) {
  Object.assign(state, {
    pendingButtonAction: action,
  });
}

function onFullFilledMarkInterested(state, action) {
  const copyState = [...state.items];
  if (action.payload && action.payload.data) {
    const findOpp = copyState.findIndex((i) => i.id === action.payload.data.teamingOpportunityId);
    if (copyState[findOpp].type === 'i') copyState[findOpp].appliedInternal = action.payload.data.status;
    if (copyState[findOpp].type === 'e') copyState[findOpp].appliedExternal = action.payload.data.status;
  }
  Object.assign(state, {
    items: [...copyState],
    pendingButtonAction: false,
  });
}

const markSaved = createAsyncThunk('teamingOpportunities/markSaved', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.MARK_SAVED, params);
});

function onPendingMarkSaved(state, action) {
  Object.assign(state, {
    pendingSaved: true,
  });
  Object.assign(state.dashboard, {
    pending: true,
  });
}

function onFullFilledMarkSaved(state, action) {
  Object.assign(state, {
    pendingSaved: false,
  });
  Object.assign(state.dashboard, {
    pending: false,
  });
}

const getInternalOpportunities = createAsyncThunk('teamingOpportunities/getInternalOpportunities', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.GET_LIST, params);
});
function onGetInternalOpportunities(state, action) {
  return Object.assign(state, {
    internalOpportunities: action.payload.items,
  });
}

const getUserPursuits = createAsyncThunk('teamingOpportunities/getUserPursuits', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.GET_USER_PURSUITS, params);
});

function onGetUserPursuits(state, action) {
  return Object.assign(state, {
    pursuits: action.payload.items,
  });
}

const getPublicOpportunities = createAsyncThunk('teamingOpportunities/getPublicOpportunities', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.GET_LIST, params);
});
function onGetPublicOpportunities(state, action) {
  return Object.assign(state, {
    publicOpportunities: action.payload.items,
  });
}

const addOpportunity = createAsyncThunk('teamingOpportunities/addOpportunity', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.ADD_OPPORTUNITY, params);
});
function onAddOpportunity(state, action) {
  if (state.currentShowOpportunity) {
    return Object.assign(state, {
      currentShowOpportunity: action.payload.data,
    });
  }
}

const addOpportunityRequest = createAsyncThunk('teamingOpportunities/addOpportunityRequest', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.ADD_OPPORTUNITY_REQUEST, params);
});

function onAddOpportunityRequest(state, action) {
  if (state.currentShowOpportunity) {
    return Object.assign(state, {
      currentShowOpportunity: action.payload.data,
    });
  }
}

const openAddPublicOpportunity = createAction('teamingOpportunities/openAddPublicOpportunity');
function onOpenAddPublicOpportunity(state, action) {
  return Object.assign(state, {
    openAddPublicOpportunityModal: action.payload,
  });
}

const openAddInternalOpportunity = createAction('teamingOpportunities/openAddInternalOpportunity');
function onOpenAddInternalOpportunity(state, action) {
  return Object.assign(state, {
    openAddInternalOpportunityModal: action.payload,
  });
}

const openContactVendor = createAction('teamingOpportunities/openContactVendor');
function onOpenContactVendor(state, action) {
  return Object.assign(state, {
    openContactVendorModal: action.payload,
  });
}

const openPublicOpportunityDetailAction = createAction('teamingOpportunities/openPublicOpportunityDetailAction');
function onOpenPublicOpportunityDetailAction(state, action) {
  return Object.assign(state, {
    openPublicOpportunityDetail: action.payload,
  });
}

const openInternalOpportunityDetailAction = createAction('teamingOpportunities/openInternalOpportunityDetailAction');
function onOpenInternalOpportunityDetailAction(state, action) {
  return Object.assign(state, {
    openInternalOpportunityDetail: action.payload,
  });
}

const getTagsOpportunities = createAsyncThunk('teamingOpportunities/getTagsOpportunities', (params) => {
  if (params.filter.text) {
    return application.call(API.TAGS_USER.GET_TAGS_BY_USER, {
      text: params.filter.text,
      type: 'public-opportunities',
    });
  }
});

const changeCurrentShowOpportunity = createAction('teamingOpportunities/changeCurrentShowOpportunity');
function onChangeCurrentShowOpportunity(state, action) {
  return Object.assign(state, {
    currentShowOpportunity: action.payload,
  });
}

const changeCurrentEditOpportunity = createAction('teamingOpportunities/changeCurrentEditOpportunity');
function onChangeCurrentEditOpportunity(state, action) {
  return Object.assign(state, {
    currentEditOpportunity: action.payload,
  });
}

const closeOpportunity = createAsyncThunk('teamingOpportunities/closeOpportunity', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.CLOSE_OPPORTUNITY, params);
});
function onCloseOpportunity(state, action) {
  if (state.currentShowOpportunity) {
    return Object.assign(state, {
      currentShowOpportunity: action.payload.data,
    });
  }
}

const getInterestedUsers = createAsyncThunk('teamingOpportunity/getInterestedUsers', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.GET_INTERESTED_USERS, params);
});
function onGetInterestedUsers(state, action) {
  Object.assign(state.interestedUsersInternal, {
    items: action.payload.items,
    count: action.payload.count,
    pending: false,
  });
}

const getInterestedContractors = createAsyncThunk('teamingOpportunity/getInterestedContractors', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.GET_INTERESTED_USERS, params);
});
function onGetInterestedContractors(state, action) {
  Object.assign(state.interestedContractorsExternal, {
    items: action.payload.items,
    count: action.payload.count,
    pending: false,
  });
}

const changeStatusCollaborator = createAsyncThunk('teamingOpportunity/changeStatusCollaborator', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.CHANGE_STATUS_COLLABORATOR, params);
});
function onFullChangeStatus(state, action) {
  if (action.payload) {
    const type = action.payload.type;

    let data = 'interestedUsersInternal';
    let copy = [...state.interestedUsersInternal.items];
    if (type === 'external') {
      copy = [...state.interestedContractorsExternal.items];
      data = 'interestedContractorsExternal';
    }
    const findItem = copy.findIndex((i) => i.id === action.payload.id);
    if (findItem !== -1) copy[findItem].status = action.payload.status;

    Object.assign(state[data], {
      items: [...copy],
    });
  }
}

const deleteRequestCollaborator = createAsyncThunk('teamingOpportunity/deleteRequestCollaborator', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.DELETE_REQUEST_COLLABORATOR, params);
});
function ondeleteRequestCollaborator(state, action) {
  if (action.payload) {
    const type = action.payload.type;

    let data = 'interestedUsersInternal';
    let copy = [...state.interestedUsersInternal.items];
    if (type === 'external') {
      copy = [...state.interestedContractorsExternal.items];
      data = 'interestedContractorsExternal';
    }
    const findItem = copy.findIndex((i) => i.id === action.payload.id);
    if (findItem !== -1) copy.splice(findItem, 1);

    Object.assign(state[data], {
      items: [...copy],
    });
  }
}

const changeInterestedUsersInternal = createAction('teamingOpportunities/changeInterestedUsersInternal');
function onChangeInterestedUsersInternal(state, action) {
  return Object.assign(state, {
    interestedUsersInternal: action.payload,
  });
}

const getRecommendedContractors = createAsyncThunk('teamingOpportunity/getRecommendedContractors', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.GET_RECOMMENDED_CONTRACTORS, params);
});
function onGetRecommendedContractors(state, action) {
  Object.assign(state.recommendedContractors, {
    items: action.payload,
    pending: false,
  });
}
const changeInterestedContractorsExternal = createAction('teamingOpportunities/changeInterestedContractorsExternal');
function onChangeInterestedContractorsExternal(state, action) {
  return Object.assign(state, {
    interestedContractorsExternal: action.payload,
  });
}

const getProfileInterestedContractor = createAsyncThunk(
  'teamingOpportunity/getProfileInterestedContractor',
  (params) => {
    return application.call(API.TEAMING_OPPORTUNITIES.FIND_INTERESTED_CONTRACTOR, params);
  }
);

const getTeamingCollaborator = createAsyncThunk('teamingOpportunity/findOneCollaborator', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.FIND_ONE_COLLABORATOR, params);
});

const getOpportunitiesByPursuitId = createAsyncThunk(
  'teamingOpportunity/getOpportunitiesByPursuitId',
  (params = {}) => {
    return application.call(API.PURSUITS.GET_OPPORTUNITIES_BY_PURSUIT_ID, params);
  }
);

function getOriginalOpportunity(state, { payload }) {
  if (payload.items) {
    state.originalViewOpportunity = payload.items[0];
  }
  Object.assign(state, { pending: false });
}

const setSelectedOpportunity = createAction('teamingOpportunities/setSelectedOpportunity');
function onSetSelectedOpportunity(state, action) {
  return Object.assign(state, {
    selectedOpportunity: action.payload,
  });
}

const onClearSearch = createAction('teamingOpportunities/onClearSearch');

const changeFilters = createAction('teamingOpportunities/changeFilters');
function onChangeFilters(state, action) {
  return Object.assign(state, {
    filters: action.payload,
  });
}

const storeIdFilter = createAction('teamingOpportunities/storeIdFilter');
function onStoreIdFilter(state, action) {
  const newState = { ...state };
  newState.filters.fields = action.payload;
  return Object.assign(state, {
    filters: newState.filters,
  });
}

const deleteIdFilter = createAction('teamingOpportunities/deleteIdFilter');
function onDeleteIdFilter(state, action) {
  const newState = { ...state };
  delete newState.filters.fields.id;
  return Object.assign(state, {
    filters: newState.filters,
  });
}

const changeSavedStatus = createAction('teamingOpportunities/changeSavedStatus');
function onChangeSavedStatus(state, action) {
  const copyItems = [...state.items];
  const index = copyItems.findIndex((i) => i.id === action.payload.id);
  copyItems[index] = { ...copyItems[index], savedInfo: action.payload.isSaved ? { id: action.payload.id } : null };

  return Object.assign(state, {
    items: copyItems,
  });
}

const changeCollaborateStatus = createAction('teamingOpportunities/changeCollaborateStatus');
function onChangeCollaborateStatus(state, action) {
  const copyItems = [...state.items];
  const index = copyItems.findIndex((i) => i.id === action.payload.id);
  copyItems[index] = { ...copyItems[index], collaborateInfo: { id: action.payload.id } };

  return Object.assign(state, {
    items: copyItems,
  });
}

const deleteAllFilters = createAction('teamingOpportunities/deleteAllFilters');
function onDeleteAllFilters(state, action) {
  return Object.assign(state, {
    filters: { fields: {}, text: '' },
  });
}

const getList = createAsyncThunk('teamingOpportunities/getList', (params) => {
  return application.call(API.TEAMING_OPPORTUNITIES.GET_LIST, params);
});
function onGetList(state, action) {
  return Object.assign(state, {
    items: action.payload ? [...action.payload.items] : [],
    item: null,
    selectedOpportunity: action.payload ? action?.payload?.items[0] : null,
  });
}

export const actions = {
  ...listActions,
  ...searchActions,
  getDashboard,
  markInterested,
  markSaved,
  getUserPursuits,
  teamingOpportunityDetail,
  getOpportunitiesByPursuitId,
  addOpportunity,
  addOpportunityRequest,
  openAddPublicOpportunity,
  openAddInternalOpportunity,
  openContactVendor,
  openPublicOpportunityDetailAction,
  openInternalOpportunityDetailAction,
  getInternalOpportunities,
  getPublicOpportunities,
  getTagsOpportunities,
  changeCurrentShowOpportunity,
  changeCurrentEditOpportunity,
  closeOpportunity,
  getInterestedUsers,
  getInterestedContractors,
  changeStatusCollaborator,
  deleteRequestCollaborator,
  changeInterestedUsersInternal,
  getRecommendedContractors,
  changeInterestedContractorsExternal,
  getProfileInterestedContractor,
  getTeamingCollaborator,
  setSelectedOpportunity,
  onClearSearch,
  changeFilters,
  getList,
  storeIdFilter,
  deleteIdFilter,
  changeSavedStatus,
  changeCollaborateStatus,
  deleteAllFilters,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, actions)
    .addCase(getDashboard.fulfilled, onGetDashboard)
    .addCase(getDashboard.pending, (state) => onPendingDashboard(state, true))
    .addCase(getDashboard.rejected, (state) => onPendingDashboard(state, false))
    .addCase(teamingOpportunityDetail.fulfilled, onItemDetails)
    .addCase(teamingOpportunityDetail.pending, onPending)
    .addCase(teamingOpportunityDetail.rejected, onItemDetailsReject)
    .addCase(markInterested.fulfilled, onFullFilledMarkInterested)
    .addCase(markInterested.pending, (state) => onPendingMarkInterested(state, true))
    .addCase(markInterested.rejected, (state) => onPendingMarkInterested(state, false))
    .addCase(markSaved.fulfilled, onFullFilledMarkSaved)
    .addCase(markSaved.pending, (state) => onPendingMarkSaved(state, true))
    .addCase(markSaved.rejected, (state) => onPendingMarkSaved(state, false))
    .addCase(getInternalOpportunities.fulfilled, onGetInternalOpportunities)
    .addCase(getPublicOpportunities.fulfilled, onGetPublicOpportunities)
    .addCase(getUserPursuits.fulfilled, onGetUserPursuits)
    .addCase(getOpportunitiesByPursuitId.fulfilled, getOriginalOpportunity)
    .addCase(addOpportunity.fulfilled, onAddOpportunity)
    .addCase(addOpportunityRequest.fulfilled, onAddOpportunityRequest)
    .addCase(closeOpportunity.fulfilled, onCloseOpportunity)
    .addCase(openAddPublicOpportunity, onOpenAddPublicOpportunity)
    .addCase(openContactVendor, onOpenContactVendor)
    .addCase(changeInterestedUsersInternal, onChangeInterestedUsersInternal)
    .addCase(changeInterestedContractorsExternal, onChangeInterestedContractorsExternal)
    .addCase(openPublicOpportunityDetailAction, onOpenPublicOpportunityDetailAction)
    .addCase(openInternalOpportunityDetailAction, onOpenInternalOpportunityDetailAction)
    .addCase(changeCurrentShowOpportunity, onChangeCurrentShowOpportunity)
    .addCase(changeCurrentEditOpportunity, onChangeCurrentEditOpportunity)
    .addCase(openAddInternalOpportunity, onOpenAddInternalOpportunity)
    .addCase(getInterestedUsers.pending, (state, action) => {
      Object.assign(state.interestedUsersInternal, {
        pending: true,
      });
    })
    .addCase(getInterestedUsers.rejected, (state, action) => {
      Object.assign(state.interestedUsersInternal, {
        pending: false,
      });
    })
    .addCase(getInterestedUsers.fulfilled, onGetInterestedUsers)

    .addCase(getInterestedContractors.pending, (state, action) => {
      Object.assign(state.interestedContractorsExternal, {
        pending: true,
      });
    })
    .addCase(getInterestedContractors.rejected, (state, action) => {
      Object.assign(state.interestedContractorsExternal, {
        pending: false,
      });
    })
    .addCase(getInterestedContractors.fulfilled, onGetInterestedContractors)
    .addCase(changeStatusCollaborator.fulfilled, onFullChangeStatus)
    .addCase(deleteRequestCollaborator.fulfilled, ondeleteRequestCollaborator)
    .addCase(getRecommendedContractors.pending, (state, action) => {
      Object.assign(state.recommendedContractors, {
        pending: true,
      });
    })
    .addCase(getRecommendedContractors.fulfilled, onGetRecommendedContractors)
    .addCase(setSelectedOpportunity, onSetSelectedOpportunity)
    .addCase(onClearSearch, (state, action) => {
      Object.assign(state, {
        textSearch: action.payload,
      });
    })
    .addCase(changeFilters, onChangeFilters)
    .addCase(storeIdFilter, onStoreIdFilter)
    .addCase(deleteIdFilter, onDeleteIdFilter)
    .addCase(getList.fulfilled, onGetList)
    .addCase(changeSavedStatus, onChangeSavedStatus)
    .addCase(changeCollaborateStatus, onChangeCollaborateStatus)
    .addCase(deleteAllFilters, onDeleteAllFilters);
});
