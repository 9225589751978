import * as Yup from 'yup';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';

export const initialValues = {
  term: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    term: Yup.string().required(trans('required-field')),
  })
);
