import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import React from 'react';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconCalendar = (props) => {
  const { width = 25, height = 25 } = props;

  return <CalendarTodayOutlinedIcon width={width} height={height} />;
};

IconCalendar.propTypes = simpleIconPropsTypes;
