import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconVendors = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="M9 1c1.105 0 2 .895 2 2v4h8c1.105 0 2 .895 2 2v10c0 1.105-.895 2-2 2h-8c-.365 0-.707-.098-1.001-.268-.293.17-.635.268-.999.268H3c-1.105 0-2-.895-2-2V3c0-1.105.895-2 2-2zm5 8h-3v10h3V9zm-5 6H3v4h6v-4zm10-6h-3v10h3V9zM3 9v4h6V9H3zm0-2h6V3H3v4z" />
    </SvgIcon>
  );
};

IconVendors.propTypes = simpleIconPropsTypes;
