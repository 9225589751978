import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconArchiveSlash = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g id="vuesax/linear/archive-slash">
        <g id="vuesax/linear/archive-slash_2">
          <g id="archive-slash">
            <path
              id="Vector"
              d="M22 2L2 22"
              stroke="#292D32"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M20.68 8.71V19.71C20.68 21.72 19.24 22.57 17.48 21.59L11 17.54"
              stroke="#292D32"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M3.32007 19.95V5.86C3.32007 3.74 5.05007 2 7.18007 2H16.8301C18.0401 2 19.1201 2.56 19.8301 3.44"
              stroke="#292D32"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconArchiveSlash.propTypes = simpleIconPropsTypes;
