import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

export default function LimitHeight({
  children,
  component,
  className,
  height = 0,
  range = '',
  scrollable = false,
  forwardHeight = false,
  ...rest
}) {
  const styles = useStyles();
  const Component = component ? component : 'div';

  return (
    <Component
      className={clsx(
        height && styles[`${range}${height}`],
        scrollable && styles.scrollable,
        forwardHeight && styles.forwardHeight,
        className
      )}
      {...rest}
    >
      {children}
    </Component>
  );
}

const useStyles = makeStyles({
  scrollable: { overflow: 'auto' },
  forwardHeight: { height: '100%' },

  150: { height: 150 },
  max150: { maxHeight: 150 },
  min150: { minHeight: 150 },
  360: { height: 360 },
  max360: { maxHeight: 360 },
  min360: { minHeight: 360 },
  440: { height: 440 },
  max440: { maxHeight: 440 },
  min440: { minHeight: 440 },
  520: { height: 520 },
  max520: { maxHeight: 520 },
  min520: { minHeight: 520 },
});
