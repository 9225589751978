import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCube = (props) => {
  const { width = 18, height = 18 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 18 18">
      <path
        d="M15.2167 4.05009C15.2167 3.99175 15.1333 3.93342 15.0917 3.87509L15.0167 3.79175C14.8226 3.55216 14.5779 3.35862 14.3 3.22509L8.96667 0.725086C8.67139 0.589016 8.35012 0.518555 8.025 0.518555C7.69988 0.518555 7.37861 0.589016 7.08333 0.725086L1.75 3.22509C1.47355 3.36089 1.22923 3.55407 1.03333 3.79175L0.933333 3.83342C0.884859 3.88353 0.842864 3.93952 0.808333 4.00009C0.60953 4.31443 0.50272 4.67816 0.5 5.05009V10.9334C0.501115 11.3309 0.61637 11.7197 0.832057 12.0536C1.04774 12.3874 1.35479 12.6523 1.71667 12.8168L7.05 15.3168C7.34641 15.4541 7.66916 15.5252 7.99583 15.5252C8.3225 15.5252 8.64526 15.4541 8.94167 15.3168L14.275 12.8168C14.6359 12.6547 14.9429 12.3929 15.16 12.0622C15.377 11.7315 15.495 11.3456 15.5 10.9501V5.05009C15.4957 4.69755 15.398 4.35247 15.2167 4.05009ZM7.75833 2.21675C7.83462 2.18373 7.91687 2.16669 8 2.16669C8.08313 2.16669 8.16538 2.18373 8.24167 2.21675L12.6833 4.28342L8 6.45842L3.31667 4.28342L7.75833 2.21675ZM2.41667 11.3334C2.34411 11.3053 2.28158 11.2562 2.23706 11.1924C2.19253 11.1285 2.16803 11.0529 2.16667 10.9751V5.58342L7.16667 7.90842V13.5084L2.41667 11.3334ZM13.575 11.3334L8.83333 13.5084V7.90842L13.8333 5.58342V10.9501C13.8352 11.0329 13.8114 11.1142 13.7651 11.1828C13.7189 11.2515 13.6524 11.3041 13.575 11.3334Z"
        fill="#888FA9"
      />
    </SvgIcon>
  );
};

IconCube.propTypes = simpleIconPropsTypes;
