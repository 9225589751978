import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const initialValues = {
  samApiKey: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    samApiKey: Yup.string()
      .required(trans('empty-not-allowed', { field: trans('samApiKey') }))
      .nullable(),
  })
);
