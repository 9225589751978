import React, { useEffect, useState, useCallback } from 'react';
import { useFormData } from '../../index';
import { useTrans } from '../../../../services/i18n';
import { SelectField, TextField, Autocomplete } from '../../../../components/common/Inputs';
import { Grid } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import { useApplication } from '../../../../services/application';
import { catchYupErrors, schemaToInitialState } from '../../../../utils/validationUtils';
import MenuItem from '@material-ui/core/MenuItem';
import { AmplifyAuth } from '../../../../services/amplifyAuth';
// import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAlertContext } from '../../../../contexts/AlertContext';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PhoneNumber from '../PhoneNumber';
import { Divider } from '../../../../components/common/Divider';
import { LINKEDIN_URL_FORMAT } from '../../../../constants/regex';
import { API } from '../../../../constants';
import { Button } from '../../../../components/common/Buttons';
import { ReactComponent as ArrowBackIcon } from '../../icons/arrow-left.svg';
import { getInitialValues, useScheme } from './helpers';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  labelDropdowns: {
    fontFamily: 'Lexend',
    marginBottom: '8px',
    color: '#4e5978',
    fontWeight: 500,
  },
  containerCard: {
    padding: '2.125rem 1.5rem 1rem',
  },
  containerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.3rem 2.3rem 1.87rem 2.3rem',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  backButton: {
    color: '#999999',
    fontSize: 18,
    fontFamily: 'Manrope',
    fontWeight: '500',
    wordWrap: 'break-word',
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cancelNextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const companyAddressSchema = yup.object().shape({});

export default function CompanyAddress({
  nextStep,
  previousStep,
  isEdit,
  onCompletionChange,
  initialValues,
  cancelButtonHandler,
}) {
  const application = useApplication();
  const { companyAddressData, setCompanyAddressData } = useFormData();
  const [errors, setErrors] = useState({});
  const [profitType, setProfitType] = useState(initialValues.profitType || null);
  const { trans } = useTrans();
  const [phone, setPhone] = useState(null);
  const styles = useStyles();
  const { successAlert, errorAlert } = useAlertContext();
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (onCompletionChange) {
      onCompletionChange(isComplete);
    }
  }, [isComplete, onCompletionChange]);

  const handleNextClick = () => {
    try {
      companyAddressSchema.validateSync(companyAddressSchema, { abortEarly: false });
      onCompletionChange(true);
      nextStep();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });
        setErrors(errorMessages);
      }
      onCompletionChange(false);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} className={styles.containerCard}>
        <div
          style={{
            width: '100%',
            height: '100%',
            paddingLeft: 40,
            paddingRight: 40,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 24,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: 'black',
              fontSize: 21,
              fontFamily: 'Manrope',
              fontWeight: '700',
              wordWrap: 'break-word',
            }}
          >
            Company Address
          </div>
          <div style={{ alignSelf: 'stretch', height: 1, background: '#D9D9D9' }} />
        </div>
        <Grid item sm={6} xs={12} px={0}>
          <TextField
            type="text"
            label={trans('organization-form-address-line-1')}
            placeholder={trans('organization-form-address-line-1')}
            value={companyAddressData.addressLine1 || ''}
            onChange={(e) => {
              setCompanyAddressData({
                ...companyAddressData,
                addressLine1: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="text"
            label={trans('organization-form-city')}
            placeholder={trans('organization-form-city')}
            value={companyAddressData.city || ''}
            onChange={(e) => {
              setCompanyAddressData({
                ...companyAddressData,
                city: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="text"
            label={trans('organization-form-address-line-2')}
            placeholder={trans('organization-form-address-line-2')}
            value={companyAddressData.addressLine2 || ''}
            onChange={(e) => {
              setCompanyAddressData({
                ...companyAddressData,
                addressLine2: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="text"
            label={trans('organization-form-state')}
            placeholder={trans('organization-form-state')}
            value={companyAddressData.state || ''}
            onChange={(e) => {
              setCompanyAddressData({
                ...companyAddressData,
                state: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12} px={0}>
          <TextField
            type="text"
            label={trans('organization-form-zip')}
            placeholder={trans('organization-form-zip')}
            value={companyAddressData.zipCode || ''}
            onChange={(e) => {
              setCompanyAddressData({
                ...companyAddressData,
                zipCode: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="text"
            label={trans('organization-form-country')}
            placeholder={trans('organization-form-country')}
            value={companyAddressData.country || ''}
            onChange={(e) => {
              setCompanyAddressData({
                ...companyAddressData,
                country: e.target.value,
              });
            }}
          />
        </Grid>
      </Grid>
      <Divider light spacing={1} />
      <Grid container className={styles.containerFooter}>
        <div className={styles.backButtonContainer}>
          <ArrowBackIcon />
          <div className={styles.backButton} onClick={previousStep}>
            {trans('organization-form-back-button')}
          </div>
        </div>
        <div className={styles.cancelNextButtonContainer}>
          <Button variant="contained" color="primary" onClick={cancelButtonHandler}>
            {trans('organization-form-cancel-button')}
          </Button>
          <Button variant="outlined" color="primary" onClick={handleNextClick} style={{ marginLeft: '10px' }}>
            {trans('organization-form-next-button')}
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  );
}
