import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  svgRoot: {
    display: 'inline-block',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    MozUserSelect: 'none',
    verticalAlign: 'top',
    fill: 'white',
  },
  stroke: {
    stroke: 'currentColor',
  },
  fill: {
    fill: 'currentColor',
  },
});

export const SvgIcon = ({
  children,
  viewBox,
  width = 18,
  height = 18,
  coloredStroke = false,
  className,
  style,
  noFill = false,
}) => {
  const styles = useStyles();

  return (
    <svg
      style={style}
      className={clsx(styles.svgRoot, !noFill && (coloredStroke ? styles.stroke : styles.fill), className)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      {children}
    </svg>
  );
};
