import React from 'react';
import { emptyArray } from '../../utils/arrayUtils';
import AddToPipeline from './AddToPipeline';

export const AddToPipelineContext = React.createContext({
  onOpenDialog: undefined,
  onCloseDialog: undefined,
});
export const useAddToPipelineContext = () => React.useContext(AddToPipelineContext);

const MOCK = [
  { title: 'Simple RFI/RFP Pipeline', updatedAt: '2021-10-10' },
  { title: 'Simple DDF/SSE Pipeline', updatedAt: '2021-10-10' },
  { title: 'Simple VVV/VGT Pipeline', updatedAt: '2021-10-10' },
];

export class AddToPipelineProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pipelinesList: [],
      item: null,
      open: false,
      success: false,
      pending: false,
    };
    this.handlers = {
      onOpenDialog: this.onOpenDialog.bind(this),
      onCloseDialog: this.onCloseDialog.bind(this),
      onAddToPipeline: this.onAddToPipeline.bind(this),
    };
  }

  onOpenDialog(item) {
    this.setState({ open: true, item, pending: true });
    if (!emptyArray(this.state.pipelinesList)) {
      return this.setState({ pending: false });
    }

    setTimeout(() => {
      this.setState({ pending: false, pipelinesList: MOCK });
    }, 1000);
  }

  onCloseDialog() {
    this.setState({ open: false, success: false, item: null });
  }

  onAddToPipeline(pipeline) {
    const { scope } = this.props;
    const { item } = this.state;
    if (!(scope && item)) return;

    this.setState({ pending: true });

    setTimeout(() => {
      this.setState({ pending: false, success: true });
    }, 2000);
  }

  render() {
    const value = { ...this.state, ...this.handlers };

    return <AddToPipelineContext.Provider value={value}>{this.props.children}</AddToPipelineContext.Provider>;
  }
}

export class AddToPipelineConsumer extends React.Component {
  renderItems(props) {
    return <AddToPipeline {...props} />;
  }

  render() {
    return <AddToPipelineContext.Consumer>{this.renderItems}</AddToPipelineContext.Consumer>;
  }
}
