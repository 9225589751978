import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconSquaredPlus = (props) => {
  const { width = 24, height = 24, fill = 'white' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 12H16" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 16V8" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

IconSquaredPlus.propTypes = simpleIconPropsTypes;
