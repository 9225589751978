import { useEffect, useState } from 'react';
import { useAlertContext } from '../contexts/AlertContext';
import { useApplication } from '../services/application';
import { useTrans } from '../services/i18n';
import { AmplifyAuth } from '../services/amplifyAuth';

export default function usePhoneInput({ auth, updatePhone, onClose, updatePhoneVerified }) {
  const { trans } = useTrans();
  const application = useApplication();
  const { successAlert, errorAlert } = useAlertContext();
  const [sentCode, setSentCode] = useState(false);
  const [code, setCode] = useState('');
  const [pending, setPending] = useState(false);
  const [newPhone, setPhone] = useState('');
  const [resendPending, setResendPending] = useState(false);

  const { phoneVerified, phone } = auth;

  const handleSetPhone = async () => {
    setPending(true);

    if (!sentCode) {
      return application
        .call('user.setPhone', { phone: newPhone })
        .then(async (res) => {
          if (res) {
            if (res.verified === 'false') {
              setSentCode(true);
              // Update redux state
              updatePhone(newPhone);
              updatePhoneVerified(false);
            } else {
              await successUpdatePhone();
            }
          }
        })
        .catch((e) => errorAlert(e.message))
        .finally(() => setPending(false));
    } else {
      return AmplifyAuth.verifyCurrentUserAttributeSubmit('phone_number', code)
        .then(async () => {
          await successUpdatePhone();
          // Update redux state
          updatePhoneVerified(true);
        })
        .finally(() => {
          setPending(false);
        })
        .catch((e) => {
          console.log('Error valitating phone');
          // Update redux state
          updatePhoneVerified(false);
          errorAlert(e.message);
        });
    }
  };

  const successUpdatePhone = async () => {
    try {
      await application.call('user.updatePhone', { phone: newPhone });
      updatePhone(newPhone);
      successAlert(trans('success-modified-phone'));
      onClose();
    } catch (error) {
      errorAlert(error.message);
    }
  };

  const resendCode = async () => {
    return AmplifyAuth.verifyCurrentUserAttribute('phone_number')
      .finally(() => setResendPending(false))
      .catch((e) => errorAlert(e.message));
  };

  useEffect(() => {
    if (phone) {
      setPhone(phone);
    }
  }, [phone]);

  return {
    phone,
    phoneVerified,
    sentCode,
    resendPending,
    code,
    pending,
    newPhone,
    resendCode,
    setCode,
    setPhone,
    handleSetPhone,
  };
}
