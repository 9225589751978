import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { RouterLink } from '../../../../../components/common/Buttons';
import FieldValue from '../../../../../components/generics/Helpers/FieldValue';
import { ROUTE_CODE_CATEGORIES_DETAILS } from '../../../../../constants';
import { useTrans } from '../../../../../services/i18n';
import { emptyArray } from '../../../../../utils/arrayUtils';
import { generateLocation } from '../../../helpers';
import AvatarTitle from './Avatar';
import { ROUTE_VENDORS_DETAILS } from '../../../../../constants';

const VendorFound = ({ item, md = 6, isSmScreen = false }) => {
  const { trans } = useTrans();
  const detailLink = item ? ROUTE_VENDORS_DETAILS.path.replace(':id', item.id) : '';

  return (
    item && (
      <div style={{ padding: '30px 16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AvatarTitle
              title={item.name}
              description={generateLocation(item)}
              detailLink={detailLink}
              small={isSmScreen}
            />
          </Grid>

          <Grid container style={{ paddingLeft: '90px' }} spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <FieldValue value={item.ueiCode} label={trans('uei-code')} />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <FieldValue value={item.cageCode} label={trans('cage-code')} />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <FieldValue noWrap={false} label={trans('primary-NAICS')}>
                {item.primaryNaics ? (
                  <Typography
                    variant="h6"
                    component={RouterLink}
                    noWrap={false}
                    to={ROUTE_CODE_CATEGORIES_DETAILS.path.replace(':id', `NAICS-${item.primaryNaics}`)}
                  >
                    {item.primaryNaics} {item.primaryNaicsName}
                  </Typography>
                ) : (
                  <Typography variant="h6" component="p" color="hint">
                    {trans('not-provided')}
                  </Typography>
                )}
              </FieldValue>
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <FieldValue value={item.entityStructureCode} label={trans('EntityStructure')} />
            </Grid>

            <Grid item>
              <FieldValue label={trans('NAICS-category')}>
                {!emptyArray(item.naics) ? (
                  item.naics.map((item) => (
                    <Typography
                      key={item.code}
                      variant="h6"
                      component={RouterLink}
                      noWrap={false}
                      to={ROUTE_CODE_CATEGORIES_DETAILS.path.replace(':id', `NAICS-${item.code}`)}
                    >
                      {item.code} - {item.name}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="h6" component="p" color="hint">
                    {trans('not-provided')}
                  </Typography>
                )}
              </FieldValue>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  );
};

export default VendorFound;
