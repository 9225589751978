import React from 'react';
import Suspense from '../../services/lazyLoad/Suspense';
import { useMediaQuery } from '@material-ui/core';

const PlaceholderDesktop = React.lazy(() => import('./Placeholder/PlaceholderDesktop'));
const PlaceholderMobile = React.lazy(() => import('./Placeholder/PlaceholderMobile'));

export default function TeamingOpportunitiesWrapper() {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return <Suspense>{isMobile ? <PlaceholderMobile /> : <PlaceholderDesktop />}</Suspense>;
}
