import React from 'react';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../../../components/common/Typography';
import { Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import TimeIcon from '@material-ui/icons/AccessTime';
import styles from './styles.module.css';
import generalSettingsImg from '../../components/images/general_settings.png';
import notiFrequency from '../../components/images/noti_frequency.png';
import { Button } from '../../../../components/common/Buttons';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '../../../../components/common/Divider';
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';
import { IconTimer } from '../../../../components/svg';

const showAboutNotificationInfoKey = 'showAboutNotificationInfo';

export default function InfoAboutNotificationsContinueButton({ onClose, openSettings, showContinueButtons }) {
  const { trans } = useTrans();
  const isSmallScreen = useMediaQueryDown('sm');

  const [checkedShowAgainOption, setCheckedShowAgainOption] = React.useState(false);
  function onChangeShowAgainOption(value) {
    localStorage.setItem(showAboutNotificationInfoKey, JSON.stringify(value));
    setCheckedShowAgainOption(value);
  }

  function onClickCheckShowInfoAgain() {
    onChangeShowAgainOption(!checkedShowAgainOption);
  }

  React.useEffect(() => {
    let showNotificationInfoValue = JSON.parse(localStorage.getItem(showAboutNotificationInfoKey));
    setCheckedShowAgainOption(showNotificationInfoValue);
  }, []);

  return (
    <Grid container>
      <Grid md={6} item className={styles.item} style={{ borderRight: '1px solid #4E59781F' }}>
        <div className={styles.iconInfo}>
          <IconTimer />
        </div>
        <div className={styles.title}>
          <Typography variant="body1" weight="bold">
            {trans('notification-frequency')}
          </Typography>
        </div>
        <div className={styles.image}>
          <img src={notiFrequency} alt="Frequency" />
        </div>
        <div className={styles.text}>
          <Typography variant="body2" weight="500">
            Configure the frequency with which you want to receive instant notifications, remember that the
            configuration you leave in general settings will be reflected in the instant notifications.
          </Typography>
        </div>
      </Grid>
      <Grid md={6} item className={styles.item}>
        <div className={styles.iconInfo}>
          <SettingsIcon />
        </div>
        <div className={styles.title}>
          <Typography variant="body1" weight="bold">
            {trans('general-notification-settings')}
          </Typography>
        </div>
        <div className={styles.image}>
          <img src={generalSettingsImg} alt="General" />
        </div>
        <div className={styles.text}>
          <Typography variant="body2" weight="500">
            From My profile and going to Email notification settings you can configure all these notifications in
            general and you can set up notifications for Pursuit Notices, Saved Searches notifications and Team Updates.
          </Typography>
        </div>
      </Grid>

      {showContinueButtons && (
        <>
          <Grid xs={12} style={{ width: '100%', marginTop: 20 }}>
            <Divider light />
          </Grid>
          {isSmallScreen ? (
            <>
              <div style={{ width: '100%', marginTop: 20, marginLeft: '40', textAlign: 'center' }}>
                <Grid xs={12}>
                  <Button style={{ marginRight: 15 }} variant="outlined" color="primary">
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary">
                    Accept
                  </Button>
                </Grid>
                <Grid xs={12}>
                  <Typography variant="caption" weight="500">
                    {trans('do-not-show-again')}
                  </Typography>
                  <Checkbox
                    checked={checkedShowAgainOption}
                    onClick={onClickCheckShowInfoAgain}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Grid>
              </div>
            </>
          ) : (
            <>
              <Grid
                style={{ width: '100%', marginTop: 20, marginBottom: 10 }}
                container
                xs={12}
                spacing={6}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant="caption" weight="500">
                  Do not show again
                </Typography>
                <Checkbox
                  checked={checkedShowAgainOption}
                  onClick={onClickCheckShowInfoAgain}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Button onClick={onClose} style={{ marginRight: 15 }} variant="outlined" color="primary">
                  Cancel
                </Button>

                <Button
                  onClick={() => {
                    onClose();
                    openSettings();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Accept
                </Button>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
