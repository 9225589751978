import { Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import Loader from '../../Loader';
import DialogTitleDefault from './Titles/DialogTitle';

const PaperProps = { square: true };
const TransitionProps = {
  up: { direction: 'up' },
  down: { direction: 'down' },
  left: { direction: 'left' },
  right: { direction: 'right' },
};

export const ModalActions = ({ children }) => {
  const classes = useStyles();
  return <DialogActions className={classes.modalActions}>{children}</DialogActions>;
};

export default function ModalDialog({
  title,
  children,
  onClose,
  className,
  fullWidth = true,
  actions = null,
  DialogTitle = null,
  disableContentSpacing = false,
  pending = false,
  position = 'center',
  direction = 'down',
  checkedToggle = false,
  showToggleOption = false,
  onCheckedToggleOption = undefined,
  onlyTitle = false,
  justifyActionsContent = 'flex-end',
  noWrap = true,
  titleHeight = 74,
  paperProps = {},
  ...rest
}) {
  const classes = useStyles({
    justifyActionsContent: justifyActionsContent,
  });
  return (
    <Dialog
      className={clsx(className, `pos-${position}`)}
      onClose={onClose}
      fullWidth={fullWidth}
      TransitionComponent={Slide}
      PaperProps={{ ...PaperProps, ...paperProps }}
      TransitionProps={TransitionProps[direction]}
      {...rest}
    >
      <Loader open={pending} />

      {DialogTitle ? (
        DialogTitle
      ) : (title && onClose) || onlyTitle ? (
        <DialogTitleDefault
          checkedToggle={checkedToggle}
          showToggleOption={showToggleOption}
          onCheckedToggleOption={onCheckedToggleOption}
          title={title}
          onClose={onClose ? onClose : null}
          noWrap={noWrap}
          titleHeight={titleHeight}
        />
      ) : null}

      <DialogContent className={clsx(classes.modalContent, disableContentSpacing && classes.disableContentSpacing)}>
        {children}
      </DialogContent>

      {actions && <DialogActions className={classes.modalActions}>{actions}</DialogActions>}
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  modalContent: {
    padding: 30,
  },
  disableContentSpacing: {
    padding: 0,
  },

  modalActions: {
    padding: '20px 20px',
    borderTopWidth: 1,
    borderTopColor: theme.palette.gray.divider,
    borderTopStyle: 'solid',
    justifyContent: (props) => props.justifyActionsContent,
  },
}));
