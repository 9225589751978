import React from 'react';
import styles from './styles.module.css';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important',
    },
  },
  paddingBottom: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0 !important',
    },
  },
}));

const FeatureVideo = ({ index, item = {}, hideDescription = false }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={10} className={styles.padding}>
      <Grid item md={6} sm={12} xs={12} className={classes.paddingBottom}>
        <div className={styles.image}>
          <img src="https://via.placeholder.com/276x156" alt="image" />
        </div>
      </Grid>
      <Grid item md={6} sm={12} xs={12} className={classes.paddingTop}>
        <h3>Jonh wayne live trining & Procurement Agen Jonh Conway</h3>
        <p>
          It is a long established fact that a reader will be distracted by the readable content of a page when looking
          at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as
          opposed
        </p>
      </Grid>
    </Grid>
  );
};

export default FeatureVideo;
