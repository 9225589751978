import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCalendar = (props) => {
  const { width = 18, height = 18 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 18 18">
      <path
        d="M15 3.33317H14.1667V2.49984C14.1667 2.27882 14.0789 2.06686 13.9226 1.91058C13.7663 1.7543 13.5543 1.6665 13.3333 1.6665C13.1123 1.6665 12.9004 1.7543 12.7441 1.91058C12.5878 2.06686 12.5 2.27882 12.5 2.49984V3.33317H7.5V2.49984C7.5 2.27882 7.4122 2.06686 7.25592 1.91058C7.09964 1.7543 6.88768 1.6665 6.66667 1.6665C6.44565 1.6665 6.23369 1.7543 6.07741 1.91058C5.92113 2.06686 5.83333 2.27882 5.83333 2.49984V3.33317H5C4.33696 3.33317 3.70107 3.59656 3.23223 4.0654C2.76339 4.53424 2.5 5.17013 2.5 5.83317V15.8332C2.5 16.4962 2.76339 17.1321 3.23223 17.6009C3.70107 18.0698 4.33696 18.3332 5 18.3332H15C15.663 18.3332 16.2989 18.0698 16.7678 17.6009C17.2366 17.1321 17.5 16.4962 17.5 15.8332V5.83317C17.5 5.17013 17.2366 4.53424 16.7678 4.0654C16.2989 3.59656 15.663 3.33317 15 3.33317ZM5 4.99984H5.83333V5.83317C5.83333 6.05418 5.92113 6.26615 6.07741 6.42243C6.23369 6.57871 6.44565 6.6665 6.66667 6.6665C6.88768 6.6665 7.09964 6.57871 7.25592 6.42243C7.4122 6.26615 7.5 6.05418 7.5 5.83317V4.99984H12.5V5.83317C12.5 6.05418 12.5878 6.26615 12.7441 6.42243C12.9004 6.57871 13.1123 6.6665 13.3333 6.6665C13.5543 6.6665 13.7663 6.57871 13.9226 6.42243C14.0789 6.26615 14.1667 6.05418 14.1667 5.83317V4.99984H15C15.221 4.99984 15.433 5.08763 15.5893 5.24392C15.7455 5.4002 15.8333 5.61216 15.8333 5.83317V9.1665H4.16667V5.83317C4.16667 5.61216 4.25446 5.4002 4.41074 5.24392C4.56702 5.08763 4.77899 4.99984 5 4.99984ZM15 16.6665H5C4.77899 16.6665 4.56702 16.5787 4.41074 16.4224C4.25446 16.2661 4.16667 16.0542 4.16667 15.8332V10.8332H15.8333V15.8332C15.8333 16.0542 15.7455 16.2661 15.5893 16.4224C15.433 16.5787 15.221 16.6665 15 16.6665Z"
        fill="#888FA9"
      />
      <path
        d="M6.66634 14.1667C7.12658 14.1667 7.49967 13.7936 7.49967 13.3333C7.49967 12.8731 7.12658 12.5 6.66634 12.5C6.2061 12.5 5.83301 12.8731 5.83301 13.3333C5.83301 13.7936 6.2061 14.1667 6.66634 14.1667Z"
        fill="#888FA9"
      />
      <path
        d="M13.3337 12.5H10.0003C9.77931 12.5 9.56735 12.5878 9.41107 12.7441C9.25479 12.9004 9.16699 13.1123 9.16699 13.3333C9.16699 13.5543 9.25479 13.7663 9.41107 13.9226C9.56735 14.0789 9.77931 14.1667 10.0003 14.1667H13.3337C13.5547 14.1667 13.7666 14.0789 13.9229 13.9226C14.0792 13.7663 14.167 13.5543 14.167 13.3333C14.167 13.1123 14.0792 12.9004 13.9229 12.7441C13.7666 12.5878 13.5547 12.5 13.3337 12.5Z"
        fill="#888FA9"
      />
    </SvgIcon>
  );
};

IconCalendar.propTypes = simpleIconPropsTypes;
