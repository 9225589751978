import React from 'react';
import { FiThumbsUp } from 'react-icons/fi';
import { simpleIconPropsTypes } from '../propTypes';

export const IconRecommended = (props) => {
  const { width = 24, color } = props;

  return <FiThumbsUp size={width} color={color} />;
};

IconRecommended.propTypes = simpleIconPropsTypes;
