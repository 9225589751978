import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import { Button } from '../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../components/common/Dialogs/ModalDialog';
import { useForm } from '../../../../../hooks/useForm';
import { useTrans } from '../../../../../services/i18n';
import { ShareEmailsAutocomplete } from '../../../../../components/autocompletes/ShareEmailsAutocomplete';
import { getInitialValues, useScheme } from './helpers';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import Typography from '../../../../../components/common/Typography';
import EmailIcon from '@material-ui/icons/Email';
import connect from '../../../connect';
import { useApplication } from '../../../../../services/application';

const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: true };

const ShareInvoiceModal = ({ open, onClose, authUser, invoiceId }) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const [isSend, setIsSend] = React.useState(false);
  const [emails, setEmails] = React.useState([]);
  const application = useApplication();

  const onSubmit = useCallback(
    (values) => {
      const mails = values.tags.map((i) => i.value);
      setIsSend(true);
      application
        .call('billing.shareInvoice', { emails: [authUser.email, ...mails], invoiceId })
        .then((res) => {
          setIsSend(false);
          resetForm();
          onClose();
        })
        .catch((error) => {
          setIsSend(false);
          resetForm();
          onClose();
        });
    },
    [onClose, infoAlert, errorAlert, successAlert, application]
  );

  const { getFieldProps, handleSubmit, isValid, resetForm } = useForm(
    schema,
    getInitialValues(null),
    onSubmit,
    formOptions
  );

  const closeModal = () => {
    resetForm();
    onClose();
  };

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={trans('share-with-your-team')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" color="primary" disabled={!isValid} pending={isSend} onClick={handleSubmit}>
              {trans('share-now')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography style={{ color: '#888FA9' }} variant="body1" weight="600">
            {trans('share-with-your-team-text')}
          </Typography>

          <span
            style={{
              background: '#17B471',
              height: '46px',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              color: '#FFFFFF',
              padding: '0 18px',
              margin: '20px 0',
            }}
          >
            <EmailIcon style={{ marginRight: '20px' }} />
            <Typography variant="body1">{authUser.email}</Typography>
          </span>

          <Typography style={{ margin: '20px 0', color: '#888FA9' }} variant="body1" weight="600">
            {trans('share-with-your-team')}
          </Typography>
          <ShareEmailsAutocomplete
            label={trans('email')}
            value={emails}
            placeholder={trans('email')}
            required
            multi
            {...getFieldProps('tags')}
            //getTags={getTagsByUser}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

export default connect(ShareInvoiceModal);
