import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconInbox = (props) => {
  const { width = 24, height = 24, archive = false } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      {archive ? (
        <path d="M20 3H4c-1 0-2 .9-2 2v3.01c0 .72.43 1.34 1 1.69V19c0 1.1 1.1 2 2 2h14c.9 0 2-.9 2-2V9.7c.57-.35 1-.97 1-1.69V5c0-1.1-1-2-2-2zm-5 12H9v-2h6v2zm5-7H4V5h16v3z" />
      ) : (
        <path d="M19 3H5c-1.1 0-2 .9-2 2v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 6h-4c0 1.62-1.38 3-3 3s-3-1.38-3-3H5V5h14v4zm-4 7h6v3c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3z" />
      )}
    </SvgIcon>
  );
};

IconInbox.propTypes = simpleIconPropsTypes;
