import React from 'react';
import connect from '../../connect';
import styles from './styles.module.css';
import { Button } from '../../../../../components/common/Buttons';
import Typography from '../../../../../components/common/Typography';
import { Grid } from '@material-ui/core';
import { ReactComponent as InsertDriveFileIcon } from '../../../../../components/assests/InsertDriveFileIcon.svg';
import { ReactComponent as UploadIcon } from '../../../../../components/assests/UploadIcon.svg';
import { Divider } from '../../../../../components/common/Divider';
import { ReactComponent as IconClip } from '../../../../../components/assests/clip-icon.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useTrans } from '../../../../../services/i18n';

const CardActionCsv = ({
  icon,
  title,
  subtitle,
  actionButton,
  textButton,
  variantButton,
  colorButton,
  type,
  file,
  isUpload,
  loadingImport,
}) => {
  const { trans } = useTrans();

  return (
    <div>
      <div className={styles.card}>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {type === 'upload' ? <UploadIcon /> : <InsertDriveFileIcon />}
        </Grid>
        <Grid item xs={8} align={'left'}>
          <div>
            <Typography weight={'bold'} color="primary">
              {title}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle2">{subtitle}</Typography>
          </div>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid className={styles.buttons}>
            {type === 'upload' && (
              <label htmlFor="uploadFile">
                <input
                  accept=".csv"
                  onChange={actionButton}
                  style={{ display: 'none' }}
                  id="uploadFile"
                  name="uploadFile"
                  type="file"
                />
                <Button pending={loadingImport} color={colorButton} variant="contained" component="span">
                  {textButton}
                </Button>
              </label>
            )}
            {type !== 'upload' && (
              <Button onClick={actionButton} variant={variantButton} color={colorButton}>
                {textButton}
              </Button>
            )}
          </Grid>
        </Grid>
        {isUpload && (
          <Grid item style={{ width: '100%' }}>
            <Grid
              item
              style={{ width: '100%', height: '4px', backgroundColor: '#F1F3F9', margin: '15px 0px 15px 0px' }}
              align={'center'}
            >
              <Grid item style={{ width: '97%' }} align={'center'}>
                <Divider spacing={0} />
              </Grid>
            </Grid>

            <Grid item style={{ width: '100%', height: 'auto' }} align={'center'}>
              <Grid container spacing={1} wrap={'nowrap'} style={{ width: '97%' }} align={'center'}>
                <Grid item style={{ margin: '0px 15px 0px 5px' }}>
                  <IconClip />
                </Grid>
                <Grid item>
                  <Typography weight="400" gutter="label" variant="body2">
                    {file.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
      <Grid item style={{ width: '100%', height: 'auto', marginTop: '15px' }} align={'center'}>
        <Grid container spacing={1} wrap={'nowrap'} style={{ width: '97%' }} align={'center'}>
          <Grid item style={{ margin: '0px 15px 0px 5px' }}>
            <InfoOutlinedIcon style={{ color: '#2445A7' }} />
          </Grid>
          <Grid item style={{ color: '#979797' }}>
            <Typography weight="600" variant="button">
              {trans('information-csv')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(CardActionCsv);
