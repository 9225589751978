import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { Paper } from '../../Containers';
import Typography from '../../Typography';

export default function ProgressesLineItem({ className, value, color, height = 30 }) {
  const styles = useStyles();
  const procentValue = `${Math.floor(Number(value) || 0)}%`;

  return (
    <Paper
      className={clsx(className, styles.root, styles[`h-${height}`])}
      style={{ flexBasis: procentValue }}
      bgColorFade={color}
      square
    >
      <Typography variant="overline" color="white" weight="600">
        {procentValue}
      </Typography>
    </Paper>
  );
}

const useStyles = makeStyles({
  root: { flexGrow: 1, flexShrink: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' },

  'h-30': { height: 30 },
});
