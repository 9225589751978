import IconButton from '@material-ui/core/IconButton';
import IconDropDown from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { ListItemTextBase } from '../../../../components/common/Containers';
import { ContextMenuListItem, Popover } from '../../../../components/common/Dialogs/Popover';
import Typography from '../../../../components/common/Typography';
import SavePipelineForm from '../../../../components/forms/SavePipelineForm';
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import usePopover from '../../../../hooks/usePopover';
import { useTrans } from '../../../../services/i18n';
import useQueryCallbacks from '../../../../hooks/query/useQueryCallbacks';
import { QUERY_PIPELINE } from '../../../../constants/queries';
import connect from '../connect';

const PipelineMenu = ({ item, pending, onDelete, onSave, editPipeline, getTagsByUser, pipelineId, settings }) => {
  const { trans } = useTrans();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useBooleanHandlers();
  const [openEdit, onOpenEdit, onCloseEdit] = useBooleanHandlers();

  const { deleteQuery } = useQueryCallbacks();

  return (
    !!item && (
      <>
        <IconButton onClick={onOpenPopover}>
          <IconDropDown />
        </IconButton>

        <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
          <ContextMenuListItem button onClick={onOpenEdit} onClickCB={onClosePopover} size="normal">
            <ListItemTextBase>{trans('edit')}</ListItemTextBase>
          </ContextMenuListItem>
          {['Owner', 'Admin'].includes(settings.userRole) && (
            <ContextMenuListItem button onClick={onOpenConfirm} onClickCB={onClosePopover} size="normal">
              <ListItemTextBase>{trans('delete')}</ListItemTextBase>
            </ContextMenuListItem>
          )}
        </Popover>

        {openEdit && (
          <SavePipelineForm
            item={item}
            open={openEdit}
            onClose={onCloseEdit}
            onSaveSearch={onSave}
            editPipeline={editPipeline}
            pipelineId={pipelineId}
            getTagsByUser={getTagsByUser}
          />
        )}

        <ConfirmDialog
          open={openConfirm}
          onClose={onCloseConfirm}
          onConfirm={() => {
            onDelete?.({ id: pipelineId }).then(deleteQuery(QUERY_PIPELINE));
          }}
          pending={pending}
          title={trans('DeletePipeline')}
          text={trans('ConfirmDeletePipeline')}
        >
          <Typography color="primary">{item.title}</Typography>
        </ConfirmDialog>
      </>
    )
  );
};

export default connect(PipelineMenu);
