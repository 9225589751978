import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const initialValues = {
  taskName: '',
  description: '',
  tags: [],
  dueDate: null,
  assignedUser: '',
  pursuit: { id: '', pipelineId: '' },
};

export const getInitialValues = (item, pursuit) => {
  return Object.assign(
    {},
    initialValues,
    item,
    pursuit
      ? {
          pursuit: {
            id: pursuit.id,
            pipelineId: pursuit.pipelineId,
          },
        }
      : {}
  );
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    taskName: Yup.string().required(trans('required-field')),
    description: Yup.string().required(trans('required-field')),
    dueDate: Yup.date().typeError(trans('required-field')).required(trans('required-field')),
    assignedUser: Yup.string().required(trans('required-field')),
    pursuit: Yup.object()
      .shape({
        id: Yup.string().required(trans('required-field')),
        pipelineId: Yup.string().required(trans('required-field')),
      })
      .required(trans('required-field')),
  })
);
