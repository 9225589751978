import { createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_CONTRACT_OPPORTUNITIES } from '../../constants';
import { application } from '../../services/application';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  extendBuilderWithNotInterestedActions,
  extendListBuilder,
  generateListActions,
  generateNotInterestedActions,
  generateSearchActions,
  INITIAL_LIST_STATE_EXTENDED,
} from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['postedDate', 'desc'],
};

const listActions = generateListActions({
  scope: SCOPE_CONTRACT_OPPORTUNITIES,
  apiMethod: {
    GET_LIST: API.CONTRACT_OPPORTUNITIES.GET_LIST,
    EXPORT_CSV: API.CONTRACT_OPPORTUNITIES.EXPORT_CSV,
  },
  getStore: (store) => store.contractOpportunities,
});

const notInterestingActions = generateNotInterestedActions({
  scope: SCOPE_CONTRACT_OPPORTUNITIES,
  apiMethod: { ADD_NOT_INTERESTED: API.CONTRACT_OPPORTUNITIES.ADD_NOT_INTERESTED },
  getStore: (store) => store.contractOpportunities,
});

const searchActions = generateSearchActions({
  scope: SCOPE_CONTRACT_OPPORTUNITIES,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.contractOpportunities,
});

const getAllNaics = createAsyncThunk('fco/getAllNaics', (params) => {
  return application.call(API.CONTRACT_OPPORTUNITIES.GET_ALL_NAICS, params);
});

const createQuoteRequest = createAsyncThunk('fco/createQuoteRequest', (params) => {
  return application.call(API.CONTRACT_OPPORTUNITIES.CREATE_QUOTE_REQUEST, params);
});

export const actions = {
  ...listActions,
  ...searchActions,
  ...notInterestingActions,
  getAllNaics,
  createQuoteRequest,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendListBuilder(builder, actions);
  extendBuilderWithNotInterestedActions(builder, actions);

  return builder;
});
