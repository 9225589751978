import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconHelp = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm.774 11.984V17h-2.002v-2.016h2.002zm-.91-8.26c.532 0 1.034.083 1.505.248.471.166.861.438 1.169.816.205.238.364.514.476.83.112.314.168.63.168.948 0 .509-.114.97-.343 1.386-.229.415-.511.803-.847 1.162-.145.154-.274.288-.389.402-.114.115-.216.222-.307.322s-.172.205-.242.312c-.13.215-.21.39-.238.525-.028.135-.042.334-.042.595h-2.016l.007-.231c.013-.231.047-.462.101-.693.073-.308.181-.588.326-.84.117-.238.244-.426.381-.563.138-.138.297-.28.476-.424.18-.145.394-.348.641-.609.191-.21.319-.417.382-.62.063-.202.094-.425.094-.668 0-.126-.013-.247-.039-.364-.025-.117-.073-.22-.143-.308-.112-.154-.26-.265-.444-.332-.185-.068-.382-.102-.592-.102-.159 0-.314.019-.466.056-.151.037-.28.098-.388.182-.13.098-.227.222-.29.371-.063.15-.093.315-.088.497H8.7l.035-.246c.07-.405.201-.78.392-1.126.229-.415.525-.742.889-.98.266-.177.56-.313.882-.406.322-.093.644-.14.966-.14z"
        transform="translate(-1376 -683) translate(720) translate(581 683) translate(75)"
      />
    </SvgIcon>
  );
};

IconHelp.propTypes = simpleIconPropsTypes;
