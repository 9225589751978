import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '../../../../components/common/Typography';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useStyles from './styles';

function Counter(props) {
  const { number, onIncrement, onDecrement } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <Grid item sm={4} xs={4}>
          <IconButton disabled={number <= 0} className={classes.buttonCounter} size="small" onClick={onDecrement}>
            <ArrowDropDownIcon />
          </IconButton>
        </Grid>
        <Grid item lg={2} sm={4} xs={4}>
          <Typography weight="600" variant="h4">
            {number}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={4}>
          <IconButton className={classes.buttonCounter} size="small" onClick={onIncrement}>
            <ArrowDropUpIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

export default Counter;
