import Grid from '@material-ui/core/Grid';
import React, { useState, useCallback } from 'react';
import { useForm } from '../../../../../hooks/useForm';
import { useTrans } from '../../../../../services/i18n';
import { Button } from '../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../components/common/Dialogs';
import { getInitialValues, useScheme } from './helpers';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import { Typography } from '@material-ui/core';
import connect from '../../connect';
import { TagsAutocomplete } from '../../../../../components/autocompletes/TagsAutocomplete';

const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: true };

const AddVendorTagsForm = ({ open, onClose, addVendorTags, items, vendorsTags, filters, getList }) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert } = useAlertContext();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      addTags(values);
    },
    [onClose, addVendorTags]
  );

  const { getFieldProps, handleSubmit, resetForm } = useForm(schema, getInitialValues(), onSubmit, formOptions);

  const closeAddVendorTagsModal = () => {
    resetForm();
    onClose();
  };

  const addTags = (values) => {
    try {
      setLoading(true);
      const tags = values.tags.map((t) => t.create);
      const vendors = items.filter((i) => i.checked === true).map((i) => i.id);
      addVendorTags({ tags: tags, vendors: vendors }).then((res) => {
        getList(filters);
        closeAddVendorTagsModal();
        successAlert('Tags added!');
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      errorAlert('Error adding tags');
    }
  };

  return (
    <ModalDialog
      open={open}
      onClose={() => closeAddVendorTagsModal()}
      title={trans('add-tag')}
      actions={
        <div>
          <Button variant="outlined" color="primary" onClick={() => closeAddVendorTagsModal()}>
            {trans('cancel')}
          </Button>
          <Button
            pending={isLoading}
            style={{ marginLeft: '16px' }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {trans('add')}
          </Button>
        </div>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Tag selected Vedors to search them quicker</Typography>
        </Grid>

        <Grid container style={{ padding: '0  12px 16px 12px' }}>
          <Grid item xs={12}>
            <TagsAutocomplete
              label={trans('tag')}
              required
              placeholder={trans('tag-name')}
              multi
              freeSolo={false}
              options={vendorsTags}
              {...getFieldProps('tags')}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

export default connect(AddVendorTagsForm);
