import { useState } from 'react';
import Typography from '../../../../../components/common/Typography';

const CodeDescription = ({ item }) => {
  const [seeMore, setSeeMore] = useState(false);

  const truncateText = item?.description.substring(0, 100);
  const lengthDescription = item?.description.length;

  return (
    <>
      <Typography color="hint" weight="500" variant="body2">
        {seeMore ? item.description : `${truncateText} ${lengthDescription > 100 ? '...' : ''}`}
      </Typography>
      {lengthDescription > 100 && (
        <Typography
          className="pointer"
          onClick={() => setSeeMore(!seeMore)}
          color="primary"
          weight="bold"
          variant="body2"
        >
          {seeMore ? 'Hide' : 'See more'}
        </Typography>
      )}
    </>
  );
};

export default CodeDescription;
