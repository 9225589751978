import * as Yup from 'yup';
import makeValidationSchema from '../../../../../../../hooks/makeValidationSchema';

export const initialValues = {
  responseBy: null,
  title: '',
  description: '',
  naics: [],
  budget: '',
  tags: [],
};

export const getInitialValues = (item) => {
  if (item) {
    item = {
      ...item,
      naics: item.naics ? item.naics.map((i) => ({ id: i, value: i })) : [],
      tags: item.tags ? item.tags.map((i) => ({ create: i, value: i })) : [],
    };
  }
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    responseBy: Yup.date().typeError(trans('required-field')).required(trans('required-field')),
    title: Yup.string().required(trans('required-field')),
    description: Yup.string().required(trans('required-field')),
    naics: Yup.array().required(trans('required-field')).min(1),
    budget: Yup.number().typeError('Must be a number').required(trans('required-field')),
  })
);
