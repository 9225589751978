import IconButton from '@material-ui/core/IconButton';
import IconSorting from '@material-ui/icons/ImportExport';
import React from 'react';
import { Button } from '../../../../components/common/Buttons';
import { Popover } from '../../../../components/common/Dialogs/Popover';
import Typography from '../../../../components/common/Typography';
import { IconSort } from '../../../../components/svg/Icons/IconSort';
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';
import usePopover from '../../../../hooks/usePopover';
import { useTrans } from '../../../../services/i18n';
import { SpacingContainer } from '../../../common/Containers/Base';
import SortItem from './SortItem';
import { Box } from '@material-ui/core';

const buttonStyle = {
  border: '1px solid rgba(36, 69, 167, 0.40)',
};

export default function SortingList({
  scope,
  order,
  orderOptions,
  onChangeOrder,
  media = 'xs',
  mobileButton = false,
  label = 'sort-by',
  ignoreSmScreen = false,
  alternativeStyle = false,
  customButtonStyle = {},
}) {
  let isSmScreen = useMediaQueryDown(media);
  if (ignoreSmScreen) {
    isSmScreen = false;
  }
  const { trans } = useTrans();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();

  const onItemClick = React.useCallback(
    (value) => {
      onClosePopover();
      return onChangeOrder && onChangeOrder(value);
    },
    [onChangeOrder, onClosePopover]
  );

  const current = React.useMemo(() => {
    const [value, direction = 'asc'] = order || [];
    const founded = orderOptions.find((o) => o.value === value);
    return founded ? { name: founded.name, direction: founded[direction] } : { name: 'N/A' };
  }, [orderOptions, order]);

  return (
    <>
      {mobileButton && alternativeStyle ? (
        <Button
          onClick={onOpenPopover}
          id="sort-by"
          fullWidth={isSmScreen}
          variant={isSmScreen ? 'outlined' : 'text'}
          size={isSmScreen ? 'large' : 'small'}
          endIcon={<IconSort />}
          style={isSmScreen ? { ...buttonStyle, ...customButtonStyle } : customButtonStyle}
        >
          <Typography
            variant="body2"
            color="hint"
            component="div"
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Box style={{ flex: 1, whiteSpace: 'nowrap' }}>
              {trans(label)}
              &nbsp; &nbsp; &nbsp;
            </Box>
            <Typography color="label" weight="700" variant="body2" component="div">
              {trans(current.name)} {typeof current.direction === 'string' ? `/ ${trans(current.direction)}` : ''}
            </Typography>
          </Typography>
        </Button>
      ) : alternativeStyle ? (
        <Box>
          <Typography variant="body2" color="hint" noWrap>
            {trans(label)}
          </Typography>
          <Button
            onClick={onOpenPopover}
            id="filter-by"
            style={{
              padding: '0 1rem 0 0',
              justifyContent: 'space-between',
              width: '100%',
              textAlign: 'start',
            }}
            endIcon={<IconSort />}
          >
            <Typography color="label" weight="700" variant="body2" component="span">
              {trans(current.name)} {typeof current.direction === 'string' ? `/ ${trans(current.direction)}` : ''}
            </Typography>
          </Button>
        </Box>
      ) : mobileButton && isSmScreen ? (
        <IconButton onClick={onOpenPopover} id="sort-by" size="small">
          <IconSorting />
        </IconButton>
      ) : (
        <Button
          onClick={onOpenPopover}
          id="sort-by"
          fullWidth={isSmScreen}
          variant={isSmScreen ? 'outlined' : 'text'}
          size={isSmScreen ? 'large' : 'small'}
          endIcon={<IconSort />}
          style={isSmScreen ? { ...buttonStyle, ...customButtonStyle } : customButtonStyle}
        >
          <Typography
            variant="body2"
            color="hint"
            noWrap
            component="div"
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            {trans(label)}&nbsp;&nbsp;&nbsp;
            <Typography color="label" weight="700" variant="body2" component="span">
              {trans(current.name)} {typeof current.direction === 'string' ? `/ ${trans(current.direction)}` : ''}
            </Typography>
          </Typography>
        </Button>
      )}

      <Popover id={`id-popover-sorting-${scope}`} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        <SpacingContainer py={1} px={2}>
          {orderOptions.map((item) => (
            <SortItem key={item.value} item={item} onItemClick={onItemClick} />
          ))}
        </SpacingContainer>
      </Popover>
    </>
  );
}
