import { useDrag } from 'react-dnd';

const collect = (monitor) => ({ isDragging: monitor.isDragging() });

export default function useDragTarget({ type, item, ...rest }, deps) {
  const [collectedProps, dragRef, preview] = useDrag(
    {
      item: { type, item },
      type,
      collect,
      ...rest,
    },
    deps
  );

  return { ...collectedProps, dragRef, preview };
}
