import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconStarOutlined = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M8.899 8.072L3.78 8.81l-.114.024c-.665.18-.904 1.037-.386 1.534l3.695 3.551-.872 5.012-.012.11c-.04.687.693 1.184 1.328.855L12 17.519l4.58 2.377.101.045c.643.244 1.337-.305 1.215-1.01l-.873-5.012 3.696-3.55.08-.087c.434-.534.13-1.37-.58-1.472L15.1 8.072l-2.286-4.57c-.336-.67-1.292-.67-1.628 0L8.9 8.073zM12 5.943l1.686 3.37.055.096c.143.215.37.36.629.398l3.743.54-2.699 2.594-.076.083c-.165.203-.235.469-.19.73l.64 3.678-3.37-1.746-.114-.05c-.236-.084-.498-.067-.723.05l-3.37 1.746.641-3.678.012-.112c.013-.261-.087-.518-.278-.701l-2.7-2.594 3.744-.54c.295-.043.55-.227.684-.494L12 5.943z"
        transform="translate(-997.000000, -441.000000) translate(251.000000, 182.000000) translate(720.000000, 0.000000) translate(0.000000, 24.000000) translate(26.000000, 235.000000)"
      />
    </SvgIcon>
  );
};

IconStarOutlined.propTypes = simpleIconPropsTypes;
