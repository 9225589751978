import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useTabs from '../../../hooks/useTabs';
import { useTrans } from '../../../services/i18n';
import { emptyArray } from '../../../utils/arrayUtils';
import { scrollToTarget } from '../../../utils/scrollUtils';
import { Tab, Tabs } from '../../common/Tabs';
import { IconBack, IconPrint } from '../../svg/Icons';

const DetailsAnchorsHeader = ({ backLink, onPrint, anchors = [] }) => {
  const styles = useStyles();
  const { trans } = useTrans();
  const [tab, onChangeTab] = useTabs();
  const history = useHistory();

  React.useEffect(() => {
    if (!tab) return;
    scrollToTarget(document.getElementById(tab));
  }, [tab]);

  return (
    <Grid container className={styles.header} alignItems="center" wrap="nowrap" justifyContent="space-between">
      {history.length > 0 ? (
        <IconButton onClick={history.goBack} className={styles.btnHeader} color="inherit">
          <IconBack />
        </IconButton>
      ) : (
        <IconButton component={RouterLink} to={backLink} className={styles.btnHeader} color="inherit">
          <IconBack />
        </IconButton>
      )}

      {!emptyArray(anchors) && (
        <Grid item className={styles.content} zeroMinWidth>
          <Tabs value={tab} onChange={onChangeTab}>
            {anchors.map((anchor, ind) => (
              <Tab key={ind} value={anchor.id} label={trans(anchor.i18nKey)} />
            ))}
          </Tabs>
        </Grid>
      )}

      {onPrint ? (
        <IconButton onClick={onPrint} className={styles.btnHeader} color="inherit" disabled={true}>
          <IconPrint />
        </IconButton>
      ) : (
        <Grid item className={styles.btnHeader} />
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  //theme.zIndex.appBar + 1
  header: {
    zIndex: 1,
    position: 'relative',
    borderTop: `1px solid ${theme.palette.gray.divider}`,
    borderLeft: `1px solid ${theme.palette.gray.divider}`,
    height: 60,
    boxShadow: '0 10px 20px 0 rgba(78, 89, 120, 0.1)',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  btnHeader: {
    borderRadius: 0,
    height: '100%',
    width: 80,
    [theme.breakpoints.down('sm')]: { width: 60 },
  },
  content: { height: '100%' },
}));

export default DetailsAnchorsHeader;
