import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { Popover } from '../../../../components/common/Dialogs/Popover';
import { SpacingContainer } from '../../../../components/common/Containers';

const useStyles = makeStyles({
  bigInput: {
    '& input': { paddingTop: 21, paddingBottom: 22 },
  },
  infoIcon: {
    width: 20,
    height: 20,
  },
});

export default function InfoPopover(props) {
  const { helpTooltip = false, anchorEl, onOpenPopover, onClosePopover, ...rest } = props;
  {
    /* Help popover */
  }
  return (
    <Popover
      id={'show-description'}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: { width: '20.125rem' },
      }}
    >
      <SpacingContainer px={3} py={2}>
        <div
          style={{ textAlign: 'left' }}
          dangerouslySetInnerHTML={{
            __html: `${helpTooltip}`,
          }}
        ></div>
      </SpacingContainer>
    </Popover>
  );
}
