import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconQuoteRequests = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path
        className="st0"
        d="M17.5,5.7C17.5,5.7,17.5,5.7,17.5,5.7c-0.1-2.8-2.2-5-4.9-5.3c-0.1,0-0.3-0.1-0.4,0c0,0-0.1,0-0.1,0H6.1
		c0,0,0,0,0,0C3,0.3,0.5,2.8,0.5,5.9v6.8c0,3,2.5,5.5,5.6,5.5c0,0,0,0,0,0h5.8c0,0,0,0,0,0c3.1,0,5.6-2.5,5.6-5.5V5.9
		C17.5,5.8,17.5,5.8,17.5,5.7z M13.3,3l1.2,0.7L15,4.9h-1.7V3z M11.9,16.2C11.9,16.2,11.9,16.2,11.9,16.2H6.1c0,0,0,0,0,0
		c-2,0-3.6-1.6-3.6-3.5V5.9c0-1.9,1.6-3.5,3.6-3.5c0,0,0,0,0,0h5.1v3.5c0,0.6,0.4,1,1,1h3.2v5.8C15.5,14.6,13.9,16.2,11.9,16.2z"
      />
      <path className="st0" d="M8,12H4.8c-0.6,0-1,0.4-1,1s0.4,1,1,1H8c0.6,0,1-0.4,1-1S8.6,12,8,12z" />
      <path className="st0" d="M12.3,8.8H4.8c-0.6,0-1,0.4-1,1s0.4,1,1,1h7.4c0.6,0,1-0.4,1-1S12.8,8.8,12.3,8.8z" />
    </SvgIcon>
  );
};

IconQuoteRequests.propTypes = simpleIconPropsTypes;
