import { connect } from 'react-redux';
import {
  editSettingsAction,
  setupSettingsAction,
  getUserRoleAction,
  setupAvatarAction,
  setupAvatarIdAction,
} from '../../store/orgSettings';
import { subscriptionActions } from '../../store/subscription';

import { application } from '../../services/application';
import {
  addUserAction,
  deleteUserAction,
  editUserAction,
  getUsersAction,
  reSendInviteAction,
  enableDisableUser,
} from '../../store/orgUsers';
import { actions as ticketsActions } from '../../store/business/tickets';
import { actions as documentsActions } from '../../store/business/documents';

import { authActions } from '../../store/auth';
import { orgActions } from '../../store/orgSettings';
function mapStateToProps(state) {
  return {
    settings: state.orgSettings,
    users: state.orgUsers,
    authUser: state.auth,
    openRegisterOrgModal: state.orgSettings.openRegisterOrgModal,
    openAddFeatureInfoOrgModal: state.orgSettings.openAddFeatureInfoOrgModal,
    openAddUpdateOrgModal: state.orgSettings.openAddUpdateOrgModal,
    openPublicProfileOrgModal: state.orgSettings.openPublicProfileOrgModal,
    subscription: state.subscription,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOpenModalPremium: (payload) => dispatch(subscriptionActions.setOpenModalPremium(payload)),
    addTicket: (params) => dispatch(ticketsActions.addTicket(params)),
    enableDisableUser: (params) => dispatch(enableDisableUser(params)),
    updateUserOrganizations: (params) => dispatch(authActions.updateUserOrganizations(params)),
    updateCurrentOrgInfo: (params) => dispatch(orgActions.updateCurrentOrgInfo(params)),
    getOrganizationInfo: () => {
      return dispatch(orgActions.getOrganizationInfoAction({})).then((res) => {
        if (res.payload) dispatch(getUsersAction(res.payload));
      });
    },
    onCreateTeaming: (params) => {
      return dispatch(documentsActions.createTeaming(params)).then((res) => {
        if (!res.payload.ok) {
          throw new Error('Error uploading new version file');
        }
      });
    },

    /*getOrganizationInfo: () => {
      return application
        .call('organization.getOrganizationInfo')
        .then((settings) => dispatch(getOrganizationInfoAction(settings)))
        .catch(console.warn);
    },*/
    getUsers: () => {
      return application
        .call('organization.getOrganizationInfo')
        .then((organization) => dispatch(getUsersAction(organization)))
        .catch(console.warn);
    },
    setupSettings: (
      avatarId,
      orgName,
      capabilityStatement,
      description,
      contactPerson,
      contactPhoneNumber,
      contactEmail
    ) => {
      return dispatch(
        setupSettingsAction({
          avatarId,
          orgName,
          capabilityStatement,
          description,
          contactPerson,
          contactPhoneNumber,
          contactEmail,
        })
      );
    },
    editSettings: (
      avatarId,
      orgName,
      capabilityStatement,
      description,
      contactPerson,
      contactPhoneNumber,
      contactEmail
    ) => {
      return dispatch(
        editSettingsAction({
          avatarId,
          orgName,
          capabilityStatement,
          description,
          contactPerson,
          contactPhoneNumber,
          contactEmail,
        })
      );
    },
    addUser: (values) => {
      return dispatch(addUserAction({ ...values }));
    },
    editUser: (values, username, organizationId) => {
      return dispatch(editUserAction({ ...values, username: username, organizationId: organizationId }));
    },
    deleteUser: (userOrganization) => {
      return dispatch(
        deleteUserAction({
          userOrganizationId: userOrganization.id,
          orgId: userOrganization.organizationId,
          user: userOrganization.userId,
        })
      );
    },
    reSendInvite: (username) => {
      return dispatch(reSendInviteAction({ username: username }));
    },
    getAvatarUploadUrl: (fileName) => {
      return application.call('organization.getUploadAvatarUrl', { fileName });
    },
    getAvatarDownloadUrl: (id) => {
      return application.call('organization.getDownloadAvatarUrl', { id });
    },
    removeAvatar: (id) => {
      return application.call('organization.removeAvatar', { id });
    },
    getUserRole: () => {
      return dispatch(getUserRoleAction());
    },
    setupAvatar: (avatar) => {
      return dispatch(setupAvatarAction(avatar));
    },
    setupAvatarId: (avatarId) => {
      return dispatch(setupAvatarIdAction(avatarId));
    },
    setPublicProfile: (params) => {
      return dispatch(orgActions.setPublicProfile(params));
    },
    getPublicProfile: (params) => {
      return dispatch(orgActions.getPublicProfile(params));
    },
    onChangePublicProfile: (params) => {
      return dispatch(orgActions.onChangePublicProfile(params));
    },
    openRegisterOrg: (params) => dispatch(orgActions.openRegisterOrg(params)),
    registerOrg: (params) => dispatch(orgActions.registerOrg(params)),
    openAddFeatureInfoOrg: (params) => dispatch(orgActions.openAddFeatureInfoOrg(params)),
    openAddUpdateOrg: (params) => dispatch(orgActions.openAddUpdateOrg(params)),
    openPublicProfileOrg: (params) => dispatch(orgActions.openPublicProfileOrg(params)),
    checkOrganization: (params) => dispatch(orgActions.checkOrganization(params)),
    updateFeatureInfoOrg: (params) => dispatch(orgActions.updateFeatureInfoAction(params)),
    setSeats: (params) => dispatch(orgActions.setSeats(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
