import Grid from '@material-ui/core/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from '../../../hooks/useForm';
import { useTrans } from '../../../services/i18n';
import { TagsAutocomplete } from '../../autocompletes/TagsAutocomplete';
import { Button } from '../../common/Buttons';
import { ModalDialog } from '../../common/Dialogs';
import { DatePicker, TextField } from '../../common/Inputs';
import { getInitialValues, useScheme } from './helpers';
import { API } from '../../../constants';
import { application } from '../../../services/application';
import { MenuItem, FormLabel, Select } from '@material-ui/core';
import styles from './styles.module.css';
import { useAlertContext } from '../../../contexts/AlertContext';
import Typography from '../../common/Typography';
import Paper from '../../common/Containers/Paper';
import { SpacingContainer } from '../../common/Containers/Base';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { useIsMobile } from '../../../hooks/useIsMobile';

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  validateOnSubmit: true,
};

export default function SaveTaskForm({
  pursuit,
  formShowOptionsPursuit = false,
  item,
  open,
  onClose,
  onSaveTask,
  onEditTask,
  actionTask,
  searchTags,
}) {
  const { trans } = useTrans();
  const isMobile = useIsMobile();
  const schema = useScheme();
  const [users, setUsers] = useState([]);
  const [pipelines, setPipelines] = useState([]);
  const [pursuits, setPursuits] = useState([]);
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const [isSend, setIsSend] = React.useState(false);
  const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState(false);

  const onSubmit = useCallback(
    (values) => {
      const editting = !!values?.id;
      if (editting && typeof onEditTask !== 'function') return;
      if (!editting && typeof onSaveTask !== 'function') return;

      setIsSend(true);
      Promise.resolve(editting ? onEditTask(values) : onSaveTask(values))
        .then((res) => {
          if (res?.payload?.success) {
            if (!editting) {
              actionTask?.('created', res.payload.task);
            }
            successAlert(editting ? 'Task saved' : 'Task added');
            onClose();
            resetForm();
          } else {
            errorAlert();
          }
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false));
    },
    [onClose, onSaveTask, infoAlert, errorAlert, successAlert, pursuit]
  );

  const { getFieldProps, handleSubmit, values, isSubmitting, isValid, resetForm, setFieldValue } = useForm(
    schema,
    getInitialValues(item, pursuit),
    onSubmit,
    {
      ...formOptions,
      ...(hasBeenSubmitted && { validateOnChange: true }),
      ...(hasBeenSubmitted && { validateOnBlur: true }),
    }
  );

  const getOrganizationUsers = async () => {
    const res = await application.call(API.USERS.GET_ORGANIZATION_USERS, {});
    setUsers(Array.isArray(res) ? res : []);
  };

  const getPipelines = async () => {
    const pipelines = (await application.call(API.PIPELINES.GET_LIST, {}))?.items;
    setPipelines(Array.isArray(pipelines) ? pipelines : []);
  };
  const getPursuits = async () => {
    const pursuits = (await application.call(API.PURSUITS.GET_PURSUITS, {}))?.items;
    setPursuits(Array.isArray(pursuits) ? pursuits : []);
  };

  const handleCloseForm = () => {
    onClose();
    resetForm();
    setHasBeenSubmitted(false);
  };

  const handleChangePipeline = (v) => {
    setFieldValue('pursuit', { id: '', pipelineId: v.target.value });
  };

  useEffect(() => {
    getOrganizationUsers();
    if (formShowOptionsPursuit) {
      getPipelines();
      getPursuits();
    }
  }, []);

  return (
    <ModalDialog
      open={open}
      onClose={handleCloseForm}
      title={trans(values.id ? 'edit-pipelines-pursuits-tasks' : 'add-pipelines-pursuits-tasks')}
      direction={isMobile ? 'up' : 'down'}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" disabled={isSend || isSubmitting} onClick={handleCloseForm}>
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!isValid || isSubmitting}
              pending={isSend}
              onClick={() => {
                setHasBeenSubmitted(true);
                handleSubmit();
              }}
            >
              {isSubmitting ? trans(values.id ? 'Saving' : 'Creating') : trans(values.id ? 'save' : 'create')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            style={{ background: '#2445A729', display: 'flex', width: '100%' }}
            className={''}
            bgColor="paperSecondary"
            component={SpacingContainer}
            py={2}
            px={2}
            radius="4"
          >
            <InfoIcon color="primary" style={{ marginRight: '10px' }} />
            <Typography variant="body2">
              <Typography variant="h6">{trans('reminder-to-add-task')}</Typography>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={trans('name')}
            placeholder={trans('name')}
            required
            maxLength={80}
            labelColor={''}
            labelVariant={'h6'}
            {...getFieldProps('taskName')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={trans('description')}
            placeholder={trans('description')}
            required
            multiline
            minRows={4}
            maxRows={10}
            maxLength={255}
            labelColor={''}
            labelVariant={'h6'}
            {...getFieldProps('description')}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <DatePicker
            label={trans('due-date')}
            placeholder={trans('date')}
            required
            disablePast
            disableToolbar
            orientation="landscape"
            labelColor={''}
            labelVariant={'h6'}
            InputAdornmentProps={{ position: 'end', variant: 'outlined' }}
            {...getFieldProps('dueDate')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormLabel
            style={{ color: '#000000', fontSize: '15px', fontFamily: 'Lexend', fontWeight: '500' }}
            className={styles.label}
          >
            {trans('assigned-to')}
          </FormLabel>
          <Select
            className={styles.assignedTo}
            type="select"
            placeholder={trans('select')}
            {...getFieldProps('assignedUser')}
          >
            {users.map((u) => (
              <MenuItem key={u._id} value={u.userName}>
                {u.name} {u.familyName}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <TagsAutocomplete
            label={
              <FormLabel style={{ color: '#000000', fontSize: '15px', fontFamily: 'Lexend', fontWeight: '500' }}>
                {trans('Tags')}
              </FormLabel>
            }
            placeholder={trans('TypeToSearchOrCreateTags')}
            multi
            {...getFieldProps('tags')}
            getTags={searchTags}
          />
        </Grid>

        {formShowOptionsPursuit && (
          <React.Fragment>
            <Grid item md={6} xs={12}>
              <FormLabel
                style={{ color: '#000000', fontSize: '15px', fontFamily: 'Lexend', fontWeight: '500' }}
                className={styles.label}
              >
                {trans('Pipeline')}
              </FormLabel>
              <Select
                className={styles.assignedTo}
                type="select"
                placeholder={trans('Pipeline')}
                {...getFieldProps('pursuit.pipelineId')}
                error={getFieldProps('pursuit').error}
                onChange={handleChangePipeline}
              >
                {pipelines.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {values.pursuit.pipelineId && (
              <Grid item md={6} xs={12}>
                <FormLabel
                  style={{ color: '#000000', fontSize: '15px', fontFamily: 'Lexend', fontWeight: '500' }}
                  className={styles.label}
                >
                  {trans('Pursuit')}
                </FormLabel>
                {pursuits.filter((x) => x.pipelineId === values.pursuit.pipelineId).length > 0 ? (
                  <Select
                    className={styles.assignedTo}
                    type="select"
                    placeholder={trans('Pursuit')}
                    {...getFieldProps('pursuit.id')}
                    error={getFieldProps('pursuit').error}
                  >
                    {pursuits
                      .filter((x) => x.pipelineId === values.pursuit.pipelineId)
                      .map((p) => (
                        <MenuItem key={p.id} value={p.id}>
                          {p.pursuitName}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  <Typography variant="h6">{trans('no-pursuits-created')}</Typography>
                )}
              </Grid>
            )}
          </React.Fragment>
        )}
      </Grid>
    </ModalDialog>
  );
}
