import { connect } from 'react-redux';
import { actions } from '../../../store/tasks';
import { actions as notificationActions } from '../../../store/notifications';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeStatusById(info) {
      return dispatch(
        actions.changeTaskStatus({
          id: info?.id,
          status: info?.status === 'incomplete' ? 'completed' : 'incomplete',
        })
      ).then(() => dispatch(notificationActions.fetchList({})));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
