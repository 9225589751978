import React from 'react';
import connect from '../connect';
import { Divider } from '../../Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '../../Typography';
import { useTrans } from '../../../../services/i18n';
import premiumIcon from '../../../assests/premium-icon.png';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReactComponent as IconCheck } from '../../../assests/ico-check.svg';
import { ReactComponent as IconCross } from '../../../assests/ico-cross.svg';
import { ReactComponent as Logo } from '../../../assests/Logo-APP-Login.svg';
import { SpacingContainer, Paper, InlineContainer } from '../../Containers';
import { DrawerDialog } from '../../Dialogs/DrawerDialog';
import SwitchPlan from '../../../../containers/Billing/PlansTab/PlanSwitch';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { Button } from '../../Buttons';
import { ROUTE_CHECKOUT, ROUTE_DASHBOARD } from '../../../../constants';
const useStyles = makeStyles({
  item: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  headers: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  banner: {
    backgroundColor: '#112159',
    borderRadius: '12px',
    height: '56px',
    marginBottom: '15px',
  },
  button: {
    backgroundColor: '#112159',
  },
  imagePublic: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '28px',
    marginRight: '0px',
    marginLeft: '10px',
  },
  divider: {
    flexShrink: 0,
    marginBottom: '15px',
  },
  table: {
    backgroundColor: 'white',
    width: '75%',
  },
  contact: {
    background: '#F1F3F9',
    borderRadius: '12px',
    width: '100%',
    height: '109px',
  },
  infocontact: {
    padding: '15px 0px 0px 25px',
  },
  titleContact: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  bannerContent: {
    marginTop: '10px',
    marginLeft: '10px',
    marginRight: '5px',
  },
  bannerText: {
    marginTop: '12px',
  },
  help: {
    color: '#888FA9',
    margin: '25px 0px 25px 0px',
  },
  skip: {
    backgroundColor: 'white',
    border: '1px solid rgba(36, 69, 167, 0.4)',
    color: '#2445A7',
    width: '110px',
    height: '52px',
  },
  getPremium: {
    width: '180px',
    height: '52px',
  },
});
const featureTypes = {
  notIncluded: 'partial',
  limited: 'limited',
  included: 'included',
};

export const premiumFeatureKeys = [
  'unlimited-searches',
  'export-up-to-3000-records',
  'live-support',
  'unlimited-pursuits-pipelines-tasks',
  'full-access-federal-agents',
  'up-to-10-users',
  'feature-your-vendor-profile',
];

export const freeFeatureKeys = [
  {
    key: 'maximum-10-saved-searches',
    type: featureTypes.limited,
  },
  {
    key: 'no-exports',
    type: featureTypes.notIncluded,
  },
  {
    key: 'email-support-only',
    type: featureTypes.limited,
  },
  {
    key: 'no-pursuits-pipelines-tasks',
    type: featureTypes.notIncluded,
  },
  {
    key: 'no-access-federal-agents',
    type: featureTypes.notIncluded,
  },
  {
    key: 'only-1-user',
    type: featureTypes.limited,
  },
  {
    key: 'cant-feature-your-vendor-profile',
    type: featureTypes.notIncluded,
  },
];

const getIcon = (feature) => {
  switch (feature) {
    case featureTypes.notIncluded:
      return <IconCross style={{ color: '#888FA9' }} />;
    case featureTypes.limited:
      return <IconCheck style={{ color: '#888FA9' }} />;
  }
  return <IconCheck style={{ color: '#17B471' }} />;
};
const SingupSubscribe = ({}) => {
  const { trans } = useTrans();
  const classes = useStyles();
  const isOpen = true;

  return (
    <DrawerDialog open={isOpen} maxWidth="100%" transitionDuration={400} anchor="right">
      <Logo style={{ height: '65px', position: 'absolute', margin: '15px' }} />
      <div className={classes.container}>
        <Grid container spacing={2} className={classes.table}>
          <Grid item xs={12} className={classes.headers}>
            <Typography weight="700" variant="h1">
              {trans('get-full-access-usfcr-features')}
            </Typography>
            <Typography weight="500" variant="h3" gutter="bottomSpace" style={{ color: '#4E5978' }}>
              {trans('Subscribe annualy, cancel anytime')}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <SpacingContainer align="center" className={classes.banner}>
              <Grid item>
                <Grid container spacing={1} wrap={'nowrap'}>
                  <Grid item className={classes.bannerContent}>
                    <img src={premiumIcon} className={classes.imagePublic} />
                  </Grid>
                  <Grid item className={classes.bannerText}>
                    <Typography variant="caption" color="white" weight="500" align="center" fontFamily="lexend">
                      {trans('USFCR Premium plan ')}
                    </Typography>
                    <Typography variant="caption" color="white" align="center" weight="300">
                      {trans(' Become a Premium Member To Get Full Access')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </SpacingContainer>

            <Grid item className={classes.divider} align={'center'}>
              <Divider horizontal />
            </Grid>

            <SwitchPlan />

            <Grid item className={classes.divider} align={'center'}>
              <Divider horizontal />
            </Grid>

            {premiumFeatureKeys.map((feature, i) => (
              <Grid item key={i}>
                <Grid container spacing={1} wrap={'nowrap'}>
                  <Grid item>{getIcon(featureTypes.included)}</Grid>
                  <Grid item>
                    <Typography variant={'h6'}>{trans(feature)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={6} className={classes.item}>
            <Grid item className={classes.divider} align={'center'}>
              <Divider horizontal />
            </Grid>
            <Typography variant="h2" weight="600" fontFamily="lexend" className={classes.titleContact}>
              {trans('Contact information')}
            </Typography>
            <Grid item className={classes.contact}>
              <Grid item className={classes.infocontact}>
                <Typography variant="caption" weight="700" fontFamily="lexend">
                  {trans('Contact')}
                </Typography>
              </Grid>
              <Grid item className={classes.infocontact}>
                <Typography variant="caption" weight="500" fontFamily="lexend" style={{ color: '#888FA9' }}>
                  {trans('myemail@usfcr.com')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.help}>
              <Grid container spacing={1} wrap={'nowrap'}>
                <Grid item>
                  <HelpOutline />
                </Grid>
                <Grid item>
                  <Typography variant={'body2'}>
                    {trans('We will send you an invitation code for futher registration')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1} wrap={'nowrap'}>
                <Grid item style={{ width: '110px', height: '52px', margin: '10px' }}>
                  <Button to={ROUTE_DASHBOARD.path} variant="contained" className={classes.skip}>
                    {trans('Skip')}
                  </Button>
                </Grid>
                <Grid item style={{ width: '180px', height: '52px', margin: '10px' }}>
                  <Button to={ROUTE_CHECKOUT.path} variant="contained" color="primary" className={classes.getPremium}>
                    {trans('Get premium now!')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </DrawerDialog>
  );
};

export default connect(SingupSubscribe);
