import React from 'react';
import { useTrans } from '../../../../services/i18n';
import SpacingContainer from '../../../common/Containers/Base/SpacingContainer';
import { IconSearch } from '../../../svg/Icons/IconSearch';
import NothingFoundCard from './index';

export default function NothingFoundCardList({ title, description, Icon, py = 0, px = 0 }) {
  const { trans } = useTrans();

  return (
    <SpacingContainer py={py} px={px}>
      <NothingFoundCard
        Icon={Icon || <IconSearch notFound />}
        title={typeof title === 'string' ? title : trans('NothingFound')}
        description={typeof description === 'string' ? description : trans('PleaseTrySomethingElse')}
        size="large"
        py={10}
      />
    </SpacingContainer>
  );
}
