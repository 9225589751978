import { Button } from '@material-ui/core';
import React from 'react';
import { useTrans } from '../../../../../services/i18n';
import SaveSearchForm from '../../../../../components/forms/SaveSearchForm';
import { useSavedSearch } from '../hooks/useSavedSearch';

function SaveSearchEdit({ item }) {
  const { trans } = useTrans();

  const { itemSearch, open, onOpen, onClose, handleSave } = useSavedSearch(item);

  if (!itemSearch) {
    return <></>;
  }

  return (
    <>
      <Button color="primary" variant="contained" onClick={onOpen}>
        {trans('edit')}
      </Button>

      {itemSearch && open && (
        <SaveSearchForm item={itemSearch} open={open} onClose={onClose} onSaveSearch={handleSave} />
      )}
    </>
  );
}

export default SaveSearchEdit;
