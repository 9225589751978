import React from 'react';
import QRCode from 'qrcode.react';
import { Grid, CircularProgress } from '@material-ui/core';
import Typography from '../../../components/common/Typography';
import { CodeInput } from '../../../components/common/Inputs';
import { useTrans } from '../../../services/i18n';
import { Button } from '../../../components/common/Buttons';
import { AmplifyAuth } from '../../../services/amplifyAuth';
import { AUTH_TOTP } from '../../../constants';

const CODE_SIZE = 200;

function QRCodeHolder() {
  return (
    <div style={{ height: CODE_SIZE, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress color="inherit" />
    </div>
  );
}

const AuthenticatorAppFa = ({ qrCode, updateMFA, setSuccess }) => {
  const { trans } = useTrans();
  const [code, setCode] = React.useState('');
  const [pending, setPending] = React.useState(false);

  const handleSuccess = () => {
    setSuccess(true);
    updateMFA(AUTH_TOTP);
  };

  const onConfirm = React.useCallback(() => {
    setPending(true);
    AmplifyAuth.currentAuthenticatedUser()
      .then((user) => {
        return AmplifyAuth.verifyTotpToken(user, code)
          .then(() => AmplifyAuth.setPreferredMFA(user, AUTH_TOTP))
          .then(() => {
            handleSuccess();
          });
      })
      .finally(() => setPending(false))
      .catch(console.warn);
  }, [code]);

  return (
    <>
      <Grid container direction="column" alignItems="center">
        {qrCode ? <QRCode value={qrCode} size={CODE_SIZE} /> : <QRCodeHolder />}
        <Typography align="center" weight="600" gutter="verticalSpaceBig">
          {trans('scan-qr-code-to-enable-two-factor-authentication')}
        </Typography>
        <CodeInput type="number" onChange={setCode} />
        <Typography color="hint" align="center" weight="600" variant="body2" gutter="verticalSpaceBig">
          {trans('enter-code-displayed-in-application')}
        </Typography>
        <Button
          id="code-confirm"
          variant="contained"
          color="primary"
          size="large"
          onClick={onConfirm}
          disabled={code.length !== 6}
          pending={pending}
        >
          {trans('confirm')}
        </Button>
      </Grid>
    </>
  );
};

export default AuthenticatorAppFa;
