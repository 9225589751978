import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import Typography from './index';

const useStyles = makeStyles({
  normal: { marginLeft: 10, marginRight: 10 },
  small: { marginLeft: 7, marginRight: 7 },
});

export default function Bull({ className, variant, size = 'normal', ...rest }) {
  const styles = useStyles();

  return (
    <Typography className={clsx(className, styles[size])} variant={variant} color="hint" component="span" {...rest}>
      &bull;
    </Typography>
  );
}
