import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from '../../../../../hooks/useForm';
import { useTrans } from '../../../../../services/i18n';
import { Button } from '../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../components/common/Dialogs';
import { TextField } from '../../../../../components/common/Inputs';
import { getInitialValues, useScheme } from './helpers';
import styles from './styles.module.css';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import { Typography } from '@material-ui/core';
import { ReactComponent as NoVendorsIcon } from '../../icons/ico-no-vendors.svg';
import { Divider } from '../../../../../components/common/Divider';
import connect from '../../connect';
import VendorFound from '../VendorFound';

const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  validateOnBlur: false,
  enableReinitialize: true,
};

const AddVendorForm = ({ open, onClose, checkVendor, addVendor, getList, filters, openAddVendorManually }) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert } = useAlertContext();
  const [vendorExists, setVendorExists] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingAdd, setLoadingAdd] = useState(false);
  const [vmExists, setVmExist] = useState(null);
  const [busyBtn, setBusyBtn] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      onCheckVendor(values);
    },
    [onClose, checkVendor]
  );

  const { getFieldProps, handleSubmit, values, resetForm } = useForm(schema, getInitialValues(), onSubmit, formOptions);

  useEffect(() => {}, []);

  const closeAddVendorModal = () => {
    setVendor(null);
    setVendorExists(false);
    setVmExist(null);
    setBusyBtn(false);
    resetForm();
    onClose();
  };

  const onCheckVendor = (data) => {
    setLoading(true);
    return checkVendor(data).then((res) => {
      setLoading(false);
      setBusyBtn(true);
      if (res.payload.exists) {
        setVendorExists(true);
        setVendor(res.payload.vendor);
        setVmExist(res.payload.vm);
      }
      if (!res.payload.exists) {
        setVendorExists(false);
        setVendor(null);
        setVmExist(null);
      }
    });
  };

  const addToTheList = () => {
    setLoadingAdd(true);
    addVendor({
      vendorId: vendor.id,
      cageCode: vendor.cageCode,
      ueiCode: vendor.ueiCode,
      typeSave: 'Registered',
    })
      .then((res) => {
        if (res.payload) {
          if (res.payload.success) {
            closeAddVendorModal();
            successAlert('Vendor added');
            getList(filters);
          } else {
            errorAlert(res?.payload?.message);
          }
        } else {
          errorAlert('Error adding vendor');
        }
      })
      .finally(() => setLoadingAdd(false));
  };

  return (
    <ModalDialog
      open={open}
      onClose={() => closeAddVendorModal()}
      title={trans('add-registered-vendor')}
      actions={
        vendorExists &&
        vendor && (
          <div>
            <Button disabled={isLoadingAdd} variant="outlined" color="primary" onClick={() => closeAddVendorModal()}>
              {trans('cancel')}
            </Button>
            <Button
              disabled={isLoading || vmExists}
              pending={isLoadingAdd}
              style={{ marginLeft: '16px' }}
              variant="contained"
              color="success"
              onClick={addToTheList}
            >
              {trans('add-to-the-list')}
            </Button>
          </div>
        )
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">
            To get started, please enter one of the unique Vendor codes below to check if this Vendor registered in the
            system already.
          </Typography>
        </Grid>

        <Grid container style={{ padding: '0  12px 16px 12px' }}>
          <Grid item xs={10}>
            <TextField
              label={trans('unique-code')}
              placeholder={'UEI or CAGE'}
              required
              maxLength={80}
              {...getFieldProps('term')}
            />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'end', padding: '0 0 2px 10px' }}>
            <Button pending={isLoading} size="large" variant="outlined" color="primary" onClick={handleSubmit}>
              {trans('Check')}
            </Button>
          </Grid>

          {!isLoading && values.term && busyBtn && (
            <>
              {vendorExists && vendor && !vmExists && !isLoading ? (
                <VendorFound item={vendor} />
              ) : (
                <Grid
                  container
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '50px 16px',
                  }}
                >
                  <Divider light />
                  {!vendorExists && (
                    <>
                      <NoVendorsIcon />
                      <Typography style={{ width: '100%', textAlign: 'center', marginTop: '20px' }} variant="h5">
                        Vendor with code «{values.term}» is not registered
                      </Typography>
                      <Typography style={{ width: '100%', textAlign: 'center', color: '#888fa9' }} variant="h6">
                        You can add this Vendor manually or try another code
                      </Typography>

                      <div style={{ marginTop: '20px' }}>
                        <Button variant="contained" color="primary" onClick={() => openAddVendorManually(true)}>
                          {trans('add-manually')}
                        </Button>
                      </div>
                    </>
                  )}
                  {vendorExists && vmExists && (
                    <>
                      <NoVendorsIcon />
                      <Typography style={{ width: '100%', textAlign: 'center', marginTop: '20px' }} variant="h5">
                        Vendor with code «{values.term}» is already present in the management list.
                      </Typography>
                    </>
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

export default connect(AddVendorForm);
