import { intlDateFormat } from '../../utils/dateUtils';

export const V_TABS = [
  { id: 'summary', i18nKey: 'summary' },
  { id: 'contracts', i18nKey: 'contracts' },
  { id: 'teaming', i18nKey: 'teaming' },
  { id: 'notes', i18nKey: 'notes' },
];

export const V_MANAGEMENT_DETAILS_TABS = [
  { id: 'summary', i18nKey: 'summary' },
  { id: 'notes', i18nKey: 'notes' },
];

export const V__CONTRACT_TABS = [
  { id: 'funding', i18nKey: 'funding' },
  { id: 'awards', i18nKey: 'awards' },
];

export function getContractAwardId(item) {
  return item && [item.referencedIDVId, item.awardContractId].filter((v) => !!v).join('-');
}

export function nameTypeContact(typeContact) {
  let type = 'Unrecognized';
  if (['governmentBusinessPOC', 'governmentBusinessAlternatePOC'].includes(typeContact)) type = 'Government';
  if (['electronicBusinessPOC', 'electronicBusinessAlternatePOC'].includes(typeContact)) type = 'Electronic';
  if (['pastPerformancePOC', 'pastPerformanceAlternatePOC'].includes(typeContact)) type = 'Performance';

  return type;
}

export const generateLocationShort = (item) =>
  !item.city ? '-' : `${item.city}${item.country ? ', ' + item.country : ''}`;

export const generateLocation = (item) => {
  if (!item.city) return '';

  return [
    item.street || item.addressLine1,
    item.city,
    `${item.state || item.stateOrProvinceCode || ''} ${item.zipCode || ''}`.trim(),
    item.country || item.countryCode,
  ]
    .filter((val) => !!val)
    .join(', ');
};

export const orderOptions = [
  { name: 'RegistrationDate', value: 'registrationDate', asc: 'oldest-first', desc: 'newest-first' },
  { name: 'ActivationDate', value: 'activationDate', asc: 'oldest-first', desc: 'newest-first' },
  { name: 'relevance', value: 'relevance', desc: 'most' },
];

export const getRegistrationDate = (registrationDate) => {
  const now = new Date();
  const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

  return currentDate.toISOString() > registrationDate ? intlDateFormat(registrationDate) : null;
};

export const getVendorIdentifiers = (item) => {
  const { dunsCode, ueiCode, cageCode } = item;
  return [dunsCode, ueiCode, cageCode].filter((item) => !!item).join();
};
