import { useMemo, useState, useCallback } from 'react';
import { ROUTE_EXPIRATION, ROUTE_PASSWORD_SUCCESS } from '../../../constants';
import useExceptionsActions from '../../../hooks/dispatchers/useExceptionsAction';
import useGlobalSettings from '../../../hooks/dispatchers/useGlobalSettings';
import useQueryHistory from '../../../hooks/query/useQueryHistory';
import { AmplifyAuth } from '../../../services/amplifyAuth';
import { EXPIRED_CODE_EXCEPTION_FORGET_PASSWORD } from '../../../utils/exceptions';
import { catchYupErrors } from '../../../utils/validationUtils';

const useChangePassword = () => {
  const { setEmailExpiredD } = useGlobalSettings();
  const { history, query = {} } = useQueryHistory();
  const { setExceptionsD } = useExceptionsActions();
  const { email, code } = useMemo(
    () => ({ email: query.email.trim().replaceAll(' ', '+'), code: query.code }),
    [query]
  );
  const [changePasseword, setChangePassword] = useState({
    loading: false,
    error: '',
    validateCode: false,
  });

  const changePasswordProcess = useCallback(
    async (newPassword, { setStatus, setErrors }) => {
      setChangePassword((prev) => ({ ...prev, loading: true }));
      try {
        await AmplifyAuth.forgotPasswordSubmit(email, code, newPassword);
        setChangePassword((prev) => ({ ...prev, validateCode: true, loading: false }));
        history.push(ROUTE_PASSWORD_SUCCESS.path);
      } catch (error) {
        catchYupErrors(error, setErrors, (err) => setStatus({ message: err.message }));
        setChangePassword((prev) => ({ ...prev, validateCode: false, loading: false }));
        setEmailExpiredD(email);
        setExceptionsD({
          ...error,
          code: EXPIRED_CODE_EXCEPTION_FORGET_PASSWORD,
        });
        history.push(ROUTE_EXPIRATION.path);
      }
    },
    [email, code, history, setEmailExpiredD, setExceptionsD]
  );

  const handleSubmit = useCallback(
    (values, params) => {
      if (!(email && code)) return;
      changePasseword && changePasswordProcess(values.password, params);
    },
    [email, code, changePasseword, changePasswordProcess]
  );

  return { handleSubmit, pending: changePasseword.loading };
};

export default useChangePassword;
