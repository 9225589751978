import React from 'react';
import { useTeamingActions } from '../hooks/useTeamingActions';
import { useTrans } from '../../../../../services/i18n';
import { Button } from '@material-ui/core';

function TeamingActions({ item }) {
  const { collaborator, handleAccept, handleReject } = useTeamingActions(item);
  const { trans } = useTrans();

  if (collaborator) {
    collaborator.avoidDelete = true;
  }

  if (!collaborator || collaborator?.status === null || collaborator?.status === undefined) {
    return null;
  }

  return !item?.isOwner ? null : (
    <>
      {collaborator.status === 'not_reviewed' && (
        <>
          <Button onClick={handleAccept} color="primary" variant="contained">
            {trans('accept')}
          </Button>
          <Button onClick={handleReject} color="primary" variant="contained">
            {trans('reject')}
          </Button>
        </>
      )}
    </>
  );
}

export default TeamingActions;
