import { useFormik } from 'formik';

export const REINIT_VALUES = { enableReinitialize: false };

export function useForm(schema, initialState, onSubmit, formikOptions) {
  const formik = useFormik({
    validationSchema: schema,
    // initialErrors: initialState,
    initialValues: initialState,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit,
    ...formikOptions,
  });

  const getFieldProps = (key) => ({
    ...formik.getFieldProps(key),
    error: formik.errors && formik.errors[key],
  });

  return { ...formik, getFieldProps };
}
