import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';

export const INITIAL_STATE = {
  id: '',
  avatarId: '',
  avatarUrl: '',
  orgName: '',
  tagline: '',
  capabilityStatement: '',
  description: '',
  contactPhoneNumber: '',
  contactPerson: '',
  contactEmail: '',
  onPublicProfile: false,
  publicProfile: null,
  vendorClaimed: false,
  dunsVendorClaim: '',
  openRegisterOrgModal: false,
  openAddFeatureInfoOrgModal: false,
  openAddUpdateOrgModal: false,
  openPublicProfileOrgModal: false,
  vendor: null,
  subscription: null,
  revenueGoal: 0,
  manualRegistrationRecord: null,
  orgTeamingRecord: null,
};

export const setupAvatarAction = createAction('orgSettings/setupAvatar');

function onSetupAvatar(state, action) {
  return {
    ...state,
    avatarUrl: action.payload.avatar,
  };
}

export const setupAvatarIdAction = createAction('orgSettings/setupAvatarId');

function onSetupAvatarId(state, action) {
  return {
    ...state,
    avatarId: action.payload.avatarId,
    avatarUrl: action.payload.avatarUrl,
    profileImgStatus: action.payload.profileImgStatus,
  };
}

export const openRegisterOrg = createAction('orgSettings/openRegisterOrg');

function onOpenRegisterOrg(state, action) {
  return {
    ...state,
    openRegisterOrgModal: action.payload,
  };
}

export const openAddFeatureInfoOrg = createAction('orgSettings/openAddFeatureInfoOrg');

function onOpenAddFeatureInfoOrg(state, action) {
  return {
    ...state,
    openAddFeatureInfoOrgModal: action.payload,
  };
}

export const openAddUpdateOrg = createAction('orgSettings/openAddUpdateOrg');

function onOpenAddUpdateOrg(state, action) {
  return {
    ...state,
    openAddUpdateOrgModal: action.payload,
  };
}

export const openPublicProfileOrg = createAction('orgSettings/openPublicProfileOrg');

function onOpenPublicProfileOrg(state, action) {
  return {
    ...state,
    openPublicProfileOrgModal: action.payload,
  };
}

export const getUserRoleAction = createAsyncThunk('orgSettings/getUserRole', async (params) => {
  return application.call('user.getUserRole', params);
});

function onGetUserRole(state, action) {
  return {
    ...state,
    userRole: action.payload.role,
  };
}

export const setupSettingsAction = createAsyncThunk('orgSettings/setupSettings', async (params) => {
  return application.call('organization.addOrganization', params);
});

function onSetupSettings(state, action) {
  return {
    ...state,
    avatarId: action.payload.avatarId,
    orgName: action.payload.orgName,
    capabilityStatement: action.payload.capabilityStatement,
    description: action.payload.description,
    contactEmail: action.payload.contactEmail,
    contactPhoneNumber: action.payload.contactPhoneNumber,
    contactPerson: action.payload.contactPerson,
    tagline: action.payload.tagline,
    revenueGoal: action.payload.revenueGoal,
  };
}

export const editSettingsAction = createAsyncThunk('orgSettings/editSettings', async (params) => {
  return application.call('organization.editOrganization', params);
});

function onEditSettings(state, action) {
  return {
    ...state,
    avatarId: action.payload.avatarId,
    avatarUrl: action.payload.avatarUrl,
    orgName: action.payload.orgName,
    capabilityStatement: action.payload.capabilityStatement,
    description: action.payload.description,
    contactEmail: action.payload.contactEmail,
    contactPhoneNumber: action.payload.contactPhoneNumber,
    contactPerson: action.payload.contactPerson,
    tagline: action.payload.tagline,
  };
}

export const updateFeatureInfoAction = createAsyncThunk('orgSettings/updateFeatureInfo', async (params) => {
  return application.call('organization.updateFeatureInfo', params);
});

function onUpdateFeatureInfo(state, action) {
  return {
    ...state,
    tagline: action.payload.tagline,
    capabilityStatement: action.payload.capabilityStatement,
  };
}

const setOrganizationInfo = createAction('orgSettings/setOrganizationInfo');
function onSetOrganizationInfo(state, action) {
  return Object.assign(state, {
    ...action.payload.orgInfo,
    vendorClaimed: action.payload.vendorClaim,
    dunsVendorClaim: action.payload.dunsVendorClaim,
    publicProfile: action.payload.publicProfile,
    manualRegistrationRecord: action.payload.manualRegistrationRecord,
    orgTeamingRecord: action.payload.orgTeamingRecord,
  });
}

const getOrganizationInfoAction = createAsyncThunk('orgSettings/getOrganizationInfo', (params) => {
  return application.call('organization.getOrganizationInfo', params);
});
function onGetOrganizationInfo(state, action) {
  Object.assign(state, {
    ...action.payload.orgInfo,
    vendorClaimed: action.payload.vendorClaim,
    dunsVendorClaim: action.payload.dunsVendorClaim,
    publicProfile: action.payload.publicProfile,
    manualRegistrationRecord: action.payload.manualRegistrationRecord,
    orgTeamingRecord: action.payload.orgTeamingRecord,
  });
}

const getPermissions = createAsyncThunk('orgSettings/getPermissions', async (params) => {
  return application.call(API.ORGANIZATION.GET_PERMISSIONS, params);
});
function onGetPermissions(state, action) {
  return {
    ...state,
    permissions: action.payload.permissions,
    components: action.payload.components,
  };
}

export const updateCurrentOrgInfo = createAction('orgSettings/updateCurrentOrgInfo');

function onCurrentOrgInfo(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

const setPublicProfile = createAsyncThunk('orgSettings/setPublicProfile', async (params) => {
  return application.call(API.ORGANIZATION.SET_PUBLIC_PROFILE, params);
});
function onSetPublicProfile(state, action) {
  Object.assign(state, {
    publicProfile: action.payload.data,
  });
}

const getPublicProfile = createAsyncThunk('orgSettings/getPublicProfile', async (params) => {
  return application.call(API.ORGANIZATION.GET_PUBLIC_PROFILE, params);
});
function onGetPublicProfile(state, action) {
  Object.assign(state, {
    publicProfile: action.payload,
  });
}

const onChangePublicProfile = createAsyncThunk('orgSettings/changePublicProfile', (params) => {
  return application.call(API.ORGANIZATION.TOGGLE_PUBLIC_PROFILE, { toogle: params });
});
function setChangePublicProfile(state, action) {
  Object.assign(state, {
    onPublicProfile: action.payload,
  });
}

export const checkOrganization = createAsyncThunk('orgSettings/checkOrganization', async (params) => {
  return application.call(API.ORGANIZATION.CHECK_ORGANIZATION, params);
});
function onCheckOrganization(state, action) {
  return {
    ...state,
  };
}

//Claim vendor or register organization
const registerOrg = createAsyncThunk('orgSettings/registerOrg', async (params) => {
  return application.call(API.ORGANIZATION.REGISTER_ORG, params);
});
function onRegisterOrg(state, action) {
  Object.assign(state, {
    vendorClaimed: action.payload.vendorClaimed,
    vendor: action.payload.vendor,
  });
}

const setCreateSubscriptionAction = createAction('orgSettings/setCreateSubscription');
function onSetCreateSubscription(state, action) {
  return Object.assign(state, {
    subscription: action.payload,
  });
}

const setCancelSubscriptionAction = createAction('orgSettings/setCancelSusbcription');
function onCancelSusbcription(state, action) {
  return Object.assign(state, {
    subscription: action.payload,
  });
}

const setSeats = createAsyncThunk('orgSettings/setSeats', async (params) => {
  return application.call(API.ORGANIZATION.SET_SEATS, params);
});
function onSetSeats(state, action) {
  if (action.payload) {
    Object.assign(state, {
      seats: action.payload.licenses,
    });
  }
}

export const updateInfoOrg = createAction('auth/updateInfoOrg');
function onUpdateInfoOrg(state, action) {
  return Object.assign(state, {
    orgName: action.payload.orgName,
  });
}

export const getRevenueGoal = createAsyncThunk('orgSettings/getRevenueGoal', async (params) => {
  return application.call(API.ORGANIZATION.GET_REVENUE_GOAL, params);
});
export const setRevenueGoal = createAsyncThunk('orgSettings/setRevenueGoal', async (params) => {
  return application.call(API.ORGANIZATION.SET_REVENUE_GOAL, params);
});
function onRevenueGoal(state, action) {
  return Object.assign(state, {
    revenueGoal: action.payload.revenueGoal,
  });
}

export const orgActions = {
  setOrganizationInfo,
  setupSettingsAction,
  editSettingsAction,
  getOrganizationInfoAction,
  getUserRoleAction,
  setupAvatarAction,
  setupAvatarIdAction,
  updateCurrentOrgInfo,
  setPublicProfile,
  getPublicProfile,
  onChangePublicProfile,
  openRegisterOrg,
  checkOrganization,
  openAddFeatureInfoOrg,
  registerOrg,
  openAddUpdateOrg,
  openPublicProfileOrg,
  updateFeatureInfoAction,
  setCreateSubscriptionAction,
  setCancelSubscriptionAction,
  setSeats,
  updateInfoOrg,
  setRevenueGoal,
  getPermissions,
};

export const orgSettingsReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(setOrganizationInfo, onSetOrganizationInfo)
    .addCase(setupSettingsAction.fulfilled, onSetupSettings)
    .addCase(editSettingsAction.fulfilled, onEditSettings)
    .addCase(getUserRoleAction.fulfilled, onGetUserRole)
    .addCase(getOrganizationInfoAction.fulfilled, onGetOrganizationInfo)
    .addCase(setupAvatarAction, onSetupAvatar)
    .addCase(updateCurrentOrgInfo, onCurrentOrgInfo)
    .addCase(setupAvatarIdAction, onSetupAvatarId)
    .addCase(openRegisterOrg, onOpenRegisterOrg)
    .addCase(registerOrg.fulfilled, onRegisterOrg)
    .addCase(openAddFeatureInfoOrg, onOpenAddFeatureInfoOrg)
    .addCase(openAddUpdateOrg, onOpenAddUpdateOrg)
    .addCase(openPublicProfileOrg, onOpenPublicProfileOrg)
    .addCase(checkOrganization.fulfilled, onCheckOrganization)
    .addCase(updateFeatureInfoAction.fulfilled, onUpdateFeatureInfo)
    .addCase(setPublicProfile.fulfilled, onSetPublicProfile)
    .addCase(getPublicProfile.fulfilled, onGetPublicProfile)
    .addCase(onChangePublicProfile.fulfilled, setChangePublicProfile)
    .addCase(setCreateSubscriptionAction, onSetCreateSubscription)
    .addCase(setCancelSubscriptionAction, onCancelSusbcription)
    .addCase(setSeats.fulfilled, onSetSeats)
    .addCase(updateInfoOrg, onUpdateInfoOrg)
    .addCase(getRevenueGoal.fulfilled, onRevenueGoal)
    .addCase(setRevenueGoal.fulfilled, onRevenueGoal)
    .addCase(getPermissions.fulfilled, onGetPermissions);
});
