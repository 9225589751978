import React from 'react';
import { useHistory } from 'react-router-dom';
import useBooleanHandlers from '../../../../../../../../hooks/useBooleanHandlers';
import { useTrans } from '../../../../../../../../services/i18n';
import SimpleSearchField from '../../../../../../../../components/generics/SearchTopPanel/SearchField/SimpleSearchField';
import connect from '../../../../../connect';

const Search = ({ textSearch, onClearSearch, onChangeText, getList, filters, changeFilters }) => {
  const { trans } = useTrans();
  const { push, location } = useHistory();
  const [focused, onFocus, onBlur] = useBooleanHandlers();

  const handleChange = React.useCallback(
    (value) => {
      if (!value) onClearSearch('');
      onChangeText && onChangeText(value);
      onBlur();
      const copyFilters = { ...filters };
      copyFilters.text = value;
      changeFilters(copyFilters);
      getList(copyFilters);
    },
    [location.pathname, onChangeText, push, onBlur]
  );

  return (
    <SimpleSearchField
      value={textSearch}
      onValueChange={handleChange}
      name="ezSearch"
      size="medium"
      bgColor="default"
      placeholder={trans('search')}
      freeSolo
      onFocus={onFocus}
    />
  );
};

export default connect(Search);
