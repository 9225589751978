import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DrawerDialog } from '../../../components/common/Dialogs/DrawerDialog';
import { QUERY_PIPELINE } from '../../../constants/queries';
import { useQuery } from '../../../hooks/query/useQuery';
import PipelineDetails from './PipelineDetails';
import { useDispatch, useSelector } from 'react-redux';
import { actions as pipelinesDetailsActions } from '../../../store/pipelinesDetails';

export default function PipelineDetailsWrapper() {
  const { query } = useQuery();
  const pipelineId = query[QUERY_PIPELINE];

  const dispatch = useDispatch();
  const show = useSelector((s) => s.pipelineDetails.show);
  React.useLayoutEffect(() => {
    dispatch(pipelinesDetailsActions.show(!!pipelineId));
  }, [pipelineId]);

  return (
    <DrawerDialog open={show} maxWidth="100%" transitionDuration={300} anchor="bottom">
      <DndProvider backend={HTML5Backend}>
        <PipelineDetails pipelineId={pipelineId} />
      </DndProvider>
    </DrawerDialog>
  );
}
