import React from 'react';
import Typography from '../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import AuthLayout from '../../../components/layouts/AuthLayout';
import expirationImage from '../../../components/assests/ico-expired.png';
import { Button } from '../../../components/common/Buttons';
import Loader from '../../../components/common/Loader';
import { useExpiration } from './useExpiration';

const Expiration = () => {
  const { classes, pending, propsComponent } = useExpiration();
  return (
    <>
      {pending && <Loader open={true} />}
      <AuthLayout urlBackground={propsComponent?.urlBackground}>
        <div className={classes.formBack}>
          <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
            <div className={classes.centeredBlockInfoState}>
              <Grid item xs={12} className={classes.signForm}>
                <img src={expirationImage} alt="expiration" className={classes.authStateImage} />
                <Typography variant="h1Medium" fontFamily="lexend" weight="500" className={classes.verificationTitle}>
                  {propsComponent.title}
                </Typography>
                {propsComponent.textButton && (
                  <Grid item xs={12}>
                    <Button
                      id="re-send-btn"
                      color="primary"
                      variant="contained"
                      className={classes.buttonResend}
                      onClick={propsComponent.onClick}
                    >
                      {propsComponent.textButton}
                    </Button>
                  </Grid>
                )}
                <Typography component="p" weight="500" variant="body1" className={classes.email}>
                  {propsComponent.contact[0]} <a href="mailto:appsupport@usfcr.com">appsupport@usfcr.com</a>{' '}
                  {propsComponent.contact[1]}
                </Typography>
              </Grid>
            </div>
          </Grid>
        </div>
      </AuthLayout>
    </>
  );
};

export default Expiration;
