import { useEffect, useState } from 'react';
import Typography from '../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from '../styles';
import { useTrans } from '../../../services/i18n';
import AuthLayout from '../../../components/layouts/AuthLayout';
import { Button } from '../../../components/common/Buttons';
import { useApplication } from '../../../services/application';
import { useLocation } from 'react-router';
import qs from 'query-string';

const UnsubscribeEmails = () => {
  const classes = useStyles();
  const { trans } = useTrans();
  const application = useApplication();
  const [pending, setPending] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [successfully, setSuccesfully] = useState(null);
  const { search } = useLocation();

  const handleConfirm = async ({ confirm }) => {
    if (confirm) setPending(!pending);

    const { u: userId } = qs.parse(search);

    if (!userId) {
      setDisabled(true);
      return false;
    }

    return application
      .call('user.unsubscribeEmails', {
        confirm,
        userId,
      })
      .then(() => {
        if (confirm) setSuccesfully('Your email notifications settings have been updated successfully.');
      })
      .catch(() => {
        if (!confirm) setDisabled(true);
      })
      .finally(() => {
        if (confirm) setPending(false);
      });
  };

  useEffect(() => {
    handleConfirm({ confirm: false });
  }, []);

  return (
    <AuthLayout>
      <div className={classes.formBack}>
        <Grid container spacing={0} justifyContent="center" className={classes.content} alignItems="center">
          <Grid item xs={12} className={classes.signForm}>
            {!successfully ? (
              <>
                <Typography component="p" weight="500" variant="body1" gutter="bottom2">
                  By clicking confirm, you are choosing to no longer receive incoming emails for Saved Searches and
                  Pursuits notifications.
                </Typography>
                <Typography component="p" weight="500" variant="body1" className={classes.email}>
                  You can always update your email notification settings from the My Profile section on your USFCR
                  account.
                </Typography>
              </>
            ) : (
              <Typography component="p" weight="500" variant="body1" className={classes.email}>
                Your email notifications settings have been updated successfully.
              </Typography>
            )}
            {!successfully && (
              <Grid item xs={4}>
                <Button
                  id="sign-in-btn"
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  pending={pending}
                  disabled={disabled}
                  onClick={() => handleConfirm({ confirm: true })}
                >
                  {trans('confirm')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
};

export default UnsubscribeEmails;
