import useStyles from './styles';
import ModalDialog from '../../Dialogs/ModalDialog';
import { useTrans } from '../../../../services/i18n';
import { ROUTE_PLANS_PRIVATE } from '../../../../constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import connect from '../connect';
import Header from './Header';
import Footer from './Footer';
import { ReactComponent as SubscribePlan } from '../../../assests/subscribe-illustration.svg';
import Typography from '../../Typography';
import { Box } from '@material-ui/core';
import { IconCheck } from '../../../svg/Icons/IconCheck';
import { STANDARD_PLANS_INFORMATION, TIERS } from '../../../../utils/subscription';

const UpgradeSubscriptionModal = ({ open, fixed = false, onClose, currentProduct, plans }) => {
  const history = useHistory();
  const classes = useStyles();
  const { trans } = useTrans();
  const benefits = STANDARD_PLANS_INFORMATION.find((p) => p.subscriptionTier === TIERS.LIMITED).benefits;

  const goToPlans = () => {
    onClose();
    history.push(ROUTE_PLANS_PRIVATE.path);
  };

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      DialogTitle={<Header onClose={onClose} />}
      actions={<Footer onViewClick={goToPlans} />}
      justifyActionsContent={'center'}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <SubscribePlan width={400} />

        <Typography variant="p" className={classes.mt}>
          You are now on the <strong>Limited Plan</strong> and have access to:
        </Typography>

        <Box display="flex" justifyContent="center" alignItems="center" className={classes.mt}>
          <div>
            {benefits.map((b, index) => (
              <Box display="block" style={{ marginBottom: '.25rem' }}>
                <Typography variant={'body2'}>
                  <IconCheck style={{ fill: '#17B471' }} />
                  {trans(b.title)}
                </Typography>
                <ul style={{ margin: 0 }}>
                  {b?.subs?.map((sub, index) => (
                    <li key={index}>
                      <Typography variant="body2">{sub}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            ))}
          </div>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default connect(UpgradeSubscriptionModal);
