import { createAction } from '@reduxjs/toolkit';
import { emptyArray } from '../../../utils/arrayUtils';

const filters = {
  filterUnread: (item) => !item.read,
  filterRead: (item) => !!item.read,
};

export function generateCheckItemsActions({ scope }) {
  if (!scope) return {};

  return {
    checkItem: createAction(`${scope}/checkItem`),
    checkAllItems: createAction(`${scope}/checkAllItems`),
    removeAllItems: createAction(`${scope}/removeAllItems`),
  };
}

export function generateCheckItemsCallbacks(dispatch, actions) {
  const { checkItem, checkAllItems, removeAllItems } = actions || {};

  const onCheckItem = (item) => checkItem && dispatch(checkItem({ item }));
  const onCheckAll = () => checkAllItems && dispatch(checkAllItems());
  const onRemoveAllChecked = () => removeAllItems && dispatch(removeAllItems());

  return { onCheckItem, onCheckAll, onRemoveAllChecked };
}

export function extendBuilderWithCheckItemsActions(builder, actions, getStore) {
  const { checkItem, checkAllItems, removeAllItems } = actions;
  const getStoreValues = getStore ? getStore : (state) => state;

  if (checkItem) {
    builder.addCase(checkItem, (state, action) => {
      const store = getStoreValues(state);
      const { item } = action.payload;
      const index = store.checkedItems.findIndex((checkedId) => checkedId === item.id);

      if (index >= 0) {
        store.checkedItems.splice(index, 1);
      } else {
        store.checkedItems.push(item.id);
      }
    });
  }

  if (checkAllItems) {
    builder.addCase(checkAllItems, (state, { payload }) => {
      const filterCB = payload && payload.filterCB;
      const store = getStoreValues(state);
      if (emptyArray(store.items)) return;

      const items =
        filterCB && typeof filters[filterCB] === 'function' ? store.items.filter(filters[filterCB]) : store.items;

      store.checkedItems = items.reduce(
        (acc, item) => {
          if (!acc.includes(item.id)) acc.push(item.id);
          return acc;
        },
        Array.isArray(store.checkedItems) ? store.checkedItems : []
      );
    });
  }

  if (removeAllItems) {
    builder.addCase(removeAllItems, (state, _action) => {
      const store = getStoreValues(state);
      const items = store.items;
      if (emptyArray(store.items) || emptyArray(store.checkedItems)) return;

      items.forEach((item) => {
        const index = store.checkedItems.findIndex((checkedId) => checkedId === item.id);
        if (index >= 0) store.checkedItems.splice(index, 1);
      });
    });
  }

  return builder;
}
