import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTrans } from '../../../services/i18n';
import { Paper, SpacingContainer } from '../../common/Containers';
import { Divider } from '../../common/Divider';
import Loader from '../../common/Loader';
import Typography from '../../common/Typography';

const titleStyles = { minHeight: 55 };

export default function DetailsContentBlock({
  children,
  title,
  actions,
  subTitle = null,
  pending = false,
  disableDivider = false,
  px = '5sm3',
}) {
  const { trans } = useTrans();

  return (
    <Paper className="relative">
      {pending && <Loader open={pending} />}

      <SpacingContainer px={px} py={subTitle ? 3 : 2}>
        <Grid container spacing={1} alignItems="center" justifyContent="space-between" style={titleStyles}>
          <Grid item>
            <Typography fontFamily="lexend" variant="h5" weight="500">
              {title}
            </Typography>
            {subTitle}
          </Grid>
          {actions && <Grid item>{actions}</Grid>}
        </Grid>
      </SpacingContainer>

      {!disableDivider && <Divider light />}

      {children ? (
        children
      ) : (
        <SpacingContainer px="5sm3" py={3}>
          <Typography color="hint">{trans('not-listed')}</Typography>
        </SpacingContainer>
      )}
    </Paper>
  );
}
