import { Button } from '@material-ui/core';
import React from 'react';
import AddPublicOpportunityForm from '../../../../../containers/Pipelines/PursuitDetails/Management/Teaming/components/AddPublicOpportunityForm';
import AddInternalOpportunityForm from '../../../../../containers/Pipelines/PursuitDetails/Management/Teaming/components/AddInternalOpportunityForm';
import { useTrans } from '../../../../../services/i18n';
import { useTeamingEdit } from '../hooks/useTeamingEdit';

function TeamingEdit({ item }) {
  const { trans } = useTrans();

  const { open, onOpen, onClose } = useTeamingEdit(item);

  return !item?.pursuit || !item.isOwner ? null : (
    <>
      <Button color="primary" variant="contained" onClick={onOpen}>
        {trans('edit')}
      </Button>

      {item.type === 'e' && (
        <AddPublicOpportunityForm open={open} onClose={onClose} pursuit={item.pursuit} item={item} />
      )}
      {item.type === 'i' && (
        <AddInternalOpportunityForm open={open} onClose={onClose} pursuit={item.pursuit} item={item} />
      )}
    </>
  );
}

export default TeamingEdit;
