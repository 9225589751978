import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconMenu = (props) => {
  const { width = 24, height = 24, open } = props;

  return open ? (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M6.077 18h11.846c.592 0 1.077-.45 1.077-1s-.485-1-1.077-1H6.077C5.485 16 5 16.45 5 17s.485 1 1.077 1zm.323-5h11.2c.77 0 1.4-.45 1.4-1s-.63-1-1.4-1H6.4c-.77 0-1.4.45-1.4 1s.63 1 1.4 1zM5 7c0 .55.485 1 1.077 1h11.846C18.515 8 19 7.55 19 7s-.485-1-1.077-1H6.077C5.485 6 5 6.45 5 7z"
        transform="translate(-338 -28) translate(332 20) translate(6 8)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M8.613 7.21l.094.083L12 10.585l3.293-3.292c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094L13.415 12l3.292 3.293c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083L12 13.415l-3.293 3.292c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L10.585 12 7.293 8.707c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083z"
        transform="translate(-1020 -224) translate(370 198) translate(650 26)"
      />
    </SvgIcon>
  );
};

IconMenu.propTypes = simpleIconPropsTypes;
