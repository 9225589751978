import { TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import Typography from '../../../../components/common/Typography';
import { useTrans } from '../../../../services/i18n';
import { formatPrettyUSD } from '../../../../utils/numberUtils';

function AgenciesTable({ statistics }) {
  const { trans } = useTrans();
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Typography variant="body2" weight="800">
            {trans('agency')}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" weight="800">
            {trans('amount-won')}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" weight="800">
            {trans('amount-lost')}
          </Typography>
        </TableCell>
      </TableRow>
      <TableBody>
        {statistics?.agencies.map((agency, index) => (
          <TableRow>
            <TableCell align="left">
              <Typography variant="body2" color="hint">
                {agency.name}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" color="hint">
                {formatPrettyUSD(agency.amountWon)}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" color="hint">
                {formatPrettyUSD(agency.amountLost)}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

export default AgenciesTable;
