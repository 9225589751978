import { createReducer, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { application } from '../../services/application';
import { API } from '../../constants';
import { config } from '../../services/api';

const INITIAL_STATE = {
  forms: [],
  formDetail: null,
  organizationForms: [],
  currentFormType: localStorage.getItem('formType') ? localStorage.getItem('formType') : 'principal',
};

export const formSubmission = createAsyncThunk('clientPortal/formSubmission', async (params) => {
  return application.call(API.CLIENT_PORTAL.FORM_SUBMISSION, params);
});

function onFormSubmission(state, action) {
  return {
    ...state,
  };
}

export const formPartialSubmission = createAsyncThunk('clientPortal/formPartialSubmission', async (params) => {
  return application.call(API.CLIENT_PORTAL.FORM_PARTIAL_SUBMISSION, params);
});

function onFormPartialSubmission(state, action) {
  return {
    ...state,
  };
}

export const getFormStackById = createAsyncThunk('clientPortal/getFormStackById', async (params) => {
  return application.call(API.CLIENT_PORTAL.GET_FORMSTACK_BY_ID, params);
});

function onGetFormStackById(state, action) {
  return {
    ...state,
    formDetail: action.payload.form,
  };
}

export const getOrganizationForms = createAsyncThunk('clientPortal/getOrganizationForms', async (params) => {
  return application.call(API.CLIENT_PORTAL.GET_ORGANIZATION_FORMS, params);
});

function onGetOrganizationForms(state, action) {
  return {
    ...state,
    organizationForms: action.payload.forms,
  };
}

export const changeCurrentFormType = createAction('clientPortal/changeCurrentFormType');
function onChangeCurrentFormType(state, action) {
  localStorage.setItem('formType', action.payload);
  return Object.assign(state, {
    currentFormType: action.payload,
  });
}

export const uploadFilesForm = createAsyncThunk('clientPortal/uploadFilesForm', (params) => {
  return fetch(`${config.apiHost}/upload-files-form`, {
    method: 'POST',
    body: params,
  });
});

export const clientPortalActions = {
  getFormStackById,
  formSubmission,
  getOrganizationForms,
  formPartialSubmission,
  uploadFilesForm,
};

export const clientPortal = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getFormStackById.fulfilled, onGetFormStackById)
    .addCase(formPartialSubmission.fulfilled, onFormPartialSubmission)
    .addCase(formSubmission.fulfilled, onFormSubmission)
    .addCase(getOrganizationForms.fulfilled, onGetOrganizationForms)
    .addCase(changeCurrentFormType, onChangeCurrentFormType)
    .addCase(uploadFilesForm.fulfilled, (state, action) => {});
});

export default clientPortal;
