import React, { useMemo } from 'react';
import { CheckBox } from '../../../../../common/Inputs';
import { FormControlLabel, alpha } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import Typography from '../../../../../common/Typography';

function CustomFormControl({ label, onChange, checked, color = 'primary' }) {
  const theme = useTheme();

  const themeColor = useMemo(() => {
    return theme.palette[color].main;
  }, [theme, color]);

  const style = {
    backgroundColor: checked ? alpha(themeColor, 0.1) : 'transparent',
    padding: '.5rem .75rem',
    borderRadius: '0.75rem',
    border: `1px solid ${checked ? 'transparent' : alpha(themeColor, 0.1)}`,
    gap: '0.25rem',
    transition: 'all 150ms ease-in-out',
    userSelect: 'none',
  };

  return (
    <FormControlLabel
      style={style}
      control={
        <CheckBox
          icon={<RadioButtonUnchecked htmlColor={themeColor} style={{ width: '1.2rem' }} />}
          checkedIcon={<RadioButtonChecked htmlColor={themeColor} style={{ width: '1.2rem' }} />}
          checked={checked}
          onChange={onChange}
          dense
          color={color}
        />
      }
      label={
        <Typography weight="500" variant="body2" color="label">
          {label}
        </Typography>
      }
    />
  );
}

export default CustomFormControl;
