import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import ListItemTextBase from '../../../../components/common/Containers/Base/ListItemTextBase';
import { ContextMenuListItem, Popover } from '../../../../components/common/Dialogs/Popover';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import usePopover from '../../../../hooks/usePopover';
import { useTrans } from '../../../../services/i18n';
import connect from '../../connect';
import UserModal from '../UserModal';
import { useComponentPermission } from '../../../../hooks/useComponentPermission';
import { COMPONENT_FEATURES } from '../../../../utils/constants';

function UsersActions({ user, deleteUser, reSendInvite, enableDisableUser, authUser, getOrganizationInfo }) {
  const { trans } = useTrans();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const [open, openModal, onClose] = useBooleanHandlers();
  const { hasPermission, getPermissions } = useComponentPermission(COMPONENT_FEATURES.USERS);

  const onDeleteUser = React.useCallback(() => {
    deleteUser &&
      deleteUser(user).then(() => {
        getPermissions();
        getOrganizationInfo();
      });
  }, [deleteUser, user, getPermissions, getOrganizationInfo]);

  const onEnableDisableUser = React.useCallback(() => {
    if (user.disabled && !hasPermission) return;

    if (enableDisableUser) {
      enableDisableUser({
        id: user.id,
        organizationId: user.organizationId,
        disabled: user.disabled ? false : true,
      }).then(() => {
        getPermissions();
        getOrganizationInfo();
      });
    }
  }, [enableDisableUser, user, getPermissions, hasPermission, getOrganizationInfo]);

  return !user ? null : (
    <>
      {user && !user.owner && authUser.userName !== user.userId && (
        <IconButton variant={'contained'} size="small" onClick={onOpenPopover}>
          <MoreHorizIcon />
        </IconButton>
      )}

      <Popover id={'id-users-actions'} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        <ContextMenuListItem button onClick={openModal} onClickCB={onClosePopover}>
          <ListItemTextBase>{trans('edit')}</ListItemTextBase>
        </ContextMenuListItem>

        <ContextMenuListItem
          button
          onClick={onEnableDisableUser}
          onClickCB={onClosePopover}
          disabled={user.disabled && !hasPermission}
        >
          <ListItemTextBase>
            {user.disabled ? trans('enable-user-organization') : trans('disable-user-organization')}
          </ListItemTextBase>
        </ContextMenuListItem>

        <ContextMenuListItem button onClick={onDeleteUser} onClickCB={onClosePopover}>
          <ListItemTextBase>{trans('delete')}</ListItemTextBase>
        </ContextMenuListItem>
      </Popover>

      <UserModal open={open} onClose={onClose} user={user} />
    </>
  );
}

export default connect(UsersActions);
