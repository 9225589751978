import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { SCOPE_USER } from '../../constants';
import { USERS } from '../../constants/api';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE } from '../helpers/common';
import { extendBuilderWithListActions, generateListActions } from '../helpers/listActionsHelpers';
import { onPending, onReject, onSuccess } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE,
  order: ['updatedAt', 'desc'],
};

const listActions = generateListActions({
  scope: SCOPE_USER,
  apiMethod: {
    GET_LIST: USERS.GET_ORGANIZATIONS,
  },
  getStore: (store) => store.listUserOrganizations,
});

export const actions = {
  ...listActions,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendBuilderWithListActions(builder, actions);
});
