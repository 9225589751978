import Grid from '@material-ui/core/Grid';
import { Button } from '../../../../../../components/common/Buttons';
import React from 'react';
import * as Yup from 'yup';
import ModalDialog from '../../../../../../components/common/Dialogs/ModalDialog';
import { TextField } from '../../../../../../components/common/Inputs';
import makeValidationSchema from '../../../../../../hooks/makeValidationSchema';
import { useForm } from '../../../../../../hooks/useForm';
import { useTrans } from '../../../../../../services/i18n';
import { schemaToInitialState } from '../../../../../../utils/validationUtils';
import connect from '../../connect';
import { useAlertContext } from '../../../../../../contexts/AlertContext';
import PhoneInput from 'react-phone-number-input/input';

import makeStyles from '@material-ui/core/styles/makeStyles';
import 'react-phone-number-input/style.css';

const useScheme = makeValidationSchema((trans) => {
  return Yup.object().shape({
    name: Yup.string().required(trans('empty-not-allowed', { field: trans('name') })),
    familyName: Yup.string().required(trans('empty-not-allowed', { field: trans('lastName') })),
    email: Yup.string().email('Invalid email'),
    role: Yup.string(),
    jobTitle: Yup.string(),
    phone: Yup.string(),
  });
});

const useStyles = makeStyles((theme) => ({
  labelDropdowns: {
    fontFamily: 'Lexend',
    marginBottom: '8px',
    color: '#4e5978',
    fontWeight: 500,
  },
  phoneInput: {
    fontWeight: 'normal',
    background: theme.palette.background.input,
    color: theme.palette.common.black,
    borderRadius: '3px',
    height: '1.4em',
    padding: '1.7rem',
    border: 'none',
    width: '100%',
    '&::placeholder': { color: theme.palette.gray.active, opacity: 1 },
  },
}));

function MemberModal({
  addVendorMember,
  updateVendorMember,
  member,
  onClose,
  open = false,
  vendorInfo,
  memberPending,
}) {
  const { trans } = useTrans();
  const schema = useScheme();
  const [loading, setLoading] = React.useState(false);
  const { successAlert, errorAlert } = useAlertContext();

  const styles = useStyles();

  /***
   *
   */
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const resp = await addVendorMember({
        ...values,
        vendorManagementId: vendorInfo.id,
      });
      if (resp?.error) {
        errorAlert('Error creating Member');
      } else {
        resetForm();
        successAlert('Success!');
        typeof onClose === 'function' && onClose();
      }
      setLoading(false);
    } catch (error) {
      errorAlert('Error creating Member');
      setLoading(false);
      console.error(error);
    }
  };

  /***
   *
   */
  const onEdit = React.useCallback(
    async (values) => {
      try {
        setLoading(true);
        const resp = await updateVendorMember({
          ...values,
          vendorManagementId: vendorInfo.id,
          id: member.id,
        });
        if (resp?.error) {
          errorAlert('Error updating Member');
        } else {
          successAlert('Success!');
          typeof onClose === 'function' && onClose();
        }
        setLoading(false);
      } catch (error) {
        errorAlert('Error updating Member');
        setLoading(false);
        console.error(error);
      }
    },
    [updateVendorMember, onClose, member, successAlert]
  );

  const { handleSubmit, resetForm, getFieldProps, setFieldValue, isValid, setValues } = useForm(
    schema,
    member || schemaToInitialState(schema),
    member ? onEdit : onSubmit,
    {
      validateOnChange: true,
      validateOnBlur: true,
    }
  );

  /***
   *
   */
  const handleCancel = React.useCallback(() => {
    resetForm();
    typeof onClose === 'function' && onClose();
  }, [onClose, resetForm]);

  /***
   *
   */
  React.useEffect(() => {
    if (member) {
      const { id, created_at, updated_at, ...data } = member;
      setValues(data);
    }
    return () => {
      resetForm();
    };
  }, [member, resetForm, setValues]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans(member ? 'edit-org-member' : 'add-org-member')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={memberPending}
              onClick={handleCancel}
              pending={loading}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" pending={loading} onClick={handleSubmit} disabled={!isValid}>
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            label={trans('first-name')}
            placeholder={trans('first-name')}
            required
            maxLength={255}
            {...getFieldProps('name')}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            label={trans('last-name')}
            placeholder={trans('last-name')}
            required
            maxLength={255}
            {...getFieldProps('familyName')}
          />
        </Grid>

        <Grid item lg={6} md={6} xs={12}>
          <TextField
            label={trans('job-title')}
            placeholder={trans('job-title')}
            required
            maxLength={255}
            {...getFieldProps('jobTitle')}
          />
        </Grid>

        <Grid item lg={6} md={6} xs={12}>
          <TextField
            label={trans('role')}
            placeholder={trans('role')}
            required
            maxLength={255}
            {...getFieldProps('role')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className={styles.labelDropdowns}>{trans('phone')}</div>
          <PhoneInput
            defaultCountry="US"
            international
            placeholder={trans('phone')}
            className={styles.phoneInput}
            {...getFieldProps(`phone`)}
            onChange={(value) => setFieldValue('phone', value)}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            label={trans('email')}
            type="text"
            placeholder={trans('email')}
            {...getFieldProps('email')}
            required
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
}

export default connect(MemberModal);
