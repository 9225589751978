import { LaunchRounded } from '@material-ui/icons';
import React from 'react';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconExports = (props) => {
  const { width = 24, height = 24 } = props;

  return <LaunchRounded width={width} height={height} />;
};

IconExports.propTypes = simpleIconPropsTypes;
