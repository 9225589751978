import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';

export const INITIAL_STATE = {
  userPursuits: [],
  fullLog: [],
  countLogs: 0,
  loading: false,
  members: [],
  membersNotInPursuit: [],
};

const getPursuits = createAsyncThunk('pursuits/getPursuits', (params = {}) => {
  return application.call(API.PURSUITS.GET_PURSUITS, { ...params, pagination: { page: 1, perPage: 30 } });
});

const storeLinkPursuit = createAsyncThunk('pursuits/storeLinkPursuit', (params = {}) => {
  return application.call(API.PURSUITS.LINK_PURSUIT, params);
});

const getOpportunitiesByPursuitId = createAsyncThunk('pursuits/getOpportunitiesByPursuitId', (params = {}) => {
  return application.call(API.PURSUITS.GET_OPPORTUNITIES_BY_PURSUIT_ID, params);
});

const getRelatedByPursuitId = createAsyncThunk('pursuits/getRelatedByPursuitId', (params = {}) => {
  return application.call(API.PURSUITS.GET_RELATED_BY_PURSUIT_ID, params);
});

const getFullLogByPursuit = createAsyncThunk('pursuits/getFullLogByPursuit', (params = {}) => {
  return application.call(API.PURSUITS.GET_FULL_LOG_BY_PURSUIT, params);
});

const getLikelyBiddersByPursuitId = createAsyncThunk('pursuits/getLikelyBiddersByPursuitId', (params = {}) => {
  return application.call(API.PURSUITS.GET_LIKELY_BIDDERS_BY_PURSUIT_ID, params);
});

const getMembersByPursuitId = createAsyncThunk('pursuits/getMembersByPursuitId', (params = {}) => {
  return application.call(API.PURSUITS.GET_MEMBERS_BY_PURSUIT_ID, params);
});
function onMembers(state, action) {
  Object.assign(state, {
    members: action.payload ? [...action.payload.members] : [],
    membersNotInPursuit: action.payload ? [...action.payload.orgUsers] : [],
  });
}

const addMemberToPursuit = createAsyncThunk('pursuits/addMemberToPursuit', (params = {}) => {
  return application.call(API.PURSUITS.ADD_MEMBER_TO_PURSUIT, params);
});
function onAddMember(state, action) {
  Object.assign(state, {
    members: [...state.members, action.payload],
  });
  const index = state.membersNotInPursuit.findIndex((i) => i.userId === action.payload.userId);
  state.membersNotInPursuit.splice(index, 1);
}

const editMemberPursuit = createAsyncThunk('pursuits/editMemberPursuit', (params = {}) => {
  return application.call(API.PURSUITS.EDIT_MEMBER_PURSUIT, params);
});

function onEditMember(state, action) {
  const members = [...state.members];
  const index = members.findIndex((m) => m.id === action.payload.id);
  members[index] = action.payload;
  Object.assign(state, {
    members: members,
  });
}

const deleteMemberPursuit = createAsyncThunk('pursuits/deleteMemberPursuit', (params = {}) => {
  return application.call(API.PURSUITS.DELETE_MEMBER_PURSUIT, params);
});

function onDeleteMember(state, action) {
  const members = [...state.members];
  const membersNotInPursuit = [...state.membersNotInPursuit];
  const index = members.findIndex((m) => m.id === action.payload.id);
  const copyMember = members.find((m) => m.id === action.payload.id);
  members.splice(index, 1);
  membersNotInPursuit.push(copyMember);
  Object.assign(state, {
    members: members,
    membersNotInPursuit: membersNotInPursuit,
  });
}

const enableDisableMemberPursuit = createAsyncThunk('pursuits/enableDisableMemberPursuit', (params = {}) => {
  return application.call(API.PURSUITS.ENABLE_DISABLE_MEMBER_PURSUIT, params);
});

function onEnableDisableMember(state, action) {
  const members = [...state.members];
  const index = members.findIndex((m) => m.id === action.payload.id);
  members[index] = action.payload;
  Object.assign(state, {
    members: members,
  });
}

const savePursuit = createAsyncThunk('pursuits/savePursuit', (params) => {
  return application.call(API.PURSUITS.ADD_PURSUIT, params);
});

const getPointOfContactsFCO = createAsyncThunk('pursuits/getPointOfContactsFCO', (params) => {
  return application.call(API.CONTRACT_OPPORTUNITIES.GET_POINT_OF_CONTACTS, params);
});

function onFetchPursuits(state, action) {
  Object.assign(state, {
    userPursuits: action.payload ? [...action.payload.items] : [],
  });
}

function onFetchFullLog(state, action) {
  Object.assign(state, {
    fullLog: action.payload ? [...action.payload.items] : [],
    countLogs: action.payload.count,
    loading: false,
  });
}
function onStoreLinkPursuit() {}

export const actions = {
  getPointOfContactsFCO,
  getPursuits,
  storeLinkPursuit,
  getOpportunitiesByPursuitId,
  getRelatedByPursuitId,
  getFullLogByPursuit,
  getLikelyBiddersByPursuitId,
  getMembersByPursuitId,
  addMemberToPursuit,
  editMemberPursuit,
  deleteMemberPursuit,
  enableDisableMemberPursuit,
  savePursuit,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return builder
    .addCase(getPursuits.fulfilled, onFetchPursuits)
    .addCase(getFullLogByPursuit.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getFullLogByPursuit.fulfilled, onFetchFullLog)
    .addCase(storeLinkPursuit.fulfilled, onStoreLinkPursuit)
    .addCase(getOpportunitiesByPursuitId.fulfilled, () => {})
    .addCase(getRelatedByPursuitId.fulfilled, () => {})
    .addCase(getLikelyBiddersByPursuitId.fulfilled, () => {})
    .addCase(addMemberToPursuit.fulfilled, onAddMember)
    .addCase(editMemberPursuit.fulfilled, onEditMember)
    .addCase(deleteMemberPursuit.fulfilled, onDeleteMember)
    .addCase(enableDisableMemberPursuit.fulfilled, onEnableDisableMember)
    .addCase(getMembersByPursuitId.fulfilled, onMembers);
});
