import React from 'react';
import Typography from '../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from '../styles';
import { useTrans } from '../../../services/i18n';
import AuthLayout from '../../../components/layouts/AuthLayout';
import { Button } from '../../../components/common/Buttons';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE_PLANS_PUBLIC } from '../../../constants';
import { Box, useTheme } from '@material-ui/core';
import { useIsMobile } from '../../../hooks/useIsMobile';

const NoAccountFound = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { trans } = useTrans();
  const isMobile = useIsMobile();

  return (
    <AuthLayout>
      <div className={classes.formBack}>
        <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
          <Grid
            item
            xs={12}
            className={classes.signForm}
            justifyContent="center"
            alignItems="center"
            style={{
              textAlign: 'center',
              margin: isMobile ? '2rem 0' : '8rem 2rem',
            }}
          >
            <Typography variant="h2" fontFamily="lexend" weight="500">
              {trans('it-seems-your-account-no-longer-exists')}
            </Typography>
            <Typography component="p" weight="500" variant="body1">
              {trans('create-a-new-account-message')}
            </Typography>
            <Box
              display="flex"
              gridGap=".5rem"
              justifyContent="center"
              alignItems="center"
              my={'2rem'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Button variant="contained" color="success" component={RouterLink} to={ROUTE_PLANS_PUBLIC.path}>
                {trans('sign-up-online')}
              </Button>
              <Typography variant="body2" color="hint">
                {' '}
                or{' '}
              </Typography>
              <Button
                variant="contained"
                component={RouterLink}
                href="tel:8772522700"
                style={{
                  padding: '7.5px 20px 8px',
                  backgroundColor: '#52CBFF35',
                  borderRadius: '.25rem',
                  fontWeight: 400,
                }}
              >
                Call{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                    marginLeft: '.25em',
                  }}
                >
                  {' '}
                  877 252 2700
                </span>
              </Button>
            </Box>
            <Typography variant="body2" color="hint">
              If you believe this is an error, please email{' '}
              <a href="mailto:appsupport@usfcr.com" style={{ fontWeight: 600, color: 'inherit' }}>
                appsupport@usfcr.com
              </a>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
};

export default NoAccountFound;
