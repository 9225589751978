import React from 'react';
import { Button } from '../../../../components/common/Buttons';
import { useTrans } from '../../../../services/i18n';
import { Box } from '@material-ui/core';
import connect from '../connect';

function ExportButton({ item, exportPursuits }) {
  const { trans } = useTrans();

  const onClick = () => {
    exportPursuits?.({ pipelineId: item?.id });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Button variant="contained" color="primary" onClick={onClick}>
        {trans('export')}
      </Button>
    </Box>
  );
}

export default connect(ExportButton);
