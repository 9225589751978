import MyLocationIcon from '@material-ui/icons/MyLocation';
import React from 'react';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconPursuits = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <MyLocationIcon
      width={width}
      height={height}
      style={{
        fontSize: `${width}px`,
      }}
    />
  );
};

IconPursuits.propTypes = simpleIconPropsTypes;
