import { connect } from 'react-redux';
import { actions } from '../../store/billing';
import { orgActions } from '../../store/orgSettings';
import { actions as businessActions } from '../../store/business/overview';
import { subscriptionActions } from '../../store/subscription';

function mapStateToProps(state) {
  return {
    subscription: state.orgSettings.subscription,
    roleInOrganization: state.orgSettings.originalRole,
    isEnterprise: state.orgSettings.associatedSalesforceId,
    currentProduct: state.billing.currentProduct,
    pendingCreate: state.billing.pendingCreate,
    defaultPaymentMethod: state.billing.defaultPaymentMethod,
    selectedPlan: state.billing.selectedPlan,
    authUser: state.auth,
    settings: state.orgSettings,
    plans: state.billing.plans,
    subscriptionStatus: state.subscription.data,
    businessTeam: state.businessOverview.team,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomerBilling: () => dispatch(actions.getCustomerBilling()),
    addCard: (params) => dispatch(actions.addPaymentMethod(params)),
    removePaymentMethod: (params) => dispatch(actions.removePaymentMethod(params)),
    updateBilling: (params) => dispatch(actions.updateBilling(params)),
    getPlans: (params) => dispatch(actions.getPlans(params)),
    getInvoices: (params) => dispatch(actions.getInvoices(params)),
    createSubscription: (params) => dispatch(actions.createSubscription(params)),
    cancelSubscription: (params) => dispatch(actions.cancelSubscription(params)),
    updateCurrentProduct: (params) => dispatch(actions.updateCurrentProduct(params)),
    setCancelSubscription: (params) => dispatch(orgActions.setCancelSubscriptionAction(params)),
    setCreateSubscription: (params) => dispatch(orgActions.setCreateSubscriptionAction(params)),
    setDefaultPaymentMethodAction: (params) => dispatch(actions.setDefaultPaymentMethodAction(params)),
    getInvoiceById: (params) => dispatch(actions.getInvoiceById(params)),
    downloadInvoice: (params) => dispatch(actions.downloadInvoice(params)),
    setCurrentModalityPlan: (params) => dispatch(actions.setCurrentModalityPlan(params)),
    upgradeDowngradeSubscription: (params) => dispatch(actions.upgradeDowngradeSubscription(params)),
    getOrganizationInfo: (params) => dispatch(orgActions.getOrganizationInfoAction(params)),
    fetchBusinessTeam: (params) => dispatch(businessActions.getOverview(params)),
    fetchSubscriptionStatus: () => dispatch(subscriptionActions.fetch()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
