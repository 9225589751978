import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useMemo } from 'react';
import usePagination, { ROWS_PER_PAGE_OPTIONS } from '../../../hooks/usePagination';
import { Paper, SpacingContainer } from '../Containers';
import { Divider } from '../Divider';
import Typography from '../Typography';

export default function PaginationSmall({
  bgColor = 'transparent',
  actions = null,
  px = 0,
  py = 0,
  divider = '',
  forceShow = false,
  ...paginationProps
}) {
  const { page, perPage, count, isFirstPage, isLastPage, pending, handleNextPage, handlePrevPage } =
    usePagination(paginationProps);

  return count <= (perPage || ROWS_PER_PAGE_OPTIONS[0]) && !actions && !forceShow ? null : (
    <>
      {divider === 'top' && <Divider light />}
      <Paper component={SpacingContainer} px={px} py={py} square bgColor={bgColor}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography weight="700" component="span" variant="body2">
              {(page - 1) * perPage + 1} — {Math.min(page * perPage, count)}
            </Typography>
            <Typography component="span" variant="body2" weight="700" color="hint">
              {` / ${count}`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handlePrevPage} disabled={pending || isFirstPage}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton onClick={handleNextPage} disabled={pending || isLastPage}>
              <ChevronRightIcon />
            </IconButton>
          </Grid>
          {actions && <Grid item>{actions}</Grid>}
        </Grid>
      </Paper>
    </>
  );
}
