import React from 'react';
import { useCallback, useState } from 'react';

export default function useDragAndDropState({ initialItems, allowExternalChanges, onUpdateColumns, field = 'id' }) {
  const [tempColumns, setTempColumns] = useState(initialItems);

  React.useLayoutEffect(() => {
    if (allowExternalChanges) {
      setTempColumns(initialItems);
    }
  }, [allowExternalChanges, initialItems]);

  const onMoveCard = useCallback(
    ({ dragItem, hoverItem }) => {
      if (!(dragItem && hoverItem && dragItem[field] && hoverItem[field])) return;
      if (dragItem[field] === hoverItem[field]) return;

      setTempColumns((tempColumns) => {
        const dragItemIndex = tempColumns.findIndex((item) => item[field] === dragItem[field]);
        const hoverItemIndex = tempColumns.findIndex((item) => item[field] === hoverItem[field]);
        if (dragItemIndex < 0 || hoverItemIndex < 0) return tempColumns;
        tempColumns = [...tempColumns];
        const saveItem = tempColumns.splice(dragItemIndex, 1)[0];
        tempColumns.splice(hoverItemIndex, 0, saveItem);

        return tempColumns;
      });
    },
    [field]
  );

  const onDropCard = useCallback(() => {
    setTempColumns((tempColumns) => {
      onUpdateColumns?.(tempColumns);
      return tempColumns;
    });
  }, [onUpdateColumns]);

  return { columns: tempColumns, onMoveCard, onDropCard };
}
