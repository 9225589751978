import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconHelpCenterGray = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" noFill={true}>
      <path
        d="M17 2.43005H7C4 2.43005 2 4.43005 2 7.43005V13.4301C2 16.4301 4 18.4301 7 18.4301V20.5601C7 21.3601 7.89 21.8401 8.55 21.3901L13 18.4301H17C20 18.4301 22 16.4301 22 13.4301V7.43005C22 4.43005 20 2.43005 17 2.43005ZM12 14.6001C11.58 14.6001 11.25 14.2601 11.25 13.8501C11.25 13.4401 11.58 13.1001 12 13.1001C12.42 13.1001 12.75 13.4401 12.75 13.8501C12.75 14.2601 12.42 14.6001 12 14.6001ZM13.26 10.4501C12.87 10.7101 12.75 10.8801 12.75 11.1601V11.3701C12.75 11.7801 12.41 12.1201 12 12.1201C11.59 12.1201 11.25 11.7801 11.25 11.3701V11.1601C11.25 10.0001 12.1 9.43005 12.42 9.21005C12.79 8.96005 12.91 8.79005 12.91 8.53005C12.91 8.03005 12.5 7.62005 12 7.62005C11.5 7.62005 11.09 8.03005 11.09 8.53005C11.09 8.94005 10.75 9.28005 10.34 9.28005C9.93 9.28005 9.59 8.94005 9.59 8.53005C9.59 7.20005 10.67 6.12005 12 6.12005C13.33 6.12005 14.41 7.20005 14.41 8.53005C14.41 9.67005 13.57 10.2401 13.26 10.4501Z"
        fill="#888FA9"
      />
    </SvgIcon>
  );
};

IconHelpCenterGray.propTypes = simpleIconPropsTypes;
