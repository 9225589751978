import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';
import { ColoredLogoSvg, IconMenu, IconMenuControl } from '../../../svg';
import EZSearch from '../EZSearch';
import NotificationsInfo from '../NotificationsInfo';
import useStyles from '../styles';
import UserMenu from '../UserMenu';
import BecomePremiumTopBar from '../../../common/Subscription/BecomePremiumTopBar';
import OnboardingModal from '../OnboardingModal';
import connect from './connect';
import UserHelpModal from '../UserHelpModal';

const AppHeader = ({ setDrawerOpen, openDrawer, setDrawerClose, authUser, settings }) => {
  const styles = useStyles();
  const [onboardingOpen, setOnboardingOpen] = useState(false);

  useEffect(() => {
    if (authUser && !authUser.onViewTour) {
      setOnboardingOpen(true);
    }
  }, [authUser]);

  return (
    <>
      <div className={styles.appHeader}>
        <IconButton
          disableFocusRipple
          disableRipple
          className={styles.btnToggle}
          edge="start"
          color="inherit"
          onClick={openDrawer ? setDrawerClose : setDrawerOpen}
        >
          <IconMenuControl rotate={openDrawer} />
        </IconButton>
        <div className={styles.mobileAppLogo}>
          <ColoredLogoSvg />
        </div>
        <div className={styles.searchDiv}>
          <EZSearch />
        </div>
        <div className={styles.rightMenu}>
          <UserHelpModal />
          <NotificationsInfo />
          <UserMenu />
          <IconButton
            disableFocusRipple
            disableRipple
            className={styles.mobileToggle}
            edge="start"
            color="inherit"
            onClick={openDrawer ? setDrawerClose : setDrawerOpen}
          >
            <IconMenu open={!openDrawer} />
          </IconButton>
        </div>
      </div>
      <BecomePremiumTopBar />
      <OnboardingModal open={onboardingOpen} onClose={() => setOnboardingOpen(false)} />
    </>
  );
};

export default connect(AppHeader);
