import React from 'react';
import { ModalDialog } from '../../../components/common/Dialogs';
import { useTrans } from '../../../services/i18n';
import connect from '../connect';
import OrganizationsList from './OrganizationsList';

const ModalSwitchOrganizations = ({ open, onClose }) => {
  const { trans } = useTrans();

  return (
    <ModalDialog open={open} onClose={onClose} title={trans('my-organizations')}>
      <OrganizationsList />
    </ModalDialog>
  );
};

export default connect(ModalSwitchOrganizations);
