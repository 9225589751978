import React, { useEffect, useState } from 'react';
import connect from '../../../connect';
import Typography from '../../../../../components/common/Typography';
import { useTrans } from '../../../../../services/i18n';
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SpacingContainerBase from '../../../../../components/layouts/SpacingContainerBase';
import { LoaderSmall } from '../../../../../components/common/LoaderSmall';

import { useParams, useHistory } from 'react-router-dom';

import { intlDateWithTimeFormat } from '../../../../../utils/dateUtils';
import Button from '../../../../../components/common/Buttons/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { InlineContainer } from '../../../../../components/common/Containers';
import IconButton from '@material-ui/core/IconButton';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import dayjs from 'dayjs';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '@material-ui/icons/CloudDownloadSharp';
import ShareIcon from '@material-ui/icons/Share';
import { formatNumber } from '../../../../../utils/numberUtils';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const Item = ({ getInvoiceById }) => {
  const { trans } = useTrans();
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [pending, setPending] = useState(false);
  const history = useHistory();

  const backToInvoices = () => {
    history.push('/billing?tab=invoices');
  };

  useEffect(() => {
    setPending(true);
    getInvoiceById({ id }).then((res) => {
      setPending(false);
      setInvoice(res.payload.invoice);
    });
  }, [id]);

  return (
    <>
      <SpacingContainerBase>
        <div style={{ display: 'flex', alignItems: 'center', padding: '16px 0' }}>
          <IconButton style={{ marginRight: '10px' }} onClick={() => backToInvoices()}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography weight="700" variant="h2">
            {trans('invoice-details')}
          </Typography>
        </div>

        {pending && <LoaderSmall open={pending} />}

        {!pending && invoice && (
          <Card style={{ padding: '30px 50px' }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography weight="700" variant="h4" gutter="bottomSpace">
                  {trans('invoice-number')} {invoice.number}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="body1" weight="600">
                  {trans('date-of-issue')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{dayjs.unix(invoice.created).format('DD MMM YYYY')}</Typography>
              </Grid>
            </Grid>
            <Grid
              style={{ background: '#F9F9F9', marginTop: '16px', marginBottom: '16px' }}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Typography variant="body1" weight="600">
                  {trans('date-due')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {invoice.due_date
                    ? dayjs.unix(invoice.due_date).format('DD MMM YYYY')
                    : dayjs.unix(invoice.created).format('DD MMM YYYY')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="body1" weight="600">
                  {trans('bill-to')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{invoice.customer_email}</Typography>
              </Grid>
            </Grid>
            <Grid
              style={{ background: '#F9F9F9', marginTop: '16px', marginBottom: '16px' }}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Typography variant="body1" weight="600">
                  {trans('invoice-number')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{invoice.number}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="body1" weight="600">
                  {trans('payment-method-lower')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{'Card'}</Typography>
              </Grid>
            </Grid>
            <Grid
              style={{ background: '#F9F9F9', marginTop: '16px', marginBottom: '16px' }}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Typography variant="body1" weight="600">
                  {trans('distributor')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{'Advance procurement portal'}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="body1" weight="600">
                  {trans('address')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{'9400 4th St N #111, St. Petersburg, FL 33702'}</Typography>
              </Grid>
            </Grid>
            <Grid
              style={{ background: '#F9F9F9', marginTop: '16px', marginBottom: '16px' }}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Typography variant="body1" weight="600">
                  {trans('taxes')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">$ {invoice.tax ? formatNumber(invoice.tax / 100) : '0'}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="body1" weight="600">
                  {trans('total')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">$ {formatNumber(invoice.total / 100)}</Typography>
              </Grid>
            </Grid>
          </Card>
        )}
      </SpacingContainerBase>
    </>
  );
};

export default connect(Item);
