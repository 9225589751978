import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { Button } from '../../../../components/common/Buttons';
import { SpacingContainer } from '../../../../components/common/Containers/Base';
import { Divider } from '../../../../components/common/Divider';
import Typography from '../../../../components/common/Typography';
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard';
import { IconUser } from '../../../../components/svg';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { useTrans } from '../../../../services/i18n';
import connect from '../../connect';
import ManageUsersList from '../ManageUsersList';
import UserModal from '../UserModal';
import { useSubscribedOnly } from '../../../../components/common/Subscription/hooks/useSubscribedOnly';
import InfoAddSeatsModal from '../InfoAddSeatsModal';
import { COMPONENT_FEATURES } from '../../../../utils/constants';
import { useComponentPermission } from '../../../../hooks/useComponentPermission';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../constants';

function ManageUsers({ seats, users, settings, subscription, setOpenModalPremium }) {
  const { trans } = useTrans();
  const [openUserDialog, onOpenUserDialog, onCloseUserDialog] = useBooleanHandlers();
  const [openAddSeatsUserDialog, onOpenAddSeatsDialog, onCloseAddSeatsDialog] = useBooleanHandlers();
  const { hasPermission } = useComponentPermission(COMPONENT_FEATURES.USERS);
  const history = useHistory();

  const { subscribedOnly } = useSubscribedOnly(subscription, setOpenModalPremium);

  const openAddUser = () => {
    if (settings.availableSeats === 0 || !hasPermission) {
      onOpenAddSeatsDialog();
    } else {
      onOpenUserDialog();
    }
  };

  const goToPlans = () => {
    history.push(ROUTES.ROUTE_PLANS_PRIVATE.path);
  };

  return (
    <>
      <UserModal open={openUserDialog} onClose={onCloseUserDialog} />
      <InfoAddSeatsModal open={openAddSeatsUserDialog} onClose={onCloseAddSeatsDialog} />

      {!!users.length ? (
        <SpacingContainer px={4} py={3}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" weight="600" fontFamily="lexend">
                {trans('manage-users')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                id="add-user-btn"
                variant="contained"
                isIcon
                color="primary"
                onClick={subscribedOnly(openAddUser, goToPlans)}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>

          <Divider marginTop="2" light />

          <ManageUsersList />
        </SpacingContainer>
      ) : (
        <NothingFoundCard
          title={trans('manage-users')}
          description={trans('AddMembersToYourOrganization')}
          Icon={<IconUser multiple />}
          py={10}
          size="small"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={subscribedOnly(onOpenUserDialog, goToPlans)}
            disabled={users.length - 1 >= seats}
          >
            {trans('AddMembers')}
          </Button>
        </NothingFoundCard>
      )}
    </>
  );
}

export default connect(ManageUsers);
