// import Grid from '@material-ui/core/Grid';
import { Container, Grid, Box } from '@material-ui/core';
import React, { useEffect, useState, createContext, useContext } from 'react';
import { API } from '../../constants';
import { useApplication } from '../../services/application';
import * as yup from 'yup';
import { Paper } from '../../components/common/Containers';
import { SpacingContainer } from '../../components/common/Containers/Base';
import { Divider } from '../../components/common/Divider';
import Button from '../../components/common/Buttons/Button';
import Loader from '../../components/common/Loader';
import Typography from '../../components/common/Typography';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';
import { IconUser } from '../../components/svg';
import { useTrans } from '../../services/i18n';
//import SetupModal from './components/SetupModal';
import connect from './connect';
import { useHistory } from 'react-router-dom';
import SectionScrollable from '../../components/common/Containers/SectionScrollable';
import StepCard from './components/StepCard';
import GeneralInformation from './components/GeneralInformation';
import Industry from './components/Industry';
import CompanyAddress from './components/CompanyAddress';
import { ROUTE_ORG_SETTINGS } from '../../../src/constants/index';
import { useLocation } from 'react-router-dom';

const FormDataContext = createContext({
  generalInformationData: {
    companyName: '',
    businessStartDate: '',
    ueiCode: '',
    cageCode: '',
    dba: '',
    stateOfIncorporation: '',
    corporateUrl: '',
    phone: null,
    businessHours: '',
    profitType: '',
  },
  companyAddressData: {
    addressLine1: '',
    city: '',
    addressLine2: '',
    state: '',
    zipCode: '',
    country: '',
  },
  industryData: {
    primaryNaics: [],
    secondaryNaics: [],
    pscCodes: [],
    socioEconomicCategories: [],
    majorityEthnicity: null,
    corporateStructure: null,
  },
  setGeneralInformationData: () => {},
  setCompanyAddressData: () => {},
  setIndustryData: () => {},
});

export const useFormData = () => {
  return useContext(FormDataContext);
};

function OrganizationManualRegistrationForm({ settings, getPublicProfile, getOrganizationInfo, getUsers }) {
  const application = useApplication();
  const { trans } = useTrans();
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const allowRole = ['admin', 'owner'].includes(settings?.userRole?.toLowerCase());
  const [currentStep, setCurrentStep] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  const [isCompanyAddressComplete, setIsCompanyAddressComplete] = useState(false);
  const [isGeneralInformationComplete, setIsGeneralInformationComplete] = useState(false);
  const [isIndustryComplete, setIsIndustryComplete] = useState(false);
  const location = useLocation();
  const generalInformationRecord = location.state?.manualRegistrationRecord;
  const isEditMode = location.state?.isEditMode;

  const [generalInformationData, setGeneralInformationData] = useState({
    companyName: location.state?.manualRegistrationRecord?.companyName || '',
    businessStartDate: location.state?.manualRegistrationRecord?.businessStartDate || '',
    ueiCode: location.state?.manualRegistrationRecord?.ueiCode || '',
    cageCode: location.state?.manualRegistrationRecord?.cageCode || '',
    dba: location.state?.manualRegistrationRecord?.dba || '',
    stateOfIncorporation: location.state?.manualRegistrationRecord?.stateOfIncorporation || '',
    corporateUrl: location.state?.manualRegistrationRecord?.corporateUrl || '',
    phone: location.state?.manualRegistrationRecord?.phone || null,
    businessHours: location.state?.manualRegistrationRecord?.businessHours || '',
    profitType: location.state?.manualRegistrationRecord?.profitType || '',
  });
  const [companyAddressData, setCompanyAddressData] = useState({
    addressLine1: location.state?.manualRegistrationRecord?.addressLine1 || '',
    city: location.state?.manualRegistrationRecord?.city || '',
    addressLine2: location.state?.manualRegistrationRecord?.addressLine2 || '',
    state: location.state?.manualRegistrationRecord?.state || '',
    zipCode: location.state?.manualRegistrationRecord?.zipCode || '',
    country: location.state?.manualRegistrationRecord?.country || '',
  });
  const [industryData, setIndustryData] = useState({
    primaryNaics: location.state?.manualRegistrationRecord?.primaryNaics || [],
    secondaryNaics: location.state?.manualRegistrationRecord?.secondaryNaics || [],
    pscCodes: location.state?.manualRegistrationRecord?.pscCodes || [],
    socioEconomicCategories: location.state?.manualRegistrationRecord?.socioEconomicCategories || [],
    majorityEthnicity: location.state?.manualRegistrationRecord?.majorityEthnicity || null,
    corporateStructure: location.state?.manualRegistrationRecord?.corporateStructure || null,
  });

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const cancelProcess = () => {
    history.push(ROUTE_ORG_SETTINGS.path);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getOrganizationInfo()]).finally(() => {
      setLoading(false);
    });
  }, [getOrganizationInfo, getUsers]);

  function handleCompanyAddressCompletionChange(isComplete) {
    setIsCompanyAddressComplete(isComplete);
  }

  function handleGeneralInformationCompletionChange(isComplete) {
    setIsGeneralInformationComplete(isComplete);
  }

  function handleIndustryCompletionChange(isComplete) {
    setIsIndustryComplete(isComplete);
  }

  const handleFinalSubmission = async () => {
    const finalData = {
      ...generalInformationData,
      ...companyAddressData,
      ...industryData,
    };
    try {
      const response = await application.call(API.ORGANIZATION.REGISTER_ORGANIZATION_MANUALLY, { finalData });
      history.push(ROUTE_ORG_SETTINGS.path);
    } catch (error) {
      console.log(error);
    }
  };

  let currentForm;
  switch (currentStep) {
    case 1:
      currentForm = (
        <GeneralInformation
          cancelButtonHandler={cancelProcess}
          previousStep={cancelProcess}
          nextStep={nextStep}
          onCompletionChange={handleGeneralInformationCompletionChange}
          initialValues={generalInformationData}
        />
      );
      break;
    case 2:
      currentForm = (
        <CompanyAddress
          cancelButtonHandler={cancelProcess}
          nextStep={nextStep}
          previousStep={previousStep}
          onCompletionChange={handleCompanyAddressCompletionChange}
          initialValues={companyAddressData}
        />
      );
      break;
    case 3:
      currentForm = (
        <Industry
          cancelButtonHandler={cancelProcess}
          previousStep={previousStep}
          onCompletionChange={handleIndustryCompletionChange}
          initialValues={industryData}
          onSubmit={handleFinalSubmission}
        />
      );
      break;
    default:
  }

  return (
    <FormDataContext.Provider
      value={{
        generalInformationData,
        setGeneralInformationData,
        companyAddressData,
        setCompanyAddressData,
        industryData,
        setIndustryData,
      }}
    >
      <SectionScrollable>
        <SpacingContainerBase>
          <Loader open={loading} />

          <Typography weight="600" variant="h2" fontFamily="lexend" gutter="bottomSpaceBig">
            {trans('register-your-organization-title')}
          </Typography>
          <SpacingContainerBase>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Box mb={2}>
                  <StepCard
                    title={trans('register-organization-form-step-1')}
                    isActive={currentStep === 1}
                    isCompleted={isGeneralInformationComplete}
                    stepNumber={1}
                    nextStep={2}
                    previousStep={1}
                  />
                </Box>
                <Box mb={2}>
                  <StepCard
                    title={trans('register-organization-form-step-2')}
                    isActive={currentStep === 2}
                    isCompleted={isCompanyAddressComplete}
                    stepNumber={2}
                    nextStep={3}
                    previousStep={1}
                  />
                </Box>
                <Box mb={2}>
                  <StepCard
                    title={trans('register-organization-form-step-3')}
                    isActive={currentStep === 3}
                    isCompleted={isIndustryComplete}
                    stepNumber={3}
                    previousStep={2}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={9}>
                <Paper>
                  <Grid item xs={12}>
                    {currentForm}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </SpacingContainerBase>
        </SpacingContainerBase>
      </SectionScrollable>
    </FormDataContext.Provider>
  );
}

export default connect(OrganizationManualRegistrationForm);
