import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconAdded = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M13 7v4h4v2h-4v4h-2v-4H7v-2h4V7h2z"
        transform="translate(-374.000000, -342.000000) translate(270.000000, 113.000000) translate(40.000000, 107.000000) translate(0.000000, 58.000000) translate(0.000000, 51.000000) translate(64.000000, 13.000000)"
      />
    </SvgIcon>
  );
};

IconAdded.propTypes = simpleIconPropsTypes;
