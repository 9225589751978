import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useTrans } from '../../../../../services/i18n';
import { Button } from '../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../components/common/Dialogs';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import connect from '../../connect';
import { ReactComponent as UploadCsvIcon } from '../../icons/ico-upload-csv.svg';
import CardActionCsv from '../CardActionCsv';
import CardProcessCsv from '../CardProcessCsv';
import { useApplication } from '../../../../../services/application';
import Typography from '../../../../../components/common/Typography';
import { Divider } from '../../../../../components/common/Divider';

import Papa from 'papaparse';

const AddVendorsCsv = ({
  open,
  onClose,
  changeImportingCsv,
  changeProcessCsv,
  processCsv,
  addVendorsFromImport,
  processValuesCsv,
}) => {
  const { trans } = useTrans();
  const { successAlert, errorAlert } = useAlertContext();
  const application = useApplication();
  const [urlDownload, setUrlDownload] = useState(null);
  const [loadingImport, setLoadingImport] = useState(false);
  const [loadingByTypeImport, setLoadingByTypeImport] = useState(null);
  const [file, setFile] = useState(null);
  const [isUpload, setIsUpload] = useState(false);

  const onSubmit = async (type) => {
    setLoadingByTypeImport(type);
    let data;
    if (type === 'all') data = processValuesCsv.newsRegistered.concat(processValuesCsv.notNewRegistered);
    if (type === 'new') data = processValuesCsv.newsRegistered;
    if (type === 'not-new') data = processValuesCsv.notNewRegistered;
    if (data) {
      Promise.resolve(addVendorsFromImport({ data }))
        .then(() => {
          onClose();
          successAlert('Vendor was sucessfully added');
        })
        .catch(() => errorAlert('Error upload vendors'))
        .finally(() => setLoadingByTypeImport(null));
    }
  };

  const getUrlDownloadTemplate = async () => {
    setUrlDownload(null);
    const url = await application.call('vendorsManagement.getUrlDownloadCsvTemplate', {});
    setUrlDownload(url);
  };

  const onDownload = () => {
    const link = document.createElement('a');
    link.download = urlDownload;
    link.href = urlDownload;
    link.target = '_blank';
    link.click();
  };

  const uploadCsvFile = (ev) => {
    setLoadingImport(true);
    const csv = ev.currentTarget.files[0];
    setFile(csv);
    setIsUpload(true);

    Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log(results.data);
        changeImportingCsv({ data: results.data })
          .then(() => {
            setLoadingImport(false);
            changeProcessCsv(true);
          })
          .catch(() => {
            setLoadingImport(false);
            errorAlert('Error loading csv');
          });
      },
    });
  };

  const cancelAction = () => {
    onClose();
    setLoadingImport(false);
  };

  useEffect(() => {
    if (open) {
      getUrlDownloadTemplate();
    }
  }, [open]);

  return (
    <ModalDialog
      open={open}
      onClose={() => onClose()}
      title={trans('import-vendors-from-csv')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              disabled={
                loadingByTypeImport ||
                (!processValuesCsv.newsRegistered.length && !processValuesCsv.notNewRegistered.length)
              }
              pending={loadingByTypeImport === 'all'}
              variant="contained"
              color="primary"
              onClick={() => onSubmit('all')}
            >
              {trans('import-vendor')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid container style={{ padding: '0  12px 16px 12px' }}>
          <Grid item>
            <Typography weight="600" fontFamily="Manrope" color="label" gutter="label" variant="body2">
              {trans('csv-step-by-step')}
            </Typography>
          </Grid>

          <Grid item style={{ width: '100%' }} align={'center'}>
            <Divider spacing={1} />
          </Grid>

          <Grid item style={{ margin: '10px 0px 10px 0px' }}>
            <Grid container spacing={2} wrap={'nowrap'}>
              <Grid item>
                <Typography weight={'bold'} color="primary">
                  {trans('step-1')}
                </Typography>
              </Grid>
              <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2px' }}>
                <Typography weight="600" color="label" gutter="label" variant="body2">
                  {trans('step-1-info')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CardActionCsv
              title={trans('get-template')}
              subtitle={trans('to-create-your-csv-file')}
              actionButton={onDownload}
              textButton={trans('download-csv')}
              variantButton="contained"
              colorButton="primary"
              type="download"
            />
          </Grid>

          <Grid item style={{ margin: '15px 0px 10px 0px' }}>
            <Grid container spacing={2} wrap={'nowrap'}>
              <Grid item>
                <Typography weight={'bold'} color="primary">
                  {trans('step-2')}
                </Typography>
              </Grid>
              <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2px' }}>
                <Typography weight="600" color="label" gutter="label" variant="body2">
                  {trans('step-1-info')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CardActionCsv
              icon={<UploadCsvIcon />}
              title={trans('upload-csv-file')}
              subtitle={trans('to-start-import')}
              actionButton={uploadCsvFile}
              textButton={trans('import-csv')}
              variantButton="contained"
              colorButton="success"
              type="upload"
              file={file}
              isUpload={isUpload}
              loadingImport={loadingImport}
            />
          </Grid>

          {!loadingImport && processCsv && (
            <Grid item style={{ margin: '15px 0px 10px 0px' }}>
              <Grid item xs={12}>
                <CardProcessCsv />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

export default connect(AddVendorsCsv);
