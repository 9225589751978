import React, { useEffect } from 'react';
import Typography from '../../components/common/Typography';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';
import { useTrans } from '../../services/i18n';
import connect from './connect';

import TabPanel from './TabPanel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import styles from './styles.module.css';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';

//Tabs
import BillingTab from './BillingTab';
import PlansTab from './PlansTab';
import InvoicesTab from './InvoicesTab';

import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_DASHBOARD } from '../../constants';

const Billing = (props) => {
  const { roleInOrganization, isEnterprise } = props;
  const { trans } = useTrans();
  const history = useHistory();
  const search = useLocation().search;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // let queryParams = new URLSearchParams(search);
    // queryParams.delete('tab');
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    const tabs = ['billing', 'invoices', 'plan'];
    const tabUrl = new URLSearchParams(search).get('tab');

    const foundIndex = tabs.indexOf(tabUrl?.toLowerCase());
    if (foundIndex > -1) {
      setValue(foundIndex);
    }
  }, [search]);

  return (
    <SpacingContainerBase>
      <Typography weight="700" variant="h2" gutter="bottomSpace">
        {trans('billing-overview')}
      </Typography>

      <Grid container>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              indicatorColor="primary"
              TabIndicatorProps={{
                style: {
                  height: '3px',
                },
              }}
              className={styles.tabsContainer}
              value={value}
              onChange={handleChange}
              aria-label="tab"
            >
              <Tab
                className={clsx(styles.tab, value === 0 && styles.activeTab)}
                label={trans('billing')}
                {...a11yProps(0)}
              />
              <Tab
                className={clsx(styles.tab, styles.borderLeftRight, value === 1 && styles.activeTab)}
                label={trans('invoices')}
                {...a11yProps(1)}
              />
              <Tab
                className={clsx(styles.tab, value === 2 && styles.activeTab)}
                label={trans('plan')}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
        </Grid>
      </Grid>

      <TabPanel value={value} index={0}>
        <BillingTab navigateManagePlan={handleChange} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InvoicesTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PlansTab />
      </TabPanel>
    </SpacingContainerBase>
  );
};

export default connect(Billing);
