import { alpha as fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contained: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&$error': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    '&$success': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
    '&$warning': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    '&$info': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
    },
  },
  outlined: {
    '&$error': {
      color: theme.palette.error.main,
      borderColor: fade(theme.palette.error.main, 0.4),
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.08),
      },
    },
    '&$success': {
      color: theme.palette.success.main,
      borderColor: fade(theme.palette.success.main, 0.4),
      '&:hover': {
        backgroundColor: fade(theme.palette.success.main, 0.08),
      },
    },
    '&$warning': {
      color: theme.palette.warning.main,
      borderColor: fade(theme.palette.warning.main, 0.4),
      '&:hover': {
        backgroundColor: fade(theme.palette.warning.main, 0.08),
      },
    },
  },
  text: {
    '&$error': {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.08),
      },
    },
    '&$success': {
      color: theme.palette.success.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.success.main, 0.08),
      },
    },
    '&$warning': {
      color: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.warning.main, 0.08),
      },
    },
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    visibility: 'visible',
  },
  isIconRoot: {
    padding: 15,
    width: 40,
    '&$sizeSmall': {
      padding: 2,
      borderRadius: 2,
      width: 28,
    },
    '&$sizeLarge': {
      padding: 22,
      borderRadius: 8,
      width: 54,
    },
  },
  isIconOutlined: {
    padding: 13,
    '&$sizeSmall': {
      padding: 2,
      borderRadius: 2,
      width: 28,
    },
    '&$sizeLarge': {
      padding: 20,
      borderRadius: 8,
      width: 54,
    },
  },
  disabledStyles: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  pendingStyles: {
    '& > $label': {
      visibility: 'hidden',
    },
  },
  view: {
    overflow: 'hidden',
    cursor: 'default',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'currentColor',
      opacity: 0.1,
    },
  },
  label: {},
  sizeSmall: {},
  sizeLarge: {},
  error: {},
  success: {},
  warning: {},
  info: {},
}));

export default useStyles;
