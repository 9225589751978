import MUISnackbar from '@material-ui/core/Snackbar';
import React from 'react';
import { SpacingContainer } from '../Containers/Base';
import Paper from '../Containers/Paper';

export const SnackbarTopCenter = { vertical: 'top', horizontal: 'center' };

export default function Snackbar({
  children,
  open,
  onClose,
  bgColor = 'default',
  autoHideDuration = 6000,
  anchorOrigin = SnackbarTopCenter,
  ...rest
}) {
  const handleClose = React.useCallback(
    (_e, reason) => {
      if (reason === 'clickaway' || typeof onClose !== 'function') return;
      onClose();
    },
    [onClose]
  );

  return (
    <MUISnackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      {...rest}
    >
      <Paper bgColor={bgColor} component={SpacingContainer} px={2} py={2} elevation={1} radius={20}>
        {children}
      </Paper>
    </MUISnackbar>
  );
}
