import { useState, useEffect } from 'react';
import connect from '../connect';
import { useParams } from 'react-router-dom';
import { ColoredLogoSvg } from '../../../components/svg/logos';
import { Card } from '@material-ui/core';
import { Divider } from '../../../components/common/Divider';
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase';
import { Paper, SpacingContainer } from '../../../components/common/Containers';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../components/common/Typography';
import FieldValue from '../../../components/generics/Helpers/FieldValue';
import { useTrans } from '../../../services/i18n';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Button } from '../../../components/common/Buttons';
import { LoaderSmall } from '../../../components/common/LoaderSmall';
import styles from '../styles.module.css';
import { ROUTE_BILLING } from '../../../constants';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { formatNumber } from '../../../utils/numberUtils';

const calculateDiscount = (coupon, originalPrice) => {
  if (coupon) {
    const { amount_off, percent_off } = coupon;
    if (amount_off) {
      return amount_off / 100;
    }
    if (percent_off) {
      const amountDiscount = parseFloat((originalPrice * percent_off) / 100);
      return amountDiscount;
    }
  }
};

const SuccessPayment = (props) => {
  const { userFullName, getSubscription, downloadInvoice, getOrganizationInfo, getSubscriptionStatus } = props;
  const { subscriptionId } = useParams();
  const [subscription, setSubscription] = useState(null);
  const [pending, setPending] = useState(false);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { trans } = useTrans();

  const download = async (id) => {
    await downloadInvoice({ id, fileName: `${subscription?.invoice.number}.pdf` });
  };

  useEffect(() => {
    setPending(true);
    getSubscription({ sub: subscriptionId }).then((res) => {
      if (res && res.payload && res.payload.success) {
        const {
          price: { unit_amount },
          coupon,
          ...rest
        } = res.payload.data;
        setSubscription({ ...rest, coupon });
        setTotal(unit_amount);
        if (coupon) {
          const totalDiscount = calculateDiscount(coupon, unit_amount);
          setDiscount(totalDiscount);
        }
      }

      getOrganizationInfo({});
      getSubscriptionStatus();
      setPending(false);
    });
  }, [subscriptionId, getOrganizationInfo, getSubscription]);

  return (
    <SpacingContainerBase>
      <SpacingContainer py={2}>
        {!pending ? (
          <>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
              <Grid item>
                <Typography weight="600" variant="h2" fontFamily="lexend" title>
                  <CheckCircleOutlineIcon fontSize="large" className={styles.checkIcon} />
                  {trans('thank-you')} {userFullName}
                </Typography>
                <Typography weight="500" color="label" fontFamily="lexend" variant="h6">
                  {trans('Order')}: {subscription?.invoice.number}
                </Typography>
              </Grid>
              <Grid item>
                <ColoredLogoSvg />
              </Grid>
            </Grid>

            <Card elevation={0} className={styles.containerCardCheckout}>
              <SpacingContainer px={5} py={4}>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="h5" fontFamily="lexend" weight="500" gutter="label">
                          {trans('title-order-confirmed')}
                        </Typography>
                        <Typography variant="body2" fontFamily="lexend" weight="500" color="placeholder">
                          {trans('message-order-confirmed')}
                        </Typography>

                        <Divider light spacing="2" />

                        <Typography variant="h5" fontFamily="lexend" weight="500" gutter="bottom2">
                          {trans('customer-information')}
                        </Typography>
                        <FieldValue
                          value={subscription?.customer?.email}
                          label={trans('contact')}
                          colorLabel="label"
                          color="hint"
                          fontFamily="lexend"
                        />
                        <div className={styles.spacing}></div>
                        <FieldValue
                          value={subscription?.customer?.billing}
                          label={trans('billing-address')}
                          colorLabel="label"
                          color="hint"
                          fontFamily="lexend"
                        />

                        <Divider light spacing="2" />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className={styles.separateButtons}
                          variant="contained"
                          color="primary"
                          to={ROUTE_BILLING.path}
                          size="large"
                        >
                          {trans('back-to-billing')}
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => download(subscription?.invoice.invoiceId)}
                          size="large"
                        >
                          {trans('download-invoice')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} md={12}>
                    <Paper bgColor="paperSecondary" component={SpacingContainer} py={4} px={4}>
                      <Grid container alignItems="flex-end" direction="row" spacing={2}>
                        <Grid item>
                          <Typography variant="h6" fontFamily="lexend" gutter="label">
                            {subscription?.plan} {subscription?.isTrial ? '(Trial)' : ''}
                          </Typography>
                          <Typography variant="h6" color="label" fontFamily="lexend">
                            <AttachMoneyIcon /> USD {formatNumber(total)}/{subscription?.modality} payment plan
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Divider light spacing="3" />
                    {discount > 0 && (
                      <>
                        <Grid spacing={2} container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography weight="600" variant="body1" fontFamily="lexend">
                              {trans('subtotal')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography weight="600" variant="h6" fontFamily="lexend">
                              ${formatNumber(total)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid spacing={2} container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography weight="600" variant="body1" fontFamily="lexend">
                              {trans('discount')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography weight="600" variant="h6" fontFamily="lexend" color="secondary">
                              - ${formatNumber(discount)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid spacing={2} container alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Typography weight="600" variant="body1" fontFamily="lexend">
                          {trans('total')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography weight="600" variant="h2" fontFamily="lexend">
                          ${formatNumber(discount <= total ? total - discount : 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SpacingContainer>
            </Card>
          </>
        ) : (
          <LoaderSmall color="primary" size="3rem" />
        )}
      </SpacingContainer>
    </SpacingContainerBase>
  );
};

export default connect(SuccessPayment);
