export * from './routes';
export * as ROUTES from './routes';
export * from './search';
export * from './codes';
export * as API from './api';

export const AUTH_TOTP = 'TOTP';
export const AUTH_NOMFA = 'NOMFA';
export const AUTH_SMS_MFA = 'SMS_MFA';
export const AUTH_SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA';
export const AUTH_NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const CODE_ENTER = 13;

export const DATE_PRETTY_FORMAT = 'MMM D, YYYY';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YY, h:mm  A';
export const ISO_STANDART_DATE_TIME_FORMAT = 'YYYY-MM-DDThh:mm:ss±zz';
export const TIME_FORMAT = 'h:mm a';

export const REFERENCED_IDVID_IN_FCO = 'award.number';

export const CA_ID_FOR_GRAPH = 'Federal Contract Awards';
export const GA_ID_FOR_GRAPH = 'Federal Grant Awards';
export const SCOPE_RELEASES = 'RELEASES';

export const AVATAR_STATUSES = {
  approved: 'APPROVED',
  pending: 'PENDING',
  rejected: 'REJECTED',
};
