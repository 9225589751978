import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    subscription: state.subscription,
    limitedAccountsDeletionDate: state?.globalSettings?.limitedAccountsDeletionDate,
    limitedAccountsDaysToDisable: state?.globalSettings?.limitedAccountsDaysToDisable,
    limitedAccountsDaysToDelete: state?.globalSettings?.limitedAccountsDaysToDelete,
  };
}

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps);
