import { createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_GOVERMENT_FILES } from '../../constants';
import {
  extendBuilderWithNotInterestedActions,
  extendListBuilder,
  generateListActions,
  generateSearchActions,
  INITIAL_LIST_STATE_EXTENDED,
} from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['postedDate', 'desc'],
};

const listActions = generateListActions({
  scope: SCOPE_GOVERMENT_FILES,
  apiMethod: {
    GET_LIST: API.GOVERMENT_FILES.GET_LIST,
    EXPORT_CSV: API.GOVERMENT_FILES.EXPORT_CSV,
  },
  getStore: (store) => store.govermentFiles,
});

const searchActions = generateSearchActions({
  scope: SCOPE_GOVERMENT_FILES,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.govermentFiles,
});

export const actions = {
  ...listActions,
  ...searchActions,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendListBuilder(builder, actions);
  extendBuilderWithNotInterestedActions(builder, actions);

  return builder;
});
