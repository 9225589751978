import { useState, useCallback } from 'react';
import { useAlertContext } from '../../../contexts/AlertContext';
import { useApplication } from '../../../services/application';

export function useResendEmailForgetPasswordCode() {
  const [pending, setPending] = useState();
  const { successAlert, errorAlert } = useAlertContext();
  const application = useApplication();

  const resendCodeForgetPassword = useCallback(
    async (email) => {
      setPending(true);

      try {
        await application.call('user.forgotPassword', { email });
        successAlert('Confirmation link was re-sent successfully', 'Please check your email');
        setTimeout(() => setPending(false), 10000);
      } catch (error) {
        errorAlert(`Could not connect to the server, try again in a few minutes.\nError Code: ${error?.code}`);
        setPending(false);
      }
    },
    [application, successAlert, errorAlert]
  );

  return { pending, resendCodeForgetPassword };
}

export default useResendEmailForgetPasswordCode;
