import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconUpload = (props) => {
  const { width, height } = props;

  return (
    <SvgIcon width={width || 38} height={height || 42} viewBox="0 0 38 42">
      <path
        d="M328 70c.513 0 .936.386.993.883L329 71v6c0 2.689-2.122 4.882-4.783 4.995L324 82h-28c-2.689 0-4.882-2.122-4.995-4.783L291 77v-6c0-.552.448-1 1-1 .513 0 .936.386.993.883L293 71v6c0 1.598 1.249 2.904 2.824 2.995L296 80h28c1.598 0 2.904-1.249 2.995-2.824L327 77v-6c0-.552.448-1 1-1zm-18-30h.02c.023 0 .046.002.07.004L310 40c.05 0 .1.004.149.011l.052.01c.022.004.043.009.065.015l.046.014.06.021.051.023.061.03.037.022c.025.016.05.032.074.05l.018.014c.007.005.01.007.012.01l.082.073 8 8c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083L311 43.415V69c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L309 69V43.415l-6.293 6.292c-.36.36-.928.388-1.32.083l-.094-.083c-.36-.36-.388-.928-.083-1.32l.083-.094 8-8 .082-.073.008-.007-.09.08c.035-.036.073-.068.112-.097.024-.018.049-.034.074-.05l.037-.021c.02-.011.04-.022.06-.031l.053-.023.06-.021.045-.014.065-.016.053-.009.058-.007.072-.004H310z"
        transform="translate(-701.000000, -165.000000) translate(380.000000, 20.000000) translate(30.000000, 105.000000)"
      />
    </SvgIcon>
  );
};

IconUpload.propTypes = simpleIconPropsTypes;
