import { ROUTE_SIGNIN, ROUTE_DASHBOARD } from '../constants';

export const ABBREVIATION_EXCEPTIONS = ['USA', 'LLC', 'U.S.', 'US'];
export const LOWER_CASE_EXCEPTIONS = ['of', 'for', 'on'];

export const formatNumberToUsd = (value, digits = 2, isShowSign = false) => {
  value = Number(value);
  if (!value) {
    value = 0;
  }
  return `${isShowSign ? (value > 0 ? '+' : value < 0 ? '-' : '') : ''}${new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
    currencyDisplay: 'symbol',
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(value || 0)}`;
};

export function replaceCharacters(string) {
  if (!string) return '';
  return string.replace(/[^a-zA-Z0-9]/g, '');
}

export function removeHyphenFromString(string) {
  return string.split('-').join('');
}

export function htmlToText(content) {
  const el = document.createElement('div');
  el.innerHTML = String(content || '');

  return el.innerText;
}

export function formatToTitleCase(str) {
  if (!str) return str;
  return str.replace(/\w\S*/g, (txt) => {
    if (ABBREVIATION_EXCEPTIONS.includes(txt.toUpperCase())) return txt.toUpperCase();
    if (LOWER_CASE_EXCEPTIONS.includes(txt.toLowerCase())) return txt.toLowerCase();
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function jsonStringify(obj) {
  if (!obj) return '';
  try {
    return JSON.stringify(obj);
  } catch (e) {
    console.warn(e);
    return '';
  }
}

export function jsonParse(str) {
  if (!str) return {};
  try {
    return JSON.parse(str);
  } catch (e) {
    console.warn(e);
    return {};
  }
}

export function cloneObject(object) {
  return jsonParse(jsonStringify(object));
}

export function omit(obj, ...props) {
  const result = { ...obj };
  props.forEach(function (prop) {
    delete result[prop];
  });
  return result;
}

export function pick(obj, ...props) {
  return props.reduce(function (result, prop) {
    result[prop] = obj[prop];
    return result;
  }, {});
}

export function hashCode(str) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

export function sortObjectKeys(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((acc, curr) => ({ ...acc, [curr]: obj[curr] }), {});
}

export function getObjectHashCode(obj) {
  return hashCode(JSON.stringify(sortObjectKeys(obj)));
}

export function getCurrentPathWithoutQuery() {
  return window.location.href.split('?')[0];
}

export function getSiteDomain() {
  return window.location.protocol + '//' + window.location.host;
}

export function isNull(value) {
  return !(typeof value !== 'undefined' && value !== null);
}

export function formatToSlugCase(str) {
  if (!str) return str;
  return str
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '');
}

export function formatTime24To12(timeStr) {
  let [hour, minute] = timeStr.split(':');
  let period = 'AM';

  hour = parseInt(hour, 10);

  if (hour > 12) {
    period = 'PM';
    hour -= 12;
  } else if (hour === 0) {
    hour = 12;
    period = 'AM';
  } else if (hour === 12) {
    period = 'PM';
  }

  return `${hour}:${minute} ${period}`;
}

export function formatTimeInterval(timesArray) {
  const start = formatTime24To12(timesArray[0]);
  const end = formatTime24To12(timesArray[1]);

  return `${start} - ${end}`;
}

export function formatPhoneNumber(phone) {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const stripped = cleaned.length === 11 && cleaned.startsWith('1') ? cleaned.substr(1) : cleaned;
  const match = stripped.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) {
    return phone;
  }

  const formatted = `(${match[1]}) ${match[2]}-${match[3]}`;
  return formatted;
}

export function getRedirectUrls(authorized, redirectUrl) {
  const visitedUrl = window.location.pathname + window.location.search;
  const authRedirect =
    visitedUrl.length <= 1
      ? ROUTE_SIGNIN.path // When domain.com or domain.com/ is visited, go to Sign In page
      : visitedUrl.startsWith(ROUTE_SIGNIN.path) // When Sign In page is visited, go to Sign In
      ? visitedUrl
      : `${ROUTE_SIGNIN.path}?redirectUrl=${encodeURIComponent(visitedUrl)}`; // When other pages are visited, go to Sign In page with redirectUrl

  const decodedUri = decodeURIComponent(redirectUrl);

  const route =
    redirectUrl?.length > 1 ? `${ROUTE_SIGNIN.path}?redirectUrl=${encodeURIComponent(redirectUrl)}` : visitedUrl;
  let notAuthRedirect;

  if (authorized) {
    if (redirectUrl) {
      notAuthRedirect = decodedUri;
    } else {
      notAuthRedirect = route === visitedUrl ? null : visitedUrl;
    }
  } else {
    notAuthRedirect = route === visitedUrl ? null : route;
  }

  return { authRedirect, notAuthRedirect: notAuthRedirect === 'null' ? ROUTE_DASHBOARD.path : notAuthRedirect };
}
