import * as Yup from 'yup';
import makeValidationSchema from '../../../../../hooks/makeValidationSchema';

export const initialValues = {
  country: '',
  state: '',
  city: '',
  line1: '',
  postal_code: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    country: Yup.object().required(trans('required-field')),
    state: Yup.object().required(trans('required-field')),
    city: Yup.object().required(trans('required-field')),
    line1: Yup.string().required(trans('required-field')),
    postal_code: Yup.object().required(trans('required-field')),
  })
);
