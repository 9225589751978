import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconPipelines = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="M20.812 14.416l.062.098c.25.449.117 1.006-.29 1.298l-.098.062-9 5c-.265.147-.579.165-.856.055l-.116-.055-9-5c-.482-.268-.656-.877-.388-1.36.249-.448.792-.63 1.254-.439l.106.05L11 18.856l8.514-4.73c.449-.248 1.006-.116 1.298.29zm0-4l.062.098c.25.449.117 1.006-.29 1.298l-.098.062-9 5c-.265.147-.579.165-.856.055l-.116-.055-9-5c-.482-.268-.656-.877-.388-1.36.249-.448.792-.63 1.254-.439l.106.05L11 14.856l8.514-4.73c.449-.248 1.006-.116 1.298.29zm-10.298-9.29c.302-.168.67-.168.972 0l9 5c.685.38.685 1.367 0 1.748l-9 5c-.302.168-.67.168-.972 0l-9-5c-.685-.38-.685-1.367 0-1.748zM11 3.144L4.059 7 11 10.855 17.94 7 11 3.144z" />
    </SvgIcon>
  );
};

IconPipelines.propTypes = simpleIconPropsTypes;
