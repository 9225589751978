import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import test from '../../components/assests/logo.svg';
import Avatar from '../../components/common/Cards/Avatar';
import { Paper } from '../../components/common/Containers';
import { SpacingContainer } from '../../components/common/Containers/Base';
import Button from '../../components/common/Buttons/Button';
import Loader from '../../components/common/Loader';
import Typography from '../../components/common/Typography';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';
import { IconVendors } from '../../components/svg/Icons';
import { useTrans } from '../../services/i18n';
import connect from './connect';
import { ReactComponent as DeactivatedIcon } from './ico-blocked.svg';

import { Chip } from '../../components/common/Chip';

import { useHistory } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DetailsAnchorsHeader from '../../components/generics/DetailsAnchorsHeader';
import SectionScrollable from '../../components/common/Containers/SectionScrollable';

function OrgSettings({
  settings,
  getPublicProfile,
  getOrganizationInfo,
  getUsers,
  getAvatarDownloadUrl,
  getUserRole,
  setupAvatar,
  openRegisterOrgModal,
  openRegisterOrg,
  openAddFeatureInfoOrg,
  openAddFeatureInfoOrgModal,
  openAddUpdateOrgModal,
  openAddUpdateOrg,
  onChangePublicProfile,
  setPublicProfile,
  openPublicProfileOrgModal,
  openPublicProfileOrg,
}) {
  const { trans } = useTrans();
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  const getAvatar = React.useCallback(
    (id) => {
      getAvatarDownloadUrl(id)
        .then((url) => setupAvatar({ avatar: url }))
        .catch(console.warn);
    },
    [getAvatarDownloadUrl, setupAvatar]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([getOrganizationInfo()]).finally(() => {
      setLoading(false);
    });
  }, [getOrganizationInfo, getUsers]);

  useEffect(() => {
    if (settings && settings.active) {
      history.replace('/dashboard');
    }
  }, [settings]);

  return (
    <SpacingContainerBase>
      <Paper style={{ position: 'relative' }}>
        <Paper style={{ position: 'absolute', width: '100%', boxShadow: '0 16px 16px 0 rgba(0, 0, 0, 0.12)' }}>
          <SpacingContainer px={4} py={5}>
            <Grid container alignItems="center">
              {settings.orgName && settings.avatarId ? (
                <Avatar src={settings.avatarId || test} alt="-" />
              ) : (
                !loading && (
                  <Paper style={{ background: '#f1f3f9', padding: '1rem' }}>
                    <IconVendors />
                  </Paper>
                )
              )}

              <div>
                <Typography
                  style={{ marginLeft: '30px' }}
                  weight="400"
                  variant="h1Medium"
                  fontFamily="lexend"
                  align="center"
                >
                  {settings.orgName || 'Setup Your Organization'}
                </Typography>
                <div style={{ width: '100%', marginLeft: '30px', marginTop: '10px' }}>
                  {/* {settings.vendorClaimed ? (
                      <Chip
                        color="success"
                        label={
                          <>
                            <CheckCircleOutlineIcon style={{ fontSize: '16px', marginRight: '5px' }} />{' '}
                            {trans('registered-organization')}{' '}
                          </>
                        }
                      />
                    ) : (
                      <Chip label={trans('unregistered')} />
                    )} */}
                  <Chip color="error" label={trans('deactivated')} />
                </div>

                {/* <Button
                    style={{ position: 'absolute', right: '50px', top: '50px' }}
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => openAddUpdateOrg(true)}
                  >
                    {trans('edit')}
                  </Button> */}
              </div>
            </Grid>
          </SpacingContainer>
        </Paper>
        <Paper id="setup" style={{ background: '#4e5978', height: '100%', paddingTop: '210px', paddingBottom: '10px' }}>
          <div style={{ textAlign: 'center', paddingBottom: '16px' }}>
            <DeactivatedIcon />
          </div>
          <Typography
            variant="h4"
            weight="600"
            color="white"
            style={{ width: '100%', textAlign: 'center', padding: '0 20px 5px 0' }}
          >
            The organization has been deactivated by the USFCR administrator
          </Typography>
          <Typography variant="body1" color="white" style={{ width: '100%', textAlign: 'center', padding: '0 0' }}>
            Please contact support for more information
          </Typography>
          <div style={{ padding: '30px', textAlign: 'center' }}>
            {/* <Button variant="contained" color="success">
              {trans('contact-support')}
            </Button> */}
          </div>
        </Paper>
      </Paper>
    </SpacingContainerBase>
  );
}

export default connect(OrgSettings);
