import React from 'react';
import ListItemTextBase from '../../../../common/Containers/Base/ListItemTextBase';
import { ContextMenuListItem, Popover } from '../../../../common/Dialogs/Popover';
import styless from '../styles.module.css';
import CheckIcon from '@material-ui/icons/Check';
import { useDispatch } from 'react-redux';
import { application } from '../../../../../services/application';
import * as API from '../../../../../constants/api';
import { subscriptionActions } from '../../../../../store/subscription';
import { orgActions } from '../../../../../store/orgSettings';
import { SUBSCRIPTION_STATUS } from '../../../../../utils/constants';
import { useSubscriptionStatus } from '../../../../../hooks/useSubscriptionStatus';
import { useHistory } from 'react-router-dom';

const items = [
  {
    label: 'Limited',
    id: 'limited',
    status: [SUBSCRIPTION_STATUS.NOT_SUBSCRIBED, SUBSCRIPTION_STATUS.EXPIRED],
  },
  {
    label: 'Advantage',
    id: 'advantage',
    status: [SUBSCRIPTION_STATUS.SUBSCRIBED],
  },
  {
    label: 'Pro',
    id: 'pro',
    status: [SUBSCRIPTION_STATUS.SUBSCRIBED],
  },
  {
    label: 'SAP Advantage',
    id: 'sapAdvantage',
    status: [SUBSCRIPTION_STATUS.SUBSCRIBED],
  },
  {
    label: 'APP Max',
    id: 'appMax',
    status: [SUBSCRIPTION_STATUS.SUBSCRIBED],
  },
  {
    label: 'Procurement Pro',
    id: 'procurementPro',
    status: [SUBSCRIPTION_STATUS.SUBSCRIBED],
  },
];

// Development only
const useChangeStatus = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const changeStatus = (params) => () => {
    return application
      .call(API.ORGANIZATION.UPDATE_SUBSCRIPTION_STATUS_TEST_ONLY, {
        ...params,
      })
      .then(() => {
        history.go(0);
        dispatch(subscriptionActions.fetch());
        dispatch(orgActions.getOrganizationInfoAction());
      });
  };

  return {
    changeStatus,
  };
};

const ChangeOrgStatus = React.memo(({ anchorEl, subscription }) => {
  const { changeStatus } = useChangeStatus();

  const { data } = useSubscriptionStatus(subscription);
  const [pop, setPop] = React.useState(null);
  const open = Boolean(pop);
  const handleClick = (event) => {
    setPop(event.currentTarget);
  };

  const handleClose = () => {
    setPop(null);
  };
  return (
    <ContextMenuListItem button>
      <ListItemTextBase onClick={handleClick} style={{ whiteSpace: 'nowrap' }}>
        {'Switch Organization Membership (Test)'} {'>'}{' '}
      </ListItemTextBase>
      <Popover
        className={styless.pop}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ padding: '10px', position: 'relative' }}>
          {items.map((item, i) => (
            <ContextMenuListItem key={i} button onClick={changeStatus(item)}>
              <ListItemTextBase className={styless.item}>
                {item.id === data?.currentPlan && <CheckIcon className={styless.itemIcon} />} <span>{item.label}</span>
              </ListItemTextBase>
            </ContextMenuListItem>
          ))}
        </div>
      </Popover>
    </ContextMenuListItem>
  );
});

export default ChangeOrgStatus;
