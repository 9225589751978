import * as Yup from 'yup';
import makeValidationSchema from '../../../../../hooks/makeValidationSchema';

export const initialValues = {
  tags: [],
};

export const getInitialValues = () => {
  return Object.assign({}, initialValues);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    tags: Yup.array().required(trans('required-field')).min(1),
  })
);
