import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconClock = (props) => {
  const { width = 15, height = 15, className } = props;

  return (
    <SvgIcon width={width} height={height} className={className} viewBox="0 0 15 15">
      <path
        d="M7.492 0C11.64 0 15 3.36 15 7.5c0 4.14-3.36 7.5-7.508 7.5C3.353 15 0 11.64 0 7.5 0 3.36 3.353 0 7.492 0zM7.5 1.5c-3.315 0-6 2.685-6 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6zm.375 2.25v3.938L11.25 9.69l-.563.923L6.75 8.25v-4.5h1.125z"
        transform="translate(-906 -251) translate(680 120) translate(0 68) translate(219 57) translate(7 4) translate(0 2)"
      />
    </SvgIcon>
  );
};

IconClock.propTypes = simpleIconPropsTypes;
