import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconTriage = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M10 9c0-2.68 1.167-3.926 3.76-3.997L14 5h5.584l-1.291-1.293c-.36-.36-.388-.928-.083-1.32l.083-.094c.36-.36.928-.388 1.32-.083l.094.083 3 3c.36.36.388.928.083 1.32l-.083.094-3 3c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L19.584 7H14c-.118 0-.23.002-.335.006l-.297.018c-.93.084-1.26.414-1.344 1.344l-.018.297C12.002 8.77 12 8.882 12 9c0 1.195-.524 2.268-1.355 3 .83.732 1.355 1.805 1.355 3 0 .118.002.23.006.335l.018.297c.084.93.414 1.26 1.344 1.344l.297.018L14 17h5.584l-1.291-1.293c-.36-.36-.388-.928-.083-1.32l.083-.094c.36-.36.928-.388 1.32-.083l.094.083 3 3c.36.36.388.928.083 1.32l-.083.094-3 3c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L19.584 19H14c-2.761 0-4-1.239-4-4 0-1.054-.816-1.918-1.85-1.995L8 13H2c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L2 11h6c1.105 0 2-.895 2-2z"
        transform="translate(-43.000000, -274.000000) translate(20.000000, 254.000000) translate(23.000000, 20.000000)"
      />
    </SvgIcon>
  );
};

IconTriage.propTypes = simpleIconPropsTypes;
