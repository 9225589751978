import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import IconDropDown from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import usePopover from '../../../../hooks/usePopover';
import { useTrans } from '../../../../services/i18n';
import { BottomLeftPosition, ContextMenuListItem, Popover } from '../../Dialogs/Popover';
import Typography from '../../Typography';
import CheckBox from './CheckBox';

export default function SelectAllMenu({
  onCheckAll,
  onCheckRead,
  onCheckUnread,
  onRemoveAllChecked,
  children,
  isSomeChecked = false,
  isAllChecked = false,
}) {
  const { trans } = useTrans();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();

  return (
    <Grid container alignItems="flex-start" wrap="nowrap" spacing={1} style={{ width: '4rem' }}>
      <Grid item>
        <CheckBox
          dense
          color="secondary"
          checked={isAllChecked || isSomeChecked}
          indeterminate={isSomeChecked && !isAllChecked}
          onChange={isAllChecked || isSomeChecked ? onRemoveAllChecked : onCheckAll}
        />
      </Grid>

      <IconButton size="small" onClick={onOpenPopover}>
        <IconDropDown />
      </IconButton>

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover} {...BottomLeftPosition}>
        <ContextMenuListItem button onClick={onCheckAll} onClickCB={onClosePopover} size="normal">
          <Typography variant="body2" color="primary" weight="500" noWrap>
            {trans('SelectAll')}
          </Typography>
        </ContextMenuListItem>
        <ContextMenuListItem button onClick={onRemoveAllChecked} onClickCB={onClosePopover} size="normal">
          <Typography variant="body2" color="primary" weight="500" noWrap>
            {trans('RemoveAll')}
          </Typography>
        </ContextMenuListItem>

        {onCheckRead && (
          <ContextMenuListItem button onClick={onCheckRead} onClickCB={onClosePopover} size="normal">
            <Typography variant="body2" color="primary" weight="500" noWrap>
              {trans('SelectRead')}
            </Typography>
          </ContextMenuListItem>
        )}

        {onCheckUnread && (
          <ContextMenuListItem button onClick={onCheckUnread} onClickCB={onClosePopover} size="normal">
            <Typography variant="body2" color="primary" weight="500" noWrap>
              {trans('SelectUnread')}
            </Typography>
          </ContextMenuListItem>
        )}

        {children}
      </Popover>
    </Grid>
  );
}
