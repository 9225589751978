import React, { useEffect } from 'react';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';
import connect from './connect';
import Loader from '../../components/common/Loader';
import Plans from './components/Plans';

function PrivatePlans({ getProducts, pending }) {
  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <SpacingContainerBase>
      {pending && <Loader open />}
      <Plans />
    </SpacingContainerBase>
  );
}

export default connect(PrivatePlans);
