import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import React from 'react';

const styles = (theme) => ({
  root: {
    padding: '5px 25px',
    '&.normal': { padding: '8px 25px' },
    '&.small': { padding: '5px 8px' },
  },
  divider: {
    margin: '15px 0 10px',
    padding: 0,
    borderBottom: `1px solid ${theme.palette.gray.divider}`,
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.action.hoverSecondary,
      color: theme.palette.text.primary,
    },
  },
});

class ListItemClass extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleClickWithCb = this.handleClickWithCb.bind(this);
  }

  handleClickWithCb(e) {
    this.props.onClick(e);
    this.props.onClickCB(e);
  }

  render() {
    const { onClickCB, onClick, size, className, ...rest } = this.props;

    return (
      <ListItem
        className={clsx(className, size)}
        onClick={typeof onClickCB === 'function' && typeof onClick === 'function' ? this.handleClickWithCb : onClick}
        {...rest}
      />
    );
  }
}

export const ContextMenuListItem = withStyles(styles)(ListItemClass);
