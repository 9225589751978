import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useTrans } from '../../../../services/i18n';
import { emptyArray } from '../../../../utils/arrayUtils';
import { Button } from '../../Buttons';
import { Chip } from '../../Chip';
import { Paper } from '../../Containers';
import Typography from '../../Typography';
import InputFile from './InputFile';
import useUploadFiles from './useUploadFiles';

export default function UploadFilesField({
  label,
  value,
  onChange,
  placeholder,
  name = 'files',
  multiple = false,
  error = '',
}) {
  const { trans } = useTrans();
  const styles = useStyles();
  const displayValues = multiple && Array.isArray(value) ? value : value ? [value] : [];

  const { handleInputChange, handleDelete } = useUploadFiles({ value, name, onChange, multiple });

  return (
    <>
      {label && (
        <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
          {label}
        </Typography>
      )}

      <Grid container wrap="nowrap">
        <Grid item xs>
          <Paper
            bgColor="paperSecondary"
            radius={4}
            className={styles.paper}
            component={Grid}
            container
            alignItems="center"
          >
            {!emptyArray(displayValues) ? (
              displayValues.map((item, ind) => (
                <Chip
                  key={ind}
                  label={item.name}
                  color="white"
                  square
                  onDelete={handleDelete.bind(null, ind)}
                  indented
                />
              ))
            ) : (
              <Typography variant="body2" color="placeholder" className={styles.placeholder}>
                {placeholder || trans('NoFilesAdded')}
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" size="large" component="label">
            {trans('Browse')}
            <InputFile multiple onChange={handleInputChange} />
          </Button>
        </Grid>
      </Grid>

      {error && (
        <Typography variant="caption" color="error" component="p" className={styles.error}>
          {error}
        </Typography>
      )}
    </>
  );
}

const useStyles = makeStyles({
  paper: {
    minHeight: 52,
    padding: '7px 10px',
  },
  placeholder: {
    height: '100%',
    paddingLeft: 13,
    paddingBottom: 1,
  },
  error: { marginTop: 6 },
});
