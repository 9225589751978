import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconStar = (props) => {
  const { width = 24, height = 24, filled = false, fullSize = false } = props;

  return fullSize ? (
    <SvgIcon width={width} height={height} viewBox="0 0 15 15">
      <path
        d="M9.588 8.879l-3.98.564-.09.018c-.516.138-.702.793-.3 1.174l2.874 2.715-.677 3.833-.01.084c-.03.525.539.905 1.033.653L12 16.103l3.562 1.817.079.035c.5.187 1.04-.233.944-.772l-.678-3.833 2.875-2.715.061-.066c.339-.409.102-1.047-.45-1.126L14.41 8.88l-1.778-3.494c-.261-.513-1.005-.513-1.266 0L9.588 8.879z"
        transform="translate(-5, -4)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      {filled ? (
        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z" />
      ) : (
        <path d="M9.588 8.879l-3.98.564-.09.018c-.516.138-.702.793-.3 1.174l2.874 2.715-.677 3.833-.01.084c-.03.525.539.905 1.033.653L12 16.103l3.562 1.817.079.035c.5.187 1.04-.233.944-.772l-.678-3.833 2.875-2.715.061-.066c.339-.409.102-1.047-.45-1.126L14.41 8.88l-1.778-3.494c-.261-.513-1.005-.513-1.266 0L9.588 8.879z" />
      )}
    </SvgIcon>
  );
};

IconStar.propTypes = simpleIconPropsTypes;
