import React from 'react';
import { useSubscriptionStatus } from '../../../../hooks/useSubscriptionStatus';
import { SUBSCRIPTION_STATUS } from '../../../../utils/constants';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SpacingContainer, Paper } from '../../Containers';
import { useTrans } from '../../../../services/i18n';
import connect from '../connect';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import premiumIcon from '../../../assests/premium-icon.png';
import Typography from '../../Typography';
import IconButton from '@material-ui/core/IconButton';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE_PLANS_PRIVATE } from '../../../../constants';
import { useComponentPermission } from '../../../../hooks/useComponentPermission';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#112159',
    marginBottom: '30px',
    borderRadius: '12px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    padding: 5,
  },
  containerText: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
  },
  arrowButton: {
    marginLeft: '5%',
  },
  iconPublic: {
    color: '#ffffff',
    width: '20px',
    height: '18px',
  },
});

const LargeBanner = ({ subscription, showSmallText = true, getPlans, plans, orgSettings, componentFeature }) => {
  const { pending, data } = useSubscriptionStatus(subscription);
  const { trans } = useTrans();
  const classes = useStyles();
  const history = useHistory();

  const { hasPermission } = useComponentPermission(componentFeature);

  if (
    (pending || [SUBSCRIPTION_STATUS.SUBSCRIBED, SUBSCRIPTION_STATUS.ACTIVE].includes(data?.status)) &&
    hasPermission
  ) {
    return null;
  }

  const onLinkClick = (e) => {
    history.push(ROUTE_PLANS_PRIVATE.path);
  };

  return (
    <React.Fragment>
      <Link to={{ pathname: '', state: { plans: plans } }} onClick={onLinkClick} style={{ textDecoration: 'none' }}>
        <Paper component={SpacingContainer} px={1} py={1} className={classes.container}>
          <SpacingContainer className={classes.content}>
            <div className={classes.containerText}>
              <img src={premiumIcon} alt="" />
              <Typography variant="subtitle2" color="white" weight="500" fontFamily="lexend">
                {trans('usfcr-plan')}
              </Typography>
              {showSmallText && (
                <Typography variant="body2" color="white">
                  {trans('Upgrade your Plan To Get Full Access')}
                </Typography>
              )}
            </div>
            <IconButton size="small" className={classes.arrowButton}>
              <ArrowForwardIos className={classes.iconPublic} />
            </IconButton>
          </SpacingContainer>
        </Paper>
      </Link>
    </React.Fragment>
  );
};

export default connect(LargeBanner);
