import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconClose = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M8.613 7.21l.094.083L12 10.585l3.293-3.292c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094L13.415 12l3.292 3.293c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083L12 13.415l-3.293 3.292c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L10.585 12 7.293 8.707c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083z"
        transform="translate(-1020 -224) translate(370 198) translate(650 26)"
      />
    </SvgIcon>
  );
};

IconClose.propTypes = simpleIconPropsTypes;
