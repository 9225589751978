import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { SCOPE_NOT_INTERESTING } from '../../constants';
import { NOT_INTERESTING } from '../../constants/api';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE } from '../helpers/common';
import { extendBuilderWithListActions, generateListActions } from '../helpers/listActionsHelpers';
import { onPending, onReject, onSuccess } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE,
  order: ['updatedAt', 'desc'],
};

const listActions = generateListActions({
  scope: SCOPE_NOT_INTERESTING,
  apiMethod: {
    GET_LIST: NOT_INTERESTING.GET_LIST,
  },
  getStore: (store) => store.listOfNotInterested,
});

const removeFromNotInterestedAction = createAsyncThunk('listOfNotInterested/removeFromNotInterested', async (id) =>
  application.call(NOT_INTERESTING.DELETE, { id })
);

export const actions = {
  ...listActions,
  removeFromNotInterestedAction,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendBuilderWithListActions(builder, actions)
    .addCase(removeFromNotInterestedAction.pending, onPending)
    .addCase(removeFromNotInterestedAction.rejected, onReject)
    .addCase(removeFromNotInterestedAction.fulfilled, onSuccess);
});
