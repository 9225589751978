import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_VENDORS } from '../../constants';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common';
import { extendListBuilder, generateListActions, generateSearchActions } from '../helpers/listActionsHelpers';
import { onPending, onPendingDone } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['relevance', 'desc'],
};

const listActions = generateListActions({
  scope: SCOPE_VENDORS,
  apiMethod: {
    GET_LIST: API.VENDORS.GET_LIST,
    EXPORT_CSV: API.VENDORS.EXPORT_CSV,
  },
  getStore: (store) => store.vendors,
});

const searchActions = generateSearchActions({
  scope: SCOPE_VENDORS,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.vendors,
});

const addToFavorite = createAsyncThunk('vendors/toggleFavorite', (params) => {
  return application.call(API.VENDORS.TOGGLE_FAVORITE, params);
});

const getAllNaics = createAsyncThunk('vendors/getAllNaics', (params) => {
  return application.call(API.VENDORS.GET_ALL_NAICS, params);
});

function onAddToFavorite(state, action) {
  //changed vendorId to ueiCode
  const vendor = state.items.find((item) => item.ueiCode === action.payload.id);
  if (vendor) {
    vendor.isPotentialPartner = action.payload.isPotentialPartner;
  }

  return Object.assign(state, { pending: false });
}

export const actions = {
  ...listActions,
  ...searchActions,
  addToFavorite,
  getAllNaics,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, actions)
    .addCase(addToFavorite.fulfilled, onAddToFavorite)
    .addCase(addToFavorite.pending, onPending)
    .addCase(addToFavorite.rejected, onPendingDone);
});
