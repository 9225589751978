export const ANCHORS = [
  { id: 'summary', i18nKey: 'summary' },
  { id: 'reports', i18nKey: 'reports' },
  { id: 'popopp', i18nKey: 'popular-opportunities' },
  { id: 'funding-analys', i18nKey: 'funding-analysis' },
  /*{ id: 'idvs', i18nKey: 'contract-idvs' },*/
  { id: 'awards', i18nKey: 'contract-awards' },
  { id: 'fco', i18nKey: 'contract-opportunities' },
];

export const orderOptions = [
  { name: 'alphabetical', value: 'alphabetical', asc: 'A-Z', desc: 'Z-A' },
  {
    name: 'relevance',
    value: 'relevance',
    desc: 'most',
  },
];

export function markFavCodes(payloadItems, payloadFavorites) {
  const favorites = new Map(payloadFavorites.map((fav) => [fav.code, true]));
  return payloadItems.map((item) => {
    item.isFavorite = item.isFavorite || favorites.has(item.code);
    return item;
  });
}

export function markAllAsFavorite(payloadItems) {
  return payloadItems.map((item) => {
    item.isFavorite = true;
    return item;
  });
}
