import { createAsyncThunk, createAction, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_QUOTE_REQUESTS } from '../../constants';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common';
import { extendListBuilder, generateListActions } from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
};

//Actions
const listActions = generateListActions({
  scope: SCOPE_QUOTE_REQUESTS,
  apiMethod: {
    GET_LIST: API.QUOTE_REQUESTS.GET,
  },
  getStore: (store) => store.quoteRequests,
});

export const actions = {
  ...listActions,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, actions);
});
