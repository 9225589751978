import Grid from '@material-ui/core/Grid';
import React from 'react';
import SpacingContainer from '../../../common/Containers/Base/SpacingContainer';
import Paper from '../../../common/Containers/Paper';
import Typography from '../../../common/Typography';

const TITLE_PROPS = {
  small: { variant: 'h4', weight: 600 },
  medium: { variant: 'h2', weight: 600, gutter: 'bottom2' },
  large: { variant: 'h1Medium', weight: 300, gutter: 'title' },
  largeBold: { variant: 'h1Medium', weight: 500, gutter: 'bottom2' },
};

export default function NothingFoundCard({ title, description, Icon, children, px = 2, py = 2, size = 'small' }) {
  const titleProps = TITLE_PROPS[size] || TITLE_PROPS['small'];

  return (
    <Paper component={SpacingContainer} px={px} py={py}>
      <Grid container alignItems="center" justifyContent="center" alignContent="center" direction="column">
        {Icon && (
          <Typography color="hint" gutter="bottom2" align="center" component="div">
            {Icon}
          </Typography>
        )}

        {title && (
          <Typography {...titleProps} component="h5" fontFamily="lexend" align="center">
            {title}
          </Typography>
        )}

        {description && (
          <Typography variant="body1" color="hint" gutter={children ? 'bottomSpace' : undefined} align="center">
            {description}
          </Typography>
        )}

        {children}
      </Grid>
    </Paper>
  );
}
