import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTrans } from '../../../services/i18n';
import Button from '../../common/Buttons/Button';
import { Paper, SpacingContainer } from '../../common/Containers';
import { ModalDialog } from '../../common/Dialogs/ModalDialog';
import Typography from '../../common/Typography';
import { IconRemove } from '../../svg/Icons/IconRemove';

export default function ConfirmDialog({
  open,
  onConfirm,
  onClose,
  icon,
  children,
  pending = false,
  title = '',
  text = '',
  textStyles,
  cancelTitle = '',
  confirmTitle = '',
  buttonColor = 'secondary',
  withOutBackground = false,
  noIcon = false,
  noText = false,
  switchButtons = false,
  varianConfirm = 'contained',
  variantCancel = 'outlined',
  grayLeftText = false,
  note = '',
  noteStyles = {},
  direction = 'down',
}) {
  const { trans } = useTrans();

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={title || trans('confirm')}
      direction={direction}
      actions={
        switchButtons ? (
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant={varianConfirm}
                color={buttonColor}
                disabled={!onConfirm}
                pending={pending}
                onClick={onConfirm}
              >
                {confirmTitle || trans('confirm')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant={variantCancel} color="primary" onClick={onClose}>
                {cancelTitle || trans('cancel')}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button variant={variantCancel} color="primary" onClick={onClose}>
                {cancelTitle || trans('cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={varianConfirm}
                color={buttonColor}
                disabled={!onConfirm}
                pending={pending}
                onClick={onConfirm}
              >
                {confirmTitle || trans('confirm')}
              </Button>
            </Grid>
          </Grid>
        )
      }
    >
      {!noIcon && (
        <SpacingContainer className="center" py={3}>
          {icon ? icon : <IconRemove thin />}
        </SpacingContainer>
      )}

      {!noText &&
        (grayLeftText ? (
          <Typography align="left" color="hint" weight="600" gutter="bottomSpace" style={textStyles}>
            {text || trans('ConfirmAction')}
          </Typography>
        ) : (
          <Typography align="center" weight="600" gutter="bottomSpace" style={textStyles}>
            {text || trans('ConfirmAction')}
          </Typography>
        ))}

      {note && (
        <Typography align="center" weight="bold" gutter="bottomSpace" style={noteStyles}>
          {note}
        </Typography>
      )}

      {!!children && (
        <Paper
          bgColor={withOutBackground ? '' : 'paperSecondary'}
          component={SpacingContainer}
          py={2}
          px={2}
          className="center"
        >
          {children}
        </Paper>
      )}
    </ModalDialog>
  );
}
