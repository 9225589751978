import { Grid } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Typography from '../../../components/common/Typography';
import { useTrans } from '../../../services/i18n';
import CodeInput from '../../../components/common/Inputs/CodeInput';
import { Button } from '../../../components/common/Buttons';
import { Divider } from '../../../components/common/Divider';
import connect from '../connect';
import usePhoneInput from '../../../hooks/usePhoneInput';
import { useMediaQuery } from '@material-ui/core';

const SetPhone = ({ auth, onClose, updatePhone, updatePhoneVerified }) => {
  const { trans } = useTrans();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const {
    phone,
    phoneVerified,
    sentCode,
    resendPending,
    code,
    pending,
    newPhone,
    resendCode,
    setCode,
    setPhone,
    handleSetPhone,
  } = usePhoneInput({ auth, updatePhone, onClose, updatePhoneVerified });

  const getPhoneToShow = () => {
    if (auth?.phoneVerified === 'true' || auth?.phoneVerified === true) {
      return phone;
    }
    if ((auth?.phoneVerified === 'false' || auth?.phoneVerified === false) && sentCode === false) {
      return '';
    }
    if ((auth?.phoneVerified === 'false' || auth?.phoneVerified === false) && sentCode === true) {
      return newPhone;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutter="label" variant={isMobile ? 'body2' : 'h6'} component="p" weight="bold" color="label">
          {trans('enter-phone')}
        </Typography>
        <div style={{ position: 'relative' }}>
          <PhoneInput
            defaultCountry="US"
            international
            placeholder="Enter phone number"
            // value={(auth?.phoneVerified === 'true' || auth?.phoneVerified === true || sentCode === true) && phone}
            value={getPhoneToShow()}
            onChange={setPhone}
            style={{ background: '#f1f3f9', padding: '10px', borderRadius: '3px', fontFamily: 'Lexend' }}
          />
          {sentCode && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ position: 'absolute', right: '10px', top: '11px' }}
              pending={resendPending}
              onClick={resendCode}
            >
              {trans('resend-code')}
            </Button>
          )}
        </div>
        {sentCode && (
          <>
            <Divider light spacing={2} />
            <Typography variant={isMobile ? 'body2' : 'h6'} component="p" weight="bold" color="label">
              {trans('step-verification')}
            </Typography>
            <Typography variant={isMobile ? 'body2' : 'h6'} component="p" color="label" gutter="bottom2">
              {trans('please-verify-code-phone-registered')}
            </Typography>

            <Typography gutter="label" variant={isMobile ? 'body2' : 'h6'} component="p" weight="bold" color="label">
              {trans('enter-code')}
            </Typography>
            <CodeInput fields={6} type="number" onChange={(e) => setCode(e)} />
          </>
        )}
        <Divider light spacing={3} />
        <div style={{ textAlign: 'right' }}>
          {sentCode ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSetPhone}
              pending={pending}
              disabled={!newPhone || code.length < 6 || pending}
            >
              {trans('verify')}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSetPhone}
              pending={pending}
              disabled={(!newPhone || newPhone === phone || pending) && (phoneVerified === true || phoneVerified === 'true')}
            >
              {trans('confirm')}
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default connect(SetPhone);
