import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import palette from '../../../themes/theme/pallete';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

const useStyles = makeStyles({
  root: {
    transition: 'transform 0.25s ease-in-out',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
});

export const IconSortingSmall = (props) => {
  const { width = 24, height = 24, down = false } = props;
  const styles = useStyles();

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" className={clsx(styles.root, down && styles.rotate)}>
      <circle cx="12" cy="12" r="10" fill={palette.gray.background} />
      <path
        fill={palette.gray.main}
        fillRule="nonzero"
        d="M11.293 9.293c.36-.36.928-.388 1.32-.083l.094.083 3 3c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083L12 11.415l-2.293 2.292c-.36.36-.928.388-1.32.083l-.094-.083c-.36-.36-.388-.928-.083-1.32l.083-.094 3-3z"
      />
    </SvgIcon>
  );
};

IconSortingSmall.propTypes = simpleIconPropsTypes;
