import MUIPaper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  forwardScroll: { height: '100%', overflow: 'hidden' },
  hidden: { overflow: 'hidden' },

  'radius-0': { borderRadius: 0 },
  'radius-4': { borderRadius: 4 },
  'radius-8': { borderRadius: 8 },
  'radius-20': { borderRadius: 20 },

  default: { backgroundColor: theme.palette.background.default },
  transparent: { backgroundColor: 'transparent' },
  primary: { backgroundColor: theme.palette.primary.dark },
  primaryTabs: { backgroundColor: theme.palette.primary.tabs, color: theme.palette.common.white },
  gray: { backgroundColor: theme.palette.gray.main },
  warning: { backgroundColor: theme.palette.warning.fade },
  paperForm: { backgroundColor: theme.palette.background.paperForm },
  paperSecondary: { backgroundColor: theme.palette.gray.background },
  active: { backgroundColor: theme.palette.gray.paperActive },
  success: { backgroundColor: theme.palette.success.main, color: theme.palette.common.white },
  error: { backgroundColor: theme.palette.error.main, color: theme.palette.common.white },
  info: { backgroundColor: theme.palette.info.main, color: theme.palette.common.white },
  pipeline: { backgroundColor: theme.palette.pipeline.main, color: theme.palette.common.white },
  pipelineLight: { backgroundColor: theme.palette.pipeline.light, color: theme.palette.common.white },
  pipelineDark: { backgroundColor: theme.palette.pipeline.dark, color: theme.palette.common.white },

  'hover-default': { '&:hover': { backgroundColor: theme.palette.background.default } },
  'hover-transparent': { '&:hover': { backgroundColor: 'transparent' } },
  'hover-primary': { '&:hover': { backgroundColor: theme.palette.primary.dark } },
  'hover-paperForm': { '&:hover': { backgroundColor: theme.palette.background.paperForm } },
  'hover-paperSecondary': { '&:hover': { backgroundColor: theme.palette.gray.background } },
  'hover-active': { '&:hover': { backgroundColor: theme.palette.gray.paperActive } },
  'hover-info': { '&:hover': { backgroundColor: theme.palette.info.main } },

  'fade-primary': { backgroundColor: theme.palette.primary.fade },
  'fade-secondary': { backgroundColor: theme.palette.secondary.fade },
  'fade-success': { backgroundColor: theme.palette.success.fade },
  'fade-warning': { backgroundColor: theme.palette.warning.fade },
  'fade-error': { backgroundColor: theme.palette.error.fade },
  'fade-info': { backgroundColor: theme.palette.info.fade },
  'fade-gray': { backgroundColor: theme.palette.gray.fade },
}));

export default React.forwardRef(
  (
    { children, className, hidden, bgColor, bgColorFade, bgColorHover, forwardScroll, radius, elevation = 0, ...rest },
    ref
  ) => {
    const styles = useStyles();
    return (
      <MUIPaper
        ref={ref}
        className={clsx(
          className,
          forwardScroll && styles.forwardScroll,
          hidden && styles.hidden,
          radius && styles[`radius-${radius}`],
          bgColorFade ? styles[`fade-${bgColorFade}`] : bgColor && styles[bgColor],
          bgColorHover && styles[`hover-${bgColorHover}`]
        )}
        elevation={elevation}
        {...rest}
      >
        {children}
      </MUIPaper>
    );
  }
);
