import React from 'react';
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Switch } from '../../../components/common/Inputs';
import { useState } from 'react';
import connect from '../connect';
import { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatNumber } from '../../../utils/numberUtils';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles({
  containerHeadPlans: {
    padding: '0px 10px 10px 0px',
    borderRadius: '8px 8px 0 0 !important',
    webkitBorderRadius: '8px 8px 0 0 !important',
    mozBorderRadius: '8px 8px 0 0 !important',
    msBorderRadius: '8px 8px 0 0 !important',
    oBorderRadius: '8px 8px 0 0 !important',
  },
});

const SwitchPlan = ({ getPlans, subscription, setCurrentModalityPlan, plans }) => {
  const styles = useStyles();
  const [modality, setModality] = useState(true);
  const [pending] = useState(false);

  let [price, setPrice] = useState(0);
  const changeModality = (ev) => {
    setModality(ev.target.checked);
  };

  useEffect(() => {
    const mod = modality ? 'month' : 'year';
    const p = plans[0]?.prices?.find((p) => p.recurring.interval === mod);
    setPrice(p);
  }, [modality, plans]);

  return (
    <>
      <Card elevation={0} className={styles.containerHeadPlans}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item style={{ color: '#888FA9' }}>
            <AttachMoneyIcon /> {`USD ${formatNumber(price?.unit_amount)}/${modality ? 'month' : 'year'} payment plan`}
          </Grid>
          {!pending && (
            <Grid item>
              <Switch
                color="primary"
                size="medium"
                disabled={subscription && subscription.cancel}
                onChange={(ev) => changeModality(ev)}
                label={modality ? 'Monthly' : 'Yearly'}
                checked={modality}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default connect(SwitchPlan);
