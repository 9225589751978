import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_OVERVIEW, ROUTE_FORMS, ROUTE_DOCUMENTS, ROUTE_SUPPORT_TICKET } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const Overview = React.lazy(() => import('./Overview'));
const Forms = React.lazy(() => import('../Forms'));
const Documents = React.lazy(() => import('./Documents'));
const Tickets = React.lazy(() => import('./Tickets'));

export default function PipelinesWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_OVERVIEW.path}>
          <Overview />
        </Route>
        <Route exact path={ROUTE_FORMS.path}>
          <Forms />
        </Route>
        <Route exact path={ROUTE_DOCUMENTS.path}>
          <Documents />
        </Route>
        <Route exact path={ROUTE_SUPPORT_TICKET.path}>
          <Tickets />
        </Route>
        <Redirect exact path="/*" to={ROUTE_OVERVIEW.path} />
      </Switch>
    </Suspense>
  );
}
