import Grid from '@material-ui/core/Grid';
import ModalDialog from '../../../../components/common/Dialogs/ModalDialog';
import SaveCancelDialogActions from '../../../../components/common/Dialogs/SaveCancelDialogActions';
import Typography from '../../../../components/common/Typography';
import { useTrans } from '../../../../services/i18n';

function InfoAddSeatsModal({ open = false, onClose }) {
  const { trans } = useTrans();
  return (
    <ModalDialog open={open} onClose={onClose} title={trans('Information')}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography weight="500" fontFamily="lexend" variant="h5">
            In order to add more users to your organization you must get more seats
          </Typography>
        </Grid>
      </Grid>
    </ModalDialog>
  );
}

export default InfoAddSeatsModal;
