import Grid from '@material-ui/core/Grid';
import React from 'react';
import { SpacingContainer } from '../../../../../../components/common/Containers';
import Typography from '../../../../../../components/common/Typography';
import { useTrans } from '../../../../../../services/i18n';
import { emptyArray } from '../../../../../../utils/arrayUtils';
import DraggablePursuitItem from '../../PursuitItem';
import connect from './connect';
import { Droppable } from 'react-beautiful-dnd';

function PursuitList({ items, stageId, changePursuitStatus, deletePursuit }) {
  const { trans } = useTrans();

  return (
    <Droppable droppableId={stageId}>
      {(provided, snapshot) => {
        return (
          <SpacingContainer
            ref={provided.innerRef}
            px={1}
            py={1}
            className="forwardHeight"
            style={{ overflow: 'auto' }}
          >
            {!emptyArray(items) ? (
              <Grid container direction="column" spacing={1}>
                {items?.map((item, index) => (
                  <DraggablePursuitItem
                    key={item.id}
                    item={item}
                    index={index}
                    deletePursuit={deletePursuit}
                    changePursuitStatus={changePursuitStatus}
                  />
                ))}
              </Grid>
            ) : (
              <Grid container justifyContent="center" alignItems="center" className="forwardHeight">
                <Typography variant="caption" color="white" component="p">
                  {!snapshot.isDraggingOver && trans('DragAndDropPursuitsHere')}
                </Typography>
              </Grid>
            )}
            {provided.placeholder}
          </SpacingContainer>
        );
      }}
    </Droppable>
  );
}

export default connect(PursuitList);
