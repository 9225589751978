import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTE_STATE_LOCAL_CONTRACT_OPPORTUNITIES,
  ROUTE_STATE_LOCAL_CONTRACT_OPPORTUNITIES_DETAILS,
  SCOPE_STATE_LOCAL_CONTRACT_OPPORTUNITIES,
} from '../../constants';
import { AddToPipelineConsumer, AddToPipelineProvider } from '../../contexts/AddToPipelineContext';
import Suspense from '../../services/lazyLoad/Suspense';

const StateLocalContractOpportunities = React.lazy(() => import('./StateLocalContractOpportunities'));
const StateLocalContractOpportunitiesDetails = React.lazy(() => import('./StateLocalContractOpportunityDetails'));

export default function StateLocalContractOpportunitiesWrapper() {
  return (
    <Suspense>
      <AddToPipelineProvider scope={SCOPE_STATE_LOCAL_CONTRACT_OPPORTUNITIES}>
        <Switch>
          <Route exact path={ROUTE_STATE_LOCAL_CONTRACT_OPPORTUNITIES_DETAILS.path}>
            <StateLocalContractOpportunitiesDetails />
          </Route>
          <Route exact path={ROUTE_STATE_LOCAL_CONTRACT_OPPORTUNITIES.path}>
            <StateLocalContractOpportunities />
          </Route>

          <Redirect exact path="/*" to={ROUTE_STATE_LOCAL_CONTRACT_OPPORTUNITIES.path} />
        </Switch>

        <AddToPipelineConsumer />
      </AddToPipelineProvider>
    </Suspense>
  );
}
