import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_EXPORTS } from '../../constants';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE } from '../helpers/common';
import { extendBuilderWithListActions, generateListActions } from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE,
  stats: { monthlyLimit: 0, atOnceLimit: 0, current: 0, resetLimitDate: null },
  pagination: { page: 1, perPage: 10 },
  params: { scope: '' },
  order: ['createdAt', 'desc'],
  count: 0,
};

const listActions = generateListActions({
  scope: SCOPE_EXPORTS,
  apiMethod: {
    GET_LIST: API.EXPORTS.GET_LIST,
  },
  getStore: (store) => store.exports,
});

const changeCount = createAction(`${SCOPE_EXPORTS}/changeCount`);

const getStatsAction = createAsyncThunk(`${SCOPE_EXPORTS}/getStats`, (params) => {
  return application.call(API.EXPORTS.GET_STATS, params);
});
const onGetStats = (state, action) => {
  state.stats = action.payload;
};

export const actions = {
  ...listActions,
  getStatsAction,
  changeCount,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, actions);

  builder.addCase(getStatsAction.fulfilled, onGetStats);
  builder.addCase(changeCount, (state, action) => {
    state.count = action.payload || 0;
  });
});
