import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const NOTIFICATION_DEFAULT = 'NONE';
export const NOTIFICATION_OPTIONS = [
  { name: 'instant', value: 'INSTANT' },
  { name: 'daily', value: 'DAILY' },
  { name: 'weekly', value: 'WEEKLY' },
  { name: 'global', value: 'GLOBAL' },
  { name: 'none', value: 'NONE' },
];

export const initialValues = {
  title: '',
  withNotifications: false,
  notification: 'INSTANT',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item, {
    withNotifications: Boolean(item && item.notification !== NOTIFICATION_DEFAULT),
  });
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    title: Yup.string().required(trans('required-field')),

    withNotifications: Yup.bool().optional().default(false),
    notification: Yup.string()
      .when('withNotifications', (val, schema) =>
        val
          ? schema.oneOf(
              NOTIFICATION_OPTIONS.map((item) => item.value),
              trans('required-field')
            )
          : schema
      )
      .required(trans('required-field')),
  })
);
