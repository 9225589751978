import { Grid, Link, MenuItem, Select } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { Button } from '../../../components/common/Buttons';
import TextField from '../../../components/common/Inputs/TextField';
import Typography from '../../../components/common/Typography';
import { IconBack } from '../../../components/svg/Icons';
import { ROUTE_VERIFY, ROUTE_PLANS_PUBLIC } from '../../../constants';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import { useForm } from '../../../hooks/useForm';
import { useApplication } from '../../../services/application';
import { useTrans } from '../../../services/i18n';
import { catchYupErrors, schemaToInitialState } from '../../../utils/validationUtils';
import useStyles from '../styles';
import { TermsModal } from './components/TermsModal';
import AuthLayout from '../../../components/layouts/AuthLayout';
import SectionScrollable from '../../../components/common/Containers/SectionScrollable';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { CheckBox, SelectField } from '../../../components/common/Inputs';
import { TIERS } from '../../../utils/subscription';

const SignUp = () => {
  const classes = useStyles();
  const { trans } = useTrans();
  const [openTerms, onOpenTerms, onCloseTerms] = useBooleanHandlers();
  const [inputTypePassword, setInputTypePassword] = React.useState('password');
  const [inputTypeConfirmPassword, setInputTypeConfirmPassword] = React.useState('password');

  const application = useApplication();

  const history = useHistory();
  const defaultSelectedPlan = history.location?.state?.plan ?? TIERS.LIMITED;
  const availableTiers = Object.values(TIERS);

  useEffect(() => {
    // Redirect to the ROUTE_PLANS_PUBLIC path immediately
    history.push(ROUTE_PLANS_PUBLIC.path);
  }, [history]);

  const schema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(trans('empty-not-allowed', { field: trans('first-name') })),
        familyName: Yup.string().required(trans('empty-not-allowed', { field: trans('last-name') })),
        email: Yup.string()
          .email(trans('incorrect-email'))
          .required(trans('empty-not-allowed', { field: trans('email') })),
        password: Yup.string()
          .password(trans('incorrect-password'))
          .required(trans('empty-not-allowed', { field: trans('password') })),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password')], trans('passwords-match'))
          .required(trans('empty-not-allowed', { field: trans('confirm-password') })),
        acceptTerms: Yup.bool().oneOf([true], 'Field must be checked').required(),
        /*invitationCode: Yup.mixed()
          .oneOf(['ZG6Y'], 'Invalid invitation code')
          .required(trans('empty-not-allowed', { field: trans('invitation-code') }))*/
        orgName: Yup.string().required(trans('org-required')),
      }),
    [trans]
  );

  const handleSubmit = async (values, { setStatus, setErrors }) => {
    return application
      .call('user.createUser', { ...values, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then(() =>
        history.push(ROUTE_VERIFY.path, {
          firstName: values.firstName,
          familyName: values.familyName,
          email: values.email,
        })
      )
      .catch((e) => catchYupErrors(e, setErrors, (err) => setStatus(err.message)));
  };

  const formik = useForm(
    schema,
    { ...schemaToInitialState(schema), subscriptionPlan: defaultSelectedPlan },
    handleSubmit,
    {
      validateOnChange: false,
      validateOnBlur: false,
    }
  );

  const handleVisibilityPassword = () => {
    setInputTypePassword(inputTypePassword === 'password' ? 'text' : 'password');
  };

  const handleVisibilityConfirmPassword = () => {
    setInputTypeConfirmPassword(inputTypeConfirmPassword === 'password' ? 'text' : 'password');
  };

  const getTierRenderValue = (tier) => {
    if (tier === TIERS.FREE_TRIAL) {
      return trans('freeTrial-14-day');
    }

    return trans(tier);
  };

  return (
    <AuthLayout>
      <SectionScrollable>
        <TermsModal open={openTerms} onClose={onCloseTerms} />
        <div className={classes.formBack}>
          <Grid container spacing={0} justifyContent="center" className={classes.content}>
            <Grid item xs={12} className={classes.topHeader}>
              <IconButton edge="start" color="inherit" size="small" onClick={history.goBack}>
                <IconBack />
              </IconButton>
            </Grid>
            <Grid item xs={12} className={classes.signForm}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h1Medium" fontFamily="lexend" weight="500">
                    {trans('sign-up')}
                  </Typography>
                </Grid>
                {formik.status && (
                  <Grid item xs={12}>
                    <Typography color="error">{formik.status}</Typography>
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <TextField
                    label={trans('first-name')}
                    placeholder={trans('name')}
                    required
                    {...formik.getFieldProps('firstName')}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label={trans('last-name')}
                    placeholder={trans('last-name')}
                    {...formik.getFieldProps('familyName')}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={trans('your-email')}
                    placeholder={trans('email')}
                    {...formik.getFieldProps('email')}
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label={trans('password')}
                    type={inputTypePassword}
                    placeholder={trans('password')}
                    {...formik.getFieldProps('password')}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={handleVisibilityPassword}
                          position="end"
                          className={classes.cursorPointer}
                        >
                          {inputTypePassword === 'password' ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    type={inputTypeConfirmPassword}
                    label={trans('confirm-password')}
                    placeholder={trans('confirm-password')}
                    {...formik.getFieldProps('confirmPassword')}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={handleVisibilityConfirmPassword}
                          position="end"
                          className={classes.cursorPointer}
                        >
                          {inputTypeConfirmPassword === 'password' ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {/*<Grid item md={6} xs={12}>
                  <TextField
                    label={trans('invitation-code')}
                    placeholder={trans('code')}
                    {...formik.getFieldProps('invitationCode')}
                    required
                  />
                </Grid>*/}
                <Grid item xs={12}>
                  <TextField
                    label={trans('org-name')}
                    placeholder={trans('org-name')}
                    {...formik.getFieldProps('orgName')}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography weight={'bold'} variant="body2" color="label" gutter="label">
                    {trans('subscription-plan')}
                  </Typography>
                  <SelectField
                    getRenderValue={getTierRenderValue}
                    placeholder={trans('subscription-plan')}
                    {...formik.getFieldProps('subscriptionPlan')}
                    defaultValue={defaultSelectedPlan}
                  >
                    <MenuItem disabled value="">
                      <em>{trans('subscription-plan')}</em>
                    </MenuItem>
                    {availableTiers?.map((tier, index) => (
                      <MenuItem key={index} value={tier}>
                        {getTierRenderValue(tier)}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item md={6} xs={12} />
                <Grid item xs={12}>
                  <Typography variant="body2" weight="400">
                    <CheckBox
                      style={{ marginRight: '5px' }}
                      color="secondary"
                      {...formik.getFieldProps('acceptTerms')}
                    />
                    {trans('by-continuing')}
                    <Link onClick={onOpenTerms}>
                      <span>{trans('terms-of-service')}</span>
                    </Link>
                  </Typography>

                  {formik?.errors?.acceptTerms && (
                    <small style={{ color: '#ff5225' }}>Terms of service is required</small>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    id="create-an-account-btn"
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={formik.handleSubmit}
                    pending={formik.isSubmitting}
                  >
                    {trans('create-an-account')}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.flexHelper} />
            </Grid>
            <Grid item xs={12} className={classes.flexHelper} />
          </Grid>
        </div>
      </SectionScrollable>
    </AuthLayout>
  );
};

export default SignUp;
