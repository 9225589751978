import React, { useEffect, useMemo } from 'react';
import AuthLayout from '../../../components/layouts/AuthLayout';
import { ROUTE_PASSWORD_SUCCESS, ROUTE_DASHBOARD, ROUTE_MIGRATION } from '../../../constants';
import { AmplifyAuth, getAmplifyAuthData } from '../../../services/amplifyAuth';
import { catchYupErrors } from '../../../utils/validationUtils';
import SetPasswordForm from './SetPasswordForm';
import useQueryHistory from '../../../hooks/query/useQueryHistory';
import { useApplication } from '../../../services/application';
import { useAlertContext } from '../../../contexts/AlertContext';
import { ModalDialog } from '../../../components/common/Dialogs';
import { useTrans } from '../../../services/i18n';
import Grid from '@material-ui/core/Grid';
import { Button } from '../../../components/common/Buttons';
import { SpacingContainer } from '../../../components/common/Containers';
import Typography from '../../../components/common/Typography';

const safeDecodeURIComponent = (str) => {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    console.error('Error decoding component: ', e);
    return str;
  }
};

const extractCodeFromUrl = (url, query) => {
  if (query.invite) {
    const codeStart = url.indexOf('code=') + 5;
    const codeEnd = url.indexOf('&invite');
    return codeEnd !== -1 ? safeDecodeURIComponent(url.substring(codeStart, codeEnd)) : '';
  }
  return query.code ? safeDecodeURIComponent(query.code) : '';
};

const SetPassword = () => {
  const { history, query = {} } = useQueryHistory();

  const application = useApplication();
  const { errorAlert } = useAlertContext();
  const email = useMemo(() => query?.email.trim().replaceAll(' ', '+'), [query]);

  const code = useMemo(() => extractCodeFromUrl(window.location.href, query), [query]);

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { trans } = useTrans();

  const handleSubmit = React.useCallback(
    (values, { setStatus, setErrors }) => {
      setLoading(true);
      if (query.invite) {
        if (!(email && code)) return;
        AmplifyAuth.signIn(email, code.trim())
          .then((user) => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              AmplifyAuth.completeNewPassword(
                user, // the Cognito User Object
                values.password // the new password
              )
                .then(async (user) => {
                  const { name } = await application.authorize(getAmplifyAuthData(user));
                  if (!name) {
                    history.push(ROUTE_MIGRATION.path);
                  } else {
                    history.push(ROUTE_DASHBOARD.path);
                  }
                  setLoading(false);
                })
                .catch((e) => {
                  setLoading(false);
                  errorAlert(e?.message);
                });
            }
          })
          .catch((e) => {
            setLoading(false);
            if (e.name === 'NotAuthorizedException') {
              setOpen(true);
              return;
            }

            errorAlert(e?.message);
          });
      } else {
        if (!(email && query.code)) return;
        // Forgot password
        AmplifyAuth.forgotPasswordSubmit(email, query.code, values.password)
          .then(() => {
            history.push(ROUTE_PASSWORD_SUCCESS.path);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            errorAlert(e?.message);
            return catchYupErrors(e, setErrors, (err) => setStatus({ message: err.message }));
          });
      }
    },
    [history, email, query.code]
  );

  return (
    <AuthLayout>
      <SetPasswordForm onSubmit={handleSubmit} loading={loading} />
      <ModalDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        disableContentSpacing
        title={`${trans("The 'Create Your Password' link has already been used.")}`}
      >
        <SpacingContainer px={6} py={4}>
          <Grid container spacing={2} ml={8} justifyContent="flex-start">
            <Grid item>
              <Typography variant="body1" weight={400}>
                It seems that the one-time link has already been used. Below are possible solutions.{' '}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" weight={600} fontFamily="lexend">
                Solutions:
              </Typography>
              <ol>
                <li>
                  <Typography variant="body1" weight={400}>
                    First, try{' '}
                    <span
                      style={{ color: '#2445A7', cursor: 'pointer' }}
                      onClick={() => (window.location.href = '/sign-in')}
                    >
                      logging in
                    </span>{' '}
                    using the password you’ve already created for your USFCR account.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" weight={400}>
                    If you haven’t set a password, you can start over by clicking the
                    <span
                      style={{ color: '#2445A7', cursor: 'pointer' }}
                      onClick={() => (window.location.href = '/sign-in?forgotPassword=true')}
                    >
                      “Forgot your password?“
                    </span>{' '}
                    link on the Login page.
                  </Typography>
                </li>
              </ol>
            </Grid>
          </Grid>
        </SpacingContainer>
      </ModalDialog>
    </AuthLayout>
  );
};

export default SetPassword;
