import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  env: '',
};

export const setStage = createAction('stage/setStage');

function onSetStage(state, action) {
  return Object.assign(state, { env: action.payload.stage });
}

const stageReducer = createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(setStage, onSetStage);
});

export default stageReducer;
