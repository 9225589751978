import { useState, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from '../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../components/common/Dialogs';
import { useTrans } from '../../../../../services/i18n';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import { SpacingContainer } from '../../../../../components/common/Containers';
import { SelectField, TextField } from '../../../../../components/common/Inputs';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '../../../../../components/common/Typography';
import { USER_ROLES } from '../../../../../constants/user';
import { useForm } from '../../../../../hooks/useForm';
import { getInitialValues, useScheme } from './formik';
import { useApplication } from '../../../../../services/application';
import connect from '../connect';
import { AmplifyAuth } from '../../../../../services/amplifyAuth';

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
};

const FormAddUser = ({ updateInfoUser, authUser }) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert } = useAlertContext();
  const [isSend, setIsSend] = useState(false);
  const application = useApplication();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (authUser && authUser.name && authUser.familyName) {
      const values = ['name', 'unassigned', 'user'];
      if (values.includes(authUser.name.toLowerCase()) || values.includes(authUser.familyName.toLowerCase())) {
        setOpen(true);
      }
    }
  }, [authUser]);

  const onSubmit = useCallback(
    async (values) => {
      setIsSend(true);
      application
        .call('user.updateUserInfo', { name: values.name, familyName: values.lastName })
        .then(async (res) => {
          if (res) {
            updateInfoUser({ name: values.name, familyName: values.lastName });
            await AmplifyAuth.currentAuthenticatedUser({
              bypassCache: true,
            });
            closeModal();
          }
        })
        .catch((e) => errorAlert(e.message))
        .finally(() => {
          setIsSend(false);
        });
    },
    [open, errorAlert, successAlert]
  );

  const { getFieldProps, handleSubmit, resetForm } = useForm(schema, getInitialValues({}), onSubmit, formOptions);

  const closeModal = () => {
    resetForm();
    setOpen(false);
  };

  return (
    <ModalDialog
      open={open}
      title={trans('complete-your-data')}
      onlyTitle={true}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          {/* <Grid item>
            <Button variant="outlined" color="primary" disabled={isSend} onClick={closeModal}>
              {trans('cancel')}
            </Button>
          </Grid> */}
          <Grid item>
            <Button variant="contained" color="primary" disabled={isSend} pending={isSend} onClick={handleSubmit}>
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={2} py={3}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} xs={12}>
            <TextField
              label={trans('first-name')}
              placeholder={trans('required')}
              required
              maxLength={255}
              {...getFieldProps('name')}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <TextField
              label={trans('last-name')}
              placeholder={trans('required')}
              required
              maxLength={255}
              {...getFieldProps('lastName')}
            />
          </Grid>
          {/* <Grid item sm={6} xs={12}>
            <TextField
              label={trans('email')}
              type="text"
              placeholder={trans('required')}
              {...getFieldProps('email')}
              required
            />
          </Grid> */}
        </Grid>
      </SpacingContainer>
    </ModalDialog>
  );
};

export default connect(FormAddUser);
