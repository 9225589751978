import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const initialValues = {
  pursuitName: '',
  description: '',
  owner: '',
  winProbability: '',
  estimatedValue: '',
  dueDate: '',
  estimatedAwardDate: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    pursuitName: Yup.string().required(trans('required-field')),
    description: Yup.string().optional().nullable().default(''),
    // owner: Yup.string().required(trans('required-field')),
    winProbability: Yup.number().optional().nullable().default(undefined),
    estimatedValue: Yup.number().optional().nullable().default(0),
    dueDate: Yup.date().typeError(trans('required-field')).required(trans('required-field')),
    estimatedAwardDate: Yup.date().typeError(trans('required-field')).required(trans('required-field')),
  })
);
