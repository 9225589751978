import React from 'react';

function CheckIcon(props) {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.333 3.18L8.92301 14.6L4.68301 10.36L6.09301 8.95L8.92301 11.78L18.923 1.78L20.333 3.18ZM18.123 8.22C18.253 8.79 18.333 9.39 18.333 10C18.333 14.42 14.753 18 10.333 18C5.91301 18 2.33301 14.42 2.33301 10C2.33301 5.58 5.91301 2 10.333 2C11.913 2 13.373 2.46 14.613 3.25L16.053 1.81C14.433 0.67 12.463 0 10.333 0C4.81301 0 0.333008 4.48 0.333008 10C0.333008 15.52 4.81301 20 10.333 20C15.853 20 20.333 15.52 20.333 10C20.333 8.81 20.113 7.67 19.733 6.61L18.123 8.22Z"
        fill="#17B471"
      />
    </svg>
  );
}

export default CheckIcon;
