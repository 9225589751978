import IconButton from '@material-ui/core/IconButton';
import IconMore from '@material-ui/icons/MoreHoriz';
import React from 'react';
import { ListItemTextBase } from '../../../../../components/common/Containers';
import { ContextMenuListItem, Popover } from '../../../../../components/common/Dialogs/Popover';
import Typography from '../../../../../components/common/Typography';
import ConfirmDialog from '../../../../../components/generics/Confirm/Dialog';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import usePopover from '../../../../../hooks/usePopover';
import { useTrans } from '../../../../../services/i18n';
import SavePipelineStageForm from '../../../../../components/forms/SavePipelineStageForm';
import { actions } from '../../../../../store/pipelinesDetails';
import { useDispatch } from 'react-redux';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import { PIPELINE_DETAILS_FIXED_STAGES } from '../../../../../utils/constants';
import connect from '../../connect';

const StageItemMenu = ({ item, title, pending, onDelete, onEdit, addStages, index, settings }) => {
  const { successAlert } = useAlertContext();
  const { trans } = useTrans();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useBooleanHandlers();
  const [openEdit, onOpenEdit, onCloseEdit] = useBooleanHandlers();
  const dispatch = useDispatch();

  const handleSort = (sortBy) => {
    dispatch(actions.sortPursuitsBy({ sortBy, stageId: item.id }));
  };

  return (
    !!item && (
      <>
        <IconButton color="inherit" onClick={onOpenPopover} size="small">
          <IconMore />
        </IconButton>

        {<SavePipelineStageForm item={item} open={openEdit} onClose={onCloseEdit} onEdit={onEdit} />}
        <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
          <ContextMenuListItem size="normal">
            <Typography variant="caption" color="hint">
              {trans('Actions')}
            </Typography>
          </ContextMenuListItem>

          {/* {PIPELINE_DETAILS_FIXED_STAGES.includes(item.type) ? null : ( */}
          <ContextMenuListItem button onClick={onOpenEdit} onClickCB={onClosePopover} size="normal">
            <ListItemTextBase>{trans('EditStage')}</ListItemTextBase>
          </ContextMenuListItem>
          {/* )} */}

          {PIPELINE_DETAILS_FIXED_STAGES.includes(item.type) ? null : (
            <ContextMenuListItem
              button
              onClick={() => {
                addStages?.({ index: index });
                successAlert('Stage added!');
              }}
              onClickCB={onClosePopover}
              size="normal"
            >
              <ListItemTextBase>{trans('AddStageBefore')}</ListItemTextBase>
            </ContextMenuListItem>
          )}

          <ContextMenuListItem
            button
            onClick={() => {
              addStages?.({ index: index + 1 });
              successAlert('Stage added!');
            }}
            onClickCB={onClosePopover}
            size="normal"
          >
            <ListItemTextBase>{trans('AddStageAfter')}</ListItemTextBase>
          </ContextMenuListItem>
          {!PIPELINE_DETAILS_FIXED_STAGES.includes(item.type) && ['Owner', 'Admin'].includes(settings.userRole) && (
            <ContextMenuListItem button onClick={onOpenConfirm} onClickCB={onClosePopover} size="normal">
              <ListItemTextBase>{trans('DeleteStage')}</ListItemTextBase>
            </ContextMenuListItem>
          )}

          <ContextMenuListItem size="normal">
            <Typography variant="caption" color="hint">
              {trans('sort-by')}
            </Typography>
          </ContextMenuListItem>
          <ContextMenuListItem
            button
            onClick={() => handleSort('pursuitName')}
            onClickCB={onClosePopover}
            size="normal"
          >
            <ListItemTextBase>{trans('name')}</ListItemTextBase>
          </ContextMenuListItem>
          <ContextMenuListItem button onClick={() => handleSort('dueDate')} onClickCB={onClosePopover} size="normal">
            <ListItemTextBase>{trans('due-date')}</ListItemTextBase>
          </ContextMenuListItem>
          <ContextMenuListItem button onClick={() => handleSort('updatedAt')} onClickCB={onClosePopover} size="normal">
            <ListItemTextBase>{trans('updated')}</ListItemTextBase>
          </ContextMenuListItem>
        </Popover>

        <ConfirmDialog
          open={openConfirm}
          onClose={onCloseConfirm}
          onConfirm={() => {
            onDelete?.({ id: item.id }).then(onCloseConfirm);
          }}
          pending={pending}
          title={trans('DeleteStage')}
        >
          <Typography color="hint">{trans('delete')}</Typography>
          <Typography color="primary">{title}</Typography>
        </ConfirmDialog>
      </>
    )
  );
};

export default connect(StageItemMenu);
