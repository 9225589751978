import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../Typography';

export default function RadioForm({ options, onChange, name = 'radio', value, disabled, error, touched }) {
  const { trans } = useTrans();

  return (
    <FormControl component="fieldset" disabled={disabled}>
      <RadioGroup row name={name} value={value} onChange={!disabled ? onChange : undefined}>
        {options.map((option, ind) => (
          <FormControlLabel
            key={ind}
            value={option.value}
            control={<Radio color="secondary" id={`radio-${name}-${option.value}`} />}
            label={trans(option.name)}
          />
        ))}
      </RadioGroup>

      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </FormControl>
  );
}
