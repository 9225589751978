const MuiSelect = {
  select: {
    '&:focus': {
      backgroundColor: undefined,
    },
  },
  selectMenu: {
    height: '1.438em',
  },
  filled: {
    '&$filled': {
      paddingRight: 38,
    },
  },
  outlined: {
    '&$outlined': {
      paddingRight: 32,
      paddingLeft: 20,
    },
  },
  iconFilled: {
    right: 12,
  },
};

export default MuiSelect;
