import React from 'react';
import AlertSnackbar from '../components/common/Snackbar/AlertSnackbar';
import { translateApiMessage } from '../constants/messages';
import { application } from '../services/application';
import { I18nContext } from '../services/i18n';
import connect from '../components/layouts/AppLayout/NotificationsInfo/connect';

const MAX_SNACKBARS = 3;
const EVENT_SERVER_ALERT = 'alert-message';
const EVENT_ALERT = 'alert-event';

export const AlertContext = React.createContext({
  addAlert: undefined,
  logAlert: undefined,
  errorAlert: undefined,
  infoAlert: undefined,
  successAlert: undefined,
});
export const useAlertContext = () => React.useContext(AlertContext);

export const errorAlert = (title, message) => application.emit(EVENT_ALERT, { variant: 'error', title, message });
export const successAlert = (title, message) => application.emit(EVENT_ALERT, { variant: 'success', title, message });
export const infoAlert = (title, message) => application.emit(EVENT_ALERT, { variant: 'info', title, message });
export const logAlert = (title, message) => application.emit(EVENT_ALERT, { variant: 'default', title, message });

class AlertContextProviderr extends React.Component {
  static contextType = I18nContext;

  constructor(props) {
    super(props);

    this.state = { snackbars: [] };
    this.handlers = {
      addAlert: this.addSnackbar.bind(this),
      logAlert: this.addSnackbar.bind(this, 'default'),
      errorAlert: this.addSnackbar.bind(this, 'error'),
      infoAlert: this.addSnackbar.bind(this, 'info'),
      successAlert: this.addSnackbar.bind(this, 'success'),
      onClose: this.removeSnackbar.bind(this),
    };
  }

  componentDidMount() {
    application.on(EVENT_SERVER_ALERT, this._onServerEvent);
    application.on(EVENT_ALERT, this._onAlertEvent);
  }

  componentWillUnmount() {
    application.off(EVENT_SERVER_ALERT, this._onServerEvent);
    application.off(EVENT_ALERT, this._onAlertEvent);
  }

  _onServerEvent = (data) => {
    this.props.getUnread();
    if (!(data && this.context)) return;
    const { variant, title, message, replace } = translateApiMessage(this.context.trans, data.message, data.values);

    this.addSnackbar(variant, title, message, replace);
  };

  _onAlertEvent = (data) => {
    if (!(data && this.context && typeof this.context.trans === 'function')) return;
    const { trans } = this.context;
    const { variant, title, message, values } = data;

    this.addSnackbar(variant || 'info', title ? trans(title, values) : 'Alert', message ? trans(message, values) : '');
  };

  addSnackbar(variant, title, message = '', replace = false) {
    if (!(variant && title)) return;

    const currentSnackbars = Array.isArray(this.state.snackbars) ? this.state.snackbars : [];
    currentSnackbars.splice(MAX_SNACKBARS - 1);

    const newSnack =
      title instanceof Error && title.message
        ? { variant, title: 'Error', message: String(title.message) }
        : { variant, title: String(title), message: String(message) };

    this.setState({ snackbars: replace ? [newSnack] : [...currentSnackbars, newSnack] });
  }

  removeSnackbar(index) {
    try {
      const currentSnackbars = Array.isArray(this.state.snackbars) ? this.state.snackbars : [];
      currentSnackbars.splice(index, 1);

      this.setState({ snackbars: [...currentSnackbars] });
    } catch (err) {
      console.warn(err.message);
    }
  }

  render() {
    const value = { snackbars: this.state.snackbars, ...this.handlers };

    return <AlertContext.Provider value={value}>{this.props.children}</AlertContext.Provider>;
  }
}

export const AlertContextProvider = connect(AlertContextProviderr);

export class AlertContextConsumer extends React.Component {
  renderItems(props) {
    const { snackbars, onClose } = props;

    return snackbars.map(({ title, message, variant }, index) => (
      <AlertSnackbar
        open
        key={`${index}-key`}
        variant={variant}
        title={title}
        message={message}
        onClose={onClose.bind(null, index)}
      />
    ));
  }

  render() {
    return <AlertContext.Consumer>{this.renderItems}</AlertContext.Consumer>;
  }
}
