import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Typography, Divider, ListItem, ListItemIcon, ListItemText, Grid } from '@material-ui/core/';

import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import { useTrans } from '../../../../services/i18n';
import styles from './styles.module.css';
import LinkPursuitModal from '../LinkPursuitModal';
import connect from './connect';
import { formatDate } from '../../../../utils/dateUtils';
import { useSubscribedOnly } from '../../../common/Subscription/hooks/useSubscribedOnly';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons';
import { Button } from '../../../common/Buttons';

const StyledMenu = withStyles({
  paper: {
    marginTop: '5px',
    width: '300px',
    maxHeight: '350px',
  },
})((props) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    whiteSpace: 'normal',
    display: 'flex',
    flexWrap: 'wrap',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const LinkPursuitButton = ({
  size = 'medium',
  getPursuits,
  userPursuits,
  opportunity,
  type,
  subscription,
  setOpenModalPremium,
  upAnchorEl = null,
  handleChildEvent,
  controlType = 'menuItem',
}) => {
  const { trans } = useTrans();

  const { subscribedOnly } = useSubscribedOnly(subscription, setOpenModalPremium);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [currentPursuit, setCurrentPursuit] = useState(null);
  const [transDataOpportunity, setTransDataOpportunity] = useState(null);

  const handleClick = (event) => {
    handleChildEvent('hidden');
    setAnchorEl(event.currentTarget);
    getPursuits();
  };

  const getArrayValue = (value) => {
    if (typeof value === 'string') return value?.split(',') || [];

    if (typeof value === 'object') return value?.code ? [value.code] : [];

    return value;
  };

  const handleSelectPursuit = async (p) => {
    if (type === 'contract') {
      const { primaryPoc, secondaryPoc } = opportunity;
      const pointOfContacts = [primaryPoc, secondaryPoc];
      console.log(pointOfContacts);
      pointOfContacts
        .filter((c) => c && !!c.email)
        .map((c) => ({
          email: c.email,
          fullName: c.name,
        }));
      const naics = getArrayValue(opportunity.naics);
      const psc = getArrayValue(opportunity.psc);
      setTransDataOpportunity({ ...transDataOpportunity, pointOfContacts, naics, psc });
    }
    setCurrentPursuit(p);
    setOpenModal(true);
    handleClose();
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoBack = () => {
    handleChildEvent('visible');
    handleClose();
  };

  const handleChangeSearch = (ev) => {
    const { value } = ev.target;
    setSearchInput(value);
    getPursuits({ text: value });
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    if (type === 'contract' && opportunity) setTransDataOpportunity(opportunity);
    if (type === 'award' && opportunity) {
      let opp = {
        // id: opportunity.idToLinkPursuit,
        id: opportunity.id,
        title: opportunity.title,
        setAside: opportunity.setAside,
        naics: [opportunity.NAICSCategory],
        psc: [opportunity.PSC],
      };
      setTransDataOpportunity(() => ({ ...opportunity, ...opp }));
    }
    if (type === 'grant' && opportunity) {
      let opp = {
        id: opportunity.id,
        title: opportunity.title,
        dueDate: opportunity.closeDate,
      };
      setTransDataOpportunity(() => ({ ...opportunity, ...opp }));
    }
    if (type === 'nsn' && opportunity) {
      const { solicitationNumber, nomenclature, ...rest } = opportunity;
      let opp = {
        ...rest,
        title: `${solicitationNumber} ${nomenclature ? `- ${nomenclature}` : ''}`,
      };
      setTransDataOpportunity(() => ({ ...opportunity, ...opp }));
    }
    if (type === 'stateLocal' && opportunity) {
      let opp = {
        id: opportunity.id,
        title: opportunity.title,
        dueDate: opportunity.proposalDeadline,
      };
      setTransDataOpportunity({ ...opportunity, ...opp });
    }
  }, [type, opportunity]);

  return (
    <>
      <div
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: controlType === 'menuItem' ? 10 : 0,
          paddingRight: 10,
        }}
      >
        {controlType === 'menuItem' ? (
          <MenuItem
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={subscribedOnly(handleClick)}
            size={size}
          >
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
              <Grid>
                <Typography color={'primary'}>{trans('link-to-pursuit')}</Typography>
              </Grid>
              <Grid>
                <ListItemIcon style={{ justifyContent: 'end' }}>
                  <ChevronRightOutlined color={'primary'} />
                </ListItemIcon>
              </Grid>
            </Grid>
          </MenuItem>
        ) : (
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={subscribedOnly(handleClick)}
            size={size}
          >
            {trans('link-to-pursuit')}
          </Button>
        )}

        <StyledMenu
          className={styles.noPadding}
          id="customized-menu"
          anchorEl={upAnchorEl || anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <ListItem style={{ paddingLeft: 10, paddingRight: 10 }}>
            <ListItemIcon style={{ minWidth: '25px', cursor: 'pointer' }} onClick={handleGoBack}>
              <ChevronLeftOutlined style={{ marginLeft: '-5px' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5">{trans('select-pursuit')}</Typography>
            </ListItemText>
          </ListItem>

          <FormControl className={styles.inputSearch}>
            <InputBase
              onClickCapture={stopImmediatePropagation}
              onKeyDown={(e) => e.stopPropagation()}
              placeholder="Search pursuit"
              className={styles.inputBase}
              startAdornment={
                <InputAdornment position="start" className={styles.adormentSearch}>
                  <SearchIcon className={styles.textMuted} />
                </InputAdornment>
              }
              value={searchInput}
              onChange={handleChangeSearch}
            />
          </FormControl>

          {userPursuits &&
            userPursuits.map((p, i) => (
              <div key={p.id}>
                <div>{i >= 1 ? <Divider style={{ backgroundColor: 'rgba(78, 89, 120, 0.12)' }} /> : <div></div>}</div>
                <StyledMenuItem onClick={() => handleSelectPursuit(p)}>
                  <div className={styles.menuItemTitle}>{p.pursuitName}</div>
                  <div className={styles.menuItemContent}>
                    <span className={styles.w100}>
                      <span className={styles.textMuted}> Updated:</span> {formatDate(p.updatedAt, 'MMM D, YYYY')}
                    </span>
                    <span className={styles.w100}>
                      <span className={styles.textMuted}> Pipeline:</span> {p.pipelineName}
                    </span>
                  </div>
                </StyledMenuItem>
              </div>
            ))}
        </StyledMenu>
      </div>

      <LinkPursuitModal
        open={openModal}
        opportunity={transDataOpportunity}
        type={type}
        pursuit={currentPursuit}
        pending={false}
        onCloseDialog={closeModal}
      />
    </>
  );
};

export default connect(LinkPursuitButton);
