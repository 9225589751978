import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { Paper } from '../../components/common/Containers';
import { SpacingContainer } from '../../components/common/Containers/Base';
import { Divider } from '../../components/common/Divider';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';
import { useTrans } from '../../services/i18n';
import connect from './connect';
import Typography from '../../components/common/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PaginationSmall from '../../components/common/Paginations/PaginationSmall';
import { intlDateFormat } from '../../utils/dateUtils';
import { emptyArray } from '../../utils/arrayUtils';
import Tag from './Tag';
import MDEditor from '@uiw/react-md-editor';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Loader from '../../components/common/Loader';
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard';
import { LoaderSmall } from '../../components/common/LoaderSmall';
import LargeBanner from '../../components/common/Subscription/BecomePremiumTopBar/BigBanner';

function Releases({
  releases,
  onInit,
  onChangePage,
  onChangeRowPerPage,
  changeCurrentRelease,
  onChangeSearchText,
  currentRelease,
}) {
  const { trans } = useTrans();
  const { items, count, pagination, pending } = releases || {};
  const useStyles = makeStyles((theme) => ({
    active: {
      color: 'white',
      background: '#f32445',
    },
    inputSearch: {
      width: '100%',
      height: '60px',
    },

    inputBase: {
      height: '60px',
    },
    adormentSearch: {
      marginLeft: '16px',
    },
  }));
  const [inputSearch, setInputSearch] = useState('');
  const [flagChange, setFlagChange] = useState(false);
  const styles = useStyles();
  const classes = {
    active: styles.active,
    inputSearch: styles.inputSearch,
    inputBase: styles.inputBase,
    adormentSearch: styles.adormentSearch,
  };

  const onChangeSearch = (ev) => {
    setInputSearch(ev.target.value);
    onChangeSearchText(ev.target.value);
  };

  const showRelease = (release) => {
    setFlagChange(true);
    changeCurrentRelease(release);
  };

  useEffect(() => {
    onInit().then((res) => {
      changeCurrentRelease(items[0]);
    });
  }, []);

  useEffect(() => {
    if (!emptyArray(items) && !currentRelease) changeCurrentRelease(items[0]);
  }, [releases]);

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <SpacingContainerBase>
      <SpacingContainer>
        <Loader open={pending && !inputSearch} />
        <LargeBanner />
        <Grid item xs={12} style={{ paddingBottom: '30px' }}>
          <Typography weight="600" variant="h2" fontFamily="lexend">
            {trans('usfcr-releases')}
          </Typography>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12} md={3} item>
            <Paper style={{ paddingBottom: !emptyArray(items) ? '16px' : '0px' }}>
              <FormControl>
                <InputBase
                  onClickCapture={stopImmediatePropagation}
                  // onKeyDown={(e) => e.stopPropagation()}
                  placeholder="Search"
                  className={classes.inputBase}
                  startAdornment={
                    <InputAdornment position="start" className={classes.adormentSearch}>
                      <SearchIcon className={classes.textMuted} />
                    </InputAdornment>
                  }
                  value={inputSearch}
                  onChange={onChangeSearch}
                />
              </FormControl>
              {pending && inputSearch && <LoaderSmall size="1.55em" />}
              {emptyArray(items) && inputSearch && !pending && (
                <>
                  <Divider light style={{ marginBottom: '16px' }} />

                  <Grid item xs zeroMinWidth>
                    <NothingFoundCard title="" description="No results" />
                  </Grid>
                </>
              )}

              {!pending &&
                !emptyArray(items) &&
                items.map((i) => (
                  <div
                    key={i.id}
                    style={{ padding: '8px 12px', margin: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}
                    className={currentRelease && i.id === currentRelease.id ? classes.active : ''}
                    onClick={() => showRelease(i)}
                  >
                    <Typography
                      style={{ color: currentRelease && i.id === currentRelease.id ? 'white' : '#b1b5c6' }}
                      variant="body2"
                      fontFamily="lexend"
                    >
                      {intlDateFormat(i.createdAt)}
                    </Typography>
                    <Typography variant="body2" fontFamily="lexend">
                      {i.title}
                    </Typography>
                  </div>
                ))}

              {!emptyArray(items) && (
                <PaginationSmall
                  {...pagination}
                  count={count}
                  onChangePage={onChangePage}
                  onChangeRowPerPage={onChangeRowPerPage}
                  pending={false}
                  px={3}
                  py={1}
                />
              )}
            </Paper>
          </Grid>
          {!emptyArray(items) && (
            <>
              <Grid xs={12} md={9} item>
                {currentRelease && (
                  <Paper style={{ padding: '40px 25px' }}>
                    <Grid container spacing={2}>
                      <Grid xs={12} md={10} item>
                        <Typography variant="h2" fontFamily="lexend">
                          {currentRelease.title}
                        </Typography>
                      </Grid>
                      <Grid xs={12} md={2} item>
                        <Typography variant="body2" weight="500" fontFamily="lexend">
                          {' '}
                          {trans('published-on')}
                        </Typography>
                        <Typography variant="body2" weight="300" fontFamily="lexend">
                          {' '}
                          {intlDateFormat(currentRelease.createdAt)}
                        </Typography>
                      </Grid>
                      <Grid xs={12} md={12} item>
                        <Grid container spacing={1} alignItems="center">
                          {currentRelease.tags &&
                            !emptyArray(currentRelease.tags) &&
                            currentRelease.tags.map((tag, index) => (
                              <Grid item key={index}>
                                <Tag tagName={tag} />
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                      <Grid xs={12} md={12} item>
                        <MDEditor.Markdown source={currentRelease.content} />
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </Grid>
            </>
          )}
          {!pending && emptyArray(items) && inputSearch && (
            <Grid item xs zeroMinWidth>
              <NothingFoundCard title="No releases" description="No releases for this search" />
            </Grid>
          )}
        </Grid>

        {!pending && emptyArray(items) && !inputSearch && (
          <Grid container style={{ marginTop: '16px' }}>
            <Grid item xs zeroMinWidth>
              <NothingFoundCard title="No releases" description="USFCR no added releases yet" />
            </Grid>
          </Grid>
        )}
      </SpacingContainer>
    </SpacingContainerBase>
  );
}

export default connect(Releases);
