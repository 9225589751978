import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTE_VENDORS,
  ROUTE_VENDORS_DETAILS,
  ROUTE_LOCAL_VENDORS_DETAILS,
  ROUTE_VENDORS_MANAGEMENT,
  ROUTE_VENDORS_MANAGEMENT_LIST,
  ROUTE_VENDORS_MANAGEMENT_DETAILS,
} from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const Vendors = React.lazy(() => import('./Vendors'));
const VendorsDetails = React.lazy(() => import('./Details'));
const LocalVendorsDetails = React.lazy(() => import('./LocalVendors/Details'));
const VendorsManagement = React.lazy(() => import('./Management'));
const VendorsManagementList = React.lazy(() => import('./Management/List'));
const VendorManagementDetails = React.lazy(() => import('./Management/Details'));

export default function VendorsWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_VENDORS_DETAILS.path}>
          <VendorsDetails />
        </Route>
        <Route exact path={ROUTE_LOCAL_VENDORS_DETAILS.path}>
          <LocalVendorsDetails />
        </Route>
        <Route exact path={ROUTE_VENDORS.path}>
          <Vendors />
        </Route>
        <Route exact path={ROUTE_VENDORS.path}>
          <Vendors />
        </Route>
        <Route exact path={ROUTE_VENDORS_MANAGEMENT.path}>
          <VendorsManagement />
        </Route>
        <Route exact path={ROUTE_VENDORS_MANAGEMENT_LIST.path}>
          <VendorsManagementList />
        </Route>
        <Route exact path={ROUTE_VENDORS_MANAGEMENT_DETAILS.path}>
          <VendorManagementDetails />
        </Route>
        <Redirect exact path="/*" to={ROUTE_VENDORS.path} />
      </Switch>
    </Suspense>
  );
}
