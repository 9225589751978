import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_CODE_CATEGORIES } from '../../constants';
import { INITIAL_LIST_STATE_EXTENDED, isLoadMoreAction } from '../helpers/common';
import {
  extendBuilderWithListActions,
  extendBuilderWithSearchActions,
  generateListActions,
  generateSearchActions,
} from '../helpers/listActionsHelpers';
import { application } from '../../services/application';
import { onPending, onPendingDone } from '../helpers/sharedCases';
import { markFavCodes, markAllAsFavorite } from '../../containers/CodeCategories/helpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['alphabetical', 'asc'],
};

const listActions = generateListActions({
  scope: SCOPE_CODE_CATEGORIES,
  apiMethod: {
    GET_LIST: API.CODE_CATEGORIES.GET_LIST,
    EXPORT_CSV: API.CODE_CATEGORIES.EXPORT_CSV,
  },
  getStore: (store) => store.codeCategories,
});

const searchActions = generateSearchActions({
  scope: SCOPE_CODE_CATEGORIES,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.codeCategories,
});

const addToFavorite = createAsyncThunk('federalAgents/toggleFavorite', (params) => {
  return application.call(API.CODE_CATEGORIES.TOGGLE_FAVORITE, params);
});

function onAddToFavorite(state, action) {
  const code = state.items.find((item) => item.docId === action.payload.id);
  if (code) {
    code.isFavorite = action.payload.isFavorite;
  }
  return Object.assign(state, { pending: false });
}

function onFetchList(state, action) {
  const items = action.payload.favorites
    ? markFavCodes(action.payload.items, action.payload.favorites)
    : markAllAsFavorite(action.payload.items);

  return isLoadMoreAction(action)
    ? Object.assign(state, {
        ...action.payload,
        items: [...state.items, ...items],
        pending: false,
      })
    : Object.assign(state, action.payload, { pending: false }, { items });
}

function onPendingList(state, action) {
  return {
    ...state,
    pending: true,
    items: isLoadMoreAction(action) ? state.items : [],
  };
}

export const actions = {
  ...listActions,
  ...searchActions,
  addToFavorite,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, { ...listActions, fetchList: null });
  extendBuilderWithSearchActions(builder, { ...searchActions });

  builder
    .addCase(addToFavorite.fulfilled, onAddToFavorite)
    .addCase(addToFavorite.pending, onPending)
    .addCase(addToFavorite.rejected, onPendingDone)
    .addCase(listActions.fetchList.fulfilled, onFetchList)
    .addCase(listActions.fetchList.pending, onPendingList)
    .addCase(listActions.fetchList.rejected, onPendingDone);
});
