import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconVendorManagement = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2128 21.4645L16.2289 17.6827C16.0786 17.3961 16 17.0773 16 16.7536V3.99999C16 2.89542 16.8954 1.99999 18 1.99999H20C21.1046 1.99999 22 2.89542 22 3.99999V16.7744C22 17.0715 21.9338 17.3648 21.8063 17.6331L20.0015 21.4293C19.6476 22.1737 18.5957 22.1944 18.2128 21.4645ZM18 16.9575L19.054 19L20 16.9786V8H18V16.9575ZM18 6H20V3.99999H18V6ZM8 2H4C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H8C8.36429 22 8.70583 21.9026 9 21.7324C9.29418 21.9026 9.63571 22 10 22H12C13.1046 22 14 21.1046 14 20V10C14 8.89543 13.1046 8 12 8H10V4C10 2.89543 9.10457 2 8 2ZM10 10V20H12V10H10ZM8 8V4H4V8H8ZM8 10H4V14H8V10ZM4 16V20H8V16H4Z"
        fill="#4E5978"
      />
    </svg>
  );
};

IconVendorManagement.propTypes = simpleIconPropsTypes;
