import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCircleCheck = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" noFill={true}>
      <rect width="24" height="24" rx="12" fill="#17B471" fill-opacity="0.1" />
      <path
        d="M17.3177 7.77802C16.9235 7.35653 16.2553 7.35535 15.8595 7.77545L10 13.9958L8.13798 12.0267C7.74291 11.6089 7.07776 11.6096 6.68351 12.0281L6.64591 12.068C6.28311 12.4532 6.28311 13.0543 6.64591 13.4394L9.2721 16.2273C9.66688 16.6464 10.3331 16.6464 10.7279 16.2273L17.3564 9.1907C17.7182 8.80655 17.7193 8.20732 17.3588 7.8219L17.3177 7.77802Z"
        fill="#17B471"
      />
    </SvgIcon>
  );
};

IconCircleCheck.propTypes = simpleIconPropsTypes;
