import React, { useCallback, useEffect } from 'react';
import { useTrans } from '../../../../services/i18n';
import { Switch } from '../../../../components/common/Inputs';
import { Box } from '@material-ui/core';
import connect from '../connect';

function IncludeStatisticsToggle({ item, toggleIncludeInStatistics }) {
  const { trans } = useTrans();
  const [includeStatistics, setIncludeStatistics] = React.useState(true);

  useEffect(() => {
    setIncludeStatistics(Boolean(item?.includeInStatistics) || false);
  }, [item?.includeInStatistics]);

  const onToggleClick = useCallback(() => {
    setIncludeStatistics(!includeStatistics);
    toggleIncludeInStatistics?.({ id: item?.id, includeInStatistics: !includeStatistics });
  }, [includeStatistics, toggleIncludeInStatistics, item?.id]);

  return (
    <Box display="flex" alignItems="center" marginRight="1.5rem">
      <Switch
        label={trans('include-statistics-in-won-vs-lost')}
        checked={includeStatistics}
        onChange={(e) => onToggleClick?.(e.target.checked)}
      />
    </Box>
  );
}

export default connect(IncludeStatisticsToggle);
