import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ROUTE_DEMO_DASHBOARD } from '../../../constants';
import { useShouldRedirectLimitedUsers } from '../../../hooks/useShouldRedirectLimitedUsers';

const getRedirect = (isAuthenticated, noAuth, redirect) => {
  if (!(isAuthenticated === !noAuth)) {
    return redirect;
  }
};

export const AuthRoute = (props) => {
  const { noAuth = false, authorized, redirect, children, component, permissionKey, ...rest } = props;
  const redirectUrl = getRedirect(authorized, noAuth, redirect);
  const shouldRedirectLimitedUsers = useShouldRedirectLimitedUsers(authorized, rest.location.pathname);

  if (shouldRedirectLimitedUsers) {
    return <Redirect to={ROUTE_DEMO_DASHBOARD.path} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        redirectUrl ? (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: location },
            }}
          />
        ) : component ? (
          React.createElement(component)
        ) : (
          children
        )
      }
    />
  );
};
