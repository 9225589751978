import React, { useState } from 'react';
import ModalDialog from '../../../../components/common/Dialogs/ModalDialog';
import SaveCancelDialogActions from '../../../../components/common/Dialogs/SaveCancelDialogActions';
import { TextField } from '../../../../components/common/Inputs';
import { useTrans } from '../../../../services/i18n';
import { Grid } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import * as Yup from 'yup';
import UploadAvatar from '../../../../components/common/Inputs/UploadAvatar';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import connect from '../../connect';
import { useAlertContext } from '../../../../contexts/AlertContext';
import { AVATAR_STATUSES } from '../../../../constants';

const useScheme = makeValidationSchema((trans) => {
  return Yup.object().shape({
    orgName: Yup.string().required(trans('empty-not-allowed', { field: trans('org-name') })),
    //capabilityStatement: Yup.string().required(trans('empty-not-allowed', { field: trans('org-capability') })),
    avatarId: Yup.string().optional(),
    //description: Yup.string().required(trans('empty-not-allowed', { field: trans('description') })),
    contactEmail: Yup.string().optional().nullable(),
    contactPhoneNumber: Yup.string().matches(/^\d+$/, 'The field should have digits only').optional().nullable(),
    contactPerson: Yup.string().optional().nullable(),
  });
});

const uploadFileToS3 = async (url, file) => {
  try {
    const requestOptions = {
      method: 'PUT',
      body: file,
    };
    await fetch(url, requestOptions).catch(console.warn);
  } catch (e) {
    console.warn(e);
  }
};

function SetupModal({
  setupSettings,
  editSettings,
  settings,
  getAvatarUploadUrl,
  removeAvatar,
  setupAvatar,
  setupAvatarId,
  open,
  onClose,
}) {
  const { trans } = useTrans();
  const { orgName, capabilityStatement, avatarId, description, contactPerson, contactPhoneNumber, contactEmail } =
    settings;
  const schema = useScheme();
  const [pendingUploadAvatar, setPendingUploadAvatar] = useState(false);
  const { successAlert, errorAlert } = useAlertContext();

  const uploadPhoto = React.useCallback(
    (file, avatarId, orgName, capabilityStatement, description, contactPerson, contactPhoneNumber, contactEmail) => {
      setPendingUploadAvatar(true);
      return getAvatarUploadUrl(file.name)
        .then(async (value) => {
          await uploadFileToS3(value.url, file);
          setupAvatarId({ avatarId: value.id, avatarUrl: value.url, profileImgStatus: AVATAR_STATUSES.pending });
          editSettings(
            value.id,
            orgName,
            capabilityStatement,
            description,
            contactPerson,
            contactPhoneNumber,
            contactEmail
          );
          setPendingUploadAvatar(false);
          successAlert(trans('logo-verification-msj'));
        })
        .catch((e) => {
          console.warn(e);
          setPendingUploadAvatar(false);
        });
    },
    [editSettings, getAvatarUploadUrl, setupAvatarId]
  );

  const onSubmit = React.useCallback(
    ({ orgName, capabilityStatement, description, contactPerson, contactPhoneNumber, contactEmail }) => {
      setupSettings(
        avatarId,
        orgName,
        capabilityStatement,
        description,
        contactPerson,
        contactPhoneNumber,
        contactEmail
      );
      onClose();
    },
    [onClose, avatarId, setupSettings, description, contactPerson, contactPhoneNumber, contactEmail]
  );

  const onEdit = React.useCallback(
    ({ orgName, capabilityStatement, description, contactPerson, contactPhoneNumber, contactEmail }) => {
      editSettings(
        avatarId,
        orgName,
        capabilityStatement,
        description,
        contactPerson,
        contactPhoneNumber,
        contactEmail
      );
      onClose();
    },
    [editSettings, onClose, avatarId, description, contactPerson, contactPhoneNumber, contactEmail]
  );

  const { handleSubmit, resetForm, getFieldProps } = useForm(
    schema,
    { orgName, capabilityStatement, description, contactPerson, contactPhoneNumber, contactEmail },
    orgName ? onEdit : onSubmit,
    {
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
    }
  );

  const handleCancel = React.useCallback(() => {
    onClose();
    resetForm({
      values: { orgName, capabilityStatement, description, contactPerson, contactPhoneNumber, contactEmail },
    });
  }, [onClose, resetForm, orgName, capabilityStatement, description, contactPerson, contactPhoneNumber, contactEmail]);

  const handleSetAvatar = React.useCallback(
    (e) => {
      if (e.currentTarget.files[0]) {
        uploadPhoto(
          e.currentTarget.files[0],
          avatarId,
          orgName,
          capabilityStatement,
          description,
          contactPerson,
          contactPhoneNumber,
          contactEmail
        );
        setupAvatar({ avatar: URL.createObjectURL(e.currentTarget.files[0]) });
      }
    },
    [
      avatarId,
      capabilityStatement,
      orgName,
      setupAvatar,
      uploadPhoto,
      description,
      contactPerson,
      contactPhoneNumber,
      contactEmail,
    ]
  );

  const handleRemoveAvatar = React.useCallback(() => {
    removeAvatar(avatarId);
    editSettings('', orgName, capabilityStatement, description, contactPerson, contactPhoneNumber, contactEmail);
    setupAvatar('');
    setupAvatarId({ avatarId: '', profileImgStatus: '' });
  }, [
    avatarId,
    capabilityStatement,
    editSettings,
    orgName,
    removeAvatar,
    setupAvatar,
    setupAvatarId,
    description,
    contactPerson,
    contactPhoneNumber,
    contactEmail,
  ]);

  return (
    <>
      <ModalDialog
        open={open}
        onClose={onClose}
        title={trans('org-setup-title')}
        actions={
          <SaveCancelDialogActions
            formId="org-setup-title"
            onCancelClick={handleCancel}
            onSaveClick={handleSubmit}
            isOrgSettings={true}
          />
        }
      >
        <Grid container direction="row" spacing={3} justifyContent="space-between" alignItems="flex-start">
          <Grid item sm={4} xs={12}>
            <UploadAvatar
              settings={settings}
              pending={pendingUploadAvatar}
              handleSetAvatar={handleSetAvatar}
              handleRemoveAvatar={handleRemoveAvatar}
            />
          </Grid>
          <Grid item container justifyContent="flex-start" direction="column" sm={8} xs={12} spacing={0} wrap="nowrap">
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextField
                type="text"
                label={trans('org-name')}
                placeholder={trans('required')}
                required
                {...getFieldProps('orgName')}
              />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextField label={trans('contact-email')} required maxLength={80} {...getFieldProps('contactEmail')} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextField
                label={trans('contact-phone-number')}
                required
                maxLength={80}
                {...getFieldProps('contactPhoneNumber')}
              />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <TextField label={trans('contact-person')} required maxLength={80} {...getFieldProps('contactPerson')} />
            </Grid>
          </Grid>
        </Grid>
      </ModalDialog>
    </>
  );
}

export default connect(SetupModal);
