import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    padding: '1rem',
    background: (props) => (props.isActive ? '#2445A7' : props.isCompleted ? 'white' : 'white'),
    borderRadius: '1.5rem',
    gap: '1rem',
  },
  stepNumberContainer: {
    width: '3rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    background: 'white',
    borderRadius: '1rem',
    border: (props) => (props.isActive ? 'none' : props.isCompleted ? '2px #2445A7 solid' : '2px #999999 solid'),
  },
  stepNumber: {
    color: (props) => (props.isActive ? '#2445A7' : props.isCompleted ? '#2445A7' : '#999999'),
    fontSize: 22,
    fontFamily: 'Manrope',
    fontWeight: '700',
  },
  stepTitle: {
    width: 128,
    color: (props) => (props.isActive ? 'white' : props.isCompleted ? '#2C3A49' : '#999999'),
    fontSize: 15,
    fontFamily: 'Manrope',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const StepCard = ({ title, isActive, isCompleted, stepNumber }) => {
  const styles = useStyles({ isActive, isCompleted });

  return (
    <Paper className={styles.stepContainer} elevation={isActive ? 3 : 1}>
      <div className={styles.stepNumberContainer}>
        <Typography className={styles.stepNumber}>{stepNumber}</Typography>
      </div>
      <Typography className={styles.stepTitle}>{title}</Typography>
    </Paper>
  );
};

export default StepCard;
