import React from 'react';
import { Autocomplete } from '../../../../components/common/Inputs';
import { useApiAutocomplete } from '../../../../hooks/useApiAutocomplete';

const getOptionLabel = (option) => (option && option.id ? `${option.id}` : '');

const setCodeAsValue = (val) => ({ ...val, value: val.code });

export const CustomStatesAutocomplete = (props) => {
  const { onChange, value, multi, labelKey = 'value', label, options, ...rest } = props;
  const { loading, handleValueChange, filterOptions, handleInputChange } = useApiAutocomplete({
    labelKey,
    onChange: React.useCallback((val) => onChange && onChange(setCodeAsValue(val)), [onChange]),
  });

  const validateValueArray = () => {
    if (Array.isArray(value)) {
      return value;
    } else {
      return [];
    }
  };

  return (
    <Autocomplete
      name="states"
      options={options}
      label={label}
      labelKey={labelKey}
      loading={loading}
      value={validateValueArray()}
      filterOptions={filterOptions}
      onChange={handleValueChange}
      multiple={multi}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};
