import { Grid, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Paper } from '../../components/common/Containers';
import Loader from '../../components/common/Loader';
import Typography from '../../components/common/Typography';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';
import { useTrans } from '../../services/i18n';
import connect from './connect';
import { useHistory } from 'react-router-dom';
import SectionScrollable from '../../components/common/Containers/SectionScrollable';
import StepCard from './components/StepCard';
import CompanyInformation from './components/CompanyInformation';
import KeyPersonnel from './components/KeyPersonnel';
import TeamingQuestions from './components/TeamingQuestions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ROUTE_ORG_SETTINGS } from '../../constants';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: '0.7rem',
  },
  title: {
    marginTop: '2rem',
    padding: '0rem 2rem',
  },
  stepContainer: {
    paddingTop: '0px !important',
  },
}));

function OrganizationTeamingInformationForm({
  settings,
  getPublicProfile,
  getOrganizationInfo,
  getUsers,
  onCreateTeaming,
}) {
  const styles = useStyles();
  const { trans } = useTrans();
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const location = useLocation();
  const orgTeamingRecord = location.state?.orgTeamingRecord;
  const isEditMode = location.state?.isEditMode;

  // State where all wizard data will be stored
  const [data, setData] = useState(orgTeamingRecord || {});

  const [currentStep, setCurrentStep] = useState(1);
  // First step
  const [isCompanyInfoComplete, setIsCompanyInfoComplete] = useState(false);
  // Second step
  const [isQuestionsComplete, setIsQuestionsComplete] = useState(false);
  // Tirth step
  const [isKeyPersonnelComplete, setIsKeyPersonnelComplete] = useState(false);

  /**
   *
   */
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  /**
   *
   */
  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
    if (currentStep === 2) {
      setIsQuestionsComplete(true);
    }
    if (currentStep === 3) {
      setIsKeyPersonnelComplete(true);
    }
  };

  /***
   *
   */
  const cancelProcess = () => {
    // Go to main page
    history.push(ROUTE_ORG_SETTINGS.path);
  };

  /***
   *
   */
  useEffect(() => {
    setLoading(true);
    Promise.all([getOrganizationInfo()]).finally(() => {
      setLoading(false);
    });
  }, [getOrganizationInfo, getUsers]);

  /***
   *
   */
  let currentForm;
  switch (currentStep) {
    case 1:
      currentForm = (
        <CompanyInformation
          nextStep={nextStep}
          onCompletionChange={setIsCompanyInfoComplete}
          onCancel={cancelProcess}
          data={data}
          setData={setData}
          isEditMode={isEditMode}
        />
      );
      break;
    case 2:
      currentForm = (
        <TeamingQuestions
          nextStep={nextStep}
          previousStep={previousStep}
          onCompletionChange={setIsQuestionsComplete}
          onCancel={cancelProcess}
          data={data}
          setData={setData}
          isEditMode={isEditMode}
        />
      );
      break;
    case 3:
      currentForm = (
        <KeyPersonnel
          previousStep={previousStep}
          onCompletionChange={setIsKeyPersonnelComplete}
          onCancel={cancelProcess}
          data={data}
          setData={setData}
          onCreateTeaming={onCreateTeaming}
          isEditMode={isEditMode}
        />
      );
      break;
  }

  return (
    <SectionScrollable>
      <SpacingContainerBase>
        <Loader open={loading} />
        <Typography weight="600" variant="h2" fontFamily="lexend" className={styles.title}>
          {trans('teaming-organization-form-title')}
        </Typography>
        <SpacingContainerBase>
          <Grid container>
            <Grid item xs={12} sm={3} className={styles.stepContainer}>
              <Box mb={2}>
                <StepCard
                  title={trans('teaming-organization-form-step-1')}
                  isActive={currentStep === 1}
                  isCompleted={isCompanyInfoComplete}
                  stepNumber={1}
                  nextStep={2}
                  previousStep={1}
                />
              </Box>
              <Box mb={2}>
                <StepCard
                  title={trans('teaming-organization-form-step-2')}
                  isActive={currentStep === 2}
                  isCompleted={isQuestionsComplete}
                  stepNumber={2}
                  nextStep={3}
                  previousStep={1}
                />
              </Box>
              <Box mb={2}>
                <StepCard
                  title={trans('teaming-organization-form-step-3')}
                  isActive={currentStep === 3}
                  isCompleted={isKeyPersonnelComplete}
                  stepNumber={3}
                  previousStep={2}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={9} className={styles.formContainer}>
              <Paper>
                <Grid item xs={12}>
                  {currentForm}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </SpacingContainerBase>
      </SpacingContainerBase>
    </SectionScrollable>
  );
}

export default connect(OrganizationTeamingInformationForm);
