import makeStyles from '@material-ui/core/styles/makeStyles';
import { Height } from '@material-ui/icons';

export const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.modal - 1,
    overflow: 'auto',
    alignItems: 'flex-start',
  },
  fixed: {
    zIndex: theme.zIndex.modal + 1,
    position: 'fixed',
  },
  wrapper: {
    position: 'relative',
    width: '633px',
  },
  absolute: {
    position: 'absolute',
  },
  col: {
    flex: 1,
    minWidth: 100,
  },
  dividerCol: {
    flexShrink: 0,
  },
  planTitle: {
    marginBottom: 16,
  },
  table: {},
  closeBtn: {
    position: 'absolute',
    right: 24,
    top: 24,
  },
  premiumIcon: {
    width: '190px',
    height: '100px',
  },
  ContentFree: {
    fontFamily: 'Manrope',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0px',
  },
  tabsContainer: {
    border: '1px solid #888fa9',
    width: '100%',
    maxWidth: '420px',
    display: 'inline-flex',
    padding: '0',
    marginBottom: '15px',
    height: '1px !important',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    msBorderRadius: '10px',
    oBorderRadius: '10px',
  },
  tab: {
    margin: '0',
    padding: '0 35px !important',
    height: '100%',
    color: '#2445a7',
    background: 'white',
  },
  borderRight: {
    borderRight: '1px solid #f0efef',
  },
  borderLeftRight: {
    borderLeft: '1px solid #2445a7 !important',
    borderRight: '1px solid #2445a7 !important',
  },
  activeTab: {
    fontWeight: 'bold !important',
    color: '#2445a7 !important',
    background: '#f1f3f9 !important',
  },
  bottomShadow: {
    boxShadow: '0px 10px 20px rgba(78, 89, 120, 0.06)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
    width: '100%',
  },
  mt: {
    marginTop: '1rem',
  },
}));

export default useStyles;
