import React from 'react';
import { useApiAutocomplete } from '../../hooks/useApiAutocomplete';
import { useApplication } from '../../services/application';
import Autocomplete from '../common/Inputs/Autocomplete';

const getOptionLabel = (option) => (option && option.id ? `${option.id} - ${option.value}` : '');

const setCodeAsValue = (val) => ({ ...val, value: val.code });

export const EntityStructureAutocomplete = (props) => {
  const { onChange, value, multi, labelKey = 'value', label, ...rest } = props;
  const application = useApplication();
  const { options, loading, handleValueChange, filterOptions, handleInputChange } = useApiAutocomplete({
    labelKey,
    onChange: React.useCallback((val) => onChange && onChange(setCodeAsValue(val)), [onChange]),
    getOptionList: (params) => application.call('vendors.getStructureList', params),
  });

  const validateValueArray = () => {
    if (Array.isArray(value)) {
      return value;
    } else {
      return [];
    }
  }

  return (
    <Autocomplete
      name="entity-structure"
      options={options}
      label={label}
      labelKey={labelKey}
      loading={loading}
      value={validateValueArray()}
      filterOptions={filterOptions}
      onChange={handleValueChange}
      multiple={multi}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};
