import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconPursuits = (props) => {
  const { width = 24, height = 24, fill = '#4E5978' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.94 11C20.48 6.83 17.17 3.52 13 3.06V1H11V3.06C6.83 3.52 3.52 6.83 3.06 11H1V13H3.06C3.52 17.17 6.83 20.48 11 20.94V23H13V20.94C17.17 20.48 20.48 17.17 20.94 13H23V11H20.94ZM12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19ZM12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8ZM12 10C10.8946 10 10 10.8946 10 12C10 13.1054 10.8946 14 12 14C13.1054 14 14 13.1054 14 12C14 10.8946 13.1054 10 12 10Z"
        fill={fill}
      />
    </svg>
  );
};

IconPursuits.propTypes = simpleIconPropsTypes;
