import colors from '../pallete';
import typography from '../typography';

const MuiAvatar = {
  root: {
    fontWeight: typography.fontWeightExtraBold,
    textTransform: 'uppercase',
    fontSize: typography.body1.fontSize,
    wordWrap: 'break-word',
    paddingLeft: 0,
    width: 100,
    height: 100,
  },
  colorDefault: {
    backgroundColor: colors.primary.main,
    color: colors.common.white,
  },
};

export default MuiAvatar;
