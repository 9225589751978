import { batch, connect } from 'react-redux';
import { generateListCallbacks } from '../../../store/helpers/listActionsHelpers';
import { actions } from '../../../store/listUserOrganizations';
import { usersActions as orgUsersActions } from '../../../store/orgUsers';

function mapStateToProps(state) {
  return {
    authUser: state.auth || {},
    listUserOrganizations: state.listUserOrganizations,
    settings: state.orgSettings,
    pendingOrg: state.orgUsers.pending,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    onInit: async (params) => {
      batch(() => {});
      return dispatch(actions.fetchList());
    },
    changeOrganization: (params) => dispatch(orgUsersActions.changeActiveOrganization(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
