import Grid from '@material-ui/core/Grid';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../../../../components/common/Buttons';
import { ModalDialog } from '../../../../components/common/Dialogs';
import { useTrans } from '../../../../services/i18n';
import { useAlertContext } from '../../../../contexts/AlertContext';
import { SpacingContainer } from '../../../../components/common/Containers';
//import { Autocomplete } from '../../../../components/common/Inputs';
import connect from './connect';
import Typography from '../../../common/Typography';
import { APP_MENU } from '../../../../constants';
import * as MENU_ICONS from '../../../../components/svg/Icons/Menu';
import { IconDashboard } from '../../../../components/svg/Icons/Menu';
import { Divider } from '../../../common/Divider';
import { ReactComponent as CurrentStepIcon } from './icons/currentStep.svg';
import { ReactComponent as PassStepIcon } from './icons/passStep.svg';
import { ReactComponent as InactiveStepIcon } from './icons/step.svg';
import { useApplication } from '../../../../services/application';

const OnboardingModal = ({ open, onClose, changeCurrentOnboardingStep, updateOnViewTour, subscription }) => {
  const { trans } = useTrans();
  const application = useApplication();
  const [tourStep, setTourStep] = useState(0);
  const { currentPlan } = subscription?.data;

  const isValidPlan = useMemo(() => {
    return !['', 'expired'].includes(currentPlan);
  }, [currentPlan]);

  const closeModal = () => {
    onClose();
    updateOnViewTour({ onViewTour: 1 });
    changeCurrentOnboardingStep({ step: 0 });
    //setTourStep(0);
  };

  const beginTour = async () => {
    await application.call('user.markOnViewTour', {});
    setTourStep(1);
    changeCurrentOnboardingStep({ step: 1 });
  };

  const nextStep = () => {
    const n = tourStep + 1;
    setTourStep(n);
    changeCurrentOnboardingStep({ step: n });
    if (n > 4) {
      closeModal();
    }
  };

  const skipTour = async () => {
    await application.call('user.markOnViewTour', {});
    closeModal();
  };

  const IconC = (i) => {
    const Icon = i && tourStep ? MENU_ICONS[i.icon] || IconDashboard : IconDashboard;

    return <Icon />;
  };

  const StepIcon = ({ step }) => {
    if (step === tourStep) {
      return <CurrentStepIcon />;
    } else if (step < tourStep) {
      return <PassStepIcon />;
    } else {
      return <InactiveStepIcon />;
    }
  };

  useEffect(() => {
    if (open && isValidPlan) setTourStep(0);
  }, [open, isValidPlan]);

  return (
    <ModalDialog
      open={open && isValidPlan}
      onClose={closeModal}
      title={
        tourStep === 0 ? (
          trans('a-quick-and-efficient-tour')
        ) : (
          <span style={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
            <span
              style={{
                background: '#F32445',
                color: 'white',
                marginRight: '16px',
                padding: '5px 6px 2px 6px',
                borderRadius: '12px',
              }}
            >
              {IconC(APP_MENU[tourStep - 1])}
            </span>
            {trans(APP_MENU[tourStep - 1]?.key)}
          </span>
        )
      }
      actions={
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item>
            {tourStep > 0 &&
              [1, 2, 3, 4].map((i, index) => {
                return (
                  <span style={{ margin: '0 3px' }} key={index}>
                    {' '}
                    <StepIcon step={i} />
                  </span>
                );
              })}
          </Grid>
          <Grid item>
            {tourStep === 0 ? (
              <>
                <Button variant="outlined" color="primary" onClick={skipTour} style={{ marginRight: '16px' }}>
                  {trans('skip')}
                </Button>
                <Button variant="contained" color="primary" onClick={() => beginTour()}>
                  {trans('begin-tour')}
                </Button>
              </>
            ) : (
              <Button variant="contained" color="primary" onClick={() => nextStep()}>
                {tourStep === 4 ? trans('finish-tour') : trans('continue')}
              </Button>
            )}
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={0}>
        {tourStep === 0 && (
          <div>
            <Typography variant="h3" weight="bold">
              {trans('welcome-to-usfcr')}!
            </Typography>
            <br />
            <Typography variant="body1">{trans('welcome-to-usfcr-des')}</Typography>
          </div>
        )}

        {tourStep > 0 && (
          <div>
            <Typography style={{ color: '#888FA9' }} component="span" fontFamily="lexend" variant="body2">
              {APP_MENU[tourStep - 1]?.onboardingDes}
            </Typography>

            <Divider light style={{ margin: '16px 0' }} />
            {APP_MENU[tourStep - 1] &&
              APP_MENU[tourStep - 1].items.map((i, index) => {
                const IconComponent = MENU_ICONS[i.icon] || IconDashboard;
                return (
                  i.onboardingDes && (
                    <div
                      key={`${i.key}-${index}`}
                      style={{
                        marginBottom: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span style={{ color: '#4E5978' }}>
                        <IconComponent style={{ marginRight: '10px', color: '#4E5978' }} />
                        <Typography
                          style={{ marginLeft: '16px', color: '#4E5978' }}
                          component="span"
                          fontFamily="lexend"
                          variant="body2"
                          className={''}
                        >
                          {trans(i.key)}
                        </Typography>
                      </span>
                      <span style={{ color: '#888FA9' }}>{i.onboardingDes ?? ''}</span>
                    </div>
                  )
                );
              })}
          </div>
        )}
      </SpacingContainer>
    </ModalDialog>
  );
};

export default connect(OnboardingModal);
