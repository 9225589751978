import React, { useEffect, useRef } from 'react';
import Typography from '../../Typography';
import SignatureCanvas from 'react-signature-canvas';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function Signature(props) {
  const { error, name, value, label = null, className, onChange, ...rest } = props;

  const signatureRef = useRef(null);

  const clear = () => {
    signatureRef.current.clear();
  };

  useEffect(() => {
    if (value) {
      signatureRef.current.fromDataURL(`data:image/png;base64,${value}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {label && (
        <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
          {label}
        </Typography>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <SignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{ width: 500, height: 175, style: { border: 'solid 1px black' } }}
          onEnd={() => {
            const event = {
              target: {
                name,
                value: signatureRef.current
                  .getTrimmedCanvas()
                  .toDataURL('image/png')
                  .replace('data:image/png;base64,', ''),
                type: 'signature',
              },
            };
            onChange(event);
          }}
          {...rest}
        />
        {/* Changed button to anchor */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
        <a href="javascript:void(0)" onClick={clear} style={{ cursor: 'pointer', color: '#007BFF' }}>
          Clear
        </a>
        <FormHelperText>{error}</FormHelperText>
      </div>
    </>
  );
}
