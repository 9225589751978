import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import * as Yup from 'yup';
import { LINKEDIN_URL_FORMAT } from '../../../../constants/regex';

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    intPrimeContractor: Yup.boolean().nullable(),
    intSubContractor: Yup.boolean().nullable(),
    publicVisibility: Yup.boolean().nullable(),
    linkedInUrl: Yup.string().nullable().matches(LINKEDIN_URL_FORMAT, trans('linkedin-error-msg')),
    budget: Yup.object()
      .shape({
        from: Yup.number()
          .nullable()
          .typeError('Must be a number')
          .test(
            'is-positive',
            'Value cannot be negative',
            (value) => value === null || value === undefined || value >= 0
          )
          .test(
            'is-not-negative-zero',
            'Value cannot be negative',
            (value) => value === null || value === undefined || 1 / value !== -Infinity
          ),
        to: Yup.number()
          .nullable()
          .typeError('Must be a number')
          .test(
            'is-positive',
            'Value cannot be negative',
            (value) => value === null || value === undefined || value >= 0
          )
          .test(
            'is-not-negative-zero',
            'Value cannot be negative',
            (value) => value === null || value === undefined || 1 / value !== -Infinity
          ),
      })
      .test('validate-budget', 'Budget to must be greater than budget from', function (value) {
        const { from, to } = this.parent.budget;
        if (from !== null && from !== undefined && to !== null && to !== undefined) {
          return to > from;
        }
        return true;
      }),
    consPerContract: Yup.number()
      .nullable()
      .typeError('Must be a number')
      .test('is-positive', 'Value cannot be negative', (value) => value === null || value === undefined || value >= 0)
      .test(
        'is-not-negative-zero',
        'Value cannot be negative',
        (value) => value === null || value === undefined || 1 / value !== -Infinity
      ),
    consAggregate: Yup.number()
      .nullable()
      .typeError('Must be a number')
      .test('is-positive', 'Value cannot be negative', (value) => value === null || value === undefined || value >= 0)
      .test(
        'is-not-negative-zero',
        'Value cannot be negative',
        (value) => value === null || value === undefined || 1 / value !== -Infinity
      ),
    servPerContract: Yup.number()
      .nullable()
      .typeError('Must be a number')
      .test('is-positive', 'Value cannot be negative', (value) => value === null || value === undefined || value >= 0)
      .test(
        'is-not-negative-zero',
        'Value cannot be negative',
        (value) => value === null || value === undefined || 1 / value !== -Infinity
      ),
    servAggregate: Yup.number()
      .nullable()
      .typeError('Must be a number')
      .test('is-positive', 'Value cannot be negative', (value) => value === null || value === undefined || value >= 0)
      .test(
        'is-not-negative-zero',
        'Value cannot be negative',
        (value) => value === null || value === undefined || 1 / value !== -Infinity
      ),
  })
);
