import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { useAlertContext } from '../../../../contexts/AlertContext';
import { useEffect, useState } from 'react';
import { Button } from '../../../../components/common/Buttons';
import { useTrans } from '../../../../services/i18n';
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog';
import connect from '../../connect';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ModalDialog from '../../../../components/common/Dialogs/ModalDialog';
import { Box } from '@material-ui/core';
import subscriptionCanceledIcon from '../../../../components/assests/subscription-canceled.svg';
import Typography from '../../../../components/common/Typography';
import { formatDatePretty } from '../../../../utils/dateUtils';

const ButtonCancelSubscription = (props) => {
  const {
    subscription,
    cancelSubscription,
    setCancelSubscription,
    fetchSubscriptionStatus,
    getOrganizationInfo,
    subscriptionEndDate,
    returnButton = false,
  } = props;

  const { trans } = useTrans();
  const [pending, setPending] = useState(false);
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useBooleanHandlers();
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers();
  const { successAlert, errorAlert } = useAlertContext();

  const onCancelSubscription = async () => {
    try {
      setPending(!pending);
      const { payload } = await cancelSubscription({ id: subscription.id }).finally(() => setPending(false));

      if (payload.success) {
        successAlert(payload.message);
        onOpenModal();
        // setCancelSubscription(payload.subscription);
      } else {
        errorAlert(payload.message);
      }
    } catch (error) {
      errorAlert(error.message);
      setPending(false);
    }
  };

  const handleCloseModal = async () => {
    onCloseModal();
    onCloseConfirm();
    await Promise.all([getOrganizationInfo(), fetchSubscriptionStatus()]);
  };

  return (
    <>
      {subscription && ['active', 'trialing'].includes(subscription.status) && !subscription.cancel && (
        <>
          {returnButton ? (
            <Button
              onClick={onOpenConfirm}
              pending={pending}
              color="primary"
              style={{
                fontWeight: 500,
              }}
            >
              {trans('cancel-subscription')}
            </Button>
          ) : (
            <div
              style={{
                cursor: 'pointer',
                color: '#2445A7',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
              onClick={onOpenConfirm}
              pending={pending}
              variant="flat"
              color="primary"
              fullWidth
            >
              <span>{trans('cancel-subscription')}</span>

              <ArrowForwardIosIcon style={{ fontSize: '13px' }} />
            </div>
          )}

          <ConfirmDialog
            open={openConfirm}
            onClose={onCloseConfirm}
            onConfirm={onCancelSubscription}
            pending={pending}
            title={trans('confirm-cancel-subscription')}
            text={trans('cancel-subscription-text')}
            noIcon={true}
            switchButtons={true}
            confirmTitle={`         ${trans('yes')}!         `}
            cancelTitle={trans('no-go-back')}
            varianConfirm="outlined"
            variantCancel="contained"
            grayLeftText={true}
          />

          {subscriptionEndDate && (
            <ModalDialog open={openModal} onClose={handleCloseModal}>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gridGap="1rem">
                <img src={subscriptionCanceledIcon} alt="subscription-canceled" />

                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" px={'1rem'}>
                  <Typography variant="h5" weight={600}>
                    {trans('your-subscription-has-been-canceled')}
                  </Typography>
                  <Typography variant="body1" weight="500" color="label">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: trans('subscription-canceled-modal-text').replace(
                          '{subscriptionEndDate}',
                          formatDatePretty(subscriptionEndDate, 'MMMM D, YYYY')
                        ),
                      }}
                    />
                  </Typography>
                </Box>

                <Button variant="outlined" color="primary" onClick={handleCloseModal}>
                  {trans('close')}
                </Button>
              </Box>
            </ModalDialog>
          )}
        </>
      )}
    </>
  );
};

export default connect(ButtonCancelSubscription);
