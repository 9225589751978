import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconExports = (props) => {
  const { width = 24, height = 24, fill = '#4E5978' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12L20.2 3.79999" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M21.0002 7.8V3H16.2002"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.1 3H9.3C4.8 3 3 4.8 3 9.3V14.7C3 19.2 4.8 21 9.3 21H14.7C19.2 21 21 19.2 21 14.7V12.9"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

IconExports.propTypes = simpleIconPropsTypes;
