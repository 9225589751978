import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconOrganizationDetails = (props) => {
  const { width = 64, height = 64 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none">
      <path
        stroke="#B2B8CF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M5.333 58.667h53.334M32 5.333a18.001 18.001 0 0 0 13.333 0v8a18.002 18.002 0 0 1-13.333 0v-8ZM32 13.333v8M45.333 21.333H18.667c-5.334 0-8 2.667-8 8v29.334h42.666V29.333c0-5.333-2.666-8-8-8ZM12.213 32h39.574"
      />
      <path
        stroke="#B2B8CF"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M21.307 32v26.667M31.973 32v26.667M42.64 32v26.667"
      />
    </svg>
  );
};

IconOrganizationDetails.propTypes = simpleIconPropsTypes;
