import { useEffect, useState, useMemo, useCallback } from 'react';
import useActions from '../../../../../hooks/dispatchers/useActions';
import { actions } from '../../../../../store/teamingOpportunities';
import { useAlertContext } from '../../../../../contexts/AlertContext';

export const useTeamingActions = (item) => {
  const [getTeamingCollaborator] = useActions([actions.getTeamingCollaborator]);
  const [changeStatusCollaborator] = useActions([actions.changeStatusCollaborator]);

  const [collaborator, setCollaborator] = useState(null);
  const { successAlert } = useAlertContext();

  const params = useMemo(() => {
    if (!item?.details) return {};
    return {
      id: item.itemId ?? 0,
      username: item.details ?? 0,
    };
  }, [item]);

  const fetchCollaborator = useCallback(async () => {
    if (!item?.details) return;

    const collaborator = await getTeamingCollaborator(params);
    if (collaborator && collaborator.payload) setCollaborator(collaborator.payload);
  }, [item, params, getTeamingCollaborator]);

  useEffect(() => {
    fetchCollaborator();
  }, [item, fetchCollaborator]);

  const handleAccept = useCallback(async () => {
    const result = await changeStatusCollaborator({ oppId: collaborator.id, status: 'accepted', type: 'internal' });
    await fetchCollaborator();
    successAlert('Accepted');
    return result;
  }, [changeStatusCollaborator, fetchCollaborator, collaborator, successAlert]);

  const handleReject = useCallback(async () => {
    const result = await changeStatusCollaborator({ oppId: collaborator.id, status: 'rejected', type: 'internal' });
    await fetchCollaborator();
    successAlert('Rejected');
    return result;
  }, [changeStatusCollaborator, fetchCollaborator, collaborator, successAlert]);

  return {
    collaborator,
    handleAccept,
    handleReject,
  };
};
