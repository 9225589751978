import palette from '../pallete';

const MuiToggleButton = {
  MuiToggleButtonGroup: {
    root: {
      padding: 0,
      backgroundColor: palette.gray.background,
      borderRadius: 4,
    },
    groupedHorizontal: {
      '&:not(:last-child)': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&:not(:first-child)': {
        borderLeft: 'none',
        marginLeft: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
  MuiToggleButton: {
    root: {
      border: 'none',
      borderRadius: 4,
      color: palette.primary.main,
      padding: 8,
      '&$selected': {
        backgroundColor: palette.secondary.main,
        color: palette.common.white,
        '&:hover': { backgroundColor: palette.secondary.main },
      },
    },
    sizeSmall: {
      padding: 3,
      borderRadius: 4,
    },
    sizeLarge: {
      padding: 14,
      minWidth: 52,
    },
  },
};

export default MuiToggleButton;
