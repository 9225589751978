import Grid from '@material-ui/core/Grid';
import React, { useState, useCallback } from 'react';
import { useForm } from '../../../../hooks/useForm';
import { useTrans } from '../../../../services/i18n';
import { Button } from '../../../../components/common/Buttons';
import { ModalDialog } from '../../../../components/common/Dialogs';
import { TextField } from '../../../../components/common/Inputs';
import { getInitialValues, useScheme } from './helpers';
import Typography from '../../../../components/common/Typography';
import { ReactComponent as NoVendorsIcon } from '../../icons/ico-no-vendors.svg';
import { Divider } from '../../../../components/common/Divider';
import connect from '../../connect';
import VendorFound from '../VendorFound';
import ContactModal from '../../../../components/common/Subscription/ContactModal';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import SaveFormTicket from '../../../../components/forms/SaveSupportTicket';
import { useAlertContext } from '../../../../contexts/AlertContext';

const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  validateOnBlur: false,
  validateOnSubmit: true,
};

const RegisterOrganizationForm = ({
  open,
  settings,
  addTicket,
  onClose,
  checkOrganization,
  registerOrg,
  openAddFeatureInfoOrg,
}) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const [vendorExists, setVendorExists] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [busyBtn, setBusyBtn] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [pendingRegister, setPendingRegister] = useState(false);
  const [openContact, onOpenContact, onCloseContact] = useBooleanHandlers();
  const [openFormTicket, onOpenFormTicket, onCloseFormTicket] = useBooleanHandlers();
  const { errorAlert } = useAlertContext();

  const onSubmit = useCallback(
    (values) => {
      onCheckVendor(values);
    },
    [onClose, checkOrganization]
  );

  const { getFieldProps, handleSubmit, values, resetForm } = useForm(schema, getInitialValues(), onSubmit, formOptions);

  const closeAddVendorModal = () => {
    setVendor(null);
    setBusyBtn(false);
    setVendorExists(false);
    setLoading(false);
    setIsClaimed(false);
    resetForm();
    onClose();
  };

  const onCheckVendor = (data) => {
    setLoading(true);
    return checkOrganization(data).then((res) => {
      setLoading(false);
      if (res.payload.exists) {
        setVendorExists(true);
        setVendor(res.payload.vendor);
        setIsClaimed(res.payload.isClaimed);
        setBusyBtn(true);
      }
      if (!res.payload.exists) {
        setVendorExists(false);
        setVendor(null);
        setBusyBtn(true);
        setIsClaimed(false);
      }
    });
  };

  const registerOrganization = () => {
    setPendingRegister(true);
    registerOrg({ vendorId: vendor.ueiCode || vendor.cageCode })
      .then((res) => {
        closeAddVendorModal();
        openAddFeatureInfoOrg(true);
      })
      .catch(() => {
        errorAlert('An error occurred, try again');
      })
      .finally(() => setPendingRegister(false));
  };

  const handleContact = () => {
    closeAddVendorModal();
    window.Beacon('open');
    window.Beacon('navigate', '/ask');
  };

  return (
    <ModalDialog
      open={open}
      onClose={() => closeAddVendorModal()}
      title={trans('register-your-organization')}
      actions={
        vendorExists &&
        vendor && (
          <div>
            <Button variant="outlined" color="primary" onClick={() => closeAddVendorModal()} disabled={pendingRegister}>
              {trans('cancel')}
            </Button>
            <Button
              disabled={isClaimed || isLoading}
              pending={pendingRegister}
              style={{ marginLeft: '16px' }}
              variant="contained"
              color="success"
              onClick={registerOrganization}
            >
              {trans('register')}
            </Button>
          </div>
        )
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" weight={600}>
            To get started, let’s find out if your organization is a part of USFCR portal already
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            margin: '10px 0 25px 0',
            padding: '13px 20px 16px',
            borderRadius: '8px',
            backgroundColor: '#fff1d3',
          }}
        >
          <Typography variant="h6" weight={600}>
            Please enter one of the identification codes below to complete automatically checking. This way you’ll be
            able to link your USFCR and SAM account together.{' '}
          </Typography>
        </Grid>
        <Grid container style={{ padding: '0  12px 16px 12px' }}>
          <Grid item xs={10}>
            <TextField
              label={trans('enter-organization-indicator')}
              placeholder={'UEI code or CAGE'}
              required
              maxLength={80}
              {...getFieldProps('term')}
            />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'end', padding: '0 0 2px 10px' }}>
            <Button pending={isLoading} size="large" variant="outlined" color="primary" onClick={handleSubmit}>
              {trans('Check')}
            </Button>
          </Grid>

          {!vendorExists && busyBtn && (
            <Grid
              container
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '50px 16px',
              }}
            >
              <Divider light />
              <NoVendorsIcon />
              <Typography style={{ width: '100%', textAlign: 'center', marginTop: '20px' }} variant="h5">
                Organization with «{values.term}» code not found
              </Typography>
              <Typography style={{ width: '100%', textAlign: 'center', color: '#888fa9' }} variant="h6">
                Contact our support to register y our organization or try another code
              </Typography>
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button style={{ marginInline: 'auto' }} onClick={handleContact} variant="outlined" color="primary">
                  {trans('contact-support')}
                </Button>
                <br />
                <span>
                  Or email us to{' '}
                  <a
                    style={{ fontWeight: 'bold', color: '#2445a7  ', textDecoration: 'none', textAlign: 'center' }}
                    href="mailto:appsupport@usfcr.com"
                  >
                    appsupport@usfcr.com
                  </a>
                </span>
              </div>
            </Grid>
          )}

          {vendorExists && vendor && <VendorFound item={vendor} />}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: '16px 0 0 0',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {busyBtn && vendorExists && !isClaimed && (
            <div
              style={{
                marginTop: '16px',
                padding: '30px 0 0 0',
                borderTop: '1px solid rgba(78, 89, 120, 0.12)',
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" weight={500}>
                Not registered on SAM.gov yet? <br />
                <Button
                  onClick={handleContact}
                  style={{ padding: 0, height: 'auto', fontWeight: 'bold', lineHeight: 1.6, fontSize: '0.9375rem' }}
                  color="primary"
                  variant="text"
                >
                  Contact us for support
                </Button>{' '}
                or email us to{' '}
                <a style={{ fontWeight: 'bold' }} href="mailto:appsupport@usfcr.com">
                  appsupport@usfcr.com
                </a>
              </Typography>
            </div>
          )}

          {busyBtn && vendorExists && isClaimed && (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                backgroundColor: 'rgba(255, 82, 37, 0.1)',
                padding: '16px',
                marginBottom: '16px',
                color: '#ff5225',
              }}
            >
              <Typography variant="h6" weight={500}>
                This organization has already been claimed
              </Typography>
            </div>
          )}

          {busyBtn && vendorExists && isClaimed && (
            <Typography style={{ textAlign: 'center' }} variant="h6" weight={500}>
              Not registered on SAM.gov yet?
              <br />
              <Button
                onClick={handleContact}
                style={{ padding: 0, height: 'auto', fontWeight: 'bold', lineHeight: 1.6, fontSize: '0.9375rem' }}
                color="primary"
                variant="text"
              >
                Contact us for support
              </Button>{' '}
              or email us to{' '}
              <a style={{ fontWeight: 'bold' }} href="mailto:appsupport@usfcr.com">
                appsupport@usfcr.com
              </a>
            </Typography>
          )}
        </Grid>
      </Grid>
      {!busyBtn && (
        <div
          style={{
            marginTop: '16px',
            padding: '30px 0 0 0',
            borderTop: '1px solid rgba(78, 89, 120, 0.12)',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" weight={500}>
            Not registered on SAM.gov yet? <br />
            <Button
              onClick={handleContact}
              style={{ padding: 0, height: 'auto', fontWeight: 'bold', lineHeight: 1.6, fontSize: '0.9375rem' }}
              color="primary"
              variant="text"
            >
              Contact us for support
            </Button>{' '}
            or email us to{' '}
            <a style={{ fontWeight: 'bold' }} href="mailto:appsupport@usfcr.com">
              appsupport@usfcr.com
            </a>
          </Typography>
        </div>
      )}
      <SaveFormTicket open={openFormTicket} onClose={onCloseFormTicket} addTicket={addTicket} />
      <ContactModal open={openContact} onClose={onCloseContact} />
    </ModalDialog>
  );
};

export default connect(RegisterOrganizationForm);
