import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconView = (props) => {
  const { width = 24, height = 24, variant = 'list' } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      {variant === 'list' ? (
        <path
          d="M4.059 19H19.94c.583 0 1.059-.45 1.059-1v-4c0-.55-.476-1-1.059-1H4.06C3.476 13 3 13.45 3 14v4c0 .55.476 1 1.059 1zM3 6v4c0 .55.476 1 1.059 1H19.94c.583 0 1.059-.45 1.059-1V6c0-.55-.476-1-1.059-1H4.06C3.476 5 3 5.45 3 6z"
          transform="translate(-1152.000000, -157.000000) translate(380.000000, 146.000000) translate(772.000000, 11.000000)"
        />
      ) : variant === 'quick' ? (
        <path
          d="M6 4c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1h2zm14 0c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H10c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1h10z"
          transform="translate(-1254 -320) translate(380 146) translate(0 172) translate(874 2)"
        />
      ) : variant === 'module' ? (
        <path
          d="M4 11h5V5H4v6zm0 7h5v-6H4v6zm6 0h5v-6h-5v6zm6 0h5v-6h-5v6zm-6-7h5V5h-5v6zm6-6v6h5V5h-5z"
          transform="translate(-701.000000, -2122.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(41.000000, 59.000000) translate(396.000000, 56.000000) translate(14.000000, 14.000000)"
        />
      ) : variant === 'table' ? (
        <path
          d="M3 14h4v-4H3v4zm0 5h4v-4H3v4zM3 9h4V5H3v4zm5 5h13v-4H8v4zm0 5h13v-4H8v4zM8 5v4h13V5H8z"
          transform="translate(-753.000000, -2122.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(41.000000, 59.000000) translate(396.000000, 56.000000) translate(66.000000, 14.000000)"
        />
      ) : variant === 'tableView' ? (
        <>
          <path
            d="M13.5 4.1V19.9C13.5 21.4 14.14 22 15.73 22H19.77C21.36 22 22 21.4 22 19.9V4.1C22 2.6 21.36 2 19.77 2H15.73C14.14 2 13.5 2.6 13.5 4.1Z"
            transform="translate(-753.000000, -2122.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(41.000000, 59.000000) translate(396.000000, 56.000000) translate(66.000000, 14.000000)"
          />
          <path
            d="M2 4.1V19.9C2 21.4 2.64 22 4.23 22H8.27C9.86 22 10.5 21.4 10.5 19.9V4.1C10.5 2.6 9.86 2 8.27 2H4.23C2.64 2 2 2.6 2 4.1Z"
            transform="translate(-753.000000, -2122.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(41.000000, 59.000000) translate(396.000000, 56.000000) translate(66.000000, 14.000000)"
          />
        </>
      ) : variant === 'listView' ? (
        <>
          <path
            d="M19.9 13.5H4.1C2.6 13.5 2 14.14 2 15.73V19.77C2 21.36 2.6 22 4.1 22H19.9C21.4 22 22 21.36 22 19.77V15.73C22 14.14 21.4 13.5 19.9 13.5Z"
            transform="translate(-753.000000, -2122.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(41.000000, 59.000000) translate(396.000000, 56.000000) translate(66.000000, 14.000000)"
          />
          <path
            d="M19.9 2H4.1C2.6 2 2 2.64 2 4.23V8.27C2 9.86 2.6 10.5 4.1 10.5H19.9C21.4 10.5 22 9.86 22 8.27V4.23C22 2.64 21.4 2 19.9 2Z"
            transform="translate(-753.000000, -2122.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(41.000000, 59.000000) translate(396.000000, 56.000000) translate(66.000000, 14.000000)"
          />
        </>
      ) : null}
    </SvgIcon>
  );
};

IconView.propTypes = simpleIconPropsTypes;
