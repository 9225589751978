import MUILink from '@material-ui/core/Link';
import React from 'react';
import { Link } from 'react-router-dom';

export default React.forwardRef(
  ({ href, target, to, component, underline = 'none', color = 'inherit', ...rest }, ref) => {
    return (
      <MUILink
        ref={ref}
        color={color}
        underline={underline}
        component={to ? Link : component}
        to={to}
        href={href}
        target={href ? '_blank' : target}
        {...rest}
      />
    );
  }
);
