import Grid from '@material-ui/core/Grid';
import React from 'react';
import Button from '../../../../components/common/Buttons/Button';
import { Chip } from '../../../../components/common/Chip';
import { SpacingContainer } from '../../../../components/common/Containers';
import Typography from '../../../../components/common/Typography';
import { IconClock, IconUser } from '../../../../components/svg';
import { SCOPES_ROUTES, SCOPE_GRANT_OPPORTUNITIES } from '../../../../constants';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';
import { useTrans } from '../../../../services/i18n';
import { intlDateWithTimeFormat, intlDateFormat } from '../../../../utils/dateUtils';
import { useMediaQuery } from '@material-ui/core';

export default function PipelineInfo({ item }) {
  const { trans } = useTrans();
  const isSmScreen = useMediaQueryDown('sm');
  const isLandscape = useMediaQuery('(orientation: landscape)');

  const [show, onShow, onHide] = useBooleanHandlers();
  const scopeRoute = SCOPES_ROUTES[SCOPE_GRANT_OPPORTUNITIES];
  const tags = item?.tags;

  return !item ? null : (
    <SpacingContainer py={isSmScreen && isLandscape ? 0 : 3} px={isSmScreen && isLandscape ? 0 : 2} className="center">
      <Grid container alignItems="center" spacing={1} justifyContent="center">
        {(show || !isSmScreen || !isLandscape) && (
          <>
            <Grid item xs={12}>
              <Typography variant={isSmScreen ? 'h4' : 'h2'} weight="500" fontFamily="lexend" noWrap>
                {item?.title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="center" spacing={5} direction="row">
                <Grid item xs={6}>
                  <Chip
                    icon={<IconUser rounded />}
                    label={
                      <Typography variant="button" weight="700">
                        <Typography variant="button" color="hint" weight="600">
                          {`${trans('Owner')} `}
                        </Typography>
                        {item?.username}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Chip
                    icon={<IconClock />}
                    label={
                      <Typography variant="button" weight="700">
                        <Typography variant="button" color="hint" weight="600">
                          {`${trans('updated')} `}
                        </Typography>
                        {isSmScreen
                          ? intlDateFormat(new Date(item?.updatedAt))
                          : intlDateWithTimeFormat(new Date(item?.updatedAt))}
                      </Typography>
                    }
                  />
                </Grid>
                {tags && tags.length > 0 ? (
                  <Grid item>
                    {tags
                      ? tags.map((tag, index) => (
                          <Chip key={index} label={tag.create} variant="outlined" color="secondary" />
                        ))
                      : undefined}
                  </Grid>
                ) : undefined}
              </Grid>
            </Grid>
          </>
        )}
        {isSmScreen && isLandscape && (
          <Grid item xs={12}>
            <Button onClick={show ? onHide : onShow} color="primary" size="small">
              {trans(show ? 'Hide' : 'ShowMoreInfo')}
            </Button>
          </Grid>
        )}
      </Grid>
    </SpacingContainer>
  );
}
