import { makeStyles } from '@material-ui/core/styles';

const headerBtn = (theme, width = 80) => ({
  padding: 0,
  borderLeftWidth: 1,
  borderRightWidth: 1,
  borderColor: theme.palette.gray.border,
  borderLeftStyle: 'solid',
  borderRightStyle: 'solid',
  borderRadius: 0,
  width,
  height: 80,
  zIndex: 999,
});

const appHeader = (theme, singleShadow) => ({
  backgroundColor: theme.palette.common.white,
  minHeight: 80,
  padding: '0',
  boxShadow: singleShadow ? '0px 1px 0px 0px #E9EBEE;' : '0 10px 20px 0 rgba(78, 89, 120, 0.06)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  zIndex: theme.zIndex.appBar,
  [theme.breakpoints.down('sm')]: {
    minHeight: 'auto',
  },
});

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    height: '100%',
    width: '100%',
    zIndex: theme.zIndex.drawer - 1,
    flex: 1,
  },
  appHeader: {
    ...appHeader(theme),
  },
  appHeaderSingle: {
    ...appHeader(theme, true),
  },
  searchDiv: {
    height: 80,
    borderLeftWidth: 1,
    borderColor: theme.palette.gray.border,
    borderLeftStyle: 'solid',
    [theme.breakpoints.down('sm')]: {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
      height: 61,
    },
  },
  searchText: {
    marginLeft: 20,
  },
  headerBtn: {
    ...headerBtn(theme),
  },
  header100Btn: {
    ...headerBtn(theme, null),
  },
  headerBtnMobile: {
    ...headerBtn(theme, 64),
  },

  actions: {
    borderTop: `1px solid ${theme.palette.gray.border}`,
  },
}));
