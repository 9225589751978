export class ApiError extends Error {
  /**
   * @readonly
   * @type {string}
   */
  name = 'API Error';
  /**
   * @readonly
   * @type {*&{}}
   */
  data;
  /**
   * @readonly
   * @type string
   */
  url;

  /**
   * @constructor
   * @param {string} message
   * @param {*&{}} data
   * @param {string} url
   */
  constructor(message, data, url) {
    super(message);
    this.url = url;
    this.data = data;
    this.stack = url ? `[${url}] ${super.toString()}` : super.toString();
  }
}

export class RpcError extends ApiError {
  /**
   * @readonly
   * @type {string}
   */
  name = 'RPC Error';
  /**
   * @readonly
   * @type {string}
   */
  code;

  /**
   * @constructor
   * @param {* & { code: string, message: string, data?: * }} rpcError
   * @param {string} method
   */
  constructor(rpcError, method) {
    super(rpcError.message, rpcError.data, method);
    this.code = rpcError.code;
  }
}
