import { connect } from 'react-redux';
import { actions } from '../../../../store/pursuits';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    storeLinkPursuit: (params) => dispatch(actions.storeLinkPursuit(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
