import { Dialog, DialogContent, Slide, Grid } from '@material-ui/core';
import Typography from '../../../../../components/common/Typography';
import { LoaderSmall } from '../../../../../components/common/LoaderSmall';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTrans } from '../../../../../services/i18n';

const PaperProps = { square: true };

const ModalProcessed = ({ open }) => {
  const { trans } = useTrans();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      className={'pos-center'}
      TransitionComponent={Slide}
      fullWidth
      PaperProps={{ ...PaperProps, style: { borderRadius: 0 } }}
      TransitionProps={{ direction: 'down' }}
    >
      <DialogContent className={classes.modalContent}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <LoaderSmall color="primary" size="4rem" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" fontFamily="lexend" weight="500" className={classes.center}>
              {trans('your-payment-is-being-processed')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  modalContent: {
    padding: 30,
  },
  center: {
    textAlign: 'center',
  },
}));

export default ModalProcessed;
