import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: '100%',
    '& > *': {
      minWidth: 0,
      '&:not(:last-child)': {
        marginRight: theme.spacing(1.5),
      },
    },
    '&$3mr > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
    '&$2mr > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    '&$1mr > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  fullWidth: {
    display: 'flex',
    width: '100%',
  },
  bottom: {
    marginBottom: -theme.spacing(1),
  },
  wrap: { flexWrap: 'wrap' },
  '3mr': {},
  '2mr': {},
  '1mr': {},
}));

export default function InlineContainer({
  children,
  className,
  spacing,
  edge,
  fullWidth = false,
  noWrap = true,
  ...rest
}) {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.container,
        className,
        fullWidth && styles.fullWidth,
        spacing && styles[`${spacing}mr`],
        edge && styles[edge],
        !noWrap && styles.wrap
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
