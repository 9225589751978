import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_PIPELINES } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const Pipelines = React.lazy(() => import('./Pipelines'));

export default function PipelinesWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_PIPELINES.path}>
          <Pipelines />
        </Route>
        <Redirect exact path="/*" to={ROUTE_PIPELINES.path} />
      </Switch>
    </Suspense>
  );
}
