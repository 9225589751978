import { Grid, FormControlLabel } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles, alpha } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IconRefresh from '@material-ui/icons/Refresh';
import React from 'react';
import useQueryCallbacks from '../../../../../../hooks/query/useQueryCallbacks';
import { useMediaQueryDown } from '../../../../../../hooks/useMediaQuery';
import { InlineContainer, SpacingContainer } from '../../../../../common/Containers';
import CheckBox, { SelectAllMenu } from '../../../../../common/Inputs/CheckBox';
import SortingPanel from '../../../../../generics/SortingPanel';
import { IconInbox } from '../../../../../svg/Icons';
import { IconRemove } from '../../../../../svg/Icons';
import connect from '../../connect';
import { orderOptions } from '../helpers';
import ActionsMenu from './ActionsMenu';
import { useTrans } from '../../../../../../services/i18n';
import Typography from '../../../../../common/Typography';
import { Button } from '../../../../../common/Buttons';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '../../../../../common/Inputs/TimePicker';
import CustomFormControl from './CustomFormControl';

function ActionsPanel({
  scope,
  notifications,
  isAllChecked,
  isSomeChecked,
  onRefreshList,
  onChangeOrder,
  onCheckAll,
  onMarkAllTrash,
  onRestoreAll,
  onDeleteAll,
  onRemoveAllChecked,

  onCheckRead,
  onCheckUnread,
  onMarkAsRead,
  onMarkAsTrashed,
  onMarkAsRestored,
  onDelete,

  notificationFilters,
  setNotificationFilters,
}) {
  const theme = useTheme();
  const { query, setQueryFromHref, deleteQueryFromHref } = useQueryCallbacks();
  const isSmScreen = useMediaQueryDown('sm');
  const { trans } = useTrans();

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 8,
    },
  }))(Tooltip);

  const onMarkAllAsRead = React.useCallback(() => {
    return onMarkAsRead(Array.from(notifications.items).map((i) => i.id));
  }, [notifications.items, onMarkAsRead]);

  const getButtonStyle = (isActive) =>
    isActive
      ? {
          backgroundColor: alpha(theme.palette.secondary.light, 0.04),
        }
      : {};

  const getCheckedValue = (key) => {
    return notificationFilters[key];
  };

  const setCheckedValue = (key, value) => {
    setNotificationFilters({ ...notificationFilters, [key]: value });
  };

  return (
    <SpacingContainer px={3} py={2}>
      <Grid container alignItems="center" wrap="nowrap" justifyContent="space-between">
        <Grid item>
          <InlineContainer spacing={isSmScreen ? 2 : 1}>
            <SelectAllMenu
              isSomeChecked={isSomeChecked}
              isAllChecked={isAllChecked}
              onCheckAll={onCheckAll}
              onCheckRead={onCheckRead}
              onCheckUnread={onCheckUnread}
              onRemoveAllChecked={onRemoveAllChecked}
            />

            <IconButton size="small" onClick={onRefreshList}>
              <IconRefresh />
            </IconButton>

            <ActionsMenu
              isSomeChecked={isSomeChecked}
              onMarkAllAsRead={onMarkAllAsRead}
              onMarkAsRead={onMarkAsRead}
              onAddToTrash={onMarkAsTrashed}
              onMarkAsRestored={onMarkAsRestored}
              onRemove={onDelete}
              onMarkAllTrash={onMarkAllTrash}
              onRestoreAll={onRestoreAll}
              onDeleteAll={onDeleteAll}
              isSmScreen={isSmScreen}
              count={notifications.count}
              archived={query.arch}
            />
          </InlineContainer>
        </Grid>
        <Grid item>
          <InlineContainer spacing={3}>
            <InlineContainer spacing={3}>
              <Typography weight="500" variant="body2" color="hint">
                {trans('filter')}
              </Typography>
              <CustomFormControl
                label={trans('saved-searches')}
                color="violet"
                checked={getCheckedValue('savedSearches')}
                onChange={(e) => setCheckedValue('savedSearches', e.target.checked)}
              />
              <CustomFormControl
                label={trans('pursuit-updates')}
                color="success"
                checked={getCheckedValue('pursuitUpdates')}
                onChange={(e) => setCheckedValue('pursuitUpdates', e.target.checked)}
              />
              <CustomFormControl
                label={trans('task-updates')}
                color="warning"
                checked={getCheckedValue('taskUpdates')}
                onChange={(e) => setCheckedValue('taskUpdates', e.target.checked)}
              />
              <CustomFormControl
                label={trans('team-updates')}
                color="info"
                checked={getCheckedValue('teamingUpdates')}
                onChange={(e) => setCheckedValue('teamingUpdates', e.target.checked)}
              />

              <Box
                style={{
                  width: '.25rem',
                  height: '.25rem',
                  borderRadius: '50%',
                  backgroundColor: theme.palette.gray.active,
                }}
              />
            </InlineContainer>

            <InlineContainer>
              <CustomTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                title="Notifications"
                placement="bottom"
              >
                <Button
                  color={!query.arch ? 'secondary' : 'default'}
                  data-href="arch=Y"
                  onClick={deleteQueryFromHref}
                  style={getButtonStyle(!query.arch)}
                >
                  <Typography variant="body2" weight="700" style={{ marginRight: '.25rem' }}>
                    {trans('inbox')}
                  </Typography>
                  <IconInbox />
                </Button>
              </CustomTooltip>
              <CustomTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                title={trans('trash')}
                placement="bottom"
              >
                <Button
                  color={query.arch ? 'secondary' : 'default'}
                  data-href="arch=Y"
                  onClick={setQueryFromHref}
                  style={{ ...getButtonStyle(query.arch) }}
                >
                  <Typography variant="body2" weight="700" style={{ marginRight: '.25rem' }}>
                    {trans('archive')}
                  </Typography>
                  <IconRemove />
                </Button>
              </CustomTooltip>
            </InlineContainer>
          </InlineContainer>
        </Grid>
      </Grid>
    </SpacingContainer>
  );
}

export default connect(ActionsPanel);
