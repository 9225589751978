import { connect } from 'react-redux';
import {
  getFormStackById,
  formSubmission,
  getOrganizationForms,
  formPartialSubmission,
  changeCurrentFormType,
  uploadFilesForm,
} from '../../store/clientPortal';
import { orgActions } from '../../store/orgSettings';
import { actions } from '../../store/business/overview';

function mapStateToProps(state) {
  return {
    formDetail: state.clientPortal.formDetail,
    organizationForms: state.clientPortal.organizationForms,
    currentFormType: state.clientPortal.currentFormType,
    settings: state.orgSettings,
    businessTeam: state.businessOverview.team,
    org: state.orgSettings,
    subscription: state.subscription,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFormStackById: (params) => dispatch(getFormStackById(params)),
    formPartialSubmission: (params) => dispatch(formPartialSubmission(params)),
    formSubmission: (params) => dispatch(formSubmission(params)),
    getOrganizationForms: (params) => dispatch(getOrganizationForms(params)),
    changeCurrentFormType: (params) => dispatch(changeCurrentFormType(params)),
    uploadFilesForm: (params) => dispatch(uploadFilesForm(params)),
    getOrganizationInfo: (params) => dispatch(orgActions.getOrganizationInfoAction(params)),
    fetch: () => dispatch(actions.getOverview({})),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
