import React from 'react';
import store from '../../store';
import Application from './application';

export const ApplicationEvents = Application.EVENTS;

export const application = new Application(store);

export const ApplicationContext = React.createContext(application);

/**
 * @return {Application}
 */
export const useApplication = () => React.useContext(ApplicationContext);

export const ApplicationProvider = ({ children }) => {
  return <ApplicationContext.Provider value={application}>{children}</ApplicationContext.Provider>;
};
