import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSelectedItem from '../../../../../hooks/useSelectedItem';
import { emptyArray } from '../../../../../utils/arrayUtils';
import {
  ROUTE_TASKS,
  ROUTE_ORG_SETTINGS,
  ROUTE_TEAMING_OPPORTUNITIES_DETAILS,
  ROUTE_PURSUIT_DETAILS,
  ROUTE_CONTRACT_OPPORTUNITIES_DETAILS,
  ROUTE_CONTRACT_AWARDS_DETAILS,
  ROUTE_FEDERAL_AGENTS_DETAILS,
  ROUTE_VENDORS_DETAILS,
  ROUTE_GRANT_OPPORTUNITIES_DETAILS,
} from '../../../../../constants';
import { containsDetails } from '../Notifications/Notification/CardActions';
import dayjs from 'dayjs';
import { formatDateRangePretty } from '../../../../../utils/dateUtils';

const NOTIFICATION_TYPES = {
  'Saved Searches': 'savedSearches',
  'Task Reminders': 'taskUpdates',
  'Pursuit Update': 'pursuitUpdates',
  'Team Updates': 'teamingUpdates',
};

export const useNotifications = (notifications, onMarkAsReadSingle, onChangePage, onRefreshList) => {
  const history = useHistory();
  const [fetchWeek, setFetchWeek] = useState(1);
  const [notificationFilters, setNotificationFilters] = useState({
    savedSearches: true,
    pursuitUpdates: true,
    taskUpdates: true,
    teamingUpdates: true,
  });

  const { items, count, pending, pagination, checkedItems } = notifications;

  const { hasItems, maxIndex } = useMemo(
    () => ({
      hasItems: !emptyArray(items),
      maxIndex: !emptyArray(items) ? items.length - 1 : 0,
    }),
    [items]
  );
  const [{ showItem, selectedIndex, isFirstPage, isLastPage }, onSetSelected, onCloseSelectedItem, onNext, onPrev] =
    useSelectedItem({ selectedIndex: null, maxIndex, onChangePage, count, resetOnClose: true, ...pagination });

  const openSelected = useMemo(() => showItem && !!items[selectedIndex], [showItem, items, selectedIndex]);

  const selectedItem = useMemo(() => {
    if (selectedIndex === null) return null;
    return items[selectedIndex];
  }, [items, selectedIndex]);

  const pushToSection = useCallback(
    (item) => {
      const routes = {
        TEAMING: ROUTE_TEAMING_OPPORTUNITIES_DETAILS.path.replace(':id', item.itemId),
        TASKS: `${ROUTE_TASKS.path}?task=${item.itemId}`,
        ORGANIZATION: ROUTE_ORG_SETTINGS.path,
        PURSUIT: `${ROUTE_PURSUIT_DETAILS.path.replace(':id', item.itemId)}?tab=discussions`,
        FGO: ROUTE_GRANT_OPPORTUNITIES_DETAILS.path.replace(':id', item.itemId),
        FCO: ROUTE_CONTRACT_OPPORTUNITIES_DETAILS.path.replace(':id', item.itemId),
        CA: `${ROUTE_CONTRACT_AWARDS_DETAILS.path.replace(':id', item.itemId)}?i=${item.itemId}`,
        VENDOR: ROUTE_VENDORS_DETAILS.path.replace(':id', item.itemId),
        AGENT: ROUTE_FEDERAL_AGENTS_DETAILS.path.replace(':id', item.itemId),
      };
      history.push(routes[item.scope]);
    },
    [history]
  );

  const onNotificationClicked = useCallback(
    (index, item) => {
      if (!item.read) {
        onMarkAsReadSingle({ id: item.id }).then(() => onSetSelected(0));
      }

      if (containsDetails(item.scope)) {
        onSetSelected(index);
      } else {
        onCloseSelectedItem();
        pushToSection(item);
      }
    },
    [onCloseSelectedItem, onMarkAsReadSingle, onSetSelected, pushToSection]
  );

  const onLoadMore = () => {
    // Not more than 1 month
    if (fetchWeek > 4) return;

    const newFetchWeek = fetchWeek + 1;
    setFetchWeek(newFetchWeek);
    onRefreshList({
      fetchWeek: newFetchWeek,
    });
  };

  const getEnabledNotificationTypes = useCallback(
    (filters = notificationFilters) => {
      return Object.keys(filters).filter((key) => filters[key]);
    },
    [notificationFilters]
  );

  const getFilteredItems = () => {
    const enabledNotificationTypes = getEnabledNotificationTypes();

    return items.reduce((acc, item) => {
      const newItems = [];
      for (const notification of item?.items) {
        const type = NOTIFICATION_TYPES[notification?.itemType ?? ''];
        if (enabledNotificationTypes.includes(type)) {
          newItems.push(notification);
        }
      }
      return newItems.length ? [...acc, { label: item.label, items: newItems }] : acc;
    }, []);
  };

  const resetNotificationFilters = () => {
    setNotificationFilters({
      savedSearches: true,
      pursuitUpdates: true,
      taskUpdates: true,
      teamingUpdates: true,
    });
  };

  const disableLoadMore = useMemo(() => {
    return fetchWeek >= 4 || !hasItems;
  }, [fetchWeek, hasItems]);

  const nothingFoundTitle = useMemo(() => {
    if (hasItems) {
      const endDate = dayjs();
      const startDate = dayjs().subtract(fetchWeek, 'week');
      return formatDateRangePretty(startDate, endDate);
    }

    return 'YourNotificationInboxisEmpty';
  }, [hasItems, fetchWeek]);

  const nothingFoundSubtitle = useMemo(() => {
    if (hasItems) {
      return 'nothing-found-for-this-period';
    }

    return 'AllNewNotificationAppear';
  }, [hasItems]);

  return {
    items: getFilteredItems(),
    count,
    pending,
    pagination,
    checkedItems,
    hasItems,
    maxIndex,
    showItem,
    selectedIndex,
    isFirstPage,
    isLastPage,
    onSetSelected,
    onCloseSelectedItem,
    onNext,
    onPrev,
    openSelected,
    selectedItem,
    onNotificationClicked,
    onLoadMore,
    notificationFilters,
    setNotificationFilters,
    resetNotificationFilters,
    disableLoadMore,
    nothingFoundTitle,
    nothingFoundSubtitle,
  };
};
