import { alpha as fade } from '@material-ui/core/styles/colorManipulator';
import palette from '../pallete';
import typography from '../typography';

const MuiChip = {
  root: {
    color: palette.common.black,
    backgroundColor: palette.gray.background,
    height: 28,
    '&.flex': { display: 'flex' },
    '& > svg:not($deleteIcon)': { marginLeft: 10, marginRight: '-5px' },
  },
  sizeSmall: { height: 20 },

  deleteIcon: {
    margin: '0 5px 0 -2px',
    width: 15,
    height: 15,
  },

  deletable: {
    '&:focus': { backgroundColor: undefined },
  },

  label: {
    fontSize: typography.caption.fontSize,
    fontWeight: 'bold',
    paddingLeft: 10,
    paddingRight: 10,
    '.mui-chip-icon &': { padding: 2, width: 28 },
  },
  labelSmall: {
    paddingLeft: 6,
    paddingRight: 6,
    marginBottom: 1,
  },

  outlinedPrimary: { borderColor: fade(palette.primary.main, 0.3) },
  outlinedSecondary: { borderColor: fade(palette.secondary.main, 0.3) },
};

export default MuiChip;
