import React, { useEffect } from 'react';

import { IconButton } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

import connect from './connect';

const Banner = ({ getBanner, banner }) => {
  const [openBanner, setOpenBanner] = React.useState(false);
  const [bannerInfo, setBannerInfo] = React.useState();

  useEffect(() => {
    if (banner.payload?.banner?.value) {
      let newBannerInfo = JSON.parse(banner.payload.banner.value);
      let localBannerInfo = getLocalBannerInfo();
      if (localBannerInfo) {
        let createdDateBannerLocal = new Date(localBannerInfo.createAt);
        let createdDateBannerNew = new Date(newBannerInfo.createAt);
        if (createdDateBannerLocal < createdDateBannerNew) {
          setupNewBannerInfo(newBannerInfo);
        } else {
          setupBannerInfo(localBannerInfo);
        }
      } else {
        setupNewBannerInfo(newBannerInfo);
      }
    }
  }, [banner]);

  function getLocalBannerInfo() {
    let localBannerInfo = JSON.parse(localStorage.getItem('bannerInfo'));
    if (localBannerInfo) {
      return localBannerInfo;
    } else {
      return null;
    }
  }

  function setupBannerInfo(localBannerInfo) {
    setBannerInfo(localBannerInfo);
    setOpenBanner(!localBannerInfo.wasClosed);
  }

  function setupNewBannerInfo(payload) {
    localStorage.setItem('bannerInfo', JSON.stringify(payload));
    setBannerInfo(payload);
    setOpenBanner(!payload.wasClosed);
  }

  function closeBanner() {
    let localBannerInfo = JSON.parse(localStorage.getItem('bannerInfo'));
    localBannerInfo.wasClosed = true;
    localStorage.setItem('bannerInfo', JSON.stringify(localBannerInfo));
    setOpenBanner(false);
  }

  useEffect(() => {
    getBanner();
  }, []);

  function Alert(props) {
    return <MuiAlert style={{ borderRadius: 0 }} elevation={6} variant="filled" {...props} />;
  }

  return (
    <>
      {bannerInfo && (
        <Collapse in={openBanner}>
          <Alert
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={closeBanner}>
                {' '}
                <CloseIcon fontSize="inherit"></CloseIcon>{' '}
              </IconButton>
            }
            severity={bannerInfo.severity}
          >
            {bannerInfo.message}
          </Alert>
        </Collapse>
      )}
    </>
  );
};

export default connect(Banner);
