import { IconAddPhoto } from '../../../svg';
import { Button } from '../../Buttons';
import React from 'react';
import { useTrans } from '../../../../services/i18n';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Slider } from '@material-ui/core';
import { ModalDialog } from '../../Dialogs/ModalDialog';
import Typography from '../../Typography';
import getCroppedImg from './utils';
import { IconZoomIn, IconZoomOut } from '../../../../components/svg/Icons';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 0,
  },
  avatarCover: {
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    aspectRatio: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    borderRadius: 8,
    marginBottom: 10,
    backgroundColor: theme.palette.gray.background,
    [theme.breakpoints.down('sm')]: {
      height: 158,
      width: 158,
    },
  },
  label: {
    marginBottom: 5,
  },
  avatarImage: {
    width: '100%',
    height: 'auto',
  },
  controls: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
}));
const CustomSlider = withStyles({
  rail: {
    backgroundColor: '#4E59781F',
  },
  thumb: {
    width: '1.25rem',
    height: '1.25rem',
    marginTop: '-0.56rem',
  },
})(Slider);

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function UploadAvatar({ pending = false, handleSetAvatar, settings, handleRemoveAvatar }) {
  const { trans } = useTrans();
  const classes = useStyles();
  const { avatarId, avatarUrl } = settings;

  const [imgSrc, setImgSrc] = React.useState('');
  const [open, setOpen] = React.useState('');
  const imgRef = React.useRef(null);
  const [crop, setCrop] = React.useState();
  const [completedCrop, setCompletedCrop] = React.useState();
  const [scale, setScale] = React.useState(1);
  const [rotate] = React.useState(0);
  const [aspect] = React.useState(4 / 3);

  const inputFile = React.useRef(null);
  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const handleChange = (event, newValue) => {
    setScale(newValue);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const onSelectFile = () => {
    inputFile.current?.click();
  };

  const onChangeFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
      setOpen(true);
    }
  };

  const uploadImage = async () => {
    try {
      const image = await getCroppedImg(imgSrc, completedCrop, scale, rotate);
      const data = {
        currentTarget: {
          files: [new File([image], `profile_image.${image.type.split('/').pop()}`)],
        },
      };
      await handleSetAvatar(data);
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.root}>
      <Typography weight="500" className={classes.label} fontFamily="lexend" color="label" variant="body2">
        {trans('logo')}
      </Typography>
      <div className={classes.avatarCover}>
        {avatarId && avatarUrl ? (
          <img src={avatarUrl} alt="avatar" className={classes.avatarImage} />
        ) : (
          <IconAddPhoto />
        )}
      </div>
      <Grid container item xs={12} alignItems="center" className={classes.controls}>
        <Button
          pending={pending}
          id="upload-avatar-btn"
          variant={'text'}
          size="small"
          color="primary"
          onClick={onSelectFile}
        >
          {trans(avatarId ? 'change' : 'upload')}
          <input
            ref={inputFile}
            id="input-upload-avatar"
            type="file"
            style={{ display: 'none' }}
            onChange={onChangeFile}
          />
        </Button>
        {avatarId && (
          <Button id="remove-avatar" variant={'text'} size="small" onClick={handleRemoveAvatar} color="primary">
            {trans('remove')}
          </Button>
        )}
      </Grid>
      <ModalDialog
        open={open}
        onClose={closeModal}
        title={trans('edit-image-profile')}
        actions={
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item style={{ width: '33%' }} />
            <Grid item style={{ width: '33%' }}>
              <Grid container style={{ width: '100%' }} spacing={2} justifyContent="space-between">
                <Grid item>
                  <IconZoomOut />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <CustomSlider
                    value={scale}
                    onChange={handleChange}
                    step={0.1}
                    max={3}
                    min={0}
                    track={false}
                    color="gray"
                  />
                </Grid>
                <Grid item>
                  <IconZoomIn />
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ width: '33%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="success" onClick={uploadImage}>
                {trans('save')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{
              position: 'relative',
              width: '42.5rem',
              height: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                style={{}}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </Grid>
        </Grid>
      </ModalDialog>
    </div>
  );
}
