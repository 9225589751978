import colors from '../pallete';

const MuiAutocomplete = {
  paper: {
    backgroundColor: colors.background.input,
  },
  root: {
    '& > .achiddenlabel > [class*="MuiFilledInput-root"]': {
      paddingTop: 10,
      paddingBottom: 9,
      '& $input': { padding: '6px 6px' },
    },
  },
  inputRoot: {
    '&[class*="MuiFilledInput-root"]': {
      paddingTop: 9,
      paddingLeft: 9,
      paddingBottom: 9,
      '& $input': { padding: '7px 0 8px 14px' },
    },
    '&[class*="MuiFilledInput-root"][class*="MuiFilledInput-marginDense"]': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      '& $input': {
        padding: '15px 0 0 10px',
        '&[class*="MuiFilledInput-inputHiddenLabel"]': {
          paddingTop: 7.5,
          paddingBottom: 7.5,
        },
      },
    },
  },
  tag: {
    height: 30,
    borderRadius: 4,
    backgroundColor: colors.common.white,
    '& > [class*="label"]': {
      fontWeight: 'normal',
      fontSize: '13px',
    },
    '& > [class*="deleteIcon"]': {
      margin: '0 7px 0 0',
    },
  },
};

export default MuiAutocomplete;
