import makeStyles from '@material-ui/core/styles/makeStyles';
import MUITextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import React from 'react';
import Typography from '../../Typography';

const useStyles = makeStyles({
  bigInput: {
    '& input': { paddingTop: 21, paddingBottom: 22 },
  },
});

export default function Signature(props) {
  const {
    InputProps: _InputProps,
    inputProps: _inputProps,
    error,
    maxLength,
    label = null,
    fullWidth = true,
    disableBrowserAutoComplete = true,
    big = false,
    className,
    id = '',
    name = '',
    bgColor = '',
    align = '',
    borderRadius1rem = '',
    labelWeight = '500',
    labelFontFamily = 'lexend',
    labelColor = 'label',
    labelGutter = 'label',
    labelVariant = 'body2',
    ...rest
  } = props;
  const InputProps = _InputProps || {};
  const inputProps = _inputProps || {};
  const styles = useStyles();
  InputProps.disableUnderline = true;
  if (disableBrowserAutoComplete) inputProps.autoComplete = 'off';
  if (typeof maxLength === 'number') inputProps.maxLength = maxLength;
  return (
    <>
      {label && (
        <Typography
          weight={labelWeight}
          fontFamily={labelFontFamily}
          color={labelColor}
          gutter={labelGutter}
          variant={labelVariant}
        >
          {label}
        </Typography>
      )}
      <MUITextField
        id={id || `input-${name}`}
        name={name}
        InputProps={InputProps}
        inputProps={inputProps}
        variant="filled"
        helperText={error}
        error={Boolean(error)}
        hiddenLabel={!(error || label)}
        fullWidth={fullWidth}
        autoComplete={'off'}
        className={clsx(
          className,
          big && styles.bigInput,
          bgColor && `textfield-${bgColor}`,
          align && `textfield-${align}`,
          borderRadius1rem && `textfield-${borderRadius1rem}`
        )}
        {...rest}
      />
    </>
  );
}
