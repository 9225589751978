import MUIListItemIcon from '@material-ui/core/ListItemIcon';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  small: { minWidth: 32 },
  medium: { minWidth: 40 },
});

export default function ListItemIcon({ children, className, size = 'medium', ...rest }) {
  const styles = useStyles();

  return (
    <MUIListItemIcon className={clsx(className, styles[size])} {...rest}>
      {children}
    </MUIListItemIcon>
  );
}
