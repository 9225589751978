import { useSelector } from 'react-redux';
import {
  ROUTE_CHECKOUT,
  ROUTE_DEMO_DASHBOARD,
  ROUTE_PLANS_PRIVATE,
  ROUTE_PROFILE,
  ROUTE_SUCCESS_PAYMENT,
} from '../constants';
import dayjs from 'dayjs';
import { TIERS } from '../utils/subscription';

export const LIMITED_VALID_ROUTES = [
  ROUTE_DEMO_DASHBOARD.path,
  ROUTE_PLANS_PRIVATE.path,
  ROUTE_CHECKOUT.path,
  ROUTE_SUCCESS_PAYMENT.path,
  ROUTE_PROFILE.path,
];

export const useShouldRedirectLimitedUsers = (authorized, pathname) => {
  const subscription = useSelector((state) => state.subscription);
  const limitedDeletionPatchDate = useSelector((state) => state.globalSettings.limitedAccountsDeletionDate);
  const daysToDisable = useSelector((state) => state.globalSettings.limitedAccountsDaysToDisable);

  const isLimited = subscription?.data?.currentPlan === TIERS.LIMITED;
  const disableFeatures = shouldDisableFeaturesForLimitedUsers(limitedDeletionPatchDate, daysToDisable);

  const isValidRouteForLimited = LIMITED_VALID_ROUTES.includes(pathname);

  return authorized && isLimited && disableFeatures && !isValidRouteForLimited;
};

export const shouldDisableFeaturesForLimitedUsers = (limitedDeletionPatchDate, daysToDisable = 30) => {
  return dayjs().isAfter(dayjs(limitedDeletionPatchDate).add(daysToDisable, 'days'));
};
