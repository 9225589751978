import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

export function useItem({ item, downloadInvoice }) {
  const history = useHistory();
  const [shareInvoiceOpen, setShareInvoiceOpen] = useState(false);

  const openShareInvoice = () => setShareInvoiceOpen(true);
  const closeShareInvoice = () => setShareInvoiceOpen(false);

  const show = (id) => {
    history.push(`/invoice/${id}`);
  };

  const download = (id) => {
    downloadInvoice({ id, fileName: `${item.number}.pdf` });
  };

  const interval = useMemo(() => {
    if (item.total < 0) {
      return item.lines.data[0].price.recurring.interval;
    }

    const validItem = item.lines.data.find((i) => !i.description.toLowerCase().startsWith('unused'));
    return validItem?.price.recurring.interval;
  }, [item]);

  return { shareInvoiceOpen, openShareInvoice, closeShareInvoice, show, download, interval };
}
