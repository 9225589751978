import { batch, connect } from 'react-redux';
import { generateListCallbacks } from '../../../store/helpers/listActionsHelpers';
import { actions } from '../../../store/listOfNotInterested';

function mapStateToProps(state) {
  return {
    listOfNotInterested: state.listOfNotInterested,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    onDelete: (id) =>
      batch(async () => {
        await dispatch(actions.removeFromNotInterestedAction(id));
        await dispatch(actions.fetchList());
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
