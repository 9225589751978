import { connect } from 'react-redux';
import { actions } from '../../store/billing';
import { subscriptionActions } from '../../store/subscription';
import { orgActions } from '../../store/orgSettings';

function mapStateToProps(state) {
  return {
    products: state.billing.products,
    pending: state.billing.pending,
    selectedPlan: state.billing.selectedPlan,
    subscription: state.subscription,
    auth: state.auth,
    org: state.orgSettings,
    defaultPaymentMethod: state.billing.defaultPaymentMethod,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProducts: (params) => dispatch(actions.getProducts(params)),
    setSelectedPlan: (params) => dispatch(actions.setSelectedPlan(params)),
    updateSubscription: (params) => dispatch(actions.updateSubscription(params)),
    getOrganizationInfo: (params) => dispatch(orgActions.getOrganizationInfoAction(params)),
    getSubscriptionStatus: (params) => dispatch(subscriptionActions.fetch(params)),
    getCustomerBilling: () => dispatch(actions.getCustomerBilling()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
