import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_FEDERAL_AGENTS } from '../../constants';
import { application } from '../../services/application';
import {
  extendListBuilder,
  generateListActions,
  generateSearchActions,
  INITIAL_LIST_STATE_EXTENDED,
} from '../helpers/listActionsHelpers';
import { onPending, onPendingDone } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['lastFCOPostedDate', 'desc'],
};

const listActions = generateListActions({
  scope: SCOPE_FEDERAL_AGENTS,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENTS.GET_LIST,
    EXPORT_CSV: API.FEDERAL_AGENTS.EXPORT_CSV,
  },
  getStore: (store) => store.federalAgents,
});

const searchActions = generateSearchActions({
  scope: SCOPE_FEDERAL_AGENTS,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.federalAgents,
});

const addToFavorite = createAsyncThunk('federalAgents/toggleFavorite', (params) => {
  return application.call(API.FEDERAL_AGENTS.TOGGLE_FAVORITE, params);
});

function onAddToFavorite(state, action) {
  const agent = state.items.find((item) => item.id === action.payload.id);
  if (agent) {
    agent.isPotentialPartner = action.payload.isPotentialPartner;
  }
  return Object.assign(state, { pending: false });
}

export const actions = {
  ...listActions,
  ...searchActions,
  addToFavorite,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, {
    ...listActions,
    ...searchActions,
  })
    .addCase(addToFavorite.fulfilled, onAddToFavorite)
    .addCase(addToFavorite.pending, onPending)
    .addCase(addToFavorite.rejected, onPendingDone);
});
