import Grid from '@material-ui/core/Grid';
import React, { useState, useEffect } from 'react';
import usePopover from '../../../../hooks/usePopover';
import Avatar from '../../../common/Cards/Avatar';
import ListItemTextBase from '../../../common/Containers/Base/ListItemTextBase';
import SpacingContainer from '../../../common/Containers/Base/SpacingContainer';
import { ContextMenuListItem, Popover } from '../../../common/Dialogs/Popover';
import Typography from '../../../common/Typography';
import { useTrans } from '../../../../services/i18n';
import { Divider } from '../../../common/Divider';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import useStyles from '../styles';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_BILLING, ROUTE_ORG_SETTINGS, ROUTE_PROFILE } from '../../../../constants';
import useLogout from '../../../../hooks/useLogout';
import connect from './connect';
import useUserFullName from '../../../../hooks/useUserFullName';
import CheckIcon from '@material-ui/icons/Check';
import styless from './styles.module.css';
import ChangeOrgStatus from './ChangeOrgStatus';
import MediumBanner from '../../../common/Subscription/BecomePremiumTopBar/MidBanner';
import CompletionUserDataModal from './CompletionUserDataModal';
import CompletionOrganizationDataModal from './CompletionOrganizationDataModal';

import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import ModalSwitchOrganizations from '../../../../containers/Profile/MyOrganizations/ModalSwitchOrganizations';

function UserMenu({ authUser, subscription, settings, changeOrganization }) {
  const isExtraSmallScreen = useMediaQueryDown('xs');
  const styles = useStyles();
  const { trans } = useTrans();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const onLogout = useLogout();
  const [openOrganizations, onOpenOrganizations, onCloseOrganizations] = useBooleanHandlers();
  const [appVersion, setAppVersion] = useState(localStorage.getItem('appVersion') || 'Unknown');

  const fullName = useUserFullName(authUser);

  const [pop, setPop] = React.useState(null);

  const handleClick = (event) => {
    setPop(event.currentTarget);
  };

  const handleClose = () => {
    setPop(null);
  };

  const location = useLocation();

  const changeActiveOrganization = (orgId) => {
    changeOrganization({ id: orgId }).then((res) => {
      const currentLocation = location.pathname;
      if (currentLocation.includes('/pipelines')) {
        window.location = '/pipelines';
      } else {
        window.location.reload();
      }
    });
    setPop(null);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setAppVersion(localStorage.getItem('appVersion') || 'Unknown');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const open = Boolean(pop);

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={`${styles.avatarRoot} pointer`}
        onClick={onOpenPopover}
        wrap="nowrap"
      >
        <Avatar value={fullName} position="left" size={40} style={{ marginRight: isExtraSmallScreen ? 6 : 12 }} />

        <Typography component="p" variant="body2" noWrap weight="bold" className={styles.avatarTitle}>
          {fullName}
          <Typography style={{ width: '100%', fontSize: '12px' }} component="span" variant="body2" noWrap>
            {settings.orgName}
          </Typography>
        </Typography>

        {!isExtraSmallScreen && <ArrowDropDown />}
      </Grid>
      <Popover id={'id-user-menu'} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        <SpacingContainer px={3} py={2}>
          <Typography variant="body1" weight="800" noWrap>
            {trans('account')}
          </Typography>
          <Typography variant="body2" color="hint" noWrap>
            {authUser.email}
          </Typography>
        </SpacingContainer>
        <Divider light spacing={1} />
        <ContextMenuListItem button component={Link} to={ROUTE_PROFILE.path}>
          <ListItemTextBase>{trans('my-profile')}</ListItemTextBase>
        </ContextMenuListItem>
        <ContextMenuListItem button component={Link} to={ROUTE_ORG_SETTINGS.path}>
          <ListItemTextBase>{trans('organization-settings')}</ListItemTextBase>
        </ContextMenuListItem>

        <ContextMenuListItem button component={Link} to={ROUTE_BILLING.path}>
          <ListItemTextBase>{trans('billing')}</ListItemTextBase>
        </ContextMenuListItem>

        <Divider light spacing={1} />
        {['dev', 'test'].includes(process.env.REACT_APP_ENVIRONMENT) && (
          <ChangeOrgStatus anchorEl={anchorEl} subscription={subscription} />
        )}

        <ContextMenuListItem button>
          <ListItemTextBase onClick={onOpenOrganizations}>{trans('switch-organization')}</ListItemTextBase>
          <Popover
            className={styless.pop}
            id={'simple-popover'}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div style={{ padding: '10px', position: 'relative' }}>
              {authUser &&
                Array.isArray(authUser.organizations) &&
                authUser.organizations.map((org) => (
                  <ContextMenuListItem key={org.id} button onClick={() => changeActiveOrganization(org.organizationId)}>
                    <ListItemTextBase className={styless.item}>
                      {!!org.active && <CheckIcon className={styless.itemIcon} />} <span>{org.name}</span>
                    </ListItemTextBase>
                  </ContextMenuListItem>
                ))}
            </div>
          </Popover>
        </ContextMenuListItem>

        <ContextMenuListItem button onClick={onLogout}>
          <ListItemTextBase>{trans('logout')}</ListItemTextBase>
        </ContextMenuListItem>

        <ContextMenuListItem>
          <MediumBanner showSmallText={false} />
        </ContextMenuListItem>
        <div className={styles.appVersionLabel}>App Version: {appVersion}</div>
      </Popover>
      <CompletionUserDataModal open={true} onClose={() => {}} />
      <CompletionOrganizationDataModal open={true} onClose={() => {}} />
      <ModalSwitchOrganizations open={openOrganizations} onClose={onCloseOrganizations} />
    </>
  );
}

export default connect(UserMenu);
