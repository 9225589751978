import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_EXPORTS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const Exports = React.lazy(() => import('./Exports'));

export default function ExportsWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_EXPORTS.path}>
          <Exports />
        </Route>
        <Redirect exact path="/*" to={ROUTE_EXPORTS.path} />
      </Switch>
    </Suspense>
  );
}
