import { useEffect } from 'react';
import useActions from '../../../../../hooks/dispatchers/useActions';
import { actions } from '../../../../../store/teamingOpportunities';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';

export const useTeamingEdit = (item) => {
  const [changeCurrentShow] = useActions([actions.changeCurrentShowOpportunity]);
  const [open, onOpen, onClose] = useBooleanHandlers(false);

  useEffect(() => {
    const fetchTaskList = async () => {
      if (!item?.pursuit) return;

      const naics = item.naics ? (Array.isArray(item.naics) ? item.naics.map((n) => n.code) : [item.naics.code]) : [];
      changeCurrentShow({ ...item, naics });
    };

    fetchTaskList();
  }, [item, changeCurrentShow]);

  return {
    open,
    onOpen,
    onClose,
  };
};
