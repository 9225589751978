import { Collapse } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../../constants';
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';
import { useApplication } from '../../../../services/application';
import { useTrans } from '../../../../services/i18n';
import { emptyArray } from '../../../../utils/arrayUtils';
import { formatToSlugCase } from '../../../../utils/helpers';
import { ReactComponent as ColoredLogoSvg } from '../../../assests/Logo-APP-Login.svg';
import logoMobile from '../../../assests/logo-mobile.png';
import { Paper, SpacingContainer } from '../../../common/Containers';
import MediumBanner from '../../../common/Subscription/BecomePremiumTopBar/MidBanner';
import Typography from '../../../common/Typography';
import * as MENU_ICONS from '../../../svg/Icons/Menu';
import { IconDashboard } from '../../../svg/Icons/Menu';
import connect from '../AppHeader/connect';
import EZSearch from '../EZSearch';
import { useAppMenu } from './hooks/useActiveGroup';
import useStyles from './styles';
import Badge from '@material-ui/core/Badge';

const AppMenu = ({
  openDrawer,
  setDrawerClose,
  settings,
  subscription,
  currentOnboardingStep,
  authUser,
  getAvatarDownloadUrl,
  counts,
}) => {
  const classes = useStyles();
  const { trans } = useTrans();
  const isSmallScreen = useMediaQueryDown('sm');
  const [pendingLMS, setPendingLMS] = useState(false);

  const { menu, setActive } = useAppMenu(subscription?.data);

  const dependOnSalesforce = ['business'];
  const application = useApplication();
  const [logoURL, setLogoURL] = useState();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const activeGroupOnboardingTour = (key) => {
    if (currentOnboardingStep === 1 && key === 'workplace') return true;
    if (currentOnboardingStep === 2 && key === 'discovery') return true;
    if (currentOnboardingStep === 3 && key === 'business') return true;
    if (currentOnboardingStep === 4 && key === 'knowledge') return true;

    return false;
  };

  const executeStringFunction = (fnstring) => {
    switch (fnstring) {
      case 'goToLearnWorlds':
        goToLearnWorlds();
        break;
      default:
        break;
    }
  };

  const clickOnLink = (route) => {
    if (route.function) {
      executeStringFunction(route.function);
    }
    if (isSmallScreen) setDrawerClose();
  };

  const goToLearnWorlds = async () => {
    if (pendingLMS) return;
    setPendingLMS(true);
    const res = await application.call('learnWorlds.singleSignOn', {});
    setPendingLMS(false);
    window.open(res.lw.url, '_blank');
  };

  useEffect(() => {
    if (settings.nameImageBranding != null) {
      if (!settings.externalImageBranding) {
        getAvatarDownloadUrl(settings.nameImageBranding).then((value) => setLogoURL(value.url));
      } else {
        setLogoURL(settings.nameImageBrandingUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  /***
   *
   */
  const renderRouteContent = (route) => {
    const badgeContent = (() => {
      switch (route.key) {
        case 'pipelines-pursuits-tasks':
          return counts.pipelineTasksCount;
        case 'personal-tasks':
          return counts.personalTasksCount;
        default:
          return null;
      }
    })();

    // If there's a badge count, display it with the text
    if (badgeContent !== null) {
      return (
        <span style={{ display: 'grid', gridTemplateColumns: '98% 10%' }}>
          <span>{`${trans(route.key)}`}</span>
          <Badge style={{ top: '0.7rem' }} badgeContent={badgeContent} color="secondary"></Badge>
        </span>
      );
    }

    // Default case, just render the translated text
    return trans(route.key);
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, openDrawer ? classes.drawerOpen : classes.drawerClose)}
      classes={{
        paper: openDrawer
          ? classes.drawerOpen
          : `${classes.drawerClose} ${windowSize.innerHeight < 705 ? classes.drawerLitleHeight : ''}`,
      }}
      anchor={isSmallScreen ? 'bottom' : 'left'}
      style={{
        zIndex: currentOnboardingStep > 0 ? 1702 : 'inherit',
      }}
    >
      <Paper
        component={SpacingContainer}
        style={{
          borderRadius: 0,
          height: '100%',
          paddingTop: '10px',
        }}
      >
        {authUser && !authUser.onViewTour && currentOnboardingStep > 0 && (
          <div
            style={{
              background: 'rgba(0, 0, 0, 0.5)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              overflow: 'hidden',
            }}
          ></div>
        )}
        {isSmallScreen && (
          <div className={classes.containerEzSearch}>
            <EZSearch setDrawerClose={setDrawerClose} />
          </div>
        )}

        <div className={classes.topPart}>
          <a href={ROUTES.ROUTE_DASHBOARD.path}>
            {logoURL ? (
              <img src={logoURL} style={{ width: 'auto', height: '50px', marginLeft: '15px' }} alt="" />
            ) : openDrawer ? (
              <ColoredLogoSvg
                short={openDrawer.toString()}
                style={{ width: 'auto', height: '50px', marginLeft: '15px' }}
              />
            ) : (
              <div>
                <img src={logoMobile} alt="mobile-logo" className={classes.mobileAppLogo} />
              </div>
            )}
          </a>
        </div>

        <Grid container direction={'column'} spacing={3} style={{ width: '90%' }}>
          {settings &&
            !emptyArray(settings.permissions) &&
            menu.map((group, groupIndex) => {
              if (group.items.length === 0) return null;

              let submenuItems = group.items;

              if (group.keyPermission) {
                const moduleExists = settings.permissions.find((i) => i.key === group.keyPermission);
                if (settings && !moduleExists) return null;

                submenuItems = submenuItems.filter((i) => {
                  const submoduleExists = moduleExists.submodules.includes(i.keyPermission);
                  return i.showMenu && submoduleExists;
                });
              }

              const GroupIcon = MENU_ICONS[group.icon] || IconDashboard;
              return (
                authUser && (
                  <Grid container item key={group.key} className={formatToSlugCase(group.key)} direction={'column'}>
                    {authUser && !authUser.onViewTour && currentOnboardingStep > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          zIndex: 1704,
                        }}
                      ></div>
                    )}
                    <Grid item>
                      <Grid
                        container
                        className={clsx(classes.groupInactive, {
                          [classes.groupActive]: group.active,
                          [classes.groupDisabled]: group.disabled,
                        })}
                        alignItems={'center'}
                        wrap="nowrap"
                        onClick={
                          group.disabled
                            ? null
                            : () => {
                                setActive(groupIndex);
                              }
                        }
                        style={
                          activeGroupOnboardingTour(group.key)
                            ? {
                                position: 'relative',
                                zIndex: 1703,
                                background: 'white',
                                paddingTop: '16px',
                              }
                            : {}
                        }
                      >
                        <Grid item>
                          <div
                            className={clsx(classes.groupIconInactive, {
                              [classes.groupIconActive]:
                                (group.active && authUser.onViewTour) || activeGroupOnboardingTour(group.key),
                              [classes.groupDisabled]: group.disabled,
                            })}
                          >
                            <GroupIcon />
                          </div>
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            fontFamily="lexend"
                            variant="body2"
                            className={clsx(classes.groupTitle, {
                              [classes.groupTitleActive]:
                                (group.active && authUser.onViewTour) || activeGroupOnboardingTour(group.key),
                              [classes.groupDisabled]: group.disabled,
                            })}
                          >
                            {trans(group.key)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      style={
                        activeGroupOnboardingTour(group.key)
                          ? {
                              position: 'relative',
                              zIndex: 1703,
                              background: 'white',
                              paddingBottom: '16px',
                              paddingRight: '120px',
                            }
                          : {}
                      }
                    >
                      <Collapse
                        in={Boolean((group.active && authUser.onViewTour) || activeGroupOnboardingTour(group.key))}
                        appear={true}
                        unmountOnExit={false}
                        mountOnEnter={true}
                      >
                        <Grid container direction={'column'} spacing={3} className={classes.groupContent}>
                          <Grid item />
                          {submenuItems
                            .filter((i) => i.showMenu)
                            .map((route, index) => {
                              const IconComponent = MENU_ICONS[route.icon] || IconDashboard;
                              return (
                                <Grid
                                  item
                                  key={index}
                                  className={`${formatToSlugCase(group.key)}-${formatToSlugCase(route.key)}`}
                                  style={{
                                    cursor: route.disabled ? 'not-allowed' : 'pointer',
                                  }}
                                >
                                  <Link
                                    onClick={() => clickOnLink(route)}
                                    to={route.path === 'none' ? '#' : route.path}
                                    component={NavLink}
                                    className={classes.inactive}
                                    activeClassName={route.path === 'none' ? '' : classes.active}
                                    underline="none"
                                    disabled
                                    style={{
                                      display: 'grid',
                                      gridTemplateColumns: '10% 90%',
                                      pointerEvents: route.disabled ? 'none' : 'auto',
                                    }}
                                  >
                                    <IconComponent />
                                    <Typography
                                      component="span"
                                      fontFamily="lexend"
                                      variant="subtitle1"
                                      className={classes.linkText}
                                      style={{ position: 'relative' }}
                                    >
                                      {renderRouteContent(route)}
                                      {route.function && pendingLMS && (
                                        <span
                                          style={{
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            right: '-25px',
                                            left: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'end',
                                            visibility: 'visible',
                                          }}
                                        >
                                          <CircularProgress
                                            style={{ marginLeft: '10px' }}
                                            color="inherit"
                                            size="1.29em"
                                          />
                                        </span>
                                      )}
                                    </Typography>
                                  </Link>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>
                )
              );
            })}
        </Grid>
        <br />
        <div className={classes.containerPremiumBanner}>
          <MediumBanner />
        </div>
      </Paper>
    </Drawer>
  );
};

export default connect(AppMenu);
