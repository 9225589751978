import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import { Button } from '../../common/Buttons';
import { ModalDialog } from '../../common/Dialogs';
import { TextField } from '../../common/Inputs';
import { useForm } from '../../../hooks/useForm';
import { useTrans } from '../../../services/i18n';
import { categoryTypeInfoCaption, getInitialValues, useScheme } from './helpers';
import { useAlertContext } from '../../../contexts/AlertContext';
import Typography from '../../common/Typography';
import { Box, MenuItem } from '@material-ui/core';
import { SelectField } from '../../common/Inputs';
import Paper from '../../common/Containers/Paper';
import { SpacingContainer } from '../../common/Containers/Base';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
};

export default function SaveSupportTicketForm({ item, open, onClose, addTicket, editTicket, categories }) {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const [isSend, setIsSend] = React.useState(false);

  const onSubmit = useCallback(
    (values) => {
      const editting = !!values.id;
      if (editting && typeof editTicket !== 'function') return;
      if (!editting && typeof addTicket !== 'function') return;

      setIsSend(true);

      Promise.resolve(editting ? editTicket(values) : addTicket(values))
        .then((res) => {
          if (res.payload) {
            successAlert(editting ? 'Ticket saved!' : 'Ticket added!');
            onClose();
            resetForm();
          } else {
            errorAlert('An error has occurred');
          }
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false));
    },
    [onClose, addTicket, infoAlert, errorAlert, successAlert, editTicket]
  );

  const { getFieldProps, handleSubmit, values, resetForm } = useForm(
    schema,
    getInitialValues(item),
    onSubmit,
    formOptions
  );

  const cancelTicket = () => {
    resetForm();
    onClose();
  };

  const handleSupportClick = () => {
    window.Beacon('open');
    window.Beacon('navigate', '/ask');
    onClose();
  };

  return (
    <ModalDialog
      open={open}
      onClose={cancelTicket}
      title={trans(values?.id ? 'edit-ticket' : 'create-ticket')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" disabled={isSend} onClick={cancelTicket}>
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" pending={isSend} onClick={handleSubmit}>
              {trans(values?.id ? 'save' : 'create')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          <Typography weight={'bold'} variant="body2" color="label" gutter="label">
            {trans('category-ticket')}
          </Typography>
          <SelectField
            getRenderValue={(value) => value}
            placeholder={trans('category-ticket')}
            {...getFieldProps('type')}
          >
            <MenuItem disabled value="">
              <em>{trans('category-ticket')}</em>
            </MenuItem>
            {categories?.map((c, index) => (
              <MenuItem key={index} value={c}>
                {c}
              </MenuItem>
            ))}
          </SelectField>
          {values.type && categoryTypeInfoCaption[values.type] && (
            <Paper
              style={{ background: '#f1f3f9', display: 'flex', width: '100%' }}
              className={''}
              bgColor="paperForm"
              component={SpacingContainer}
              py={2}
              px={2}
              radius="4"
            >
              <InfoIcon color="primary" style={{ marginRight: '10px' }} />
              <Typography variant="body2">
                <Typography variant="h6">{categoryTypeInfoCaption[values.type]}</Typography>
              </Typography>
            </Paper>
          )}
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            label={trans('subject')}
            placeholder={trans('required')}
            required
            maxLength={100}
            {...getFieldProps('subject')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={trans('description')}
            placeholder={trans('required')}
            required
            multiline
            minRows={4}
            maxRows={10}
            maxLength={255}
            {...getFieldProps('description')}
          />
        </Grid>
        <div
          style={{
            marginTop: '10px',
            borderTop: '1px solid rgba(78, 89, 120, 0.12)',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            style={{
              padding: '13px 20px 16px',
              borderRadius: '8px',
              backgroundColor: '#fff1d3',
            }}
          >
            <Typography variant="h6" weight={500}>
              By creating a <span style={{ color: '#2445a7', fontWeight: 'bold' }}>Service</span> Support Ticket, you
              are requesting assistance with a registration or service. <br />
              <br />
              If you need assistance with using the platform, please email{' '}
              <a style={{ fontWeight: 'bold' }} href="mailto:appsupport@usfcr.com">
                appsupport@usfcr.com
              </a>{' '}
              or{' '}
              <Button
                style={{ padding: 0, height: 'auto', lineHeight: '1.6', fontSize: '0.9375rem', marginBottom: '2px' }}
                color="primary"
                variant="text"
                onClick={handleSupportClick}
              >
                click here
              </Button>
              .
            </Typography>
          </Box>
        </div>
      </Grid>
    </ModalDialog>
  );
}
