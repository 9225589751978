import WebSocketRpcClient from './WebSocketRpcClient';
import HttpClient from './HttpClient';

export * from './errors';
export * from './helpers';

export const config = {
  wsUri: process.env.REACT_APP_WS_URI || `ws://${window.location.host}`,
  apiHost: process.env.REACT_APP_API_HOST || `https://${window.location.host}`,
};

/**
 * getWebSocketRpcClient
 * @return {WebSocketRpcClient}
 */
export function getWebSocketRpcClient() {
  if (!global['appWebSocketRpcClient']) {
    global['appWebSocketRpcClient'] = new WebSocketRpcClient(config.wsUri);
  }
  return global['appWebSocketRpcClient'];
}

/**
 * getHttpClient
 * @return {HttpClient}
 */
export function getHttpClient() {
  if (!global['appHttpClient']) {
    global['appHttpClient'] = new HttpClient(config.apiHost);
  }
  return global['appHttpClient'];
}

export { WebSocketRpcClient, HttpClient };
