import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import styles from './styles.module.css';

export const LoaderSmall = ({ size = 30, color = 'primary', text = '' }) => {
  return (
    <div className={styles.center}>
      <CircularProgress size={size} color={color} />{' '}
      {text && (
        <Typography variant="h3" color="primary" className={styles.text}>
          {text}
        </Typography>
      )}
    </div>
  );
};
