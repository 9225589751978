import { Button } from '@material-ui/core';
import React from 'react';
import { useTrans } from '../../../services/i18n';
import connect from './connect';

function ToggleCompleteButton({ item, onChangeStatusById }) {
  const { trans } = useTrans();

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={() => onChangeStatusById({ id: item.id, status: item.status })}
    >
      {trans(item.status === 'completed' ? 'mark-incomplete' : 'mark-complete')}
    </Button>
  );
}

export default connect(ToggleCompleteButton);
