import React from 'react';
import clsx from 'clsx';
import Backdrop from '@material-ui/core/Backdrop';
import { Paper, SpacingContainer } from '../../Containers';
import { Button } from '../../Buttons';
import { useTrans } from '../../../../services/i18n';
import Grid from '@material-ui/core/Grid';
import Typography from '../../Typography';
import { Divider } from '../../Divider';
import { ReactComponent as IconCheck } from '../../../assests/ico-check.svg';
import { ReactComponent as IconCross } from '../../../assests/ico-cross.svg';
import { IconClose } from '../../../svg';
import { IconButton, Portal } from '@material-ui/core';
import ContactModal from '../ContactModal';
import { SUBSCRIPTION_STATUS } from '../../../../utils/constants';
import { useSubscriptionStatus } from '../../../../hooks/useSubscriptionStatus';
import useStyles from './styles';
import connect from '../connect';

const featureTypes = {
  notIncluded: 'partial',
  limited: 'limited',
  included: 'included',
};

export const premiumFeatureKeys = [
  'unlimited-searches',
  'export-up-to-3000-records',
  'live-support',
  'unlimited-pursuits-pipelines-tasks',
  'full-access-federal-agents',
  'up-to-10-users',
  'feature-your-vendor-profile',
];

export const freeFeatureKeys = [
  {
    key: 'maximum-10-saved-searches',
    type: featureTypes.limited,
  },
  {
    key: 'no-exports',
    type: featureTypes.notIncluded,
  },
  {
    key: 'email-support-only',
    type: featureTypes.limited,
  },
  {
    key: 'no-pursuits-pipelines-tasks',
    type: featureTypes.notIncluded,
  },
  {
    key: 'no-access-federal-agents',
    type: featureTypes.notIncluded,
  },
  {
    key: 'only-1-user',
    type: featureTypes.limited,
  },
  {
    key: 'cant-feature-your-vendor-profile',
    type: featureTypes.notIncluded,
  },
];

const getIcon = (feature) => {
  switch (feature) {
    case featureTypes.notIncluded:
      return <IconCross style={{ color: '#888FA9' }} />;
    case featureTypes.limited:
      return <IconCheck style={{ color: '#888FA9' }} />;
    default:
      return <IconCheck style={{ color: '#17B471' }} />;
  }
};

const FeaturesModal = React.memo(({ open, fixed = false, onClose, subscription }) => {
  const styles = useStyles();
  const { trans } = useTrans();
  const [contactOpen, setContactOpen] = React.useState(false);
  const { pending, data } = useSubscriptionStatus(subscription);
  React.useLayoutEffect(() => {
    if (contactOpen && (pending || data?.status === SUBSCRIPTION_STATUS.SUBSCRIBED)) {
      setContactOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending, data?.status]);
  const fixedPortal = (children) => {
    if (fixed) {
      return <Portal>{children}</Portal>;
    }
    return children;
  };

  return fixedPortal(
    <React.Fragment>
      <ContactModal open={contactOpen} onClose={() => setContactOpen(false)} />

      <Backdrop className={clsx(styles.root, fixed ? styles.fixed : styles.absolute)} open={open} onClick={onClose}>
        <Paper
          component={SpacingContainer}
          px={7}
          py={5}
          className={styles.wrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <Grid container direction={'column'} spacing={3}>
            <Grid item align={'center'}>
              <Typography variant={'h1Medium'} weight={'700'}>
                {trans('more-benefits-premium')}
              </Typography>
              {onClose && (
                <IconButton className={styles.closeBtn} onClick={onClose} disableRipple>
                  <IconClose />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              <Grid container spacing={8} className={styles.table} wrap={'nowrap'}>
                <Grid item className={styles.col}>
                  <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                      <Typography variant={'h1'} className={styles.planTitle} weight={'700'}>
                        {trans('basic-account')}
                      </Typography>
                    </Grid>
                    <Grid container direction={'column'} spacing={2}>
                      {freeFeatureKeys.map((feature, i) => {
                        return (
                          <Grid item key={i}>
                            <Grid container spacing={2} wrap={'nowrap'}>
                              <Grid item>{getIcon(feature.type)}</Grid>
                              <Grid item>
                                <Typography variant={'h6'}>{trans(feature.key)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={styles.dividerCol} align={'center'}>
                  <Divider vertical />
                </Grid>
                <Grid item className={styles.col}>
                  <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                      <Typography variant={'h1'} className={styles.planTitle} weight={'700'}>
                        {trans('premium-account')}
                      </Typography>
                    </Grid>
                    {premiumFeatureKeys.map((feature, i) => (
                      <Grid item key={i}>
                        <Grid container spacing={2} wrap={'nowrap'}>
                          <Grid item>{getIcon(featureTypes.included)}</Grid>
                          <Grid item>
                            <Typography variant={'h6'}>{trans(feature)}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item align={'center'}>
              <Button variant={'contained'} color={'success'} size={'large'} onClick={() => setContactOpen(true)}>
                {trans('get-premium')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Backdrop>
    </React.Fragment>
  );
});

export default connect(FeaturesModal);
