import { connect } from 'react-redux';
import { actions } from '../../../../../store/teamingOpportunities';

function mapStateToProps(state) {
  return {
    internalOpportunities: state.teamingOpportunities.internalOpportunities,
    publicOpportunities: state.teamingOpportunities.publicOpportunities,
    openAddPublicOpportunityModal: state.teamingOpportunities.openAddPublicOpportunityModal,
    openPublicOpportunityDetail: state.teamingOpportunities.openPublicOpportunityDetail,
    openInternalOpportunityDetail: state.teamingOpportunities.openInternalOpportunityDetail,
    openAddInternalOpportunityModal: state.teamingOpportunities.openAddInternalOpportunityModal,
    openContactVendorModal: state.teamingOpportunities.openContactVendorModal,
    currentShowOpportunity: state.teamingOpportunities.currentShowOpportunity,
    interestedUsersInternal: state.teamingOpportunities.interestedUsersInternal,
    interestedContractorsExternal: state.teamingOpportunities.interestedContractorsExternal,
    recommendedContractors: state.teamingOpportunities.recommendedContractors,
    orgSettings: state.orgSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openAddPublicOpportunity: (params) => dispatch(actions.openAddPublicOpportunity(params)),
    openAddInternalOpportunity: (params) => dispatch(actions.openAddInternalOpportunity(params)),
    openContactVendor: (params) => dispatch(actions.openContactVendor(params)),
    openPublicOpportunityDetailAction: (params) => dispatch(actions.openPublicOpportunityDetailAction(params)),
    openInternalOpportunityDetailAction: (params) => dispatch(actions.openInternalOpportunityDetailAction(params)),
    addOpportunity: (params) => dispatch(actions.addOpportunity(params)),
    getInternalOpportunities: (params) => dispatch(actions.getInternalOpportunities(params)),
    getPublicOpportunities: (params) => dispatch(actions.getPublicOpportunities(params)),
    getTagsOpportunities: (params) => dispatch(actions.getTagsOpportunities(params)),
    changeCurrentShowOpportunity: (params) => dispatch(actions.changeCurrentShowOpportunity(params)),
    closeOpportunity: (params) => dispatch(actions.closeOpportunity(params)),
    getInterestedUsers: (params) => dispatch(actions.getInterestedUsers(params)),
    getInterestedContractors: (params) => dispatch(actions.getInterestedContractors(params)),
    changeStatusCollaborator: (params) => dispatch(actions.changeStatusCollaborator(params)),
    deleteRequestCollaborator: (params) => dispatch(actions.deleteRequestCollaborator(params)),
    changeInterestedUsersInternal: (params) => dispatch(actions.changeInterestedUsersInternal(params)),
    getRecommendedContractors: (params) => dispatch(actions.getRecommendedContractors(params)),
    changeInterestedContractorsExternal: (params) => dispatch(actions.changeInterestedContractorsExternal(params)),
    getProfile: (params) => dispatch(actions.getProfileInterestedContractor(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
