import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AvatarTitle from './AvatarTitle';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../Typography';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  directionColumn: {
    flexDirection: 'column',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  usfcrTeamCard: {
    width: '100%',
    borderRadius: 12,
    border: 'solid 1px rgba(136, 143, 169, 0.2)',
    margin: '35px 20px 20px 0',
    padding: '23px 30px',
  },
  titleType: {
    fontSize: 16,
    marginBottom: 10,
  },
});

export default function BusinessContact({ item, makeContactLink = false }) {
  const classes = useStyles();
  const { trans } = useTrans();

  return (
    <div className={classes.usfcrTeamCard}>
      <Typography className={classes.titleType} weight="bold">
        {trans(item.type)}
      </Typography>
      <AvatarTitle item={item} makeContactLink={makeContactLink} />
    </div>
  );
}
