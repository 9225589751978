import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../../common/Typography';
import { IconSortingSmall } from '../../../svg';

export default function SortItem({ item, onItemClick }) {
  const { trans } = useTrans();
  const { value, name, asc, desc } = Object(item);

  return !value ? null : (
    <>
      {!!desc && (
        <ListItem button onClick={onItemClick.bind(null, [value, 'desc'])}>
          <Grid container alignItems="center" spacing={3} wrap="nowrap">
            <Grid item>
              <Typography variant="body2" color="primary" weight="500" noWrap>
                {trans(name || value)}
              </Typography>
            </Grid>
            <Grid item container alignItems="center" wrap="nowrap" justifyContent="flex-end" spacing={1}>
              <Typography variant="caption" color="hint" weight="500" component="span" noWrap>
                {trans(desc)}
              </Typography>
              <Grid item>
                <IconSortingSmall />
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {!!asc && (
        <ListItem button onClick={onItemClick.bind(null, [value, 'asc'])}>
          <Grid container alignItems="center" spacing={3} wrap="nowrap">
            <Grid item>
              <Typography variant="body2" color="primary" weight="500" noWrap>
                {trans(name || value)}
              </Typography>
            </Grid>
            <Grid item container alignItems="center" wrap="nowrap" justifyContent="flex-end" spacing={1}>
              <Typography variant="caption" color="hint" weight="500" component="span" noWrap>
                {trans(asc)}
              </Typography>
              <Grid item>
                <IconSortingSmall down />
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </>
  );
}
