import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AlertContextConsumer, AlertContextProvider } from './contexts/AlertContext';
import Router from './Router';
import './services/api';
import { ApplicationProvider } from './services/application';
import { I18nProvider } from './services/i18n';
import store from './store';
import theme from './themes';
import { registerValidationSchema } from './utils/validationUtils';
import { Userpilot } from 'userpilot';

dayjs.extend(utc);
Userpilot.initialize('NX-39729983');

function App() {
  registerValidationSchema();
  return (
    <ThemeProvider theme={theme}>
      <StoreProvider store={store}>
        <I18nProvider>
          <AlertContextProvider>
            <ApplicationProvider>
              <CssBaseline />
              <BrowserRouter>
                <Router />
              </BrowserRouter>
              <AlertContextConsumer />
            </ApplicationProvider>
          </AlertContextProvider>
        </I18nProvider>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
