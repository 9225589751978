import React from 'react';
import ReactCodeInput from 'react-code-input';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  codeInput: {
    '& > input': {
      marginRight: 10,
      MozAppearance: 'textfield',
      width: 54,
      borderRadius: 4,
      fontSize: '1rem',
      height: 64,
      backgroundColor: theme.palette.gray.background,
      color: theme.palette.common.black,
      textAlign: 'center',
      border: '0px',
      [theme.breakpoints.down('xs')]: {
        marginRight: '2px !important',
        width: 50,
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: '2px !important',
        width: 40,
      },
      outline: 'none',
    },
  },
}));

export default function CodeInput(props) {
  const { onChange, ...rest } = props;
  const classes = useStyles();

  const onChangeHandler = React.useCallback((value) => onChange && onChange(value, rest.name), [onChange, rest.name]);

  return <ReactCodeInput {...rest} fields={6} className={classes.codeInput} onChange={onChangeHandler} />;
}
