import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  fixed: {
    flexShrink: 0,
  },
  dividedTop: {
    borderBottom: `1px solid ${theme.palette.gray.divider}`,
  },
  dividedBottom: {
    borderTop: `1px solid ${theme.palette.gray.divider}`,
  },
  scrollable: {
    flex: '1 0',
    overflow: 'auto',
  },
  body: {
    display: 'flex',
    width: '100%',
    flex: '1 0',
    overflow: 'hidden',
    position: 'relative',
  },
}));
