import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconTasks = (props) => {
  const { width, height, big = false } = props;

  return big ? (
    <SvgIcon width={width || 62} height={height || 56} viewBox="0 0 62 56">
      <path
        d="M144 0c1.657 0 3 1.343 3 3v14c0 .769-.29 1.47-.765 2 .476.53.765 1.232.765 2v14c0 .769-.29 1.47-.765 2 .476.53.765 1.232.765 2v14c0 1.657-1.343 3-3 3H88c-1.657 0-3-1.343-3-3V39c0-.768.289-1.47.764-2-.475-.53-.764-1.231-.764-2V21c0-.768.289-1.47.764-2-.475-.53-.764-1.231-.764-2V3c0-1.657 1.343-3 3-3zm0 38H88c-.552 0-1 .448-1 1v14c0 .552.448 1 1 1h56c.552 0 1-.448 1-1V39c0-.552-.448-1-1-1zm0-18H88c-.552 0-1 .448-1 1v14c0 .552.448 1 1 1h56c.552 0 1-.448 1-1V21c0-.552-.448-1-1-1zm0-18H88c-.552 0-1 .448-1 1v14c0 .552.448 1 1 1h56c.552 0 1-.448 1-1V3c0-.552-.448-1-1-1z"
        transform="translate(-730.000000, -509.000000) translate(251.000000, 182.000000) translate(0.000000, 247.000000) translate(394.000000, 80.000000)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width || 22} height={height || 22} viewBox="0 0 22 22">
      <path d="M18 1c1.657 0 3 1.343 3 3v2c0 .768-.289 1.47-.764 2 .475.53.764 1.231.764 2v2c0 .769-.29 1.47-.765 2 .476.53.765 1.232.765 2v2c0 1.657-1.343 3-3 3H4c-1.657 0-3-1.343-3-3v-2c0-.768.289-1.47.764-2C1.289 13.47 1 12.77 1 12v-2c0-.768.289-1.47.764-2C1.289 7.47 1 6.77 1 6V4c0-1.657 1.343-3 3-3zm0 14H4c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1zm0-6H4c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-2c0-.552-.448-1-1-1zm0-6H4c-.552 0-1 .448-1 1v2c0 .552.448 1 1 1h14c.552 0 1-.448 1-1V4c0-.552-.448-1-1-1z" />
    </SvgIcon>
  );
};

IconTasks.propTypes = simpleIconPropsTypes;
