import { emptyArray } from './arrayUtils';

export function splitFileName(fileName = '') {
  const aName = String(fileName).split('.');
  const ext = `.${aName.pop() || '*'}`;
  const name = aName.join('.');
  return [name, ext];
}

export function openFiles(files, meta = {}, exclude = undefined) {
  if (emptyArray(files)) return [];

  return files.reduce((documents, file) => {
    const founded = typeof exclude === 'function' ? exclude(file) : false;
    if (!founded) {
      const [name, ext] = splitFileName(file && file.name);
      documents.push(Object.assign({}, meta, { originFile: file, object: 'file', name, ext }));
    }
    return documents;
  }, []);
}

export function openEventFiles(event, meta, exclude) {
  const files = Array.from(
    (event && event.target && event.target.files) || (event && event.dataTransfer && event.dataTransfer.files) || []
  );

  return openFiles(files, meta, exclude);
}

export function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

export function bytesToKilobytes(bytes) {
  return Math.floor(bytes / 1024);
}
