import React from 'react';
import { SCOPES_ROUTES } from '../../constants';
import { useTrans } from '../../services/i18n';
import Autocomplete from '../common/Inputs/Autocomplete';

const getOptions = (trans) =>
  Object.keys(SCOPES_ROUTES).map((scope) => ({ i18nKey: trans(SCOPES_ROUTES[scope].key), value: scope, id: scope }));

export const ScopeAutocomplete = (props) => {
  const { trans } = useTrans();
  const { onChange, value, multi, label, name = 'scope', ...rest } = props;

  const options = React.useMemo(() => getOptions(trans), [trans]);
  const handleChange = React.useCallback((event) => onChange && onChange(event), [onChange]);

  const validateValueArray = () => {
    if (Array.isArray(value)) {
      return value;
    } else {
      return [];
    }
  }

  return (
    <Autocomplete
      name={name}
      options={options}
      label={label}
      labelKey="i18nKey"
      value={validateValueArray()}
      onChange={handleChange}
      multiple={multi}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      {...rest}
    />
  );
};
