import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconShield = (props) => {
  const { width = 18, height = 18 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 18 18">
      <path
        d="M8.00007 17.2085C7.70886 17.213 7.42154 17.1412 7.16673 17.0001L6.91673 16.8585C4.96946 15.7568 3.34945 14.158 2.22213 12.2255C1.09481 10.2929 0.500558 8.0958 0.500067 5.85848V5.74181C0.497425 5.44672 0.573182 5.15622 0.719595 4.9C0.866008 4.64377 1.07783 4.43102 1.3334 4.28348L7.16673 1.00015C7.4201 0.853866 7.70751 0.776855 8.00007 0.776855C8.29263 0.776855 8.58003 0.853866 8.8334 1.00015L14.6667 4.28348C14.9223 4.43102 15.1341 4.64377 15.2805 4.9C15.427 5.15622 15.5027 5.44672 15.5001 5.74181V5.85848C15.4979 8.09745 14.9011 10.2957 13.7708 12.2284C12.6405 14.1611 11.0171 15.7589 9.06673 16.8585L8.81673 17.0001C8.56686 17.1384 8.28564 17.2101 8.00007 17.2085ZM8.00007 2.45848L2.16673 5.74181V5.85848C2.16814 7.80106 2.6859 9.70836 3.66699 11.385C4.64807 13.0616 6.05721 14.4473 7.75007 15.4001L8.00007 15.5418L8.25007 15.4001C9.94292 14.4473 11.3521 13.0616 12.3331 11.385C13.3142 9.70836 13.832 7.80106 13.8334 5.85848V5.74181L8.00007 2.45848Z"
        fill="#888FA9"
      />
    </SvgIcon>
  );
};

IconShield.propTypes = simpleIconPropsTypes;
