import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  cont: {
    overflow: 'auto',
    position: 'relative',
  },
  xs: {
    maxHeight: 360,
  },
});

export default function Scrollable({ children, height = 'xs', className, ...rest }) {
  const styles = useStyles();

  return (
    <div className={clsx(styles.cont, className, styles[height])} {...rest}>
      {children}
    </div>
  );
}
