import React from 'react';
import BusinessContact from '../../../../../components/common/Cards/BusinessContact';

function Contact({ contactPerson }) {
  if (contactPerson) {
    return <BusinessContact item={contactPerson} makeContactLink />;
  }

  return null;
}

export default Contact;
