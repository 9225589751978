import React, { useState, useEffect } from 'react';
import Typography from '../common/Typography';
import { Grid, Paper, Box } from '@material-ui/core';
import { useStyles } from './styles';
import { useTrans } from '../../services/i18n';
import authBack from '../assests/auth-image-v2.jpg';
import cornerLogo from '../assests/corner-logo.svg';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useApplication } from '../../services/application';

const AuthLayout = ({ actions, children, urlBackground }) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const application = useApplication();

  const { trans } = useTrans();
  const [appVersion, setAppVersion] = useState(localStorage.getItem('appVersion') || 'Unknown');

  useEffect(() => {
    const handleStorageChange = () => {
      setAppVersion(localStorage.getItem('appVersion') || 'Unknown');
    };

    window.addEventListener('storage', handleStorageChange);
    async function getAppVersion() {
      const version = await application.rpcClient.call('healthCheck');
      const storedAppVersion = localStorage.getItem('appVersion');
      if (storedAppVersion !== version.version || !storedAppVersion) {
        console.log('App version changed. Reloading...');
        localStorage.setItem('appVersion', version.version);
        window.location.reload();
      }
    }
    getAppVersion();
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const now = new Date();
  return (
    <Paper
      className={classes.media}
      style={{
        backgroundImage: `
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        url(${urlBackground || authBack})`,
        minHeight: '100vh',
        ...(isMobile && { padding: 0 }),
        backgroundAttachment: 'fixed',
        overflow: 'hidden',
      }}
    >
      {isMobile ? (
        <Box display="flex" flexDirection="column" minHeight="100vh" padding={0} gridGap="1rem" overflow="auto">
          <Box>
            <img src={cornerLogo} alt="app-welcome-logo" />
          </Box>

          <Box flex={1} display="flex" justifyContent="center" alignItems="center" padding={'0 1rem'}>
            <div className={classes.content}>{children}</div>
          </Box>

          <Box>
            <div className={classes.mobileFooter}>
              <Typography variant="body2" component="p" weight="500">
                {trans('copyright')} {now.getFullYear()} {trans('all-rights-reserved')}
              </Typography>
              <Typography variant="body2" component="p" weight="700" style={{ marginTop: '.25rem' }}>
                {trans('not-a-government-agency')}
              </Typography>
              <Typography variant="body2" component="p" weight="700" style={{ marginTop: '.25rem' }}>
                {`App Version: ${appVersion}`}
              </Typography>
            </div>
          </Box>
        </Box>
      ) : (
        <Grid container spacing={0} direction="row" className={classes.height}>
          <Grid item xl={8} lg={7} md={6} sm="auto" xs="auto" className={classes.imageBlock}>
            <div className={classes.authHeader}>
              <div className={classes.logos}>
                <img src={cornerLogo} alt="app-welcome-logo" className={classes.cornerLogo} />
              </div>
            </div>
            <div className={classes.footer}>
              <Typography variant="body2" component="p" weight="500">
                {trans('copyright')} {now.getFullYear()} {trans('all-rights-reserved')}
              </Typography>
              <Typography variant="body2" component="p" weight="700" style={{ marginTop: '.25rem' }}>
                {trans('not-a-government-agency')}
              </Typography>
              <Typography variant="body" component="p" weight="700" style={{ marginTop: '.25rem', fontSize: '10px' }}>
                {`App Version: ${appVersion}`}
              </Typography>
            </div>
          </Grid>

          <Grid item xl={4} lg={5} md={6} sm={12} xs={12} className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default AuthLayout;
