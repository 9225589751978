import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery() {
  const { search, hash } = useLocation();

  const { query, searchParams } = useMemo(() => {
    const searchParams = new URLSearchParams(search + hash);
    const query = Object.fromEntries(searchParams.entries());

    return { query, searchParams };
  }, [search, hash]);

  return { query, searchParams };
}
