import { breakpoints } from '../common';

const MuiDialog = {
  MuiDialog: {
    root: {
      '&.pos-top $scrollPaper': {
        alignItems: 'flex-start',
        [breakpoints.down('sm')]: { alignItems: 'flex-end' },
      },
      '&.pos-fullHeight $paper': {
        margin: 0,
        height: '100%',
        minHeight: '100%',
      },
    },
    paper: {
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: '100%',
        margin: 0,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
    },
    paperFullWidth: {
      [breakpoints.down('sm')]: { width: '100%' },
    },
    paperScrollPaper: {
      maxHeight: 'calc(100% - 20px)',
    },
    scrollPaper: {
      [breakpoints.down('sm')]: { alignItems: 'flex-end' },
    },
    paperWidthSm: { maxWidth: 700 },
  },
};

export default MuiDialog;
