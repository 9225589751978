import Typography from '../../../../../components/common/Typography';
import { Button } from '../../../../../components/common/Buttons';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import { useTrans } from '../../../../../services/i18n';
import FormBillingInformation from '../FormBillingInfo';

export default function BillingInfo({ billingInfo, onUpdateBilling }) {
  const { trans } = useTrans();
  const [openFormBilling, onOpenFormBilling, onCloseFormBilling] = useBooleanHandlers();

  return (
    <div>
      <Typography variant="body2" color="hint" weight="bold" gutter="label">
        {billingInfo?.country?.name}
      </Typography>
      <Typography variant="body2" color="hint" weight="bold" gutter="label">
        {billingInfo?.state?.name}
      </Typography>
      <Typography variant="body2" color="hint" weight="bold" gutter="label">
        {billingInfo?.city}
      </Typography>
      <Typography variant="body2" color="hint" weight="bold" gutter="label">
        {billingInfo?.line1}
      </Typography>
      <Typography variant="body2" color="hint" weight="bold" gutter="label">
        ZIP CODE: {billingInfo?.postal_code}
      </Typography>
      <Button onClick={onOpenFormBilling} variant="outlined" color="primary">
        {trans('edit-billing')}
      </Button>
      {openFormBilling && (
        <FormBillingInformation
          item={billingInfo}
          open={openFormBilling}
          onClose={onCloseFormBilling}
          savePaymentMethod={onUpdateBilling}
        />
      )}
    </div>
  );
}
