import { connect } from 'react-redux';

function mapStateToProps(state) {
  return { settings: state.orgSettings, coForQuoteRequests: state.globalSettings.coForQuoteRequests };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps);
