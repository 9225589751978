import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '../../../common/Buttons';
import { Menu, MenuItem, Typography, ListItemIcon, ListItemText, ListItem, Grid } from '@material-ui/core/';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import { ChevronLeftOutlined, ChevronRightOutlined, Add } from '@material-ui/icons';
import { useTrans } from '../../../../services/i18n';
import styles from './styles.module.css';
import AddToPipelineModal from '../AddToPipelineModal';
import connect from './connect';
import { formatDate } from '../../../../utils/dateUtils';
import { useAlertContext } from '../../../../contexts/AlertContext';
import { emptyArray } from '../../../../utils/arrayUtils';
import SavePipelineForm from '../../../forms/SavePipelineForm';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { useSubscribedOnly } from '../../../common/Subscription/hooks/useSubscribedOnly';

const StyledMenu = withStyles({
  paper: {
    marginTop: '5px',
    width: '300px',
    maxHeight: '350px',
  },
})((props) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    whiteSpace: 'normal',
    display: 'flex',
    flexWrap: 'wrap',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const AddToPipelineButton = ({
  size = 'medium',
  opportunity,
  type,
  getPipelines,
  userPipelines,
  addPursuitToPipeline,
  addPipeline,
  getTagsByUser,
  subscription,
  setOpenModalPremium,
  upAnchorEl = null,
  handleChildEvent,
  controlType = 'menuItem',
}) => {
  const { trans } = useTrans();
  const { errorAlert } = useAlertContext();

  const { subscribedOnly } = useSubscribedOnly(subscription, setOpenModalPremium);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [currentPipeline, setCurrentPipeline] = useState(null);
  const [openForm, onOpenForm, onCloseForm] = useBooleanHandlers();
  const [pending, setPending] = useState(false);
  const [pursuitRegister, setPursuitRegister] = useState('');
  const [pendingButton, setPendingButton] = useState(false);

  const handleClick = (event) => {
    setPending(true);
    setAnchorEl(event.currentTarget);
    handleChildEvent('hidden');
    getPipelines().then(() => {
      setPending(false);
    });
  };

  const getDataToSend = (type, opp) => {
    if (type === 'award') {
      return {
        ...opp,
        // id: opp.idToLinkPursuit,
        naics: [opp.NAICSCategory],
        psc: [opp.PSC],
      };
    }
    if (type === 'contract') return { id: opp.id, naics: [opp.naics] };
    if (type === 'nsn') {
      const { solicitationNumber, nomenclature, ...rest } = opp;
      return {
        ...rest,
        title: `${solicitationNumber} ${nomenclature ? `- ${nomenclature}` : ''}`,
        naics: [],
        psc: [],
      };
    }
    if (type === 'grant')
      return {
        id: opp.id,
        naics: [],
        psc: [],
        dueDate: opp.closeDate,
      };

    if (type === 'stateLocal')
      return {
        id: opp.id,
        naics: [],
        psc: [],
        dueDate: opp.proposalDeadline,
      };
  };

  const handleSelectPipeline = (p) => {
    setPendingButton(true);
    setCurrentPipeline(p);
    addPursuitToPipeline({
      pipelineId: p.id,
      opportunity: getDataToSend(type, opportunity),
      opportunityType: type,
    })
      .then((res) => {
        if (res.payload.success) {
          setOpenModal(true);
          setPursuitRegister(res.payload.pursuit.id);
        }
      })
      .catch(errorAlert)
      .finally(() => {
        setPendingButton(false);
      });

    handleClose();
  };

  const closeModal = () => {
    setOpenModal(false);
    setCurrentPipeline(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoBack = () => {
    handleChildEvent('visible');
    handleClose();
  };

  const openFormAddPipeline = () => {
    handleClose();
    onOpenForm();
  };

  const handleChangeSearch = (ev) => {
    ev.stopPropagation();
    const { value } = ev.target;
    setSearchInput(value);
    getPipelines({ text: value });
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <div
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: controlType === 'menuItem' ? 10 : 0,
          paddingRight: 10,
        }}
      >
        {controlType === 'menuItem' ? (
          <MenuItem
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={subscribedOnly(handleClick)}
            pending={pendingButton}
          >
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
              <Grid>
                <Typography color={'primary'}>{trans('add-to-pipeline')}</Typography>
              </Grid>
              <Grid>
                <ListItemIcon style={{ justifyContent: 'end' }}>
                  <ChevronRightOutlined color={'primary'} />
                </ListItemIcon>
              </Grid>
            </Grid>
          </MenuItem>
        ) : (
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={subscribedOnly(handleClick)}
            pending={pendingButton}
          >
            {trans('add-to-pipeline')}
          </Button>
        )}

        <StyledMenu
          className={styles.noPadding}
          id="customized-menu"
          anchorEl={upAnchorEl || anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <ListItem style={{ paddingLeft: 10, paddingRight: 10 }}>
            <ListItemIcon style={{ minWidth: '25px', cursor: 'pointer' }} onClick={handleGoBack}>
              <ChevronLeftOutlined style={{ marginLeft: '-5px' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5">{trans('add-to-pipeline')}</Typography>
            </ListItemText>
            <ListItemIcon style={{ minWidth: '25px', cursor: 'pointer' }} onClick={openFormAddPipeline}>
              <Add />
            </ListItemIcon>
          </ListItem>
          <FormControl className={styles.inputSearch}>
            <InputBase
              onClickCapture={stopImmediatePropagation}
              onKeyDown={(e) => e.stopPropagation()}
              placeholder="Search pipeline"
              className={styles.inputBase}
              startAdornment={
                <InputAdornment position="start" className={styles.adormentSearch}>
                  <SearchIcon className={styles.textMuted} />
                </InputAdornment>
              }
              value={searchInput}
              onChange={handleChangeSearch}
            />
          </FormControl>

          {!emptyArray(userPipelines) ? (
            userPipelines.map((p, i) => (
              <StyledMenuItem key={i} onClick={() => handleSelectPipeline(p)}>
                <div className={styles.menuItemTitle}>{p.name}</div>
                <div className={styles.menuItemContent}>
                  <span className={styles.w100}>
                    <span className={styles.textMuted}> Updated:</span> {formatDate(p.updatedAt, 'MMM D, YYYY')}
                  </span>
                </div>
              </StyledMenuItem>
            ))
          ) : (
            <div className={styles.containerNoResults}>
              <Typography variant="h5" className={styles.titleContainerNoResults}>
                {trans('no-pipelines')}
              </Typography>
              {!pending && emptyArray(userPipelines) && (
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  onClick={openFormAddPipeline}
                  size={size}
                >
                  {trans('create-pipeline')}
                </Button>
              )}
            </div>
          )}
        </StyledMenu>
      </div>
      <SavePipelineForm open={openForm} onClose={onCloseForm} addPipeline={addPipeline} getTagsByUser={getTagsByUser} />
      <AddToPipelineModal
        open={openModal}
        pending={false}
        pursuitRegister={pursuitRegister}
        pipeline={currentPipeline}
        onCloseDialog={closeModal}
      />
    </>
  );
};

export default connect(AddToPipelineButton);
