import IconButton from '@material-ui/core/IconButton';
import IconMore from '@material-ui/icons/MoreHoriz';
import React from 'react';
import usePopover from '../../../../../../hooks/usePopover';
import { useTrans } from '../../../../../../services/i18n';
import { SpacingContainer } from '../../../../../common/Containers';
import { BottomLeftPosition, ContextMenuListItem, Popover } from '../../../../../common/Dialogs/Popover';
import { Divider } from '../../../../../common/Divider';
import Typography from '../../../../../common/Typography';
import Button from '../../../../../common/Buttons/Button';
import { IconRemove } from '../../../../../svg/Icons';
import { RouterLink } from '../../../../../common/Buttons';

export default function ActionsMenu({
  onMarkAllTrash,
  isSomeChecked,
  onRestoreAll,
  onDeleteAll,
  onMarkAllAsRead,
  onMarkAsRead,
  onAddToTrash,
  onMarkAsRestored,
  onRemove,
  isSmScreen = false,
  count = 0,
  archived = false,
}) {
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const { trans } = useTrans();

  return (
    <>
      {isSomeChecked && !isSmScreen && !archived && (
        <>
          <RouterLink onClick={onAddToTrash} style={{ marginLeft: '10px' }}>
            {trans('trash')}
          </RouterLink>
          <RouterLink onClick={onMarkAsRead} style={{ marginLeft: '10px', marginRight: '10px' }}>
            {trans('MarkAsRead')}
          </RouterLink>
          <RouterLink onClick={onRemove} style={{ marginLeft: '10px', marginRight: '10px' }}>
            {trans('delete')}
          </RouterLink>
        </>
      )}

      {isSomeChecked && !isSmScreen && archived && (
        <>
          <RouterLink onClick={onMarkAsRestored} style={{ marginLeft: '10px' }}>
            {trans('restore')}
          </RouterLink>
          <RouterLink onClick={onRemove} style={{ marginLeft: '10px', marginRight: '10px' }}>
            {trans('delete')}
          </RouterLink>
        </>
      )}

      {!isSomeChecked && (
        <IconButton size="small" onClick={onOpenPopover}>
          <IconMore />
        </IconButton>
      )}

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover} {...BottomLeftPosition}>
        {!isSomeChecked && !archived ? (
          <>
            <ContextMenuListItem
              button
              onClick={() => onMarkAllTrash({ archived: Boolean(archived) })}
              onClickCB={onClosePopover}
              size="normal"
            >
              <Typography variant="body2" color="primary" weight="500" noWrap>
                {trans('trash-all')}
              </Typography>
            </ContextMenuListItem>
            <ContextMenuListItem button onClick={onMarkAllAsRead} onClickCB={onClosePopover} size="normal">
              <Typography variant="body2" color="primary" weight="500" noWrap>
                {trans('MarkAllAsRead')}
              </Typography>
            </ContextMenuListItem>

            <Divider light spacing={1} />

            <SpacingContainer px={3} py={2}>
              <Typography variant="body2" color="hint">
                {trans('SelectMultipleMessagesMoreOptions')}
              </Typography>
            </SpacingContainer>
          </>
        ) : !isSomeChecked && archived ? (
          <>
            <ContextMenuListItem
              button
              onClick={() => onRestoreAll({ archived: true })}
              onClickCB={onClosePopover}
              size="normal"
            >
              <Typography variant="body2" color="primary" weight="500" noWrap>
                {trans('restore-all')}
              </Typography>
            </ContextMenuListItem>
            <ContextMenuListItem button onClick={onDeleteAll} onClickCB={onClosePopover} size="normal">
              <Typography variant="body2" color="primary" weight="500" noWrap>
                {trans('delete-all')}
              </Typography>
            </ContextMenuListItem>

            <Divider light spacing={1} />

            <SpacingContainer px={3} py={2}>
              <Typography variant="body2" color="hint">
                {trans('SelectMultipleMessagesMoreOptions')}
              </Typography>
            </SpacingContainer>
          </>
        ) : isSomeChecked && archived ? (
          <>
            <ContextMenuListItem button onClick={onMarkAsRead} onClickCB={onClosePopover} size="normal">
              <Typography variant="body2" color="primary" weight="500" noWrap>
                {trans('MarkAsRead')}
              </Typography>
            </ContextMenuListItem>
          </>
        ) : (
          <>
            <ContextMenuListItem button onClick={onRemove} onClickCB={onClosePopover} size="normal">
              <Typography variant="body2" color="primary" weight="500" noWrap>
                {trans('delete')}
              </Typography>
            </ContextMenuListItem>
          </>
        )}
      </Popover>
    </>
  );
}
