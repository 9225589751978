import React from 'react';
import { VENDORS_MANAGEMENT } from '../../constants/api';
import { useApiAutocomplete } from '../../hooks/useApiAutocomplete';
import { application } from '../../services/application';
import { formatToTitleCase } from '../../utils/helpers';
import Autocomplete from '../common/Inputs/Autocomplete';

export const LocationsAutocomplete = (props) => {
  const { onChange, value, multi, labelKey = 'value', label, ...rest } = props;

  const { options, loading, handleValueChange, filterOptions, handleInputChange } = useApiAutocomplete({
    labelKey,
    onChange: React.useCallback((val) => onChange && onChange(val), [onChange]),
    getOptionList: (params) => application.call(VENDORS_MANAGEMENT.GET_LOCATIONS_AUTOCOMPLETE, params),
  });

  const validateValueArray = () => {
    if (Array.isArray(value)) {
      return value;
    } else {
      return [];
    }
  }

  return (
    <Autocomplete
      name="locations"
      options={options}
      label={label}
      labelKey={labelKey}
      loading={loading}
      value={validateValueArray()}
      filterOptions={filterOptions}
      onChange={handleValueChange}
      multiple={multi}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      formatLabelCB={formatToTitleCase}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};
