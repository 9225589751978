import { connect } from 'react-redux';
import { actions } from '../../../../store/pipelinesDetails';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  const updateStagesOrder = async (params) => {
    await dispatch(actions.updateStagesOrder(params));
  };

  return {
    updateStagesOrder,
    onDropPursuit(params) {
      dispatch(actions.onDropPursuit(params));
    },
    updatePursuitsOrder(params) {
      dispatch(actions.updatePursuitsOrder(params));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
