import React from 'react';
import Typography from '../../../Typography';

export default function ListItemTextBase({ children, ...rest }) {
  return (
    <Typography variant="body2" color="primary" weight="500" {...rest}>
      {children}
    </Typography>
  );
}
