import Grid from '@material-ui/core/Grid';
import React, { useCallback, useEffect } from 'react';
import { Button } from '../../common/Buttons';
import { ModalDialog } from '../../common/Dialogs';
import { DatePicker, TextField } from '../../common/Inputs';
import { useForm } from '../../../hooks/useForm';
import { useTrans } from '../../../services/i18n';
import { getInitialValues, useScheme } from './helpers';
import { useAlertContext } from '../../../contexts/AlertContext';
import connect from './connect';
import InputAdornment from '@material-ui/core/InputAdornment';

const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: true };

function StorePursuitForm({ stage = null, open, onClose, onSave, getPursuits, conditionFreshSuccess }) {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const [isSend, setIsSend] = React.useState(false);

  const onSubmit = useCallback(
    (values) => {
      setIsSend(true);
      Promise.resolve(
        onSave({
          ...values,
          stageId: stage ? stage.id : stage,
          pipelineId: stage ? stage.pipelineId : null,
          notOpportunity: true,
        })
      )
        .then((res) => {
          if (res.hasOwnProperty('error')) {
            errorAlert('Error creating pursuit');
            return false;
          }
          getPursuits(conditionFreshSuccess);
          successAlert('Pursuit added!');
          onClose();
          resetForm();
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false));
    },
    [onClose, infoAlert, errorAlert, successAlert, onSave]
  );

  const { getFieldProps, handleSubmit, values, isSubmitting, isValid, resetForm } = useForm(
    schema,
    getInitialValues(),
    onSubmit,
    formOptions
  );

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('AddPursuit')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" disabled={isSend} onClick={onClose}>
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={!isValid} pending={isSend} onClick={handleSubmit}>
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          <TextField
            label={trans('name')}
            placeholder={trans('required')}
            required
            maxLength={80}
            {...getFieldProps('name')}
          />
        </Grid>

        {/*<Grid item sm={4} xs={12}>*/}
        {/*    <TextField*/}
        {/*        label={trans('Owner')}*/}
        {/*        placeholder={trans('required')}*/}
        {/*        required*/}
        {/*        maxLength={5}*/}
        {/*        align="center"*/}
        {/*        {...getFieldProps('owner')}*/}
        {/*    />*/}
        {/*</Grid>*/}

        <Grid item xs={12}>
          <TextField
            label={trans('description')}
            placeholder={trans('description')}
            multiline
            rows={4}
            maxRows={10}
            {...getFieldProps('description')}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label={trans('Win Probability')}
            placeholder={trans('Win Probability')}
            maxLength={3}
            align="center"
            {...getFieldProps('winProbability')}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            label={trans('Estimated Value')}
            placeholder={trans('Estimated Value')}
            InputProps={{
              startAdornment: <InputAdornment position="end">$</InputAdornment>,
            }}
            maxLength={20}
            align="center"
            {...getFieldProps('estimatedValue')}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <DatePicker
            label={trans('Due Date')}
            placeholder={trans('Due Date')}
            maxLength={5}
            align="center"
            disablePast
            disableToolbar
            {...getFieldProps('dueDate')}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <DatePicker
            label={trans('Est. Award Date')}
            placeholder={trans('Est. Award Date')}
            maxLength={5}
            align="center"
            disableToolbar
            {...getFieldProps('estimatedAwardDate')}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
}

export default connect(StorePursuitForm);
