import React from 'react';
import { Grid, Button } from '@material-ui/core';
import styles from './styles.module.css';
import clsx from 'clsx';
import { formatDate } from '../../../../utils/dateUtils';

const MergeOverwriteOportunityItem = ({
  name,
  newValue,
  incomingValue,
  overwrite,
  index,
  opportunityName,
  updateDataPursuit,
  fieldType,
}) => {
  const isOdd = (num) => num % 2;

  const PointOfContacts = ({ points = [] }) => {
    return <>{points ? points.map((p) => (p ? <div key={p.email}>{p.fullName}</div> : '')) : ''}</>;
  };

  const Naics = ({ naics = [] }) => {
    return <>{naics ? naics.map((n, i) => <div key={i}>{n}</div>) : ''}</>;
  };

  const componentRender = (oppName, value) => {
    if (oppName === 'pointOfContacts') {
      return <PointOfContacts points={value.text} />;
    } else if (oppName === 'naics') {
      return <Naics naics={value.text} />;
    } else {
      return fieldType === 'date' ? (value.text ? formatDate(value.text, 'MM/DD/YYYY') : '') : value.text;
    }
  };

  const overwriteField = () => {
    if (overwrite) {
      updateDataPursuit(index, newValue, false);
    } else {
      updateDataPursuit(index, incomingValue, true);
    }
  };

  return (
    <>
      <Grid container>
        <Grid container className={clsx(styles.container, isOdd(index) && styles.backgroundGray)}>
          <Grid item md={4} className={clsx(styles.item, styles.textMuted)}>
            Field
          </Grid>
          <Grid item md={8} className={clsx(styles.bold, styles.item)}>
            {name}
          </Grid>
          <Grid item md={4} className={clsx(styles.item, styles.textMuted)}>
            Incoming Value
          </Grid>
          <Grid item md={8} className={styles.item}>
            {componentRender(opportunityName, incomingValue)}
          </Grid>
          <Grid item md={4} className={clsx(styles.item, styles.textMuted)}>
            New Value
          </Grid>
          <Grid item md={8} className={styles.item}>
            {overwrite ? componentRender(opportunityName, incomingValue) : componentRender(opportunityName, newValue)}
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item md={8} className={styles.item}>
            <Button variant="contained" disabled={true} size="small" className={clsx(styles.item, styles.marginRight)}>
              Merge
            </Button>
            <Button onClick={overwriteField} variant="contained" color="secondary" size="small">
              Overwrite
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MergeOverwriteOportunityItem;
