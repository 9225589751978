import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const initialValues = {
  name: '',
  description: '',
  winProbability: 0,
  estimatedValue: 0,
  dueDate: '',
  estimatedAwardDate: '',
};

export const getInitialValues = () => {
  return Object.assign({}, initialValues);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    name: Yup.string().required(trans('required-field')),
    description: Yup.string().optional().nullable().default(''),
    winProbability: Yup.number()
      .typeError(trans('FormFieldNumber'))
      .positive(trans('PositiveNumber'))
      .integer(trans('IntegerNumber'))
      .min(0)
      .max(100)
      .optional()
      .nullable()
      .default(0),
    estimatedValue: Yup.number().typeError(trans('FormFieldNumber')).min(0).optional().nullable().default(0),
    dueDate: Yup.date().optional().nullable(),
    estimatedAwardDate: Yup.date().optional().nullable(),
  })
);
