import React, { useEffect } from 'react';
import Typography from '../../../../../components/common/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import connect from '../../../connect';
import { useState } from 'react';
import { getBenefitsByTier } from '../../../../../utils/subscription';

const PlanBenefits = ({ currentProduct, subscriptionStatus }) => {
  const [benefits, setBenefits] = useState([]);

  useEffect(() => {
    const tier = currentProduct?.product?.tier ?? subscriptionStatus?.currentPlan;
    const tierBenefits = getBenefitsByTier(tier);
    setBenefits(tierBenefits.slice(0, 10));
  }, [currentProduct, subscriptionStatus]);

  return (
    <>
      <div>
        {benefits.map((b, index) => (
          <div style={{ padding: '2px 0px' }} key={index + new Date().getTime()}>
            <Typography variant="body1" style={{ display: 'flex', flexDirection: 'row' }}>
              {b.available ? (
                <CheckCircleOutlineIcon style={{ color: '#17B471', marginRight: '16px' }} />
              ) : (
                <CloseSharpIcon style={{ color: '#888FA9', marginRight: '16px' }} />
              )}
              {b.name}
            </Typography>
            {b.subs && (
              <ul style={{ marginLeft: 45 }}>
                {b?.subs?.map((sub, index) => (
                  <li key={index}>
                    <Typography variant="body1">{sub}</Typography>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default connect(PlanBenefits);
