import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCourthouse = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M2 22h20M12 2c1.6.64 3.4.64 5 0v3c-1.6.64-3.4.64-5 0V2ZM12 5v3M17 8H7c-2 0-3 1-3 3v11h16V11c0-2-1-3-3-3ZM4.58 12h14.84"
      />
      <path
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M7.99 12v10M11.99 12v10M15.99 12v10"
      />
    </svg>
  );
};

IconCourthouse.propTypes = simpleIconPropsTypes;
