import React from 'react';
import { ModalDialog, DialogTitle } from '../../../../components/common/Dialogs';
import { IconSMSNotification } from '../../../../components/svg';
import { useTrans } from '../../../../services/i18n';

import InfoAboutNotificationsContinueButton from './InfoAboutNotificationsContinueButton';

export default function InfoAboutNotificationsContinueButtonModal({
  open,
  onClose,
  openSettings,
  showContinueButtons,
}) {
  const { trans } = useTrans();
  return (
    <React.Fragment>
      <ModalDialog
        open={open}
        onClose={onClose}
        DialogTitle={
          <DialogTitle
            icon={<IconSMSNotification />}
            title={trans('email-notification-settings')}
            onClose={onClose}
          ></DialogTitle>
        }
      >
        <InfoAboutNotificationsContinueButton
          onClose={onClose}
          openSettings={openSettings}
          showContinueButtons={showContinueButtons}
        />
      </ModalDialog>
    </React.Fragment>
  );
}
