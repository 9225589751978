import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../../../components/common/Typography';
import { makeStyles } from '@material-ui/core/styles';
import usePopover from '../../../../hooks/usePopover';
import Popover from '../Popover';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles({
  label: {
    fontSize: '0.938rem',
  },
  formControl: {
    width: '100%',
  },
  container: {
    backgroundColor: '#F1F3F9', // Gray background
    padding: '6px 10px 6px 10px', // Padding around the RadioGroup
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginTop: '0.1rem',
  },
});

export default function TypographyTextHelp({
  label,
  labelWeight = '400',
  labelFontFamily = 'manrope',
  labelColor = 'default',
  labelVariant = 'h6',
  labelGutter = 'label',
  isRequired = false,
  helpTooltip = false,
}) {
  const { trans } = useTrans();
  const classes = useStyles();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();

  return (
    <>
      <Typography
        weight={labelWeight}
        fontFamily={labelFontFamily}
        color={labelColor}
        gutter={labelGutter}
        variant={labelVariant}
      >
        {label}
        {isRequired && <span style={{ color: 'red', marginLeft: '0.5rem' }}>*</span>}
        {helpTooltip && (
          <span
            style={{
              marginLeft: '0.3rem',
              cursor: 'pointer',
            }}
          >
            <InfoOutlined onClick={onOpenPopover} color="primary" className={classes.infoIcon} />
          </span>
        )}
      </Typography>
      {/* Help popover */}
      <Popover
        helpTooltip={helpTooltip}
        anchorEl={anchorEl}
        onOpenPopover={onOpenPopover}
        onClosePopover={onClosePopover}
      />
    </>
  );
}
