import Grid from '@material-ui/core/Grid';
import { Button } from '../../../../../../components/common/Buttons';
import React from 'react';
import * as Yup from 'yup';
import ModalDialog from '../../../../../../components/common/Dialogs/ModalDialog';
import { TextField } from '../../../../../../components/common/Inputs';
import makeValidationSchema from '../../../../../../hooks/makeValidationSchema';
import { useForm } from '../../../../../../hooks/useForm';
import { useTrans } from '../../../../../../services/i18n';
import { schemaToInitialState } from '../../../../../../utils/validationUtils';
import connect from '../../connect';
import { useAlertContext } from '../../../../../../contexts/AlertContext';

const useScheme = makeValidationSchema((trans) => {
  return Yup.object().shape({
    title: Yup.string().required(trans('empty-not-allowed', { field: trans('title') })),
    note: Yup.string().required(trans('empty-not-allowed', { field: trans('note') })),
  });
});

function NoteModal({ addVendorNote, updateVendorNote, note, onClose, open = false, vendorInfo, memberPending }) {
  const { trans } = useTrans();
  const schema = useScheme();
  const [loading, setLoading] = React.useState(false);
  const { successAlert, errorAlert } = useAlertContext();

  /***
   *
   */
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const resp = await addVendorNote({
        ...values,
        vendorManagementId: vendorInfo.id,
      });
      if (resp?.error) {
        errorAlert('Error creating Note');
      } else {
        resetForm();
        successAlert('Success!');
        typeof onClose === 'function' && onClose();
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      errorAlert('Error creating Note');
      setLoading(false);
    }
  };

  /***
   *
   */
  const onEdit = React.useCallback(
    async (values) => {
      try {
        setLoading(true);
        const resp = await updateVendorNote({
          ...values,
          vendorManagementId: vendorInfo.id,
          id: note.id,
        });
        if (resp?.error) {
          errorAlert('Error updating Member');
        } else {
          successAlert('Success!');
          typeof onClose === 'function' && onClose();
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        errorAlert('Error updating Member');
        setLoading(false);
      }
    },
    [updateVendorNote, onClose, note, successAlert]
  );

  /***
   *
   */
  const { handleSubmit, resetForm, getFieldProps, isValid, setValues } = useForm(
    schema,
    note || schemaToInitialState(schema),
    note ? onEdit : onSubmit,
    {
      validateOnChange: true,
      validateOnBlur: true,
    }
  );

  /***
   *
   */
  const handleCancel = React.useCallback(() => {
    resetForm();
    typeof onClose === 'function' && onClose();
  }, [onClose, resetForm]);

  /***
   *
   */
  React.useEffect(() => {
    if (note) {
      const { id, created_at, updated_at, ...data } = note;
      setValues(data);
    }
    return () => {
      resetForm();
    };
  }, [note, resetForm, setValues]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans(note ? 'edit-org-note' : 'add-org-note')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={memberPending}
              onClick={handleCancel}
              pending={loading}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" pending={loading} onClick={handleSubmit} disabled={!isValid}>
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField label={trans('Title')} placeholder={trans('Title')} maxLength={255} {...getFieldProps('title')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={trans('Note')}
            placeholder={trans('note-placeholder')}
            multiline
            minRows={4}
            maxRows={10}
            maxLength={2000}
            {...getFieldProps('note')}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
}

export default connect(NoteModal);
