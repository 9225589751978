import TableSortLabel from '@material-ui/core/TableSortLabel';
import React from 'react';
import { IconSort } from '../../svg/Icons/IconSort';
import TableCell from './TableCell';

const IconComponent = (order, fill) => () => <IconSort order={order} fill={fill} />;

export default function TableSortCell({
  children,
  onChange,
  orderLabel,
  orderBy,
  orderDir,
  fill = null,
  ...cellProps
}) {
  const isActive = orderBy === orderLabel;

  const handleChange = React.useCallback(
    () => onChange([orderLabel, orderBy === orderLabel && orderDir === 'asc' ? 'desc' : 'asc']),
    [orderBy, orderDir, orderLabel, onChange]
  );

  return (
    <TableCell {...cellProps}>
      <TableSortLabel
        active={isActive}
        direction={orderDir || 'asc'}
        IconComponent={IconComponent(isActive ? orderDir : '', fill)}
        onClick={handleChange}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
}
