import React, { useEffect, useState } from 'react';
import { useFormData } from '../../index';
import { useTrans } from '../../../../services/i18n';
import { SelectField, TextField } from '../../../../components/common/Inputs';
import Typography from '../../../../components/common/Typography';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAlertContext } from '../../../../contexts/AlertContext';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PhoneNumber from '../PhoneNumber';
import { Divider } from '../../../../components/common/Divider';
import CheckBox from '../../../../components/common/Inputs/CheckBox';
import { Button } from '../../../../components/common/Buttons';
import { NaicsCodeAutocomplete } from '../../../../components/autocompletes/NaicsCodeAutocomplete';
import { PscAutocomplete } from '../../../../components/autocompletes/PscAutocomplete';
import { ReactComponent as ArrowBackIcon } from '../../icons/arrow-left.svg';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as yup from 'yup';

const industrySchema = yup.object().shape({});

const useStyles = makeStyles((theme) => ({
  checkBoxContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: 40,
    paddingRight: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    display: 'inline-flex',
  },
  individualCheckBoxContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    display: 'flex',
  },
  ethnicitiesLabels: { color: 'black', fontSize: 14, fontFamily: 'Manrope', fontWeight: '400' },
  labelStyle: {
    color: 'black',
    fontSize: 14,
    fontFamily: 'Manrope',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  corporateStructureContainer: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
  },
  corporateStructureOptions: {
    width: 24,
    height: 24,
    position: 'relative',
  },
  labelDropdowns: {
    fontFamily: 'Lexend',
    marginBottom: '8px',
    color: '#4e5978',
    fontWeight: 500,
  },
  containerCard: {
    padding: '2.125rem 1.5rem 1rem',
  },
  containerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.3rem 2.3rem 1.87rem 2.3rem',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  backButton: {
    color: '#999999',
    fontSize: 18,
    fontFamily: 'Manrope',
    fontWeight: '500',
    wordWrap: 'break-word',
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cancelNextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function Industry({
  nextStep,
  previousStep,
  isEdit,
  onCompletionChange,
  initialValues,
  cancelButtonHandler,
  onSubmit,
}) {
  const { industryData, setIndustryData } = useFormData();
  const [isMinoritySelected, setIsMinoritySelected] = useState(initialValues.majorityEthnicity != null ? true : false);
  const [majorityEthnicity, setMajorityEthnicity] = useState(initialValues.majorityEthnicity || null);
  const [errors, setErrors] = useState({});
  const { trans } = useTrans();
  const [phone, setPhone] = useState(null);
  const styles = useStyles();
  const { successAlert, errorAlert } = useAlertContext();
  const [isComplete, setIsComplete] = useState(false);
  const [selectedStructure, setSelectedStructure] = useState(initialValues.corporateStructure || '');

  useEffect(() => {
    if (onCompletionChange) {
      onCompletionChange(isComplete);
    }
  }, [isComplete, onCompletionChange]);

  const transformedSecondaryNaics = industryData.secondaryNaics.map((val) => ({
    create: val,
    value: val,
  }));

  const transformedPrimaryNaics = industryData.primaryNaics.map((val) => ({
    create: val,
    value: val,
  }));

  const transformedPscCodes = industryData.pscCodes.map((val) => ({
    create: val,
    value: val,
  }));

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;

    setIndustryData((prevData) => {
      let updatedCategories;
      let updatedData = { ...prevData };

      if (value === 'MINORITY' && checked) {
        setIsMinoritySelected(true);
        setMajorityEthnicity('Asian');
        updatedData.majorityEthnicity = 'Asian';
      } else if (value === 'MINORITY' && !checked) {
        setIsMinoritySelected(false);
        setMajorityEthnicity(null);
        updatedData.majorityEthnicity = null;
      }

      updatedCategories = checked
        ? [...prevData.socioEconomicCategories, value]
        : prevData.socioEconomicCategories.filter((category) => category !== value);

      updatedData.socioEconomicCategories = updatedCategories;

      return updatedData;
    });
  };

  const handleMajorityEthnicityChange = (event) => {
    const newEthnicity = event.target.value;
    setMajorityEthnicity(newEthnicity);
    setIndustryData((prevData) => {
      return { ...prevData, majorityEthnicity: newEthnicity };
    });
  };

  const handleStructureChange = (event) => {
    setSelectedStructure(event.target.value);
    setIndustryData({
      ...industryData,
      corporateStructure: event.target.value,
    });
  };

  const handleNextClick = () => {
    try {
      industrySchema.validateSync(industryData, { abortEarly: false });
      onCompletionChange(true);
      onSubmit();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });
        setErrors(errorMessages);
      }
      onCompletionChange(false);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} className={styles.containerCard}>
        <div
          style={{
            width: '100%',
            height: '100%',
            paddingLeft: 40,
            paddingRight: 40,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 24,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: 'black',
              fontSize: 21,
              fontFamily: 'Manrope',
              fontWeight: '700',
              wordWrap: 'break-word',
            }}
          >
            Industry
          </div>
          <div style={{ alignSelf: 'stretch', height: 1, background: '#D9D9D9' }} />
        </div>
        <Grid item xs={12}>
          <NaicsCodeAutocomplete
            label={trans('organization-form-primary-naics')}
            placeholder={trans('typeToSearchNaics')}
            multi
            value={transformedPrimaryNaics}
            onChange={(event) => {
              const selectedValues = event.target.value.map((item) => item.value);
              const lastSelectedValue = selectedValues[selectedValues.length - 1];
              setIndustryData((prev) => ({
                ...prev,
                primaryNaics: [lastSelectedValue],
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <NaicsCodeAutocomplete
            label={trans('organization-form-secondary-naics')}
            placeholder={trans('typeToSearchNaics')}
            multi
            value={transformedSecondaryNaics}
            onChange={(event) => {
              const newValue = event.target.value.map((item) => item.value);
              setIndustryData((prev) => ({
                ...prev,
                secondaryNaics: newValue,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PscAutocomplete
            label={trans('organization-form-psc')}
            placeholder={trans('typeToSearchPsc')}
            multi
            value={transformedPscCodes}
            onChange={(event) => {
              const newValue = event.target.value.map((item) => item.value);
              setIndustryData((prev) => ({
                ...prev,
                pscCodes: newValue,
              }));
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <div
            style={{ color: 'black', fontSize: 15, fontFamily: 'Lexend', fontWeight: '500', wordWrap: 'break-word' }}
          >
            {trans('organization-form-socioeconomic-categories')}
          </div>
        </Grid>
        <Grid item xs={12} className={styles.checkBoxContainer}>
          <div className={styles.individualCheckBoxContainer}>
            <CheckBox
              name="WOSB"
              value="WOSB"
              color="secondary"
              onChange={handleCategoryChange}
              checked={industryData.socioEconomicCategories.includes('WOSB')}
            />
            <Typography className={styles.labelStyle}>WOSB</Typography>
          </div>

          <div className={styles.individualCheckBoxContainer}>
            <CheckBox
              name="EDWOSB"
              value="EDWOSB"
              color="secondary"
              onChange={handleCategoryChange}
              checked={industryData.socioEconomicCategories.includes('EDWOSB')}
            />
            <Typography className={styles.labelStyle}>EDWOSB</Typography>
          </div>

          <div className={styles.individualCheckBoxContainer}>
            <CheckBox
              name="VOSB"
              value="VOSB"
              color="secondary"
              onChange={handleCategoryChange}
              checked={industryData.socioEconomicCategories.includes('VOSB')}
            />
            <Typography className={styles.labelStyle}>VOSB</Typography>
          </div>

          <div className={styles.individualCheckBoxContainer}>
            <CheckBox
              name="SDVOSB"
              value="SDVOSB"
              color="secondary"
              onChange={handleCategoryChange}
              checked={industryData.socioEconomicCategories.includes('SDVOSB')}
            />
            <Typography className={styles.labelStyle}>SDVOSB</Typography>
          </div>

          <div className={styles.individualCheckBoxContainer}>
            <CheckBox
              name="MINORITY"
              value="MINORITY"
              color="secondary"
              onChange={handleCategoryChange}
              checked={industryData.socioEconomicCategories.includes('MINORITY')}
            />
            <Typography className={styles.labelStyle}>Minority Owned Business</Typography>
          </div>
        </Grid>

        {isMinoritySelected && (
          <Grid item xs={12}>
            <div
              style={{ color: 'black', fontSize: 14, fontFamily: 'Manrope', fontWeight: '400', wordWrap: 'break-word' }}
            >
              {trans('organization-form-socioeconomic-categories-description')}
            </div>
            <RadioGroup
              aria-label="majority-ethnicity"
              name="majority-ethnicity"
              value={majorityEthnicity}
              onChange={handleMajorityEthnicityChange}
            >
              <FormControlLabel value="Asian" control={<Radio />} label="Asian" className={styles.ethnicitiesLabels} />
              <FormControlLabel
                value="Black/African American"
                control={<Radio />}
                label="Black/African American"
                className={styles.ethnicitiesLabels}
              />
              <FormControlLabel
                value="Hispanic/Latino"
                control={<Radio />}
                label="Hispanic/Latino"
                className={styles.ethnicitiesLabels}
              />
              <FormControlLabel
                value="Native American"
                control={<Radio />}
                label="Native American"
                className={styles.ethnicitiesLabels}
              />
              <FormControlLabel
                value="Pacific Islander"
                control={<Radio />}
                label="Pacific Islander"
                className={styles.ethnicitiesLabels}
              />
            </RadioGroup>
          </Grid>
        )}
        <Grid item xs={12}>
          <div
            style={{ color: 'black', fontSize: 15, fontFamily: 'Lexend', fontWeight: '500', wordWrap: 'break-word' }}
          >
            {trans('organization-form-corporate-structure')}
          </div>
        </Grid>
        <Grid item sm={12} xs={12}>
          <div className={styles.corporateStructureContainer}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectedStructure}
              onChange={handleStructureChange}
              row
            >
              <FormControlLabel
                color="primary"
                className={styles.ethnicitiesLabels}
                value="LLC"
                control={<Radio color="secondary" />}
                label="LLC"
              />
              <FormControlLabel
                color="primary"
                className={styles.ethnicitiesLabels}
                value="Corporation"
                control={<Radio color="secondary" />}
                label="Corporation"
              />
              <FormControlLabel
                color="primary"
                className={styles.ethnicitiesLabels}
                value="LP"
                control={<Radio color="secondary" />}
                label="LP"
              />
              <FormControlLabel
                color="primary"
                className={styles.ethnicitiesLabels}
                value="Sole Prop"
                control={<Radio color="secondary" />}
                label="Sole Prop"
              />
              <FormControlLabel
                // color="#F32445"
                color="primary"
                className={styles.ethnicitiesLabels}
                value="S-Corp"
                control={<Radio color="secondary" />}
                label="S-Corp"
              />
              <FormControlLabel
                color="primary"
                className={styles.ethnicitiesLabels}
                value="International"
                control={<Radio color="secondary" />}
                label="International"
              />
              <FormControlLabel
                color="#F32445"
                className={styles.ethnicitiesLabels}
                value="Gov Entity"
                control={<Radio color="secondary" />}
                label="Gov Entity"
              />
            </RadioGroup>
          </div>
        </Grid>
      </Grid>
      <Divider light spacing={1} />
      <Grid container className={styles.containerFooter}>
        <div className={styles.backButtonContainer}>
          <ArrowBackIcon />
          <div className={styles.backButton} onClick={previousStep}>
            {trans('organization-form-back-button')}
          </div>
        </div>
        <div className={styles.cancelNextButtonContainer}>
          <Button variant="outlined" color="primary" onClick={cancelButtonHandler}>
            {trans('organization-form-cancel-button')}
          </Button>
          <Button variant="contained" color="success" onClick={handleNextClick} style={{ marginLeft: '10px' }}>
            {trans('organization-form-complete-button')}
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  );
}
