import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconLinkOff = (props) => {
  const { width = 16, height = 16 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66683 10.61H6.8335V10.8766H4.66683C3.10297 10.8766 1.8335 9.60714 1.8335 8.04328C1.8335 6.86075 2.56333 5.846 3.59515 5.42646L4.29327 5.14262L3.76038 4.60973L2.0406 2.88995L2.2735 2.65706L12.7264 13.11L12.4935 13.3428L10.1737 11.0231L10.0273 10.8766H9.82016H9.16683V10.2233V10.0162L9.02038 9.86973L7.50705 8.3564L7.3606 8.20995H7.1535H5.8335V7.87662H7.02727L6.17372 7.02307L4.79372 5.64306L4.6241 5.47345L4.38565 5.4996C3.10375 5.64019 2.10016 6.72328 2.10016 8.04328C2.10016 9.45943 3.25069 10.61 4.66683 10.61ZM9.16683 5.47662V5.20995H11.3335C12.8974 5.20995 14.1668 6.47943 14.1668 8.04328C14.1668 8.91574 13.7679 9.69746 13.1408 10.2168L12.9547 10.0307C13.5319 9.56093 13.9002 8.84292 13.9002 8.04328C13.9002 6.62714 12.7496 5.47662 11.3335 5.47662H9.16683Z"
        fill="black"
        stroke="#F32445"
      />
    </svg>
  );
};

IconLinkOff.propTypes = simpleIconPropsTypes;
