import React from 'react';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../../../components/common/Typography';
import { Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import TimeIcon from '@material-ui/icons/AccessTime';
import styles from './styles.module.css';
import generalSettingsImg from '../../components/images/general_settings.png';
import notiFrequency from '../../components/images/noti_frequency.png';

export default function InfoAboutNotifications() {
  const { trans } = useTrans();

  return (
    <Grid container>
      <Grid md={6} item className={styles.item} style={{ borderRight: '1px solid #4E59781F' }}>
        <div className={styles.iconInfo}>
          <TimeIcon />
        </div>
        <div className={styles.title}>
          <Typography variant="body1" weight="bold">
            {trans('notification-frequency')}
          </Typography>
        </div>
        <div className={styles.image}>
          <img src={notiFrequency} />
        </div>
        <div className={styles.text}>
          <Typography variant="body2" weight="500">
            Configure the frequency with which you want to receive instant notifications, remember that the
            configuration you leave in general settings will be reflected in the instant notifications.
          </Typography>
        </div>
      </Grid>
      <Grid md={6} item className={styles.item}>
        <div className={styles.iconInfo}>
          <SettingsIcon />
        </div>
        <div className={styles.title}>
          <Typography variant="body1" weight="bold">
            {trans('general-notification-settings')}
          </Typography>
        </div>
        <div className={styles.image}>
          <img src={generalSettingsImg} />
        </div>
        <div className={styles.text}>
          <Typography variant="body2" weight="500">
            From My profile and going to Email notification settings you can configure all these notifications in
            general and you can set up notifications for Pursuit Notices, Saved Search notifications and Team Updates.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
