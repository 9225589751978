import { /*createAsyncThunk,*/ createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_NSNOPPORTUNITIES } from '../../constants';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common';
import { extendListBuilder, generateListActions, generateSearchActions } from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['issuedDate', 'desc'],
};

//Actions
const listActions = generateListActions({
  scope: SCOPE_NSNOPPORTUNITIES,
  apiMethod: {
    GET_LIST: API.NSNOPPORTUNITIES.GET_LIST,
    EXPORT_CSV: API.NSNOPPORTUNITIES.EXPORT_CSV,
  },
  getStore: (store) => store.nsnOpportunities,
});

const searchActions = generateSearchActions({
  scope: SCOPE_NSNOPPORTUNITIES,
  apiMethod: {
    SAVE_SEARCH: API.SAVED_SEARCH.CREATE,
    MODIFY_SEARCH: API.SAVED_SEARCH.MODIFY,
    SHARE_SEARCH: API.SHARED_SEARCH.CREATE,
  },
  getStore: (store) => store.nsnOpportunities,
});

export const actions = {
  ...listActions,
  ...searchActions,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, actions);
});
