import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    transition: 'transform 0.25s ease-in-out',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
});

export const IconMenuControl = (props) => {
  const { width = 24, height = 24, rotate = false } = props;

  const styles = useStyles();

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" className={clsx(styles.root, !rotate && styles.rotate)}>
      <path
        d="M4 18h11c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h8c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h11c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1zm17.686 8.134l-3.094-3.14 3.094-3.138c.419-.425.419-1.112 0-1.537-.42-.425-1.096-.425-1.515 0l-3.857 3.913c-.419.425-.419 1.111 0 1.536l3.857 3.913c.419.425 1.096.425 1.515 0 .408-.425.419-1.122 0-1.547z"
        transform="translate(-329 -28) translate(300) translate(29 28)"
      />
    </SvgIcon>
  );
};

IconMenuControl.propTypes = simpleIconPropsTypes;
