import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { application } from '../../services/application';
import { API } from '../../constants';
import { onItemDetails, onItemDetailsReject } from '../helpers/sharedCases';
import { INITIAL_LIST_STATE, INITIAL_FUNDING_STATE, INITIAL_AWARDS_COUNT_STATE } from '../helpers/common';
import {
  extendBuilderWithListActions,
  extendBuilderWithReportsActions,
  generateListActions,
  generateReportsActions,
} from '../helpers/listActionsHelpers';
import { SCOPE_AGENCIES_OFFICES } from '../../constants';

export const INITIAL_STATE = {
  item: {},
  pending: false,
  federalContractAwards: INITIAL_LIST_STATE,
  federalAgencyHierarchy: INITIAL_LIST_STATE,
  federalPeople: INITIAL_LIST_STATE,
  contractOpportunities: INITIAL_LIST_STATE,
  federalContractIdvs: INITIAL_LIST_STATE,
  govermentFiles: INITIAL_LIST_STATE,
  popularOpportunities: INITIAL_LIST_STATE,
  grantOpportunities: {
    ...INITIAL_LIST_STATE,
    dictionary: {
      eligibleApplicantType: {},
      fundingActivityCategory: {},
      fundingInstrument: {},
      opportunityCategory: {},
    },
  },
  funding: INITIAL_FUNDING_STATE,
  awards: INITIAL_AWARDS_COUNT_STATE,

  fundingAnalysis: INITIAL_LIST_STATE,
};

const fetchFederalAgency = createAsyncThunk('federalAgency/findOne', async (params) => {
  return application.call(API.FEDERAL_AGENCIES.FIND_ONE, params);
});

const getDictionary = createAsyncThunk('grantOpportunities/getDictionary', async (params) => {
  return application.call(API.GRANT_OPPORTUNITIES.GET_DICTIONARY, { params });
});

const federalPeopleListActions = generateListActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/federalPeople`,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.FETCH_FEDERAL_PEOPLE,
    EXPORT_CSV: API.FEDERAL_AGENCIES.EXPORT_FEDERAL_PEOPLE,
  },
  getStore: (store) => store.federalAgencyDetails.federalPeople,
});

const federalHierarchyListActions = generateListActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/federalAgencyHierarchy`,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.FETCH_HIERARCHY,
  },
  getStore: (store) => store.federalAgencyDetails.federalAgencyHierarchy,
});

const federalContractAwardsListActions = generateListActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/federalContractAwards`,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.FETCH_FEDERAL_CONTRACT_AWARDS,
    EXPORT_CSV: API.FEDERAL_AGENCIES.EXPORT_FEDERAL_CONTRACT_AWARDS,
  },
  getStore: (store) => store.federalAgencyDetails.federalContractAwards,
});

const contractOpportunitiesListActions = generateListActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/contractOpportunities`,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.GET_CONTRACT_OPPORTUNITIES,
    EXPORT_CSV: API.FEDERAL_AGENCIES.CONTRACT_OPPORTUNITIES_EXPORT_CSV,
  },
  getStore: (store) => store.federalAgencyDetails.contractOpportunities,
});

const federalContractIdvsListActions = generateListActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/federalContractIdvs`,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.FETCH_FEDERAL_CONTRACT_IDVS,
    EXPORT_CSV: API.FEDERAL_AGENCIES.EXPORT_FEDERAL_CONTRACT_IDVS,
  },
  getStore: (store) => store.federalAgencyDetails.federalContractIdvs,
});

const govermentFilesListActions = generateListActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/govermentFiles`,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.FETCH_GOVERMENT_FILES,
    EXPORT_CSV: API.FEDERAL_AGENCIES.EXPORT_GOVERMENT_FILES,
  },
  getStore: (store) => store.federalAgencyDetails.govermentFiles,
});

const popularOpportunitiesListActions = generateListActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/popularOpportunities`,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.FETCH_POPULAR_OPPORTUNITIES,
  },
  getStore: (store) => store.federalAgencyDetails.popularOpportunities,
});

const agencyReportsActions = generateReportsActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/reports`,
  apiMethod: {
    GET_FUNDING: API.FEDERAL_AGENCIES.GET_FUNDING,
    GET_AWARDS_COUNT: API.FEDERAL_AGENCIES.GET_AWARDS_COUNT,
  },
  getStore: (store) => store.federalAgencyDetails,
});

const federalGrantOpportunitiesListActions = generateListActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/federalGrantOpportunities`,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.FETCH_GRANT_OPPORTUNITIES,
    EXPORT_CSV: API.FEDERAL_AGENCIES.EXPORT_GRANT_OPPORTUNITIES,
  },
  getStore: (store) => store.federalAgencyDetails.grantOpportunities,
});

const cleanStore = createAction('federalAgencies/cleanStore');

const fundingAnalysisListActions = generateListActions({
  scope: `${SCOPE_AGENCIES_OFFICES}/fundingAnalysis`,
  apiMethod: {
    GET_LIST: API.FEDERAL_AGENCIES.FETCH_FUNDING_ANALYSIS,
    EXPORT_CSV: API.FEDERAL_AGENCIES.EXPORT_FUNDING_ANALYSIS,
  },
  getStore: (store) => store.federalAgencyDetails.fundingAnalysis,
});

export const actions = {
  fetchFederalAgency,
  getDictionary,
  cleanStore,

  federalPeopleListActions,
  federalHierarchyListActions,
  federalContractAwardsListActions,
  contractOpportunitiesListActions,
  federalContractIdvsListActions,
  govermentFilesListActions,
  popularOpportunitiesListActions,
  federalGrantOpportunitiesListActions,
  fundingAnalysisListActions,
  ...agencyReportsActions,
};

function onStartPending(state) {
  return Object.assign(state, { pending: true });
}

function onGetDictionary(state, action) {
  return Object.assign(state, { grantOpportunities: { ...state.grantOpportunities, dictionary: action.payload } });
}

function onGetDictionaryPending(state) {
  return Object.assign(state, { grantOpportunities: { ...state.grantOpportunities, pending: true } });
}

function onGetDictionaryRejected(state) {
  return Object.assign(state, { grantOpportunities: { ...state.grantOpportunities, pending: false } });
}

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, federalPeopleListActions, (store) => store.federalPeople);
  extendBuilderWithListActions(builder, federalHierarchyListActions, (store) => store.federalAgencyHierarchy);
  extendBuilderWithListActions(builder, federalContractAwardsListActions, (store) => store.federalContractAwards);
  extendBuilderWithListActions(builder, contractOpportunitiesListActions, (store) => store.contractOpportunities);
  extendBuilderWithListActions(builder, federalContractIdvsListActions, (store) => store.federalContractIdvs);
  extendBuilderWithListActions(builder, govermentFilesListActions, (store) => store.govermentFiles);
  extendBuilderWithListActions(builder, popularOpportunitiesListActions, (store) => store.popularOpportunities);
  extendBuilderWithListActions(builder, federalGrantOpportunitiesListActions, (store) => store.grantOpportunities);
  extendBuilderWithListActions(builder, fundingAnalysisListActions, (store) => store.fundingAnalysis);
  extendBuilderWithReportsActions(builder, agencyReportsActions, (store) => store);

  builder
    .addCase(fetchFederalAgency.fulfilled, onItemDetails)
    .addCase(fetchFederalAgency.pending, onStartPending)
    .addCase(fetchFederalAgency.rejected, onItemDetailsReject)
    .addCase(getDictionary.fulfilled, onGetDictionary)
    .addCase(getDictionary.pending, onGetDictionaryPending)
    .addCase(getDictionary.rejected, onGetDictionaryRejected)

    .addCase(cleanStore, () => INITIAL_STATE);
});
