import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';
import { onItemDetails, onItemDetailsReject } from '../helpers/sharedCases';

const INITIAL_STATE = {
  item: {},
  pending: false,
};

const fetchGovermentFile = createAsyncThunk('govermentFile/findOne', (params) => {
  return application.call(API.GOVERMENT_FILES.FIND_ONE, params);
});

export const actions = {
  fetchGovermentFile,
};

function onStartPending(state) {
  return Object.assign(state, { pending: true });
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(fetchGovermentFile.fulfilled, onItemDetails)
    .addCase(fetchGovermentFile.pending, onStartPending)
    .addCase(fetchGovermentFile.rejected, onItemDetailsReject);
});
