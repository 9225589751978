import { Grid, IconButton, ListItem, ListItemText } from '@material-ui/core';
import React, { useCallback } from 'react';
import { SpacingContainer } from '../../../components/common/Containers/Base';
import SectionScrollable from '../../../components/common/Containers/SectionScrollable';
import { Divider } from '../../../components/common/Divider';
import PaginationSmall from '../../../components/common/Paginations/PaginationSmall';
import Typography from '../../../components/common/Typography';
import Bull from '../../../components/common/Typography/Bull';
import NothingFoundCard from '../../../components/generics/Cards/NothingFoundCard';
import SimpleSearchField from '../../../components/generics/SearchTopPanel/SearchField/SimpleSearchField';
import { IconRemove } from '../../../components/svg';
import { useTrans } from '../../../services/i18n';
import { emptyArray } from '../../../utils/arrayUtils';
import { intlDateWithTimeFormat } from '../../../utils/dateUtils';
import connect from './connect';
import { correctNotInterestedType } from './helpers';
import { RouterLink } from '../../../components/common/Buttons';
import {
  ROUTE_CONTRACT_OPPORTUNITIES_DETAILS,
  ROUTE_CONTRACT_AWARDS_DETAILS,
  ROUTE_GRANT_OPPORTUNITIES_DETAILS,
} from '../../../constants';

const sections = {
  FGO: ROUTE_GRANT_OPPORTUNITIES_DETAILS.path,
  FCO: ROUTE_CONTRACT_OPPORTUNITIES_DETAILS.path,
  NSN: null,
  CA: ROUTE_CONTRACT_AWARDS_DETAILS.path,
};

function NotInterestedList({
  listOfNotInterested,
  onInit,
  onDelete,
  onChangePage,
  onChangeRowPerPage,
  onChangeSearchText,
}) {
  const trans = useTrans().trans;
  const { items, filters, count, pending, pagination } = listOfNotInterested;
  const hasItems = !emptyArray(items);

  React.useEffect(() => {
    onInit();
  }, [onInit]);

  const handleDelete = useCallback((e) => onDelete(e.currentTarget.id), [onDelete]);

  const getDetailLink = (item) => {
    let id = item.typeId;

    if (item.type === 'CA') id = `${id}?i=${id}`;

    const link = sections[item.type] ? `${sections[item.type].replace(':id', id)}` : null;
    return link;
  };

  return (
    <SectionScrollable
      pending={pending}
      TopFixed={
        (hasItems || filters.text) && (
          <SimpleSearchField
            value={filters.text}
            onValueChange={onChangeSearchText}
            size="medium"
            name="NISearchText"
          />
        )
      }
      BottomFixed={
        <PaginationSmall
          {...pagination}
          count={count}
          onChangePage={onChangePage}
          onChangeRowPerPage={onChangeRowPerPage}
          pending={pending}
          divider="top"
          px={3}
          py={1}
        />
      }
    >
      {hasItems ? (
        <SpacingContainer px="4sm3" py={2} component="ul">
          {items.map((item, index) => (
            <React.Fragment key={item.id}>
              {index !== 0 && <Divider light spacing={1} component="li" />}

              <ListItem disableGutters>
                <ListItemText>
                  <Typography
                    fontFamily="lexend"
                    weight="500"
                    variant="body1"
                    component={RouterLink}
                    to={() => getDetailLink(item)}
                  >
                    {item.title}
                  </Typography>
                  <Grid container>
                    <Grid item>
                      <Typography color="hint" weight="600" variant="body2">
                        {trans('added')}{' '}
                        <Typography component="span" variant="body2">
                          {intlDateWithTimeFormat(item.updatedAt)}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Bull />
                    <Grid item>
                      <Typography color="hint" weight="600" variant="body2">
                        {trans('type')}{' '}
                        <Typography component="span" variant="body2">
                          {correctNotInterestedType(item.type)}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
                <IconButton aria-label="close" id={item.id} onClick={handleDelete}>
                  <IconRemove />
                </IconButton>
              </ListItem>
            </React.Fragment>
          ))}
        </SpacingContainer>
      ) : (
        <NothingFoundCard
          title={trans(!!filters.text ? 'NothingFound' : 'NothingHasBeenAddedYet')}
          description={trans(!!filters.text ? 'PleaseTrySomethingElse' : 'AllOpportunitiesMarkedAppearHere')}
          size="small"
          py={10}
        />
      )}
    </SectionScrollable>
  );
}

export default connect(NotInterestedList);
