import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconContractOpportunities = (props) => {
  const { width, height, thin = false } = props;

  return thin ? (
    <SvgIcon width={width || 42} height={height || 38} viewBox="0 0 42 38">
      <path
        d="M67 38c-2.761 0-5-2.239-5-5V13c0-2.761 2.239-5 5-5h7V5C74 2.311 76.122.118 78.783.005L79 0h8c2.689 0 4.882 2.122 4.995 4.783L92 5v3h7c2.689 0 4.882 2.122 4.995 4.783L104 13v20c0 2.761-2.239 5-5 5zm7-28h-7c-1.657 0-3 1.343-3 3v20c0 1.657 1.343 3 3 3h7V10zm16 0H76v26h14V10zm9 0h-7v26h7c1.598 0 2.904-1.249 2.995-2.824L102 33V13c0-1.657-1.343-3-3-3zM87 2h-8c-1.598 0-2.904 1.249-2.995 2.824L76 5v3h14V5c0-1.598-1.249-2.904-2.824-2.995L87 2z"
        transform="translate(-1100 -307) translate(971 182) translate(67 125)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width || 22} height={height || 22} viewBox="0 0 22 22">
      <path d="M3 21c-1.657 0-3-1.343-3-3V8c0-1.657 1.343-3 3-3h3V4c0-1.598 1.249-2.904 2.824-2.995L9 1h4c1.598 0 2.904 1.249 2.995 2.824L16 4v1h3c1.598 0 2.904 1.249 2.995 2.824L22 8v10c0 1.657-1.343 3-3 3zM6 7H3c-.552 0-1 .448-1 1v10c0 .552.448 1 1 1h3V7zm8 0H8v12h6V7zm5 0h-3v12h3c.513 0 .936-.386.993-.883L20 18V8c0-.552-.448-1-1-1zm-6-4H9c-.513 0-.936.386-.993.883L8 4v1h6V4c0-.513-.386-.936-.883-.993L13 3z" />
    </SvgIcon>
  );
};

IconContractOpportunities.propTypes = simpleIconPropsTypes;
