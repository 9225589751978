import Grid from '@material-ui/core/Grid';
import { Menu, MenuItem, Divider, withStyles } from '@material-ui/core/';
import React from 'react';
import { useTrans } from '../../../services/i18n';
import { Button } from '../../common/Buttons';
import LinkPursuitButton from './LinkPursuitButton';
import AddToPipelineButton from './AddToPipelineButton';
import connect from './connect';
import CreateQuoteRequest from './CreateQuoteRequest';
//import { CONTRACT_OPPORTUNITIES_TYPES_FOR_QUOTES_REQUESTS } from '../../../utils/constants';
import Typography from '../../common/Typography';
import { useComponentPermission } from '../../../hooks/useComponentPermission';
import { COMPONENT_FEATURES } from '../../../utils/constants';

const StyledMenu = withStyles({
  paper: {
    marginTop: '5px',
    width: '211px',
    maxHeight: '178px',
    padding: 0,
    '& ul': {
      padding: 0,
    },
  },
})((props) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const section = {
  contract: 'FCO',
  award: 'CA',
  grant: 'FGO',
  nsn: 'NSN',
};

const OpportunityListActions = ({
  onAddToNotIteresting,
  item,
  size = 'medium',
  type,
  settings,
  detail = false,
  coForQuoteRequests,
  variant = 'outlined',
  hideRequestQuote = false,
  hideNotInterested = false,
  removeContainer = false,
  buttonStyle = {},
}) => {
  const { trans } = useTrans();
  const { hasPermission } = useComponentPermission(COMPONENT_FEATURES.PURSUITS_PIPELINES_TASKS);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [visibility, setVisibility] = React.useState('visible');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setVisibility('visible');
  };

  const handleChildEvent = (value) => {
    setVisibility(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isCorrectOpportunityType = React.useMemo(() => {
    if (!item) return false;
    if (type === 'grant') return true;

    return coForQuoteRequests.some((i) => i.name === item?.type && i.checked);
  }, [item, type, coForQuoteRequests]);

  const [pending, setPending] = React.useState(false);

  const handleAddToNotInteresting = React.useCallback(async () => {
    if (!(item && onAddToNotIteresting)) return;
    setPending(true);
    onAddToNotIteresting({ id: item.id, title: item.title, type: type ? section[type] : null }).finally(() =>
      setPending(false)
    );
  }, [onAddToNotIteresting, item, type]);

  const Container = removeContainer ? React.Fragment : Grid;

  return (
    <Container container alignItems="center" spacing={2}>
      <Grid item>
        <Button
          id={`quick-actions-${size}`}
          size={size}
          aria-controls={'basic-menu'}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          color="primary"
          variant={variant}
          onClick={handleClick}
          pending={pending}
          style={buttonStyle}
        >
          {trans('quick-actions')}
        </Button>

        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': `quick-actions-${size}`,
          }}
          style={{ padding: 0, visibility }}
        >
          {hasPermission && item && item.id && ['Admin', 'Owner'].includes(settings.userRole) && (
            <>
              <LinkPursuitButton
                opportunity={item}
                type={type}
                id={`link-to-pursuit-${size}`}
                size={size}
                upAnchorEl={anchorEl}
                handleChildEvent={handleChildEvent}
              />
              <Divider variant="middle" />
              <AddToPipelineButton
                opportunity={item}
                type={type}
                id={`add-to-pipeline-${size}`}
                size={size}
                upAnchorEl={anchorEl}
                handleChildEvent={handleChildEvent}
              />
            </>
          )}
          <Divider variant="middle" />
          {!hideNotInterested && (
            <div style={{ paddingTop: 8, paddingBottom: 8 }}>
              <MenuItem onClick={handleAddToNotInteresting}>
                <Typography>{trans('not-interesting')}</Typography>
              </MenuItem>
            </div>
          )}
        </StyledMenu>
      </Grid>
      {!hideRequestQuote && item && item.id && ['Admin', 'Owner'].includes(settings.userRole) && (
        <>
          {['contract', 'grant'].includes(type) && !detail && isCorrectOpportunityType && (
            <Grid item>
              <CreateQuoteRequest opportunity={item} type={type} id={`create-quote-request-${size}`} size={size} />
            </Grid>
          )}
        </>
      )}
    </Container>
  );
};

export default connect(OpportunityListActions);
