import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconNotifications = (props) => {
  const { width, height, thin = false } = props;

  return thin ? (
    <SvgIcon width={width || 64} height={height || 64} viewBox="0 0 64 64">
      <path
        d="M302 58c.714 0 1.198.73.922 1.39-1.178 2.819-3.42 4.61-5.922 4.61s-4.744-1.791-5.922-4.61c-.276-.66.208-1.39.922-1.39zm-1.684 2.005h-6.633l.093.13c.837 1.128 1.92 1.788 3.037 1.855l.187.005c1.184 0 2.34-.669 3.224-1.86l.092-.13zM297 0c12.59 0 23 10.217 23 22.704v19.503c0 4.535 3.366 7.793 8 7.793l.133.007c1.156.124 1.156 1.862 0 1.986L328 52h-62l-.133-.007C264.668 51.864 264.713 50 266 50l.26-.003c4.5-.12 7.74-3.34 7.74-7.79V22.704C274 10.217 284.41 0 297 0zm0 2c-11.494 0-21 9.33-21 20.704v19.503c0 3.259-1.42 6.014-3.719 7.763l-.04.03h49.517l-.275-.215c-2.094-1.702-3.402-4.27-3.48-7.292l-.003-.286V22.704C318 11.33 308.494 2 297 2z"
        transform="translate(-688.000000, -261.000000) translate(423.000000, 261.000000)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width || 22} height={height || 22} viewBox="0 0 21 22">
      <path
        d="M12.559 19.17c.458.307.581.928.273 1.387-.301.45-.727.817-1.23 1.068-.491.244-1.042.372-1.6.372-.559 0-1.11-.128-1.6-.372-.504-.251-.929-.618-1.23-1.068-.308-.459-.186-1.08.273-1.388.459-.307 1.08-.185 1.387.274.105.156.263.293.462.391.211.106.456.163.708.163.251 0 .497-.057.708-.163.198-.098.357-.235.461-.39.308-.46.93-.582 1.388-.275zM10.002 0c1.856 0 3.637.737 4.95 2.05 1.312 1.313 2.05 3.093 2.05 4.95 0 2.9.523 5.079 1.378 6.646.286.525.586.935.879 1.244.158.167.265.256.298.278.823.549.434 1.832-.555 1.832h-18c-.99 0-1.378-1.283-.555-1.832.033-.022.14-.11.298-.278.292-.309.593-.719.879-1.244C2.479 12.08 3.002 9.9 3.002 7c0-1.857.737-3.637 2.05-4.95C6.365.737 8.145 0 10.002 0zm0 2c-1.326 0-2.598.527-3.536 1.464C5.53 4.402 5.002 5.674 5.002 7c0 3.225-.602 5.734-1.622 7.604-.076.138-.152.27-.228.396h13.7c-.077-.126-.153-.258-.228-.396-1.02-1.87-1.622-4.379-1.622-7.604 0-1.326-.527-2.598-1.465-3.536C12.6 2.527 11.327 2 10.002 2z"
        transform="translate(-1237 -19) translate(1237 11) translate(0 8)"
      />
    </SvgIcon>
  );
};

IconNotifications.propTypes = simpleIconPropsTypes;
