import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { application } from '../../services/application';
import { onPending, onReject } from '../helpers/sharedCases';
import { API } from '../../constants';
import { ANY_OF_THOSE, NONE_OF_THOSE } from '../../constants/filter';

const MORE_OPPORTUNITIES = {
  location: null,
  pagination: {
    page: 0,
    perPage: 3,
  },
  count: 0,
  items: [],
};

export const INITIAL_STATE = {
  item: {},
  pending: false,
  moreOpportunities: MORE_OPPORTUNITIES,
  history: {
    items: [],
    index: 0,
    current: {},
    nextIndex: 0,
    prevIndex: 0,
  },
  attachments: {},
};

export const fetchOpportunity = createAsyncThunk('grantOpportunities/fetchOpportunity', async ({ id, searchText }) => {
  return application.call('grantOpportunities.findOne', { id, searchText });
});

export const fetchMoreOpportunities = createAsyncThunk(
  'grantOpportunities/fetchMoreContractOpportunities',
  async (_, { getState }) => {
    const { grantOpportunityDetails } = getState();

    const { item } = grantOpportunityDetails;
    const { page, perPage } = grantOpportunityDetails.moreOpportunities.pagination;
    console.log('item', item);
    const params = {
      filters: {
        agencyCode: {
          type: ANY_OF_THOSE,
          value: [{ id: item.agency.code ? item.agency.code.toUpperCase() : '' }],
        },
        notId: {
          type: NONE_OF_THOSE,
          value: [{ id: item.id }],
        },
      },
      pagination: {
        page: page + 1,
        perPage,
      },
    };
    const data = await application.call(API.GRANT_OPPORTUNITIES.GET_LIST, params);
    return {
      ...data,
      pagination: {
        page: page + 1,
        perPage,
      },
    };
  }
);

export const setMoreOpportunities = createAction('grantOpportunities/setMoreOpportunities');

export const cleanStore = createAction('grantOpportunities/cleanStore');

export const changeHistory = createAction('contractOpportunities/changeHistory');

export const actions = {
  fetchOpportunity,
  fetchMoreOpportunities,
  setMoreOpportunities,
  cleanStore,
  changeHistory,
};

function onFetchGrantOpportunity(state, action) {
  const { filesLinks, downloadAllLink, downloadAllLinks, history } = action.payload.details;
  const index = history.length;
  return Object.assign(state, {
    item: action.payload.item,
    attachments: { filesLinks, downloadAllLink, downloadAllLinks },
    history: {
      items: [...history, action.payload.item],
      current: action.payload.item,
      index,
      nextIndex: history[index + 1] ? index + 1 : null,
      prevIndex: history[index - 1] ? index - 1 : null,
    },
    pending: false,
  });
}

function onChangeHistory(state, action) {
  const index = action.payload;
  const { history } = state;
  const historyObj = history.items[index];
  const item = historyObj;

  return Object.assign(
    state,
    {
      history: Object.assign({}, state.history, {
        item,
        index,
        current: historyObj,
        nextIndex: history.items[index + 1] ? index + 1 : null,
        prevIndex: history.items[index - 1] ? index - 1 : null,
      }),
    },
    { item, pending: false }
  );
}

function onFetchMoreOpportunities(state, action) {
  return Object.assign(state, {
    moreOpportunities: Object.assign(state.moreOpportunities, {
      items: [].concat(state.moreOpportunities.items, action.payload.items),
      count: action.payload.count,
      pagination: action.payload.pagination,
      pending: false,
    }),
  });
}
function onPendingMoreOpportunities(state) {
  return Object.assign(state, { moreOpportunities: Object.assign({}, state.moreOpportunities, { pending: true }) });
}
function onRejectFetchMoreOpportunities(state) {
  return Object.assign(state, { moreOpportunities: Object.assign({}, state.moreOpportunities, { pending: false }) });
}

function onSetMoreOpportunities(state, action) {
  return Object.assign(state, { moreOpportunities: action.payload });
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(fetchOpportunity.rejected, onReject)
    .addCase(fetchOpportunity.fulfilled, onFetchGrantOpportunity)
    .addCase(fetchOpportunity.pending, onPending)
    .addCase(fetchMoreOpportunities.rejected, onRejectFetchMoreOpportunities)
    .addCase(fetchMoreOpportunities.fulfilled, onFetchMoreOpportunities)
    .addCase(fetchMoreOpportunities.pending, onPendingMoreOpportunities)
    .addCase(setMoreOpportunities, onSetMoreOpportunities)
    .addCase(cleanStore, () => INITIAL_STATE)
    .addCase(changeHistory, onChangeHistory);
});
