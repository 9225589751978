import React from 'react';
import translation from './translations/en.json';

function replaceValues(source, values) {
  if (values) {
    return Object.keys(values).reduce((str, key) => {
      return str.replace(`{${key}}`, values[key]);
    }, String(source));
  }
  return source;
}

export const i18nContextInitialValue = {
  currentLanguage: 'en',
  translation,
  changeLanguage: () => {},
  trans: () => {},
  plurals: () => {},
};
export const I18nContext = React.createContext(i18nContextInitialValue);

export function useTrans() {
  return React.useContext(I18nContext);
}

export class I18nProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = { currentLanguage: 'en', translation };

    this.handlers = {
      trans: this.trans.bind(this),
      plurals: this.plurals.bind(this),
      changeLanguage: this.changeLanguage.bind(this),
    };
  }

  trans(key, values) {
    const { translation } = this.state;
    if (!translation[key]) console.warn('i18n. No such key - ', key);

    const text = translation[key] || key;
    return values ? replaceValues(text, values) : text;
  }

  plurals(val, ...keys) {
    return val === 1 ? this.trans(keys[0]) : this.trans(keys[1] || keys[0]);
  }

  changeLanguage(currentLanguage) {
    import(`./translations/${currentLanguage}.json`)
      .then((translation) => {
        this.setState((state) => ({ ...state, translation, currentLanguage }));
      })
      .catch(console.error);
  }

  render() {
    const value = { currentLanguage: this.state.currentLanguage, ...this.handlers };

    return <I18nContext.Provider value={value}>{this.props.children}</I18nContext.Provider>;
  }
}
