import * as Yup from 'yup';
import makeValidationSchema from '../../../../../hooks/makeValidationSchema';

export const initialValues = {
  orgName: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    orgName: Yup.string().required(trans('empty-not-allowed', { field: trans('orgName') })),
  })
);
