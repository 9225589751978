import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { Button, RouterLink } from '../../components/common/Buttons';
import { InlineContainer, SpacingContainer } from '../../components/common/Containers/Base';
import { ModalDialog } from '../../components/common/Dialogs';
import { Divider } from '../../components/common/Divider';
import { InputPropsSearch, TextField } from '../../components/common/Inputs';
import Typography from '../../components/common/Typography';
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard';
import { useTrans } from '../../services/i18n';
import { emptyArray } from '../../utils/arrayUtils';
import { intlDateFormat } from '../../utils/dateUtils';

export default function AddToPipeline({ item, pipelinesList, onAddToPipeline, success, pending, open, onCloseDialog }) {
  const { trans } = useTrans();

  return (
    <ModalDialog
      open={open}
      onClose={onCloseDialog}
      disableContentSpacing
      title={success ? `${trans('Success')}!` : trans('pipelines')}
      actions={
        success && (
          <InlineContainer>
            <Button variant="outlined" color="primary">
              {trans('ViewPipeline')}
            </Button>
            <Button variant="contained" color="primary">
              {trans('ViewPursuit')}
            </Button>
          </InlineContainer>
        )
      }
      pending={pending}
    >
      {success ? (
        <SpacingContainer px={4} py={4}>
          <Typography variant="h5" weight={500} fontFamily="lexend">
            The Pursuit was added to the Pipeline
          </Typography>
          <Typography variant="h6" component="p" weight={500} color="hint">
            {`${trans('Pipeline')} `}
            <RouterLink to="#">«Simple RFI/RFP Pipeline» (Triage)</RouterLink>
          </Typography>
        </SpacingContainer>
      ) : (
        <>
          <TextField
            value={''}
            onChange={undefined}
            name="search"
            fullWidth
            placeholder={'Search pipelines'}
            InputProps={InputPropsSearch}
            autoFocus
          />

          {!emptyArray(pipelinesList) ? (
            pipelinesList.map((pipeline, index) => (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <SpacingContainer px={4} py={0}>
                    <Divider light />
                  </SpacingContainer>
                )}

                <ListItem button onClick={onAddToPipeline.bind(null, pipeline)}>
                  <SpacingContainer px={2} py={1}>
                    <Typography variant="body1" weight={500}>
                      {pipeline.title}
                    </Typography>
                    <Typography variant="body2" color="hint" component="p">
                      {`${trans('updated')} ${intlDateFormat(pipeline.updatedAt)}`}
                    </Typography>
                  </SpacingContainer>
                </ListItem>
              </React.Fragment>
            ))
          ) : (
            <SpacingContainer px={3} py={3}>
              <NothingFoundCard
                title={trans('NothingFound')}
                description={trans('PleaseTrySomethingElse')}
                size="small"
              />
            </SpacingContainer>
          )}
        </>
      )}
    </ModalDialog>
  );
}
