import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_GOVERMENT_FILES, ROUTE_GOVERMENT_FILES_DETAILS } from '../../constants';
import Suspense from '../../services/lazyLoad/Suspense';

const GovermentFiles = React.lazy(() => import('./GovermentFiles'));
const GovermentFilesDetails = React.lazy(() => import('./Details'));

export default function GovermentFilesWrapper() {
  return (
    <Suspense>
      <Switch>
        <Route exact path={ROUTE_GOVERMENT_FILES_DETAILS.path}>
          <GovermentFilesDetails />
        </Route>
        <Route exact path={ROUTE_GOVERMENT_FILES.path}>
          <GovermentFiles />
        </Route>
        <Redirect exact path="/*" to={ROUTE_GOVERMENT_FILES.path} />
      </Switch>
    </Suspense>
  );
}
