import React from 'react';
import { Grid, Button, IconButton } from '@material-ui/core';

import MoreHoriz from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import PaginationSmall from '../../../../../../components/common/Paginations/PaginationSmall';
import { SpacingContainer, Paper } from '../../../../../../components/common/Containers';
import { Divider } from '../../../../../../components/common/Divider';
import DetailsContentBlock from '../../../../../../components/generics/Helpers/DetailsContentBlock';
import ListItemTextBase from '../../../../../../components/common/Containers/Base/ListItemTextBase';
import { ContextMenuListItem, Popover } from '../../../../../../components/common/Dialogs/Popover';
import { useTrans } from '../../../../../../services/i18n';
import Typography from '../../../../../../components/common/Typography';
import ReadMore from '../../../../../../components/common/ReadMore';
import usePopover from '../../../../../../hooks/usePopover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { IconDelete, IconPencil } from '../../../../../../components/svg';
import NoteModal from './NoteModal';
import ConfirmDialog from '../../../../../../components/generics/Confirm/Dialog';
import { DATE_TIME_FORMAT } from '../../../../../../constants';
import { formatDatePretty } from '../../../../../../utils/dateUtils';
import { useAlertContext } from '../../../../../../contexts/AlertContext';

const useStyles = makeStyles((theme) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  author: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.2rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'end',
  },
  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionsM: {
    position: 'absolute',
    right: 0,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const Note = ({ item, actions, notes, removeVendorNote, currentUserId, currentUserName }) => {
  const { trans } = useTrans();
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpenNoteModal, setIsOpenNoteModal] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [currentNote, setCurrentNote] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { successAlert, errorAlert } = useAlertContext();

  const [anchorEl, onOpen, onClosePopover] = usePopover();

  const { items, pending, pagination, count } = notes;
  const { onInit, onChangePage, onChangeRowPerPage } = actions;

  /***
   *
   */
  const onOpenPopover = (note) => {
    return (e) => {
      setCurrentNote(note);
      onOpen(e);
    };
  };

  /***
   *
   */
  const openNoteModal = (note) => {
    return () => {
      setIsOpenNoteModal(true);
      note && setCurrentNote(note);
    };
  };

  /***
   *
   */
  const closeNoteModal = () => {
    setCurrentNote(null);
    setIsOpenNoteModal(false);
  };

  /***
   *
   */
  const onCloseConfirm = () => {
    setCurrentNote(null);
    setOpenConfirm(false);
  };

  /***
   *
   */
  const onOpenConfirm = (note) => {
    return () => {
      setOpenConfirm(true);
      note && setCurrentNote(note);
    };
  };

  /***
   *
   */
  React.useEffect(() => {
    if (!item.id) return;
    onInit({ vendorManagementId: item.id });
  }, [onInit, item.id]);

  /***
   * Fix error when the last item in a page different from 1 is deleted
   */
  React.useEffect(() => {
    if (count > 0 && count <= (pagination.page - 1) * pagination.perPage) {
      onChangePage(pagination.page - 1);
    }
  }, [count]);

  return (
    <Paper hidden>
      <NoteModal open={isOpenNoteModal} onClose={closeNoteModal} note={currentNote} />
      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        <ContextMenuListItem button onClick={openNoteModal()}>
          <ListItemTextBase>{trans('edit')}</ListItemTextBase>
        </ContextMenuListItem>

        <ContextMenuListItem button onClick={onOpenConfirm()} onClickCB={onClosePopover}>
          <ListItemTextBase>{trans('delete')}</ListItemTextBase>
        </ContextMenuListItem>
      </Popover>

      <ConfirmDialog
        open={openConfirm}
        onClose={onCloseConfirm}
        onConfirm={async () => {
          try {
            setLoading(true);
            if (currentNote.id) {
              const resp = await removeVendorNote({ id: currentNote.id });
              if (resp?.error) {
                errorAlert('Error deleting Note');
              } else {
                successAlert('Success!');
                onCloseConfirm();
                // Get all items in order to change the current page if applies
                onInit({ vendorManagementId: item.id });
              }
            }
            setLoading(false);
          } catch (error) {
            errorAlert('Error deleting Note');
            setLoading(false);
          }
        }}
        noIcon
        pending={loading}
        title={trans('delete-org-note')}
        noText
        withOutBackground
      >
        <Typography variant="h2">{trans('confirm-delete-org-note')}</Typography>
        <Typography variant="body1" color="secondary" weight="500">
          {currentNote?.title}
        </Typography>
      </ConfirmDialog>
      <SpacingContainer>
        <DetailsContentBlock
          title={trans('org-notes')}
          pending={pending}
          actions={
            isMobile ? (
              <Button variant="contained" color="primary" onClick={openNoteModal()} size="md">
                <AddIcon />
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={openNoteModal()}>
                &nbsp;{trans('add-note')} &nbsp;
              </Button>
            )
          }
        >
          <Grid container alignItems="center" spacing={2} xs={12}>
            <Grid item xs={12} spacing={2} className={styles.container}>
              <Grid />
              {items?.length > 0 &&
                items.map((row) => (
                  <React.Fragment key={row.id}>
                    <SpacingContainer px="5sm3" py={0}>
                      <Grid container xs={12} className={styles.cell}>
                        <Grid item lg={10} md={10} xs={10} flexDirection="column">
                          <Grid container className={styles.containerHeader}>
                            <Grid item className={styles.title} {...(isMobile && { style: { width: '100%' } })}>
                              <div>
                                <Typography variant="h6" weight="500" color="black">
                                  {row.title}
                                </Typography>
                              </div>
                              {isMobile && currentUserId === row.author?.userName && (
                                <div>
                                  <IconButton onClick={onOpenPopover(row)} size="small">
                                    <MoreHoriz />
                                  </IconButton>
                                </div>
                              )}
                            </Grid>
                            <Grid item className={styles.author}>
                              <Typography variant="body2" style={{ color: '#888FA9' }}>
                                {trans('Created')}
                              </Typography>
                              <Typography variant="body2" style={{ color: '#4E5978' }}>
                                {formatDatePretty(row.created_at || new Date(), DATE_TIME_FORMAT).toLowerCase()}
                              </Typography>
                              <Typography variant="body2" style={{ color: '#888FA9' }}>
                                By:
                              </Typography>
                              <Typography variant="body2" style={{ color: '#4E5978' }}>
                                {row?.author?.name || currentUserName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <div>
                            <ReadMore variant="body2">{row.note || trans('not-provided')}</ReadMore>
                          </div>
                        </Grid>

                        {!isMobile && (
                          <Grid item lg={2} md={2} xs={2} className={styles.actions}>
                            <IconButton onClick={openNoteModal(row)} disabled={currentUserId !== row.userId}>
                              <IconPencil color={currentUserId === row.userId ? '#2445a7' : '#999999'} />
                            </IconButton>
                            <IconButton onClick={onOpenConfirm(row)} disabled={currentUserId !== row.userId}>
                              <IconDelete color={currentUserId === row.userId ? '#2445a7' : '#999999'} />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </SpacingContainer>
                    <Divider light className={styles.marginX} />
                  </React.Fragment>
                ))}
              <PaginationSmall
                {...pagination}
                count={count}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                pending={pending}
                px="5sm3"
                py={2}
              />
            </Grid>
          </Grid>
        </DetailsContentBlock>
      </SpacingContainer>
    </Paper>
  );
};

export default Note;
