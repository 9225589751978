import React from 'react';
import AWSRumScript from './AWSRumScript';
import UserPilotScripts from './UserPilotScripts';

function Scripts({ authUser, orgSettings, stage }) {
  return (
    <>
      {authUser && <UserPilotScripts user={authUser} orgSettings={orgSettings} stage={stage} />}
      <AWSRumScript stage={stage} />
    </>
  );
}

export default Scripts;
