import React from 'react';
import { useApiAutocomplete } from '../../hooks/useApiAutocomplete';
import Autocomplete from '../common/Inputs/Autocomplete';

const getOptionLabel = (option) => (option ? option.value : '');

export const OrganizationTypesAutocomplete = (props) => {
  const { onChange, value, multi, labelKey = 'value', label, ...rest } = props;
  const { options, loading, handleValueChange, filterOptions, handleInputChange } = useApiAutocomplete({
    labelKey,
    onChange: React.useCallback((val) => onChange && onChange(val), [onChange]),
    getOptionList: (params) => [
      { id: '501(C)(3) Nonprofit', value: '501(C)(3) Nonprofit' },
      { id: 'Small Business', value: 'Small Business' },
      { id: 'Individuals', value: 'Individuals' },
    ],
  });

  const validateValueArray = () => {
    if (Array.isArray(value)) {
      return value;
    } else {
      return [];
    }
  };

  return (
    <Autocomplete
      name="grant-programs"
      options={options}
      label={label}
      labelKey={labelKey}
      loading={loading}
      value={validateValueArray()}
      filterOptions={filterOptions}
      onChange={handleValueChange}
      multiple={multi}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};
