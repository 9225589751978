import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
//import test from '../../components/assests/logo.svg';
import Avatar from '../../components/common/Cards/Avatar';
import { Paper } from '../../components/common/Containers';
import { SpacingContainer } from '../../components/common/Containers/Base';
import { Divider } from '../../components/common/Divider';
import Button from '../../components/common/Buttons/Button';
import Loader from '../../components/common/Loader';
import Typography from '../../components/common/Typography';
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase';
import { IconVendors } from '../../components/svg/Icons';
import { useTrans } from '../../services/i18n';
import ManageUsers from './components/ManageUsers';
//import SetupModal from './components/SetupModal';
import connect from './connect';
import OrganizationDetails from './components/OrganizationDetails';
import OrganizationTeaming from './components/OrganizationTeaming';
import { Chip } from '../../components/common/Chip';
import RegisterOrganizationForm from './components/RegisterOrganizationForm';
import AddUpdateOrganizationForm from './components/AddUpdateOrganizationForm';
import { useHistory } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DetailsAnchorsHeader from '../../components/generics/DetailsAnchorsHeader';
import SectionScrollable from '../../components/common/Containers/SectionScrollable';
import Seats from './components/Seats';
import AvailableSeats from './components/Seats/AvailableSeats';
import { AVATAR_STATUSES } from '../../constants';

function OrgSettings({
  settings,
  getPublicProfile,
  getOrganizationInfo,
  getUsers,
  getAvatarDownloadUrl,
  getUserRole,
  setupAvatar,
  openRegisterOrgModal,
  openRegisterOrg,
  openAddUpdateOrgModal,
  openAddUpdateOrg,
}) {
  const { trans } = useTrans();
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  const tabsFixed = [
    { id: 'details', i18nKey: 'Details', key: 'details' },
    { id: 'setup', i18nKey: 'Setup', key: 'setup' },
    { id: 'featured', i18nKey: 'Featured', key: 'featured' },
    { id: 'profile', i18nKey: 'Profile', key: 'profile' },
    { id: 'users', i18nKey: 'Users', key: 'users' },
  ];

  const getAvatar = React.useCallback(
    (id) => {
      getAvatarDownloadUrl(id)
        .then((url) => setupAvatar({ avatar: url }))
        .catch(console.warn);
    },
    [getAvatarDownloadUrl, setupAvatar]
  );

  const [tabs, setTabs] = useState([]);

  const allowRole = ['admin', 'owner'].includes(settings?.userRole?.toLowerCase());

  useEffect(() => {
    setLoading(true);
    Promise.all([getOrganizationInfo()]).finally(() => {
      setLoading(false);
    });
  }, [getOrganizationInfo, getUsers]);

  useEffect(() => {
    if (settings.avatarId) {
      getAvatar(settings.avatarId);
    }
  }, [getAvatar, settings.avatarId]);

  useEffect(() => {
    const tabs = [...tabsFixed];
    if (!settings.onPublicProfile) tabs.splice(3, 1);
    if (!settings.vendorClaimed) tabs.splice(2, 1);
    setTabs(tabs);
  }, [settings.onPublicProfile, settings.vendorClaimed]);

  return (
    <SectionScrollable TopFixed={<DetailsAnchorsHeader anchors={tabs} backLink={'/dashboard'} onPrint={undefined} />}>
      <SpacingContainerBase>
        <AddUpdateOrganizationForm open={openAddUpdateOrgModal} onClose={() => openAddUpdateOrg(false)} />

        <RegisterOrganizationForm open={openRegisterOrgModal} onClose={() => openRegisterOrg(false)} />
        <Loader open={loading} />

        <Typography weight="600" variant="h2" fontFamily="lexend" gutter="bottomSpaceBig">
          {trans('org-settings')}
        </Typography>
        <Paper style={{ position: 'relative' }}>
          <Paper style={{ width: '100%', boxShadow: '0 16px 16px 0 rgba(0, 0, 0, 0.12)' }}>
            <SpacingContainer px={4} py={5}>
              <Grid container alignItems="center">
                {settings.orgName &&
                settings.avatarId &&
                settings.avatarUrl &&
                settings.profileImgStatus === AVATAR_STATUSES.approved ? (
                  <Avatar src={settings.avatarId && settings.avatarUrl} alt="-" />
                ) : (
                  !loading && (
                    <Paper style={{ background: '#f1f3f9', padding: '1rem' }}>
                      <IconVendors />
                    </Paper>
                  )
                )}

                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                  <Typography weight="400" variant="h1Medium" fontFamily="lexend" style={{ marginLeft: 30 }}>
                    {settings.orgName || 'Setup Your Organization'}
                  </Typography>
                  {settings.profileImgStatus && (
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: '30px',
                        alignItems: 'center',
                        gap: 10,
                        padding: '0 0 1rem 0',
                      }}
                    >
                      {settings.profileImgStatus === AVATAR_STATUSES.pending ? (
                        <Chip
                          color="primary"
                          style={{ backgroundColor: '#FFAC00' }}
                          label={trans('pending-image-profile')}
                        />
                      ) : (
                        <Chip
                          label={
                            settings.profileImgStatus === AVATAR_STATUSES.approved
                              ? trans('approved-image-profile')
                              : trans('rejected-image-profile')
                          }
                          color={settings.profileImgStatus === AVATAR_STATUSES.approved ? 'success' : 'error'}
                        />
                      )}
                    </div>
                  )}
                  <div style={{ display: 'flex', marginLeft: '30px', alignItems: 'center', gap: 10 }}>
                    {settings.vendorClaimed ? (
                      <Chip
                        color="success"
                        label={
                          <>
                            <CheckCircleOutlineIcon style={{ fontSize: '16px', marginRight: '5px' }} />{' '}
                            {trans('registered-organization')}{' '}
                          </>
                        }
                      />
                    ) : (
                      <Chip label={trans('unregistered')} />
                    )}

                    <AvailableSeats settings={settings} />
                  </div>

                  {allowRole && (
                    <Button
                      style={{ position: 'absolute', right: '50px', top: '50px' }}
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => openAddUpdateOrg(true)}
                    >
                      {trans('edit')}
                    </Button>
                  )}
                </div>
              </Grid>
            </SpacingContainer>
          </Paper>
        </Paper>

        {allowRole && (
          <Paper id="details" style={{ marginTop: '16px' }}>
            <Divider light />
            <OrganizationDetails openRegisterOrg={openRegisterOrg} loading={loading} />
          </Paper>
        )}

        {allowRole && (
          <Paper id="details" style={{ marginTop: '16px' }}>
            <Divider light />
            <OrganizationTeaming loading={loading} openAddUpdateOrg={openAddUpdateOrg} />
          </Paper>
        )}

        {settings?.userRole?.toLowerCase() === 'owner' &&
          !settings?.associatedSalesforceId &&
          settings?.subscription && <Seats settings={settings} />}

        {!loading && settings.orgName && allowRole && (
          <Paper id="users" style={{ marginTop: '16px' }}>
            <Divider light />
            <ManageUsers seats={settings?.seats} />
          </Paper>
        )}
      </SpacingContainerBase>
    </SectionScrollable>
  );
}

export default connect(OrgSettings);
