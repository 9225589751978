import { IconButton, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import usePopover from '../../../../../../../../hooks/usePopover';
import { useTrans } from '../../../../../../../../services/i18n';
import {
  BottomLeftPosition,
  ContextMenuListItem,
  Popover,
} from '../../../../../../../../components/common/Dialogs/Popover';
import Typography from '../../../../../../../../components/common/Typography';
import connect from '../../../../../connect';
import { ReactComponent as FilterIcon } from '../../.././../../icons/ico-filter-alt.svg';
import { ReactComponent as ResetIcon } from '../../.././../../icons/ico-reset.svg';
import { ReactComponent as CloseIcon } from '../../.././../../icons/ico-close-popup.svg';

import { Divider } from '../../../../../../../../components/common/Divider';

import { SelectField } from '../../../../../../../../components/common/Inputs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TagsAutocomplete } from '../../../../../../../../components/autocompletes/TagsAutocomplete';
import { NaicsCodeAutocomplete } from '../../../../../../../../components/autocompletes/NaicsCodeAutocomplete';
import { SetAsideAutocomplete } from '../../../../../../../../components/autocompletes/SetAsideAutocomplete';
import { LocationsAutocomplete } from '../../../../../../../../components/autocompletes/LocationsAutocomplete';
import { emptyArray } from '../../../../../../../../utils/arrayUtils';

const useStyles = makeStyles((theme) => ({
  labelDropdowns: {
    fontFamily: 'Lexend',
    marginBottom: '8px',
    color: '#4e5978',
    fontWeight: 500,
  },
  input: {
    padding: '8px 18px',
  },
}));

const Filters = ({ vendorsTags, filters, getList, isSmScreen = false }) => {
  const styles = useStyles();

  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const { trans } = useTrans();
  const [expirationStatuses, setExpirationStatuses] = useState([
    {
      name: 'All',
      value: 'all',
    },
    {
      name: 'Expired Soon',
      value: 'soonExpired',
    },
    {
      name: 'Expired',
      value: 'expired',
    },
  ]);

  const [tags, setTags] = useState([{ create: 'All', value: 'all' }]);
  const [setAsides, setSetAsides] = useState([]);
  const [naics, setNaics] = useState([]);
  const [locations, setLocations] = useState([]);

  const [expirationStatus, setExpirationStatus] = useState(expirationStatuses[0]);
  const [tag, setTag] = useState([]);

  const changeExpirationStatus = (ev) => {
    setExpirationStatus(ev.target.value);
    const copyFilters = { ...filters };
    copyFilters.type = ev.target.value.value;
    getList(copyFilters);
  };

  function changeTag(ev) {
    if (ev.target.value.some((i) => i.value === 'all')) {
      setTag(() => tags.filter((t) => t.value === 'all'));
    } else {
      setTag(() => ev.target.value);
    }
    filterTags(ev.target.value);
  }
  const filterTags = (val) => {
    const copyFilters = { ...filters };
    if (emptyArray(val) || val.some((i) => i.value === 'all')) {
      delete copyFilters.filters.tags;
    } else {
      copyFilters.filters = {
        tags: { type: 'ANY_OF_THOSE', value: val.map((t) => t.create) },
      };
    }

    getList(copyFilters);
  };

  const changeLocations = (ev) => {
    const val = ev.target.value;
    setLocations(ev.target.value);
    const copyFilters = { ...filters };
    if (emptyArray(val)) {
      delete copyFilters.filters.state;
    } else {
      copyFilters.filters = {
        state: { type: 'ANY_OF_THOSE', value: val.map((l) => ({ id: l.code, value: l.code })) },
      };
    }

    getList(copyFilters);
  };

  const changeSetAsides = (ev) => {
    const val = ev.target.value;
    setSetAsides(ev.target.value);
    const copyFilters = { ...filters };
    copyFilters.filters = {
      setAsides: { type: 'ANY_OF_THOSE', value: val.map((sa) => ({ id: sa.id, value: sa.id })) },
    };
    getList(copyFilters);
  };
  const changeNaics = (ev) => {
    const val = ev.target.value;
    setNaics(ev.target.value);
    const copyFilters = { ...filters };
    copyFilters.filters = {
      naics: { type: 'ANY_OF_THOSE', value: val },
    };
    getList(copyFilters);
  };

  const resetFilters = () => {
    setTag([]);
    setSetAsides([]);
    setNaics([]);
    setLocations([]);
    setExpirationStatus(expirationStatuses[0]);
    const copyFilters = { ...filters };
    copyFilters.filters = {};
    getList(copyFilters);
  };

  useEffect(() => {
    setTags([{ create: 'all', value: 'all' }, ...vendorsTags]);
  }, [vendorsTags]);

  return (
    <>
      <IconButton onClick={onOpenPopover}>
        <FilterIcon />
      </IconButton>

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover} {...BottomLeftPosition}>
        <ContextMenuListItem
          size="normal"
          style={{ width: '400px', padding: '16px 16px', display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography variant="h5" weight="600" noWrap>
            {trans('quick-filters')}
          </Typography>

          <div>
            <ResetIcon onClick={resetFilters} style={{ marginRight: '32px', cursor: 'pointer' }} />{' '}
            <CloseIcon onClick={() => onClosePopover()} style={{ cursor: 'pointer' }} />
          </div>
        </ContextMenuListItem>

        <Divider light />

        <div className={styles.input} style={{ marginTop: '15px' }}>
          <div className={styles.labelDropdowns}>{trans('expiration-status')}</div>
          <SelectField
            fullWidth
            value={expirationStatus}
            onChange={changeExpirationStatus}
            renderValue={(item) => item && `${item.name}`}
            type="select"
          >
            {expirationStatuses.map((item, ind) => (
              <MenuItem key={ind} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </SelectField>
        </div>

        <div className={styles.input}>
          <SetAsideAutocomplete
            label={trans('set-asides')}
            placeholder={trans('typeToSearchSetAsides')}
            multi
            value={setAsides}
            onChange={changeSetAsides}
          />
        </div>

        <div className={styles.input}>
          <NaicsCodeAutocomplete
            label={trans('naics-code')}
            placeholder={trans('typeToSearchNaics')}
            multi
            value={naics}
            onChange={changeNaics}
          />
        </div>

        <div className={styles.input}>
          <LocationsAutocomplete
            label={trans('locations')}
            labelKey="name"
            placeholder={trans('typeToSearchLocation')}
            multi
            value={locations}
            onChange={changeLocations}
          />
        </div>

        <div className={styles.input}>
          <TagsAutocomplete
            label={trans('tag')}
            required
            placeholder={trans('tag-name')}
            multi
            freeSolo={false}
            options={tags}
            value={tag}
            onChange={changeTag}
          />
        </div>
      </Popover>
    </>
  );
};

export default connect(Filters);
