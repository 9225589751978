import Grid from '@material-ui/core/Grid';
import { useEffect, useCallback, useState } from 'react';
import { useForm } from '../../../../../hooks/useForm';
import { useTrans } from '../../../../../services/i18n';
import { Button } from '../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../components/common/Dialogs';
import { TextField, DatePicker } from '../../../../../components/common/Inputs';
import { getInitialValues, useScheme } from './helpers';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import { Typography } from '@material-ui/core';
import connect from '../../connect';
import { NaicsCodeAutocomplete } from '../../../../../components/autocompletes/NaicsCodeAutocomplete';
import { SetAsideAutocomplete } from '../../../../../components/autocompletes/SetAsideAutocomplete';

const formOptions = { validateOnMount: false, validateOnChange: true, enableReinitialize: true };

const AddVendorManuallyForm = ({ open, onClose, addVendor, getList, filters, openAddVendor, editVendorManagement }) => {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const [pending, setPending] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      addToTheList(values);
    },
    [onClose, addVendor]
  );

  const { getFieldProps, handleSubmit, resetForm } = useForm(
    schema,
    getInitialValues(editVendorManagement),
    onSubmit,
    formOptions
  );

  useEffect(() => {}, []);

  const closeAddVendorModal = () => {
    resetForm();
    onClose();
    openAddVendor(false);
  };

  const addToTheList = (values) => {
    try {
      setPending(true);

      const naics = values.naicsCode.map((i) => i.id);
      const asides = values.setAsides.map((i) => i.id);
      const data = { ...values, naicsCode: naics, setAsides: asides };

      addVendor({ ...data, typeSave: 'Unregistered' }).then((res) => {
        setPending(false);
        const editting = values.id ? true : false;
        if (res.payload) {
          getList(filters);
          closeAddVendorModal();
          if (res.payload.exists) {
            infoAlert('The vendor has already been added!');
          } else {
            if (data.cageCode === '' && data.ueiCode === '') {
              successAlert('Vendor added to draft!');
            } else {
              successAlert(`Vendor ${editting ? 'edited' : 'added'}`);
            }
          }
        } else {
          errorAlert(`Failed to ${editting ? 'edit' : 'add'} vendor`);
        }
      });
    } catch (error) {
      setPending(false);
      errorAlert('Failed to add vendor');
    }
  };

  return (
    <ModalDialog
      open={open}
      onClose={() => closeAddVendorModal()}
      title={editVendorManagement?.id ? trans('edit-vendor-manually') : trans('add-vendor-manually')}
      actions={
        <div>
          <Button variant="outlined" color="primary" onClick={() => closeAddVendorModal()}>
            {trans('cancel')}
          </Button>
          <Button
            style={{ marginLeft: '16px' }}
            variant="contained"
            color="success"
            onClick={handleSubmit}
            pending={pending}
          >
            {editVendorManagement?.id ? trans('save') : trans('add-to-the-list')}
          </Button>
        </div>
      }
    >
      <Grid container spacing={3}>
        {!editVendorManagement?.id && (
          <Grid item xs={12}>
            <Typography variant="h6">
              To add a new Vendor to your list please enter information below. This Vendor will only be visible to your
              organization.
            </Typography>
          </Grid>
        )}

        <Grid container style={{ padding: '0  12px 16px 12px' }}>
          <Grid item xs={12}>
            <TextField
              label={trans('vendor-name')}
              placeholder={trans('required')}
              required
              maxLength={80}
              {...getFieldProps('name')}
            />
          </Grid>

          <Grid container style={{ marginTop: '25px' }}>
            <Grid item xs={6} style={{ paddingRight: '16px' }}>
              <TextField
                label={trans('cage-code')}
                placeholder={trans('optional')}
                maxLength={80}
                {...getFieldProps('cageCode')}
              />
            </Grid>
            {/*<Grid item xs={4} style={{ paddingRight: '16px' }}>
              <TextField
                label={trans('duns-code')}
                placeholder={trans('optional')}
                maxLength={80}
                {...getFieldProps('dunsCode')}
              />
            </Grid>*/}

            <Grid item xs={6} style={{ paddingRight: '16px' }}>
              <TextField
                label={trans('uei-code')}
                placeholder={trans('optional')}
                maxLength={80}
                {...getFieldProps('ueiCode')}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '30px' }}>
            <Typography variant="h6">{trans('additional-information')}</Typography>
          </Grid>

          <Grid container style={{ marginTop: '25px' }}>
            <Grid item xs={6} style={{ paddingRight: '16px' }}>
              <DatePicker
                label={trans('business-start-date')}
                placeholder={trans('optional')}
                maxLength={80}
                {...getFieldProps('bussinessStartDate')}
              />
            </Grid>

            <Grid item xs={6} style={{ paddingRight: '16px' }}>
              <DatePicker
                label={trans('expiration-date')}
                placeholder={trans('optional')}
                maxLength={80}
                {...getFieldProps('expirationDate')}
              />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '16px' }}>
              <SetAsideAutocomplete
                label={trans('set-asides')}
                placeholder={trans('typeToSearchSetAsides')}
                multi
                {...getFieldProps('setAsides')}
              />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '16px' }}>
              <NaicsCodeAutocomplete
                label={trans('naics-code')}
                placeholder={trans('typeToSearchNaics')}
                multi
                {...getFieldProps('naicsCode')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

export default connect(AddVendorManuallyForm);
