import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { IconClose } from '../../../../svg/Icons/IconClose';

export default function CloseTitle({ onClose }) {
  const classes = useStyles();

  return (
    <IconButton className={classes.close} onClick={onClose} disableRipple>
      <IconClose />
    </IconButton>
  );
}

const useStyles = makeStyles({
  close: { position: 'absolute', top: 8, right: 8 },
});
