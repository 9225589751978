import React from 'react';
import Typography from '../Typography';

const ReadMore = ({ children, hiddenLength = 150, ...props }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Typography {...props}>
      {isReadMore ? text.slice(0, hiddenLength) : text}
      {text.length > hiddenLength && (
        <span onClick={toggleReadMore} style={{ color: '#2445A7' }}>
          {isReadMore ? ' View More' : ' Show less'}
        </span>
      )}
    </Typography>
  );
};

export default ReadMore;
