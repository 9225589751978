import React from 'react';
import { Paper, SpacingContainer } from '../../../../../common/Containers';
import NothingFoundCard from '../../../../../generics/Cards/NothingFoundCard';
import { IconSearch } from '../../../../../svg';
import { useTrans } from '../../../../../../services/i18n';
import bellIcon from './assets/bell-icon.svg';
import Typography from '../../../../../common/Typography';
import { Button } from '../../../../../common/Buttons';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

function NothingFound({ title, description, canClearFilters = false, onClearFilters }) {
  const theme = useTheme();
  const { trans } = useTrans();

  return (
    <Paper style={{ height: '100%', backgroundColor: '#FFFFFF80' }}>
      <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <img src={bellIcon} alt="Nothing Found" style={{ width: '4rem', marginBottom: '1rem' }} />

        <Typography variant="body2" weight="600" color="label">
          {title}
        </Typography>
        <Typography variant="body2" weight="400" color="hint">
          {description}
        </Typography>

        {canClearFilters && (
          <Button
            style={{ marginTop: '1rem', color: theme.palette.primary.main }}
            variant="link"
            onClick={onClearFilters}
          >
            {trans('clear-filters')}
          </Button>
        )}
      </Box>
    </Paper>
  );
}

export default NothingFound;
