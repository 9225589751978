import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './auth';
import codeCategoriesReducer from './codeCategories';
import codeCategoryDetailsReducer from './codeCategoryDetails';
import contractAwardDetailsReducer from './contractAwardDetails';
import contractAwardsReducer from './contractAwards';
import contractOpportunitiesReducer from './contractOpportunities';
import contractOpportunityDetails from './contractOpportunityDetails';
import ezSearchReducer from './ezSearch';
import federalAgenciesAndOfficesReducer from './federalAgenciesAndOffices';
import federalAgencyDetailsReducer from './federalAgencyDetails';
import federalAgentDetailsReducer from './federalAgentDetails';
import federalAgentsReducer from './federalAgents';
import govermentFilesReducer from './govermentFiles';
import govermentFilesDetails from './govermentFilesDetails';
import grantOpportunitiesReducer from './grantOpportunities';
import grantOpportunityDetailsReducer from './grantOpportunityDetails';
import listOfNotInterested from './listOfNotInterested';
import notificationsReducer from './notifications';
import { orgSettingsReducer } from './orgSettings';
import { orgUsersReducer } from './orgUsers';
import savedSearchesReducer from './savedSearches';
import vendorsReducer from './vendors';
import vendorsDetailsReducer from './vendorsDetails';
import pipelineDetails from './pipelinesDetails';
import pipelinesReducer from './pipelines';
import pursuitsReducer from './pursuits';
import discussionsReducer from './discussions';
import filesReducer from './pursuitsFiles';
import dashboard from './dashboard';
import tasksReducer from './tasks';
import personalTasksReducer from './personalTasks';
import myPursuitsReducer from './myPursuits';
import vendorsManagementReducer from './vendorsManagement';
import subscriptionReducer from './subscription';
import teamingOpportunitiesReducer from './teamingOpportunities';
import nsnOpportunitiesReducer from './nsnOpportunities';
import businessOverviewReducer from './business/overview';
import businessTicketsReducer from './business/tickets';
import businessDocumentsReducer from './business/documents';
import clientPortalReducer from './clientPortal';
import releasesReducer from './releases';
import billingReducer from './billing';
import { globalSettingsReducer } from './globalSettings';
import quoteRequestsReducer from './quoteRequests';
import exportsReducer from './exports';
import suggestedSearchesReducer from './suggestedSearches';
import exceptionsReducer from './exceptions';
import calendarReducer from './calendar';
import stageReducer from './stage';
import listUserOrganizationsReducer from './listUserOrganizations';
import localVendorsDetailsReducer from './localVendorsDetails';
import vendorsManagementDetailsReducer from './vendorsManagementDetails';
import organizationSavedSearchesReducer from './organizationSavedSearches';
import stateLocalContractOpportunitiesReducer from './stateLocalContractOpportunities';
import stateLocalContractOpportunityDetailsReducer from './stateLocalContractOpportunityDetails';
/**
 * @type {EnhancedStore}
 */

const combinedReducer = combineReducers({
  auth: authReducer,
  notifications: notificationsReducer,
  orgSettings: orgSettingsReducer,
  orgUsers: orgUsersReducer,
  savedSearches: savedSearchesReducer,
  contractOpportunities: contractOpportunitiesReducer,
  listOfNotInterested: listOfNotInterested,
  contractOpportunityDetails: contractOpportunityDetails,
  grantOpportunityDetails: grantOpportunityDetailsReducer,
  grantOpportunities: grantOpportunitiesReducer,
  contractAwards: contractAwardsReducer,
  contractAwardDetails: contractAwardDetailsReducer,
  federalAgents: federalAgentsReducer,
  federalAgentDetails: federalAgentDetailsReducer,
  federalAgenciesAndOffices: federalAgenciesAndOfficesReducer,
  federalAgencyDetails: federalAgencyDetailsReducer,
  codeCategories: codeCategoriesReducer,
  codeCategoryDetails: codeCategoryDetailsReducer,
  govermentFilesDetails: govermentFilesDetails,
  govermentFiles: govermentFilesReducer,
  dashboard: dashboard,
  pipelineDetails: pipelineDetails,
  vendors: vendorsReducer,
  pipelines: pipelinesReducer,
  pursuits: pursuitsReducer,
  myPursuits: myPursuitsReducer,
  discussions: discussionsReducer,
  filesPursuit: filesReducer,
  vendorsDetails: vendorsDetailsReducer,
  ezSearch: ezSearchReducer,
  tasks: tasksReducer,
  personalTasks: personalTasksReducer,
  vendorsManagement: vendorsManagementReducer,
  subscription: subscriptionReducer,
  teamingOpportunities: teamingOpportunitiesReducer,
  nsnOpportunities: nsnOpportunitiesReducer,
  businessOverview: businessOverviewReducer,
  businessTickets: businessTicketsReducer,
  businessDocuments: businessDocumentsReducer,
  clientPortal: clientPortalReducer,
  releases: releasesReducer,
  billing: billingReducer,
  globalSettings: globalSettingsReducer,
  quoteRequests: quoteRequestsReducer,
  exports: exportsReducer,
  suggestedSearches: suggestedSearchesReducer,
  exceptions: exceptionsReducer,
  calendar: calendarReducer,
  stage: stageReducer,
  listUserOrganizations: listUserOrganizationsReducer,
  localVendorsDetails: localVendorsDetailsReducer,
  vendorsManagementDetails: vendorsManagementDetailsReducer,
  organizationSavedSearches: organizationSavedSearchesReducer,
  stateLocalContractOpportunities: stateLocalContractOpportunitiesReducer,
  stateLocalContractOpportunityDetails: stateLocalContractOpportunityDetailsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/login') {
    const isEmpty = Object.keys(action.payload).length === 0;

    if (isEmpty) {
      state = undefined;
    }
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer, //,
  // middleware: [...getDefaultMiddleware()]
});

//export default configureStore();
