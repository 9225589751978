import Grid from '@material-ui/core/Grid';
import React from 'react';
import { LimitHeight, Paper, SpacingContainer } from '../../../../components/common/Containers';
import useDragAndDropState from '../../../../hooks/dnd/useDragAndDropState';
import StageItem from './StageItem';
import connect from './connect';
import { PIPELINE_DETAILS_FIXED_STAGES } from '../../../../utils/constants';
import { DragDropContext } from 'react-beautiful-dnd';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&::-webkit-scrollbar': {
      width: 7,
      height: 15,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 16px rgba(0, 0, 0, 0.3)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey',
      outline: `1px solid grey`,
    },
    minHeight: 300,
  },
}));

function Stages({ stages, updateStagesOrder, onDropPursuit, updatePursuitsOrder }) {
  const { columns, onDropCard, onMoveCard } = useDragAndDropState({
    initialItems: stages,
    allowExternalChanges: true,
    onUpdateColumns: React.useCallback((columns) => {
      updateStagesOrder({ ids: columns.map((x) => x.id) });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  });
  const classes = useStyles();

  return (
    <Paper square bgColor="pipelineDark" component={LimitHeight} scrollable forwardHeight className={classes.root}>
      <SpacingContainer className="forwardHeight" px={1} py={1}>
        <DragDropContext
          onDragEnd={(...args) => {
            onDropPursuit(...args);
            updatePursuitsOrder();
          }}
        >
          <Grid container wrap="nowrap" className="forwardHeight">
            {PIPELINE_DETAILS_FIXED_STAGES.map((type) => {
              const item = columns?.find((x) => x.type === type);
              if (!item) return null;
              if (type === 'triage') return <StageItem key={item.id} index={0} item={item} />;
              return null;
            })}

            {columns?.map((item, index) =>
              PIPELINE_DETAILS_FIXED_STAGES.includes(item.type) ? null : (
                <StageItem key={item.id} index={index} item={item} onMoveCard={onMoveCard} onDropCard={onDropCard} />
              )
            )}

            {/* {PIPELINE_DETAILS_FIXED_STAGES.map((type) => {
              const item = columns?.find((x) => x.type === type);
              if (!item) return null;
              if (type !== 'triage') return <StageItem key={item.id} index={0} item={item} />;
              return null;
            })} */}
          </Grid>
        </DragDropContext>
      </SpacingContainer>
    </Paper>
  );
}

export default connect(Stages);
