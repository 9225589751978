import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconNsnOpportunities = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="m15.22 1 .7.71v2.82l1.41-1.41 3.54 3.54a3 3 0 0 1 0 4.24l-2.12-2.12-1.41 1.41-1.06-1.05-5.15 5.15v2.12l-5.3 5.3a.996.996 0 0 1-1.41 0L2.3 19.59a.996.996 0 0 1 0-1.41l5.3-5.3h2.12l.85-.85L6.44 7.9H5.03L2 4.87l2.83-2.83 3.02 3.02v1.42l4.13 4.13 2.89-2.89-1.06-1.06 1.41-1.41h-2.83l-.71-.71L15.22 1zm1.15 11.87 5.3 5.3c.39.39.39 1.03 0 1.41l-2.12 2.12a.996.996 0 0 1-1.41 0l-5.3-5.3v-.99l2.54-2.54h.99zm-1.06 1.77-.71.71 4.24 4.24.71-.71-4.24-4.24zm-6.66-.01-4.24 4.24.71.71 4.24-4.24-.71-.71z" />
    </SvgIcon>
  );
};

IconNsnOpportunities.propTypes = simpleIconPropsTypes;
