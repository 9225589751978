import * as Yup from 'yup';
import makeValidationSchema from '../../../../../../../hooks/makeValidationSchema';

export const initialValues = {
  responseBy: null,
  title: '',
  description: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    responseBy: Yup.date().typeError(trans('required-field')).required(trans('required-field')),
    title: Yup.string().required(trans('required-field')),
    description: Yup.string().required(trans('required-field')),
  })
);
