import React from 'react';
import { SvgIcon } from '../../../../common/SvgIcon';

function PhoneIcon({ width = 48, height = 24 }) {
  return (
    <SvgIcon width={width} height={height}>
      <svg width="48" height="24" viewBox="0 0 48 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29 12C31.7614 12 34 9.76142 34 7C34 4.23858 31.7614 2 29 2C26.2386 2 24 4.23858 24 7C24 7.79984 24.1878 8.55582 24.5217 9.22624C24.6105 9.4044 24.64 9.60803 24.5886 9.80031L24.2908 10.9133C24.1615 11.3965 24.6035 11.8385 25.0867 11.7092L26.1997 11.4114C26.392 11.36 26.5956 11.3895 26.7738 11.4783C27.4442 11.8122 28.2002 12 29 12Z"
          stroke="#17B471"
          stroke-width="1.5"
        />
        <path
          d="M20.0376 7.31617L20.6866 8.4791C21.2723 9.52858 21.0372 10.9053 20.1147 11.8278C20.1147 11.8278 20.1147 11.8278 20.1147 11.8278C20.1146 11.8279 18.9959 12.9468 21.0245 14.9755C23.0525 17.0035 24.1714 15.8861 24.1722 15.8853C24.1722 15.8853 24.1722 15.8853 24.1722 15.8853C25.0947 14.9628 26.4714 14.7277 27.5209 15.3134L28.6838 15.9624C30.2686 16.8468 30.4557 19.0692 29.0628 20.4622C28.2258 21.2992 27.2004 21.9505 26.0669 21.9934C24.1588 22.0658 20.9183 21.5829 17.6677 18.3323C14.4171 15.0817 13.9342 11.8412 14.0066 9.93309C14.0495 8.7996 14.7008 7.77423 15.5378 6.93723C16.9308 5.54428 19.1532 5.73144 20.0376 7.31617Z"
          stroke="#17B471"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default PhoneIcon;
