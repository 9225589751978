import React from 'react';
import Typography from '../../Typography';
import { useTrans } from '../../../../services/i18n';
import { Button } from '../../Buttons';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { ROUTE_PLANS_PRIVATE } from '../../../../constants';

function Footer({ onViewClick }) {
  const { trans } = useTrans();
  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    history.push(ROUTE_PLANS_PRIVATE.path);
    onViewClick();
  };

  return (
    <Box className={classes.footer}>
      <Typography variant="p">{trans('check-out-plans-to-learn-more')}</Typography>
      <Button variant="contained" color="primary" onClick={onClick}>
        {trans('view-subscription-plans')}
      </Button>
    </Box>
  );
}

export default Footer;
