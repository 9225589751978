import { List, ListItem, ListItemText } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '../../../components/common/Typography';
import styles from './styles.module.css';
import { Divider } from '../../../components/common/Divider';

export default function SuccessMfa({ type }) {
  return (
    <List>
      <ListItem disableGutters>
        <CheckCircleOutlineIcon style={{ color: '#17B471' }} size="lg" />
        <ListItemText className={styles.marginLeft}>
          <Typography variant="h6" component="p" weight="600" color="label">
            Your two-factor authentication has been successfully validated.
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider light spacing={2} />
      <ListItem disableGutters>
        <ListItemText>
          <Typography gutter="bottom2" variant="h6" component="p" weight="600" color="label">
            Information to consider
          </Typography>
          <div className={styles.marginLeft}>
            {type === 'SMS' ? (
              <>
                <Typography variant="h6" component="p" weight="600" color="label">
                  ° Each time you log in you will have to enter the verification code sent to your phone.
                </Typography>
                <Typography variant="h6" component="p" weight="600" color="label">
                  ° Your phone number has been registered in our database, you can edit it from{' '}
                  <b>Personal information</b>
                </Typography>
              </>
            ) : (
              <Typography variant="h6" component="p" weight="600" color="label">
                ° Each time you log in you will have to enter the six digit code displayed in the application.
              </Typography>
            )}
          </div>
        </ListItemText>
      </ListItem>
    </List>
  );
}
