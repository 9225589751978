import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../../../components/common/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    fontSize: '0.9rem', // Change this to your desired font size
  },
  formControl: {
    width: '100%',
  },
  container: {
    backgroundColor: '#F1F3F9', // Gray background
    padding: '6px 10px 6px 10px', // Padding around the RadioGroup
  },
});

export default function RadioFormHelp({ options, onChange, name = 'radio', value, disabled, error, touched }) {
  const { trans } = useTrans();
  const classes = useStyles();

  return (
    <FormControl component="fieldset" disabled={disabled} className={classes.formControl}>
      <div className={classes.container} style={{ border: error && '1px solid #f32445' }}>
        <RadioGroup row name={name} value={value} onChange={!disabled ? onChange : undefined}>
          {options.map((option, ind) => (
            <FormControlLabel
              key={ind}
              value={option.value}
              control={<Radio color="secondary" id={`radio-${name}-${option.value}`} />}
              label={trans(option.name)}
              classes={{ label: classes.label }} // Apply custom styles here
            />
          ))}
        </RadioGroup>
      </div>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </FormControl>
  );
}
