import React from 'react';
import { useTrans } from '../../../services/i18n';
import { Button } from '../../../components/common/Buttons';
import { useAlertContext } from '../../../contexts/AlertContext';
import connect from '../connect';
import { ROUTE_CHECKOUT, ROUTE_PLANS_PRIVATE } from '../../../constants';

const UpdatePlanButton = (props) => {
  const { subscription } = props;
  const { trans } = useTrans();
  //const { successAlert, errorAlert } = useAlertContext();

  /*
  const handleCreateSubscription = async () => {
    if (!defaultPaymentMethod) {
      errorAlert(trans('no-payment-method-added'));
      return;
    }
    const { payload } = await createSubscription({
      priceId: price.id,
      paymentMethodId: defaultPaymentMethod,
    });
    if (payload.success) {
      setCreateSubscription(payload.subscription);
      successAlert(payload.message);
    } else {
      errorAlert(payload.message);
    }
  };*/

  return (
    <>
      {(!subscription || (subscription && subscription.status === 'canceled')) && (
        <Button fullWidth variant="contained" color="primary" size="large" to={ROUTE_PLANS_PRIVATE.path}>
          {trans('get-premium')}
        </Button>
      )}
    </>
  );
};

export default connect(UpdatePlanButton);
