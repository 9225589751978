import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { API } from '../../../constants';
//import { successAlert } from '../../../contexts/AlertContext';
import { application } from '../../../services/application';
import { getFilterParams } from '../../../utils/filterUtils';
import { getObjectHashCode } from '../../../utils/helpers';
import { onRejectAlert } from '../sharedCases';

export function generateSearchActions({ scope, apiMethod, getStore }) {
  if (!(scope && apiMethod && typeof getStore === 'function')) return {};

  return {
    loadSearch: createAction(`${scope}/loadSearch`),

    saveSearch: createAsyncThunk(`${scope}/saveSearch`, (data, { getState }) => {
      const { title, notification, notificationFrequency, notificationNumber } = data;
      const { count, filters, order } = getStore(getState());
      const { text, fields: storeFields } = filters;
      const fields = getFilterParams(storeFields);
      const search = { filters: { text, fields }, order };

      return apiMethod.SAVE_SEARCH
        ? application.call(apiMethod.SAVE_SEARCH, {
            title,
            notification,
            notificationFrequency,
            notificationNumber,
            search,
            scope,
            count,
          })
        : new Error('no api method');
    }),

    modifySearch: createAsyncThunk(`${scope}/modifySearch`, (data, { getState }) => {
      const { savedSearchId } = data;

      const { count, filters, order } = getStore(getState());
      const { text, fields: storeFields } = filters;
      const fields = getFilterParams(storeFields);
      const search = { filters: { text, fields }, order };

      return apiMethod.MODIFY_SEARCH
        ? application.call(apiMethod.MODIFY_SEARCH, {
            id: savedSearchId,
            search,
            scope,
            count,
          })
        : new Error('no api method');
    }),
    createSharedSearch: createAsyncThunk(`${scope}/shareSearch`, (_, { getState }) => {
      const { filters, order, sharedSearch } = getStore(getState());
      const search = { filters, order };

      if (sharedSearch) {
        const searchHash = getObjectHashCode(search);
        if (searchHash && sharedSearch.hash && Number(searchHash) === Number(sharedSearch.hash)) {
          return sharedSearch;
        }
      }

      return application.call(apiMethod.SHARE_SEARCH, { search, scope });
    }),

    getAndLoadSearchById: createAsyncThunk(
      `${scope}/getAndLoadSearchById`,
      async ({ id, type, customFilter } /*, { getState }*/) => {
        //const { searchId } = getStore(getState());

        // Do not load search if already loaded
        //if (searchId === id) return null;
        const result = await application.call(
          type === 'saved'
            ? API.SAVED_SEARCH.FIND_ONE
            : type === 'shared'
            ? API.SHARED_SEARCH.FIND_ONE
            : API.SUGGESTED_SEARCHES.FIND_ONE,
          { id, scope }
        );

        return { result, customFilter };
      }
    ),
  };
}

export function extendBuilderWithSearchActions(builder, actions) {
  const { saveSearch, createSharedSearch, loadSearch, getAndLoadSearchById } = actions;

  const setSearchParams = (state, search) => {
    const { filters, order, searchId } = search;
    const { text, fields } = filters || state.filters;

    Object.assign(state, {
      searchId,
      filters: { text: text || '', fields: fields || {} },
      order: order || state.order,
    });
  };

  if (loadSearch) {
    builder.addCase(loadSearch, (state, action) => {
      setSearchParams(state, action.payload);
    });
  }

  if (getAndLoadSearchById) {
    builder.addCase(getAndLoadSearchById.fulfilled, (state, action) => {
      const { result, customFilter } = action.payload;

      if (!(result && result.search)) return;

      let search = result.search;
      if (customFilter) {
        const decodified = decodeURIComponent(customFilter);
        const obj = JSON.parse(decodified);
        search = { ...search, filters: { ...search.filters, fields: { ...search.filters.fields, ...obj } } };
      }
      setSearchParams(state, Object.assign({ searchId: result.id }, search));
    });
  }

  if (saveSearch) {
    builder
      //.addCase(saveSearch.fulfilled, () => successAlert('Success'))
      .addCase(saveSearch.rejected, onRejectAlert);
  }

  if (createSharedSearch) {
    builder.addCase(createSharedSearch.fulfilled, (state, action) =>
      Object.assign(state, { sharedSearch: action.payload })
    );
  }
}

export function generateSearchCallbacks(dispatch, actions) {
  const { createSharedSearch, loadSearch, saveSearch, modifySearch, fetchList } = actions || {};
  if (!(createSharedSearch && fetchList && dispatch)) return {};

  return {
    onLoadSearch: async (params) => {
      dispatch(loadSearch(params));
      await dispatch(fetchList());
    },
    onCreateShareSearch: (params) => dispatch(createSharedSearch(params)),
    onSaveSearch: (params) => dispatch(saveSearch(params)),
    onModifySearch: (params) => dispatch(modifySearch(params)),

    onInit: generateOnInitCallbackWithSearches(dispatch, actions),
  };
}

export function generateOnInitCallbackWithSearches(dispatch, actions, extendCallback) {
  const { setParams, fetchList, getAndLoadSearchById, setFilters } = actions;

  return (data) => {
    batch(async () => {
      const { savedSearchId, sharedSearchId, suggestedSearchId, text, customFilter, ...params } = data || {};
      if (savedSearchId || sharedSearchId || suggestedSearchId) {
        await dispatch(
          getAndLoadSearchById({
            id: savedSearchId ? savedSearchId : sharedSearchId ? sharedSearchId : suggestedSearchId,
            type: savedSearchId ? 'saved' : sharedSearchId ? 'shared' : 'suggested',
            customFilter: customFilter,
          })
        );
      }

      if (typeof extendCallback === 'function') {
        await Promise.resolve(extendCallback());
      }

      if (text) dispatch(setFilters({ text }));
      if (params) dispatch(setParams(params));
      await dispatch(fetchList());
    });
  };
}
