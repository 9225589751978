import { createAsyncThunk, createAction, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_RELEASES } from '../../constants';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common';
import { extendListBuilder, generateListActions } from '../helpers/listActionsHelpers';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
  currentRelease: null,
};

//Actions
const listActions = generateListActions({
  scope: SCOPE_RELEASES,
  apiMethod: {
    GET_LIST: API.RELEASES.GET_RELEASES,
  },
  getStore: (store) => store.releases,
});

const changeCurrentRelease = createAction('releases/changeCurrentRelease');
const onChangeCurrentRelease = (state, action) => {
  return {
    ...state,
    currentRelease: action.payload,
  };
};

export const actions = {
  ...listActions,
  changeCurrentRelease,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, actions).addCase(changeCurrentRelease, onChangeCurrentRelease);
});
