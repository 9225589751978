import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useState } from 'react';

import { Button } from '../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../components/common/Dialogs';
import { useTrans } from '../../../../../services/i18n';
import Grid from '@material-ui/core/Grid';
import styles from './styles.module.css';
import Typography from '../../../../../components/common/Typography';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import FormBillingInformation from '../FormBillingInfo';

const elementInputStyle = {
  width: '100%',
  backgroundColor: '#f1f3f9',
  color: '#000000',
  fontSize: '16px',
  '::placeholder': {
    color: '#888fa9',
    fontSize: '14px',
  },
};
const optionsElementsStripe = {
  fonts: [
    {
      src: 'https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap',
      family: 'Lexend',
    },
  ],
};
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Wrapper = (props) => (
  <Elements stripe={stripePromise} options={optionsElementsStripe}>
    <FormCard {...props} />
  </Elements>
);

const FormCard = (props) => {
  const { open, onClose, addCard } = props;

  const stripe = useStripe();
  const elements = useElements();
  const { trans } = useTrans();
  const { errorAlert } = useAlertContext();
  const [openFormBilling, onOpenFormBilling, onCloseFormBilling] = useBooleanHandlers();
  const [isSend, setIsSend] = useState(false);
  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const handleSubmit = async (billingDetails) => {
    if (!stripe || !elements) return;

    const cardNumberElement = elements?.getElement(CardNumberElement);

    if (cardNumberElement) {
      setIsSend(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          address: billingDetails,
        },
        card: cardNumberElement,
      });
      if (error) {
        setIsSend(false);
        errorAlert(error.message);
        return false;
      }
      const { id } = paymentMethod;
      if (id) {
        try {
          const { payload } = await addCard({ paymentMethodId: id, notify: true });
          onClose();
          onCloseFormBilling();
          return payload;
        } catch (error) {
          errorAlert(error.message);
        }
        setIsSend(false);
      }
    }
  };

  const handleFormBilling = () => {
    if (cardComplete.cardNumber && cardComplete.cardExpiry && cardComplete.cardCvc) {
      onOpenFormBilling();
    }
  };

  const handleCardElementOnChange = (e) => setCardComplete({ ...cardComplete, [e.elementType]: e.complete });

  const cancel = () => {
    onClose();
    setIsSend(false);
  };

  return (
    <>
      <ModalDialog
        open={open}
        onClose={cancel}
        title={trans('add-card')}
        actions={
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" color="primary" pending={isSend} onClick={handleFormBilling}>
                {trans('continue')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
              {trans('card-details')}
            </Typography>
            <div className={styles.cardInputWrapper}>
              <CardNumberElement
                onChange={handleCardElementOnChange}
                options={{
                  style: {
                    base: elementInputStyle,
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item lg={3}>
            <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
              {trans('mm-yy')}
            </Typography>
            <div className={styles.cardInputWrapper}>
              <CardExpiryElement
                onChange={handleCardElementOnChange}
                options={{
                  style: {
                    base: elementInputStyle,
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item lg={3}>
            <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
              {trans('cvc')}
            </Typography>
            <div className={styles.cardInputWrapper}>
              <CardCvcElement
                onChange={handleCardElementOnChange}
                options={{
                  style: {
                    base: elementInputStyle,
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </ModalDialog>
      <FormBillingInformation
        hideBackdrop={true}
        open={openFormBilling}
        onClose={onCloseFormBilling}
        savePaymentMethod={handleSubmit}
        item={null}
      />
    </>
  );
};

export default Wrapper;
