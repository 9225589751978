import { useState } from 'react';
//import IconButton from '@material-ui/core/IconButton';
//import { IconRemove } from '../../../../../components/svg/Icons/IconRemove';
import { Button } from '../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../components/common/Dialogs';
import { useTrans } from '../../../../../services/i18n';
import Grid from '@material-ui/core/Grid';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import { TextField } from '../../../../../components/common/Inputs';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import ConfirmDialog from '../../../../../components/generics/Confirm/Dialog';
import Typography from '../../../../../components/common/Typography';

const RemoveCard = (props) => {
  const { paymentMethod, removePaymentMethod } = props;
  const { trans } = useTrans();
  const [isSend, setIsSend] = useState(false);
  const { successAlert, errorAlert } = useAlertContext();
  const [open, onOpen, onClose] = useBooleanHandlers();
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useBooleanHandlers();

  const handleSubmit = async () => {
    try {
      setIsSend(true);
      await removePaymentMethod({ id: paymentMethod?.id });
      successAlert('Card removed');
      onClose();
    } catch (error) {
      errorAlert(error.message);
    }
    setIsSend(false);
  };

  return (
    <>
      <Button onClick={onOpen} variant="outlined" color="primary">
        {trans('edit')}
      </Button>

      <ModalDialog
        open={open}
        onClose={onClose}
        title={trans('remove-card')}
        actions={
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" color="primary" onClick={onOpenConfirm}>
                {trans('remove-card')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        {paymentMethod && (
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} xs={12}>
              <TextField
                label={trans('card-details')}
                value={`**** **** **** ${paymentMethod.last4}`}
                disabled
                readOnly
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <TextField label={trans('mm-yy')} value={paymentMethod.expire} disabled readOnly />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <TextField label={trans('cvc')} value="***" disabled readOnly />
            </Grid>
          </Grid>
        )}
      </ModalDialog>

      <ConfirmDialog
        open={openConfirm}
        onClose={onCloseConfirm}
        onConfirm={handleSubmit}
        pending={isSend}
        title={trans('confirm-delete-card')}
        text={trans('confirm-delete-card')}
      >
        <Typography color="hint">{`**** **** **** ${paymentMethod.last4}`}</Typography>
      </ConfirmDialog>
    </>
  );
};

export default RemoveCard;
