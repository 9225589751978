import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconLock = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M12 3c2.683 0 4.882 2.07 4.995 4.676L17 7.89V10h1c1.598 0 2.904 1.249 2.995 2.824L21 13v5c0 1.657-1.343 3-3 3H6c-1.657 0-3-1.343-3-3v-5c0-1.657 1.343-3 3-3h1V7.889C7 5.182 9.245 3 12 3zm6 9H6c-.552 0-1 .448-1 1v5c0 .552.448 1 1 1h12c.552 0 1-.448 1-1v-5c0-.552-.448-1-1-1zm-6-7c-1.604 0-2.904 1.209-2.995 2.72L9 7.889V10h6V7.889C15 6.299 13.663 5 12 5z"
        transform="translate(-890 -378) translate(720) translate(150 194) translate(0 164) translate(20 20)"
      />
    </SvgIcon>
  );
};

IconLock.propTypes = simpleIconPropsTypes;
