import React, { useEffect, useState } from 'react';
import { Button, RouterLink } from '../../../common/Buttons';
import { InlineContainer, SpacingContainer } from '../../../common/Containers/Base';
import { ModalDialog } from '../../../common/Dialogs';
import Typography from '../../../common/Typography';
import { useTrans } from '../../../../services/i18n';
import MergeOverwriteOportunityItem from '../MergeOverwriteOportunityItem';
import styles from './styles.module.css';
import connect from './connect';
import { useAlertContext } from '../../../../contexts/AlertContext';

const LinkPursuitModal = ({ opportunity, type, pursuit, pending, open, onCloseDialog, storeLinkPursuit }) => {
  const { trans } = useTrans();
  const { errorAlert } = useAlertContext();

  const structContract = [
    {
      name: 'Name',
      opportunityName: 'title',
      pursuitName: 'pursuitName',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
    {
      name: 'Federal Agency',
      opportunityName: 'agencyName',
      pursuitName: 'agencyName',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      requiredId: 'agencyId',
      overwrite: false,
      fieldType: 'string',
    },
    {
      name: 'Due Date',
      opportunityName: 'responseDateActual',
      pursuitName: 'dueDate',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'date',
    },
    {
      name: 'Set Aside',
      opportunityName: 'setAside',
      pursuitName: 'setAside',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
    {
      name: 'NAICS Category',
      opportunityName: 'naics',
      pursuitName: 'naics',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
    {
      name: 'Federal People',
      opportunityName: 'pointOfContacts',
      pursuitName: 'pointOfContacts',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
  ];

  const structAward = [
    {
      name: 'Name',
      opportunityName: 'title',
      pursuitName: 'pursuitName',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
    {
      name: 'Set Aside',
      opportunityName: 'setAside',
      pursuitName: 'setAside',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
    {
      name: 'Naics Category',
      opportunityName: 'naics',
      pursuitName: 'naics',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
    {
      name: 'PSC',
      opportunityName: 'psc',
      pursuitName: 'psc',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
  ];

  const structGrant = [
    {
      name: 'Name',
      opportunityName: 'title',
      pursuitName: 'pursuitName',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
    {
      name: 'Federal Agency',
      opportunityName: 'agencyName',
      pursuitName: 'agencyName',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      requiredId: 'agencyId',
      overwrite: false,
      fieldType: 'string',
    },
  ];

  const structNsn = [
    {
      name: 'Name',
      opportunityName: 'title',
      pursuitName: 'pursuitName',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
  ];

  const structStateLocal = [
    {
      name: 'Name',
      opportunityName: 'title',
      pursuitName: 'pursuitName',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'string',
    },
    {
      name: 'Due Date',
      opportunityName: 'proposalDeadline',
      pursuitName: 'dueDate',
      newValue: {
        id: null,
        text: '',
      },
      incomingValue: {
        id: null,
        text: '',
      },
      overwrite: false,
      fieldType: 'date',
    },
  ];

  const [success, setSuccess] = useState(false);
  const [fields, setFields] = useState([]);
  const [pendingButton, setPendingButton] = useState(false);

  const showRow = (iv) => {
    if ((iv.text && !Array.isArray(iv.text)) || (Array.isArray(iv.text) && iv.text.length)) return true;
    return false;
  };

  const updateDataPursuit = (index, value, overwrite) => {
    fields[index].overwrite = overwrite;
    setFields(JSON.parse(JSON.stringify(fields)));
  };

  const closeModal = () => {
    setSuccess(false);
    onCloseDialog();
  };

  const linkPursuit = () => {
    setPendingButton(true);
    storeLinkPursuit({
      opportunityType: type,
      pursuit: pursuit,
      opportunity: opportunity,
      fields: fields,
    })
      .then((res) => {
        if (res.payload.success) setSuccess(true);
      })
      .catch(errorAlert)
      .finally(() => setPendingButton(false));
  };

  const getSectionName = () => {
    if (type === 'contract') return 'Contract Opportunity';
    if (type === 'award') return 'Contract Award';
    if (type === 'grant') return 'Grant Opportunity';
    if (type === 'nsn') return 'Nsn Opportunity';
    if (type === 'stateLocal') return 'State Local Opportunity';
  };

  useEffect(() => {
    if (type === 'contract') setFields(structContract);
    if (type === 'award') setFields(structAward);
    if (type === 'grant') setFields(structGrant);
    if (type === 'nsn') setFields(structNsn);
    if (type === 'stateLocal') setFields(structStateLocal);
  }, [type]);

  useEffect(() => {
    if (opportunity && pursuit) {
      for (const [i, field] of fields.entries()) {
        fields[i].newValue.text = pursuit[field.pursuitName];
        fields[i].incomingValue.text = opportunity[field.opportunityName];
        if (fields[i].requiredId) {
          fields[i].newValue['id'] = pursuit[fields[i].requiredId];
          fields[i].incomingValue['id'] = opportunity[fields[i].requiredId];
        }
        if (fields[i].requiredArrayIds) {
          fields[i].newValue['id'] = pursuit[fields[i].requiredArrayIds]?.map((i) => i.id);
          fields[i].incomingValue['id'] = opportunity[fields[i].requiredArrayIds]?.map((i) => i.id);
        }
        setFields(JSON.parse(JSON.stringify(fields)));
      }
    }
  }, [pursuit]);
  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      disableContentSpacing
      title={success ? `${trans('Success')}!` : trans('link-to-pursuit')}
      actions={
        success && pursuit ? (
          <InlineContainer>
            <RouterLink to={'/pipelines?pipeline=' + pursuit.pipelineId}>
              <Button variant="outlined" color="primary">
                {trans('ViewPipeline')}
              </Button>
            </RouterLink>
            <RouterLink to={'/pursuits/' + pursuit.id}>
              <Button variant="contained" color="primary">
                {trans('ViewPursuit')}
              </Button>
            </RouterLink>
          </InlineContainer>
        ) : (
          <InlineContainer>
            <Button variant="outlined" color="primary" onClick={onCloseDialog}>
              {trans('cancel')}
            </Button>
            <Button pending={pendingButton} onClick={linkPursuit} variant="contained" color="primary">
              Link
            </Button>
          </InlineContainer>
        )
      }
      pending={pending}
    >
      {success ? (
        <SpacingContainer px={4} py={4}>
          <Typography variant="h5" weight={500} fontFamily="lexend">
            The Pursuit was linked to the {getSectionName()}
          </Typography>
          <Typography variant="h6" component="p" weight={500} color="hint">
            {`${trans('Pipeline')} `}
            <RouterLink to="#">«{pursuit ? pursuit.pipelineName : ''}»</RouterLink>
          </Typography>
        </SpacingContainer>
      ) : (
        <div className={styles.mergeOverwriteContainer}>
          <p>
            You are about to link the Pursuit <b>{pursuit ? pursuit.pursuitName : ''}</b> to the current opportunity{' '}
            <b>{opportunity ? opportunity.title : ''}</b>
          </p>
          <p>
            Choose the values to use and how they should be applied. For multiple value fields, merge the new values.
            For single value fields, overwrite the existing value.
          </p>
          {fields.map(
            (f, index) =>
              showRow(f.incomingValue) && (
                <MergeOverwriteOportunityItem
                  key={index}
                  updateDataPursuit={updateDataPursuit}
                  name={f.name}
                  opportunityName={f.opportunityName}
                  incomingValue={f.incomingValue}
                  newValue={f.newValue}
                  index={index}
                  overwrite={f.overwrite}
                  fieldType={f.fieldType}
                />
              )
          )}
        </div>
      )}
    </ModalDialog>
  );
};

export default connect(LinkPursuitModal);
