import { connect } from 'react-redux';
import { actions } from '../../../../store/vendorsManagementDetails';
import { generateListCallbacks } from '../../../../store/helpers/listActionsHelpers';

function mapStateToProps(state) {
  return {
    vendorInfo: state.vendorsManagementDetails.item,
    pending: state.vendorsManagementDetails.pending,
    subscription: state.subscription,
    asideList: state.vendorsManagementDetails.asideList,
    orgAvatarUrl: state.vendorsManagementDetails.orgAvatarUrl,
    members: state.vendorsDetails.members,
    notes: state.vendorsDetails.notes,
    naics: state.vendorsDetails.naics,
    currentUserId: state.auth?.userName,
    currentUserName: state.auth?.name + ' ' + state.auth?.familyName,
  };
}

function mapDispatchToProps(dispatch) {
  const onInit = async (params) => {
    const { payload } = await dispatch(actions.fetchVendor(params));
    await dispatch(actions.fetchAsideList());
  };

  return {
    onInit,
    onDestroy: () => dispatch(actions.cleanStore()),
    vendorNoteMemberListActions: generateListCallbacks(dispatch, actions.vendorNoteMemberListActions),
    vendorNoteNotesListActions: generateListCallbacks(dispatch, actions.vendorNoteNotesListActions),
    vendorNoteNaicsListActions: generateListCallbacks(dispatch, actions.vendorNoteNaicsListActions),
    addVendorMember: async (values) => {
      return dispatch(actions.addVendorMember({ ...values }));
    },
    removeVendorMember: async (values) => {
      return dispatch(actions.removeVendorMember({ ...values }));
    },
    updateVendorMember: async (values) => {
      return dispatch(actions.updateVendorMember({ ...values }));
    },
    addVendorNote: async (values) => {
      return dispatch(actions.addVendorNote({ ...values }));
    },
    removeVendorNote: async (values) => {
      return dispatch(actions.removeVendorNote({ ...values }));
    },
    updateVendorNote: async (values) => {
      return dispatch(actions.updateVendorNote({ ...values }));
    },
    addVendorNaics: async (values) => {
      return dispatch(actions.addVendorNaics({ ...values }));
    },
    removeVendorNaics: async (values) => {
      return dispatch(actions.removeVendorNaics({ ...values }));
    },
    updateVendorNaics: async (values) => {
      return dispatch(actions.updateVendorNaics({ ...values }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
