import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { Button } from '../../../common/Buttons';
import { ModalDialog } from '../../../common/Dialogs';
import { useTrans } from '../../../../services/i18n';
import { useAlertContext } from '../../../../contexts/AlertContext';
import { SpacingContainer } from '../../../common/Containers';
import { Autocomplete } from '../../../common/Inputs';
import connect from './connect';
import Typography from '../../../common/Typography';
import { APP_MENU } from '../../../../constants';
import * as MENU_ICONS from '../../../svg/Icons/Menu';
import { IconDashboard } from '../../../svg/Icons/Menu';
import { Divider } from '../../../common/Divider';
import { ReactComponent as InfoIcon } from './icons/infoIcon.svg';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import styles from './style.module.css';
import { useApplication } from '../../../../services/application';
import { useLocation } from 'react-router-dom';
import { emptyArray } from '../../../../utils/arrayUtils';
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';

const UserHelpModal = ({ updateOnViewTour, changeSkipState, onboardingViews }) => {
  const isExtraSmallScreen = useMediaQueryDown('xs');
  const { trans } = useTrans();
  const application = useApplication();
  const [open, setOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const location = useLocation();
  const [itemOnboarding, setItemOnboarding] = useState(null);

  const { errorAlert, successAlert } = useAlertContext();

  // const closeOnboardingModal = () => {
  //   updateOnViewTour({ onViewTour: 1 });
  // };

  const showOnboardingButton = () => {
    let listItems = [];
    APP_MENU.forEach((item) => {
      item.items.forEach((subItem) => {
        listItems.push({
          view: subItem.key,
          section: item.key,
          path: subItem.path,
        });
      });
    });

    const item = listItems.find((i) => i.path === location.pathname);
    if (!emptyArray(onboardingViews) && item) {
      const hasHelps = onboardingViews.find((i) => i.view === item.view && i.section === item.section);
      return hasHelps;
    }

    return false;
  };

  const openOnboardingModal = () => {
    closeModal();
    updateOnViewTour({ onViewTour: 0 });
  };

  const openOnboardingPageAgain = () => {
    let listItems = [];
    APP_MENU.forEach((item) => {
      item.items.forEach((subItem) => {
        listItems.push({
          view: subItem.key,
          section: item.key,
          path: subItem.path,
        });
      });
    });

    const item = listItems.find((i) => i.path === location.pathname);

    if (item) {
      setItemOnboarding(item);
      changeSkipState({ view: item.view, section: item.section, skip: false });
    }
    setOpen(false);
  };

  const closeModal = async () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const gotoLearnWorlds = async () => {
    if (pending) return;

    try {
      setPending(true);
      const res = await application.call('learnWorlds.singleSignOn', {});
      setPending(false);
      closeModal();
      if (res.lw) {
        window.open(res.lw.url, '_blank').focus();
        successAlert('You have been redirected to tutorials');
      } else {
        errorAlert('There was a problem try again later');
      }
    } catch (e) {
      setPending(false);
      closeModal();
      errorAlert('There was a problem try again later');
    }
  };

  return (
    <>
      {!isExtraSmallScreen && (
        <>
          <SpacingContainer px={0} py={0}>
            <IconButton
              style={{
                marginRight: 0,
                padding: isExtraSmallScreen ? 6 : 12,
              }}
              onClick={() => openModal()}
            >
              <InfoIcon />
            </IconButton>
          </SpacingContainer>
          <ModalDialog open={open} onClose={closeModal} title={trans('help')} actions={''}>
            <SpacingContainer px={2} py={0}>
              <div>
                <Typography style={{ color: '#888FA9' }} variant="body1">
                  {trans('if-you-need-help')}
                </Typography>
              </div>
              <br />
              <Divider light />
              <Grid
                onClick={() => openOnboardingModal()}
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                className={styles.hover}
              >
                <Grid>
                  <Typography variant="body2" color="primary" weight="600">
                    {trans('see-tour-again')}
                  </Typography>
                </Grid>

                <Grid>
                  <IconButton color="primary">
                    <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
                  </IconButton>
                </Grid>
              </Grid>

              {showOnboardingButton() && (
                <>
                  <Divider light />
                  <Grid
                    onClick={() => openOnboardingPageAgain()}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    className={styles.hover}
                  >
                    <Grid>
                      <Typography variant="body2" color="primary" weight="600">
                        {trans('see-onboarding-page-again')}
                      </Typography>
                    </Grid>

                    <Grid>
                      <IconButton color="primary">
                        <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              )}

              <Divider light />
              <Grid
                onClick={() => gotoLearnWorlds()}
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                className={styles.hover}
              >
                <Grid>
                  <Typography variant="body2" color="primary" weight="600">
                    {pending ? 'Redirecting to tutorials ...' : trans('Tutorials')}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton color="primary">
                    <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
                  </IconButton>
                </Grid>
              </Grid>
            </SpacingContainer>
          </ModalDialog>
        </>
      )}
    </>
  );
};

export default connect(UserHelpModal);
