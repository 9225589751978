import { cleanEmailExpiredAction, setEmailExpiredAction } from '../../store/globalSettings';
import useActions from './useActions';

const useGlobalSettings = () => {
  const [setEmailExpiredD, cleanEmailExpiredD] = useActions([setEmailExpiredAction, cleanEmailExpiredAction]);

  return {
    setEmailExpiredD,
    cleanEmailExpiredD,
  };
};

export default useGlobalSettings;
