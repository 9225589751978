import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTE_GRANT_OPPORTUNITIES,
  ROUTE_GRANT_OPPORTUNITIES_DETAILS,
  SCOPE_GRANT_OPPORTUNITIES,
} from '../../constants';
import { AddToPipelineConsumer, AddToPipelineProvider } from '../../contexts/AddToPipelineContext';
import Suspense from '../../services/lazyLoad/Suspense';

const GrantOpportunities = React.lazy(() => import('./GrantOpportunities'));
const GrantOpportunitiesDetails = React.lazy(() => import('./GrantOpportunityDetails'));

export default function GrantOpportunitiesWrapper() {
  return (
    <Suspense>
      <AddToPipelineProvider scope={SCOPE_GRANT_OPPORTUNITIES}>
        <Switch>
          <Route exact path={ROUTE_GRANT_OPPORTUNITIES_DETAILS.path}>
            <GrantOpportunitiesDetails />
          </Route>
          <Route exact path={ROUTE_GRANT_OPPORTUNITIES.path}>
            <GrantOpportunities />
          </Route>

          <Redirect exact path="/*" to={ROUTE_GRANT_OPPORTUNITIES.path} />
        </Switch>

        <AddToPipelineConsumer />
      </AddToPipelineProvider>
    </Suspense>
  );
}
