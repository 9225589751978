import { Grid, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import Avatar from '../../../components/common/Cards/Avatar';
import { SpacingContainer } from '../../../components/common/Containers/Base';
import SectionScrollable from '../../../components/common/Containers/SectionScrollable';
import { Divider } from '../../../components/common/Divider';
import PaginationSmall from '../../../components/common/Paginations/PaginationSmall';
import Typography from '../../../components/common/Typography';
import NothingFoundCard from '../../../components/generics/Cards/NothingFoundCard';
import SimpleSearchField from '../../../components/generics/SearchTopPanel/SearchField/SimpleSearchField';
import { IconVendors } from '../../../components/svg';
import { useTrans } from '../../../services/i18n';
import { emptyArray } from '../../../utils/arrayUtils';
import connect from './connect';
import { Button } from '../../../components/common/Buttons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  blackLabel: {
    color: theme.palette.text.primary,
  },
  buttonFont: {
    fontWeight: '400',
  },
  logoContainer: {
    background: '#f1f3f9',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    marginRight: '0.5rem',
  },
}));

function OrganizationsList({
  settings,
  listUserOrganizations,
  onInit,
  onChangePage,
  onChangeRowPerPage,
  onChangeSearchText,
  changeOrganization,
  pendingOrg,
}) {
  const trans = useTrans().trans;
  const styles = useStyles();
  const location = useLocation();
  const { items, filters, count, pending, pagination } = listUserOrganizations;

  const hasItems = !emptyArray(items);

  React.useEffect(() => {
    onInit();
  }, [onInit]);

  const changeActiveOrganization = (orgId) => {
    changeOrganization({ id: orgId }).then((res) => {
      const currentLocation = location.pathname;
      if (currentLocation.includes('/pipelines')) {
        window.location = '/pipelines';
      } else {
        window.location.reload();
      }
    });
  };
  return (
    <SectionScrollable
      pending={pending}
      TopFixed={
        (hasItems || filters.text) && (
          <SimpleSearchField
            value={filters.text}
            onValueChange={onChangeSearchText}
            size="medium"
            name="MOSearchText"
          />
        )
      }
      BottomFixed={
        <PaginationSmall
          {...pagination}
          count={count}
          onChangePage={onChangePage}
          onChangeRowPerPage={onChangeRowPerPage}
          pending={pending}
          divider="top"
          px={3}
          py={1}
        />
      }
    >
      {hasItems ? (
        <SpacingContainer py={2} component="ul">
          {items.map((item, index) => (
            <React.Fragment key={item.id}>
              {index !== 0 && <Divider light spacing={1} component="li" />}

              <ListItem disableGutters style={{ padding: 0 }}>
                <div className={styles.logoContainer}>
                  {item.profileImgStatus !== 'PENDING' && item.avatarId && item.avatarUrl ? (
                    <Avatar src={item.avatarUrl} alt="-" size={16} />
                  ) : (
                    <IconVendors width={16} height={16} />
                  )}
                </div>
                <ListItemText>
                  <Typography fontFamily="lexend" weight="400" variant="h6" color="primary">
                    {item.orgName}
                  </Typography>
                  <Grid container>
                    <Grid item>
                      <Typography color="hint" weight="600" variant="body2">
                        {trans('type')}{' '}
                        <Typography variant="inherit" className={styles.blackLabel}>
                          {item.parentOrganizationId ? trans('sub-organization') : trans('main-organization')}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
                {settings.id === item.id ? (
                  <Typography variant="inherit" className={styles.blackLabel}>
                    {trans('current-organization')}
                  </Typography>
                ) : (
                  <Button
                    onClick={() => changeActiveOrganization(item.id)}
                    variant="text"
                    color="primary"
                    className={styles.buttonFont}
                    pending={pendingOrg}
                  >
                    {trans('switch')}
                  </Button>
                )}
              </ListItem>
            </React.Fragment>
          ))}
        </SpacingContainer>
      ) : (
        <NothingFoundCard
          title={trans(!!filters.text ? 'NothingFound' : 'NothingHasBeenAddedYet')}
          description={trans(!!filters.text ? 'PleaseTrySomethingElse' : 'no-organizations-found')}
          size="small"
          py={10}
        />
      )}
    </SectionScrollable>
  );
}

export default connect(OrganizationsList);
