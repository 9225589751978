import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { useQuery } from '../../../../../hooks/query/useQuery';
import { useTrans } from '../../../../../services/i18n';
import { Paper, SpacingContainer } from '../../../../common/Containers';
import SectionScrollable from '../../../../common/Containers/SectionScrollable';
import { Divider } from '../../../../common/Divider';
import Loader from '../../../../common/Loader';
import QuickViewLayout from '../../../QuickViewLayout';
import connect from '../connect';
import ActionsPanel from './ActionsPanel';
import NotificationSmallCard from './Notification/NotificationSmallCard';
import QickViewCard from '../../../../generics/Cards/QickViewCard';
import { useNotifications } from '../hooks/useNotifications';
import { Button } from '../../../../common/Buttons';
import Typography from '../../../../common/Typography';
import NothingFound from './NothingFound';
import { useTheme } from '@material-ui/core/styles';
import { ROUTE_PROFILE } from '../../../../../constants';
import { IconSMSNotification } from '../../../../svg';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import EmailNotificationModal from '../../../../../containers/Profile/EmailNotificationSettingsProfile/components/EmailNotificationModal';
import { useIsMobile } from '../../../../../hooks/useIsMobile';

function Notifications(props) {
  const theme = useTheme();
  const isMobile = useIsMobile('sm');
  const {
    scope,
    notifications,
    onClose,
    onChangePage,
    onCheckItem,
    onMarkAsReadSingle,
    onRefreshList,
    isArchive,
    authUser,
    updateEmailNotificationSettings,
  } = props;
  const { query } = useQuery();
  const { trans } = useTrans();
  const {
    items,
    pending,
    checkedItems,
    hasItems,
    selectedIndex,
    openSelected,
    onLoadMore,
    notificationFilters,
    setNotificationFilters,
    resetNotificationFilters,
    disableLoadMore,
    nothingFoundTitle,
    nothingFoundSubtitle,
  } = useNotifications(notifications, onMarkAsReadSingle, onChangePage, onRefreshList);

  // Open and close the email notification modal
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers();

  const getTitle = () => {
    if (isMobile) {
      return (
        <>
          <Grid container sm={12} xs={12}>
            <Grid
              sm={5}
              xs={5}
              style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '0.5rem' }}
            >
              {trans('Notifications')}
            </Grid>
            <Grid sm={7} xs={7} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Box>
                <Button style={{ color: theme.palette.primary.main }} variant="link" onClick={onOpenModal}>
                  <Box display="flex" justifyContent="center" alignItems="center" gridGap={'.5rem'}>
                    <IconSMSNotification htmlColor={theme.palette.primary.main} />
                    <Typography weight="400" variant="body1">
                      {trans('settings')}
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      );
    } else {
      return <>{trans('Notifications')}</>;
    }
  };

  return (
    <QuickViewLayout
      title={getTitle()}
      onBackClick={onClose}
      actions={
        <ActionsPanel
          scope={scope}
          notificationFilters={notificationFilters}
          setNotificationFilters={setNotificationFilters}
        />
      }
      search={
        !isMobile && (
          <Box display="flex" justifyContent="end" alignItems="center" height="100%" padding="0 1rem">
            <Button style={{ color: theme.palette.primary.main }} variant="link" onClick={onOpenModal}>
              <Box display="flex" justifyContent="center" alignItems="center" gridGap={'.5rem'}>
                <IconSMSNotification htmlColor={theme.palette.primary.main} />
                <Typography weight="400" variant="body1">
                  {trans('email-notification-settings')}
                </Typography>
              </Box>
            </Button>
          </Box>
        )
      }
    >
      <Grid container className="forwardScroll" height="100%">
        <Grid item md={openSelected ? 5 : 12} sm={12} xs={12} className="forwardScroll" height="100%">
          <SectionScrollable
            bgColor="paperSecondary"
            dividedFixed
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
            scrollProps={{
              style: { display: 'flex', flexDirection: 'column', height: '100%' },
            }}
          >
            <Loader open={pending} />

            <QickViewCard chosen={true} />
            {items.length && hasItems ? (
              <Box paddingTop="1rem">
                {items.map(({ label, items }, index) => (
                  <Box padding="0rem 1rem 1rem 1rem" key={index}>
                    <Box paddingBottom="0.625rem">
                      <Typography variant="body1" weight={600} color="label">
                        {trans(label)}
                      </Typography>
                    </Box>
                    <Paper style={{ overflow: 'hidden' }}>
                      {items.map((item, index) => (
                        <React.Fragment key={index}>
                          <NotificationSmallCard
                            item={item}
                            checked={checkedItems.includes(item.id)}
                            onCheckItem={onCheckItem}
                            chosen={selectedIndex === index}
                            // onClick={() => onNotificationClicked(index, item)}
                            data-index={index}
                            openSelected={openSelected}
                          />
                          {index < items?.length - 1 && <Divider light />}
                        </React.Fragment>
                      ))}
                    </Paper>
                  </Box>
                ))}
              </Box>
            ) : !pending ? (
              <SpacingContainer px={2} py={2} style={{ flex: 1 }}>
                <NothingFound
                  title={trans(!query.arch ? nothingFoundTitle : 'YourTrashIsEmpty')}
                  description={trans(!query.arch ? nothingFoundSubtitle : 'AllTrashedNotificationAppear')}
                  canClearFilters={hasItems}
                  onClearFilters={resetNotificationFilters}
                />
              </SpacingContainer>
            ) : null}

            {!isArchive && (
              <SpacingContainer style={{ padding: '1rem', paddingTop: !items.length ? 0 : '1rem' }}>
                <Button
                  fullWidth
                  variant="text"
                  disabled={disableLoadMore}
                  onClick={onLoadMore}
                  style={{ backgroundColor: 'white', padding: '1.5rem 0', borderRadius: '0.75rem' }}
                >
                  <Typography color={disableLoadMore ? 'hint' : 'primary'} variant="body1" weight={500}>
                    {trans('load-previous-7-days')}
                  </Typography>
                </Button>
              </SpacingContainer>
            )}
          </SectionScrollable>
        </Grid>
      </Grid>
      <EmailNotificationModal
        user={authUser}
        onUpdate={updateEmailNotificationSettings}
        open={openModal}
        onClose={onCloseModal}
      />
    </QuickViewLayout>
  );
}

export default connect(Notifications);
