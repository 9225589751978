import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconSavedSearch = (props) => {
  const { width, height, notFound = false } = props;

  return notFound ? (
    <SvgIcon width={width || 64} height={height || 64} viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd">
        <g fill="#B2B8CF">
          <g>
            <g>
              <g>
                <path
                  d="M175 0c16.016 0 29 12.984 29 29 0 7.648-2.96 14.605-7.8 19.787l13.507 13.506c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-13.506-13.506C189.605 55.039 182.65 58 175 58c-16.016 0-29-12.984-29-29s12.984-29 29-29zm0 2c-14.912 0-27 12.088-27 27s12.088 27 27 27 27-12.088 27-27-12.088-27-27-27zm8.707 21.293c.36.36.388.928.083 1.32l-.083.094-11 11c-.36.36-.928.388-1.32.083l-.094-.083-5-5c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083l.094.083L172 33.585l10.293-10.292c.39-.39 1.024-.39 1.414 0z"
                  transform="translate(-838 -294) translate(380 149) translate(0 65) translate(312 80)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  ) : (
    <SvgIcon width={width || 24} height={height || 24} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g fill="#B2B8CF">
          <g>
            <g>
              <g>
                <path
                  d="M175 0c16.016 0 29 12.984 29 29 0 7.648-2.96 14.605-7.8 19.787l13.507 13.506c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-13.506-13.506C189.605 55.039 182.65 58 175 58c-16.016 0-29-12.984-29-29s12.984-29 29-29zm0 2c-14.912 0-27 12.088-27 27s12.088 27 27 27 27-12.088 27-27-12.088-27-27-27zm8.707 21.293c.36.36.388.928.083 1.32l-.083.094-11 11c-.36.36-.928.388-1.32.083l-.094-.083-5-5c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083l.094.083L172 33.585l10.293-10.292c.39-.39 1.024-.39 1.414 0z"
                  transform="translate(-838 -294) translate(380 149) translate(0 65) translate(312 80)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

IconSavedSearch.propTypes = simpleIconPropsTypes;
