import { Grid } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Typography from '../../../../components/common/Typography';
import { useTrans } from '../../../../services/i18n';
import { useEffect, useState } from 'react';
import CodeInput from '../../../../components/common/Inputs/CodeInput';
import { Button } from '../../../../components/common/Buttons';
import { Divider } from '../../../../components/common/Divider';
import { useApplication } from '../../../../services/application';
import { useAlertContext } from '../../../../contexts/AlertContext';
import { AmplifyAuth } from '../../../../services/amplifyAuth';
import connect from '../../connect';

const SetPhone = ({ auth, onClose, updatePhone }) => {
  const { trans } = useTrans();
  const application = useApplication();
  const { successAlert, errorAlert } = useAlertContext();
  const [sentCode, setSentCode] = useState(false);
  const [code, setCode] = useState('');
  const [pending, setPending] = useState(false);
  const [newPhone, setPhone] = useState('');
  const [resendPending, setResendPending] = useState(false);

  const { phoneVerified, phone } = auth;

  const handleSetPhone = async () => {
    setPending(true);

    if (!sentCode) {
      return application
        .call('user.setPhone', { phone: newPhone })
        .then(async (res) => {
          if (res) {
            if (res.verified === 'false') {
              setSentCode(true);
            } else {
              await successUpdatePhone();
            }
          }
        })
        .catch((e) => errorAlert(e.message))
        .finally(() => setPending(false));
    } else {
      return AmplifyAuth.verifyCurrentUserAttributeSubmit('phone_number', code)
        .then(async () => {
          await successUpdatePhone();
        })
        .finally(() => {
          setPending(false);
        })
        .catch((e) => errorAlert(e.message));
    }
  };

  const successUpdatePhone = async () => {
    try {
      await application.call('user.updatePhone', { phone: newPhone });
      updatePhone(newPhone);
      successAlert(trans('success-modified-phone'));
      onClose();
    } catch (error) {
      errorAlert(error.message);
    }
  };

  const resendCode = async () => {
    return AmplifyAuth.verifyCurrentUserAttribute('phone_number')
      .finally(() => setResendPending(false))
      .catch((e) => errorAlert(e.message));
  };

  useEffect(() => {
    if (phone) {
      setPhone(phone);
    }
  }, [phone]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutter="label" variant="h6" component="p" weight="bold" color="label">
          {trans('enter-phone')}
        </Typography>
        <div style={{ position: 'relative' }}>
          <PhoneInput
            defaultCountry="US"
            international
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
            style={{ background: '#f1f3f9', padding: '10px', borderRadius: '3px', fontFamily: 'Lexend' }}
          />
          {sentCode && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ position: 'absolute', right: '10px', top: '11px' }}
              pending={resendPending}
              onClick={resendCode}
            >
              {trans('resend-code')}
            </Button>
          )}
        </div>
        {sentCode && (
          <>
            <Divider light spacing={2} />
            <Typography variant="h6" component="p" weight="bold" color="label">
              {trans('step-verification')}
            </Typography>
            <Typography variant="h6" component="p" color="label" gutter="bottom2">
              Please verify the CODE sent to your phone registered and enter below
            </Typography>

            <Typography gutter="label" variant="h6" component="p" weight="bold" color="label">
              {trans('enter-code')}
            </Typography>
            <CodeInput fields={6} type="number" onChange={(e) => setCode(e)} />
          </>
        )}
        <Divider light spacing={3} />
        <div style={{ textAlign: 'right' }}>
          {sentCode ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSetPhone}
              pending={pending}
              disabled={!newPhone || code.length < 6 || pending}
            >
              {trans('verify')}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSetPhone}
              pending={pending}
              disabled={(!newPhone || newPhone === phone || pending) && !phoneVerified}
            >
              {trans('confirm')}
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default connect(SetPhone);
