import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { API, SCOPE_ORGANIZATION_SAVED_SEARCHES } from '../../constants';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE } from '../helpers/common';
import { extendBuilderWithListActions, generateListActions } from '../helpers/listActionsHelpers';
import { extendBuilderWithAsyncAction } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE,
  pagination: { page: 1, perPage: 10 },
  params: { scope: '' },
  order: ['updatedAt', 'desc'],
  isAllCheckedSavedSearches: false,
  isSomeCheckedSavedSearches: false,
  savedSearchesSelected: [],
  savedSearchesUnselected: [],
};

const listActions = generateListActions({
  scope: SCOPE_ORGANIZATION_SAVED_SEARCHES,
  apiMethod: {
    GET_LIST: API.ORGANIZATION_SAVED_SEARCH.GET_LIST,
  },
  getStore: (store) => store.organizationSavedSearches,
});

const saveSearchAction = createAsyncThunk(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/saveSearch`, (data) => {
  const { id, title, notification, notificationFrequency, notificationNumber } = data;
  return application.call(API.ORGANIZATION_SAVED_SEARCH.CREATE, {
    id,
    title,
    notification,
    notificationFrequency,
    notificationNumber,
  });
});

/***
 * Deletes a specific saved search
 */
const deleteSearchAction = createAsyncThunk(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/deleteSearch`, (params) => {
  return application.call(API.ORGANIZATION_SAVED_SEARCH.DELETE, params);
});

const findOneSearchAction = createAsyncThunk(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/findOneSearch`, (params) => {
  return application.call(API.ORGANIZATION_SAVED_SEARCH.FIND_ONE, params);
});

/***
 * Store data on redux after a delete action
 */
const saveDataAfterDeleteSearchAction = createAction(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/saveDataAfterDeleteSearch`);
function onSaveDataAfterDeleteSearch(state, action) {
  // Remove item in case it was in selected or unselected array
  Object.assign(state, {
    savedSearchesSelected: [...state.savedSearchesSelected.filter((s) => s !== action.payload.id)],
    savedSearchesUnselected: [...state.savedSearchesUnselected.filter((s) => s !== action.payload.id)],
  });
}

/***
 * Store data on redux after a delete bulk action
 */
const saveDataAfterDeleteBulkSearchAction = createAction(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/saveDataAfterDeleteBulkSearch`);
function onSaveDataAfterDeleteBulkSearch(state, action) {
  // Remove item in case it was in selected or unselected array
  Object.assign(state, {
    isAllCheckedSavedSearches: false,
    isSomeCheckedSavedSearches: false,
    savedSearchesSelected: [],
    savedSearchesUnselected: [],
  });
}

/***
 * In charge of add all items on selected array
 */
const addAllSavedSearchesAction = createAction(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/addAllSavedSearchesAction`);
function onaAddAllSavedSearches(state, action) {
  // Clean
  Object.assign(state, {
    savedSearchesSelected: [],
    savedSearchesUnselected: [],
  });
  return Object.assign(state, {
    isAllCheckedSavedSearches: true,
    isSomeCheckedSavedSearches: false,
    savedSearchesSelected: state.items.map((item) => item.id),
    savedSearchesUnselected: [],
  });
}

/***
 * In charge of add all items on selected array
 */
const removeAllSavedSearchesAction = createAction(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/removeAllSavedSearchesAction`);
function onRemoveAllSavedSearches(state, action) {
  return Object.assign(state, {
    isAllCheckedSavedSearches: false,
    isSomeCheckedSavedSearches: false,
    savedSearchesSelected: [],
    savedSearchesUnselected: [],
  });
}

/***
 * When page or order change, add remaining items, except those in unSelected array
 */
const addRemainingSavedSearchesAction = createAction(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/addRemainingSavedSearches`);
function onAddRemainingSavedSearches(state, action) {
  if (state.isAllCheckedSavedSearches) {
    // Get items ids
    const itemsIds = state.items.map((item) => item.id);
    // Get only items that are not in savedSearchesUnselected nor savedSearchesSelected
    const filteredItemsIds = itemsIds.filter((itemId) => {
      if (!state.savedSearchesUnselected.includes(itemId) && !state.savedSearchesSelected.includes(itemId)) {
        return itemId;
      }
    });

    return Object.assign(state, {
      savedSearchesSelected: [...state.savedSearchesSelected, ...filteredItemsIds],
    });
  }
}

/***
 * Add a single item to savedSearchesSelected array and remove it from savedSearchesUnselected
 */
const addOneSavedSearchesAction = createAction(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/addOneSavedSearches`);
function onAddOneSavedSearches(state, action) {
  // Get how many unselected items
  const countUnslected = [...state.savedSearchesUnselected.filter((s) => s !== action.payload.id)];
  return Object.assign(state, {
    savedSearchesSelected: [...state.savedSearchesSelected, action.payload.id],
    savedSearchesUnselected: [...countUnslected],
    isSomeCheckedSavedSearches: countUnslected.length === 0 ? false : true,
  });
}

/***
 * Remove a single item to savedSearchesSelected array and add it to savedSearchesUnselected
 */
const removeOneSavedSearchesAction = createAction(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/removeOneSavedSearches`);
function onRemoveOneSavedSearches(state, action) {
  if (state.isAllCheckedSavedSearches) {
    const countSelected = [...state.savedSearchesSelected.filter((s) => s !== action.payload.id)];
    const countUnselected = [...state.savedSearchesUnselected, action.payload.id];
    const isAllUnselected = countUnselected.length !== state.count;
    return Object.assign(state, {
      isAllCheckedSavedSearches: isAllUnselected ? true : false,
      isSomeCheckedSavedSearches: isAllUnselected ? true : false,
      savedSearchesSelected: [...countSelected],
      savedSearchesUnselected: isAllUnselected ? [...countUnselected] : [],
    });
  } else {
    return Object.assign(state, {
      savedSearchesSelected: [...state.savedSearchesSelected.filter((s) => s !== action.payload.id)],
    });
  }
}

/***
 * Store data on redux after a delete bulk action
 */
const restartStateAction = createAction(`${SCOPE_ORGANIZATION_SAVED_SEARCHES}/restartState`);
function onRestartState(state, action) {
  // Restart the data in case the user look for another registered user
  return Object.assign(state, {
    isAllCheckedSavedSearches: false,
    isSomeCheckedSavedSearches: false,
    savedSearchesSelected: [],
    savedSearchesUnselected: [],
  });
}

/***
 * 
 */
const addToMySavedSearches = createAsyncThunk(
  `${SCOPE_ORGANIZATION_SAVED_SEARCHES}/addToMySavedSearches`,
  (data) => {
    return application.call(API.ORGANIZATION_SAVED_SEARCH.ADD_TO_MY_SAVED_SEARCHES, data);
  }
);

export const actions = {
  ...listActions,
  saveSearchAction,
  deleteSearchAction,
  findOneSearchAction,
  addAllSavedSearchesAction,
  removeAllSavedSearchesAction,
  addRemainingSavedSearchesAction,
  addOneSavedSearchesAction,
  removeOneSavedSearchesAction,
  saveDataAfterDeleteSearchAction,
  saveDataAfterDeleteBulkSearchAction,
  restartStateAction,
  addToMySavedSearches
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, actions);
  extendBuilderWithAsyncAction(builder, saveSearchAction);
  extendBuilderWithAsyncAction(builder, deleteSearchAction);
  extendBuilderWithAsyncAction(builder, findOneSearchAction);
  extendBuilderWithAsyncAction(builder, addToMySavedSearches);
  return (
    builder
      // .addCase(findOne.fulfilled, onFindOne)
      .addCase(addAllSavedSearchesAction, onaAddAllSavedSearches)
      .addCase(removeAllSavedSearchesAction, onRemoveAllSavedSearches)
      .addCase(addRemainingSavedSearchesAction, onAddRemainingSavedSearches)
      .addCase(addOneSavedSearchesAction, onAddOneSavedSearches)
      .addCase(removeOneSavedSearchesAction, onRemoveOneSavedSearches)
      .addCase(saveDataAfterDeleteSearchAction, onSaveDataAfterDeleteSearch)
      .addCase(saveDataAfterDeleteBulkSearchAction, onSaveDataAfterDeleteBulkSearch)
      .addCase(restartStateAction, onRestartState)
  );
});
