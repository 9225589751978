import React from 'react';
import { simpleIconPropsTypes } from '../propTypes';

export const IconTimer = (props) => {
  const { width = 25, height = 25 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M8.125 2.08331C7.94444 2.08331 7.79514 2.02429 7.67708 1.90623C7.55903 1.78817 7.5 1.63887 7.5 1.45831C7.5 1.27776 7.55903 1.12845 7.67708 1.0104C7.79514 0.892341 7.94444 0.833313 8.125 0.833313H11.875C12.0556 0.833313 12.2049 0.892341 12.3229 1.0104C12.441 1.12845 12.5 1.27776 12.5 1.45831C12.5 1.63887 12.441 1.78817 12.3229 1.90623C12.2049 2.02429 12.0556 2.08331 11.875 2.08331H8.125ZM10 11.3958C10.1806 11.3958 10.3299 11.3368 10.4479 11.2187C10.566 11.1007 10.625 10.9514 10.625 10.7708V7.22915C10.625 7.04859 10.566 6.89928 10.4479 6.78123C10.3299 6.66317 10.1806 6.60415 10 6.60415C9.81944 6.60415 9.67014 6.66317 9.55208 6.78123C9.43403 6.89928 9.375 7.04859 9.375 7.22915V10.7708C9.375 10.9514 9.43403 11.1007 9.55208 11.2187C9.67014 11.3368 9.81944 11.3958 10 11.3958ZM10 18.3125C8.97222 18.3125 8.00347 18.1146 7.09375 17.7187C6.18403 17.3229 5.38889 16.7847 4.70833 16.1041C4.02778 15.4236 3.48958 14.6285 3.09375 13.7187C2.69792 12.809 2.5 11.8403 2.5 10.8125C2.5 9.7847 2.69792 8.81595 3.09375 7.90623C3.48958 6.99651 4.02778 6.20137 4.70833 5.52081C5.38889 4.84026 6.18403 4.30206 7.09375 3.90623C8.00347 3.5104 8.97222 3.31248 10 3.31248C10.9306 3.31248 11.8021 3.46526 12.6146 3.77081C13.4271 4.07637 14.1528 4.50692 14.7917 5.06248L15.4167 4.43748C15.5417 4.31248 15.6875 4.24998 15.8542 4.24998C16.0208 4.24998 16.1736 4.31248 16.3125 4.43748C16.4236 4.56248 16.4792 4.71526 16.4792 4.89581C16.4792 5.07637 16.4236 5.2222 16.3125 5.33331L15.6875 5.95831C16.1875 6.51387 16.6146 7.18748 16.9687 7.97915C17.3229 8.77081 17.5 9.71526 17.5 10.8125C17.5 11.8403 17.3021 12.809 16.9062 13.7187C16.5104 14.6285 15.9722 15.4236 15.2917 16.1041C14.6111 16.7847 13.816 17.3229 12.9062 17.7187C11.9965 18.1146 11.0278 18.3125 10 18.3125Z"
        fill="white"
      />
    </svg>
  );
};

IconTimer.propTypes = simpleIconPropsTypes;
