import React, { useState } from 'react';
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard';
import { useApplication } from '../../../../services/application';
import { API } from '../../../../constants';
import Typography from '../../../../components/common/Typography';
import { useTrans } from '../../../../services/i18n';
import connect from '../../connect';
import { Button, RouterLink } from '../../../../components/common/Buttons';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { Paper } from '../../../../components/common/Containers';
import { emptyArray } from '../../../../utils/arrayUtils';
import { IconDownload } from '../../../../../src/components/svg';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  textContainer: {
    display: 'block',
    width: '25vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const KeyPersonnel = ({ loading, settings, openAddFeatureInfoOrg, openAddUpdateOrg }) => {
  const { trans } = useTrans();
  const application = useApplication();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function formatTime24To12(timeStr) {
    let [hour, minute] = timeStr.split(':');
    let period = 'AM';

    hour = parseInt(hour, 10);

    if (hour > 12) {
      period = 'PM';
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
      period = 'AM';
    } else if (hour === 12) {
      period = 'PM';
    }

    return `${hour}:${minute} ${period}`;
  }

  function formatTimeInterval(timesArray) {
    const start = formatTime24To12(timesArray[0]);
    const end = formatTime24To12(timesArray[1]);

    return `${start} - ${end} EST`;
  }

  function formatPhoneNumber(phone) {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const stripped = cleaned.length === 11 && cleaned.startsWith('1') ? cleaned.substr(1) : cleaned;
    const match = stripped.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (!match) {
      return phone;
    }

    const formatted = `(${match[1]}) ${match[2]}-${match[3]}`;
    return formatted;
  }

  async function handleDownloadClick(value) {
    try {
      if (value.length > 0) {
        const response = await application.call(API.ORGANIZATION.GET_TEAMING_FILES, { file: value[0] });
        if (response.success) {
          window.open(response.downloadUrl, '_blank');
        } else {
          console.error('Error downloading file');
        }
      }
    } catch (error) {
      console.error('Error downloading file');
    }
  }

  return (
    <>
      <TableContainer component={Paper} square radius={8}>
        {!emptyArray(settings.orgTeamingRecord.teamingKeyPersonnel) ? (
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{trans('name')}</TableCell>
                  <TableCell align="left">{trans('email')}</TableCell>
                  <TableCell align="left">{trans('phone')}</TableCell>
                  <TableCell align="left">{trans('best-time-availability')}</TableCell>
                  <TableCell align="left">{trans('resume')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settings.orgTeamingRecord.teamingKeyPersonnel
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((contact) => (
                    <TableRow key={contact.id}>
                      <TableCell align="left">{contact.name}</TableCell>
                      <TableCell align="left">{contact.email}</TableCell>
                      <TableCell align="left">{contact.phone ? formatPhoneNumber(contact.phone) : ''}</TableCell>
                      <TableCell align="left">
                        {!emptyArray(contact.timeToReach) ? formatTimeInterval(contact.timeToReach) : ''}
                      </TableCell>
                      <TableCell align="left">
                        <Typography fontFamily="lexend" weight="500" variant="h6" component={RouterLink} noWrap>
                          <Button
                            size="small"
                            isIcon
                            color="primary"
                            onClick={() => handleDownloadClick(contact.resumes)}
                          >
                            <IconDownload />
                          </Button>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <NothingFoundCard title={trans('No key personnel added yet')} size="small"></NothingFoundCard>
        )}
      </TableContainer>
      {!emptyArray(settings.orgTeamingRecord.teamingKeyPersonnel) ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={settings.orgTeamingRecord.teamingKeyPersonnel.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default connect(KeyPersonnel);
