import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';

export const INITIAL_STATE = {
  statistics: [
    {
      value: 0,
      text: 'Ticket overview',
      link: '/business-support-tickets',
      color: '#9576ff',
      button: false,
      colorButton: 'default',
    },
    {
      value: 0,
      text: 'Form overview',
      link: '/client-portal-forms',
      color: '#526fff',
      button: true,
      colorButton: 'default',
    },
    {
      value: 0,
      text: 'Missing documents',
      link: '/client-portal-documents?type=filerequests',
      color: '#ffac00',
      button: true,
      colorButton: 'default',
    },
    {
      value: 0,
      otherText: 'days left',
      expirationDate: '',
      text: 'Upcoming renewals',
      link: '/open-pursuits?type=internal',
      color: '#a5cb55',
      button: false,
      oneTitle: false,
    },
  ],
  team: [],
  services: [],
  requiredForms: [],
  sam: null,
  pending: false,
};

const getOverview = createAsyncThunk('business/getOverview', (params) => {
  return application.call(API.BUSINESS.GET_OVERVIEW, params);
});

function onFetchOverview(state, action) {
  const copyStatistics = [...state.statistics];
  copyStatistics[0].value = action.payload.statistics.tickets;
  copyStatistics[1].value = action.payload.statistics.forms;
  copyStatistics[2].value = action.payload.statistics.documents;
  copyStatistics[3].value = action.payload.statistics.renewals;
  copyStatistics[3].expirationDate = action.payload.statistics.expirationDate;

  Object.assign(state, {
    statistics: [...copyStatistics],
    team: action.payload.team,
    services: action.payload.services,
    requiredForms: action.payload.requiredForms,
    sam: action.payload.sam,
    sap: action.payload.sap,
    pending: false,
  });
}

export const actions = {
  getOverview,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getOverview.fulfilled, onFetchOverview)
    .addCase(getOverview.pending, (state) => {
      Object.assign(state, {
        pending: true,
      });
    })
    .addCase(getOverview.rejected, (state) => {
      Object.assign(state, {
        pending: false,
      });
    });
});
