import React, { useState } from 'react';
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard';
import Typography from '../../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import { useTrans } from '../../../../services/i18n';
import connect from '../../connect';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { emptyArray } from '../../../../utils/arrayUtils';

const useStyles = makeStyles((theme) => ({}));

const TeamingQuestions = ({ loading, settings, openAddFeatureInfoOrg, openAddUpdateOrg }) => {
  const { trans } = useTrans();
  const styles = useStyles();
  const orderedAnswers = [...settings.orgTeamingRecord.teamingAnswers].sort((a, b) => a.order - b.order);

  const teamingRecordStylesLabels = {
    color: 'hint',
    weight: '400',
    weightLabel: '500',
    fontFamily: 'Manrope',
  };

  const history = useHistory();

  return (
    <>
      {!emptyArray(settings.orgTeamingRecord.teamingAnswers) ? (
        <Grid container>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid container>
              {orderedAnswers.map((item, index) => (
                <Grid container key={index}>
                  <Grid item md={12} sm={12} xs={12} style={{ marginBottom: '16px' }}>
                    <Typography
                      variant="body2"
                      {...teamingRecordStylesLabels}
                      style={{ paddingBottom: '8px' }}
                      weight="500"
                    >
                      {item.question}
                    </Typography>
                    {item.answer ? (
                      <Typography variant="h6">{item.answer}</Typography>
                    ) : (
                      <Typography variant="h6" component="p" color="hint">
                        {trans('not-provided')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column" alignItems="center">
          <NothingFoundCard title={trans('No teaming questions added yet')} size="small"></NothingFoundCard>
        </Grid>
      )}
    </>
  );
};

export default connect(TeamingQuestions);
