import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    width: '100%',
    padding: 16,
    background: (props) => (props.isActive ? '#2445A7' : props.isCompleted ? 'white' : 'white'),
    borderRadius: 24,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    display: 'inline-flex',
  },
  stepNumberContainer: {
    width: 48,
    height: 48,
    position: 'relative',
    background: 'white',
    borderRadius: 16,
    border: (props) => (props.isActive ? 'none' : props.isCompleted ? '1px #2445A7 solid' : '1px #999999 solid'),
  },
  stepNumber: {
    left: 18,
    top: 9,
    position: 'absolute',
    textAlign: 'center',
    color: (props) => (props.isActive ? '#2445A7' : props.isCompleted ? '#2445A7' : '#999999'),
    fontSize: 22,
    fontFamily: 'Manrope',
    fontWeight: '700',
    wordWrap: 'break-word',
  },
  stepTitle: {
    width: 128,
    color: (props) => (props.isActive ? 'white' : props.isCompleted ? '#2C3A49' : '#999999'),
    fontSize: 15,
    fontFamily: 'Manrope',
    fontWeight: '700',
    wordWrap: 'break-word',
  },
}));

const StepCard = ({ title, isActive, isCompleted, stepNumber }) => {
  const styles = useStyles({ isActive, isCompleted });

  return (
    <Paper className={styles.stepContainer} elevation={isActive ? 3 : 1}>
      <div className={styles.stepNumberContainer}>
        <Typography className={styles.stepNumber}>{stepNumber}</Typography>
      </div>
      <Typography className={styles.stepTitle}>{title}</Typography>
    </Paper>
  );
};

export default StepCard;
