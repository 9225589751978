import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.modal - 1,
    overflow: 'auto',
    alignItems: 'flex-start',
  },
  fixed: {
    zIndex: theme.zIndex.modal + 1,
    position: 'fixed',
  },
  wrapper: {
    position: 'relative',
    margin: 50,
  },
  absolute: {
    position: 'absolute',
  },
  col: {
    flex: 1,
    minWidth: 300,
  },
  dividerCol: {
    flexShrink: 0,
  },
  planTitle: {
    marginBottom: 16,
  },
  table: {
    padding: 30,
  },
  closeBtn: {
    position: 'absolute',
    right: 24,
    top: 24,
  },
}));

export default useStyles;
