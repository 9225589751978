import React, { useEffect, useState, useCallback } from 'react';
import { useTrans } from '../../../../services/i18n';
import { SelectField, TextField, TimePicker } from '../../../../components/common/Inputs';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { useForm } from '../../../../hooks/useForm';
import { useApplication } from '../../../../services/application';
import { catchYupErrors, schemaToInitialState } from '../../../../utils/validationUtils';
import MenuItem from '@material-ui/core/MenuItem';
import { AmplifyAuth } from '../../../../services/amplifyAuth';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAlertContext } from '../../../../contexts/AlertContext';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import { Divider } from '../../../../components/common/Divider';
import { Button } from '../../../../components/common/Buttons';
import { ReactComponent as ArrowBackIcon } from '../../icons/arrow-left.svg';
import { FormikProvider, FieldArray } from 'formik';
import Typography from '../../../../components/common/Typography';
import { Chip } from '../../../../components/common/Chip';
import { IconTrash } from '../../../../components/svg/Icons';
import IconButton from '@material-ui/core/IconButton';
import FileUploadModal from '../FileUploadModal';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/common/Loader';
import 'react-phone-number-input/style.css';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ROUTE_ORG_SETTINGS } from '../../../../constants';

const EMAIL_REGX = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    teamingKeyPersonnel: Yup.array()
      .max(5)
      .of(
        Yup.object().shape({
          name: Yup.string(),
          email: Yup.string().nullable().optional().matches(EMAIL_REGX, 'Invalid email address'),
          phone: Yup.string(),
          timezone: Yup.string(),
          timeToReach: Yup.array().of(Yup.string()),
          resumes: Yup.array(),
        })
      ),
  })
);
function getRenderValue(timezone, value) {
  const item = timezone.find((tz) => tz.abbr === value);
  return item && item.text;
}

function getDateRenderValue(dfs, value) {
  const d = dfs.find((i) => i.value === value);
  return d && d.name;
}

const useStyles = makeStyles((theme) => ({
  labelDropdowns: {
    fontFamily: 'Lexend',
    marginBottom: '8px',
    color: '#4e5978',
    fontWeight: 500,
  },
  containerCard: {
    display: 'flex',
    padding: '1.5rem 2.5rem',
  },
  addContainer: {
    display: 'flex',
    padding: '.5rem 2.5rem',
  },
  centerItems: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.3rem 2.3rem 1.87rem 2.3rem',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  backButton: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontFamily: 'Manrope',
    fontWeight: '500',
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cancelNextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  customHeight: {
    height: '1.8rem',
  },
  tag: {
    height: '1.8rem',
    gap: '.1rem',
    backgroundColor: '#FFFFFF',
    '& > [class*="label"]': {
      fontWeight: 'normal',
      fontSize: '13px',
      backgroundColor: '#F1F3F9',
      borderRadius: '0.125rem',
      padding: '0rem 0.75rem',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    '& > [class*="deleteIcon"]': {
      margin: '0 7px 0 0',
      backgroundColor: '#F1F3F9',
      borderRadius: '0.125rem',
      height: '100%',
      fill: '#222',
    },
  },
  phoneInput: {
    fontWeight: 'normal',
    background: theme.palette.background.input,
    color: theme.palette.common.black,
    borderRadius: '3px',
    height: '1.4em',
    padding: '1.7rem',
    border: 'none',
    width: '100%',
    '&::placeholder': { color: theme.palette.gray.active, opacity: 1 },
  },
}));

export default function KeyPersonnel({
  data,
  onUpdate,
  nextStep,
  previousStep,
  setData,
  onCancel,
  onCreateTeaming,
  isEditMode,
}) {
  const { trans } = useTrans();
  const application = useApplication();
  const schema = useScheme();
  const [pending, setPending] = useState(false);
  const [openForm, setOpenForm] = useState({ idx: 0, isOpen: false });
  const [timezone, setTimezone] = React.useState([]);
  const styles = useStyles();
  const { successAlert, errorAlert } = useAlertContext();
  const organizationId = useSelector((state) => state.auth?.organizations?.find((x) => !!x.active)?.organizationId);
  const history = useHistory();
  const onCloseForm = () => {
    setOpenForm({ idx: 0, isOpen: false });
  };
  const onOpenForm = (idx) => {
    return () => setOpenForm({ idx, isOpen: true });
  };
  const onSubmit = React.useCallback(
    async (values) => {
      try {
        setPending(true);
        const input = _.merge({}, values, data);

        const parsedData = new FormData();
        parsedData.append('isEditMode', isEditMode.toString());
        if (input['capabilityStatements']?.originFile) {
          parsedData.append(
            'capabilityStatements',
            input['capabilityStatements'].originFile,
            input['capabilityStatements'].originFile.name
          );
        } else if (Array.isArray(input['capabilityStatements'])) {
          parsedData.append('capabilityStatements', input['capabilityStatements']);
        } else {
          parsedData.append('capabilityStatements', null);
        }

        if (input['teamingKeyPersonnel']?.length > 0) {
          const teamingKeyPersonnel = input['teamingKeyPersonnel'].map((personnel, idx) => {
            if (personnel.resumes?.length > 0) {
              for (const file of personnel.resumes) {
                if (file.originFile) {
                  parsedData.append(`resume_${idx}`, file.originFile, file.originFile.name);
                } else {
                  parsedData.append(`resume_${idx}`, file);
                }
              }
            } else {
              parsedData.append(`resume_${idx}`, null);
            }
            delete personnel['resumes'];

            return personnel;
          });
          input['teamingKeyPersonnel'] = teamingKeyPersonnel;
        }
        delete input?.capabilityStatements;
        parsedData.append('orgTeaming', JSON.stringify({ ...input, organizationId }));
        await onCreateTeaming(parsedData);
        successAlert('Success');
        history.push(ROUTE_ORG_SETTINGS.path);
        history.go(0);
      } catch (e) {
        errorAlert(e.message);
      } finally {
        setPending(false);
      }
    },
    [errorAlert, onCreateTeaming, data, organizationId, successAlert]
  );

  const { getFieldProps, ...formik } = useForm(schema, schemaToInitialState(schema), onSubmit, {
    validateOnChange: true,
    validateOnBlur: true,
  });
  const { setValues, setFieldValue, handleSubmit, values, isValid, errors } = formik;

  useEffect(() => {
    application.call('utilities.timezones').then((val) => setTimezone(val));
  }, [application]);

  useEffect(() => {
    setValues({
      teamingKeyPersonnel: data.teamingKeyPersonnel || [],
    });
  }, []);
  useEffect(() => {
    storeInState();
  }, [values]);

  const storeInState = () => {
    setData((prev) => ({
      ...prev,
      ...values,
    }));
  };

  const updatePersonelFile = (file) => {
    setFieldValue(`teamingKeyPersonnel.${openForm.idx}.resumes[0]`, file);
  };

  return (
    <FormikProvider value={formik}>
      <Loader open={pending} />

      <form>
        <Grid container spacing={3} className={`${styles.containerCard} ${styles.centerItems}`}>
          <Typography weight="700" variant="h4">
            {trans('teaming-organization-form-step-3')}
          </Typography>
        </Grid>
        <FileUploadModal
          open={openForm.isOpen}
          onClose={onCloseForm}
          multiple={false}
          file={values.personnel?.[openForm.idx].resumes?.[0]}
          setFile={updatePersonelFile}
          enabledSend={false}
        />

        <Divider light spacing={1} className={styles.marginX} />
        <FieldArray
          name="teamingKeyPersonnel"
          render={(arrayHelpers) => (
            <div>
              {values?.teamingKeyPersonnel?.length > 0 &&
                values.teamingKeyPersonnel.map((personnel, idx) => (
                  <div key={`teamingKeyPersonnel-personnel-${idx}`}>
                    <Grid container spacing={1} className={styles.containerCard}>
                      <Grid item sm={11} xs={12}>
                        <Typography weight="600" variant="h5">
                          {`${trans('key-personnel')} #${idx + 1}`}
                        </Typography>
                      </Grid>
                      <Grid item sm={1} xs={12}>
                        <IconButton onClick={() => arrayHelpers.remove(idx)}>
                          <IconTrash />
                        </IconButton>
                      </Grid>

                      {personnel.resumes?.length > 0 && (
                        <Grid item>
                          <Chip
                            className={styles.tag}
                            label={`${personnel.resumes?.length} files uploaded`}
                            onDelete={() => setFieldValue(`teamingKeyPersonnel.${idx}.resumes`, [])}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={onOpenForm(idx)}
                          className={styles.customHeight}
                        >
                          {personnel.resumes?.length === 0 ? trans('upload-resume') : trans('upload-more')}
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} className={styles.containerCard}>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          type="text"
                          label={trans('name')}
                          placeholder={trans('name')}
                          disableClock
                          {...getFieldProps(`teamingKeyPersonnel.${idx}.name`)}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          type="text"
                          label={trans('email')}
                          placeholder={trans('email')}
                          disableBrowserAutoComplete
                          {...getFieldProps(`teamingKeyPersonnel.${idx}.email`)}
                        />
                        {errors.teamingKeyPersonnel?.[idx]?.email && (
                          <Typography variant="h6" weight="600" color="warning" noWrap>
                            {errors.teamingKeyPersonnel?.[idx].email}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <div className={styles.labelDropdowns}>{trans('phone')}</div>
                        <PhoneInput
                          defaultCountry="US"
                          international
                          placeholder={trans('phone')}
                          className={styles.phoneInput}
                          {...getFieldProps(`teamingKeyPersonnel.${idx}.phone`)}
                          onChange={(value) => setFieldValue(`teamingKeyPersonnel.${idx}.phone`, value)}
                        />
                      </Grid>

                      <Grid item sm={4} xs={12}>
                        <TimePicker
                          label={trans('time-to-reach')}
                          placeholder={trans('required')}
                          align="center"
                          {...getFieldProps(`teamingKeyPersonnel.${idx}.timeToReach`)}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <div className={styles.labelDropdowns}>{trans('time-zone')}</div>
                        <SelectField
                          placeholder={trans('time-zone')}
                          type="select"
                          getRenderValue={getRenderValue.bind(null, timezone)}
                          {...getFieldProps(`teamingKeyPersonnel.${idx}.timezone`)}
                        >
                          {timezone &&
                            timezone.map((tz, index) => (
                              <MenuItem key={`tz_${index}`} value={tz.abbr}>
                                {tz.text}
                              </MenuItem>
                            ))}
                        </SelectField>
                      </Grid>
                      <Grid sm={12} xs={12}>
                        <Divider light className={styles.marginX} />
                      </Grid>
                    </Grid>
                  </div>
                ))}
              {values?.teamingKeyPersonnel?.length < 5 && (
                <Grid container spacing={0} className={styles.addContainer}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        arrayHelpers.push({
                          name: '',
                          email: '',
                          phone: '',
                          timezone: '',
                          timeToReach: [],
                          resumes: [],
                        })
                      }
                    >
                      {trans('add-new-personnel')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </div>
          )}
        />
        <Divider light spacing={1} />
        <Grid container className={styles.containerFooter}>
          <div className={styles.backButtonContainer}>
            <ArrowBackIcon />
            <div className={styles.backButton} onClick={previousStep}>
              {trans('back')}
            </div>
          </div>
          <div className={styles.cancelNextButtonContainer}>
            <Button variant="outlined" color="primary" onClick={onCancel}>
              {trans('cancel')}
            </Button>
            <Button
              variant="contained"
              color="success"
              style={{ marginLeft: '10px' }}
              disabled={!isValid}
              type="submit"
              onClick={handleSubmit}
            >
              {trans('organization-form-complete-button')}
            </Button>
          </div>
        </Grid>
      </form>
    </FormikProvider>
  );
}
