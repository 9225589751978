import { IconButton } from '@material-ui/core';
import React from 'react';
import usePopover from '../../../../../../../../hooks/usePopover';
import { useTrans } from '../../../../../../../../services/i18n';
import {
  BottomLeftPosition,
  ContextMenuListItem,
  Popover,
} from '../../../../../../../../components/common/Dialogs/Popover';
import Typography from '../../../../../../../../components/common/Typography';
import connect from '../../../../../connect';
import { ReactComponent as ColumnsIcon } from '../../.././../../icons/ico-columns.svg';
import { Divider } from '../../../../../../../../components/common/Divider';
import { SelectAllMenu } from '../../../../../../../../components/common/Inputs/CheckBox';
import { CheckBox } from '../../../../../../../../components/common/Inputs/CheckBox';

const ShowColumns = ({
  tableColumns,
  isSomeColumnChecked,
  isAllColumnsChecked,
  checkAllColumns,
  uncheckAllColumns,
  columnCheck,
  apiColumnCheck,
  isSmScreen = false,
}) => {
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();
  const { trans } = useTrans();

  const onCheckAll = () => {
    checkAllColumns();
    apiColumnCheck({});
  };

  const onRemoveAllChecked = () => {
    uncheckAllColumns();
    apiColumnCheck({});
  };

  const onColumnCheck = (ev, item) => {
    const payload = { name: item.name, active: ev.target.checked };
    columnCheck(payload);
    apiColumnCheck(payload);
  };

  return (
    <>
      <IconButton onClick={onOpenPopover}>
        <ColumnsIcon />
      </IconButton>

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover} {...BottomLeftPosition}>
        <ContextMenuListItem
          size="normal"
          style={{ width: '300px', padding: '16px', display: 'flex', justifyContent: 'space-between' }}
        >
          <SelectAllMenu
            isSomeChecked={isSomeColumnChecked}
            isAllChecked={isAllColumnsChecked}
            onCheckAll={onCheckAll}
            onRemoveAllChecked={onRemoveAllChecked}
          />
          <Typography variant="h5" weight="500" noWrap>
            {trans('show-all-columns')}
          </Typography>
        </ContextMenuListItem>

        <Divider light />

        {tableColumns.map((c) => (
          <ContextMenuListItem key={c.value} size="normal" style={{ padding: '0 10px' }}>
            <CheckBox
              color="secondary"
              onChange={(ev) => onColumnCheck(ev, c)}
              checked={c.active}
              md={{ '& .MuiSvgIcon-root': { fontSize: 50 } }}
            />{' '}
            <Typography variant="body2" weight="500" noWrap>
              {trans(c.name)}
            </Typography>
          </ContextMenuListItem>
        ))}
      </Popover>
    </>
  );
};

export default connect(ShowColumns);
