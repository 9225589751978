import React from 'react';
import Suspense from './Suspense';

export default function lazyLoad({ loader, loaderComponent, timeout = 300 }) {
  const LoadComponent = React.lazy(loader);

  return () => (
    <Suspense loaderComponent={loaderComponent} timeout={timeout}>
      <LoadComponent />
    </Suspense>
  );
}
