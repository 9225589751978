import clsx from 'clsx';
import useStyles from './styles';
import { Button } from '../../Buttons';
import { Divider } from '../../Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '../../Typography';
import ModalDialog from '../../Dialogs/ModalDialog';
import { useTrans } from '../../../../services/i18n';
import { Paper, SpacingContainer } from '../../Containers';
import { ReactComponent as IconCheck } from '../../../assests/ico-check.svg';
import { ReactComponent as IconCross } from '../../../assests/ico-cross.svg';
import { ReactComponent as FreePlan } from '../../../assests/FreePlan-Illustration.svg';
import { ReactComponent as SubscribePlan } from '../../../assests/subscribe-illustration.svg';
import { ReactComponent as IconDollar } from '../../../assests/dollar-icon.svg';
import { ROUTE_CHECKOUT } from '../../../../constants';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TabPanel from '../../../../containers/Billing/TabPanel';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SelectedPlan from '../../../../containers/Billing/PlansTab/PricePlan';
import connect from '../connect';
import { emptyArray } from '../../../../utils/arrayUtils';
//import { useLocation } from 'react-router-dom';
const featureTypes = {
  notIncluded: 'partial',
  limited: 'limited',
  included: 'included',
};

export const premiumFeatureKeys = [
  'unlimited-searches',
  'export-up-to-3000-records',
  'live-support',
  'unlimited-pursuits-pipelines-tasks',
  'full-access-federal-agents',
  'up-to-10-users',
  'feature-your-vendor-profile',
];

const ContactModal = ({ open, fixed = false, onClose, currentProduct, plans }) => {
  const history = useHistory();
  const styles = useStyles();
  const { trans } = useTrans();
  const [value, setValue] = React.useState(0);
  //let data = useLocation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const freeBenefits = [
    { name: 'Maximum 10 saved searches', available: true },
    { name: 'No exports', available: false },
    { name: 'Email support only', available: true },
    { name: 'No Pursuits, Pipelines and Tasks', available: false },
    { name: 'No access to the Federal Agents', available: false },
    { name: 'Only 1 user', available: true },
    { name: "Can't feature your vendor profile", available: false },
  ];

  const [benefits, setBenefits] = useState(freeBenefits);

  useEffect(() => {
    if (currentProduct) {
      setBenefits(currentProduct.product ? currentProduct.product.benefits : []);
    } else {
      setBenefits(freeBenefits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct]);

  const getIcon = (feature) => {
    switch (feature) {
      case featureTypes.notIncluded:
        return <IconCross style={{ color: '#888FA9' }} />;
      case featureTypes.limited:
        return <IconCheck style={{ color: '#888FA9' }} />;
      default:
        return <IconCheck style={{ color: '#17B471' }} />;
    }
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const goCheckout = () => {
    onClose();
    if (history.location.pathname !== ROUTE_CHECKOUT.path) {
      history.push('/plans');
    }
  };

  return (
    <ModalDialog className={styles.root} title={trans('Choose plan')} open={open} onClose={onClose}>
      <Paper component={SpacingContainer} px={2} py={2} className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <Grid container direction={'column'} spacing={3}>
          <Grid item>
            <Grid container spacing={4} className={styles.table} wrap={'nowrap'}>
              <Grid item className={styles.col}>
                <Grid container direction={'column'} spacing={1}>
                  <FreePlan className={styles.planTitle} />
                  <Grid item>
                    <Typography variant={'h4'} className={styles.planTitle} weight={'700'}>
                      {trans('USFCR Plan')}
                    </Typography>
                  </Grid>
                  <Grid container direction={'column'} spacing={1} className={styles.ContentFree}>
                    <Grid item>
                      <Grid container spacing={1} wrap={'nowrap'}>
                        <Grid item>
                          <IconDollar style={{ color: '#888FA9' }} />
                        </Grid>
                        <Grid item>
                          <Typography variant={'body2'}>{trans('basic-plan')}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {benefits.map((b, index) => (
                      <Grid item key={index}>
                        <Grid container spacing={1} wrap={'nowrap'}>
                          <Grid item>
                            {b.available ? (
                              <IconCheck style={{ color: '#17B471' }} />
                            ) : (
                              <IconCross style={{ color: '#888FA9' }} />
                            )}
                          </Grid>
                          <Grid item>
                            <Typography variant={'body2'}>{trans(b.name)}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className={styles.dividerCol} align={'center'}>
                <Divider vertical />
              </Grid>

              <Grid item className={styles.col}>
                <Grid container direction={'column'} spacing={1}>
                  <SubscribePlan className={styles.premiumIcon} />
                  <Grid item>
                    <Typography variant={'h4'} className={styles.planTitle} weight={'700'}>
                      {trans('USFCR Plan premium')}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                          indicatorColor="primary"
                          TabIndicatorProps={{
                            style: {
                              height: '0px',
                            },
                          }}
                          className={styles.tabsContainer}
                          value={value}
                          onChange={handleChange}
                          aria-label="tab"
                        >
                          <Tab
                            className={clsx(styles.tab, value === 0 && styles.activeTab)}
                            label={trans('Monthly')}
                            {...a11yProps(0)}
                          />
                          <Tab
                            className={clsx(styles.tab, styles.borderLeftRight, value === 1 && styles.activeTab)}
                            label={trans('Yearly')}
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                    </Grid>
                  </Grid>
                  {premiumFeatureKeys.map((feature, i) => (
                    <Grid item key={i}>
                      <Grid container spacing={1} wrap={'nowrap'}>
                        <Grid item>{getIcon(featureTypes.included)}</Grid>
                        <Grid item>
                          <Typography variant={'body2'}>{trans(feature)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <TabPanel value={value} index={0}>
                    <Grid container spacing={1} wrap={'nowrap'}>
                      <Grid item style={{ marginTop: '10px' }}>
                        <Typography weight="700" variant={'h5'}>
                          {trans('USD')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography weight="700" variant={'h1'}>
                          {!emptyArray(plans) && <SelectedPlan plan={plans} select="month" />}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Grid container spacing={1} wrap={'nowrap'}>
                      <Grid item style={{ marginTop: '10px' }}>
                        <Typography weight="700" variant={'h5'}>
                          {trans('USD')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography weight="700" variant={'h1'}>
                          {!emptyArray(plans) && <SelectedPlan plan={plans} select="year" />}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <Button onClick={goCheckout} variant="contained" color="primary">
                    {trans('get-premium')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ModalDialog>
  );
};

export default connect(ContactModal);
