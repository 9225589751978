import React from 'react';
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
//import { Switch } from '../../../components/common/Inputs';
import { useState } from 'react';
import Typography from '../../../components/common/Typography';
import { useTrans } from '../../../services/i18n';
import Plan from './components/Plan';
import connect from '../connect';
import { useEffect } from 'react';
import { LoaderSmall } from '../../../components/common/LoaderSmall';
import styles from './styles.module.css';
import AvailableSeats from '../../OrgSettings/components/Seats/AvailableSeats';
import { emptyArray } from '../../../utils/arrayUtils';

const PlansTab = ({
  subscription,
  setCurrentModalityPlan,
  currentProduct,
  settings,
  plans,
  subscriptionStatus,
  fetchBusinessTeam,
  businessTeam,
}) => {
  const [modality, setModality] = useState(true);
  const { trans } = useTrans();

  useEffect(() => {
    if (currentProduct && currentProduct?.plan?.interval) {
      setModality(currentProduct.plan.interval === 'month' ? true : false);
    }
  }, [currentProduct]);

  useEffect(() => {
    if (!subscriptionStatus.sfSubscription) return;

    fetchBusinessTeam();
  }, [subscriptionStatus, fetchBusinessTeam]);

  return (
    <>
      <Card elevation={0} className={styles.containerHeadPlans}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
          <Grid item>
            <Typography variant="h4" weight="bold">
              {trans('available-plan')}
            </Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <AvailableSeats settings={settings} />
          </Grid>
          {/*
          {!pending && (
            <Grid item>
              <Switch
                color="primary"
                size="medium"
                disabled={subscription && subscription.cancel}
                onChange={(ev) => changeModality(ev)}
                label={modality ? 'Monthly' : 'Yearly'}
                checked={modality}
              />
            </Grid>
          )}
          */}
        </Grid>
      </Card>

      {!emptyArray(plans) ? (
        <>
          {plans.map((p) => (
            <Grid container>
              <Grid item xs={12}>
                <Plan
                  plan={p}
                  modality={modality}
                  subscription={subscription}
                  setCurrentModalityPlan={setCurrentModalityPlan}
                  settings={settings}
                  currentProduct={currentProduct}
                  businessTeam={subscriptionStatus?.sfSubscription ? businessTeam : null}
                  subscriptionStatus={subscriptionStatus}
                />
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <LoaderSmall color="inherit" size="1.35em" />
      )}
    </>
  );
};

export default connect(PlansTab);
