import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import useStyles from '../styles';

const INTERVAL = {
  month: 'monthly',
  year: 'annual',
};

export default function useModalSeats({ item, users, open, onClose, onSaveSeats, seatPrice }) {
  const classes = useStyles();
  const [value, setValue] = useState(!item.seats ? 0 : parseInt(item.seats));
  const [pending, setPending] = useState(false);
  const { successAlert, errorAlert } = useAlertContext();
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useBooleanHandlers();

  const seatsTotal = useMemo(() => value * seatPrice?.price ?? 0, [seatPrice?.price, value]);

  const usedSeats = useMemo(() => item.seats - item.availableSeats, [item]);

  const formattedInterval = useMemo(() => INTERVAL[seatPrice?.interval] ?? '', [seatPrice?.interval]);

  const increment = () => {
    setValue(value + 1);
  };

  const decrement = () => {
    if (value > 0) setValue(value - 1);
  };

  const saveSeats = useCallback(() => {
    if (value !== item.seats) {
      const lengthUsers = users?.length - 1;
      if (lengthUsers > value) {
        errorAlert('The user(s) associated with these seat(s) must be deleted from the organizaton first.');
        return;
      }

      setPending(true);
      Promise.resolve(onSaveSeats({ licenses: parseInt(value) }))
        .then((res) => {
          if (res.payload?.success) {
            onClose();
            onCloseConfirm();
            successAlert(`${res.payload.licenses} additional seats have been added successfully`);
          } else {
            errorAlert(res.payload.message);
          }
        })
        .catch((err) => errorAlert(err.message))
        .finally(() => setPending(false));
    }
  }, [errorAlert, item.seats, onClose, onCloseConfirm, onSaveSeats, successAlert, users?.length, value]);

  useEffect(() => {
    setValue(item.seats);
  }, [open, item.seats]);

  return {
    value,
    increment,
    decrement,
    saveSeats,
    pending,
    openConfirm,
    onOpenConfirm,
    onCloseConfirm,
    classes,
    seatsTotal,
    formattedInterval,
    usedSeats,
  };
}
