import { createTheme as createMuiTheme } from '@material-ui/core';
import { breakpoints, spacing } from './theme/common';
import mixins from './theme/mixins';
import overrides from './theme/overrides';
import palette from './theme/pallete';
import typography from './theme/typography';

const theme = createMuiTheme({
  direction: 'ltr',
  typography,
  palette,
  overrides,
  mixins,
  spacing,
  shape: {
    borderRadius: 16,
    borderRadiusAlt: 12,
  },
  breakpoints: { values: breakpoints.values },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    tooltip: 1500,
    drawer: 1600,
    modal: 1700,
    snackbar: 1800,
  },
});

export default theme;
