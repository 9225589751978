import React from 'react';
import { Table, TableCell, TableHead, TableRow } from '../../../../components/common/ResponsiveTable';
import Typography from '../../../../components/common/Typography';
import { useTrans } from '../../../../services/i18n';
import connect from '../../connect';
import UsersActions from '../UsersActions';
import { formatToTitleCase } from '../../../../utils/helpers';

function ManageUsersList({ users }) {
  const { trans } = useTrans();

  return (
    <Table
      stickyHeader
      fixedLayout
      head={
        <TableHead>
          <TableCell noWrap>{trans('name')}</TableCell>
          <TableCell noWrap width="40%">
            {trans('email')}
          </TableCell>
          <TableCell noWrap>{trans('status')}</TableCell>
          <TableCell noWrap>{trans('role')}</TableCell>
          <TableCell align="right" width={62} noWrap />
        </TableHead>
      }
    >
      {users.map((user, index) => (
        <TableRow key={index}>
          <TableCell label={trans('name')}>
            <Typography color="primary" variant="body2" noWrap>
              {user.name} {user.familyName}
            </Typography>
          </TableCell>
          <TableCell label={trans('email')} respWidth="full" order={1}>
            <Typography variant="body2" noWrap weight="500">
              {user.email}
            </Typography>
          </TableCell>
          <TableCell label={trans('status')} order={1}>
            <Typography variant="body2" weight="600" noWrap color={user.disabled ? 'secondary' : 'success'}>
              {user.disabled ? 'Disabled' : 'Enabled'}
            </Typography>
          </TableCell>
          <TableCell label={trans('role')} order={1}>
            <Typography
              variant="body2"
              noWrap
              color={user.role.toLowerCase() === 'owner' ? 'primary' : 'inherit'}
              weight={user.role.toLowerCase() === 'owner' ? 'bold' : 'normal'}
            >
              {formatToTitleCase(user.role)} {user.isUsfcr ? `- USFCR` : ''}
            </Typography>
          </TableCell>
          <TableCell align="right" respWidth="auto">
            <UsersActions user={user} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
}

export default connect(ManageUsersList);
