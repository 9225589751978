import palette from '../pallete';

const MuiAlert = {
  root: { borderRadius: 4, padding: '14px 16px' },
  icon: { padding: 0 },
  message: { padding: 0 },

  outlinedInfo: { color: palette.info.main, '& $icon': { color: undefined } },
  outlinedError: { color: palette.error.main, '& $icon': { color: undefined } },
  outlinedWarning: { color: palette.warning.main, '& $icon': { color: undefined } },
  outlinedSuccess: { color: palette.success.main, '& $icon': { color: undefined } },
};

export default MuiAlert;
