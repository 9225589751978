import React from 'react';
import connect from '../connect';
import { TIERS } from '../../../utils/subscription';
import PlanList from './PlanList';
import { formatNumberToUsd } from '../../../utils/helpers';
import { useTrans } from '../../../services/i18n';

const SIGN_UP_PATH = `${process.env.REACT_APP_SIGN_UP_PAGE}/signup/`;

const PROPERTIES = {
  year: {
    name: 'annual',
    priceDescription: '300-a-year-savings',
    color: 'success',
    interval: '/year',
    signUpTarget: 'yearly',
  },
  month: {
    name: 'monthly',
    priceDescription: 'our-most-popular-option',
    color: 'primary',
    interval: '/month',
    signUpTarget: 'monthly',
  },
  freeTrial: {
    name: 'free-trial',
    priceDescription: 'all-features-included',
    color: 'secondary',
    interval: '',
    signUpTarget: 'trial',
  },
};

export const getPlanProperty = (type) => PROPERTIES[type];

function AppMaxPlans({ products, subscription, onSubscribeClick, isAuthorized }) {
  const { trans } = useTrans();
  const appMax = products?.appMax;

  if (!products || !appMax) return null;

  const { currentPlan } = subscription?.data ?? { currentPlan: TIERS.LIMITED };

  const isSubscribed = (interval) =>
    subscription?.data?.currentPlan !== TIERS.LIMITED && subscription?.data?.interval === interval;

  const getButtonText = (interval) => {
    if (!isAuthorized) {
      return trans('sign-up');
    }

    if (isSubscribed(interval)) {
      return trans('subscribed');
    }

    return trans('update-plan');
  };

  const plans = appMax?.prices
    .map((p) => ({
      name: trans(PROPERTIES[p.recurring].name),
      price: formatNumberToUsd(p.amount ?? 0, 0),
      isSubscribed: isSubscribed(p.recurring),
      onSubscribeClick: () => onSubscribeClick(PROPERTIES[p.recurring].signUpTarget),
      priceColor: PROPERTIES[p.recurring].color,
      buttonColor: PROPERTIES[p.recurring].color,
      buttonText: getButtonText(p.recurring),
      canSubscribe: true,
      priceIntervalText: PROPERTIES[p.recurring].interval,
      priceDescription: trans(PROPERTIES[p.recurring].priceDescription),
      redirectUrl: `${SIGN_UP_PATH}?tier=${PROPERTIES[p.recurring].signUpTarget}`,
      recurring: p.recurring,
    }))
    .sort((plan, b) => (plan.recurring === 'year' ? -1 : 1));

  if (!isAuthorized) {
    plans.push({
      name: trans(PROPERTIES.freeTrial.name),
      price: trans('14-days-free-trial'),
      isSubscribed: false,
      onSubscribeClick: () => onSubscribeClick(PROPERTIES.freeTrial.signUpTarget),
      priceColor: PROPERTIES.freeTrial.color,
      buttonColor: PROPERTIES.freeTrial.color,
      buttonText: trans('get-started'),
      canSubscribe: true,
      priceIntervalText: '',
      priceDescription: trans(PROPERTIES.freeTrial.priceDescription),
      redirectUrl: `${SIGN_UP_PATH}?tier=${PROPERTIES.freeTrial.signUpTarget}`,
    });
  }

  return (
    <PlanList
      plans={plans.reverse()} // Free trial, then monthly, then yearly
      products={products}
      currentPlan={currentPlan}
      onSubscribeClick={onSubscribeClick}
    />
  );
}

export default connect(AppMaxPlans);
