import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/listActionsHelpers';
import { onPending, onPendingDone } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
  comments: [],
  countComments: 0,
  firstLoading: false,
};

const changeSort = createAction('discussions/changeSort');
const changePage = createAction('discussions/changePage');

//Actions
const getDiscussions = createAsyncThunk('pursuits/getDiscussions', (params, { getState }) => {
  const { filters, pagination } = getState().discussions;
  const { order } = params;
  return application.call(API.PURSUITS.GET_DISCUSSIONS, { filters, order, pagination, pursuit: params.pursuit });
});

const getCommentsByDiscussion = createAsyncThunk('discussions/getComments', (params) => {
  return application.call(API.PURSUITS.GET_COMMENTS_BY_DISCUSSION, params);
});

const onSaveDiscussion = createAsyncThunk('discussions/create', (params) => {
  return application.call(API.PURSUITS.STORE_DISCUSSION, params);
});

const onSaveComment = createAsyncThunk('discussions/createComment', (params) => {
  return application.call(API.PURSUITS.STORE_COMMENT_DISCUSSION, params);
});

//Reducers
function onFetchList(state, action) {
  Object.assign(state, {
    items: action.payload.items,
    pending: false,
    firstLoading: true,
    count: action.payload.count,
  });
}

function onPushDiscussion(state, action) {
  if (action.payload.success) {
    Object.assign(state, {
      items: [action.payload.discussion, ...state.items],
      pending: false,
      count: state.count + 1,
    });
  }
}

function onFetchComments(state, action) {
  if (action.payload.success) {
    Object.assign(state, {
      comments: action.payload.comments.items,
      countComments: action.payload.comments.count,
    });
  }
}

function onPushComment(state, action) {
  if (action.payload.success) {
    Object.assign(state, {
      comments: [action.payload.comment, ...state.comments],
      countComments: state.countComments + 1,
    });
    const indexDiscussion = state.items.findIndex((i) => i.id === action.payload.comment.discussionId);
    if (indexDiscussion !== -1) {
      state.items[indexDiscussion].comments_count += 1;
    }
  }
}

function onResetComments(state, action) {
  Object.assign(state, {
    comments: [],
  });
}

export const actions = {
  getDiscussions,
  getCommentsByDiscussion,
  onSaveDiscussion,
  onSaveComment,
  changeSort,
  changePage,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return builder
    .addCase(getDiscussions.pending, (state, action) => {
      state.pending = true;
      state.firstLoading = false;
    })
    .addCase(getDiscussions.rejected, onPendingDone)
    .addCase(getDiscussions.fulfilled, onFetchList)
    .addCase(getCommentsByDiscussion.pending, onResetComments)
    .addCase(getCommentsByDiscussion.rejected, onResetComments)
    .addCase(getCommentsByDiscussion.fulfilled, onFetchComments)
    .addCase(onSaveDiscussion.pending, onPending)
    .addCase(onSaveDiscussion.rejected, onPendingDone)
    .addCase(onSaveDiscussion.fulfilled, onPushDiscussion)
    .addCase(onSaveComment.pending, onPending)
    .addCase(onSaveComment.rejected, onPendingDone)
    .addCase(onSaveComment.fulfilled, onPushComment)
    .addCase(changeSort, (state, action) => {
      state.order = action.payload;
    })
    .addCase(changePage, (state, action) => {
      state.pagination = { ...state.pagination, ...action.payload };
    });
});
