import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTE_CONTRACT_OPPORTUNITIES,
  ROUTE_CONTRACT_OPPORTUNITIES_DETAILS,
  SCOPE_CONTRACT_OPPORTUNITIES,
} from '../../constants';
import { AddToPipelineConsumer, AddToPipelineProvider } from '../../contexts/AddToPipelineContext';
import Suspense from '../../services/lazyLoad/Suspense';

const ContractOpportunities = React.lazy(() => import('./ContractOpportunities'));
const ContractOpportunitiesDetails = React.lazy(() => import('./ContractOpportunityDetails'));

export default function ContractOpportunitiesWrapper() {
  return (
    <Suspense>
      <AddToPipelineProvider scope={SCOPE_CONTRACT_OPPORTUNITIES}>
        <Switch>
          <Route exact path={ROUTE_CONTRACT_OPPORTUNITIES_DETAILS.path}>
            <ContractOpportunitiesDetails />
          </Route>
          <Route exact path={ROUTE_CONTRACT_OPPORTUNITIES.path}>
            <ContractOpportunities />
          </Route>

          <Redirect exact path="/*" to={ROUTE_CONTRACT_OPPORTUNITIES.path} />
        </Switch>

        <AddToPipelineConsumer />
      </AddToPipelineProvider>
    </Suspense>
  );
}
