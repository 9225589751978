import React from 'react';
import { Button } from '../../Buttons';
import { useTrans } from '../../../../services/i18n';
import { Grid } from '@material-ui/core';

function SaveDialogAction({ onSaveClick, pending = false, color = 'primary', formId = 'form' }) {
  const { trans } = useTrans();
  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button id={`${formId}-save`} variant="contained" color={color} onClick={onSaveClick} pending={pending}>
          {trans('save')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default SaveDialogAction;
