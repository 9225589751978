import React, { useEffect, useState } from 'react';
import { useApplication } from '../../../../services/application';
import { API } from '../../../../constants';
import { Divider } from '../../../../components/common/Divider';
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard';
import { IconOrganizationTeaming } from '../../../../components/svg';
import Typography from '../../../../components/common/Typography';
import Grid from '@material-ui/core/Grid';
import { useTrans } from '../../../../services/i18n';
import FieldValue from '../../../../components/generics/Helpers/FieldValue';
import connect from '../../connect';
import { Button, RouterLink } from '../../../../components/common/Buttons';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IconDownload } from '../../../../../src/components/svg';
import { Chip } from '../../../../components/common/Chip';
import { emptyArray } from '../../../../utils/arrayUtils';

const useStyles = makeStyles((theme) => ({
  certificationsTag: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#F1F3F9',
    borderRadius: '2px',
    padding: '0.75rem',
    height: 'auto',
    marginRight: '8px',

    '& > [class*="label"]': {
      color: '#222222',
      fontWeight: 400,
      fontSize: '13px',
      fontFamily: 'Manrope',
      wordWrap: 'break-word',
      padding: '0 12px',
      height: 'auto',
    },
  },
}));

const CompanyInformation = ({ loading, settings, openAddFeatureInfoOrg, openAddUpdateOrg }) => {
  const application = useApplication();
  const { trans } = useTrans();
  const styles = useStyles();
  const [capabilitiesStatementValue, setCapabilitiesStatementValue] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (settings.orgTeamingRecord.capabilityStatements.length > 0) {
      const capabilitiesStatementUrl = settings.orgTeamingRecord.capabilityStatements[0].split('/');
      const capabilitiesStatementFilename = capabilitiesStatementUrl[capabilitiesStatementUrl.length - 1];
      setCapabilitiesStatementValue(capabilitiesStatementFilename);
    }
  }, []);

  const teamingRecordStyles = {
    color: 'black',
    weight: '400',
    weightLabel: '500',
    fontFamily: 'Manrope',
  };

  const teamingRecordStylesLabels = {
    color: 'hint',
    weight: '400',
    weightLabel: '500',
    fontFamily: 'Manrope',
  };

  function formatCurrency(value) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  }

  async function handleDownloadClick(value) {
    try {
      const response = await application.call(API.ORGANIZATION.GET_TEAMING_FILES, {
        file: value,
        fileType: 'capabilityStatement',
      });
      if (response.success) {
        window.open(response.downloadUrl, '_blank');
      } else {
        console.error('Error downloading file');
      }
    } catch (error) {
      console.error('Error downloading file');
    }
  }

  return (
    <>
      <Grid container>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="h5" style={{ fontFamily: 'Lexend', fontWeight: 500, wordWrap: 'break-word' }}>
              {trans('register-organization-form-step-1')}
            </Typography>
          </Grid>
          <Grid container style={{ marginTop: '24px' }}>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={12} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                <FieldValue
                  value={(() => {
                    const primeContractor =
                      settings.orgTeamingRecord.intPrimeContractor === 1 ? trans('prime-contractor') : '';
                    const subContractor =
                      settings.orgTeamingRecord.intSubContractor === 1 ? trans('subcontractor') : '';

                    if (primeContractor && subContractor) {
                      return `${primeContractor}, ${subContractor}`;
                    } else {
                      return primeContractor || subContractor;
                    }
                  })()}
                  label={trans('company-interests')}
                  {...teamingRecordStyles}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                style={{
                  paddingRight: '40px',
                  ...(settings.orgTeamingRecord.capabilityStatements.length > 0 ? { color: '#2445A7' } : {}),
                }}
              >
                <FieldValue
                  value={capabilitiesStatementValue}
                  label={trans('capabilities-statement')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
              {settings.orgTeamingRecord.capabilityStatements.length > 0 && (
                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant="body2" {...teamingRecordStylesLabels} weight="500">
                    {trans('pdf-download')}
                  </Typography>
                  <Button
                    size="small"
                    isIcon
                    color="primary"
                    onClick={() => handleDownloadClick(settings.orgTeamingRecord.capabilityStatements[0])}
                  >
                    <IconDownload />
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={12} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                <Typography
                  variant="body2"
                  {...teamingRecordStylesLabels}
                  style={{ paddingBottom: '8px' }}
                  weight="500"
                >
                  {trans('company-keywords')}
                </Typography>
                {!emptyArray(settings.orgTeamingRecord.keywords) ? (
                  settings.orgTeamingRecord.keywords.map((keyword, index) => (
                    <Chip
                      key={index}
                      label={keyword.value}
                      color={'secondary'}
                      style={{
                        backgroundColor: '#888FA9',
                        paddingTop: 5,
                        paddingBottom: 7,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 14,
                        marginRight: 10,
                        marginBottom: 10,
                      }}
                    />
                  ))
                ) : (
                  <Typography variant="h6" component="p" color="hint">
                    {trans('not-provided')}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={12} sm={12} xs={12}>
                <FieldValue
                  value={settings.orgTeamingRecord.capabilityNarrative}
                  label={trans('capabilities-narrative')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={6} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                <FieldValue
                  value={settings.orgTeamingRecord.linkedInUrl}
                  label={trans('company-linkedin')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue
                  value={settings.orgTeamingRecord.websiteUrl}
                  label={trans('company-website')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={6} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                <FieldValue
                  value={formatCurrency(settings.orgTeamingRecord.budgetFrom)}
                  label={trans('budget-from')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue
                  value={formatCurrency(settings.orgTeamingRecord.budgetTo)}
                  label={trans('budget-to')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
            </Grid>
            <Grid item container style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Divider style={{ height: '2px' }} />
              <div style={{ width: '100%', height: '100%', background: 'rgba(78, 89, 120, 0.12)' }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ fontFamily: 'Lexend', fontWeight: 500, wordWrap: 'break-word' }}>
                {trans('certifications')}
              </Typography>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={12} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                <Typography
                  variant="body2"
                  {...teamingRecordStylesLabels}
                  style={{ paddingBottom: '8px' }}
                  weight="500"
                >
                  {trans('official-certifications')}
                </Typography>
                {!emptyArray(settings.orgTeamingRecord.officialCertifications) ? (
                  settings.orgTeamingRecord.officialCertifications.map((certification) => (
                    <Chip
                      className={styles.certificationsTag}
                      label={`${certification.states.join(' - ')} - ${certification.name}`}
                    />
                  ))
                ) : (
                  <Typography variant="h6" component="p" color="hint">
                    {trans('not-provided')}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={12} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                <Typography
                  variant="body2"
                  {...teamingRecordStylesLabels}
                  style={{ paddingBottom: '8px' }}
                  weight="500"
                >
                  {trans('quality-assurance-standards')}
                </Typography>
                {!emptyArray(settings.orgTeamingRecord.qualityAssurance) ? (
                  settings.orgTeamingRecord.qualityAssurance.map((certification) => (
                    <Chip
                      className={styles.certificationsTag}
                      label={`${certification.assuranceType} - ${certification.standardNumber}`}
                    />
                  ))
                ) : (
                  <Typography variant="h6" component="p" color="hint">
                    {trans('not-provided')}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={12} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                <Typography
                  variant="body2"
                  {...teamingRecordStylesLabels}
                  style={{ paddingBottom: '8px' }}
                  weight="500"
                >
                  {trans('general-certifications')}
                </Typography>
                {!emptyArray(settings.orgTeamingRecord.generalCertifications) ? (
                  settings.orgTeamingRecord.generalCertifications.map((certification) => (
                    <Chip className={styles.certificationsTag} label={`${certification.name}`} />
                  ))
                ) : (
                  <Typography variant="h6" component="p" color="hint">
                    {trans('not-provided')}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Divider style={{ height: '2px' }} />
              <div style={{ width: '100%', height: '100%', background: 'rgba(78, 89, 120, 0.12)' }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ fontFamily: 'Lexend', fontWeight: 500, wordWrap: 'break-word' }}>
                {trans('special-equipment-materials')}
              </Typography>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={12} sm={12} xs={12}>
                {settings.orgTeamingRecord.specialEquipment ? (
                  <Typography variant="h6">{settings.orgTeamingRecord.specialEquipment}</Typography>
                ) : (
                  <Typography variant="h6" component="p" color="hint">
                    {trans('not-provided')}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Divider style={{ height: '2px' }} />
              <div style={{ width: '100%', height: '100%', background: 'rgba(78, 89, 120, 0.12)' }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ fontFamily: 'Lexend', fontWeight: 500, wordWrap: 'break-word' }}>
                {trans('bonding-levels')}
              </Typography>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={6} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                <FieldValue
                  value={formatCurrency(settings.orgTeamingRecord.consPerContract)}
                  label={trans('construction-per-contract')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue
                  value={formatCurrency(settings.orgTeamingRecord.consAggregate)}
                  label={trans('construction-aggregate')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '16px' }}>
              <Grid item md={6} sm={12} xs={12} style={{ paddingRight: '40px' }}>
                <FieldValue
                  value={formatCurrency(settings.orgTeamingRecord.servPerContract)}
                  label={trans('service-per-contract')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FieldValue
                  value={formatCurrency(settings.orgTeamingRecord.servAggregate)}
                  label={trans('service-aggregate')}
                  noWrap={false}
                  {...teamingRecordStyles}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(CompanyInformation);
