import makeStyles from '@material-ui/core/styles/makeStyles';
import MUITypography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';

const customColors = [
  'success',
  'warning',
  'disabled',
  'white',
  'label',
  'active',
  'hint',
  'placeholder',
  'link',
  'info',
];
const customVariant = ['h1Big', 'h1Medium'];

const useStyles = makeStyles((theme) => ({
  root: {
    '& ul': { margin: '3px 0', paddingLeft: 22 },
    '& a': { color: theme.palette.primary.main, textDecoration: 'none' },
    '& img': { maxWidth: '100%' },
    '& p': { margin: '5px 0 10px' },
  },

  // colors
  success: { color: theme.palette.success.main },
  warning: { color: theme.palette.warning.main },
  disabled: { color: theme.palette.text.disabled },
  white: { color: theme.palette.common.white },
  black: { color: '#272727' },
  label: { color: theme.palette.gray.main },
  active: { color: theme.palette.gray.active },
  hint: { color: theme.palette.gray.light },
  placeholder: { color: theme.palette.text.placeholder },
  info: { color: theme.palette.info.main },
  link: { color: '#2445a7' },

  // Line height
  'lh-1.6': { lineHeight: 1.6 },
  lhOff: { lineHeight: 1.1 },
  icon: { lineHeight: 0 },
  uppercase: { textTransform: 'uppercase' },

  // Custom variants
  h1Big: { ...theme.typography.h1Big },
  h1Medium: { ...theme.typography.h1Medium },

  // Gutter
  'label-gt': { marginBottom: theme.spacing(1) },
  'title-gt': { marginBottom: theme.spacing(1.3) },
  'bottom2-gt': { marginBottom: theme.spacing(2.5) },
  'bottomSpace-gt': { marginBottom: theme.spacing(3) },
  'topSpace-gt': { marginTop: theme.spacing(3) },
  'verticalSpace-gt': { marginTop: theme.spacing(1.25), marginBottom: theme.spacing(1.25) },
  'verticalSpaceBig-gt': { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
  'bottomSpaceBig-gt': { marginBottom: theme.spacing(5) },
  'horizontalSpace-gt': { marginLeft: theme.spacing(1.3), marginRight: theme.spacing(1.3) },

  // fontFamily
  manrope: { fontFamily: theme.typography.fontFamily },
  lexend: { fontFamily: 'Lexend' },

  //weight
  200: { fontWeight: theme.typography.fontWeightExtraLight },
  300: { fontWeight: theme.typography.fontWeightLight },
  400: { fontWeight: theme.typography.fontWeightRegular },
  500: { fontWeight: theme.typography.fontWeightMedium },
  600: { fontWeight: theme.typography.fontWeightSemiBold },
  700: { fontWeight: theme.typography.fontWeightBold },
  800: { fontWeight: theme.typography.fontWeightExtraBold },
  bold: { fontWeight: theme.typography.fontWeightBold },
  normal: { fontWeight: 'normal' },

  pointer: { cursor: 'pointer' },

  //decoration
  crossed: { textDecoration: 'line-through' },
}));

export default function Typography({
  color,
  variant,
  className,
  disableLineHeight = false,
  uppercase = false,
  icon = false,
  fontFamily = 'manrope',
  weight = 'normal',
  lineHeight = '',
  gutter = '',
  title = false,
  pointer = false,

  ...rest
}) {
  const styles = useStyles();
  const isCustomColor = customColors.includes(color);
  const isCustomVariant = customVariant.includes(variant);

  return (
    <MUITypography
      color={isCustomColor ? 'initial' : color ? color : 'initial'}
      variant={isCustomVariant ? 'body1' : variant}
      className={clsx(styles.root, className, pointer && styles['pointer'], styles[fontFamily], styles[weight], {
        [styles[color]]: isCustomColor,
        [styles[variant]]: isCustomVariant,
        [styles.lhOff]: disableLineHeight,
        [styles.uppercase]: uppercase,
        [styles['title-gt']]: title,
        [styles[`lh-${lineHeight}`]]: lineHeight,
        [styles.icon]: icon,
        [styles[`${gutter}-gt`]]: !!gutter,
      })}
      {...rest}
    />
  );
}
