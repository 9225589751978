import React from 'react';
import { ModalDialog, DialogTitle } from '../../../../components/common/Dialogs';
import Typography from '../../../../components/common/Typography';
import { useTrans } from '../../../../services/i18n';
import { Button } from '../../../../components/common/Buttons';

import { Divider } from '../../../../components/common/Divider';

export default function InfoOffSavedSearchUpdatesEmailsModal({ open, onClose, onCancel }) {
  const { trans } = useTrans();
  return (
    <React.Fragment>
      <ModalDialog
        open={open}
        onClose={onClose}
        DialogTitle={
          <DialogTitle
            title={trans('turn-off-saved-search-updates-email-notifications')}
            onClose={onClose}
          ></DialogTitle>
        }
      >
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" weight="800">
            {trans('are-you-sure')}
          </Typography>
        </div>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <Typography variant="body2" weight="400">
            {trans('saved-search-off-info')}
          </Typography>
        </div>

        <div style={{ marginTop: '45px', marginBottom: '20px' }}>
          <Divider light />
        </div>

        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => {
              onClose();
              onCancel();
            }}
            style={{ marginRight: 15 }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={onClose} variant="contained" color="primary">
            Accept
          </Button>
        </div>
      </ModalDialog>
    </React.Fragment>
  );
}
