export const QUERY_QUICK_VIEW = 'qv';
export const QUERY_FAVORITES = 'my';
export const QUERY_BY_UBICATION = 'z';
export const QUERY_SAVED_SEARCH = 'ssId';
export const QUERY_SUGGESTED_SEARCH = 'sgsId';
export const QUERY_SHARED_SEARCH = 'shsId';
export const QUERY_PIPELINE = 'pipeline';
export const QUERY_TEXT = 'text';
export const QUERY_TASKS_COMPLETED = 'completed';
export const QUERY_TYPE = 'type';
export const QUERY_LOCAL_VENDORS = 'local-vendors';
export const QUERY_INNER_TYPE = 'inner-type';
export const QUERY_CUSTOM_FILTER = 'custom-filter';

export function getListQueries(query) {
  if (!query) return {};
  return {
    savedSearchId: query[QUERY_SAVED_SEARCH],
    sharedSearchId: query[QUERY_SHARED_SEARCH],
    suggestedSearchId: query[QUERY_SUGGESTED_SEARCH],
    isQuickView: query[QUERY_QUICK_VIEW] === 'y',
    isMy: query[QUERY_FAVORITES] === 'y',
    text: query[QUERY_TEXT],
    type: query[QUERY_TYPE],
    isLocalVendors: query[QUERY_LOCAL_VENDORS] === 'y',
    innerType: query[QUERY_INNER_TYPE],
    // This new parameter can be utilized to send a flag to the backend to trigger specific actions. When provided the posted date prompts the backend to include this new filter alongside others. This ensures that subsequent calls to 'fetchList' (for vendors, contract opportunities, grant opportunities, NSN opportunities, etc.) incorporate the new filter. It will be used in the emails when user clicks in the Saved Search link
    customFilter: query[QUERY_CUSTOM_FILTER], 
  };
}
