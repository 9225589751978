import { connect } from 'react-redux';
import { actions } from '../../../../store/pipelines';
import { subscriptionActions } from '../../../../store/subscription';

function mapStateToProps(state) {
  return {
    userPipelines: state.pipelines ? state.pipelines.userPipelines : [],
    subscription: state.subscription,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPipelines: (params) => dispatch(actions.getPipelines(params)),
    addPursuitToPipeline: (params) => dispatch(actions.addPursuitToPipeline(params)),
    addPipeline: (params) => dispatch(actions.addPipeline(params)),
    getTagsByUser: (params) => dispatch(actions.getTagsByUser(params)),
    setOpenModalPremium: (payload) => dispatch(subscriptionActions.setOpenModalPremium(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
