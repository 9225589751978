import React from 'react';
import { emptyArray } from '../../../../utils/arrayUtils';
import { openEventFiles } from '../../../../utils/filesUtils';

export default function useUploadFiles({ value, onChange, name = 'files', multiple = false }) {
  const handleChange = React.useCallback(
    (value) => {
      if (typeof onChange !== 'function') return;
      onChange({ target: { name, value } });
    },
    [name, onChange]
  );

  const handleInputChange = React.useCallback(
    (event) => {
      const files = openEventFiles(event);
      handleChange(multiple ? files : files[0]);
    },
    [handleChange, multiple]
  );

  const handleDelete = React.useCallback(
    (index) => {
      if (!multiple) return handleChange(null);

      const values = !emptyArray(value) ? value : [];
      values.splice(index, 1);
      handleChange(values);
    },
    [handleChange, multiple, value]
  );

  return { handleChange, handleInputChange, handleDelete };
}
