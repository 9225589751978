import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';
import { config } from '../../services/api';
import { download } from '../../utils/filesUtils';

export const INITIAL_STATE = {
  fileRequests: [],
  manageFiles: [],
  pending: false,
  breadcrumbs: [],
  loadingOpenFolder: false,
  currentBreadcrumb: 'my-documents',
};

const getDocuments = createAsyncThunk('business/getDocuments', (params) => {
  return application.call(API.BUSINESS.GET_DOCUMENTS, params);
});
function onFetchDocuments(state, action) {
  Object.assign(state, {
    manageFiles: action.payload.items,
    pending: false,
  });
}

const getFileRequests = createAsyncThunk('business/getFileRequests', (params) => {
  return application.call(API.BUSINESS.GET_DOCUMENTS, params);
});
function onFetchFileRequests(state, action) {
  Object.assign(state, {
    fileRequests: action.payload.items,
    pending: false,
  });
}

const openFolder = createAction('business/openFolder');

const getFilesInFolder = createAsyncThunk('business/getFilesInFolder', (params) => {
  return application.call(API.BUSINESS.GET_FILES_IN_FOLDER, { folder: params.value });
});
function onFullOpenFolder(state, action) {
  Object.assign(state, {
    manageFiles: action.payload.items,
    loadingOpenFolder: false,
  });
}

const downloadFile = createAsyncThunk('business/downloadFile', (params) => {
  return fetch(`${config.apiHost}/download-file-drive?fileId=${params.fileId}`)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      download(blob, params.name);
    });
});

const uploadFile = createAsyncThunk('business/uploadFile', (params) => {
  return fetch(`${config.apiHost}/upload-file-drive`, {
    method: 'POST',
    body: params,
  });
});

const uploadNewVersionFile = createAsyncThunk('business/uploadVersionFile', (params) => {
  return fetch(`${config.apiHost}/upload-version-file-drive`, {
    method: 'POST',
    body: params,
  });
});

const createTeaming = createAsyncThunk('business/createTeaming', (params) => {
  return fetch(`${config.apiHost}/create-teaming`, {
    method: 'POST',
    body: params,
  });
});

const backupToFileReqVersion = createAsyncThunk('business/backupToFileReqVersion', (params) => {
  return application.call(API.BUSINESS.BACKUP_FILE_REQ_VERSION, params);
});

export const actions = {
  getDocuments,
  getFileRequests,
  openFolder,
  getFilesInFolder,
  downloadFile,
  uploadFile,
  uploadNewVersionFile,
  backupToFileReqVersion,
  createTeaming,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getDocuments.fulfilled, onFetchDocuments)
    .addCase(getFileRequests.fulfilled, onFetchFileRequests)
    .addCase(getDocuments.pending, (state) => Object.assign(state, { pending: true, breadcrumbs: [] }))
    .addCase(getDocuments.rejected, (state) => Object.assign(state, { pending: false }))
    .addCase(getFileRequests.pending, (state) => Object.assign(state, { pending: true }))
    .addCase(getFileRequests.rejected, (state) => Object.assign(state, { pending: false }))
    .addCase(openFolder, (state, action) => {
      const copyBreadcrumbs = [...state.breadcrumbs];
      const exists = copyBreadcrumbs.find((i) => i.value === action.payload.value);
      if (!exists) copyBreadcrumbs.push(action.payload);
      Object.assign(state, {
        breadcrumbs: [...copyBreadcrumbs],
        currentBreadcrumb: action.payload.value,
      });
    })
    .addCase(getFilesInFolder.pending, (state) => Object.assign(state, { loadingOpenFolder: true }))
    .addCase(getFilesInFolder.rejected, (state) => Object.assign(state, { loadingOpenFolder: false }))
    .addCase(getFilesInFolder.fulfilled, onFullOpenFolder)
    .addCase(uploadFile.fulfilled, (state, action) => {});
});
