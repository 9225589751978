import { formatNumberToUsd } from './helpers';

export const TIERS = {
  LIMITED: 'limited',
  ADVANTAGE: 'advantage',
  PRO: 'pro',
  FREE_TRIAL: 'freeTrial',
  SAP_ADVANTAGE: 'sapAdvantage',
  APP_MAX: 'appMax',
  PROCUREMENT_PRO: 'procurementPro',
};

function getPrice(products, tier) {
  if (tier === TIERS.LIMITED) {
    return 'Free';
  }

  if (tier === TIERS.FREE_TRIAL) {
    return '14 Days Free';
  }

  if (tier === TIERS.SAP_ADVANTAGE || tier === TIERS.PROCUREMENT_PRO) {
    return 'for-pricing-call';
  }

  const product = products?.[tier];
  if (!product) {
    return '';
  }

  if (product.prices) {
    return product.prices?.map((price) => formatNumberToUsd(price.amount ?? 0, 0));
  }

  return products?.[tier]?.amount ? formatNumberToUsd(products?.[tier]?.amount ?? 0, 0) : '';
}

export const STANDARD_PLANS_INFORMATION = [
  {
    name: 'Limited',
    getPrice: () => getPrice(null, TIERS.LIMITED),
    benefits: [
      { title: 'Search Open Opportunities', subs: ['No Attachments'] },
      { title: '1 User' },
      { title: 'Access to Level 1 Videos' },
    ],
    subscriptionTier: TIERS.LIMITED,
  },
  {
    name: 'Advantage',
    getPrice: (products) => getPrice(products, TIERS.ADVANTAGE),
    benefits: [
      { title: 'Search Open Opportunities', subs: ['No Attachments'] },
      { title: '1 User' },
      { title: 'Contractor Success Portal (SAM Filing Assistance with Verified Vendor Seal)' },
      { title: 'Access to Level 2 Videos' },
    ],
    subscriptionTier: TIERS.ADVANTAGE,
  },
  {
    name: 'Pro',
    getPrice: (products) => getPrice(products, TIERS.PRO),
    benefits: [
      { title: 'Full Search' },
      { title: '5 Saved Searches' },
      { title: '2 Users' },
      { title: 'Contractor Success Portal (SAM Filing Assistance with Verified Vendor Seal)' },
      { title: '1 SAM Maintenance Update' },
      { title: 'Access to Level 2 Videos' },
    ],
    subscriptionTier: TIERS.PRO,
  },
];

export const ADVANCED_PLANS_INFORMATION = [
  {
    name: 'SAP Advantage',
    getPrice: (products) => getPrice(products, TIERS.SAP_ADVANTAGE),
    benefits: [
      { title: 'Full Search' },
      { title: '5 Saved Searches' },
      { title: '2 Users' },
      { title: 'Contractor Success Portal (SAM Filing Assistance with Verified Vendor Seal)' },
      { title: '1 SAM Maintenance Update', secondCol: true },
      { title: 'Access to Level 3 Videos', secondCol: true },
      { title: 'SAP Program', secondCol: true },
      { title: 'Set-Aside Qualification', secondCol: true },
      { title: 'Assigned Case Manager', secondCol: true },
    ],
    subscriptionTier: TIERS.SAP_ADVANTAGE,
    canSubscribe: false,
  },
  {
    name: 'APP Max',
    getPrice: (products) => getPrice(products, TIERS.APP_MAX),
    benefits: [
      { title: 'Full Search' },
      { title: 'Unlimited Saved Searches' },
      { title: '2 Users' },
      { title: 'Access to Level 1 Videos' },
      { title: '3,000 Exports Per Month', secondCol: true },
      { title: 'Pursuits, Pipelines, Tasks', secondCol: true },
      { title: 'Agent Finder', secondCol: true },
    ],
    subscriptionTier: TIERS.APP_MAX,
    canSubscribe: true,
  },
  {
    name: 'Procurement Pro',
    getPrice: (products) => getPrice(products, TIERS.PROCUREMENT_PRO),
    benefits: [
      { title: 'Full Search' },
      { title: 'Unlimited Saved Searches' },
      { title: '2 Users' },
      { title: 'Contractor Success Portal (SAM Filing Assistance with Verified Vendor Seal)' },
      { title: 'Unlimited SAM Maintenance Update' },
      { title: 'Access to Level 3 Videos', secondCol: true },
      { title: 'SAP Program', secondCol: true },
      { title: 'Set-Aside Qualification', secondCol: true },
      { title: 'Assigned Case Manager', secondCol: true },
      { title: '3,000 Exports Per Month', secondCol: true },
      { title: 'Pursuits, Pipelines, Tasks', secondCol: true },
      { title: 'Agent Finder', secondCol: true },
    ],
    subscriptionTier: TIERS.PROCUREMENT_PRO,
    canSubscribe: false,
  },
];

export const FREE_TRIAL_PLAN = {
  name: 'Free Trial - Pro',
  getPrice: (products) => getPrice(products, TIERS.FREE_TRIAL),
  benefits: [
    { title: 'Full Search' },
    { title: 'Unlimited Saved Searches' },
    { title: '1 User' },
    { title: 'Access to Level 1 Videos' },
  ],
  subscriptionTier: TIERS.FREE_TRIAL,
};

export const AVAILABLE_PLANS_INFORMATION = [
  ...STANDARD_PLANS_INFORMATION,
  FREE_TRIAL_PLAN,
  ...ADVANCED_PLANS_INFORMATION,
];

export const getBenefitsByTier = (tier) => {
  const tierBenefits = AVAILABLE_PLANS_INFORMATION.find((plan) => plan.subscriptionTier === tier)?.benefits;
  const mappedBenefits =
    tierBenefits && tierBenefits.map((benefit) => ({ name: benefit.title, available: true, subs: benefit.subs }));
  return mappedBenefits ?? [];
};
