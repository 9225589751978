import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useMediaQueryDown } from '../../../../../../hooks/useMediaQuery';
import { useTrans } from '../../../../../../services/i18n';
import { Chip } from '../../../../../common/Chip';
import { Paper } from '../../../../../common/Containers';
import { SpacingContainer } from '../../../../../common/Containers/Base';
import { CheckBox } from '../../../../../common/Inputs';
import Typography from '../../../../../common/Typography';
import {
  getActionByItem,
  getButtonTextByItemType,
  getChipBGByItemType,
  getChipBGByNotInterested,
  getChipBorderByNotInterested,
  getChipColorByItemType,
  getChipColorByNotInterested,
  getDescriptionByItem,
  getDescriptionColorByItemType,
  getSubtitleByItem,
  getTitleByItem,
  hasSubtitleByItemType,
} from '../helpers';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../common/Buttons';
import dayjs from 'dayjs';

export default function NotificationSmallCard({
  item,
  onClick,
  onCheckItem,
  checked = false,
  chosen = false,
  openSelected = false,
  ...rest
}) {
  const { trans } = useTrans();
  const isSmScreen = useMediaQueryDown('sm');
  const isUnread = !(item && item.read);
  const history = useHistory();

  return !item ? null : (
    <Paper
      component={SpacingContainer}
      px={isSmScreen ? 2 : 3}
      py={2}
      bgColorHover={chosen ? 'primary' : 'active'}
      square
    >
      <Grid container alignItems={openSelected || isSmScreen ? 'flex-start' : 'center'} spacing={2} wrap="nowrap">
        <Grid item xs="auto">
          <CheckBox
            checked={checked}
            onChange={onCheckItem ? onCheckItem.bind(null, item) : undefined}
            dense
            color="secondary"
          />
        </Grid>

        <Grid
          onClick={() => {
            item?.details?.linkTo && history.push(item.details.linkTo);
          }}
          className="pointer"
          item
          xs
          zeroMinWidth
          {...rest}
        >
          <Grid container spacing={openSelected || isSmScreen ? 1 : 2}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              justifyContent="center"
              alignItems="start"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Typography
                variant="body2"
                color={chosen ? 'white' : isUnread ? 'primary' : 'label'}
                weight={isUnread ? '600' : '400'}
                style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {getTitleByItem(item)}
              </Typography>
              {hasSubtitleByItemType(item.itemType) && (
                <Typography
                  fontFamily="manrope"
                  weight="400"
                  style={{
                    maxWidth: '100%',
                    fontSize: '0.688rem',
                    color: '#B2B8CF',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {getSubtitleByItem(item)}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={openSelected || isSmScreen ? 12 : true}
              // zeroMinWidth
              container
              justifyContent="flex-end"
              alignItems="center"
              // className={openSelected || isSmScreen ? 'order1' : undefined}
            >
              <Typography
                variant="caption"
                weight="600"
                noWrap
                style={{ color: chosen ? 'gray' : getDescriptionColorByItemType(item.itemType) }}
              >
                {getDescriptionByItem(item)}
              </Typography>
            </Grid>

            <Grid item xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
              <Chip
                style={{
                  backgroundColor: getChipBGByItemType(item?.itemType),
                  color: getChipColorByItemType(item?.itemType),
                  borderRadius: '0.4rem',
                  fontWeight: 600,
                }}
                label={item?.itemType || trans('not-provided')}
                size={isSmScreen ? 'small' : 'medium'}
              />
            </Grid>

            <Grid item xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  const redirectUrl = getActionByItem(item);
                  redirectUrl && history.push(redirectUrl);
                }}
                size="small"
                style={{
                  backgroundColor: getChipBGByNotInterested(item),
                  color: getChipColorByNotInterested(item),
                  border: getChipBorderByNotInterested(item),
                  borderRadius: '0.4rem',
                  fontWeight: 600,
                  zIndex: 1,
                  display: item?.itemType ? 'block' : 'none',
                }}
              >
                {trans(getButtonTextByItemType(item?.itemType))}
              </Button>
            </Grid>

            {/* <Grid item xs="auto" container alignItems="center">
              <Typography variant="body2" color={chosen ? 'white' : 'hint'}>
                {dayjs(item.itemLastUpdated ?? item.updatedAt)
                  .format(TIME_FORMAT)
                  .toLowerCase()}
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
