import Backdrop from '@material-ui/core/Backdrop';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.tooltip + 50,
    backgroundColor: theme.palette.action.light,
  },
  absolute: {
    position: 'absolute',
  },
}));

export default function Loader({ open = false, fixed = false }) {
  const styles = useStyles();

  return (
    <Backdrop className={clsx(styles.root, !fixed && styles.absolute)} open={open}>
      <div className="spinner-interwind">
        <div className="spinner-cover">
          <div>
            <div>
              <div>
                <div></div>
              </div>
            </div>
            <div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}
