import { Button } from '@material-ui/core';
import React from 'react';
import { useTrans } from '../../../../../services/i18n';
import SaveTaskForm from '../../../../../components/forms/SaveTaskForm';
import { useTasksActions } from '../hooks/useTasksActions';

// Template component
function TasksActions({ item }) {
  const { trans } = useTrans();

  const { itemTask, open, onOpen, onClose, onEditTask, searchTags } = useTasksActions(item);

  if (!itemTask) {
    return <></>;
  }

  return (
    <>
      {item.isOwner && (
        <Button color="primary" variant="contained" onClick={onOpen}>
          {trans('edit')}
        </Button>
      )}

      {open && (
        <SaveTaskForm
          open={open}
          searchTags={searchTags}
          onClose={onClose}
          onEditTask={onEditTask}
          item={itemTask}
          formShowOptionsPursuit={true}
        />
      )}
    </>
  );
}

export default TasksActions;
