import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  '10pt': { paddingTop: theme.spacing(10) },
  '7pt': { paddingTop: theme.spacing(7) },
  '5pt': { paddingTop: theme.spacing(5) },
  '4pt': { paddingTop: theme.spacing(4) },
  '3pt': { paddingTop: theme.spacing(3) },
  '2pt': { paddingTop: theme.spacing(2) },
  '1pt': { paddingTop: theme.spacing(1) },
  '5sm3pt': {
    paddingTop: theme.spacing(5),

    [theme.breakpoints.down('sm')]: { paddingTop: theme.spacing(3) },
  },

  '10pb': { paddingBottom: theme.spacing(10) },
  '7pb': { paddingBottom: theme.spacing(7) },
  '5pb': { paddingBottom: theme.spacing(5) },
  '4pb': { paddingBottom: theme.spacing(4) },
  '3pb': { paddingBottom: theme.spacing(3) },
  '2pb': { paddingBottom: theme.spacing(2) },
  '1pb': { paddingBottom: theme.spacing(1) },
  '5sm3pb': {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: { paddingBottom: theme.spacing(3) },
  },

  '7pl': { paddingLeft: theme.spacing(7) },
  '6pl': { paddingLeft: theme.spacing(6) },
  '5pl': { paddingLeft: theme.spacing(5) },
  '4pl': { paddingLeft: theme.spacing(4) },
  '3pl': { paddingLeft: theme.spacing(3) },
  '2pl': { paddingLeft: theme.spacing(2) },
  '1pl': { paddingLeft: theme.spacing(1) },
  '5sm3pl': {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: { paddingLeft: theme.spacing(3) },
  },
  '4sm3pl': {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { paddingLeft: theme.spacing(3) },
  },
  '4sm2pl': {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { paddingLeft: theme.spacing(2) },
  },

  '7pr': { paddingRight: theme.spacing(7) },
  '6pr': { paddingRight: theme.spacing(6) },
  '5pr': { paddingRight: theme.spacing(5) },
  '4pr': { paddingRight: theme.spacing(4) },
  '3pr': { paddingRight: theme.spacing(3) },
  '2pr': { paddingRight: theme.spacing(2) },
  '1pr': { paddingRight: theme.spacing(1) },
  '5sm3pr': {
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: { paddingRight: theme.spacing(3) },
  },
  '4sm3pr': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { paddingRight: theme.spacing(3) },
  },
  '4sm2pr': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { paddingRight: theme.spacing(2) },
  },
}));

const SpacingContainerCustom = React.forwardRef(
  ({ className, component, pt = 0, pb = 0, pl = 0, pr = 0, ...rest }, ref) => {
    const Component = component ? component : 'div';
    const styles = useStyles();

    return (
      <Component
        ref={ref}
        className={clsx(
          className,
          styles[`${pt}pt`],
          styles[`${pb}pb`],
          styles[`${pl}pl`],
          styles[`${pr}pr`],
          component === 'ul' && 'app-ul'
        )}
        {...rest}
      />
    );
  }
);

export default SpacingContainerCustom;
