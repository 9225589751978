import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import * as Yup from 'yup';
import ModalDialog from '../../../../components/common/Dialogs/ModalDialog';
import SaveCancelDialogActions from '../../../../components/common/Dialogs/SaveCancelDialogActions';
import { SelectField, TextField } from '../../../../components/common/Inputs';
import Typography from '../../../../components/common/Typography';
import { USER_ROLES } from '../../../../constants/user';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import { useForm } from '../../../../hooks/useForm';
import { useTrans } from '../../../../services/i18n';
import { schemaToInitialState } from '../../../../utils/validationUtils';
import connect from '../../connect';
import { useAlertContext } from '../../../../contexts/AlertContext';
import { useComponentPermission } from '../../../../hooks/useComponentPermission';
import { COMPONENT_FEATURES } from '../../../../utils/constants';

const useScheme = makeValidationSchema((trans) => {
  return Yup.object().shape({
    //name: Yup.string().required(trans('empty-not-allowed', { field: trans('name') })),
    name: Yup.string().required(trans('empty-not-allowed', { field: trans('name') })),
    familyName: Yup.string().required(trans('empty-not-allowed', { field: trans('lastName') })),
    email: Yup.string()
      .email(trans('incorrect-email'))
      .required(trans('empty-not-allowed', { field: trans('email') })),
    role: Yup.string().required(trans('empty-not-allowed', { field: trans('role') })),
    username: Yup.string(),
    organizationId: Yup.string(),
  });
});

function UserModal({ addUser, editUser, user, onClose, open = false, getOrganizationInfo }) {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert } = useAlertContext();
  const [pending, setPending] = React.useState(false);
  const { getPermissions } = useComponentPermission(COMPONENT_FEATURES.USERS);

  const onSubmit = async (values) => {
    setPending(true);
    addUser(values)
      .then((res) => {
        if (res.payload) {
          if (res.payload.hasOwnProperty('success') && !res.payload.success) {
            errorAlert(trans(res.payload.message));
          } else {
            successAlert('User has been invite to this organization');
            resetForm();
            typeof onClose === 'function' && onClose();
          }
        }
        getPermissions();
        getOrganizationInfo();
      })
      .catch(errorAlert)
      .finally(() => setPending(false));
  };

  const onEdit = React.useCallback(
    (values) => {
      editUser(values, user.userName, user.organizationId);
      typeof onClose === 'function' && onClose();
    },
    [editUser, onClose, user]
  );

  const { handleSubmit, resetForm, getFieldProps } = useForm(
    schema,
    user || schemaToInitialState(schema),
    user ? onEdit : onSubmit,
    { validateOnChange: false, validateOnBlur: false }
  );

  const handleCancel = React.useCallback(() => {
    resetForm();
    typeof onClose === 'function' && onClose();
  }, [onClose, resetForm]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans(user ? 'edit-user' : 'invite-user')}
      actions={
        <SaveCancelDialogActions
          formId="invite-user"
          onCancelClick={handleCancel}
          onSaveClick={handleSubmit}
          pending={pending}
        />
      }
    >
      <Grid container spacing={3}>
        {!user && (
          <>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                label={trans('first-name')}
                placeholder={trans('required')}
                required
                maxLength={255}
                {...getFieldProps('name')}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <TextField
                label={trans('last-name')}
                placeholder={trans('required')}
                required
                maxLength={255}
                {...getFieldProps('familyName')}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label={trans('email')}
                type="text"
                placeholder={trans('required')}
                {...getFieldProps('email')}
                required
              />
            </Grid>
          </>
        )}
        <Grid item sm={user ? 12 : 6} xs={12}>
          <Typography weight="500" fontFamily="lexend" color="label" variant="body2">
            {trans('role')}
          </Typography>
          <SelectField
            style={{ marginTop: '7px' }}
            type="select"
            {...getFieldProps('role')}
            placeholder={trans('required')}
          >
            {USER_ROLES.map((item, ind) => (
              <MenuItem key={ind} value={item.value}>
                {trans(item.i18nKey)}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      </Grid>
    </ModalDialog>
  );
}

export default connect(UserModal);
