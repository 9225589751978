import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconOrganizationTeaming = (props) => {
  const { width = 64, height = 64 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none">
      <path
        stroke="#B2B8CF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M50.667 24c0 3.867-1.147 7.413-3.12 10.373-2.88 4.267-7.44 7.28-12.747 8.054-.907.16-1.84.24-2.8.24-.96 0-1.893-.08-2.8-.24-5.307-.774-9.867-3.787-12.747-8.054-1.973-2.96-3.12-6.506-3.12-10.373C13.333 13.68 21.68 5.333 32 5.333c10.32 0 18.667 8.347 18.667 18.667Z"
      />
      <path
        stroke="#B2B8CF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m56.667 49.253-4.4 1.04c-.987.24-1.76.987-1.974 1.974l-.933 3.92c-.507 2.133-3.227 2.773-4.64 1.093L32 42.667l-12.72 14.64c-1.413 1.68-4.133 1.04-4.64-1.094l-.933-3.92c-.24-.986-1.014-1.76-1.974-1.973l-4.4-1.04c-2.026-.48-2.746-3.013-1.28-4.48l10.4-10.4c2.88 4.267 7.44 7.28 12.747 8.053.907.16 1.84.24 2.8.24.96 0 1.893-.08 2.8-.24 5.307-.773 9.867-3.786 12.747-8.053l10.4 10.4c1.466 1.44.746 3.973-1.28 4.453ZM33.547 15.947l1.573 3.146c.213.427.773.854 1.28.934l2.853.48c1.814.293 2.24 1.626.934 2.933l-2.214 2.213c-.373.374-.586 1.094-.453 1.627l.64 2.747c.507 2.16-.64 3.013-2.56 1.866l-2.667-1.573c-.48-.293-1.28-.293-1.76 0l-2.666 1.573c-1.92 1.12-3.067.294-2.56-1.866l.64-2.747c.106-.507-.08-1.253-.454-1.627L23.92 23.44c-1.307-1.307-.88-2.613.933-2.933l2.854-.48c.48-.08 1.04-.507 1.253-.934l1.573-3.146c.774-1.707 2.16-1.707 3.014 0Z"
      />
    </svg>
  );
};

IconOrganizationTeaming.propTypes = simpleIconPropsTypes;
