import colors from '../pallete';

const MuiTimeline = {
  MuiTimeline: {
    root: {
      padding: 0,
      marginBottom: 0,
    },
  },
  MuiTimelineItem: {
    root: {
      padding: 0,
    },
    missingOppositeContent: {
      '&:before': {
        display: 'none',
      },
    },
  },
  MuiTimelineContent: {
    root: {
      paddingTop: 0,
    },
  },
  MuiTimelineConnector: {
    root: {
      backgroundColor: colors.gray.divider,
    },
  },
  MuiTimelineDot: {
    root: {
      padding: 0,
      borderWidth: 0,
      boxShadow: 'none',
    },
    defaultSecondary: {
      backgroundColor: colors.common.white,
      borderWidth: 8,
      borderColor: colors.secondary.main,
      padding: 7,
    },
    defaultPrimary: {
      backgroundColor: colors.primary.dark,
      borderWidth: 7,
      boxShadow: `0 0 0 2px rgba(36, 69, 167, 0.4)`,
      borderColor: colors.common.white,
      padding: 7,
      marginLeft: 1,
    },
  },
};

export default MuiTimeline;
