import React from 'react';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';

export default function useSearchField({ value, onValueChange, freeSolo = false }) {
  const [text, setText] = React.useState(value || '');
  const [focused, onFocus, onBlur] = useBooleanHandlers();
  const changed = text !== value;
  const isFocused = focused || (!!text && changed);

  React.useEffect(() => setText(value), [value]);

  const onSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      //if (!freeSolo && !changed) return;

      onValueChange && onValueChange(text);
    },
    [text, onValueChange, changed, freeSolo]
  );

  const onClearSearch = React.useCallback(() => onValueChange && onValueChange(''), [onValueChange]);

  const onChange = React.useCallback((e) => setText(e.target.value), []);

  return { text, changed, isFocused, onFocus, onBlur, onSubmit, onClearSearch, onChange };
}
