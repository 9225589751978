import React, { useState } from 'react';
import { useTrans } from '../../../services/i18n';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import { Divider, Grid } from '@material-ui/core';
import { TextField } from '../../../components/common/Inputs';
import * as Yup from 'yup';
import { useForm } from '../../../hooks/useForm';
import { catchYupErrors, schemaToInitialState } from '../../../utils/validationUtils';
import { AmplifyAuth } from '../../../services/amplifyAuth';
import Typography from '../../../components/common/Typography';
import { application } from '../../../services/application';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SaveDialogAction from '../../../components/common/Dialogs/SaveDialogAction';
import { useAlertContext } from '../../../contexts/AlertContext';

const useStyles = makeStyles((theme) => ({
  containerCard: {
    padding: '2.125rem 1.5rem',
  },
  containerFooter: {
    padding: '1.87rem 2.3rem',
    marginTop: 'unset',
  },
}));

export default function ChangePassword(props) {
  const { trans } = useTrans();
  const [open, onOpen, onClose] = useBooleanHandlers();
  const [pending, setPending] = useState(false);
  const styles = useStyles();
  const { successAlert } = useAlertContext();

  const schema = Yup.object().shape({
    oldPassword: Yup.string()
      .password(trans('incorrect-password'))
      .required(trans('empty-not-allowed', { field: trans('current-password') })),
    password: Yup.string()
      .password(trans('incorrect-password'))
      .required(trans('empty-not-allowed', { field: trans('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], trans('passwords-match'))
      .required(trans('empty-not-allowed', { field: trans('confirm-password') })),
  });

  const sendNotification = async () => {
    await application.call('user.notifyChangePassword', {});
  };

  const handleSubmit = React.useCallback((values, { setStatus, setErrors }) => {
    setPending(true);
    AmplifyAuth.currentAuthenticatedUser()
      .then((user) => AmplifyAuth.changePassword(user, values.oldPassword, values.password))
      .then(() => {
        successAlert('Password changed successfully');
        cancelChange();
        sendNotification();
      })
      .finally(() => setPending(false))
      .catch((e) => catchYupErrors(e, setErrors, (err) => setStatus({ message: err.message })));
  }, []);

  const formik = useForm(schema, schemaToInitialState(schema), handleSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  });

  const cancelChange = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} className={styles.containerCard}>
        {formik.status?.message && (
          <Grid item xs={12}>
            <Typography color="error">{formik.status?.message}</Typography>
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <TextField
            label={trans('current-password')}
            type="password"
            placeholder={trans('current-password')}
            {...formik.getFieldProps('oldPassword')}
            required
          />
        </Grid>
        <Grid item sm={6} xs={12} />
        <Grid item sm={6} xs={12}>
          <TextField
            label={trans('new-password')}
            type="password"
            placeholder={trans('new-password')}
            {...formik.getFieldProps('password')}
            required
            name="password"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="password"
            label={trans('confirm-password')}
            placeholder={trans('confirm-password')}
            {...formik.getFieldProps('confirmPassword')}
            required
          />
        </Grid>
      </Grid>
      <Divider light spacing={1} />
      <Grid container spacing={3} className={styles.containerFooter}>
        <SaveDialogAction formId="change-password-edit" onSaveClick={formik.handleSubmit} pending={pending} />
      </Grid>
    </React.Fragment>
  );
}
