import React from 'react';
import { SvgIcon } from '../../../../common/SvgIcon';

function VideoIcon({ width = 48, height = 24 }) {
  return (
    <SvgIcon width={width} height={height}>
      <svg width="48" height="24" viewBox="0 0 48 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21 22H27C32 22 34 20 34 15V9C34 4 32 2 27 2H21C16 2 14 4 14 9V15C14 20 16 22 21 22Z"
          stroke="#17B471"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.0996 12V10.52C21.0996 8.60999 22.4496 7.83999 24.0996 8.78999L25.3796 9.52999L26.6596 10.27C28.3096 11.22 28.3096 12.78 26.6596 13.73L25.3796 14.47L24.0996 15.21C22.4496 16.16 21.0996 15.38 21.0996 13.48V12Z"
          stroke="#17B471"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default VideoIcon;
