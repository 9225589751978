import React from 'react';
import VendorItemManagementDetailFields from '../../../../VendorItem/ManagementDetailFields';
import VendorHeader from '../../VendorHeader';
import { SpacingContainer, Paper } from '../../../../../../components/common/Containers';

const GeneralInfo = ({ item }) => {
  return (
    <Paper hidden>
      <VendorHeader item={item} />
      <SpacingContainer px="5sm3" py={5}>
        <VendorItemManagementDetailFields item={item} />
      </SpacingContainer>
    </Paper>
  );
};

export default GeneralInfo;
