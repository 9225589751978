import colors from '../pallete';
import typography from '../typography';
import MuiAlert from './MuiAlert';
import MuiAutocomplete from './MuiAutocomplete';
import MuiAvatar from './MuiAvatar';
import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDialog from './MuiDialog';
import MuiFilledInput from './MuiFilledInput';
import MuiGrid from './MuiGrid';
import MuiSelect from './MuiSelect';
import MuiTable from './MuiTable';
import MuiTabs from './MuiTabs';
import MuiToggleButton from './MuiToggleButton';
import MuiTypography from './MuiTypography';
import MuiTimeline from './TimeLine';

const overrides = {
  MuiCssBaseline: MuiCssBaseline,
  MuiAvatar: MuiAvatar,
  MuiButton: MuiButton,
  MuiFilledInput: MuiFilledInput,
  MuiSelect: MuiSelect,
  MuiAutocomplete: MuiAutocomplete,
  MuiTypography: MuiTypography,
  MuiChip: MuiChip,
  MuiAlert: MuiAlert,
  MuiGrid: MuiGrid,
  ...MuiTimeline,
  ...MuiTable,
  ...MuiTabs,
  ...MuiToggleButton,
  ...MuiDialog,

  MuiLink: {
    root: {
      color: colors.primary.main,
      outline: 'none',
      cursor: 'pointer',
    },
    button: { fontSize: 'inherit' },
  },
  MuiSvgIcon: {
    root: {
      verticalAlign: 'top',
      '&.autoSize': { width: 'auto', height: 'auto' },
    },
  },
  MuiDivider: {
    light: {
      backgroundColor: colors.gray.divider,
    },
  },

  MuiInputBase: {
    root: { '&$disabled': { color: undefined } },
  },
  MuiRadio: {
    colorSecondary: {
      color: colors.gray.light,
      '&$checked': {
        color: colors.secondary.main,
      },
    },
  },
  MuiFormControlLabel: {
    label: { fontSize: typography.body2.fontSize },
  },
  MuiFormHelperText: {
    root: { margin: '0 !important', marginTop: '6px !important', lineHeight: 1.5 },
  },

  MuiPopover: {
    paper: {
      backgroundColor: colors.common.white,
      borderRadius: 8,
      minWidth: 160,
      padding: '5px 0',
      '.densePopover &': { padding: 0 },
    },
  },
  MuiPaper: {
    elevation1: { boxShadow: '0px 2px 5px 3px rgba(0,0,0,0.05)' },
  },
  MuiIconButton: {
    root: {
      color: colors.gray.main,
    },
  },
  MuiSkeleton: {
    root: { borderRadius: 4 },
    text: { borderRadius: 4 },
  },
  MuiTooltip: {
    tooltip: {
      padding: '16px 20px',
      color: colors.common.black,
      backgroundColor: colors.common.white,
      borderRadius: 8,
      maxWidth: 500,
      whiteSpace: 'normal',
      boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.14)',
      '&.small': {
        padding: '10px 15px',
        borderRadius: 4,
      },
    },
    arrow: {
      color: colors.common.white,
    },
  },

  MuiListItemIcon: {
    root: { color: undefined },
  },
};

export default overrides;
