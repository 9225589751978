import MUITabs from '@material-ui/core/Tabs';
import clsx from 'clsx';
import React from 'react';

export default function Tabs({
  children,
  className,
  scrollButtons = 'auto',
  variant = 'scrollable',
  textColor = 'secondary',
  indicatorColor = 'secondary',
  indicatorFilled = false,
  ...rest
}) {
  const isCustomIndicatorColor = indicatorColor === 'inherit';

  return (
    <MUITabs
      className={clsx(
        className,
        indicatorFilled && 'indicator-filled',
        isCustomIndicatorColor && `indicator-${indicatorColor}`
      )}
      indicatorColor={isCustomIndicatorColor ? 'secondary' : indicatorColor}
      scrollButtons={scrollButtons}
      variant={variant}
      textColor={textColor}
      {...rest}
    >
      {children}
    </MUITabs>
  );
}
