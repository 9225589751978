import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_CONTRACT_AWARDS, ROUTE_CONTRACT_AWARDS_DETAILS, SCOPE_CONTRACT_AWARDS } from '../../constants';
import { AddToPipelineConsumer, AddToPipelineProvider } from '../../contexts/AddToPipelineContext';
import Suspense from '../../services/lazyLoad/Suspense';

const ContractAwards = React.lazy(() => import('./ContractAwards'));
const ContractAwardsDetails = React.lazy(() => import('./ContractAwardDetails'));

export default function ContractAwardsWrapper() {
  return (
    <Suspense>
      <AddToPipelineProvider scope={SCOPE_CONTRACT_AWARDS}>
        <Switch>
          <Route exact path={ROUTE_CONTRACT_AWARDS_DETAILS.path}>
            <ContractAwardsDetails />
          </Route>
          <Route exact path={ROUTE_CONTRACT_AWARDS.path + '/:duns?/:name?'}>
            <ContractAwards />
          </Route>
          <Redirect exact path="/*" to={ROUTE_CONTRACT_AWARDS.path} />
        </Switch>

        <AddToPipelineConsumer />
      </AddToPipelineProvider>
    </Suspense>
  );
}
