import { intlDateFormat } from '../../utils/dateUtils';

export const V_ANCHORS = [
  { id: 'summary', i18nKey: 'summary' },
  { id: 'location', i18nKey: 'location' },
  { id: 'contacts', i18nKey: 'contacts' },
];

export function getContractAwardId(item) {
  return item && [item.referencedIDVId, item.awardContractId].filter((v) => !!v).join('-');
}

export function nameTypeContact(typeContact) {
  let type = 'Unrecognized';
  if (['governmentBusinessPOC', 'governmentBusinessAlternatePOC'].includes(typeContact)) type = 'Government';
  if (['electronicBusinessPOC', 'electronicBusinessAlternatePOC'].includes(typeContact)) type = 'Electronic';
  if (['pastPerformancePOC', 'pastPerformanceAlternatePOC'].includes(typeContact)) type = 'Performance';

  return type;
}

export const generateLocationShort = (item) =>
  !item.city ? '-' : `${item.city}${item.country ? ', ' + item.country : ''}`;

export const generateLocation = (item) => {
  if (!item.city) return '';

  return [
    item.street || item.addressLine1,
    item.city,
    `${item.state || item.stateOrProvinceCode || ''} ${item.zipCode || ''}`.trim(),
    item.country || item.countryCode,
  ]
    .filter((val) => !!val)
    .join(', ');
};

export const orderOptions = [
  { name: 'alphabetical', value: 'alphabetical', asc: 'A-Z', desc: 'Z-A' },
  { name: 'RegistrationDate', value: 'registrationDate', asc: 'oldest-first', desc: 'newest-first' },
];

export const getRegistrationDate = (registrationDate) => {
  const now = new Date();
  const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

  return currentDate.toISOString() > registrationDate ? intlDateFormat(registrationDate) : null;
};
