import { useCallback, useState } from 'react';

export default function usePopover() {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenPopover = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    return false;
  }, []);

  const onClosePopover = useCallback(() => {
    setAnchorEl(null);
    return false;
  }, []);

  return [anchorEl, onOpenPopover, onClosePopover];
}
