import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { application } from '../../services/application';

const INITIAL_STATE = {
  currentOnboardingStep: 0,
  samApiKey: '',
};

export const signUp = createAsyncThunk('auth/signUp', async (params) => {
  return application.call('user.createUser', params);
});

function onSignUpFulfilled(state, action) {
  return { ...state, result: action.result };
}

export const login = createAction('auth/login');

function onLogin(state, action) {
  localStorage.setItem('authFormatDate', action.payload.formatDate ? action.payload.formatDate : 'MM/DD/YYYY');
  localStorage.setItem('authTimezone', action.payload.authTimezone);
  return Object.assign({ currentOnboardingStep: 0 }, action.payload);
}

export const update = createAction('auth/update');

function onUpdate(state, action) {
  return Object.assign(state, action.payload);
}

export const updatePhone = createAction('auth/updatePhone');
function onUpdatePhone(state, action) {
  return Object.assign(state, {
    phone: action.payload,
  });
}

export const updatePhoneVerified = createAction('auth/updatePhoneVerified');
function onUpdatePhoneVerified(state, action) {
  return Object.assign(state, {
    phoneVerified: action.payload,
  });
}

export const updateSamApiKey = createAction('auth/updateSamApiKey');
function onUpdateSamApiKey(state, action) {
  return Object.assign(state, {
    samApiKey: action.payload.samApiKey,
  });
}

export const updateInfoUser = createAction('auth/updateInfoUser');
function onUpdateInfoUser(state, action) {
  return Object.assign(state, {
    name: action.payload.name,
    familyName: action.payload.familyName,
  });
}

export const updateSkipHelps = createAction('auth/updateSkipHelps');
function onUpdateSkipHelps(state, action) {
  return Object.assign(state, {
    skipHelps: action.payload,
  });
}

export const setMFADb = createAsyncThunk('auth/setMFADb', async (params) => {
  return application.call('user.setMFADb', params);
});

export const toggleTutorials = createAction('auth/toggleTutorials');

function onToggleTutorials(state, action) {
  return Object.assign(state, action.payload);
}

export const updateUserOrganizations = createAction('auth/updateUserOrganizations');
function onUserOrganizations(state, action) {
  const stateUser = Object.assign({}, state);
  const organizations = stateUser.organizations.map((org) => {
    if (org.organizationId === action.payload.organizationId) return { ...org, active: true };
    if (org.organizationId !== action.payload.organizationId) return { ...org, active: false };
  });
  stateUser.organizations = organizations;
  return Object.assign({}, stateUser);
}

export const changeCurrentOnboardingStep = createAction('auth/changeCurrentOnboardingStep');
function onChangeCurrentOnboardingStep(state, action) {
  return Object.assign(state, { currentOnboardingStep: action.payload.step });
}

export const authActions = {
  signUp,
  login,
  update,
  toggleTutorials,
  updateUserOrganizations,
  updateInfoUser,
  updateSamApiKey,
  updatePhone,
  updatePhoneVerified
};

export const authReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(login, onLogin)
    .addCase(update, onUpdate)
    .addCase(signUp.fulfilled, onSignUpFulfilled)
    .addCase(updateUserOrganizations, onUserOrganizations)
    .addCase(changeCurrentOnboardingStep, onChangeCurrentOnboardingStep)
    .addCase(toggleTutorials, onToggleTutorials)
    .addCase(updatePhone, onUpdatePhone)
    .addCase(updatePhoneVerified, onUpdatePhoneVerified)
    .addCase(updateInfoUser, onUpdateInfoUser)
    .addCase(updateSamApiKey, onUpdateSamApiKey)
    .addCase(updateSkipHelps, onUpdateSkipHelps);
});
