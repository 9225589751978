import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconContractAwards = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="M12 1c1.105 0 2 .895 2 2v16c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V3c0-1.105.895-2 2-2zm10 18c0 1.105-.895 2-2 2h-2c-1.105 0-2-.895-2-2V6.246c0-.323.079-.642.229-.929l1.984-3.782c.383-.73 1.435-.709 1.789.036l1.804 3.796c.128.268.194.562.194.859zM12 3H2v16h10V3zm8 14h-2v2h2v-2zM7 14v2H4v-2h3zm12.054-9.764L18 6.246V15h2V6.226l-.946-1.99zM10 10v2H4v-2h6zm0-4v2H4V6h6z" />
    </SvgIcon>
  );
};

IconContractAwards.propTypes = simpleIconPropsTypes;
